import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core'
import { withSnackbar } from 'notistack'
import { Close } from '@material-ui/icons'
import React from 'react'
import MaskedInput from 'react-input-mask'
import ApiInstance from '../services/ApiInstance'

class EditClient extends React.Component {
  constructor() {
    super()
    this.state = {
      open: false,
      loading: false,
      cpf: '',
      client: {
        id: '',
        name: '',
        email: '',
        cpf: '',
        telephone_1: '',
        telephone_2: '',
      },
    }
  }

  render() {
    const { open, client, loading } = this.state

    const handleSubmit = async (event) => {
      event.preventDefault()
      const { client } = this.state
      this.setState({ loading: true })

      await ApiInstance.put('cliente', { id: client.id, email: client.email, telephone_1: client.telephone_1, telephone_2: client.telephone_2 })
        .then(() => {
          const snackbarKey = this.props.enqueueSnackbar('Atualizado com sucesso.', {
            variant: 'success',
            onClick: () => {
              this.props.closeSnackbar(snackbarKey)
            },
          })
          this.setState({ loading: false, open: false })
          this.props.parent.fetchData()
        })
        .catch(() => {
          const snackbarKey = this.props.enqueueSnackbar('Falha ao atualizar.', {
            variant: 'error',
            onClick: () => {
              this.props.closeSnackbar(snackbarKey)
            },
          })
          this.setState({ loading: false })
        })
    }

    const handleChange = (event) => {
      const { client } = this.state
      const { value, name } = event.target
      this.setState({ client: { ...client, [name]: value } })
    }

    return (
      <Dialog disableScrollLock open={open} onClose={() => this.setState({ open: false })}>
        <form onSubmit={handleSubmit}>
          <DialogTitle
            disableTypography
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6">Edição de cliente</Typography>
            <IconButton onClick={() => this.setState({ open: false })} size="small" style={{ marginLeft: 'auto' }}>
              <Close></Close>
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField name="name" fullWidth variant="outlined" placeholder="José Vieira" label="Nome" value={client.name} disabled></TextField>
              </Grid>

              <Grid item xs={12}>
                <TextField name="cpf" fullWidth variant="outlined" placeholder="123.456.789-00" label="CPF" value={client.cpf} disabled></TextField>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  name="email"
                  onChange={handleChange}
                  type="email"
                  fullWidth
                  variant="outlined"
                  placeholder="jose.vieira@gmail.com"
                  label="Email"
                  value={client.email}
                ></TextField>
              </Grid>

              <Grid item xs={12} sm={6}>
                <MaskedInput
                  maskChar=""
                  mask="(99) 99999-9999"
                  name="telephone_1"
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  placeholder="(32) 99999-9999"
                  label="Telefone principal"
                  value={client.telephone_1}
                >
                  {(inputProps) => <TextField {...inputProps} />}
                </MaskedInput>
              </Grid>

              <Grid item xs={12} sm={6}>
                <MaskedInput
                  maskChar=""
                  mask="(99) 99999-9999"
                  name="telephone_2"
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  placeholder="(32) 99999-9999"
                  label="Telefone alternativo"
                  value={client.telephone_2}
                >
                  {(inputProps) => <TextField {...inputProps} />}
                </MaskedInput>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              disabled={loading}
              onClick={() => {
                this.setState({ open: false })
              }}
            >
              Cancelar
            </Button>
            <Box style={{ display: 'flex', position: 'relative' }}>
              <Button disabled={loading} type="submit" color="primary" variant="contained">
                Salvar
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: -12,
                    marginLeft: -12,
                  }}
                />
              )}
            </Box>
          </DialogActions>
        </form>
      </Dialog>
    )
  }
}

export default withSnackbar(EditClient)
