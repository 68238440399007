import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, TextField, Typography } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { withSnackbar } from 'notistack'
import React from 'react'
import ApiInstance from '../services/ApiInstance'

class ModalCancelTransaction extends React.Component {
  constructor() {
    super()

    this.state = {
      description: '',
      loading: false,
    }
  }
  render() {
    const { cancel, selectedPurchase } = this.props.parent.state

    const { description, loading } = this.state

    const handleChange = (event) => {
      const { name, value } = event.target

      this.setState({ [name]: value })
    }

    const cancelTransaction = async () => {
      this.setState({ loading: true })
      const purchase = selectedPurchase[0].purchase
      try {
        if (!description) throw new Error('Informe o motivo do cancelamento.')

        await ApiInstance.put('/transacao/cancelaTransacao', {
          id_purchase: purchase.id,
        })

        await ApiInstance.post('/transacao/emailCancelaTransacao', {
          company: purchase.empresa,
          reason: description,
          id_purchase: purchase.id,
          value: purchase.total_value_loc_corresp,
        })

        await ApiInstance.post('timeline', {
          client_id: purchase.client_id,
          name: 'Cancelamento',
          notas: description,
        })

        const snackbarKey = this.props.enqueueSnackbar('Transação cancelada com sucesso!', {
          variant: 'success',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        })

        this.props.parent.setState({ cancel: { ...this.state.cancel, open: false } })
        this.setState({ description: '', loading: false })
      } catch (error) {
        this.setState({ loading: false, confirmationOpen: false })
        if (error.originalMessage === 'DATA_LIMITE') {
          const snackbarKey = this.props.enqueueSnackbar('O limite para cancelamento de uma transação é 30 dias.', {
            variant: 'error',
            onClick: () => {
              this.props.closeSnackbar(snackbarKey)
            },
          })
          return
        }

        const snackbarKey = this.props.enqueueSnackbar(error.message, {
          variant: 'error',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        })
      }
    }

    return (
      <React.Fragment>
        <Dialog disableScrollLock open={cancel.open} onClose={() => this.props.parent.setState({ cancel: { ...cancel, open: false } })}>
          <DialogTitle
            disableTypography
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6">Deseja cancelar esta transação?</Typography>
            <IconButton onClick={() => this.props.parent.setState({ cancel: { ...cancel, open: false } })} size="small" style={{ marginLeft: 'auto' }}>
              <Close></Close>
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="description"
                  multiline
                  placeholder="Informe o motivo do cancelamento..."
                  label="Motivo"
                  fullWidth
                  variant="outlined"
                  onChange={handleChange}
                  value={description}
                  rows={4}
                ></TextField>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="primary" description="" onClick={() => this.props.parent.setState({ cancel: { ...cancel, open: false } })}>
              Voltar
            </Button>
            <Button
              description=""
              variant="contained"
              color="primary"
              onClick={() => {
                if (!this.state.description) {
                  const snackbarKey = this.props.enqueueSnackbar('Informe o motivo do cancelamento.', {
                    variant: 'error',
                    onClick: () => {
                      this.props.closeSnackbar(snackbarKey)
                    },
                  })
                  return
                }
                this.props.parent.setState({ cancel: { ...cancel, confirmationIsOpen: true } })
              }}
            >
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog disableScrollLock open={cancel.confirmationIsOpen || false} onClose={() => this.props.parent.setState({ cancel: { ...cancel, confirmationIsOpen: false } })}>
          <DialogTitle
            disableTypography
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6">Atenção!</Typography>
            <IconButton onClick={() => this.props.parent.setState({ cancel: { ...cancel, confirmationIsOpen: false } })} size="small" style={{ marginLeft: 'auto' }}>
              <Close></Close>
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <DialogContentText>Esta ação é irreversível, ou seja, após cancelada, a venda não poderá ser revertida ao estado anterior.</DialogContentText>
            <DialogContentText>
              Você está cancelando a transação com o ID {!!selectedPurchase[0] && selectedPurchase[0].purchase && selectedPurchase[0].purchase.id} pelo seguinte motivo: {description}.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button disabled={loading} onClick={() => this.props.parent.setState({ cancel: { ...cancel, confirmationIsOpen: false } })} color="primary">
              Voltar
            </Button>
            <Box style={{ display: 'flex', position: 'relative' }}>
              <Button
                disabled={loading}
                variant="contained"
                onClick={() => {
                  this.props.parent.setState({ cancel: { ...cancel, confirmationIsOpen: true } })
                  cancelTransaction()
                }}
                color="primary"
              >
                Continuar
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: -12,
                    marginLeft: -12,
                  }}
                />
              )}
            </Box>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}

export default withSnackbar(ModalCancelTransaction)
