import { useState, useRef } from 'react'
import { EditIcon, EmailIcon } from '@chakra-ui/icons'
import {
  Button,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  StackDivider,
  Text,
  useToast,
} from '@chakra-ui/react'

import ApiInstance from '../../../../services/ApiInstance'
import { EditCreditCardModal } from './EditCreditCardModal'
import { RenterType } from '../../types/renter'

interface ChangeCreditCardModal {
  renter: RenterType
  isOpen: boolean
  handleCloseModal: () => void
}

interface ButtonRef extends HTMLButtonElement {
  focus: () => void
}

export function ChangeCreditCardModal({ renter, isOpen, handleCloseModal }: ChangeCreditCardModal) {
  const [editCreditCardModalOpen, setEditCreditCardModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const toast = useToast()

  const focusRef = useRef<ButtonRef>(null)

  function handleEditCreditCard() {
    setEditCreditCardModalOpen(true)
  }

  function handleEditCreditCardCloseModal(type?: string) {
    setEditCreditCardModalOpen(false)
    if (type === 'submit') return handleCloseModal()
  }

  async function handleSendEmail() {
    setLoading(true)
    try {
      const { email, name, id } = renter
      await ApiInstance.post('sendMailToLocator', { email, name, id })

      toast({
        title: 'Email enviado com sucesso.',
        status: 'success',
        duration: 2000,
        isClosable: true,
      })
      setLoading(false)
      handleCloseModal()
    } catch (error) {
      setLoading(false)
      toast({
        title: 'Erro ao enviar email, entre em contato com nosso suporte.',
        status: 'error',
        duration: 2000,
        isClosable: true,
      })
    }
  }

  return (
    <>
      <EditCreditCardModal
        isOpen={editCreditCardModalOpen}
        handleCloseModal={handleEditCreditCardCloseModal}
        renter={renter}
      />
      <Modal
        isCentered
        isOpen={isOpen}
        initialFocusRef={focusRef}
        onClose={handleCloseModal}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontWeight="normal" color="gray.600">
            Alteração de cartão
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <HStack divider={<StackDivider borderColor="gray.200" />} spacing={4}>
              <Button
                isDisabled={loading}
                _hover={{ backgroundColor: 'blue.50' }}
                boxShadow="base"
                bg="white"
                w="100%"
                py={16}
                onClick={handleEditCreditCard}
              >
                <Flex direction="column" align="center">
                  <EditIcon mb={4} fontSize="xl" color="blue.500" />
                  <Text fontSize="sm" fontWeight="normal" color="gray.600">
                    Alterar manualmente
                  </Text>
                </Flex>
              </Button>
              <Button
                isLoading={loading}
                _hover={{ backgroundColor: 'green.50' }}
                boxShadow="base"
                bg="white"
                w="100%"
                py={16}
                onClick={handleSendEmail}
              >
                <Flex direction="column" align="center">
                  <EmailIcon mb={4} fontSize="xl" color="green.500" />
                  <Text fontSize="sm" fontWeight="normal" color="gray.600">
                    Enviar e-mail
                  </Text>
                </Flex>
              </Button>
            </HStack>
          </ModalBody>
          <ModalFooter>
            <Button ref={focusRef} color="gray.600" onClick={handleCloseModal}>
              Voltar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
