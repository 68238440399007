import { FILL_PURCHASE, FILL_CONTRACT, FILL_LOGIN, 
  FILL_LOGOUT, FILL_CLIENT, FILL_TICKET, FILL_TRIAL,
  FILL_PURCHASE_PROPRIA, FILL_PURCHASE_UCRED, FILL_TICKET_PROPRIA,
  FILL_TICKET_UCRED, FILL_TOTAL_UCRED, FILL_TOTAL_PROPRIA, 
  FILL_RECUSED_UCRED, FILL_RECUSED_PROPRIA, FILL_CONTRACT_SIGNED,
  FILL_CONTRACT_NOT_SIGNED, 
  FILL_PURCHASE_PROPRIA_CANCELLED,
  FILL_PURCHASE_UCRED_CANCELLED, FILL_COMPANY, FILL_ACTIVE_COMPANY, FILL_SINISTRO_PROPRIA, FILL_SINISTRO_UCRED} from '../actions/dashbardAction'

  
const INITIAL_STATE = {
  purchases: 0,
  contracts: 0,
  logins: 0,
  logouts: 0,
  clients: 0,
  tickets: 0,
  trials: 0,
  purchasesPropria: 0,
  purchasesPropriaCancelled: 0,
  purchasesUcred:0,
  purchasesUcredCancelled:0,
  ticketsPropria: 0,
  ticketsUcred: 0,
  totalUcred: 0,
  totalPropria: 0,
  recusedUcred: 0,
  recusedPropria: 0,
  contractsSigned: 0,
  contractsNotSigned: 0,
  companies: 0,
  activeCompany: 0,
  sinistroPropria: 0,
  sinistroUcred: 0
}

function dashboardReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
      case FILL_PURCHASE:
          return {
              ...state,
              purchases: action.value
          }
      case FILL_CONTRACT: {
          return {
              ...state,
              contracts: action.value
          }
      }
      case FILL_LOGIN: {
          return {
              ...state,
              logins: action.value
          }
      }
      case FILL_LOGOUT: {
          return {
              ...state,
              logouts: action.value
          }
      }
      case FILL_CLIENT: {
          return {
              ...state,
              clients:action.value
          }
      }
      case FILL_TICKET: {
          return {
              ...state,
              tickets: action.value
          }
      }
      case FILL_TRIAL: {
          return {
              ...state,
              trials: action.value
          }
      }
      case FILL_PURCHASE_PROPRIA: {
          return {
              ...state,
              purchasesPropria: action.value
          }
      }
      case FILL_PURCHASE_PROPRIA_CANCELLED: {
          return {
              ...state,
              purchasesPropriaCancelled: action.value
          }
      }
      case FILL_PURCHASE_UCRED: {
          return {
              ...state,
              purchasesUcred: action.value
          }
      }
      case FILL_PURCHASE_UCRED_CANCELLED: {
          return {
              ...state,
              purchasesUcredCancelled: action.value
          }
      }
      case FILL_TICKET_PROPRIA: {
          return {
              ...state,
              ticketsPropria: action.value
          }
      }
      case FILL_TICKET_UCRED : {
          return {
              ...state,
              ticketsUcred: action.value
          }
      }
      case FILL_TOTAL_UCRED: {
          return {
              ...state,
              totalUcred: action.value
          }
      }
      case FILL_TOTAL_PROPRIA: {
          return {
              ...state,
              totalPropria: action.value
          }
      }
      case FILL_RECUSED_UCRED: {
          return {
              ...state,
              recusedUcred: action.value
          }
      }
      case FILL_RECUSED_PROPRIA: {
          return {
              ...state,
              recusedPropria: action.value
          }
      }
      case FILL_CONTRACT_SIGNED: {
          return {
              ...state,
              contractsSigned: action.value
          }
      }
      case FILL_CONTRACT_NOT_SIGNED: {
          return {
              ...state,
              contractsNotSigned: action.value
          }
      }
      case FILL_COMPANY: {
          return {
              ...state,
              companies: action.value
          }
      }
      case FILL_ACTIVE_COMPANY: {
          return {
              ...state,
              activeCompany: action.value
          }
      }
      case FILL_SINISTRO_PROPRIA: {
          return {
              ...state,
              sinistroPropria: action.value
          }
      }
      case FILL_SINISTRO_UCRED: {
          return {
              ...state,
              sinistroUcred: action.value
          }
      }
      default:
          return state
  }
}

export default dashboardReducer