/* eslint-disable camelcase */
import { getContractStatus } from '../../../util/getContractStatus'
import { getPaymentMethod } from '../../../util/getPaymentMethod'
import { maskMoney } from '../../../util/money'
import { RenterType } from '../types/renter'
import { ResponseClientDataType } from '../types/response/client'
import { ResponseTransactionDetailsType } from '../types/response/transaction-details'

type MapperProps = {
  purchaseId: number
  clientDetails: ResponseClientDataType
  transactionDetails: ResponseTransactionDetailsType
}

type MapperReturnType = {
  renter: RenterType
  coresponsible: RenterType | null
}

export function clientApiDataMapper({
  purchaseId,
  clientDetails,
  transactionDetails,
}: MapperProps): MapperReturnType {
  const { main_purchase, corresponsible_purchase } = transactionDetails
  const { purchaseFormated, clientData } = clientDetails
  const { empresa, address, city, cep, payment_method, payment_url, contract_url, corresponsavel } =
    purchaseFormated.filter(purchase => Number(purchase.id) === Number(purchaseId))[0]
  const { id, name, cpf, birthday, email, telephone_1, telephone_2 } = clientData
  const isSameClientId = (corresponsavel ? corresponsavel.client_id : '') === id
  const corresponsible_is = isSameClientId ? 'renter' : 'other'
  const renter = {
    id: id,
    name: name,
    cpf: cpf,
    birthday: birthday,
    email: email,
    phone1: telephone_1,
    phone2: telephone_2,
    address: address,
    city: city,
    zipCode: cep,
    companyName: empresa,
    amountCharged: maskMoney(main_purchase.value),
    paymentMethodFormatted: getPaymentMethod(payment_method),
    paymentMethod: payment_method,
    paymentStatus: main_purchase.purchase_status_id,
    paymentParcel: main_purchase.parcel,
    paymentUrl: payment_url,
    contractUrl: contract_url,
    contractStatus: main_purchase.contract_status,
    contractStatusFormatted: getContractStatus(main_purchase.contract_status).title,
    corresponsibleIs: corresponsible_is,
  }

  const coresponsible = corresponsible_purchase.name
    ? {
        id: corresponsavel.client_id,
        name: corresponsavel.name,
        cpf: corresponsavel.cpf,
        birthday: corresponsavel.birthday,
        email: corresponsavel.email,
        phone1: corresponsavel.telephone_1,
        phone2: corresponsavel.telephone_2,
        address: corresponsavel.address,
        city: corresponsavel.city,
        zipCode: corresponsavel.cep,
        companyName: empresa,
        amountCharged: maskMoney(corresponsible_purchase.value),
        paymentMethodFormatted: getPaymentMethod(corresponsible_purchase.payment_method),
        paymentMethod: corresponsible_purchase.payment_method,
        paymentStatus: corresponsible_purchase.purchase_status_id,
        paymentParcel: corresponsible_purchase.parcel,
        paymentUrl: corresponsavel.payment_url,
        contractUrl: corresponsavel.contract_url,
        contractStatus: corresponsible_purchase.contract_status,
        contractStatusFormatted: getContractStatus(corresponsible_purchase.contract_status).title,
        corresponsibleIs: corresponsible_is,
      }
    : null
  return { renter, coresponsible }
}
