import React from 'react'

function svg(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      focusable="false"
      width="1em"
      height="1em"
      style={{width: 18, height: 18 }}
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 1792 1792"
    >
      <path
        d="M336 0h1120q139 0 237.5 98.5T1792 336v1120q0 139-98.5 237.5T1456 1792H336q-139 0-237.5-98.5T0 1456V336Q0 197 98.5 98.5T336 0zm562 1221q-103 0-155.5-40T690 1051V374q0-31-22-53t-53-22H466q-31 0-53 22t-22 53v641q0 58 16.5 120t55.5 127t95 115.5t144.5 83T898 1493t195.5-32.5t144.5-83t94.5-115.5t55-127t16.5-120V374q0-31-21.5-53t-52.5-22h-149q-31 0-53 22t-22 53v677q0 90-52.5 130T898 1221z"
        fill={props.color}
      />
    </svg>
  )
}
export default svg
