import { Input, InputProps as ChakraInputProps } from '@chakra-ui/react'
import { ForwardRefRenderFunction } from 'react'

interface InputProps extends ChakraInputProps {
  mask?: string | null
  maskChar?: string | null
}
interface InputRef extends HTMLInputElement {
  focus: () => void
}

const ChakraMaskedInput: ForwardRefRenderFunction<InputRef, InputProps> = props => (
  <Input {...props}></Input>
)

export default ChakraMaskedInput
