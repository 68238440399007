import { Avatar, Box, Button, Chip, Fab, Grid, Typography } from '@material-ui/core'
import { CancelRounded, DescriptionRounded, GetApp, MoneyOffRounded, NoteAddRounded, Print, ReceiptRounded } from '@material-ui/icons'
import Copy from 'copy-to-clipboard'
import MaterialTable from 'material-table'
import moment from 'moment'
import 'moment/locale/pt-br'
import { withSnackbar } from 'notistack'
import React from 'react'
import ApiInstance from '../../services/ApiInstance'
import ApiInstanceV2 from '../../services/ApiInstanceV2'
import styles from '../../styles/defaultStyles'
import tableIcons from '../../styles/tableIcons'

class TransactionsTable extends React.Component {
  constructor() {
    super()
    this.state = {
      loading: true,
      actions: 0,
    }
    this.tableRef = React.createRef()
  }

  getImobGainValue(purchase, hasCorresponsible) {
    let imob_gain = purchase.imob_gain

    let imob_value
    if (!hasCorresponsible) {
      imob_value = (imob_gain / 100) * (Number(purchase.total_value) * 0.95)
    } else {
      imob_value = (imob_gain / 100) * (Number(purchase.total_value_loc_corresp) * 0.95)
    }

    return Number(imob_value)
  }

  transactionsTable() {
    let purchaseArray = []
    const { company } = this.props.parent.state
    const purchases = this.props.parent.state.purchases || []

    purchases.map((_p, index) => {
      let purchaseObj = {}

      purchaseObj.index = index

      purchaseObj.parcel = _p.status === 3 ? _p.parcel + 'x' : '-'

      purchaseObj.purchase = _p

      purchaseObj.total_value = _p.total_value_loc_corresp ? Number(_p.total_value_loc_corresp) : Number(_p.total_value)

      if (company.plan_id === 2) purchaseObj.value_loc_corresp = _p.value_loc_corresp ? this.getImobGainValue(_p, true) : this.getImobGainValue(_p, false)

      switch (_p.payment_method) {
        case 'credit_card':
          purchaseObj.payment_method = 'Cartão de crédito'
          break
        case 'bank_slip':
          purchaseObj.payment_method = 'Boleto'
          break
        case 'bank_slip_1':
          purchaseObj.payment_method = 'Boleto'
          break
        case 'cash':
          purchaseObj.payment_method = 'Dinheiro'
          break
        default:
          purchaseObj.payment_method = 'Não definido'
          break
      }

      switch (_p.status) {
        case 1:
          purchaseObj.status = 'Pendente'
          purchaseObj.status_column = <Chip style={{ color: '#fff', background: styles.info }} size="small" label="Pendente" />
          break
        case 2:
          purchaseObj.status = 'Aguardando pagamento'
          purchaseObj.status_column = <Chip style={{ color: '#fff', background: styles.info }} size="small" label="Aguardando pagamento" />
          break
        case 3:
          purchaseObj.status = 'Concluída'
          purchaseObj.status_column = <Chip style={{ color: '#fff', background: styles.success }} size="small" label="Concluída" />
          break
        case 4:
          purchaseObj.status = 'Cancelada'
          purchaseObj.status_column = <Chip style={{ color: '#fff', background: styles.critical }} size="small" label="Cancelada" />
          break
        case 5:
          purchaseObj.status = 'Falha'
          purchaseObj.status_column = <Chip style={{ color: '#fff', background: styles.error }} size="small" label="Falha" />
          break
        case 6:
          purchaseObj.status = 'Pago'
          purchaseObj.status_column = <Chip style={{ color: '#fff', background: styles.success }} size="small" label="Pago" />
          break
        case 7:
          purchaseObj.status = 'Pago parcialmente'
          purchaseObj.status_column = <Chip style={{ color: '#fff', background: styles.info }} size="small" label="Pago parcialmente" />
          break
        case 8:
          purchaseObj.status = 'Pendente contrato'
          purchaseObj.status_column = <Chip style={{ color: '#fff', background: styles.info }} size="small" label="Pendente contrato" />
          break
        case 9:
          purchaseObj.status = 'Pendente aprovação Ucred'
          purchaseObj.status_column = <Chip style={{ color: '#fff', background: styles.info }} size="small" label="Pendente aprovação Ucred" />
          break
        default:
          break
      }

      if (company.plan_id !== 1) {
        switch (_p.contract_status) {
          case 'running':
            purchaseObj.contract_status = 'Aguardando assinatura'
            purchaseObj.contract_status_column = <Chip style={{ color: '#fff', background: styles.info }} size="small" label="Aguardando assinatura" />
            break
          case 'signed':
            purchaseObj.contract_status = 'Assinado com a Ucred'
            purchaseObj.contract_status_column = <Chip style={{ color: '#fff', background: styles.success }} size="small" label="Assinado com a Ucred" />
            break
          case 'canceled':
            purchaseObj.contract_status = 'Cancelado. Contrato não foi assinado'
            purchaseObj.contract_status_column = <Chip style={{ color: '#fff', background: styles.error }} size="small" label="Cancelado. Contrato não foi assinado" />
            break
          case 'parcial':
            purchaseObj.contract_status = 'Assinado parcialmente'
            purchaseObj.contract_status_column = <Chip style={{ color: '#fff', background: styles.info }} size="small" label="Assinado parcialmente" />
            break
          default:
            purchaseObj.contract_status = ''
            break
        }
      }

      purchaseObj.dt_inicio = _p.dt_inicio
      purchaseObj.dt_fim = _p.dt_fim
      purchaseObj.message_card = _p.message_card || '-'
      purchaseObj.address = _p.address || '-'
      purchaseObj.city = _p.city || '-'
      purchaseObj.cep = _p.cep || '-'

      purchaseArray.push(purchaseObj)

      return null
    })

    this.props.parent.setState({ transactionsTable: { ...this.props.parent.state.transactionsTable, rows: purchaseArray } })
  }

  render() {
    const { rows } = this.props

    const getTransactionsColumns = () => {
      const noOverflow = {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        width: 300,
        maxWidth: 300,
        textOverflow: 'ellipsis',
      }

      let columnArray = []
      if (!this.props.plan_id) {
        columnArray = []
      }

      if (this.props.plan_id === undefined) return

      if (Number(this.props.plan_id) === 1) {
        columnArray = [
          {
            title: 'Valor avalizado',
            field: 'total_value',
            type: 'currency',
            align: 'left',
            export: true,
            currencySetting: { locale: 'pt-BR', currencyCode: 'BRL', minimumFractionDigits: 2, maximumFractionDigits: 2 },
            cellStyle: noOverflow,
          },
          { title: 'Status', field: 'status', align: 'left', cellStyle: noOverflow, export: true, hidden: true, searchable: true },
          { title: 'Status da transação', field: 'status_column', cellStyle: noOverflow, export: false, searchable: false, align: 'center' },
          { title: 'Data início garantia', field: 'dt_inicio', align: 'left', type: 'date', defaultSort: 'desc', cellStyle: noOverflow },
          { title: 'Data de fim da garantia', field: 'dt_fim', align: 'left', type: 'date', cellStyle: noOverflow },
          { title: 'Status do pagamento', field: 'message_card', align: 'left', cellStyle: noOverflow },
        ]
      } else {
        columnArray = [
          {
            title: 'Valor avalizado',
            field: 'total_value',
            type: 'currency',
            align: 'left',
            export: true,
            currencySetting: { locale: 'pt-BR', currencyCode: 'BRL', minimumFractionDigits: 2, maximumFractionDigits: 2 },
            cellStyle: noOverflow,
          },
          {
            title: 'Valor à receber da Ucred',
            field: 'value_loc_corresp',
            type: 'currency',
            align: 'left',
            currencySetting: { locale: 'pt-BR', currencyCode: 'BRL', minimumFractionDigits: 2, maximumFractionDigits: 2 },
            cellStyle: noOverflow,
          },
          {
            title: 'Parcelamento',
            field: 'parcel',
            align: 'left',
            cellStyle: noOverflow,
          },
          { title: 'Status', field: 'status', align: 'left', cellStyle: noOverflow, export: true, hidden: true, searchable: true },
          { title: 'Status da transação', field: 'status_column', cellStyle: noOverflow, export: false, searchable: false, align: 'center' },
          {
            title: 'Status do contrato',
            field: 'contract_status',
            align: 'left',
            cellStyle: noOverflow,
            export: true,
            hidden: true,
            searchable: true,
          },
          {
            title: 'Status do contrato',
            field: 'contract_status_column',
            cellStyle: noOverflow,
            export: false,
            searchable: false,
            align: 'center',
          },
          { title: 'Data início garantia', field: 'dt_inicio', align: 'left', type: 'date', defaultSort: 'desc', cellStyle: noOverflow },
          { title: 'Data de fim da garantia', field: 'dt_fim', align: 'left', type: 'date', cellStyle: noOverflow },
          { title: 'Status do pagamento', field: 'message_card', align: 'left', cellStyle: noOverflow },
          { title: 'CEP', field: 'cep', cellStyle: noOverflow },
          { title: 'Cidade', field: 'city', cellStyle: noOverflow },
          { title: 'Endereço', field: 'address', cellStyle: { ...noOverflow, maxWidth: 900 } },
        ]
      }

      return columnArray
    }

    return (
      <MaterialTable
        icons={tableIcons}
        columns={getTransactionsColumns()}
        options={{
          detailPanelType: 'single',
          pageSize: 15,
          pageSizeOptions: [15, 50, 100],
          tableLayout: 'auto',
          showTitle: true,
          padding: 'dense',
          exportAllData: true,
          exportFileName: 'Relatório Ucred (Transações) - ' + moment().format('DD-MM-YYYY H:mm:ss'),
          exportButton: true,
          actionsColumnIndex: 0,
        }}
        components={{
          Container: (props) => <div id="mtable-container" {...props}></div>,
        }}
        isLoading={this.props.loading}
        data={rows}
        detailPanel={(rowData) => {
          const client = this.props.client

          let payment_method = ''
          switch (rowData.purchase.payment_method) {
            case 'not_defined':
              payment_method = 'Não definido'
              break
            case 'hybrid':
              payment_method = 'Boleto + Cartão'
              break
            case 'cash':
              payment_method = 'Dinheiro'
              break
            case 'bank_slip':
              payment_method = 'Boleto bancário'
              break
            case 'bank_slip_1':
              payment_method = 'Boleto bancário'
              break
            case 'credit_card':
              payment_method = 'Cartão de crédito'
              break
            default:
              payment_method = 'Não definido'
              break
          }

          let actions = false
          if (rowData.purchase.status === 3) actions = true
          if (rowData.purchase.status !== 4) actions = true
          if (rowData.purchase.payment_method !== 'credit_card' && rowData.purchase.payment_method !== 'not_defined') actions = true
          if (rowData.purchase.contract_status === 'signed') actions = true
          return (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid item xs={12} container style={{ width: 1232, maxWidth: 'calc(100vw - 56px)' }}>
                  <Grid item xs={12} md={6}>
                    <Box m={3} p={3} style={{ background: '#fafafa', borderRadius: 16 }}>
                      <Grid spacing={1} container item xs={12}>
                        <Grid item xs={12}>
                          <Box style={{ display: 'flex' }}>
                            <Avatar></Avatar>
                            <Box style={{ marginLeft: 16 }}>
                              <Typography>{client.name}</Typography>
                              <Typography variant="body2" color="textSecondary">
                                {'Locatário'}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant="body2" color="textSecondary">
                            CPF
                          </Typography>
                          <Typography>{client.cpf || '-'}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant="body2" color="textSecondary">
                            Email
                          </Typography>
                          <Typography>{client.email || '-'}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant="body2" color="textSecondary">
                            Telefone principal
                          </Typography>
                          <Typography>{client.telephone_1 || '-'}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant="body2" color="textSecondary">
                            Telefone alternativo
                          </Typography>
                          <Typography>{client.telephone_2 || '-'}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body2" color="textSecondary">
                            Método de pagamento
                          </Typography>
                          <Typography>{payment_method || '-'}</Typography>
                        </Grid>
                        <Grid style={{ display: 'flex', justifyContent: 'flex-end' }} item xs={12}>
                          {rowData.purchase.payment_url && rowData.purchase.status !== 3 && (
                            <Button
                              onClick={() => {
                                Copy(
                                  `Olá ${client.name}, primeiramente estamos lhe dando os parabéns por realizar uma locação com a Imobiliária ${
                                    rowData.purchase.empresa
                                  }, você vai ver como é simples, fácil e sem burocracia.\n\nSegue abaixo os links para pagamento e o contrato para assinatura:\n\nLink de pagamento: ${
                                    rowData.purchase.payment_url || '-'
                                  }\n\nContrato para assinatura: ${rowData.purchase.contract_url || '-'}\n\nCaso tenha qualquer dúvida, entre em contato com sua imobiliária.`
                                )

                                const snackbarKey = this.props.enqueueSnackbar('Mensagem copiada com sucesso!', {
                                  variant: 'info',
                                  onClick: () => {
                                    this.props.closeSnackbar(snackbarKey)
                                  },
                                })
                              }}
                              style={{ marginRight: 8 }}
                              size="small"
                              variant="contained"
                              color="primary"
                            >
                              Link de pagamento
                            </Button>
                          )}
                          <Button
                            onClick={() => {
                              this.props.parent.SalesDetail.setState({ switchState: 1, document_key: rowData.purchase.document_key })
                              this.props.parent.openDetail(rowData)
                            }}
                            style={{ marginRight: 8 }}
                            size="small"
                            variant="contained"
                            color="primary"
                          >
                            Detalhes
                          </Button>
                          <Button onClick={() => this.props.parent.EditClientModal.setState({ open: true, client })} size="small" variant="contained" color="primary">
                            Editar
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>

                  {rowData.purchase.corresponsavel && (
                    <Grid item xs={12} md={6}>
                      <Box m={3} p={3} style={{ background: '#fafafa', borderRadius: 16 }}>
                        <Grid spacing={1} container item xs={12}>
                          <Grid item xs={12}>
                            <Box style={{ display: 'flex' }}>
                              <Avatar></Avatar>
                              <Box style={{ marginLeft: 16 }}>
                                <Typography>{rowData.purchase.corresponsavel.name}</Typography>
                                <Typography variant="body2" color="textSecondary">
                                  {'Corresponsável'}
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography variant="body2" color="textSecondary">
                              CPF
                            </Typography>
                            <Typography>{rowData.purchase.corresponsavel.cpf || '-'}</Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography variant="body2" color="textSecondary">
                              Email
                            </Typography>
                            <Typography>{rowData.purchase.corresponsavel.email || '-'}</Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography variant="body2" color="textSecondary">
                              Telefone principal
                            </Typography>
                            <Typography>{rowData.purchase.corresponsavel.telephone_1 || '-'}</Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography variant="body2" color="textSecondary">
                              Telefone alternativo
                            </Typography>
                            <Typography>{rowData.purchase.corresponsavel.telephone_2 || '-'}</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="body2" color="textSecondary">
                              Método de pagamento
                            </Typography>
                            <Typography>{payment_method || '-'}</Typography>
                          </Grid>
                          <Grid style={{ display: 'flex', justifyContent: 'flex-end' }} item xs={12}>
                            {rowData.purchase.corresponsavel && (
                              <>
                                {rowData.purchase.corresponsavel.payment_url && rowData.purchase.status !== 3 && (
                                  <>
                                    <Button
                                      onClick={() => {
                                        Copy(
                                          `Olá ${rowData.purchase.corresponsavel.name}, primeiramente estamos lhe dando os parabéns por realizar uma locação com a Imobiliária ${
                                            rowData.purchase.empresa
                                          }, você vai ver como é simples, fácil e sem burocracia.\n\nSegue abaixo os links para pagamento e o contrato para assinatura:\n\nLink de pagamento: ${
                                            rowData.purchase.corresponsavel.payment_url || '-'
                                          }\n\nContrato para assinatura: ${rowData.purchase.corresponsavel.contract_url || '-'}\n\nCaso tenha qualquer dúvida, entre em contato com sua imobiliária.`
                                        )

                                        const snackbarKey = this.props.enqueueSnackbar('Mensagem copiada com sucesso!', {
                                          variant: 'info',
                                          onClick: () => {
                                            this.props.closeSnackbar(snackbarKey)
                                          },
                                        })
                                      }}
                                      style={{ marginRight: 8 }}
                                      size="small"
                                      variant="contained"
                                      color="primary"
                                    >
                                      Link de pagamento
                                    </Button>
                                  </>
                                )}
                              </>
                            )}
                            <Button
                              onClick={() => {
                                this.props.parent.SalesDetail.setState({ switchState: 2, document_key: rowData.purchase.document_key })
                                this.props.parent.openDetail(rowData)
                              }}
                              style={{ marginRight: 8 }}
                              size="small"
                              variant="contained"
                              color="primary"
                            >
                              Detalhes
                            </Button>
                            <Button
                              onClick={() =>
                                this.props.parent.EditClientModal.setState({
                                  open: true,
                                  client: { ...rowData.purchase.corresponsavel, id: rowData.purchase.corresponsavel.client_id },
                                })
                              }
                              size="small"
                              variant="contained"
                              color="primary"
                            >
                              Editar
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  )}

                  {actions && (
                    <Grid item xs={12} md={6}>
                      <Box m={3} p={3} style={{ background: '#fafafa', borderRadius: 16 }}>
                        {/* -------------------------------------------------------------------------- */}
                        {/*          PARA AUTENTICAR DOCUMENTOS, A VENDA DEVE ESTAR CONCLUÍDA          */}
                        {/* -------------------------------------------------------------------------- */}
                        {rowData.purchase.status === 3 && (
                          <Grid spacing={1} container item xs={12}>
                            <Box
                              onClick={() => {
                                this.props.parent.props.history.push(`/documentos/novo-envio/${rowData.purchase.id}`)
                              }}
                              m={1}
                              style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                            >
                              <Fab size="small" color="primary">
                                <NoteAddRounded />
                              </Fab>
                              <Typography component="span" variant="subtitle1" style={{ marginLeft: 16, cursor: 'pointer' }}>
                                Autenticar documentos
                              </Typography>
                            </Box>
                          </Grid>
                        )}

                        {/* -------------------------------------------------------------------------- */}
                        {/*                VISUALIZA OS DOCUMENTOS AUTENTICADOS DA VENDA               */}
                        {/* -------------------------------------------------------------------------- */}
                        {!!(rowData.purchase.status === 3 && rowData.purchase.contracts.length) && (
                          <Grid spacing={1} container item xs={12}>
                            <Box
                              onClick={() => {
                                this.props.parent.handleOpenModalDocuments(rowData.purchase.id)
                              }}
                              m={1}
                              style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                            >
                              <Fab size="small" color="primary">
                                <DescriptionRounded />
                              </Fab>
                              <Typography component="span" variant="subtitle1" style={{ marginLeft: 16, cursor: 'pointer' }}>
                                Baixar documentos autenticados
                              </Typography>
                            </Box>
                          </Grid>
                        )}

                        {/* -------------------------------------------------------------------------- */}
                        {/*            PARA ACIONAR SINISTROS, A VENDA DEVE ESTAR CONCLUIDA            */}
                        {/* -------------------------------------------------------------------------- */}
                        {rowData.purchase.status === 3 && (
                          <Grid spacing={1} container item xs={12}>
                            <Box
                              onClick={() =>
                                this.props.parent.setState({
                                  invoices: { ...this.props.parent.state.invoices, open: true },
                                  selectedPurchase: [rowData],
                                })
                              }
                              m={1}
                              style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                            >
                              <Fab size="small" color="primary">
                                <MoneyOffRounded />
                              </Fab>
                              <Typography component="span" variant="subtitle1" style={{ marginLeft: 16, cursor: 'pointer' }}>
                                Acionar sinistro
                              </Typography>
                            </Box>
                          </Grid>
                        )}

                        {/* -------------------------------------------------------------------------- */}
                        {/*           PARA CANCELAR VENDAS, O STATUS DEVE SER DIFERENTE DE 4           */}
                        {/* -------------------------------------------------------------------------- */}
                        {rowData.purchase.status !== 4 && (
                          <Grid spacing={1} container item xs={12}>
                            <Box
                              onClick={() => {
                                if (moment(new Date()).diff(rowData.dt_inicio, 'days') > 30) {
                                  this.props.parent.setState({
                                    cancelTransaction30Days: { ...this.props.parent.state.cancelTransaction30Days, open: true },
                                    selectedPurchase: [rowData],
                                  })

                                  return
                                }
                                this.props.parent.setState({
                                  cancel: { ...this.props.parent.state.cancel, open: true },
                                  selectedPurchase: [rowData],
                                })
                                this.props.parent.fetchData()
                              }}
                              m={1}
                              style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                            >
                              <Fab style={{ minWidth: 40 }} size="small" color="primary">
                                <CancelRounded />
                              </Fab>
                              <Typography component="span" variant="subtitle1" style={{ marginLeft: 16, cursor: 'pointer' }}>
                                Cancelar transação
                              </Typography>
                            </Box>
                          </Grid>
                        )}
                        {rowData.purchase.payment_method !== 'credit_card' && (
                          <Grid spacing={1} container item xs={12}>
                            <Box
                              onClick={() => {
                                this.props.parent.setState({
                                  sendReceipt: { ...this.props.parent.state.sendReceipt, open: true },
                                  selectedPurchase: [rowData],
                                })
                              }}
                              m={1}
                              style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                            >
                              <Fab style={{ minWidth: 40 }} size="small" color="primary">
                                <ReceiptRounded />
                              </Fab>
                              <Typography component="span" variant="subtitle1" style={{ marginLeft: 16, cursor: 'pointer' }}>
                                Enviar comprovante de pagamento
                              </Typography>
                            </Box>
                          </Grid>
                        )}

                        {rowData.purchase.contract_status === 'signed' && (
                          <Grid spacing={1} container item xs={12}>
                            <Box
                              onClick={async () => {
                                const getDownloadUrl = await ApiInstance.get(`downloadContract/${rowData.purchase.document_key}`)

                                window.open(getDownloadUrl.data.signed_file_url, '_blank')
                              }}
                              m={1}
                              style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                            >
                              <Fab style={{ minWidth: 40 }} size="small" color="primary">
                                <GetApp />
                              </Fab>
                              <Typography component="span" variant="subtitle1" style={{ marginLeft: 16, cursor: 'pointer' }}>
                                Baixar contrato Locatário/Ucred
                              </Typography>
                            </Box>
                          </Grid>
                        )}

                        <Grid spacing={1} container item xs={12}>
                          <Box
                            onClick={async () => {
                              const { purchase } = await ApiInstanceV2.get(`/purchase/${rowData.purchase.id}`)
                              await this.props.parent.setState({ purchase })

                              window.print()
                            }}
                            m={1}
                            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                          >
                            <Fab style={{ minWidth: 40 }} size="small" color="primary">
                              <Print />
                            </Fab>
                            <Typography component="span" variant="subtitle1" style={{ marginLeft: 16, cursor: 'pointer' }}>
                              Resumo da transação
                            </Typography>
                          </Box>
                        </Grid>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )
        }}
        onRowClick={(event, rowData, togglePanel) => {
          togglePanel()
          this.props.parent.SalesDetail.setState({ switchState: 0, document_key: rowData.purchase.document_key })
        }}
        title="Relatório de transações"
        localization={{
          header: {
            actions: 'Ações',
          },
          body: {
            emptyDataSourceMessage: 'Nenhum registro para exibir',
          },
          toolbar: {
            exportTitle: 'Exportar',
            searchTooltip: 'Pesquisar',
            searchPlaceholder: 'Pesquisar',
            exportCSVName: 'Exportar em CSV',
            exportPDFName: 'Exportar em PDF',
          },
          pagination: {
            labelRowsSelect: 'linhas',
            labelDisplayedRows: '{from}-{to} de {count}',
            firstTooltip: 'Primeira página',
            previousTooltip: 'Página anterior',
            nextTooltip: 'Próxima página',
            lastTooltip: 'Última página',
          },
        }}
      />
    )
  }
}

export default withSnackbar(TransactionsTable)
