/**
 * @deprecated Utilize a função vindiErrorMessageByCode ao invés desta
 */

export default function formatCode(message, twoCards = false) {
  let errorMessage = message.replace('ERRO_VINDI-', '').replace('-LOCATÁRIO', '').replace('-CORRESPONSÁVEL', '').split('|')

  if (String(message).match('CORRESPONSÁVEL') && twoCards) {
    switch (errorMessage[1]) {
      case '57':
        errorMessage[0] = 'Segundo cartão: Transação recusada, entre em contato com o banco emissor do cartão para regularizar.'
        break
      case '78':
        errorMessage[0] = 'Segundo cartão: Cartão não foi desbloqueado pelo portador.'
        break
      case 'N7':
        errorMessage[0] = 'Segundo cartão: Código de segurança inválido.'
        break
      case 'null':
        errorMessage[0] = 'Segundo cartão: Erro de comunicação com o gateway, aguarde alguns instantes e tente novamente.'
        break
      case '01':
        errorMessage[0] = 'Segundo cartão: Transação recusada, entre em contato com o banco emissor do cartão para regularizar.'
        break
      case '04':
        errorMessage[0] = 'Segundo cartão: Transação recusada, entre em contato com o banco emissor do cartão para regularizar.'
        break
      case '05':
        errorMessage[0] = 'Segundo cartão: Transação recusada, entre em contato com o banco emissor do cartão para regularizar.'
        break
      case '51':
        errorMessage[0] = 'Segundo cartão: Limite do cartão insuficiente.'
        break
      case '54':
        errorMessage[0] = 'Segundo cartão: Cartão vencido.'
        break
      case '105':
        errorMessage[0] = 'Segundo cartão: Transação recusada, entre em contato com o banco emissor do cartão para regularizar.'
        break
      case '106':
        errorMessage[0] = 'Segundo cartão: Erro ao processar a transação, tente novamente.'
        break
      case '107':
        errorMessage[0] = 'Segundo cartão: Transação recusada, entre em contato com o banco emissor do cartão para regularizar.'
        break
      case '112':
        errorMessage[0] = 'Segundo cartão: Data de validade expirada.'
        break
      case '111':
        errorMessage[0] = 'Segundo cartão: Limite do cartão insuficiente.'
        break
      case '113':
        errorMessage[0] = 'Segundo cartão: Transação bloqueada pelo emissor, entre em contato com o banco responsável pelo emissor do cartão para regularizar.'
        break
      default:
        break
    }
    return errorMessage[0]
  }
  
  if (String(message).match('LOCATÁRIO') && twoCards) {
    switch (errorMessage[1]) {
      case '57':
        errorMessage[0] = 'Primeiro cartão: Transação recusada, entre em contato com o banco emissor do cartão para regularizar.'
        break
      case '78':
        errorMessage[0] = 'Primeiro cartão: Cartão não foi desbloqueado pelo portador.'
        break
      case 'N7':
        errorMessage[0] = 'Primeiro cartão: Código de segurança inválido.'
        break
      case 'null':
        errorMessage[0] = 'Primeiro cartão: Erro de comunicação com o gateway, aguarde alguns instantes e tente novamente.'
        break
      case '01':
        errorMessage[0] = 'Primeiro cartão: Transação recusada, entre em contato com o banco emissor do cartão para regularizar.'
        break
      case '04':
        errorMessage[0] = 'Primeiro cartão: Transação recusada, entre em contato com o banco emissor do cartão para regularizar.'
        break
      case '05':
        errorMessage[0] = 'Primeiro cartão: Transação recusada, entre em contato com o banco emissor do cartão para regularizar.'
        break
      case '51':
        errorMessage[0] = 'Primeiro cartão: Limite do cartão insuficiente.'
        break
      case '54':
        errorMessage[0] = 'Primeiro cartão: Cartão vencido.'
        break
      case '105':
        errorMessage[0] = 'Primeiro cartão: Transação recusada, entre em contato com o banco emissor do cartão para regularizar.'
        break
      case '106':
        errorMessage[0] = 'Primeiro cartão: Erro ao processar a transação, tente novamente.'
        break
      case '107':
        errorMessage[0] = 'Primeiro cartão: Transação recusada, entre em contato com o banco emissor do cartão para regularizar.'
        break
      case '112':
        errorMessage[0] = 'Primeiro cartão: Data de validade expirada.'
        break
      case '111':
        errorMessage[0] = 'Primeiro cartão: Limite do cartão insuficiente.'
        break
      case '113':
        errorMessage[0] = 'Primeiro cartão: Transação bloqueada pelo emissor, entre em contato com o banco responsável pelo emissor do cartão para regularizar.'
        break
      default:
        break
    }
    return errorMessage[0]
  }

  switch (errorMessage[1]) {
    case '57':
      errorMessage[0] = 'Transação recusada, entre em contato com o banco emissor do cartão para regularizar.'
      break
    case '78':
      errorMessage[0] = 'Cartão não foi desbloqueado pelo portador.'
      break
    case 'N7':
      errorMessage[0] = 'Código de segurança inválido.'
      break
    case 'null':
      errorMessage[0] = 'Erro de comunicação com o gateway, aguarde alguns instantes e tente novamente.'
      break
    case '01':
      errorMessage[0] = 'Transação recusada, entre em contato com o banco emissor do cartão para regularizar.'
      break
    case '04':
      errorMessage[0] = 'Transação recusada, entre em contato com o banco emissor do cartão para regularizar.'
      break
    case '05':
      errorMessage[0] = 'Transação recusada, entre em contato com o banco emissor do cartão para regularizar.'
      break
    case '51':
      errorMessage[0] = 'Limite do cartão insuficiente.'
      break
    case '54':
      errorMessage[0] = 'Cartão vencido.'
      break
    case '105':
      errorMessage[0] = 'Transação recusada, entre em contato com o banco emissor do cartão para regularizar.'
      break
    case '106':
      errorMessage[0] = 'Erro ao processar a transação, tente novamente.'
      break
    case '107':
      errorMessage[0] = 'Transação recusada, entre em contato com o banco emissor do cartão para regularizar.'
      break
    case '112':
      errorMessage[0] = 'Data de validade expirada.'
      break
    case '111':
      errorMessage[0] = 'Limite do cartão insuficiente.'
      break
    case '113':
      errorMessage[0] = 'Transação bloqueada pelo emissor, entre em contato com o banco responsável pelo emissor do cartão para regularizar.'
      break
    default:
      break
  }
  return errorMessage[0]
}
