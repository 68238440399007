import { Button } from '@material-ui/core'
import { SnackbarProvider } from 'notistack'
import { Flex, Box } from '@chakra-ui/react'
import Footer from './layout/Footer'
import AppRouter from './pages/Routes'

function App() {
  return (
    <SnackbarProvider
      action={() => (
        <Button
          style={{
            height: '100%',
            left: 0,
            position: 'absolute',
            top: 0,
            width: '100%',
          }}
          styleType="link"
        />
      )}
      maxSnack={3}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={8000}
    >
      <Flex h="100vh" direction="column" justify="space-between">
        <AppRouter />
        <Footer />
      </Flex>
    </SnackbarProvider>
  )
}

export default App
