import React, { Component } from 'react'
import Input from '../../components/input'
import Button from '../../components/button'
import ApiInstance from '../../services/ApiInstance'
import { removeMask } from '../../util/money'
import { withSnackbar } from 'notistack'
import StepWizard from 'react-step-wizard'
import moment from 'moment'

const Login = (props) => {
  const nextStep = () => {
    props.go(props)
  }
  const { loginResale } = props
  return (
    <>
      <div className="login">
        <h1>Faça seu login</h1>
        <div className="login-item">
          <Input type="text" placeholder="E-mail" labelDescription="E-mail" id="email" value={loginResale.email} onChange={props.handleChange} />
        </div>
        <div className="login-item">
          <Input type="password" placeholder="Senha" labelDescription="Senha" id="password" value={loginResale.password} onChange={props.handleChange} />
        </div>
        <div className="login-button">
          {!props.loading && <Button description="LogIn" onClick={nextStep} />}
          {props.loading && <div className="loader" />}
        </div>
      </div>
    </>
  )
}

const DadosUsuario = (props) => {
  return (
    <div className="dados-usuario">
      <div className="header">
        <h3>Dados do Revendedor</h3>
      </div>
      <div className="body">
        <div className="line">
          <div className="item">
            <Input type="text" placeholder="Nome completo" labelDescription="Nome completo" id="user_name" onChange={props.handleChange} value={props.user.user_name} />
          </div>
          <div className="item">
            <Input type="text" mask="111.111.111-11" placeholder="CPF" labelDescription="CPF" id="cpf" onChange={props.handleChange} iconType="number" value={props.user.cpf} />
          </div>
        </div>
        <div className="line">
          <div className="item">
            <Input type="text" placeholder="E-mail" labelDescription="E-mail" id="email" onChange={props.handleChange} iconType="email" value={props.user.email} />
          </div>
          <div className="item">
            <div className="container-password">
              <Input type={props.type} placeholder="Senha" labelDescription="Senha" id="password" onChange={props.handleChange} iconType="password" value={props.user.password} />
            </div>
          </div>
          <div className="item">
            <div className="container-password">
              <Input
                type={props.type}
                placeholder="Confirmar senha"
                labelDescription="Confirmar senha"
                id="confirmPassword"
                onChange={props.handleChange}
                iconType="password"
                value={props.user.confirmPassword}
              />
              <i className="lni-eye" onClick={props.showPassword}></i>
            </div>
          </div>
        </div>
        {/* MARRETA */}
        <div className="line">
          <div className="item">
            <Input
              type="text"
              labelDescription="Data de nascimento"
              placeholder="Data de nascimento"
              iconType="number"
              id="birthday"
              mask="11/11/1111"
              onChange={props.handleChange}
              value={props.user.birthday}
            />
          </div>

          <div className="item">
            <Input
              type="text"
              mask="(11) 11111-1111"
              placeholder="(DD) XXXXX-XXXX"
              labelDescription="Telefone celular"
              id="telephone"
              onChange={props.handleChange}
              iconType="number"
              value={props.user.telephone}
            />
          </div>
        </div>

        <div className="progress-container">
          {!props.loading && <Button description="Salvar" onClick={() => props.go(props)} />}
          {props.loading && <div className="loader" />}
        </div>
      </div>
    </div>
  )
}

class NovoRevendedor extends Component {
  state = {
    type: 'password',
    loading: false,
    loginResale: {
      email: null,
      password: null,
    },
    user: {
      user_name: null,
      cpf: null,
      email: null,
      password: null,
      confirmPassword: null,
      telephone: null,
      birthday: null,
    },
  }

  handleChangeLogin = (event) => {
    const { id, value } = event.currentTarget
    const { loginResale } = this.state
    this.setState({ loginResale: { ...loginResale, [id]: value } })
  }

  handleChangeUser = (event) => {
    const { id, value } = event.currentTarget
    const { user } = this.state
    this.setState({ user: { ...user, [id]: value } })
  }

  setLoading = (value) => {
    this.setState({ loading: value })
  }

  validarCPF = (cpf) => {
    let soma = 0
    let resto = 0
    let i = 0
    let j = 0

    cpf = cpf.replace('.', '')
    cpf = cpf.replace('.', '')
    cpf = cpf.replace('-', '')

    if (
      cpf === '00000000000' ||
      cpf === '11111111111' ||
      cpf === '22222222222' ||
      cpf === '33333333333' ||
      cpf === '44444444444' ||
      cpf === '55555555555' ||
      cpf === '66666666666' ||
      cpf === '77777777777' ||
      cpf === '88888888888' ||
      cpf === '99999999999'
    ) {
      throw new Error('CPF Inválido')
    }

    for (i = 1; i <= 9; i++) {
      soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i)
    }

    resto = (soma * 10) % 11

    if (resto === 10 || resto === 11) {
      resto = 0
    }

    if (resto !== parseInt(cpf.substring(9, 10))) {
      throw new Error('CPF Inválido')
    }

    soma = 0

    for (j = 1; j <= 10; j++) {
      soma = soma + parseInt(cpf.substring(j - 1, j)) * (12 - j)
    }

    resto = (soma * 10) % 11

    if (resto === 10 || resto === 11) {
      resto = 0
    }

    if (resto !== parseInt(cpf.substring(10, 11))) {
      throw new Error('CPF Inválido')
    }

    return true
  }

  save = async () => {
    try {
      this.setLoading(true)
      const { user } = this.state
      user.name = user.user_name
      await ApiInstance.post('registerReseller', { user })
      this.setLoading(false)
      const snackbarKey = this.props.enqueueSnackbar('Criado com sucesso.', {
        variant: 'success',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
      this.props.history.push('/')
    } catch (e) {
      this.setLoading(false)
      const snackbarKey = this.props.enqueueSnackbar(e.message, {
        variant: 'error',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })

      return
    }
  }

  avanca = async (props) => {
    try {
      const validated = await this.verifyLogin()
      if (validated) {
        document.getElementById('card').style.width = '90%'
        document.getElementById('card').style.height = '50%'
        props.nextStep()
      }
    } catch (e) {
      const snackbarKey = this.props.enqueueSnackbar(e.message, {
        variant: 'error',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
    }
  }

  avancaUsu = () => {
    try {
      this.verifyUser()
      this.validarCPF(this.state.user.cpf)
      this.save()
    } catch (e) {
      const snackbarKey = this.props.enqueueSnackbar(e.message, {
        variant: 'error',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
    }
  }

  verifyLogin = async () => {
    try {
      let { loginResale } = this.state
      if (!loginResale.email) throw new Error('Informe o email.')
      if (!loginResale.password) throw new Error('Informe a senha.')
      const logged = await ApiInstance.post('loginRegisterResale', { email: loginResale.email, password: loginResale.password })
      if (!logged) throw new Error('Email ou senha inválido.')
      return true
    } catch (error) {
      const snackbarKey = this.props.enqueueSnackbar(error.message, {
        variant: 'error',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
      return false
    }
  }

  verifyUser = () => {
    let { user } = this.state
    const REQUIRED_FIELD = ['user_name', 'cpf', 'email', 'password', 'birthday', 'telephone']
    if (user.user_name) {
      const userName = user.user_name
      let userFullName = userName.split(' ')
      if (!userFullName[1]) {
        throw new Error('Informe nome e sobrenome.')
      }
    }
    if (user.birthday) {
      const birthdayArr = user.birthday.split('/')
      const userBirthday = birthdayArr[2]
      const yearActual = moment().format('YYYY')
      const age = Number(yearActual) - Number(userBirthday)
      if (birthdayArr[0] === 0 || birthdayArr[0] > 31) {
        throw new Error('Dia Inválido.')
      }
      if (birthdayArr[1] === 0 || birthdayArr[1] > 12) {
        throw new Error('Mês Inválido.')
      }
      if (age < 18 || age >= 110) {
        throw new Error('Tem que possuir 18 anos.')
      }
    }
    if (user.telephone) {
      const userTelephone = removeMask(user.telephone)
      if (userTelephone.length < 14) {
        throw new Error('Informe o telefone.')
      }
    }
    if (document.getElementsByClassName('error')[0]) document.getElementsByClassName('error')[0].classList.remove('error')
    REQUIRED_FIELD.map((field) => {
      if (!user[field]) {
        document.getElementById(field).focus()
        document.getElementById(field).classList.add('error')
        throw new Error('Por favor, preencha todos os campos.')
      }
      return null
    })

    if (!user.confirmPassword) {
      throw new Error('Confirme a senha.')
    } else if (user.password !== user.confirmPassword) {
      throw new Error('Senhas não conferem.')
    }
  }

  showPassword = () => {
    const { type } = this.state
    let newType = null
    if (type === 'password') newType = 'text'
    if (type === 'text') newType = 'password'
    this.setState({ type: newType })
  }

  render() {
    return (
      <div className="novo-revendedor">
        <div className="card" id="card">
          <div className="step-container">
            <StepWizard>
              <Login handleChange={this.handleChangeLogin} go={this.avanca} loginResale={this.state.loginResale} loading={this.state.loading} />
              <DadosUsuario
                loading={this.state.loading}
                setLoading={this.setLoading}
                user={this.state.user}
                handleChange={this.handleChangeUser}
                go={this.avancaUsu}
                type={this.state.type}
                showPassword={this.showPassword}
              />
            </StepWizard>
          </div>
        </div>
      </div>
    )
  }
}

export default withSnackbar(NovoRevendedor)
