import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import * as Sentry from '@sentry/browser'

import { ChakraProvider } from '@chakra-ui/react'
import { chakraTheme } from './styles/chakraTheme'

import { ThemeProvider as MaterialProvider } from '@material-ui/core/styles'
import { materialTheme } from './styles/materialTheme'

import App from './App'
import { store } from './redux/store'
import * as serviceWorker from './serviceWorker'
import './styles/sass/index.scss'

Sentry.init({ dsn: 'https://cf248512caac442ab90cf133e309e226@app.glitchtip.com/1779', environment: 'production' })

ReactDOM.render(
  <Provider store={store}>
    <ChakraProvider theme={chakraTheme}>
      <MaterialProvider theme={materialTheme}>
        <App />
      </MaterialProvider>
    </ChakraProvider>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
