
import React, { useState } from 'react';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css'

  const MousewheelControl = (props) => {
    const [swiper, updateSwiper] = useState(null);
    const params = {
      direction: 'vertical',
      slidesPerView: 1,
      spaceBetween: 30,
      mousewheel: true,
      containerClass: 'customized-swiper-container'
    }

    const goNext = () => {
        if (swiper !== null) {
          swiper.slideNext();
        }
      };
      const goPrev = () => {
        if (swiper !== null) {
          swiper.slidePrev();
        }
      };

      const calcValue = (service) => {
        let transactionValue = props.transactionValue.split('.').join('').replace(',','.')
        
        const TAXA_FINAL = Number(service.administration_fee) + Number(service.over_value)
        // Encontra o valor da mensalidade
        let value = Number(transactionValue) * (TAXA_FINAL/ 100)
        // Multiplica pelo tempo de contrato
        value = value * Number(service.contract_time)
        // Adiciona taxa de adesao
        value = value + Number(service.adhesion_fee)
        return value.toFixed(2)
      }
    
      const select = (indice, service) => {
          let totalValue = calcValue(service)
          props.selectedProduct(indice, totalValue)
      }
      return (
      <React.Fragment>
        { props.services.length ? 
        <Swiper {...params} getSwiper={updateSwiper}>
            {props.services.map((service, indice) => 
                <div className="box" key={indice}>
                    <div className="container-price">
                        <i className="lni-home"></i>
                        <div className="fullPrice">
                            <span>R$ {calcValue(service)} </span>
                        </div>
                    </div>
                    <div className={props.indice == indice ? "container-body-active": "container-body"}>
                        <div className="product-title">
                            { service.name }
                        </div>
                        <div className="data">
                            <div className="item">
                                {/* <div className="data-values">
                                    <div className="label">Tipo de garantia:</div>
                                    <div className="value">
                                        { service.warranty_ucred == 1 && "Ucred" } 
                                        { service.warranty_ucred != 1 && "Propria" } 
                                    </div>
                                </div> */}
                                { Number(props.plan_id) === 3 &&  
                                <div className="data-values">
                                    <div className="label">Valor de cobertura:</div>
                                    <div className="value">R$ {String(Number(service.valor_cobertura).toFixed(2)).replace('.', ',')}</div>
                                </div> }
                                {/* { Number(props.plan_id) === 2 &&  <div className="data-values">
                                    <div className="label">Taxa administrativa:</div>
                                    <div className="value">{service.administration_fee} %</div>
                                </div> }
                                { Number(props.plan_id) === 2 && <div className="data-values">
                                    <div className="label">Comissão:</div>
                                     <div className="value">{service.imob_gain} %</div> 
                                </div> } */}
                            </div>
                            <div className="item">
                                <div className="data-values">
                                    <div className="label">Tipo de garantia:</div>
                                    <div className="value">
                                        { service.warranty_ucred == 1 && "Ucred" } 
                                        { service.warranty_ucred != 1 && "Propria" } 
                                    </div>
                                </div>
                                {Number(props.plan_id) === 1 ?
                                <div className="data-values">
                                    <div className="label">Taxa administrativa:</div>
                                    <div className="value">{service.administration_fee} %</div>
                                </div>
                                : 
                                <>
                                    <div className="data-values">
                                        <div className="label">Taxa locatário:</div>
                                        <div className="value">{service.over_value + service.administration_fee} %</div>
                                    </div>   
                                    <div className="data-values">
                                        <div className="label">Cobertura Ucred:</div>
                                        <div className="value">{service.cover_value} vezes</div>
                                    </div>
                                </>                           
                                }
                            </div>
                        </div>
                        <div className="footer">
                            <span className={props.indice == indice ? "btn-active": ""} onClick={ () =>{ select(indice, service) }} >CONTRATAR</span>
                        </div>
                    </div>
                </div> )}
            </Swiper> 
            : null }
        
        { props.services.length > 1 && 
        <React.Fragment>
            <i className="next-prod lni-chevron-up"  onClick={goPrev}></i>
            <i className="prev-prod lni-chevron-down" onClick={goNext}></i>
        </React.Fragment> }
        
      </React.Fragment> 
    )
  };
  
  export default MousewheelControl;
