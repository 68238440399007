import React from 'react'
import Header from '../../layout/Header'
import api from '../../services/ApiInstance'
import Button from '../../components/button'
import { withSnackbar } from 'notistack'
import moment from 'moment'
class Avisos extends React.Component {
  state = {
    data: [],
  }
  componentDidMount() {
    this.loadData()
  }
  prepareData = (row) => ({
    id: row.id,
    message: row.message,
    name: row.name,
    type: row.type === 1 ? '#32CD32' : '#FF6347',
    created_at: moment(row.created_at).format('DD/MM/YYYY HH:mm'),
  })

  loadData = async () => {
    try {
      const { data: notifications } = await api.get(`aviso`)
      this.setState({ data: notifications.map(this.prepareData) })
    } catch (error) {
      const snackbarKey = this.props.enqueueSnackbar(error.message, {
        variant: 'error',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
    }
    this.setState({ loading: false })
  }
  render() {
    const { data } = this.state
    return (
      <React.Fragment>
        <Header />
        <div className="avisos">
          <div className="avisos__container">
            <div className="avisos__container-header">
              <h1> Central de Avisos </h1>
            </div>
            <div className="avisos__container-body">
              <div className="container-new">
                <Button description="Novo Aviso" />
              </div>
              <div className="avisos-timeline">
                <div className="aviso aviso-header">
                  <div className="aviso-date">
                    <p>
                      <b>DATA</b>
                    </p>
                  </div>
                  <div className="aviso-body">
                    <p>
                      <b>Conteúdo</b>
                    </p>
                  </div>
                </div>

                {data.map((coluna, key) => (
                  <div className="aviso" key={key}>
                    <div className="aviso-date">
                      <p>
                        <b>Criado Por: </b>
                        {coluna.name}
                        <span></span>
                      </p>
                      <p>
                        <b>Criado Em: </b> <span>{coluna.created_at}</span>
                      </p>
                    </div>
                    <div className="aviso-body">
                      <p>{coluna.message}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withSnackbar(Avisos)
