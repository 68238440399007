import styles from '../styles/defaultStyles'

type GetInvoiceStatusType = {
  title: 'Requisitado' | 'Recusado' | 'Falha' | 'Pago' | 'Cancelado' | 'Error'
  color: 'blue' | 'orange' | 'red' | 'green' | 'gray'
  background: string
}

export function getInvoiceStatus(status: number): GetInvoiceStatusType {
  switch (status) {
    case 1:
      return { title: 'Requisitado', color: 'blue', background: styles.info }
    case 2:
      return { title: 'Recusado', color: 'orange', background: styles.critical }
    case 3:
      return { title: 'Falha', color: 'red', background: styles.error }
    case 4:
      return { title: 'Pago', color: 'green', background: styles.success }
    case 5:
      return { title: 'Cancelado', color: 'gray', background: styles.critical }
    default:
      return { title: 'Error', color: 'red', background: styles.critical }
  }
}
