import React from 'react'
import { CSVLink } from 'react-csv'

class table extends React.Component {
  state = {
    open: false,
    search: '',
    currentPage: 20,
    initialPage: 0,
    pages: [],
    indiceFilter: null,
    numberLines: 0,
    datas: [],
    headers: [],
  }

  UNSAFE_componentWillReceiveProps(props) {
    let pages = []
    let countPaginate = props.linhas.length / 20
    countPaginate = countPaginate % 20 > 0 ? countPaginate++ : countPaginate
    for (let i = 0; i <= countPaginate; i++) {
      pages.push(i)
    }
    this.setState({ pages, numberLines: props.linhas.length })
  }

  updateSearch(event, key) {
    const value = event.target.value ? event.target.value.substr(0, 20) : ''
    let keyValue = key
    if (value == '') keyValue = null

    this.setState({ search: value, indiceFilter: keyValue })
  }

  updatePage = (page, event) => {
    this.setState({ currentPage: page * 20, initialPage: page * 20 - 20 })
    let active = document.querySelector('.pagination .active')
    active.classList.remove('active')
    event.currentTarget.classList.add('active')
  }

  export = async () => {
    const { columnsExport } = this.props
    let columnsExportLabel = []
    await columnsExport.map((column) => {
      columnsExportLabel.push({ label: column.name, key: column.key })
    })
    const items = this.props.linhas
    this.setState({ headers: columnsExportLabel, datas: items })
  }

  render() {
    const { currentPage, initialPage, indiceFilter, numberLines, datas, headers, search } = this.state
    let linesPage = indiceFilter ? this.props.linhas : this.props.linhas.slice(initialPage, currentPage)
    let filteredLinhas = this.props.linhas
      ? linesPage.filter((linha) => {
          return indiceFilter ? linha[indiceFilter].toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1 : linha
        })
      : []
    return (
      <React.Fragment>
        {this.props.export && (
          <div className="export-container">
            <CSVLink onClick={this.export} separator=";" data={datas} headers={headers} filename={'clientes.csv'} className="btn-export" target="_blank">
              Exportar <i className="lni-download"></i>
            </CSVLink>
          </div>
        )}
        <div className="responsive_table">
          <table className="table" id="table">
            <thead>
              <tr>
                {this.props.columns.map((coluna, key) => coluna.visible && <th key={key}>{coluna.name}</th>)}
                {this.props.remove && <th>Remover</th>}
              </tr>
            </thead>
            <tbody>
              {
                // Filtros
                <tr className="table__line-filter">
                  {this.props.columns.map(
                    (coluna, key) =>
                      coluna.visible && (
                        <td key={key}>
                          <div className="table__filter-container">
                            <input type="text" className="table__filter" placeholder={coluna.name} onChange={(event) => this.updateSearch(event, coluna.key)} />
                            <i className="lni-search"></i>
                          </div>
                        </td>
                      )
                  )}
                  {this.props.remove && <span></span>}
                </tr>
              }
              {filteredLinhas.map((linha, indice) => (
                <tr key={indice}>
                  {this.props.columns.map((coluna, key) => {
                    if (!linha.warranty_ucred && coluna.visible)
                      return (
                        <td key={key} onClick={() => this.props.onClickRow(linha)}>
                          {' '}
                          {linha[coluna.key]}
                        </td>
                      )
                    else if (coluna.visible) {
                      if (!Number.isInteger(linha.warranty_ucred)) {
                        if (!linha.warranty_ucred.includes('Ucred'))
                          return (
                            <td key={key} onClick={() => this.props.onClickRow(linha)}>
                              {' '}
                              {linha[coluna.key]}
                            </td>
                          )
                      }
                      return (
                        <td style={{ fontWeight: 'bold' }} key={key} onClick={() => this.props.onClickRow(linha)}>
                          {' '}
                          {linha[coluna.key]}
                        </td>
                      )
                    }
                  })}
                  {this.props.remove && (
                    <td className="line-delete" onClick={(event) => this.props.handleRemove(linha, event)}>
                      {linha.name != 'Ucred Comercial' ? (
                        <div className="btn-delete">
                          <span>Deletar</span>
                        </div>
                      ) : (
                        <div className="btn-delete disabled">
                          <span>Deletar</span>
                        </div>
                      )}
                    </td>
                  )}
                </tr>
              ))}
              {filteredLinhas.length === 0 && (
                <tr className="table__not-result">
                  <td colSpan="100%">
                    <h3>Não foi possível encontrar resultados</h3>
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <tr>Número de registros: {numberLines}</tr>

          {this.props.linhas.length > 20 && !search && (
            <div className="pagination">
              <a>&laquo;</a>
              {this.state.pages.map((pagina, indice) => (
                <a key={indice} className={indice === 0 ? 'active' : null} onClick={(event) => this.updatePage(indice + 1, event)}>
                  {indice + 1}
                </a>
              ))}
              <a>&raquo;</a>
            </div>
          )}
        </div>
      </React.Fragment>
    )
  }
}

export default table
