import {
  Backdrop,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  Slide,
  Snackbar,
  TextField,
  Typography,
  Tooltip,
  MenuItem,
  Select,
  InputLabel,
  IconButton,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import Lottie from 'lottie-react-web'
import moment from 'moment'
import React, { Component } from 'react'
import ReactCreditCard from 'react-credit-cards'
import MaskedInput from 'react-input-mask'
import ApiInstance from '../../services/ApiInstance'
import HouseLoading from '../../static/animations/house-loading.json'
import BankSlipImg from '../../static/images/boleto.png'
import HybridImg from '../../static/images/card_bank_slip.png'
import CreditCardImg from '../../static/images/credit.png'
import CreditCard from './credit_card'
import BankSlip from './bank_slip'
import Hybrid from './hybrid'
import Recurrence from './recurrence'
import Error from './error'
import { Close, Help } from '@material-ui/icons'
import Seal from '../../static/images/seal_image.png'

// RESPONSAVEL PELA TRANSICAO DO MODAL AO SELECIONAR BOLETO E CLICAR EM CONTINUAR
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const moneyToNumber = value => {
  return Number(
    String(value)
      .replace(',', '.')
      .replace(/([^0-9.])/g, ''),
  )
}

export default class VendaOnline extends Component {
  state = {
    company_id: null,
    step: 0,
    twoCards: false,
    loading: true,
    error: false,
    errorObject: {},
    hash: '',
    payment_method: 'credit_card',

    adhesion: [],
    adhesion_parcel_options: [],
    adhesion_selected_option: 0, // POSICAO DO ARRAY adhesion_parcel_options

    purchase: {},
    purchase_parcel_options: [],
    purchase_selected_option: 11, // POSICAO DO ARRAY purchase_parcel_options

    bank_slip_parcel_options: [],

    bank_slip_fee: 0,
    bank_slip_parcel: 1,
    card: { name: '' },
    corresponsible_purchase: {},
    corresponsible_purchase_parcel_options: [],
    corresponsible_purchase_selected_option: 0, // POSICAO DO ARRAY corresponsible_purchase_parcel_options

    snackbar: { message: '', severity: '', duration: 6000, open: false },
  }

  async componentDidMount() {
    const script = document.createElement('script')
    script.src = 'https://seal.securetrust.com/seal.js?style=invert'
    script.async = true
    document.body.appendChild(script)

    const urlParams = new URLSearchParams(window.location.search)
    const hash = urlParams.get('hash')
    const twoCards = urlParams.get('twoCards')
    this.setState({ payment_method: twoCards === 'true' ? 'credit_card' : 'recurrence' })

    if (twoCards !== 'true' && twoCards !== 'false') this.setState({ error: true, loading: false })

    const response = await ApiInstance.get(`vendaOnline?hash=${hash}&twoCards=${twoCards}`)
      .then(response => response)
      .catch(error => error)

    if (response.status > 299 || response.status < 200)
      return this.setState({ error: true, loading: false, errorObject: response })

    const {
      bank_slip_fee,
      adhesion,
      card,
      purchaseData: purchase,
      purchaseDataCorresp: corresponsible_purchase,
      company_id,
    } = response

    const adhesion_parcel_options = this.getAdhesionParcelOptions(
      moneyToNumber(adhesion[0] ? adhesion[0].value : 0),
    )

    const purchase_parcel_options = this.getPurchaseParcelOptions(
      moneyToNumber(purchase.value),
      moneyToNumber(corresponsible_purchase.value),
    )

    const bank_slip_parcel_options = this.getPurchaseParcelOptions(
      moneyToNumber(purchase.value) +
        moneyToNumber(adhesion[0] ? adhesion[0].value : 0) +
        moneyToNumber(corresponsible_purchase.value),
      0,
      4,
    )

    this.payment_method.setState({
      credit_card: {
        name: card.name,
        number: '',
        expiry: '',
        cvv: '',
        card_maturity: '',
        focused: false,
        errors: {
          number: '',
          expiry: '',
          cvv: '',
          card_maturity: '',
        },
      },
    })

    this.setState({
      twoCards,
      hash,
      bank_slip_fee,

      adhesion,
      adhesion_parcel_options,

      purchase,
      purchase_parcel_options,

      bank_slip_parcel_options,

      card,
      corresponsible_purchase,
      loading: false,
      company_id,
    })
  }

  getAdhesionParcelOptions(value = 0) {
    let count = 1
    const options = []

    while (count < 4) {
      options.push({
        label: `${count}X de R$ ${Number(value / count)
          .toFixed(2)
          .replace('.', ',')}`,
        value: value / count,
        quantity: count,
      })
      count++
    }
    return options
  }

  getPurchaseParcelOptions(value, correspValue = 0, totalParcel = 13) {
    let count = 1
    const parcels = []

    while (count < totalParcel) {
      parcels.push({
        label: `${count}X de R$ ${Number(value / count)
          .toFixed(2)
          .replace('.', ',')}`,

        label_corresp: `${count}X de R$ ${Number(correspValue / count)
          .toFixed(2)
          .replace('.', ',')}`,
        value: value / count,
        valueCorresp: correspValue / count,
        quantity: count,
      })

      count++
    }
    return parcels
  }

  validateDate(date) {
    if (moment(new Date(), 'MM/YY').add(12, 'months').isSameOrAfter(moment(date, 'MM/YY'))) {
      return true
    }
    return false
  }

  render() {
    const {
      adhesion: adhesionObject,
      purchase,
      card,
      step,
      payment_method,
      loading,
      snackbar,
      error,
      errorObject,
    } = this.state

    return (
      <div id="online_purchase" style={{ backgroundImage: 'url(https://res.cloudinary.com/ucred/image/upload/v1635505519/wooden-house-miniature.jpg)' }}>
        <Backdrop style={{ zIndex: 50000 }} open={loading}>
          <Lottie
            style={{ opacity: 1 }}
            width="200px"
            speed={1}
            options={{
              animationData: HouseLoading,
              autoplay: true,
              loop: true,
            }}
          ></Lottie>
        </Backdrop>
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          color="primary"
          onClose={() => this.setState({ snackbar: { ...snackbar, open: false } })}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Alert
            onClose={() => this.setState({ snackbar: { ...snackbar, open: false } })}
            severity={snackbar.severity}
            variant="filled"
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
        {error === true && (
          <Error
            bankSlipUrl={errorObject.purchase.bank_slip ? errorObject.purchase.bank_slip.print_url : ''}
            contractUrl={errorObject.contractUrl}
            paymentMethod={errorObject.purchase.payment_method}
            contractStatus={errorObject.contract.status}
            purchaseStatus={errorObject.purchase.purchase_status_id}
          />
        )}
        {step === 0 && error !== true && (
          <PaymentMethodSelect parent={this} ref={ref => (this.payment_method = ref)} />
        )}
        {step === 1 && payment_method === 'credit_card' && (
          <CreditCard parent={this} ref={ref => (this.credit_card = ref)} />
        )}
        {step === 1 && payment_method === 'bank_slip_1' && (
          <BankSlip
            parent={this}
            parcel={this.state.bank_slip_parcel}
            ref={ref => (this.bank_slip = ref)}
          />
        )}
        {step === 1 && payment_method === 'hybrid' && (
          <Hybrid parent={this} ref={ref => (this.hybrid = ref)} />
        )}
        {step === 1 && payment_method === 'recurrence' && (
          <Recurrence
            name={card.name}
            adhesion={adhesionObject}
            loading={loading}
            value={moneyToNumber(purchase.value)}
            setLoading={loading => this.setState({ loading })}
            address={{ address: purchase.address, cep: purchase.cep, city: purchase.city }}
            previousStep={() => this.setState({ step: 0 })}
            snackbar={(message: string, severity: string) => {
              this.setState({ snackbar: { message, severity, open: true } })
            }}
            ref={ref => (this.recurrence = ref)}
          />
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            position:
              (step === 1 && payment_method === 'hybrid') ||
              (step === 1 && payment_method === 'credit_card')
                ? 'fixed'
                : 'initial',
            bottom:
              (step === 1 && payment_method === 'hybrid') ||
              (step === 1 && payment_method === 'credit_card')
                ? '0'
                : 'initial',
          }}
        >
          <img
            style={{ marginTop: 16, width: '100px' }}
            src={Seal}
            alt="seal"
            onClick={() =>
              window.open(
                'https://seal.securetrust.com/cert.php?customerId=79dd70fecb9144f2a6afd9e5551917ba&size=105x54&style=invert&baseURL=',
                'c_TW',
                'location=no, toolbar=no, resizable=yes, scrollbars=yes, directories=no, status=no, width=615, height=720',
              )
            }
          ></img>
          <Typography variant="caption" style={{ color: '#fff' }}>
            Site seguro! Pagamentos processados por vindi.
          </Typography>
        </div>
      </div>
    )
  }
}

class PaymentMethodSelect extends React.Component {
  state = {
    credit_card: {
      name: '',
      number: '',
      expiry: '',
      cvv: '',
      card_maturity: '',
      focused: false,
      errors: {
        number: '',
        expiry: '',
        cvv: '',
        card_maturity: '',
      },
    },

    open: false,
    bankSlipParcelOpen: false,
    hash: '',
    twoCards: false,
  }

  componentDidMount() {
    const { card } = this.props.parent.state
    const urlParams = new URLSearchParams(window.location.search)
    const hash = urlParams.get('hash')
    const twoCards = urlParams.get('twoCards')

    this.setState({ hash, twoCards, credit_card: { ...this.state.credit_card, name: card.name } })
  }

  render() {
    const { open, credit_card, hash, loading, bankSlipParcelOpen } = this.state
    const { has_responsible } = this.props.parent.state.purchase
    const { payment_method, bank_slip_parcel_options } = this.props.parent.state

    const handleChangeCreditCard = async event => {
      let { name, value } = event.target
      const credit_card = this.state.credit_card
      const dateIsValid = await this.props.parent.validateDate(value)

      if (name === 'expiry' && dateIsValid) {
        credit_card.errors.expiry = true
        this.setState({ credit_card })
      } else {
        credit_card.errors.expiry = false
        this.setState({ credit_card })
      }

      if (name === 'card_maturity' && value > 30) {
        value = 30
      }

      if (name === 'cvv') credit_card.errors.cvv = false

      this.setState({ credit_card: { ...credit_card, [name]: value } })
    }

    const verifyCard = async () => {
      this.props.parent.setState({ loading: true })

      const expiry = moment(credit_card.expiry, 'MM/YY').format('MM/YYYY')

      await ApiInstance.post('verify_card', {
        hash: hash,
        credit_card: { ...credit_card, expiry },
      })
        .then(() => {
          this.props.parent.setState({ step: 1 })
        })
        .catch(error => {
          this.props.parent.setState({
            loading: false,
            snackbar: {
              message: error.message || 'Erro ao verificar o cartão.',
              severity: 'error',
              duration: 6000,
              open: true,
            },
          })
        })
    }

    const openBankSlipParcel = () => {
      if (
        !credit_card.number ||
        !credit_card.expiry ||
        !credit_card.card_maturity ||
        !credit_card.cvv
      ) {
        return this.props.parent.setState({
          snackbar: {
            message: 'Preencha todos os dados antes de prosseguir.',
            severity: 'error',
            duration: 6000,
            open: true,
          },
        })
      }
      this.setState({ bankSlipParcelOpen: true })
    }

    return (
      <div id="payment_method">
        <div className="container">
          <Dialog
            disableScrollLock
            open={bankSlipParcelOpen}
            TransitionComponent={Transition}
            className="dialog"
            style={{ borderRadius: '26px' }}
            onClose={() => this.setState({ bankSlipParcelOpen: false })}
          >
            <DialogTitle
              disableTypography
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography variant="h6">Parcelamento do boleto</Typography>
              <IconButton
                onClick={() => this.setState({ bankSlipParcelOpen: false })}
                size="small"
                style={{ marginLeft: 'auto' }}
              >
                <Close></Close>
              </IconButton>
            </DialogTitle>

            <Divider></Divider>

            <div className="dialog-content">
              <Typography style={{ marginBottom: 16 }} variant="body2" component="p">
                Os boletos serão encaminhados mensalmente via email, lembre-se de cumprir os prazos
                de pagamento.
              </Typography>

              <FormControl style={{ marginBottom: 8 }} fullWidth="form-modal-bank_slip-credit-card">
                <InputLabel>Selecione a quantidade de parcelas</InputLabel>
                <Select
                  value={this.props.parent.state.bank_slip_parcel}
                  onChange={event =>
                    this.props.parent.setState({ bank_slip_parcel: event.target.value })
                  }
                >
                  {bank_slip_parcel_options.map((parcel, index) => {
                    return (
                      <MenuItem key={index} fullWidth value={index + 1}>
                        {parcel.label}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>

              <Typography variant="body2" color="textSecondary" component="p">
                Primeira parcela: {moment().format('DD/MM/YYYY')}
              </Typography>

              {this.props.parent.state.bank_slip_parcel > 1 && (
                <Typography variant="body2" color="textSecondary" component="p">
                  Segunda parcela: {moment().add(1, 'month').format('DD/MM/YYYY')}
                </Typography>
              )}

              {this.props.parent.state.bank_slip_parcel > 2 && (
                <Typography variant="body2" color="textSecondary" component="p">
                  Terceira parcela: {moment().add(2, 'months').format('DD/MM/YYYY')}
                </Typography>
              )}
            </div>

            <Divider></Divider>

            <DialogActions>
              <Button
                disabled={loading}
                onClick={() => this.setState({ bankSlipParcelOpen: false })}
                color="primary"
              >
                Cancelar
              </Button>
              <Button disabled={loading} onClick={verifyCard} color="primary" variant="contained">
                Confirmar
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            disableScrollLock
            open={open}
            TransitionComponent={Transition}
            className="dialog"
            style={{ borderRadius: '26px' }}
            onClose={() => this.setState({ open: false })}
          >
            <DialogTitle
              disableTypography
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography variant="h6">Preencha seus dados</Typography>
              <IconButton
                onClick={() => {
                  this.setState({ open: false })
                }}
                size="small"
                style={{ marginLeft: 'auto' }}
              >
                <Close></Close>
              </IconButton>
            </DialogTitle>

            <Divider></Divider>

            <div className="dialog-content">
              <Typography
                variant="body"
                component="p"
                color="textSecondary"
                align="center"
                style={{ margin: '0 auto 8px' }}
              >
                Olá caro cliente, nossa garantia locatícia é atrelada ao cartão de crédito, por
                isso, precisamos dos dados de um cartão válido para que possamos concluir sua
                transação. Não será efetuada nenhuma cobrança a partir dos dados mencionados,
                somente uma verificação, visto que a opção de pagamento selecionada é boleto
                bancário.
              </Typography>
              <div className="card-form">
                <ReactCreditCard
                  preview={false}
                  name={credit_card.name}
                  number={credit_card.number}
                  expiry={credit_card.expiry}
                  cvc={credit_card.cvv}
                  focused={credit_card.focused}
                  locale={{ valid: 'Data Venc' }}
                  placeholders={{ name: 'Nome do Titular' }}
                />
                <div className="inputs">
                  <FormControl id="form-modal-bank_slip-credit-card">
                    <TextField
                      disabled
                      className="venda-online-input"
                      size="small"
                      value={credit_card.name}
                      onChange={handleChangeCreditCard}
                      name="name"
                      label="Nome"
                      placeholder="Nome"
                      fullWidth
                    />

                    <MaskedInput
                      className="venda-online-input"
                      mask="9999 9999 9999 9999"
                      name="number"
                      label="Número do cartão"
                      placeholder="Número do cartão"
                      maskChar=""
                      fullWidth
                      size="small"
                      value={credit_card.number}
                      onChange={handleChangeCreditCard}
                    >
                      {inputProps => <TextField {...inputProps} />}
                    </MaskedInput>

                    <span style={{ display: 'flex' }}>
                      <MaskedInput
                        className="venda-online-input"
                        mask="99/99"
                        name="expiry"
                        error={credit_card.errors.expiry}
                        helperText={
                          credit_card.errors.expiry &&
                          'A expiração deve superior a ' +
                            moment().add(12, 'months').format('MM/YY') +
                            '.'
                        }
                        label="Vencimento"
                        placeholder="Vencimento"
                        maskChar=""
                        fullWidth
                        size="small"
                        style={{ marginRight: 8 }}
                        value={credit_card.expiry}
                        onChange={handleChangeCreditCard}
                      >
                        {inputProps => <TextField {...inputProps} />}
                      </MaskedInput>

                      <MaskedInput
                        className="venda-online-input"
                        mask="9999"
                        name="cvv"
                        label="CVV"
                        placeholder="CVV"
                        InputProps={{
                          endAdornment: (
                            <Tooltip
                              arrow
                              position="start"
                              title="O número código de segurança é representado pelos três últimos dígitos no verso do seu cartão. Em cartões American Express, o código de segurança é um número de 4 dígitos na frente do cartão."
                            >
                              <Help></Help>
                            </Tooltip>
                          ),
                        }}
                        maskChar=""
                        fullWidth
                        size="small"
                        value={credit_card.cvv}
                        onChange={handleChangeCreditCard}
                      >
                        {inputProps => <TextField {...inputProps} />}
                      </MaskedInput>
                    </span>

                    <MaskedInput
                      className="venda-online-input"
                      mask={'99'}
                      name="card_maturity"
                      label="Dia do vencimento da fatura"
                      placeholder="Dia do vencimento da fatura"
                      maskChar=""
                      fullWidth
                      size="small"
                      value={credit_card.card_maturity}
                      onChange={handleChangeCreditCard}
                    >
                      {inputProps => <TextField {...inputProps} />}
                    </MaskedInput>
                  </FormControl>
                </div>
              </div>
            </div>

            <Divider></Divider>

            <DialogActions>
              <Button
                disabled={loading}
                onClick={() => this.setState({ open: false })}
                color="primary"
              >
                Cancelar
              </Button>
              <Button
                disabled={loading}
                onClick={openBankSlipParcel}
                color="primary"
                variant="contained"
              >
                Confirmar
              </Button>
            </DialogActions>
          </Dialog>

          <div className="header-comp">
            <Typography variant="h1" component="h1">
              Método de pagamento
            </Typography>
          </div>
          <Divider />

          {this.state.twoCards === 'true' && (
            <>
              <Button
                id='credit-card-twocards'
                color="primary"
                style={{ width: '100%' }}
                onClick={() => {
                  this.props.parent.setState({ payment_method: 'credit_card' })
                }}
              >
                <div className="method">
                  <img alt="credit_card" className="method-img" src={CreditCardImg}></img>
                  <Typography color="textSecondary">Cartão de crédito</Typography>
                  {payment_method === 'credit_card' && <i className="lni lni-checkmark"></i>}
                </div>
              </Button>
              <Divider />
            </>
          )}

          {this.state.twoCards !== 'true' && (
            <>
              <Button
                id="credit-card"
                color="primary"
                style={{ width: '100%' }}
                onClick={() => this.props.parent.setState({ payment_method: 'recurrence' })}
              >
                <div className="method">
                  <img alt="recurrence" className="method-img" src={CreditCardImg}></img>
                  <Typography color="textSecondary">Cartão de crédito</Typography>
                  {payment_method === 'recurrence' && <i className="lni lni-checkmark"></i>}
                </div>
              </Button>
              <Divider />
            </>
          )}
          <>
            <Button
              id="bank-slip"
              color="primary"
              style={{ width: '100%' }}
              onClick={() => {
                this.props.parent.setState({ payment_method: 'bank_slip_1' })
              }}
            >
              <div className="method">
                <img alt="bank_slip" className="method-img" src={BankSlipImg}></img>
                <Typography color="textSecondary">Boleto</Typography>
                {payment_method === 'bank_slip_1' && <i className="lni lni-checkmark"></i>}
              </div>
            </Button>
            <Divider />
            {!has_responsible && (
              <Button
                id="hybrid"
                color="primary"
                style={{ width: '100%' }}
                onClick={() => {
                  this.props.parent.setState({ payment_method: 'hybrid' })
                }}
              >
                <div className="method">
                  <img alt="hybrid" className="method-img" src={HybridImg}></img>
                  <Typography color="textSecondary">Boleto + Cartão</Typography>
                  {payment_method === 'hybrid' && <i className="lni lni-checkmark"></i>}
                </div>
              </Button>
            )}
          </>

          <Button
            id="next-button"
            color="primary"
            variant="contained"
            style={{ width: '100%' }}
            onClick={() => {
              if (this.props.parent.state.step === 0 && payment_method === 'bank_slip_1') {
                return this.setState({ open: true })
              }
              this.props.parent.setState({ step: 1 })
            }}
            className="next"
          >
            Continuar
          </Button>
        </div>
      </div>
    )
  }
}
