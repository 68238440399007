import React from 'react'

const Button = props => {
    return (
        <button className={`btn btn--primary ${props.className || ''}`} onClick={props.disabled ? () => {} : props.onClick}>
            {props.description}
        </button>
    )
}

export default Button