import { Box, Image, Input, InputGroup, InputRightElement } from '@chakra-ui/react'
import React, { useState, useEffect } from 'react'
import MaskedInput from 'react-input-mask'
import CardFx from '../functions/cardDetector'

export default function creditCardInput(props) {
  const [cardImage, setCardImage] = useState('')
  let flag = CardFx.getCardFlag(props.value || '')

  useEffect(() => {
    switch (flag) {
      case 'elo':
        setCardImage('https://res.cloudinary.com/ucred/image/upload/c_scale,w_100/v1627581003/icons/CreditCards/Elo.png')
        break

      case 'mastercard':
        setCardImage('https://res.cloudinary.com/ucred/image/upload/c_scale,w_100/v1627581003/icons/CreditCards/Mastercard.png')
        break

      case 'american_express':
        setCardImage('https://res.cloudinary.com/ucred/image/upload/c_scale,w_100/v1627581003/icons/CreditCards/Amex.png')
        break

      case 'hipercard':
        setCardImage('https://res.cloudinary.com/ucred/image/upload/c_scale,w_100/v1627581003/icons/CreditCards/Hipercard.png')
        break

      case 'jcb':
        setCardImage('https://res.cloudinary.com/ucred/image/upload/c_scale,w_100/v1627581003/icons/CreditCards/Jcb.png')
        break

      case 'visa':
        setCardImage('https://res.cloudinary.com/ucred/image/upload/c_scale,w_100/v1627581003/icons/CreditCards/Visa.png')
        break

      default:
        setCardImage('')
    }
  }, [props.value])

  return (
    <InputGroup size="md">
      <Input {...props} as={MaskedInput} maskChar="" mask="9999 9999 9999 9999" />

      <InputRightElement>
        <Box pr={3}>
          <Image borderRadius="full" src={cardImage} />
        </Box>
      </InputRightElement>
    </InputGroup>
  )
}
