import axios from 'axios'

const instance = axios.create({
  baseURL: 'https://meuip.herokuapp.com/api/json',
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  },
  timeout: 2000,
})

export default instance
