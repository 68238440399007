import pt from 'date-fns/locale/pt-BR';
import Moment from 'moment';
import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import Api from '../../services/ApiInstance';
import Button from '../../components/button';
import Table from '../../components/table';
import HeaderRevendedor from '../../layout/HeaderRevendedor';
registerLocale('pt-BR', pt)

export default class DetalhadoRevendedor extends React.Component {
    state = {
        selectedOption: null,
        columns : [],
          rows: [],
          linesExport: [],
          companies: null,
          disabledCompanies: false,
          companySelected: null,
          dtBegin: Date.parse("01/01/2019"),
          dtEnd: Date.parse(`12/31/${Moment().add(1, 'years').format('YYYY')}`)
    };

    handleChange = selectedOption => {
      this.setState({ selectedOption, companySelected: selectedOption.id });
    };

    allCompanies = event => {
      let { checked } = event.target
      this.setState({ disabledCompanies: checked })
      if(checked) this.setState({companySelected: ''})
    }

    changeDateBegin = date => {
      this.setState({ dtBegin: date })
    }

    changeDateEnd = date => {
      this.setState({ dtEnd: date })
    }

    getApiUrl = route => {
      switch (route) {
        case 'cliente':
            return 'detalheCliente'
        case 'trial':
            return 'detalheTrial'
        case 'consulta':
            return 'detalheConsulta'
        case 'login':
            return 'detalheUsuarioLogado'
        case 'logout':
            return 'detalheUsuarioNaoLogado'
        case 'assinatura':
            return 'detalheContratoUsuario'
        case 'ticket': 
            return 'detalheTicketMedio'
        case 'consultaUcred':
            return 'detalheConsultaUcred'
        case 'consultaUcredCancelada':
            return 'detalheConsultaUcredCancelado'
        case 'consultaPropriaCancelada':
            return 'detalheConsultaPropriaCancelado'
        case 'consultaPropria':
            return 'detalheConsultaPropria'
        case 'ticketUcred':
            return 'detalheTicketMedioUcred'
        case 'ticketPropria':
            return 'detalheTicketMedioPropria'
        case 'valorTotalUcred':
            return 'detalheValorTotalUcred'
        case 'valorTotalPropria':
            return 'detalheValorTotalPropria'
        case 'reprovadoUcred':
            return 'detalheReprovadoUcred'
        case 'reprovadoPropria':
            return 'detalheReprovadoPropria'
        case 'contratosPendentes':
            return 'detalheContratoPendente'
        case 'contratosAssinados':
            return 'detalheContratoAssinado'
        case 'imobiliaria':
            return 'detalheImobiliaria'
        case 'imobiliariaAtiva':
            return 'detalheImobiliariaAtiva'
        case 'sinistroPropria':
            return 'detalheSinistroPropria'
        case 'sinistroUcred':
            return 'detalheSinistroUcred'
        default:
            break;
      }
    }

    filter = async () => {
      const { dtBegin, dtEnd, companySelected } = this.state
      const { match: { params } } = this.props;
      let url = this.getApiUrl(params.grafico)
      let { columns, rows } = await Api.get(`${url}?dt_ini=${Moment(dtBegin).format('YYYY-MM-DD')}&dt_end=${Moment(dtEnd).format('YYYY-MM-DD')}&companyId=${companySelected}`)
      await this.setState({ columns, rows, linesExport: columns })
    }

    async componentDidMount() {
      let { data } = await Api.get('empresasRevendedor')
      const { match: { params } } = this.props;
      let formatedCompanies = data.map(company => {
          company.value = company.id
          company.label = company.name
          return company
      })
      this.setState({ companies: formatedCompanies })
      let url = this.getApiUrl(params.grafico)
      const year = Moment().add(1, 'years').format('YYYY')
      let { columns, rows } = await Api.get(`${url}?dt_ini=2019-01-01&dt_end=${year}-12-31&companyId=`)
      await this.setState({ columns, rows, linesExport: columns })
    }
    render() {
        const { selectedOption, companies, disabledCompanies, dtBegin, dtEnd } = this.state
        return (
            <React.Fragment>
                <HeaderRevendedor />
                <div className="detalhado">
                    <div className="detalhado__header">
                        <h1 className="detalhado__header--main">Detalhado</h1>
                        {/* <h2 className="detalhado__header--sub">{ params.grafico }</h2> */}
                    </div>
                    <div className="detalhado__filter">
                        <div className="item">
                            <b>De: </b> <DatePicker  locale={'pt-BR'} selected={dtBegin} dateFormat="dd/MM/yyyy" onChange={this.changeDateBegin}/> 
                        </div>
                        <div className="item">
                            <b>Até: </b> <DatePicker  locale="pt-BR" selected={dtEnd} dateFormat="dd/MM/yyyy" onChange={this.changeDateEnd}/>
                        </div>
                        <div className="item">
                            <b>Empresa: </b>  
                            <div className="container-select">
                              {companies &&
                                <Select value={selectedOption} onChange={this.handleChange} options={companies} isDisabled={disabledCompanies}/>
                              }
                            </div>
                        </div>
                        <div className="item">
                            <input type="checkbox" id="allCompany" onChange={this.allCompanies}/>
                            <label htmlFor="allCompany">Todas as Empresas</label>
                        </div>
                        <div className="item">
                            <Button description="filtrar" onClick={this.filter}/>
                        </div>
                    </div>
                    <div className="detalhado__body">
                    <Table export linhas={this.state.rows} columns={this.state.columns} 
                      onClickRow={()=>{}} 
                      placeholder="pesquise por nome de cliente"
                      columnsExport={this.state.linesExport}/>
                    </div>
                </div>
                
            </React.Fragment>
        )
    }
}