import React, { useState, useEffect, useCallback } from 'react'
import MaterialTable from 'material-table'
import moment from 'moment'
import 'moment/locale/pt-br'

import { connect } from 'react-redux'
import { changeNotification, fillAguardandoTrial, fillTrial, logOut } from '../../redux/actions/authUserAction'
import { refresh } from '../../redux/actions/creditAnalysisActions'

import ApiInstance from '../../services/ApiInstance'
import tableIcons from '../../styles/tableIcons'

import localization from '../../locale/tableLocale'
import { formatPurchases } from './formatPurchases'
import { fullPlanColumns, resalePlanColumns, cellStyle } from './purchaseTableColumns'

function Acompanhamento(props) {
  const [loading, setLoading] = useState(true)
  const [rows, setRows] = useState()
  const [columns, setColumns] = useState([
    {
      title: 'Locatário',
      field: 'cliente',
      cellStyle,
    },
    { title: 'Serviço', field: 'servico', cellStyle },
    { title: 'Status transação', field: 'status', cellStyle, export: true, hidden: true, searchable: true },
    { title: 'Status', field: 'status_column', cellStyle, export: false, searchable: false, align: 'center' },
    {
      title: 'Valor Total',
      field: 'totalValue',
      cellStyle,
      type: 'currency',
      currencySetting: { locale: 'pt-BR', currencyCode: 'BRL', minimumFractionDigits: 2, maximumFractionDigits: 2 },
    },
    { title: 'Taxa locatário', field: 'total_fee', cellStyle },
    { title: 'Cobertura Ucred', field: 'cover_value', cellStyle },
    {
      title: 'Comissão imobiliária',
      field: 'imob_gain',
      cellStyle,
      hidden: props.user && props.user.isMainUser ? false : true,
    },
    { title: 'Data Início', field: 'dt_inicio', cellStyle, type: 'date', dateSetting: { locale: 'pt-BR' } },
    { title: 'Data Fim', field: 'dt_fim', cellStyle, type: 'date', dateSetting: { locale: 'pt-BR' } },
    { title: 'Usuário', field: 'userId', cellStyle },
  ])

  useEffect(() => {
    async function fetchData() {
      const response = await ApiInstance.get('/acompanhamento')
      const purchases = response.data
      const purchasePlan = purchases[0] ? purchases[0].plan_id : null
      if (!purchasePlan) return setLoading(false)
      const fullPlan = 1
      const resalePlan = 4
      if (purchasePlan === fullPlan) setColumns(fullPlanColumns)
      if (purchasePlan === resalePlan) setColumns(resalePlanColumns)
      const purchasesFormatted = formatPurchases(purchases)
      setRows(purchasesFormatted)
      setLoading(false)
    }
    fetchData()
  }, [])

  const onClickRow = useCallback(
    ({ purchaseId, clientId }) =>
      props.parent.props.history.push({
        pathname: `detalhe-do-cliente/${clientId}`,
        state: { detail: { purchaseId } },
      }),
    []
  )

  return (
    <MaterialTable
      style={{ width: '100%' }}
      icons={tableIcons}
      columns={columns}
      options={{
        headerStyle: { whiteSpace: 'pre-wrap' },
        pageSize: 15,
        pageSizeOptions: [15, 50, 100],
        paginationType: 'stepped',
        tableLayout: 'auto',
        showTitle: true,
        padding: 'dense',
        exportAllData: true,
        exportFileName: 'Relatório Ucred (Acompanhamento) - ' + moment().format('DD-MM-YYYY H:mm:ss'),
        exportButton: true,
        actionsColumnIndex: -1,
      }}
      onRowClick={(event, rowData) => onClickRow({ purchaseId: rowData.id, clientId: rowData.id_cliente })}
      components={{ Container: (props) => <div id="mtable-container" {...props}></div> }}
      isLoading={loading}
      data={rows}
      title="Relatório de acompanhamento"
      localization={localization}
    />
  )
}

const mapStateToProps = ({ authReducer }) => ({ user: authReducer.authUser })

const mapDispatchToProps = (dispatch) => ({
  refresh: () => dispatch(refresh()),
  logOut: () => dispatch(logOut()),
  fillTrial: (trial) => dispatch(fillTrial(trial)),
  fillAguardandoTrial: (trial) => dispatch(fillAguardandoTrial(trial)),
  changeNotification: (status) => dispatch(changeNotification(status)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Acompanhamento)
