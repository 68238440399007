import React from 'react'
import Input from '../../components/input'
import Modal from 'react-responsive-modal'
import Button from '../../components/button'
import { connect } from 'react-redux'
import { fillField, refresh } from '../../redux/actions/creditAnalysisActions'

import { withSnackbar } from 'notistack'
import ApiInstance from '../../services/ApiInstance'
import { removeMask } from '../../util/money'
import Checkbox from '../../components/checkbox'

class Step1 extends React.Component {
  state = {
    help: false,
    helpEmail: false,
    corresponsavel: false,
    openModalCorresp: false,
    openModalServices: false,
    confirmarEmail: false,
    openModalEmail: false,
    loading: false,
  }
  help = () => {
    let { help } = this.state
    this.setState({ help: !help })
  }

  helpEmail = () => {
    let { helpEmail } = this.state
    this.setState({ helpEmail: !helpEmail })
  }

  changeCorresponsavel = () => {
    let { corresponsavel } = this.state
    let { fillField } = this.props
    this.setState({ corresponsavel: !corresponsavel })
    fillField('has_corresponsible', !corresponsavel)
    if (!corresponsavel) {
      if (window.localStorage.getItem('hiddenCorrespMessage') !== 'true') {
        this.setState({ openModalCorresp: true })
      }
    }
  }

  changeEmail = (event) => {
    let { confirmarEmail } = this.state
    let { fillField, creditCardCorresp, creditCardResp, client, corresponsavel } = this.props
    this.setState({ confirmarEmail: !confirmarEmail })
    fillField('confirmarEmail', !confirmarEmail)
    if (confirmarEmail) {
      if (window.localStorage.getItem('hiddenEmailMessage') !== 'true') {
        this.setState({ openModalEmail: true })
      }
    }
    if (event.target.checked) {
      fillField('fluxo', 2)
      fillField('creditCardResp', {
        ...creditCardResp,
        number: '5555 5555 5555 5557',
        expiry: '10/2022',
        cvv: '123',
        card_maturity: 1,
        type: 'mastercard',
      })
      fillField('creditCardCorresp', {
        ...creditCardCorresp,
        number: '5555 5555 5555 5557',
        expiry: '10/2022',
        cvv: '123',
        card_maturity: 1,
        type: 'mastercard',
      })
      fillField('client', { ...client, card_maturity: 1 })
      fillField('corresponsavel', { ...corresponsavel, card_maturity: 1 })
    }
    if (!event.target.checked) {
      fillField('fluxo', 1)
      fillField('creditCardResp', { number: '', name: '', expiry: '', cvv: '', focused: '', date: '', value: 0 })
      fillField('creditCardCorresp', { number: '', name: '', expiry: '', cvv: '', focused: '', date: '', value: 0 })
      fillField('client', { ...client, card_maturity: null })
      fillField('corresponsavel', { ...corresponsavel, card_maturity: null })
    }
  }

  closeModalCorresp = () => {
    this.setState({ openModalCorresp: false })
  }

  closeModalService = () => {
    this.setState({ openModalServices: false })
  }

  showMessageEmail = (event) => {
    let { checked } = event.currentTarget
    if (checked) {
      window.localStorage.setItem('hiddenEmailMessage', true)
    } else {
      window.localStorage.setItem('hiddenEmailMessage', false)
    }
  }

  showMessage = (event) => {
    let { checked } = event.currentTarget
    if (checked) {
      window.localStorage.setItem('hiddenCorrespMessage', true)
    } else {
      window.localStorage.setItem('hiddenCorrespMessage', false)
    }
  }

  handleChange = (event) => {
    let { fillField, client } = this.props
    let { id, value } = event.currentTarget
    if (id === 'cpf') fillField('client', { ...client, cpf: value })
    fillField(id, value)
  }

  avancar = async () => {
    try {
      let { cardLimit, transactionValue, cpf, familyIncome, fillField, creditCardResp, changeLoading } = this.props
      changeLoading(true)
      this.setState({ loading: true })
      let { confirmarEmail } = this.state
      if (!transactionValue || !cpf) throw new Error('CAMPO_VAZIO')
      let {
        data: { services, client, files },
      } = await ApiInstance.get(`/clienteTransacao?cpf=${cpf}&cardLimit=${removeMask(cardLimit)}&familyIncome=${removeMask(familyIncome)}&transactionValue=${removeMask(transactionValue)}`)
      if (services.length == 0) throw new Error('SEM_SERVICOS')
      if (client) {
        fillField('client', client)
        fillField('creditCardResp', { ...creditCardResp, name: client.name })
      }
      if (!client) {
        if (!confirmarEmail) {
          fillField('creditCardResp', { number: '', name: '', expiry: '', cvv: '', focused: '', date: '', value: 0 })
          fillField('creditCardCorresp', { number: '', name: '', expiry: '', cvv: '', focused: '', date: '', value: 0 })
        }

        fillField('client', {
          cpf: this.props.client.cpf,
          id_vindi: null,
          id: null,
          name: '',
          email: '',
          telephone_1: '',
          telephone_2: '',
          card_maturity: '',
          city: '',
          address: '',
          birthday: null,
          confirm_email: '',
        })
        fillField('immobile', { address: '', cep: '', city: '' })
        fillField('selectedService', { parcel: 1, value: 0 })
        fillField('finalValue', '')
        fillField('adhesion', { value: 0, parcel: 0 })
      }
      fillField('selectedService', { parcel: 1, value: 0 })
      fillField('finalValue', '')
      fillField('services', services)
      fillField('files', files)
      fillField('serviceActive', -1)
      fillField('clientCorresp', false)
      fillField('corresponsavel', {
        id_vindi: null,
        id: null,
        name: null,
        cpf: null,
        email: null,
        telephone_1: null,
        telephone_2: null,
        card_maturity: null,
        city: null,
        address: null,
        birthday: null,
        confirm_email: null,
      })

      setTimeout(() => {
        changeLoading(false)
        this.setState({ loading: false })
        this.props.nextStep()
      }, 3000)
    } catch (error) {
      this.props.changeLoading(false)
      this.setState({ loading: false })
      if (error.message == 'CAMPO_VAZIO') {
        const snackbarKey = this.props.enqueueSnackbar('Preencha todos os campos.', {
          variant: 'error',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        })
        return
      }
      if (error.message == 'SEM_SERVICOS') return this.setState({ openModalServices: true })

      const snackbarKey = this.props.enqueueSnackbar(error.message, {
        variant: 'error',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
    }
  }

  componentDidMount() {
    this.changeEmail({
      target: {
        checked: true,
      },
    })
    this.setState({ openModalEmail: false })
  }

  render() {
    let { has_corresponsible } = this.props
    let { loading } = this.state
    return (
      <React.Fragment>
        {this.props.authUser
          ? this.props.authUser.plan_id == 1 && (
              <div className="line">
                <Input type="currency" placeholder="Limite do Cartão" labelDescription="Limite do Cartão" id="cardLimit" value={this.props.cardLimit} onChange={this.handleChange} iconType="money" />
              </div>
            )
          : null}
        <div className="line">
          <Input
            type="currency"
            placeholder="Valor Transação"
            labelDescription="Valor Transação"
            id="transactionValue"
            value={this.props.transactionValue}
            onChange={this.handleChange}
            iconType="money"
          />
        </div>
        {this.props.authUser
          ? this.props.authUser.renda != 'N' && (
              <div className="line">
                <Input type="currency" placeholder="Renda Familiar" labelDescription="Renda Familiar" id="familyIncome" value={this.props.familyIncome} onChange={this.handleChange} iconType="money" />
              </div>
            )
          : null}
        <div className="line">
          <Input type="text" mask="111.111.111-11" placeholder="CPF" labelDescription="CPF" id="cpf" value={this.props.cpf} onChange={this.handleChange} iconType="number" />
        </div>
        <div className="line lineswitch">
          <div className="popover-corresponsavel">
            <label>Transacionar com 2 cartões de crédito</label>
            <Checkbox onChange={this.changeCorresponsavel} checked={has_corresponsible} />
          </div>
        </div>
        <div className="line">{loading ? <div className="loader" /> : <Button disabled={this.props.loading} description="Analisar" onClick={this.avancar} />}</div>

        <Modal open={this.state.openModalCorresp} onClose={this.closeModalCorresp} classNames={{ modal: 'modalCorresp' }}>
          <div className="container-modal-corresp">
            <h1>
              Atenção <i className="lni-warning"></i>{' '}
            </h1>
            <span>Ao selecionar está opção você poderá transacionar com 2 cartões de crédito diferentes, sendo eles de uma mesma pessoa ou de pessoas diferentes.</span>
            <div className="container-check">
              <input type="checkbox" id="notify-corresp" onChange={this.showMessage} />
              <label htmlFor="notify-corresp"> Nao exibir essa mensagem novamente </label>
            </div>
            <Button
              description="Confirmar"
              onClick={() => {
                this.setState({ openModalCorresp: false })
              }}
            />
          </div>
        </Modal>

        <Modal
          open={this.state.openModalEmail}
          onClose={() => {
            this.setState({ openModalEmail: false })
          }}
          classNames={{ modal: 'modalCorresp' }}
        >
          <div className="container-modal-corresp">
            <h1>
              Atenção <i className="lni-warning"></i>{' '}
            </h1>
            <span>Ao desmarcar esta opção é importante que possua os dados de cartão de crédito do locatário em mãos para concluir a transação, incluindo o código de segurança (CVV).</span>
            <div className="container-check">
              <input type="checkbox" id="notify-corresp" onChange={this.showMessageEmail} />
              <label htmlFor="notify-corresp"> Não exibir essa mensagem novamente </label>
            </div>
            <Button
              description="Confirmar"
              onClick={() => {
                this.setState({ openModalEmail: false })
              }}
            />
          </div>
        </Modal>

        <Modal open={this.state.openModalServices} onClose={this.closeModalService} classNames={{ modal: 'modalService' }}>
          <div className="container-modalService">
            <h1>
              Atenção <i className="lni-warning"></i>{' '}
            </h1>
            <span>Não foram encontrados serviços disponíveis para esse cliente, entre em contato com nosso suporte.</span>
            <Button description="Confirmar" onClick={this.closeModalService} />
          </div>
        </Modal>
      </React.Fragment>
    )
  }
}

function mapStateToProps({ creditAnalysis, authReducer }) {
  return {
    ...creditAnalysis,
    ...authReducer,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fillField: (field, value) => dispatch(fillField(field, value)),
    refresh: () => dispatch(refresh()),
  }
}

export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(Step1))
