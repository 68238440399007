import {
  Badge,
  Box,
  ButtonBase,
  ClickAwayListener,
  IconButton,
  Paper,
  Popper,
  Tooltip,
  Typography,
  withStyles,
  Zoom,
} from '@material-ui/core'
import { Facebook, Instagram, LinkedIn, PeopleAltRounded, YouTube } from '@material-ui/icons'
import { useState } from 'react'

export default function Social() {
  const [socialAnchor, setSocialAnchor] = useState<any>(null)
  const [social, setSocial] = useState(false)

  const StyledBadge = withStyles(theme => ({
    badge: {
      right: 6,
      top: 20,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  }))(Badge)

  const TypographyBadge = withStyles(theme => ({
    badge: {
      right: 3,
      top: 3,
      border: `2px solid ${theme.palette.background.paper}`,
    },
  }))(Badge)

  const goToUrl = async (url: string, storage: any) => {
    // EM DESENVOLVIMENTO PODE NÃO FUNCIONAR
    const visitedUrls = JSON.parse(localStorage.getItem('visited-urls') || '{}')

    visitedUrls[storage] = true

    localStorage.setItem('visited-urls', JSON.stringify(visitedUrls))

    window.open(url, '_blank')
  }

  const getSocialBadge = () => {
    const visitedUrls = JSON.parse(localStorage.getItem('visited-urls') || '{}')

    let total = 0

    if (!visitedUrls.linkedin) total++
    if (!visitedUrls.facebook) total++
    if (!visitedUrls.youtube) total++
    if (!visitedUrls.instagram) total++

    return { total, visitedUrls }
  }

  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={() => setSocial(false)}
    >
      <Box
        onClick={event => {
          setSocialAnchor(event.target)
          setSocial(!social)
        }}
      >
        <Tooltip title="Mídias sociais">
          <IconButton className="icon">
            <StyledBadge
              style={{
                pointerEvents: 'none',
                color: '#fff',
              }}
              badgeContent={getSocialBadge().total}
              color="primary"
            >
              <PeopleAltRounded />
            </StyledBadge>
          </IconButton>
        </Tooltip>

        <Popper
          style={{ top: 8, right: 4, width: 150, zIndex: 3 }}
          open={social}
          anchorEl={socialAnchor}
        >
          <Zoom in={social}>
            <Paper>
              <ButtonBase
                onClick={() => goToUrl('https://www.linkedin.com/company/ucredbr', 'linkedin')}
                style={{
                  display: 'flex',
                  width: '100%',
                  padding: 12,
                  justifyContent: 'flex-start',
                }}
              >
                <TypographyBadge
                  style={{
                    pointerEvents: 'none',
                  }}
                  invisible={getSocialBadge().visitedUrls.linkedin}
                  color="primary"
                  variant="dot"
                >
                  <LinkedIn className="social linkedin"></LinkedIn>
                </TypographyBadge>
                <Typography
                  style={{
                    marginLeft: 8,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  Linkedin
                </Typography>
              </ButtonBase>
              <ButtonBase
                onClick={() => goToUrl('https://www.instagram.com/ucredbr', 'instagram')}
                style={{
                  display: 'flex',
                  width: '100%',
                  padding: 12,
                  justifyContent: 'flex-start',
                }}
              >
                <TypographyBadge
                  style={{
                    pointerEvents: 'none',
                  }}
                  invisible={getSocialBadge().visitedUrls.instagram}
                  color="primary"
                  variant="dot"
                >
                  <Instagram className="social instagram"></Instagram>
                </TypographyBadge>
                <Typography
                  style={{
                    marginLeft: 8,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  Instagram
                </Typography>
              </ButtonBase>
              <ButtonBase
                onClick={() => goToUrl('https://www.facebook.com/ucredbr', 'facebook')}
                style={{
                  display: 'flex',
                  width: '100%',
                  padding: 12,
                  justifyContent: 'flex-start',
                }}
              >
                <TypographyBadge
                  style={{
                    pointerEvents: 'none',
                  }}
                  invisible={getSocialBadge().visitedUrls.facebook}
                  color="primary"
                  variant="dot"
                >
                  <Facebook className="social facebook"></Facebook>
                </TypographyBadge>
                <Typography
                  style={{
                    marginLeft: 8,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  Facebook
                </Typography>
              </ButtonBase>
              <ButtonBase
                onClick={() =>
                  goToUrl('https://www.youtube.com/channel/UCldIsZb6XzpwfH7bk5ex7Zg', 'youtube')
                }
                style={{
                  display: 'flex',
                  width: '100%',
                  padding: 12,
                  justifyContent: 'flex-start',
                }}
              >
                <TypographyBadge
                  style={{
                    pointerEvents: 'none',
                  }}
                  invisible={getSocialBadge().visitedUrls.youtube}
                  color="primary"
                  variant="dot"
                >
                  <YouTube className="social youtube"></YouTube>
                </TypographyBadge>
                <Typography
                  style={{
                    marginLeft: 8,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  YouTube
                </Typography>
              </ButtonBase>
            </Paper>
          </Zoom>
        </Popper>
      </Box>
    </ClickAwayListener>
  )
}
