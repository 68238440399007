import {
  Accordion,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  ListItem,
  UnorderedList,
  Box,
} from '@chakra-ui/react'
import { AccordionItem } from './AccordionItem'
import { ChackaLicence } from './licences/ChackaLicence'
import { MaterialLicense } from './licences/MaterialLicense'

interface UseTermsModalProps {
  isOpen: boolean
  onClose: () => void
}

export function UseTermsModal({ isOpen, onClose }: UseTermsModalProps) {
  return (
    <Modal size="6xl" isOpen={isOpen} onClose={onClose} isCentered scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text color="gray.600" fontWeight="normal">
            Termos de uso
          </Text>
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <Box>
            <Text mb={2} color="gray.500" fontSize="large" fontWeight="semibold">
              Licenças de terceiros
            </Text>

            <UnorderedList ml={8} w="50%">
              {/* 
                <ListItem mb={2}>Icons</ListItem>
                <Text fontSize="sm">Material Design Icons</Text>
              */}

              <ListItem mt={4} mb={3} color="gray.600">
                Libraries
              </ListItem>

              <Accordion allowToggle>
                <AccordionItem title="Chakra-ui" index={0}>
                  <ChackaLicence />
                </AccordionItem>

                <AccordionItem title="Material-ui" index={0}>
                  <MaterialLicense />
                </AccordionItem>
              </Accordion>
            </UnorderedList>
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose}>Voltar</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
