import { withSnackbar } from 'notistack'
import React from 'react'
import Cards from 'react-credit-cards'
import 'react-credit-cards/es/styles-compiled.css'
import Modal from 'react-responsive-modal'
import Select from 'react-select'
import ApiInstance from '../../services/ApiInstance'
import Button from '../../components/button'
import Input from '../../components/input'
import vindiErrorMessage from '../../functions/vindiErrorMessage'
import { maskMoney } from '../../util/money'

class VendaOnline extends React.Component {
  constructor() {
    super()

    this.state = {
      cvv: '',
      cvv_corresp: '',
      expiry: '',
      expiry_corresp: '',
      holderName: '',
      number: '',
      errorMessage: '',
      number_corresp: '',
      purchaseParcel: {},
      adhesionParcel: {},
      adhesionOptions: [],
      parcelOptions: [],

      value: 0,
      value_corresp: 0,

      valueParcel: 0,
      valueParcel_corresp: 0,

      cep: '',
      city: '',
      address: '',

      error: false,
      hash: '',
      loading: false,
      adhesion: [],
      type_card: '',
      type_card_corresp: '',
      twoCards: true,

      confirmPurchase: false,
      totalValue: 0,
    }

    this.getParcelOptions = (value, correspValue) => {
      let count = 1
      let parcels = []

      while (count < 13) {
        parcels.push({
          label: `${count}X de R$ ${Number(value / count)
            .toFixed(2)
            .replace('.', ',')}`,
          value: value / count,
          valueCorresp: correspValue / count,
          quantity: count,
        })

        count++
      }
      return parcels
    }

    this.getAdhesionOptions = (value) => {
      let count = 1
      let options = []

      while (count < 4) {
        options.push({
          label: `${count}X de R$ ${Number(value / count)
            .toFixed(2)
            .replace('.', ',')}`,
          value: value / count,
          quantity: count,
        })
        count++
      }
      return options
    }
  }

  async componentDidMount() {
    try {
      const urlParams = new URLSearchParams(window.location.search)
      const hash = urlParams.get('hash')
      const twoCards = urlParams.get('twoCards')
      if (twoCards !== 'true' && twoCards !== 'false') this.setState({ error: true })
      let response = await ApiInstance.get(`vendaOnline?hash=${hash}&twoCards=${twoCards}`)

      let { card, purchaseData, purchaseDataCorresp, adhesion } = response

      //Removendo formatações
      let purchaseValue = Number(purchaseData.value.replace(',', '.').replace('R$', ''))
      let valueCorresp = purchaseDataCorresp.value
        ? Number(purchaseDataCorresp.value.replace(',', '.').replace('R$', ''))
        : 0
      let adhesionValue = adhesion[0] ? adhesion[0].value : 0

      let totalValue = 0

      if (valueCorresp) {
        totalValue = purchaseValue + valueCorresp + adhesionValue
      } else {
        totalValue = purchaseValue + adhesionValue
      }

      //Funções para montagem dos selects
      let parcelOptions = this.getParcelOptions(purchaseValue, valueCorresp)
      let adhesionOptions = adhesion[0] ? this.getAdhesionOptions(adhesion[0].value) : {}

      this.setState({
        adhesionOptions,
        parcelOptions,

        expiry: card.expiry,
        number: card.number,
        holderName: card.name,

        value: purchaseValue,
        value_corresp: valueCorresp,
        valueParcel_corresp: purchaseDataCorresp.parcel_value
          ? Number(purchaseDataCorresp.parcel_value.replace('R$', '').replace(',', '.'))
          : 0,

        purchaseParcel: parcelOptions ? parcelOptions[parcelOptions.length - 1] : {},
        adhesionParcel: adhesionOptions ? adhesionOptions[adhesionOptions.length - 1] : {},

        valueParcel: Number(purchaseData.parcel_value.replace('R$', '').replace(',', '.')),

        totalValue,

        cep: purchaseData.cep,
        city: purchaseData.city,
        address: purchaseData.address,
        hash,
        adhesion,
        twoCards: twoCards == 'false' ? false : true,
      })
    } catch (error) {
      this.setState({ error: true, errorMessage: error.originalMessage, contractUrl: error.contractUrl })
    }
  }

  handleChangeCard = (event) => {
    let { value, id } = event.currentTarget
    if (id === 'card_maturity') {
      if (Number(value) > 30) {
        return this.setState({ [id]: 30 })
      }
      if (Number(value) < 1 || String(Number(value)).match(/[^0-9]/g)) {
        return this.setState({ [id]: '' })
      }
    }
    this.setState({ [id]: value })
  }

  handleChangeCardCorresp = (event) => {
    let { value, id } = event.currentTarget
    this.setState({ [id]: value })
  }

  getIssuerCard = ({ issuer }) => {
    this.setState({ type_card: issuer })
  }

  getIssuerCardCorresp = ({ issuer }) => {
    this.setState({ type_card_corresp: issuer })
  }

   confirmar = async () => {
    this.setState({ loading: true })
    try {
      let {
        cvv,
        cvv_corresp,
        hash,
        number,
        number_corresp,
        holderName,
        card_maturity,
        expiry,
        expiry_corresp,
        type_card,
        type_card_corresp,
        twoCards,

        purchaseParcel,
        adhesionParcel,
      } = this.state
      if (twoCards) {
        await ApiInstance.post('vendaOnlineTwoCards', {
          hash,
          cvv,
          cvv_corresp,
          card_number: number,
          card_number_corresp: number_corresp,
          card_holder: holderName,
          card_holder_corresp: holderName,
          card_maturity: Number(card_maturity),
          expiration_date: expiry,
          expiration_date_corresp: expiry_corresp,
          type_card,
          type_card_corresp,

          purchaseParcel: {
            ...purchaseParcel,
            valueCorresp: this.state.value_corresp / this.state.purchaseParcel.quantity,
          },
          adhesionParcel,
        })
      } else {
        await ApiInstance.post('vendaOnline', {
          hash,
          cvv,
          card_number: number,
          card_holder: holderName,
          card_maturity: Number(card_maturity),
          expiration_date: expiry,
          type_card,

          purchaseParcel,
          adhesionParcel,
        })
      }
      const snackbarKey = this.props.enqueueSnackbar('Pagamento realizado com sucesso.', {
        variant: 'success',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
      setTimeout(() => {
        window.location.href = 'http://www.ucred.com.br'
      }, 2000)
    } catch (error) {
      let errorMessage = vindiErrorMessage(error.message, this.state.twoCards)
      const snackbarKey = this.props.enqueueSnackbar(errorMessage, {
        variant: 'error',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
      this.setState({ loading: false })
    }
  }

  render() {
    const {
      error,
      loading,
      adhesion,
      value,
      address,
      city,
      cep,
      holderName,
      number,
      expiry,
      cvv,
      card_maturity,
      focusCard1,
      twoCards,
      value_corresp,
      number_corresp,
      expiry_corresp,
      focusCard2,
      cvv_corresp,
      purchaseParcel,
      parcelOptions,
      adhesionParcel,
      adhesionOptions,
      totalValue,
    } = this.state

    const openConfirmation = () => {
      if (!holderName) {
        const snackbarKey = this.props.enqueueSnackbar('Informe nome do titular do cartão!', {
          variant: 'error',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        })

        return
      }
      if (!number) {
        const snackbarKey = this.props.enqueueSnackbar('Informe os números do cartão!', {
          variant: 'error',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        })
        return
      }
      if (!cvv) {
        const snackbarKey = this.props.enqueueSnackbar('Informe código de segurança do cartão!', {
          variant: 'error',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        })
        return
      }
      if (!card_maturity) {
        const snackbarKey = this.props.enqueueSnackbar('Informe o dia de vencimento da fatura do cartão!', {
          variant: 'error',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        })
        return
      }
      if (!expiry) {
        const snackbarKey = this.props.enqueueSnackbar('Informe a data de validade do cartão!', {
          variant: 'error',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        })
        return
      }

      if (twoCards) {
        if (!number_corresp) {
          const snackbarKey = this.props.enqueueSnackbar('Informe o número do segundo cartão.', {
            variant: 'error',
            onClick: () => {
              this.props.closeSnackbar(snackbarKey)
            },
          })
          return
        }
        if (!expiry_corresp) {
          const snackbarKey = this.props.enqueueSnackbar('Informe a validade do segundo cartão.', {
            variant: 'error',
            onClick: () => {
              this.props.closeSnackbar(snackbarKey)
            },
          })
          return
        }
        if (!cvv_corresp) {
          const snackbarKey = this.props.enqueueSnackbar('Informe o código de segurança do segundo cartão.', {
            variant: 'error',
            onClick: () => {
              this.props.closeSnackbar(snackbarKey)
            },
          })
          return
        }
      }
      this.setState({ confirmPurchase: true })
    }
    return (
      <div className="vendaOnline">
        <Modal
          style={{ borderRadius: 4 }}
          classNames={{ modal: 'modalRevision' }}
          open={this.state.confirmPurchase}
          onClose={() => {
            this.setState({ confirmPurchase: false })
          }}
          center
        >
          <h2 className="revision-title">Atenção!</h2>
          <h3 className="revision-subtitle">Revise os dados antes de confirmar</h3>
          <div className="revision-item">
            <label>Cartão de crédito:</label>
            <span>*** *** *** {this.state.number.substr(-4)}</span>
          </div>
          {number_corresp != '' ? (
            <div className="revision-item">
              <label>Segundo cartão de crédito:</label>
              <span>**** **** **** {this.state.number_corresp.substr(-4)}</span>
            </div>
          ) : (
            ''
          )}
          <div className="revision-item">
            <label>Valor da transação:</label>
            <span>
              {maskMoney(value)} ({purchaseParcel.label})
            </span>
          </div>
          {adhesion.length ? (
            <div className="revision-item">
              <label>Valor de adesão:</label>
              <span>
                {maskMoney(adhesionParcel.value * adhesionParcel.quantity)} ({adhesionParcel.label})
              </span>
            </div>
          ) : (
            ''
          )}

          {number_corresp != '' ? (
            <div className="revision-item">
              <label>Valor da transação no segundo cartão:</label>
              <span>
                {maskMoney(Number(String(value_corresp).replace('R$', '')))} ({purchaseParcel.quantity}X{' '}
                {maskMoney(purchaseParcel.valueCorresp)})
              </span>
            </div>
          ) : (
            ''
          )}

          <div className="revision-item total-value">
            <label>Valor total:</label>
            <span>{maskMoney(totalValue)}</span>
          </div>
          <div className="row">
            {loading && <div className="loader" />}
            {!loading && <Button className="confirm" description="concluir" onClick={this.confirmar} />}
          </div>
        </Modal>
        <div className="vendaOnline--header">
          <div>
            <img src="https://i.ibb.co/1G1R9dv/LOGO-FINAL2.png" alt="logo" />
          </div>
        </div>
        {!error && (
          <div className="vendaOnline--body">
            <p className="title">Preencha os dados do cartão</p>

            <div className="container-venda">
              <span className="title">Dados do contrato</span>
              <div className="column-container">
                <div className="column">
                  <div className="item">
                    <span className="label">Valor:</span>
                    <span className="value"> {maskMoney(value)} </span>
                  </div>
                  <div className="item">
                    <span className="label">Quantidade de parcelas:</span>
                    <span className="value">
                      <Select
                        className="select-input"
                        value={purchaseParcel}
                        onChange={async (value) => {
                          await this.setState({ purchaseParcel: value })
                        }}
                        options={parcelOptions}
                      />
                    </span>
                  </div>
                  <div className="item">
                    <span className="label">Valor da parcela:</span>{' '}
                    <span className="value"> {maskMoney(Number(purchaseParcel.value))} </span>
                  </div>
                </div>

                {adhesion.length ? (
                  <>
                    <div className="spacer" />
                    <div className="column">
                      <div className="item">
                        <span className="label">Valor da Adesão:</span>{' '}
                        <span className="value">{maskMoney(adhesion[0].value)}</span>
                      </div>

                      <div className="item">
                        <span className="label">Parcelas da Adesão:</span>
                        <span className="value">
                          <Select
                            className="select-input"
                            value={adhesionParcel}
                            onChange={async (value) => {
                              await this.setState({ adhesionParcel: value })
                            }}
                            options={adhesionOptions}
                          />
                        </span>
                      </div>
                    </div>
                  </>
                ) : (
                  ''
                )}

                <div className="spacer" />

                <div className="column">
                  <div className="item">
                    <span className="label">Endereco:</span> <span className="value"> {address} </span>
                  </div>
                  <div className="item">
                    <span className="label">Cidade:</span> <span className="value"> {city} </span>
                  </div>
                  <div className="item">
                    <span className="label">CEP:</span> <span className="value"> {cep} </span>
                  </div>
                </div>
              </div>
              <div className="container-totalvalue">
                <label>Valor Total:</label>
                {adhesion.length > 0 && <span> {maskMoney(Number(value) + Number(adhesion[0].value))} </span>}
                {adhesion.length <= 0 && <span> {maskMoney(value)} </span>}
              </div>
            </div>
            <div className="container-card">
              <div className="card-image">
                <Cards
                  preview={false}
                  number={number}
                  name={holderName}
                  expiry={expiry}
                  cvc={cvv}
                  focused={focusCard1}
                  placeholders={{ name: 'Nome do Titular', expiry: 'Data Venc' }}
                  callback={this.getIssuerCard}
                />
              </div>
              <div className="card-data">
                <div className="row first">
                  <Input
                    type="text"
                    labelDescription="Titular do Cartão"
                    placeholder="Titular do Cartão"
                    id="name"
                    className=""
                    value={holderName}
                    disabled
                    onFocus={() => this.setState({ focusCard1: 'name' })}
                    onChange={this.handleChangeCard}
                  />
                </div>
                <div className="row">
                  <Input
                    type="text"
                    labelDescription="Número do Cartão"
                    placeholder="Número do Cartão"
                    id="number"
                    iconType="number"
                    mask="1111 1111 1111 1111"
                    value={number}
                    onFocus={() => this.setState({ focusCard1: 'number' })}
                    onChange={this.handleChangeCard}
                  />
                </div>
                <div className="multiple-input">
                  <Input
                    type="text"
                    labelDescription="Data de Expiração"
                    placeholder="Data de Expiração"
                    iconType="number"
                    id="expiry"
                    mask="11/11"
                    value={expiry}
                    onFocus={() => this.setState({ focusCard1: 'expiry' })}
                    onChange={this.handleChangeCard}
                  />

                  <Input
                    type="text"
                    labelDescription="Código de segurança"
                    maxLength="4"
                    placeholder="Código de segurança"
                    id="cvv"
                    onChange={this.handleChangeCard}
                    iconType="number"
                    value={cvv}
                    onFocus={() => this.setState({ focusCard1: 'cvc' })}
                    onBlur={() => this.setState({ focusCard1: '' })}
                    labelIcon={true}
                    labelIconMessage={
                      'O número código de segurança é representado pelos três últimos dígitos no verso do seu cartão. Em cartões American Express, o código de segurança é um número de 4 dígitos na frente do cartão.'
                    }
                  />

                  <Input
                    type="text"
                    labelDescription="Dia do vencimento da fatura"
                    placeholder="Dia do vencimento da fatura"
                    id="card_maturity"
                    onChange={this.handleChangeCard}
                    iconType="number"
                    value={card_maturity}
                  />
                </div>
              </div>
            </div>

            {/* -------------------------------------------------------------------------- */}
            {/*                               SEGUNDO CARTÃO                               */}
            {/* -------------------------------------------------------------------------- */}

            {twoCards && (
              <React.Fragment>
                <div className="container-venda">
                  <span className="title">Dados do contrato - Segundo Cartão: </span>
                  <div className="column-container">
                    <div className="column">
                      <div className="item">
                        <span className="label">Valor:</span> <span className="value">{maskMoney(value_corresp)} </span>
                      </div>
                      <div className="item">
                        <span className="label">Quantidade de parcelas:</span>{' '}
                        <span className="value">
                          {purchaseParcel.quantity}X {maskMoney(purchaseParcel.valueCorresp)}
                        </span>
                      </div>
                      <div className="item">
                        <span className="label">Valor da parcela</span>
                        <span className="value">
                          {maskMoney(Number(String(value_corresp).replace('R$', '')) / Number(purchaseParcel.quantity))}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="container-totalvalue">
                      <label>Valor Total</label>
                      <span> {maskMoney(value_corresp)} </span>
                    </div>
                  </div>
                </div>

                <div className="container-card">
                  <div className="card-image">
                    <Cards
                      preview={false}
                      number={number_corresp}
                      name={holderName}
                      expiry={expiry_corresp}
                      cvc={cvv_corresp}
                      focused={focusCard2}
                      placeholders={{ name: 'Nome do Titular', expiry: 'Data Venc' }}
                      callback={this.getIssuerCardCorresp}
                    />
                  </div>
                  <div className="card-data">
                    <div className="row first">
                      <Input
                        type="text"
                        labelDescription="Titular do Cartão"
                        placeholder="Titular do Cartão"
                        id="name"
                        value={holderName}
                        disabled
                        onFocus={() => this.setState({ focusCard2: 'name' })}
                        onChange={this.handleChangeCardCorresp}
                      />
                    </div>
                    <div className="row">
                      <Input
                        type="text"
                        labelDescription="Número do Cartão"
                        placeholder="Número do Cartão"
                        id="number_corresp"
                        iconType="number"
                        mask="1111 1111 1111 1111"
                        value={number_corresp}
                        onFocus={() => this.setState({ focusCard2: 'number' })}
                        onChange={this.handleChangeCardCorresp}
                      />
                    </div>
                    <div className="multiple-input">
                      <Input
                        type="text"
                        labelDescription="Data de Expiração"
                        placeholder="Data de Expiração"
                        iconType="number"
                        id="expiry_corresp"
                        mask="11/11"
                        value={expiry_corresp}
                        onFocus={() => this.setState({ focusCard2: 'expiry' })}
                        onChange={this.handleChangeCardCorresp}
                      />

                      <Input
                        type="text"
                        labelDescription="Código de segurança"
                        maxLength="4"
                        placeholder="Código de segurança"
                        id="cvv_corresp"
                        onChange={this.handleChangeCardCorresp}
                        iconType="number"
                        value={cvv_corresp}
                        onFocus={() => this.setState({ focusCard2: 'cvc' })}
                        onBlur={() => this.setState({ focusCard2: '' })}
                        labelIcon={true}
                        labelIconMessage={
                          'O número código de segurança é representado pelos três últimos dígitos no verso do seu cartão. Em cartões American Express, o código de segurança é um número de 4 dígitos na frente do cartão.'
                        }
                      />
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}

            <div className="row">
              {loading && <div className="loader" />}
              {!loading && <Button className="confirm" description="confirmar" onClick={() => openConfirmation()} />}
            </div>
          </div>
        )}

        {error && (
          <div className="error">
            <h1 className="title">{this.state.errorMessage}</h1>
            <div className="images">
              <img
                className="image-house"
                src="https://cdn.iconscout.com/icon/free/png-256/house-home-building-infrastructure-real-estate-resident-emoj-symbol-1-30743.png"
                alt="icone casa"
              />

              <img
                className="image-error"
                src="https://www.shareicon.net/data/512x512/2016/08/18/810028_close_512x512.png"
                alt="icone erro"
              />
            </div>

            {this.state.contractUrl ? (
              <Button
                className="contract"
                description="Assinar contrato"
                onClick={() => {
                  window.open(this.state.contractUrl, '_blank')
                }}
              />
            ) : (
              ''
            )}
          </div>
        )}
      </div>
    )
  }
}

export default withSnackbar(VendaOnline)
