import { Badge, BadgeProps } from '@chakra-ui/react'
import { getPaymentStatus } from '../../../../util/getPaymentStatus'

interface PaymentStatusBadgeProps extends BadgeProps {
  status: number
}

export function PaymentStatusBadge({ status, ...rest }: PaymentStatusBadgeProps) {
  const { title, color } = getPaymentStatus(status)

  return (
    <Badge
      w="fit-content"
      h="fit-content"
      paddingX={2}
      fontSize="sm"
      fontWeight="normal"
      textTransform="unset"
      colorScheme={color}
      {...rest}
    >
      {title}
    </Badge>
  )
}
