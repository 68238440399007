import React from 'react'

class Documentacao extends React.Component {
    render() {
        return (
            <React.Fragment>
              <iframe  title="documentacao" style={{ width: "100vw", height: '100vh', border: 'none' }} className="iframeDocumentacao" src="http://localhost:3333/docs/"/>
            </React.Fragment>
        )
    }
}

export default Documentacao