const cellStyle = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  width: 300,
  maxWidth: 300,
  textOverflow: 'ellipsis',
}

const fullPlanColumns = [
  { title: 'Locatário', field: 'cliente', cellStyle },
  { title: 'Serviço', field: 'servico', cellStyle },
  { title: 'Status transação', field: 'status', cellStyle, export: true, hidden: true, searchable: true },
  { title: 'Status', field: 'status_column', cellStyle, export: false, searchable: false, align: 'center' },
  {
    title: 'Valor Total',
    field: 'totalValue',
    cellStyle,
    type: 'currency',
    currencySetting: { locale: 'pt-BR', currencyCode: 'BRL', minimumFractionDigits: 2, maximumFractionDigits: 2 },
  },
  { title: 'Taxa administrativa', field: 'administration_fee', cellStyle },
  { title: 'Data Início', field: 'dt_inicio', cellStyle, type: 'date', dateSetting: { locale: 'pt-BR' } },
  { title: 'Data Fim', field: 'dt_fim', cellStyle, type: 'date', dateSetting: { locale: 'pt-BR' } },
  { title: 'Usuário', field: 'userId', cellStyle },
]

const resalePlanColumns = [
  { title: 'Locatário', field: 'cliente', cellStyle },
  { title: 'Serviço', field: 'servico', cellStyle },
  { title: 'Status transação', field: 'status', cellStyle, export: true, hidden: true, searchable: true },
  { title: 'Status', field: 'status_column', cellStyle, export: false, searchable: false, align: 'center' },
  {
    title: 'Valor Total',
    field: 'totalValue',
    cellStyle,
    type: 'currency',
    currencySetting: { locale: 'pt-BR', currencyCode: 'BRL', minimumFractionDigits: 2, maximumFractionDigits: 2 },
  },
  { title: 'Taxa locatário', field: 'total_fee', cellStyle },
  { title: 'Cobertura Ucred', field: 'cover_value', cellStyle },
  { title: 'Data Início', field: 'dt_inicio', cellStyle, type: 'date', dateSetting: { locale: 'pt-BR' } },
  { title: 'Data Fim', field: 'dt_fim', cellStyle, type: 'date', dateSetting: { locale: 'pt-BR' } },
  { title: 'Usuário', field: 'userId', cellStyle },
]

export { fullPlanColumns, resalePlanColumns, cellStyle }
