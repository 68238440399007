import { Typography, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, TextField } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { DropzoneArea } from 'material-ui-dropzone'
import { withSnackbar } from 'notistack'
import React from 'react'
import ApiInstance from '../services/ApiInstance'

class ModalCancelTransaction extends React.Component {
  constructor() {
    super()

    this.state = {
      description: '',
      loading: false,
      files64: [],
    }
  }
  convertToBase64 = (file, cb) => {
    //Read File
    var selectedFile = file
    //Check File is not Empty
    if (selectedFile.length > 0) {
      // Select the very first file from list
      var fileToLoad = selectedFile[0]
      // FileReader function for read the file.
      var fileReader = new FileReader()
      var base64
      // Onload of file read the file content
      fileReader.onload = function (fileLoadedEvent) {
        base64 = fileLoadedEvent.target.result
        // Print data in console
        cb(base64)
      }
      // Convert data to base64
      fileReader.readAsDataURL(fileToLoad)
    }
  }
  render() {
    const { cancelTransaction30Days, selectedPurchase } = this.props.parent.state

    const { description, files64, loading } = this.state

    const handleChange = (event) => {
      const { name, value } = event.target

      this.setState({ [name]: value })
    }

    return (
      <React.Fragment>
        <Dialog disableScrollLock open={cancelTransaction30Days.open} onClose={() => this.props.parent.setState({ cancelTransaction30Days: { ...cancelTransaction30Days, open: false } })}>
          <DialogTitle
            disableTypography
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6">Deseja cancelar esta transação?</Typography>
            <IconButton onClick={() => this.props.parent.setState({ cancelTransaction30Days: { ...cancelTransaction30Days, open: false } })} size="small" style={{ marginLeft: 'auto' }}>
              <Close></Close>
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <DialogContentText>Para que nossa equipe possa efetuar o cancelamento da transação, solicitamos que sejam enviados os seguintes documentos:</DialogContentText>
            <DialogContentText>
              <ul style={{ paddingLeft: 32 }}>
                <li>Contrato de locação do locatário.</li>
                <li>Termo de entrega de chaves ou documento que comprove a entrega do imóvel.</li>
                <li> Laudo de vistoria de entrada e saída (não obrigatório).</li>
              </ul>
            </DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <DropzoneArea
                  maxFileSize={2000000}
                  dropzoneText={'Arraste e solte arquivos aqui ou clique para fazer upload. São aceitos aquivos .jpeg, .png, .bmp e .pdf'}
                  getFileLimitExceedMessage={(filesLimit) => `Limite máximo de arquivos excedidos. São permitidos no máximo ${filesLimit} arquivos.`}
                  getFileAddedMessage={(fileName) => `O arquivo ${fileName} foi adicionado.`}
                  getFileRemovedMessage={(fileName) => `O arquivo ${fileName} foi removido.`}
                  getDropRejectMessage={(file) => `O arquivo ${file.name} foi rejeitado. São aceitos no máximo 3 arquivos, com tamanho máximo de 2Mb nos formatos png, jpeg, bmp ou pdf.`}
                  filesLimit={10}
                  acceptedFiles={['image/jpeg', 'image/png', 'image/bmp', 'application/pdf']}
                  onChange={(files) => {
                    let filesArr = []
                    files.map((files) => {
                      this.convertToBase64([files], (base64) => {
                        filesArr.push({ name: files.name, base: base64 })
                      })
                    })
                    this.setState({ files64: filesArr })
                  }}
                ></DropzoneArea>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="description"
                  multiline
                  placeholder="Informe o motivo do cancelamento..."
                  label="Motivo"
                  fullWidth
                  variant="outlined"
                  onChange={handleChange}
                  value={description}
                  rows={4}
                ></TextField>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="primary" description="" disabled={loading} onClick={() => this.props.parent.setState({ cancelTransaction30Days: { ...cancelTransaction30Days, open: false } })}>
              Voltar
            </Button>
            <Button
              description=""
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={async () => {
                this.setState({ loading: true })
                if (!this.state.description) {
                  this.setState({ loading: false })
                  const snackbarKey = this.props.enqueueSnackbar('Informe o motivo do cancelamento.', {
                    variant: 'error',
                    onClick: () => {
                      this.props.closeSnackbar(snackbarKey)
                    },
                  })
                  return
                }

                if (!this.state.files64.length) {
                  this.setState({ loading: false })
                  const snackbarKey = this.props.enqueueSnackbar('Insira ao menos um documento.', {
                    variant: 'error',
                    onClick: () => {
                      this.props.closeSnackbar(snackbarKey)
                    },
                  })
                  return
                }

                await ApiInstance.post('cancel-request', {
                  files: files64,
                  description,
                  purchaseId: selectedPurchase[0].purchase.id
                })
                  .then(() => {
                    this.props.parent.setState({ cancelTransaction30Days: { ...cancelTransaction30Days, open: false } })
                    this.setState({ loading: false })
                    const snackbarKey = this.props.enqueueSnackbar('Solicitação de cancelamento enviada com sucesso.', {
                      variant: 'success',
                      onClick: () => {
                        this.props.closeSnackbar(snackbarKey)
                      },
                    })
                    return
                  })
                  .catch(() => {
                    const snackbarKey = this.props.enqueueSnackbar('Houve um erro inesperado, entre em contato com nosso suporte.', {
                      variant: 'error',
                      onClick: () => {
                        this.props.closeSnackbar(snackbarKey)
                      },
                    })
                    this.setState({ loading: false })
                  })
              }}
            >
              {loading && (
                <CircularProgress
                  size={24}
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: -12,
                    marginLeft: -12,
                  }}
                />
              )}
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}

export default withSnackbar(ModalCancelTransaction)
