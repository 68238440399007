/* eslint-disable no-async-promise-executor */
let URL = 'http://127.0.0.1:3390/api/v1/'

if (process.env.REACT_APP_STAGE === 'development') URL = 'http://127.0.0.1:3333/api/v1/'
if (process.env.REACT_APP_STAGE === 'staging') URL = 'https://app.hml.ucred.io/api/v1/'
if (process.env.REACT_APP_STAGE === 'production') URL = 'https://souzagomes.ucred.io/api/v1/'

const backendUrl = URL
class Api {
  constructor() {
    this.jwt = ''
  }

  setJwt(jwt) {
    this.jwt = jwt
    localStorage.setItem('jwt', jwt)
  }

  get(url, headers) {
    return this.fetch('GET', url, undefined, headers)
  }

  post(url, body, headers) {
    return this.fetch('POST', url, body, headers)
  }

  put(url, body, headers) {
    return this.fetch('PUT', url, body, headers)
  }

  delete(url, headers) {
    return this.fetch('DELETE', url, undefined, headers)
  }

  fetch(method, url, body, headers) {
    const jwt = localStorage.getItem('jwt')
    url = backendUrl + url.replace(/^\//, '') // remove first letter if it is '/'
    headers = {
      'Content-Type': 'application/json',
      Authorization: `bearer ${jwt}`,
      ...headers,
    }

    if (!jwt) headers.Authorization = null

    return new Promise(async (resolve, reject) => {
      try {
        const res = await window.fetch(url, {
          method,
          headers,
          body: JSON.stringify(body),
        })
        const resJson = await res.json()
        // trying to find generic errors
        if (resJson.error || res.status.toString()[0] === '4' || res.status.toString()[0] === '5') {
          const error = new Error(resJson.message)
          error.contractUrl = resJson.contractUrl
          error.contract = resJson.contract
          error.originalMessage = resJson.originalMessage
          error.code = resJson.code
          error.error = resJson.error
          error.purchase = resJson.purchase
          error.status = res.status
          reject(error)
        }

        resolve(resJson)
      } catch (error) {
        reject(error)
      }
    })
  }
}

const ApiInstance = new Api()

export default ApiInstance
