/* eslint-disable react/display-name */
/* eslint-disable camelcase */
import { useState, useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/pt-br'

import { Flex, Text, useToast } from '@chakra-ui/react'
import MaterialTable, { MTableToolbar } from 'material-table'

import localization from '../../locale/tableLocale'
import ApiInstance from '../../services/ApiInstance'
import tableIcons from '../../styles/tableIcons'

import {
  ColumnType,
  InvoicesFormattedProps,
  formatInvoices,
  columnsDefinitions,
} from './invoicesTableColumns'
import { TableSkeleton } from './TableSkeleton'

interface TableDataProps {
  rows: InvoicesFormattedProps[]
  columns: ColumnType[]
}

export function InvoicesTable() {
  const [invoices, setInvoices] = useState<TableDataProps>({} as TableDataProps)
  const history = useHistory()
  const toast = useToast()

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await ApiInstance.get('/invoices')
        const invoicesFormatted = formatInvoices(response.data)
        setInvoices({
          rows: invoicesFormatted,
          columns: columnsDefinitions,
        })
      } catch {
        toast({
          title: 'Houve um erro inesperado, entre em contato com nosso suporte.',
          status: 'error',
          duration: 2000,
          isClosable: true,
        })
      }
    }
    fetchData()
  }, [toast])

  const onClickRow = useCallback(
    ({ purchaseId, clientId }) =>
      history.push({
        pathname: `detalhe-do-cliente/${clientId}`,
        state: { tab: 1, detail: { purchaseId } },
      }),
    [history],
  )

  return (
    <>
      {!invoices ? (
        <TableSkeleton />
      ) : (
        <MaterialTable
          style={{
            width: '100%',
            paddingRight: 24,
            paddingLeft: 24,
            paddingTop: 10,
            maxWidth: '1600px',
          }}
          icons={tableIcons}
          columns={invoices.columns}
          options={{
            pageSize: 15,
            pageSizeOptions: [15, 50, 100],
            tableLayout: 'auto',
            showTitle: true,
            padding: 'dense',
            exportAllData: true,
            exportFileName:
              'Relatório Ucred (Sinistros) - ' + moment().format('DD-MM-YYYY H:mm:ss'),
            exportButton: true,
            actionsColumnIndex: -1,
          }}
          onRowClick={(event, rowData) => {
            onClickRow({ purchaseId: rowData?.purchase_id, clientId: rowData?.client.id })
          }}
          components={{
            Container: props => <div id="mtable-container" {...props}></div>,
            Toolbar: props => (
              <Flex w="100%" justify="space-between" mb={8}>
                <Flex align="center">
                  <Text fontSize="2xl" fontWeight="medium" color="gray.600">
                    Relatório de sinistros
                  </Text>
                </Flex>
                <Flex align="flex-end" justify="flex-start">
                  <MTableToolbar {...{ ...props, showTitle: false }} />
                </Flex>
              </Flex>
            ),
          }}
          data={invoices.rows}
          localization={localization}
        />
      )}
    </>
  )
}
