import React from 'react'
import HeaderRevendedor from '../../layout/HeaderRevendedor'
import ViewRevendedor from '../ViewRevendedor'
const HomeRevendedor = (props) => {
  return (
    <React.Fragment>
      <HeaderRevendedor />
      <div className="home">
        <div className="home__container">
          <div className="home__container-body">
            {/* <DashboardRevendedor /> */}
            <ViewRevendedor history={props.history} />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default HomeRevendedor
