import { forwardRef, ForwardRefRenderFunction } from 'react'
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Textarea as ChakraTextarea,
  TextareaProps as ChakraTextareaProps,
} from '@chakra-ui/react'
import { FieldErrors } from 'react-hook-form'

interface TextareaProps extends ChakraTextareaProps {
  name: string
  label: string
  color?: string
  error?: FieldErrors | null
  multiline?: boolean
}

interface TextareaRef extends HTMLTextAreaElement {
  focus: () => void
}
const TextareaBase: ForwardRefRenderFunction<TextareaRef, TextareaProps> = (
  { name, label, error, color = 'gray.700', ...rest },
  ref,
) => {
  return (
    <>
      <FormControl isInvalid={!!error}>
        {!!label && (
          <FormLabel
            color="gray.500"
            fontSize="xs"
            fontWeight="semibold"
            style={{ textTransform: 'uppercase' }}
            htmlFor={name}
          >
            {label}
          </FormLabel>
        )}
        <ChakraTextarea id={name} name={name} color={color} ref={ref} {...rest} />
        {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
      </FormControl>
    </>
  )
}

export const Textarea = forwardRef(TextareaBase)
