import { Badge, BadgeProps } from '@chakra-ui/react'
import { getInvoiceStatus } from '../../../../util/getInvoiceStatus'

interface InvoiceStatusBadgeProps extends BadgeProps {
  status: number
}

export function InvoiceStatusBadge({ status, ...rest }: InvoiceStatusBadgeProps) {
  const { title, color } = getInvoiceStatus(status)

  return (
    <Badge
      w="fit-content"
      h="fit-content"
      paddingX={2}
      paddingY={1}
      fontSize="xs"
      fontWeight="normal"
      textTransform="unset"
      colorScheme={color}
      {...rest}
    >
      {title}
    </Badge>
  )
}
