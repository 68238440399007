import { useRef, useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useToast,
  Text,
  Flex,
  Stack,
  ListItem,
  UnorderedList,
  InputLeftElement,
} from '@chakra-ui/react'

import { convertToBase64 } from '../../../../util/convertToBase64'
import ApiInstance from '../../../../services/ApiInstance'

import { CurrencyInput } from '../Form/CurrencyInput'
import { DropzoneArea } from '../DropzoneArea'
import { Textarea } from '../Form/Textarea'
import { SummaryType } from '../../types/summary'

interface AddInvoiceModalProps extends Pick<SummaryType, 'purchase'> {
  isOpen: boolean
  handleCloseModal: () => void
  loadTransactionDetails: ({ tabIndex }: { tabIndex: number }) => void
}

type InvoiceInputRef = {
  focus: () => void
}

interface DescriptionTextareaRef extends HTMLTextAreaElement {
  focus: () => void
}

export function AddInvoiceModal({
  purchase,
  isOpen,
  handleCloseModal,
  loadTransactionDetails,
}: AddInvoiceModalProps) {
  const MAX_VALUE = 15 * Number(purchase.totalValueLocCorresp)

  const toast = useToast()
  const invoiceInputRef = useRef<InvoiceInputRef>(null)
  const descriptionInputRef = useRef<DescriptionTextareaRef>(null)

  const [files64, setFiles64] = useState<any[]>([])
  const [loading, setLoading] = useState(false)

  const [description, setDescription] = useState<string | null>(null)
  const [descriptionInputError, setDescriptionInputError] = useState<string | null>(null)

  const [invoiceValue, setInvoiceValue] = useState<number | null>(null)
  const [invoiceInputError, setInvoiceInputError] = useState<string | null>(null)

  const resetInputFields = () => {
    setFiles64([])
    setDescription(null)
    setDescriptionInputError(null)
    setInvoiceValue(null)
    setInvoiceInputError(null)
  }

  const handleClose = () => {
    resetInputFields()
    handleCloseModal()
  }

  const handleTextareaChange = (event: { target: { value: string } }) => {
    setDescriptionInputError(null)
    const { value } = event.target
    setDescription(value)
  }

  const handleCurrencyChange = ({ floatValue }: { floatValue: number }) => {
    setInvoiceInputError(null)
    if (floatValue > MAX_VALUE)
      return setInvoiceInputError('Valor do sinistro está acima do permitido.')
    setInvoiceValue(floatValue)
  }

  async function onDropzoneChange(files: any[]) {
    const filesArr: any[] = []
    files.map(files => {
      convertToBase64([files], (base64: any) => {
        filesArr.push({ name: files.name, base: base64 })
      })
    })
    setFiles64(filesArr)
  }

  async function payWarrantyUcred(description: string) {
    try {
      await ApiInstance.post(
        'venda/executarGarantia',
        {
          value: invoiceValue,
          description,
          purchaseSelected: purchase.id,
          files: files64,
        },
        {},
      )
      toast({
        title: 'Solicitação de sinistro aberta com sucesso.',
        status: 'success',
        duration: 2000,
        isClosable: true,
      })
      resetInputFields()
      loadTransactionDetails({ tabIndex: 1 })
      handleCloseModal()
    } catch (error) {
      toast({
        title:
          'Não foi possível realizar a abertura do sinistro, entre em contato com nosso suporte.',
        status: 'error',
        duration: 2000,
        isClosable: true,
      })
    }
  }

  const validateInputFields = () => {
    if (!invoiceValue) {
      const errorMessage = 'Valor do sinistro é obrigatório.'
      setInvoiceInputError(errorMessage)
      invoiceInputRef.current?.focus()
      throw new Error(errorMessage)
    }
    if (invoiceInputError) {
      const errorMessage = invoiceInputError
      setInvoiceInputError(errorMessage)
      invoiceInputRef.current?.focus()
      throw new Error(errorMessage)
    }
    if (!description) {
      const errorMessage = 'Informe o motivo do cancelamento.'
      setDescriptionInputError(errorMessage)
      descriptionInputRef.current?.focus()
      throw new Error(errorMessage)
    }
    if (!files64.length) throw new Error('Insira ao menos um documento para acionar o sinistro.')
    if (purchase.status !== 3)
      throw new Error(
        'Não é possível executar sinistro em vendas com status diferente de concluída.',
      )
  }

  async function handleSubmit() {
    try {
      validateInputFields()
      setLoading(true)
      const isWarrantyUcred = await ApiInstance.get(`venda/garantia/${purchase.id}`)
      if (isWarrantyUcred.data && description) return payWarrantyUcred(description)
      setLoading(false)
    } catch (error: any) {
      toast({
        title: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      })
      setLoading(false)
    }
  }

  return (
    <Modal
      size="3xl"
      isCentered
      isOpen={isOpen}
      onClose={handleCloseModal}
      scrollBehavior="inside"
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontWeight="normal" color="gray.600">
          Acionar Sinistro
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Stack spacing={8}>
            <Text fontSize="md" color="gray.600">
              Para efetivar o acionamento de sinistro, solicitamos que sejam anexados abaixo os
              seguintes documentos:
            </Text>
            <Flex>
              <UnorderedList color="gray.500" fontSize="sm" pl={4}>
                <ListItem>Contrato de locação do locatário.</ListItem>
                <ListItem>
                  Laudo de vistoria de entrada e saída (se for um caso de rescisão contratual ou
                  saída do imóvel).
                </ListItem>
                <ListItem>
                  O cálculo para valor da multa de rescisão (se for um caso de rescisão contratual).
                </ListItem>
                <ListItem>
                  Orçamento dos reparos no imóvel (se for um caso de rescisão contratual ou saída do
                  imóvel).
                </ListItem>
                <ListItem>
                  O boletos inadimplentes em nome da locatário, com os valores atualizados.
                </ListItem>
              </UnorderedList>
            </Flex>
            <CurrencyInput
              autoFocus
              isFullWidth
              name="invoiceValue"
              label="Valor do sinistro"
              error={invoiceInputError ? { message: invoiceInputError } : null}
              onValueChange={handleCurrencyChange}
              prefix=""
              leftElement={
                <InputLeftElement
                  pointerEvents="none"
                  color="gray.400"
                  fontSize="1em"
                  ml={1}
                  fontWeight="medium"
                >
                  R$
                </InputLeftElement>
              }
              ref={invoiceInputRef}
            />

            <Textarea
              rows={4}
              multiline
              resize="none"
              name="description"
              label="Motivo do sinistro"
              placeholder="Descreva o motivo do sinistro..."
              error={descriptionInputError ? { message: descriptionInputError } : null}
              onChange={handleTextareaChange}
              ref={descriptionInputRef}
            />
            <DropzoneArea
              dropzoneText="Arraste e solte arquivos aqui ou clique para fazer upload. São aceitos aquivos .jpeg, .png, .bmp e .pdf"
              onChange={onDropzoneChange}
            />
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button color="gray.600" mr={3} disabled={loading} onClick={handleClose}>
            Voltar
          </Button>
          <Button isLoading={loading} colorScheme="blue" onClick={handleSubmit}>
            Confirmar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
