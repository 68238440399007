import DateFnsUtils from '@date-io/moment'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Slide,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import moment from 'moment'
import { withSnackbar } from 'notistack'
import React from 'react'
import MaskedInput from 'react-input-mask'
import { connect } from 'react-redux'
import ApiInstance from '../../services/ApiInstance'
import ProductCard from '../../components/productCard'
import validateCpf from '../../functions/validateCpf'
import Footer from '../../layout/Footer'
import { fillField, refresh } from '../../redux/actions/creditAnalysisActions'
import { consultaCep } from '../../util/cep'

class Step2 extends React.Component {
  state = {
    serviceActive: -1,
    focusCard: 'name',
    focusCardCorresp: 'name',
    newContactClient: false,
    newContactCorresp: false,
    hasContract: false,
    dialogVerifyEmail: false,
    dialogEmail: '',
    errors: [],
    adhesionConfirm: false,
  }

  handleChangeClient = event => {
    const { client, corresponsavel, clientCorresp, fillField, creditCardResp, creditCardCorresp } =
      this.props
    const { value, name } = event.target
    let { errors } = this.state

    if (name === 'email') {
      errors = errors.filter(item => item.error !== 'CUSTOMER_EMAIL')
      errors = errors.filter(item => item.error !== 'CUSTOMER_CONFIRM_EMAIL')
    }
    if (name === 'confirm_email') {
      errors = errors.filter(item => item.error !== 'CUSTOMER_EMAIL')
      errors = errors.filter(item => item.error !== 'CUSTOMER_CONFIRM_EMAIL')
    }

    if (name === 'telephone_1' && value.length > 2)
      errors = errors.filter(item => item.error !== 'CUSTOMER_PHONE')

    this.setState({ errors })

    fillField('client', { ...client, [name]: value })
    if (name === 'name') {
      fillField('creditCardResp', { ...creditCardResp, name: value })
    }
    if (clientCorresp) {
      fillField('corresponsavel', { ...corresponsavel, [name]: value })
      if (name === 'name') fillField('creditCardCorresp', { ...creditCardCorresp, name: value })
    }
  }

  handleChangeClientBirthday = value => {
    let { errors } = this.state
    errors = errors.filter(item => item.error !== 'CUSTOMER_BIRTHDAY')
    this.setState({ errors })

    let birthday = String(moment(value).format('DD/MM/YYYY'))
    const { client, fillField } = this.props
    if (birthday === 'Invalid date') birthday = null
    fillField('client', { ...client, birthday: birthday })
  }

  handleChangeCorresponsavel = event => {
    const { corresponsavel, fillField, creditCardCorresp } = this.props
    const { value, id } = event.target

    let { errors } = this.state

    if (id === 'name') errors = errors.filter(item => item.error !== 'CORRESPONSIBLE_NAME')

    if (id === 'cpf') errors = errors.filter(item => item.error !== 'CORRESPONSIBLE_CPF')

    if (id === 'email') {
      errors = errors.filter(item => item.error !== 'CORRESPONSIBLE_EMAIL')
      errors = errors.filter(item => item.error !== 'CORRESPONSIBLE_CONFIRM_EMAIL')
    }

    if (id === 'confirm_email') {
      errors = errors.filter(item => item.error !== 'CORRESPONSIBLE_EMAIL')
      errors = errors.filter(item => item.error !== 'CORRESPONSIBLE_CONFIRM_EMAIL')
    }

    if (id === 'telephone_1' && value.length > 2)
      errors = errors.filter(item => item.error !== 'CORRESPONSIBLE_PHONE')

    this.setState({ errors })

    fillField('corresponsavel', { ...corresponsavel, [id]: value })
    if (id === 'name') fillField('creditCardCorresp', { ...creditCardCorresp, name: value })
  }

  handleChangeCorrespBirthday = value => {
    let { errors } = this.state
    errors = errors.filter(item => item.error !== 'CORRESPONSIBLE_BIRTHDAY')
    this.setState({ errors })

    let birthday = String(moment(value).format('DD/MM/YYYY'))
    const { corresponsavel, fillField } = this.props
    if (birthday === 'Invalid date') birthday = null
    fillField('corresponsavel', { ...corresponsavel, birthday: birthday })
  }

  newContactClient = () => {
    const { newContactClient } = this.state
    this.setState({ newContactClient: !newContactClient })
  }

  newContactCorresp = () => {
    const { newContactCorresp } = this.state
    this.setState({ newContactCorresp: !newContactCorresp })
  }

  selectedProduct = (index, value) => {
    const { fillField, services, has_corresponsible, creditCardResp } = this.props
    fillField('selectedService', services[index])
    fillField('finalValue', Number(value))
    fillField('serviceActive', index)
    if (!has_corresponsible) {
      fillField('creditCardResp', { ...creditCardResp, value: value })
    }
  }

  checkEmail = () => {
    const { client } = this.props
    if (client.email) {
      const mailCheck = client.email.split('@')
      if (mailCheck.length < 2) {
        const snackbarKey = this.props.enqueueSnackbar('Email inválido.', {
          variant: 'error',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        })
      }
    }
  }

  checkEmailCorresp = () => {
    const { corresponsavel } = this.props
    if (!corresponsavel.email) return
    const mailCheck = corresponsavel.email.split('@')
    if (mailCheck.length < 2) {
      const snackbarKey = this.props.enqueueSnackbar('Email inválido.', {
        variant: 'error',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
    }
  }

  checkCorresp = event => {
    const { checked } = event.target
    const { fillField, client, creditCardCorresp } = this.props
    if (checked) {
      fillField('corresponsavel', { ...client })
      fillField('creditCardCorresp', { ...creditCardCorresp, name: client.name })
      fillField('clientCorresp', true)
      this.setState({ clientCorresp: true })
    } else {
      fillField('corresponsavel', {
        id_vindi: null,
        id: null,
        name: '',
        cpf: '',
        email: '',
        telephone_1: '',
        telephone_2: '',
        card_maturity: '',
        city: '',
        address: '',
        birthday: null,
        confirm_email: '',
      })
      fillField('creditCardCorresp', {
        ...creditCardCorresp,
        name: '',
      })
      this.setState({ clientCorresp: false })
      fillField('clientCorresp', false)
    }
  }

  changeAdhesion = event => {
    const { fillField, adhesion } = this.props
    const { value } = event.target
    fillField('adhesion', { ...adhesion, value })
  }

  changeCep = async event => {
    try {
      const { value } = event.target
      const { fillField } = this.props

      let { errors } = this.state

      errors = errors.filter(item => item.error !== 'IMMOBILE_CEP')

      const cep = value.split('-').join('').split('_').join('')
      if (cep.length === 8) {
        const viaCep = await consultaCep(cep)
        const dataAddress = {
          city: viaCep.localidade ? viaCep.localidade : '',
          cep: value,
          number: '',
          street: viaCep.logradouro ? viaCep.logradouro : '',
          neighborhood: viaCep.bairro ? viaCep.bairro : '',
          state: viaCep.uf,
          country: 'BR',
        }
        fillField('immobile', { ...dataAddress })

        errors = errors.filter(item => item.error !== 'IMMOBILE_CITY')
        errors = errors.filter(item => item.error !== 'IMMOBILE_STREET')
        errors = errors.filter(item => item.error !== 'IMMOBILE_NUMBER')
        errors = errors.filter(item => item.error !== 'IMMOBILE_NEIGHBORHOOD')
        this.setState({ errors })
      } else {
        fillField('immobile', { cep, street: '', city: '', number: '' })

        errors = errors.filter(item => item.error !== 'IMMOBILE_CITY')
        errors = errors.filter(item => item.error !== 'IMMOBILE_STREET')
        errors = errors.filter(item => item.error !== 'IMMOBILE_NUMBER')
        errors = errors.filter(item => item.error !== 'IMMOBILE_NEIGHBORHOOD')
        this.setState({ errors })
      }

      this.setState({ errors })
    } catch (error) {
      const snackbarKey = this.props.enqueueSnackbar('Não foi possível localizar o CEP.', {
        variant: 'error',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
    }
  }

  changeCnpj = async event => {
    try {
      const { value } = event.target
      const cnpj = value
      const check = value
        .split('.')
        .join('')
        .split('/')
        .join('')
        .split('-')
        .join('')
        .split('_')
        .join('')
      const { fillField, client } = this.props
      fillField('client', { ...client, cnpj })
      if (check.length === 14) {
        const { companyName } = await ApiInstance.get(`cnpjAnalyse?cnpj=${cnpj}`)
        fillField('client', { ...client, cnpj, company_name: companyName })
      } else {
        fillField('client', { ...client, cnpj, company_name: '' })
      }
    } catch (error) {
      if (error.originalMessage == 'CNPJ inválido') {
        const snackbarKey = this.props.enqueueSnackbar('CNPJ inválido.', {
          variant: 'error',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        })
      } else {
        const snackbarKey = this.props.enqueueSnackbar('CNPJ não encontrado.', {
          variant: 'error',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        })
      }
    }
  }

  changeAddress = event => {
    const { id, value } = event.target
    const { fillField, immobile } = this.props
    let { errors } = this.state

    if (id === 'street') errors = errors.filter(item => item.error !== 'IMMOBILE_STREET')
    if (id === 'state') errors = errors.filter(item => item.error !== 'IMMOBILE_STATE')
    if (id === 'city') errors = errors.filter(item => item.error !== 'IMMOBILE_CITY')

    this.setState({ errors })
    fillField('immobile', { ...immobile, [id]: value })
  }

  changeNumber = event => {
    const { id, value } = event.target
    const { fillField, immobile } = this.props
    let { errors } = this.state

    errors = errors.filter(item => item.error !== 'IMMOBILE_NUMBER')
    this.setState({ errors })
    fillField('immobile', { ...immobile, [id]: value })
  }

  changeNeighborhood = event => {
    const { id, value } = event.target
    const { fillField, immobile } = this.props

    fillField('immobile', { ...immobile, [id]: value })
  }

  changeComplement = event => {
    const { id, value } = event.target
    const { fillField, immobile } = this.props

    fillField('immobile', { ...immobile, [id]: value })
  }

  changeValueCard = (event, value) => {
    let { finalValue, fillField, creditCardResp, creditCardCorresp } = this.props
    let restValue = 0
    finalValue = Number(finalValue)
    value = value.split('.').join('').replace(',', '.')
    if (Number(value) > finalValue) value = finalValue
    restValue = Number(finalValue) - Number(value)

    fillField('creditCardResp', { ...creditCardResp, value })
    fillField('creditCardCorresp', { ...creditCardCorresp, value: restValue.toFixed(2) })
  }

  checkMinValue = () => {
    const { finalValue, fillField, creditCardResp, creditCardCorresp } = this.props
    let object = {
      value: Number(creditCardResp.value),
      restValue: 0,
      valueMin: Number(finalValue) * 0.51,
      maxValue: Number(finalValue) - 30,
      finalValue: Number(finalValue),
    }

    object = JSON.parse(JSON.stringify(object))

    if (object.value > object.maxValue) {
      object.value = object.maxValue
      const snackbarKey = this.props.enqueueSnackbar(
        'O valor mínimo do corresponsável deve ser de R$ 30,00.',
        {
          variant: 'info',
          autoHideDuration: 4000,
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        },
      )
    }

    if (object.value < object.valueMin) {
      object.value = object.valueMin
      const snackbarKey = this.props.enqueueSnackbar(
        'O valor mínimo a ser passado no cartão do locatário deve ser 51% do valor total.',
        {
          variant: 'info',
          autoHideDuration: 4000,
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        },
      )
    }

    object.restValue = object.finalValue - object.value

    setTimeout(() => {
      fillField('creditCardResp', { ...creditCardResp, value: object.value })
      fillField('creditCardCorresp', { ...creditCardCorresp, value: object.restValue })
    }, 50)
  }

  VerifyEmail = async () => {
    this.props.changeLoading(true)
    let error = false
    this.setState({ dialogEmail: null })

    let email = ''
    let dialog = false

    await ApiInstance.post('check_email', { email: this.props.client.email })
      .then(response => {
        if (response.data && response.data.smtpCheck === 'false') {
          error = true
          dialog = true
          email = this.props.client.email
        }
      })
      .catch(() => {
        this.props.changeLoading(false)
      })

    if (this.props.corresponsavel.email) {
      await ApiInstance.post('check_email', { email: this.props.corresponsavel.email })
        .then(response => {
          if (response.data && response.data.smtpCheck === 'false') {
            error = true
            dialog = true
            email = email
              ? `${email}, ${this.props.corresponsavel.email}`
              : this.props.corresponsavel.email
          }
        })
        .catch(() => {
          this.props.changeLoading(false)
        })
    }

    this.setState({ dialogVerifyEmail: dialog, dialogEmail: email })

    if (error) {
      this.props.changeLoading(false)
      return true
    } else {
      return false
    }
  }

  next = async () => {
    if (!this.state.adhesionConfirm && !this.props.adhesion.value) {
      return this.setState({ adhesionConfirm: true })
    }

    if (this.props.corresponsavel.email) {
      this.checkMinValue()
    }

    if (
      this.props.client.email &&
      this.props.client.email === this.props.corresponsavel.email &&
      !this.props.clientCorresp
    ) {
      this.props.changeLoading(false)
      const snackbarKey = this.props.enqueueSnackbar(
        'O email do locatário não pode ser o mesmo do corresponsável.',
        {
          variant: 'error',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        },
      )

      return
    }
    try {
      this.props.changeLoading(false)
      return this.confirmData()
    } catch (error) {
      const snackbarKey = this.props.enqueueSnackbar(error.message, {
        variant: 'error',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
    }
  }

  back = () => {
    this.props.back()
  }

  sendData = async () => {
    try {
      let {
        corresponsavel,
        client,
        has_corresponsible,
        creditCardResp,
        creditCardCorresp,
        fillField,
        contract,
        clientCorresp,
        confirmarEmail,
        immobile,
      } = this.props

      const addresStreet = immobile.street.split('-')[0]
      const addressNeighborhood = immobile.street.split('-')[1]

      const address = {
        street: immobile.street,
        number: immobile.number,
        zipcode: immobile.cep,
        neighborhood: immobile.neighborhood,
        city: immobile.city,
        state: immobile.uf,
        country: immobile.country,
      }

      if (confirmarEmail) {
        creditCardResp = {
          ...creditCardResp,
          type: 'mastercard',
        }

        creditCardCorresp = {
          ...creditCardCorresp,
          type: 'mastercard',
        }
      }

      const resp = await ApiInstance.post('InicioTransacao', {
        address,
        client: { ...client, confirm_email: null, card_maturity: creditCardResp.card_maturity },
        corresponsavel: { ...corresponsavel, card_maturity: creditCardCorresp.card_maturity },
        has_corresponsible,
        creditCardResp,
        creditCardCorresp,
        contract,
        clientCorresp,
      })
      fillField('client', {
        ...client,
        id: resp.data.clientMain.id,
        id_vindi: resp.data.clientMain.id_vindi,
        second_id_vindi: resp.data.clientMain.second_id_vindi,
      })
      fillField('corresponsavel', {
        ...corresponsavel,
        id: resp.data.corresponsavelMain.id,
        id_vindi: resp.data.corresponsavelMain.id_vindi,
      })
      this.props.changeLoading(false)
      this.props.nextStep()
    } catch (error) {
      this.props.changeLoading(false)
      const snackbarKey = this.props.enqueueSnackbar(error.message, {
        variant: 'error',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
    }
  }

  validateData = () => {
    const {
      corresponsavel,
      client,
      has_corresponsible,
      immobile,
      clientCorresp,
      serviceActive,
      services,
    } = this.props

    const errors = []

    /* -------------------------------------------------------------------------- */
    /*                                 VALIDAÇÕES                                 */
    /* -------------------------------------------------------------------------- */

    /* --------------------------------- SERVIÇO -------------------------------- */

    if (Number(serviceActive) < 0) {
      errors.push({ error: 'SERVICE', message: 'Informe o serviço a ser contratado.' })
      this.cards.setState({ error: true })
    }

    /* --------------------------------- CLIENTE -------------------------------- */

    // NAME
    if (!client.name)
      errors.push({ error: 'CUSTOMER_NAME', message: 'Informe o nome do locatário.' })
    if (client.name.split(' ').length < 2)
      errors.push({ error: 'CUSTOMER_NAME', message: 'Informe o sobrenome do locatário.' })

    // BIRTHDAY
    if (!client.birthday)
      errors.push({
        error: 'CUSTOMER_BIRTHDAY',
        message: 'Informe a data de nascimento do locatário.',
      })
    if (moment().diff(moment(client.birthday, 'DD/MM/YYYY'), 'years') < 18) {
      errors.push({ error: 'CUSTOMER_BIRTHDAY', message: 'O locatário deve ser maior de 18 anos.' })
    }

    // EMAIL
    if (!client.email)
      errors.push({ error: 'CUSTOMER_EMAIL', message: 'Informe o email do locatário.' })
    if (!client.confirm_email)
      errors.push({ error: 'CUSTOMER_CONFIRM_EMAIL', message: 'Confirme o email do locatário.' })
    if (client.email !== client.confirm_email) {
      errors.push({ error: 'CUSTOMER_EMAIL', message: 'Email e confirmação não conferem.' })
      errors.push({ error: 'CUSTOMER_CONFIRM_EMAIL' })
    }

    // PHONE
    if (!client.telephone_1)
      errors.push({ error: 'CUSTOMER_PHONE', message: 'Informe o telefone do locatário.' })
    if (client.telephone_1.length < 14)
      errors.push({ error: 'CUSTOMER_PHONE', message: 'Telefone do locatário é inválido.' })
    if (client.telephone_1.length === 15 && client.telephone_1[5] !== '9')
      errors.push({ error: 'CUSTOMER_PHONE', message: 'Telefone do locatário é inválido.' })

    // COMPANY
    if (services[0].type_imobile === 1) {
      if (!client.cnpj) errors.push({ error: 'CNPJ', message: 'Informe o CNPJ da empresa.' })
      if (!client.company_name)
        errors.push({ error: 'SOCIAL_REASON', message: 'Informe a razão social da empresa.' })
    }

    /* ----------------------------- CORRESPONSAVEL ----------------------------- */

    if (has_corresponsible) {
      if (!clientCorresp) {
        // NAME
        if (!corresponsavel.name)
          errors.push({
            error: 'CORRESPONSIBLE_NAME',
            message: 'Informe o nome do corresponsável.',
          })
        if (corresponsavel.name && corresponsavel.name.split(' ').length < 2)
          errors.push({
            error: 'CORRESPONSIBLE_NAME',
            message: 'Informe o sobrenome do corresponsável.',
          })

        // CPF
        if (!corresponsavel.cpf)
          errors.push({ error: 'CORRESPONSIBLE_CPF', message: 'Informe o CPF do corresponsável.' })
        if (corresponsavel.cpf) {
          const validation = validateCpf(corresponsavel.cpf)
          if (validation.error) {
            errors.push({
              error: 'CORRESPONSIBLE_CPF',
              message: 'CPF do corresponsável é inválido.',
            })
          }
        }

        // BIRTHDAY
        if (!corresponsavel.birthday)
          errors.push({
            error: 'CORRESPONSIBLE_BIRTHDAY',
            message: 'Informe a data de nascimento do corresponsável.',
          })
        if (moment().diff(moment(corresponsavel.birthday, 'DD/MM/YYYY'), 'years') < 18) {
          errors.push({
            error: 'CORRESPONSIBLE_BIRTHDAY',
            message: 'O corresponsável deve ser maior de 18 anos.',
          })
        }

        // EMAIL
        if (!corresponsavel.email)
          errors.push({
            error: 'CORRESPONSIBLE_EMAIL',
            message: 'Informe o email do corresponsável.',
          })
        if (!corresponsavel.confirm_email)
          errors.push({
            error: 'CORRESPONSIBLE_CONFIRM_EMAIL',
            message: 'Confirme o email do corresponsável.',
          })
        if (corresponsavel.email !== corresponsavel.confirm_email) {
          errors.push({
            error: 'CORRESPONSIBLE_EMAIL',
            message: 'Email e confirmação não conferem.',
          })
          errors.push({ error: 'CORRESPONSIBLE_CONFIRM_EMAIL' })
        }

        // PHONE
        if (!corresponsavel.telephone_1)
          errors.push({
            error: 'CORRESPONSIBLE_PHONE',
            message: 'Informe o telefone do corresponsável.',
          })
        if (corresponsavel.telephone_1.length < 14)
          errors.push({
            error: 'CORRESPONSIBLE_PHONE',
            message: 'Telefone do corresponsável é inválido.',
          })
        if (corresponsavel.telephone_1.length === 15 && corresponsavel.telephone_1[5] !== '9')
          errors.push({
            error: 'CORRESPONSIBLE_PHONE',
            message: 'Telefone do corresponsável é inválido.',
          })
      }
    }

    /* -------------------------------- ENDEREÇO -------------------------------- */

    if (!immobile.cep) errors.push({ error: 'IMMOBILE_CEP', message: 'Informe o CEP do imóvel.' })
    if (!immobile.street)
      errors.push({ error: 'IMMOBILE_STREET', message: 'Informe o logradouro do imóvel.' })
    if (!immobile.number)
      errors.push({ error: 'IMMOBILE_NUMBER', message: 'Informe o número do imóvel.' })
    if (!immobile.neighborhood)
      errors.push({ error: 'IMMOBILE_NEIGHBORHOOD', message: 'Informe o bairro do imóvel.' })
    if (!immobile.city)
      errors.push({ error: 'IMMOBILE_CITY', message: 'Informe a cidade do imóvel.' })

    this.setState({ errors: errors })

    errors.map(error => {
      if (error.message) {
        const snackbarKey = this.props.enqueueSnackbar(error.message, {
          variant: 'error',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        })
      }
    })

    if (errors.length) {
      return true
    } else {
      return false
    }
  }

  confirmData = async () => {
    const { immobile } = this.props

    const address = `${immobile.street}, ${immobile.number}${immobile.complement ? ', ' : ''}${
      immobile.complement || ''
    } - ${immobile.neighborhood}`
    immobile.address = address

    const hasError = this.validateData()
    if (hasError) return this.props.changeLoading(false)

    const emailError = await this.VerifyEmail()
    if (emailError) return this.props.changeLoading(false)

    this.sendData()
  }

  selecionaServicoDefault() {
    const { services, transactionValue } = this.props
    const valor = this.calcValue(services[0], transactionValue)
    this.selectedProduct(0, valor)
  }

  calcValue = (service, valorTransacao) => {
    const transactionValue = Number(valorTransacao)
    const TAXA_FINAL = Number(service.administration_fee) + Number(service.over_value)
    // Encontra o valor da mensalidade
    let value = Number(transactionValue) * (TAXA_FINAL / 100)
    // Multiplica pelo tempo de contrato
    value = value * Number(service.contract_time)
    // Adiciona taxa de adesao
    value = value + Number(service.adhesion_fee)
    return value.toFixed(2)
  }

  render() {
    const {
      has_corresponsible,
      services,
      transactionValue,
      selectedService,
      finalValue,
      adhesion,
      immobile,
      serviceActive,
      confirmarEmail,
      authUser,
    } = this.props
    const { dialogVerifyEmail, dialogEmail, clientCorresp, errors } = this.state

    const Transition = React.forwardRef(function Transition(props, ref) {
      return <Slide direction="up" ref={ref} {...props} />
    })

    if (serviceActive == -1 && services.length === 1) this.selecionaServicoDefault()
    return (
      <>
        <Grid container spacing={3}>
          {/* -------------------------------------------------------------------------- */}
          {/*                             SERVIÇO CONTRATADO                             */}
          {/* -------------------------------------------------------------------------- */}
          <Grid item xs={12} id="service">
            <Box
              style={{
                background: '#fff',
                borderRadius: 16,
                boxShadow: '0px 0px 10px 0px rgb(0, 0, 0, .05)',
                overflow: 'hidden',
              }}
            >
              <Box style={{ background: '#044c94', color: '#fff' }}>
                <Typography variant="h6" style={{ padding: '16px 24px' }}>
                  Serviço a ser contratado
                </Typography>
              </Box>
              <Divider></Divider>
              <Grid container spacing={2} style={{ padding: '16px 0' }}>
                <Grid item xs={12}>
                  <ProductCard
                    ref={reference => (this.cards = reference)}
                    services={services}
                    transactionValue={transactionValue}
                    selectedProduct={this.selectedProduct}
                    index={serviceActive}
                    plan_id={authUser ? authUser.plan_id : null}
                    error={!!errors.filter(field => field.error === 'SERVICE').length}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>

          {/* -------------------------------------------------------------------------- */}
          {/*                           CONFIGURAÇÕES DA ADESÃO                          */}
          {/* -------------------------------------------------------------------------- */}

          <Grid item xs={12}>
            <Box
              style={{
                background: '#fff',
                borderRadius: 16,
                boxShadow: '0px 0px 10px 0px rgb(0, 0, 0, .05)',
                overflow: 'hidden',
              }}
            >
              <Box style={{ background: '#044c94', color: '#fff' }}>
                <Typography variant="h6" style={{ padding: '16px 24px' }}>
                  Configurações da transação
                </Typography>
              </Box>
              <Divider></Divider>
              <Grid container spacing={2} style={{ padding: '16px' }}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography>Serviço Contratado</Typography>
                      <Typography color="textSecondary">{selectedService.name || '-'}</Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography>Taxa de adesão</Typography>

                      <FormControl size="small" style={{ width: 300 }}>
                        <Select
                          id="adhesion-select"
                          variant="outlined"
                          size="small"
                          onChange={this.changeAdhesion}
                          value={adhesion.value ? adhesion.value : 0}
                        >
                          {[0, 30, 60, 90, 120].map(adhesion => (
                            <MenuItem value={adhesion} key={adhesion}>
                              R$ {`${adhesion}.00`}{' '}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Typography align="center">
                    O locatário poderá parcelar essa{' '}
                    <Typography color="primary" component="span">
                      venda em até 12 vezes de R$ {Number(Number(finalValue) / 12).toFixed(2)}
                    </Typography>
                    {!!Number(adhesion.value) && (
                      <>
                        {' '}
                        e a{' '}
                        <Typography color="primary" component={'span'}>
                          adesão em até 3 vezes de R${' '}
                          {Number(Number(adhesion.value) / 3).toFixed(2)}
                        </Typography>
                        .
                      </>
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          {/* -------------------------------------------------------------------------- */}
          {/*                             DADOS DO LOCATÁRIO                             */}
          {/* -------------------------------------------------------------------------- */}

          <Grid item md={has_corresponsible ? 6 : 12}>
            <Box
              style={{
                background: '#fff',
                borderRadius: 16,
                boxShadow: '0px 0px 10px 0px rgb(0, 0, 0, .05)',
                overflow: 'hidden',
              }}
            >
              <Box style={{ background: '#044c94', color: '#fff' }}>
                <Typography variant="h6" style={{ padding: '16px 24px' }}>
                  Dados do locatário
                </Typography>
              </Box>
              <Divider></Divider>
              <Grid container spacing={2} style={{ padding: '16px' }}>
                <Grid item xs={12} lg={12}>
                  {this.props.client.analysed == 1 &&
                  process.env.REACT_APP_STAGE !== 'development' ? (
                    <TextField
                      InputProps={{
                        disabled: false,
                        autoComplete: 'nope',
                      }}
                      fullWidth
                      name="name"
                      format="DD/MM/YYYY"
                      error={!!errors.filter(field => field.error === 'CUSTOMER_NAME').length}
                      size="small"
                      variant="outlined"
                      placeholder="Nome"
                      label="Nome"
                      value={this.props.client.name || ''}
                      onChange={this.handleChangeClient}
                    />
                  ) : (
                    <TextField
                      InputProps={{
                        autoComplete: 'nope',
                      }}
                      fullWidth
                      name="name"
                      size="small"
                      variant="outlined"
                      placeholder="Nome"
                      label="Nome"
                      value={this.props.client.name || ''}
                      onChange={this.handleChangeClient}
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MaskedInput
                    InputProps={{
                      disabled: true,
                      autoComplete: 'nope',
                    }}
                    maskChar=""
                    mask="999.999.999-99"
                    fullWidth
                    size="small"
                    variant="outlined"
                    placeholder="123.456.789-00"
                    label="CPF do locatário"
                    name="cpf"
                    value={this.props.client.cpf || ''}
                    onChange={() => {}}
                  >
                    {inputProps => <TextField {...inputProps} />}
                  </MaskedInput>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      fullWidth
                      disabled={
                        this.props.client.analysed === 1 &&
                        process.env.REACT_APP_STAGE !== 'development'
                      }
                      disableFuture
                      openTo="year"
                      error={!!errors.filter(field => field.error === 'CUSTOMER_BIRTHDAY').length}
                      size="small"
                      name="birthday"
                      format="DD/MM/YYYY"
                      maxDate={moment().add('-18', 'years').format('YYYY-MM-DD')}
                      inputVariant="outlined"
                      invalidDateMessage="Data inválida"
                      maxDateMessage="O locatário precisa ter 18 anos ou mais."
                      value={
                        this.props.client.birthday
                          ? moment(this.props.client.birthday, 'DD/MM/YYYY').format('YYYY-MM-DD')
                          : null
                      }
                      onChange={date => this.handleChangeClientBirthday(date)}
                      placeholder="01/01/1990"
                      label="Data de nascimento"
                    />
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    name="email"
                    error={!!errors.filter(field => field.error === 'CUSTOMER_EMAIL').length}
                    fullWidth
                    InputProps={{
                      autoComplete: 'nope',
                    }}
                    size="small"
                    variant="outlined"
                    placeholder="E-mail"
                    label="E-mail"
                    onChange={this.handleChangeClient}
                    value={this.props.client.email || ''}
                    onBlur={this.checkEmail}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    name="confirm_email"
                    error={
                      !!errors.filter(field => field.error === 'CUSTOMER_CONFIRM_EMAIL').length
                    }
                    fullWidth
                    InputProps={{
                      autoComplete: 'nope',
                    }}
                    size="small"
                    variant="outlined"
                    placeholder="Confirmar E-mail"
                    label="Confirmar E-mail"
                    onChange={this.handleChangeClient}
                    value={this.props.client.confirm_email || ''}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <MaskedInput
                    fullWidth
                    maskChar=""
                    InputProps={{
                      autoComplete: 'nope',
                    }}
                    name="telephone_1"
                    error={!!errors.filter(field => field.error === 'CUSTOMER_PHONE').length}
                    size="small"
                    variant="outlined"
                    mask="(99) 99999-9999"
                    placeholder="(32) 12345-6789"
                    label="Contato principal"
                    onChange={this.handleChangeClient}
                    value={this.props.client.telephone_1 || ''}
                  >
                    {inputProps => <TextField {...inputProps} />}
                  </MaskedInput>
                </Grid>

                <Grid item xs={12} md={6}>
                  <MaskedInput
                    fullWidth
                    maskChar=""
                    InputProps={{
                      autoComplete: 'nope',
                    }}
                    name="telephone_2"
                    size="small"
                    variant="outlined"
                    mask="(99) 99999-9999"
                    placeholder="(32) 12345-6789"
                    label="Contato alternativo (Opcional)"
                    onChange={this.handleChangeClient}
                    value={this.props.client.telephone_2 || ''}
                  >
                    {inputProps => <TextField {...inputProps} />}
                  </MaskedInput>
                </Grid>

                {this.props.services[0] && this.props.services[0].type_imobile == 1 && (
                  <>
                    <Grid item xs={12} md={5}>
                      <MaskedInput
                        name="cnpj"
                        InputProps={{
                          autoComplete: 'nope',
                        }}
                        maskChar=""
                        size="small"
                        fullWidth
                        variant="outlined"
                        mask="99.999.999/9999-99"
                        placeholder="12.345.678/0001-90"
                        error={!!errors.filter(field => field.error === 'CNPJ').length}
                        label="CNPJ"
                        onChange={this.changeCnpj}
                        value={this.props.client.cnpj || ''}
                      >
                        {inputProps => <TextField {...inputProps} />}
                      </MaskedInput>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        name="company_name"
                        size="small"
                        InputProps={{
                          autoComplete: 'nope',
                        }}
                        fullWidth
                        variant="outlined"
                        placeholder="Razão Social"
                        error={!!errors.filter(field => field.error === 'SOCIAL_REASON').length}
                        label="Razão Social"
                        onChange={this.handleChangeClient}
                        value={this.props.client.company_name || ''}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>
          </Grid>

          {/* -------------------------------------------------------------------------- */}
          {/*                           DADOS DO CORRESPONSÁVEL                          */}
          {/* -------------------------------------------------------------------------- */}

          {has_corresponsible && (
            <Grid item md={6}>
              <Box
                style={{
                  background: '#fff',
                  borderRadius: 16,
                  boxShadow: '0px 0px 10px 0px rgb(0, 0, 0, .05)',
                  overflow: 'hidden',
                }}
              >
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    background: '#044c94',
                    color: '#fff',
                  }}
                >
                  <Typography variant="h6" style={{ padding: '16px 24px' }}>
                    Dados do corresponsável
                  </Typography>
                  <Tooltip title="Ao marcar esta opção você nos informa que o locatário e o corresponsável são a mesma pessoa.">
                    <FormControlLabel
                      control={
                        <Checkbox
                          style={{ color: '#fff' }}
                          color="secondary"
                          checked={clientCorresp}
                          onChange={this.checkCorresp}
                        />
                      }
                      label={
                        <Typography variant="body2" style={{ color: '#fff' }}>
                          Locatário e corresponsável são o mesmo
                        </Typography>
                      }
                    />
                  </Tooltip>
                </Box>
                <Divider></Divider>

                <Grid container spacing={2} style={{ padding: '16px' }}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      InputProps={
                        clientCorresp
                          ? { startAdornment: <></>, autoComplete: 'nope' }
                          : {
                              autoComplete: 'nope',
                            }
                      }
                      disabled={clientCorresp}
                      size="small"
                      error={!!errors.filter(field => field.error === 'CORRESPONSIBLE_NAME').length}
                      variant="outlined"
                      placeholder="Nome"
                      label="Nome"
                      id="name"
                      value={this.props.corresponsavel.name || ''}
                      onChange={this.handleChangeCorresponsavel}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <MaskedInput
                      fullWidth
                      maskChar=""
                      InputProps={
                        clientCorresp
                          ? {
                              disabled: clientCorresp,
                              startAdornment: <></>,
                              autoComplete: 'nope',
                            }
                          : {}
                      }
                      disabled={clientCorresp}
                      size="small"
                      error={!!errors.filter(field => field.error === 'CORRESPONSIBLE_CPF').length}
                      variant="outlined"
                      mask="999.999.999-99"
                      placeholder="123.456.789.00"
                      label="CPF do corresponsável"
                      onChange={this.handleChangeCorresponsavel}
                      value={this.props.corresponsavel.cpf || ''}
                      id="cpf"
                    >
                      {inputProps => <TextField {...inputProps} />}
                    </MaskedInput>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        fullWidth
                        disableFuture
                        InputProps={{
                          autoComplete: 'nope',
                        }}
                        error={
                          !!errors.filter(field => field.error === 'CORRESPONSIBLE_BIRTHDAY').length
                        }
                        disabled={clientCorresp}
                        openTo="year"
                        name="birthday"
                        size="small"
                        format="DD/MM/YYYY"
                        maxDate={moment().add('-18', 'years').format('YYYY-MM-DD')}
                        maxDateMessage="O locatário precisa ter 18 anos ou mais."
                        inputVariant="outlined"
                        invalidDateMessage="Data inválida"
                        value={
                          this.props.corresponsavel.birthday
                            ? moment(this.props.corresponsavel.birthday, 'DD/MM/YYYY').format(
                                'YYYY-MM-DD',
                              )
                            : null
                        }
                        onChange={date => this.handleChangeCorrespBirthday(date)}
                        placeholder="01/01/1990"
                        label="Data de nascimento"
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      InputProps={
                        clientCorresp
                          ? {
                              disabled: clientCorresp,
                              startAdornment: <></>,
                            }
                          : {
                              autoComplete: 'nope',
                            }
                      }
                      disabled={clientCorresp}
                      size="small"
                      error={
                        !!errors.filter(field => field.error === 'CORRESPONSIBLE_EMAIL').length
                      }
                      variant="outlined"
                      type="text"
                      placeholder="E-mail"
                      label="E-mail"
                      onChange={this.handleChangeCorresponsavel}
                      value={this.props.corresponsavel.email || ''}
                      id="email"
                      onBlur={this.checkEmailCorresp}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      InputProps={
                        clientCorresp
                          ? {
                              disabled: clientCorresp,
                              startAdornment: <></>,
                            }
                          : {
                              autoComplete: 'nope',
                            }
                      }
                      disabled={clientCorresp}
                      error={
                        !!errors.filter(field => field.error === 'CORRESPONSIBLE_CONFIRM_EMAIL')
                          .length
                      }
                      size="small"
                      variant="outlined"
                      type="text"
                      placeholder="Confirmar E-mail"
                      label="Confirmar E-mail"
                      onChange={this.handleChangeCorresponsavel}
                      value={this.props.corresponsavel.confirm_email || ''}
                      id="confirm_email"
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <MaskedInput
                      fullWidth
                      maskChar=""
                      InputProps={
                        clientCorresp
                          ? {
                              disabled: clientCorresp,
                              startAdornment: <></>,
                            }
                          : {
                              autoComplete: 'nope',
                            }
                      }
                      disabled={clientCorresp}
                      size="small"
                      error={
                        !!errors.filter(field => field.error === 'CORRESPONSIBLE_PHONE').length
                      }
                      variant="outlined"
                      mask="(99) 99999-9999"
                      placeholder="(32) 12345-6789"
                      id="telephone_1"
                      label="Contato principal"
                      onChange={this.handleChangeCorresponsavel}
                      value={this.props.corresponsavel.telephone_1 || ''}
                    >
                      {inputProps => <TextField {...inputProps} />}
                    </MaskedInput>
                  </Grid>

                  <Grid item xs={12} md={6} lg={6}>
                    <MaskedInput
                      fullWidth
                      maskChar=""
                      InputProps={
                        clientCorresp
                          ? {
                              disabled: clientCorresp,
                              startAdornment: <></>,
                            }
                          : {
                              autoComplete: 'nope',
                            }
                      }
                      disabled={clientCorresp}
                      size="small"
                      variant="outlined"
                      mask="(99) 99999-9999"
                      placeholder="(32) 12345-6789"
                      id="telephone_2"
                      label="Contato alternativo (Opcional)"
                      onChange={this.handleChangeCorresponsavel}
                      value={this.props.corresponsavel.telephone_2 || ''}
                    >
                      {inputProps => <TextField {...inputProps} />}
                    </MaskedInput>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          )}

          <Grid item xs={12}>
            <Box
              style={{
                background: '#fff',
                borderRadius: 16,
                boxShadow: '0px 0px 10px 0px rgb(0, 0, 0, .05)',
                overflow: 'hidden',
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  background: '#044c94',
                  color: '#fff',
                }}
              >
                <Typography variant="h6" style={{ padding: '16px 24px' }}>
                  Dados do imóvel que deseja locar
                </Typography>
              </Box>
              <Divider></Divider>

              <Grid container spacing={2} style={{ padding: '16px' }}>
                <Grid item xs={12} sm={4} md={2}>
                  <MaskedInput
                    error={!!errors.filter(field => field.error === 'IMMOBILE_CEP').length}
                    maskChar=""
                    id="cep"
                    mask="99999-999"
                    InputProps={{
                      autoComplete: 'nope',
                    }}
                    placeholder="12345-678"
                    label="CEP"
                    fullWidth
                    variant="outlined"
                    size="small"
                    onChange={this.changeCep}
                    value={immobile.cep || ''}
                  >
                    {inputProps => <TextField {...inputProps} />}
                  </MaskedInput>
                </Grid>

                <Grid item xs={8} sm={8} md={3}>
                  <TextField
                    InputProps={{
                      startAdornment: immobile.street ? <></> : null,
                      autoComplete: 'nope',
                    }}
                    id="street"
                    error={!!errors.filter(field => field.error === 'IMMOBILE_STREET').length}
                    value={immobile.street || ''}
                    placeholder="Rua João da Silva"
                    label="Logradouro"
                    fullWidth
                    variant="outlined"
                    size="small"
                    onChange={this.changeAddress}
                  />
                </Grid>

                <Grid item xs={4} sm={3} md={2}>
                  <TextField
                    id="number"
                    InputProps={{
                      autoComplete: 'nope',
                    }}
                    value={immobile.number || ''}
                    placeholder="Número"
                    label="Número"
                    error={!!errors.filter(field => field.error === 'IMMOBILE_NUMBER').length}
                    fullWidth
                    variant="outlined"
                    size="small"
                    onChange={this.changeNumber}
                  />
                </Grid>

                <Grid item xs={12} sm={3} md={2}>
                  <TextField
                    id="neighborhood"
                    InputProps={{
                      autoComplete: 'nope',
                    }}
                    value={immobile.neighborhood || ''}
                    placeholder="Centro"
                    label="Bairro"
                    error={!!errors.filter(field => field.error === 'IMMOBILE_NEIGHBORHOOD').length}
                    fullWidth
                    variant="outlined"
                    size="small"
                    onChange={this.changeNeighborhood}
                  />
                </Grid>

                <Grid item xs={12} sm={3} md={2}>
                  <TextField
                    id="complement"
                    InputProps={{
                      autoComplete: 'nope',
                    }}
                    value={immobile.complement || ''}
                    placeholder="Apto 201"
                    label="Complemento"
                    fullWidth
                    variant="outlined"
                    size="small"
                    onChange={this.changeComplement}
                  />
                </Grid>

                <Grid item xs={8} sm={4} md={2}>
                  <TextField
                    id="city"
                    InputProps={{
                      autoComplete: 'nope',
                    }}
                    value={immobile.city || ''}
                    placeholder="Juiz de Fora"
                    label="Cidade"
                    error={!!errors.filter(field => field.error === 'IMMOBILE_CITY').length}
                    fullWidth
                    variant="outlined"
                    size="small"
                    onChange={this.changeAddress}
                  />
                </Grid>

                <Grid item xs={4} sm={2} md={1}>
                  <TextField
                    id="state"
                    InputProps={{
                      autoComplete: 'nope',
                    }}
                    value={immobile.state || ''}
                    placeholder="MG"
                    label="Estado"
                    error={!!errors.filter(field => field.error === 'IMMOBILE_STATE').length}
                    fullWidth
                    variant="outlined"
                    size="small"
                    onChange={this.changeAddress}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>

          {confirmarEmail && has_corresponsible && (
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Box
                    style={{
                      background: '#fff',
                      borderRadius: 16,
                      boxShadow: '0px 0px 10px 0px rgb(0, 0, 0, .05)',
                      overflow: 'hidden',
                    }}
                  >
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        background: '#044c94',
                        color: '#fff',
                      }}
                    >
                      <Typography variant="h6" style={{ padding: '16px 24px' }}>
                        Cartão locatário
                      </Typography>
                    </Box>
                    <Divider></Divider>

                    <Grid container spacing={2} style={{ padding: '16px' }}>
                      <Grid item xs={12}>
                        <Typography variant="body2">
                          Observação: além do valor selecionado também será passado o valor da taxa
                          de adesão caso haja algum.
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <CurrencyTextField
                          name="value"
                          fullWidth
                          size="small"
                          onChange={this.changeValueCard}
                          value={this.props.creditCardResp.value}
                          onBlur={this.checkMinValue}
                          placeholder="500,00"
                          minimumValue="0"
                          variant="outlined"
                          currencySymbol="R$"
                          decimalCharacter=","
                          digitGroupSeparator="."
                          outputFormat="string"
                          label="Valor da Transação"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Box
                    style={{
                      background: '#fff',
                      borderRadius: 16,
                      boxShadow: '0px 0px 10px 0px rgb(0, 0, 0, .05)',
                      overflow: 'hidden',
                    }}
                  >
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        background: '#044c94',
                        color: '#fff',
                      }}
                    >
                      <Typography variant="h6" style={{ padding: '16px 24px' }}>
                        Cartão corresponsável
                      </Typography>
                    </Box>
                    <Divider></Divider>
                    <Grid container spacing={2} style={{ padding: '16px' }}>
                      <Grid item xs={12}>
                        <Box style={{ height: 40 }}></Box>
                      </Grid>
                      <Grid item xs={12}>
                        <CurrencyTextField
                          name="value"
                          fullWidth
                          InputProps={{
                            autoComplete: 'nope',
                          }}
                          size="small"
                          disabled
                          value={this.props.creditCardCorresp.value || ''}
                          placeholder="500,00"
                          minimumValue="0"
                          variant="outlined"
                          currencySymbol="R$"
                          decimalCharacter=","
                          digitGroupSeparator="."
                          outputFormat="string"
                          label="Valor da Transação"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          )}

          <Grid
            container
            spacing={1}
            style={{ display: 'flex', justifyContent: 'flex-end', padding: 16 }}
          >
            <Grid item>
              <Button
                disabled={this.props.loading}
                size="large"
                color="primary"
                variant="outlined"
                onClick={this.back}
              >
                Voltar
              </Button>
            </Grid>
            <Grid item>
              <Box style={{ display: 'flex', position: 'relative' }}>
                {this.props.loading && (
                  <CircularProgress
                    size={24}
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: -12,
                      marginLeft: -12,
                    }}
                  />
                )}
                <Button
                  disabled={this.props.loading}
                  size="large"
                  color="primary"
                  variant="contained"
                  onClick={this.next}
                >
                  Avançar
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Dialog
          open={this.state.adhesionConfirm}
          onClose={() => this.setState({ adhesionConfirm: false })}
        >
          <DialogTitle
            disableTypography
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6">Você deseja prosseguir?</Typography>
            <IconButton
              onClick={() => this.setState({ adhesionConfirm: false })}
              size="small"
              style={{ marginLeft: 'auto' }}
            >
              <Close></Close>
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <DialogContentText>
              A taxa de adesão não foi informada, você tem certeza de que deseja prosseguir sem
              informar uma taxa de adesão?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => this.setState({ adhesionConfirm: false })}>
              Voltar
            </Button>
            <Button
              disabled={!this.state.adhesionConfirm}
              color="primary"
              variant="contained"
              onClick={() => {
                this.setState({ adhesionConfirm: false })
                this.next()
              }}
            >
              Continuar
            </Button>
          </DialogActions>
        </Dialog>

        {!!dialogVerifyEmail && (
          <Dialog
            open={dialogVerifyEmail}
            TransitionComponent={Transition}
            onClose={() => {
              this.props.changeLoading(false)
              this.setState({ dialogVerifyEmail: false })
            }}
          >
            <DialogTitle
              disableTypography
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography variant="h6">Atenção</Typography>
              <IconButton
                onClick={() => {
                  this.props.changeLoading(false)
                  this.setState({ dialogVerifyEmail: false })
                }}
                size="small"
                style={{ marginLeft: 'auto' }}
              >
                <Close></Close>
              </IconButton>
            </DialogTitle>
            <DialogContent dividers>
              <DialogContentText>
                Fizemos a verificação do email informado
                <Typography component="span" color="error">
                  ({dialogEmail})
                </Typography>
                , mas infelizmente não conseguimos validá-lo.
              </DialogContentText>
              <DialogContentText>
                Antes de prosseguir verifique com o locatário se o email informado é o correto. Caso
                o email seja incorreto, este impossibilitará a conclusão da transação, pois será
                necessário que o locatário assine o contrato por email.
              </DialogContentText>
              <DialogContentText>
                Caso tenha certeza que o email é correto, desconsidere esta mensagem.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  this.props.changeLoading(false)
                  this.setState({ dialogVerifyEmail: false })
                }}
                color="primary"
              >
                Cancelar
              </Button>
              <Button
                onClick={() => {
                  this.props.changeLoading(true)
                  this.sendData()
                  this.setState({ dialogVerifyEmail: false })
                }}
                color="primary"
                variant="contained"
              >
                Avançar
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </>
    )
  }
}

function mapStateToProps({ creditAnalysis, authReducer }) {
  return {
    ...creditAnalysis,
    ...authReducer,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fillField: (field, value) => dispatch(fillField(field, value)),
    refresh: () => dispatch(refresh()),
  }
}

export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(Step2))
