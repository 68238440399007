import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, TextField, Typography } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import { DropzoneArea } from 'material-ui-dropzone'
import { withSnackbar } from 'notistack'
import React from 'react'
import ApiInstance from '../services/ApiInstance'
class ModalInvoice extends React.Component {
  constructor() {
    super()
    this.state = {
      description: '',
      value: '',
      loading: false,
      files64: [],
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target
    this.setState({ [name]: value })
  }

  handleChangeMoney = (value) => {
    this.setState({ value: value })
  }

  convertToBase64 = (file, cb) => {
    //Read File
    var selectedFile = file
    //Check File is not Empty
    if (selectedFile.length > 0) {
      // Select the very first file from list
      var fileToLoad = selectedFile[0]
      // FileReader function for read the file.
      var fileReader = new FileReader()
      var base64
      // Onload of file read the file content
      fileReader.onload = function (fileLoadedEvent) {
        base64 = fileLoadedEvent.target.result
        // Print data in console
        cb(base64)
      }
      // Convert data to base64
      fileReader.readAsDataURL(fileToLoad)
    }
  }

  paySinistro = async () => {
    this.setState({ loading: true })
    const { purchase: purchaseSelected } = this.props
    try {
      const { description, value } = this.state
      if (!value || !description) throw new Error('Preencha todos os campos.')
      if (!this.state.files64.length) throw new Error('Insira ao menos um documento para acionar o sinistro.')
      if (15 * Number(purchaseSelected.purchase.total_value_loc_corresp) < Number(value)) throw new Error('Valor acima do permitido.')
      if (purchaseSelected.purchase.status !== 3) throw new Error('Não é possível executar sinistro em vendas com status diferente de concluída.')
      const isWarrantyUcred = await ApiInstance.get(`venda/garantia/${purchaseSelected.purchase.id}`)

      if (isWarrantyUcred.data) return this.payWarrantyUcred()
    } catch (error) {
      this.setState({ loading: false })
      const snackbarKey = this.props.enqueueSnackbar(error.message, {
        variant: 'error',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
    }
  }

  payWarrantyUcred = async () => {
    const { purchase: purchaseSelected } = this.props
    try {
      const { value, description, files64 } = this.state
      await ApiInstance.post('venda/executarGarantia', {
        value,
        description,
        purchaseSelected: purchaseSelected.purchase.id,
        files: files64,
      })
      const snackbarKey = this.props.enqueueSnackbar('Solicitação de sinistro aberta com sucesso.', {
        variant: 'success',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
      this.setState({ description: '', value: '', files64: [], loading: false })
      this.props.parent.fetchData()
      this.props.parent.setState({ invoices: { ...this.props.parent.state.invoices, open: false } })
    } catch (error) {
      const snackbarKey = this.props.enqueueSnackbar('Não foi possível realizar a abertura do sinistro, entre em contato com nosso suporte.', {
        variant: 'error',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
      this.setState({ loading: false })
    }
  }

  render() {
    const { open, onClose, purchase } = this.props
    const { loading, description } = this.state
    return (
      <Dialog
        disableScrollLock
        open={open}
        onClose={() => {
          if (loading) return
          onClose()
        }}
        id="invoice-modal"
      >
        <DialogTitle
          disableTypography
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6">Acionar Sinistro</Typography>
          <IconButton onClick={onClose}>
            <Close></Close>
          </IconButton>
        </DialogTitle>
        {purchase && (
          <DialogContent dividers>
            <DialogContentText>Para efetivar o acionamento de sinistro, solicitamos que sejam anexados abaixo os seguintes documentos:</DialogContentText>

            <DialogContentText>
              <ul style={{ paddingLeft: 32 }}>
                <li>Contrato de locação do locatário.</li>
                <li>Laudo de vistoria de entrada e saída (se for um caso de rescisão contratual ou saída do imóvel).</li>
                <li>O cálculo para valor da multa de rescisão (se for um caso de rescisão contratual).</li>
                <li>Orçamento dos reparos no imóvel (se for um caso de rescisão contratual ou saída do imóvel).</li>
                <li>O boletos inadimplentes em nome da locatário, com os valores atualizados.</li>
              </ul>
            </DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CurrencyTextField
                  fullWidth
                  variant="outlined"
                  currencySymbol="R$"
                  digitGroupSeparator="."
                  decimalCharacter=","
                  placeholder="Valor do Sinistro"
                  label="Valor do Sinistro"
                  minimumValue="0"
                  id="value"
                  value={this.state.value}
                  onChange={(event, value) => this.handleChangeMoney(value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="description"
                  multiline
                  placeholder="Descrever motivo do sinistro"
                  label="Descrever motivo do sinistro"
                  fullWidth
                  variant="outlined"
                  onChange={this.handleChange}
                  value={description}
                  rows={4}
                ></TextField>
              </Grid>
              <Grid item xs={12}>
                <DropzoneArea
                  maxFileSize={2000000}
                  filesLimit={10}
                  dropzoneText={'Arraste e solte arquivos aqui ou clique para fazer upload. São aceitos aquivos .jpeg, .png, .bmp e .pdf'}
                  getFileLimitExceedMessage={(filesLimit) => `Limite máximo de arquivos excedidos. São permitidos no máximo ${filesLimit} arquivos.`}
                  getFileAddedMessage={(fileName) => `O arquivo ${fileName} foi adicionado.`}
                  getFileRemovedMessage={(fileName) => `O arquivo ${fileName} foi removido.`}
                  getDropRejectMessage={(file) => `O arquivo ${file.name} foi rejeitado. São aceitos no máximo 10 arquivos, com tamanho máximo de 2Mb nos formatos png, jpeg, bmp ou pdf.`}
                  acceptedFiles={['image/jpeg', 'image/png', 'image/bmp', 'application/pdf']}
                  onChange={(files) => {
                    let filesArr = []
                    files.map((files) => {
                      this.convertToBase64([files], (base64) => {
                        filesArr.push({ name: files.name, base: base64 })
                      })
                    })
                    this.setState({ files64: filesArr })
                  }}
                ></DropzoneArea>
              </Grid>
            </Grid>
          </DialogContent>
        )}
        <DialogActions>
          <Button disabled={loading} onClick={onClose} color="primary">
            Voltar
          </Button>
          <Box style={{ display: 'flex', position: 'relative' }}>
            <Button disabled={loading} variant="contained" onClick={this.paySinistro} color="primary">
              Confirmar
            </Button>
            {loading && (
              <CircularProgress
                size={24}
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: -12,
                  marginLeft: -12,
                }}
              />
            )}
          </Box>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withSnackbar(ModalInvoice)
