import { Badge, BadgeProps } from '@chakra-ui/react'
import { getOverallStatus } from '../../../../util/getOverallStatus'
import { TransactionType } from '../../types/transaction'

interface PaymentStatusBadgeProps extends BadgeProps {
  transaction: TransactionType
}

export function OverallStatusBadge({ transaction, ...rest }: PaymentStatusBadgeProps) {
  const status = transaction.transactionResume?.purchase_status_id
  const contractStatus = transaction.transactionResume?.contract?.status
  const corresponsibleStatus =
    transaction.transactionResume?.corresponsible_purchase?.purchase_status_id
  const corresponsibleContractStatus =
    transaction.transactionResume?.corresponsible_purchase?.contract?.status
  const { title, color } = getOverallStatus(
    status,
    contractStatus,
    corresponsibleStatus,
    corresponsibleContractStatus,
  )

  return (
    <Badge
      w="fit-content"
      h="fit-content"
      paddingX={2}
      fontSize="sm"
      fontWeight="normal"
      textTransform="unset"
      colorScheme={color}
      {...rest}
    >
      {title}
    </Badge>
  )
}
