/* eslint-disable camelcase */
/* eslint-disable no-undef */
import { useToast } from '@chakra-ui/react'
import moment from 'moment'
import React, { createContext, useEffect, useState } from 'react'
import * as yup from 'yup'
import ApiInstance from '../services/ApiInstanceV2'
import VindiError from '../functions/vindiErrorMessageByCode'
import { useHistory } from 'react-router-dom'

const step = process.env.REACT_APP_STAGE
let bankSlipCode = ''
switch (step) {
  case 'development':
    bankSlipCode = 'bank_slip'
    break

  case 'staging':
    bankSlipCode = 'bank_slip'
    break

  case 'production':
    bankSlipCode = 'bank_slip_1'
    break

  default:
    bankSlipCode = 'bank_slip'
}

const ChangePaymentMethod = createContext({})

export const ChangePaymentMethodProvider: React.FC = ({ children }) => {
  const history = useHistory()
  const toast = useToast()
  const [pageLoadError, setPageLoadError] = useState(false)
  const [errors, setErrors] = useState<Errors[]>([])
  const [renovationData, setRenovationData] = useState<RenovationData>()
  const [loading, setLoading] = useState(false)
  const [split, setSplit] = useState(false)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<0 | 1 | 2 | 3 | 4>(0)
  const [selectedCard, setSelectedCard] = useState<'main' | 'second' | null>('main')
  const [mainPaymentMethod, setMainPaymentMethod] = useState<PaymentMethod>({
    name: '',
    type: '',
    number: '',
    cvv: '',
    expiration_date: '',
    card_maturity: 0,
    parcel: 0,
    value: 0,
  })

  const [secondPaymentMethod, setSecondPaymentMethod] = useState<PaymentMethod>({
    name: '',
    type: '',
    number: '',
    cvv: '',
    expiration_date: '',
    card_maturity: 0,
    parcel: 0,
    value: 0,
  })

  const fetchPaymentData = async () => {
    setLoading(true)

    const urlParams = new URLSearchParams(window.location.search)
    const hash = urlParams.get('hash')

    await ApiInstance.get('change-payment-method?hash=' + hash)
      .then((response: RenovationData) => {
        setMainPaymentMethod({ ...mainPaymentMethod, name: response.client.name })
        setSecondPaymentMethod({ ...secondPaymentMethod, name: response.client.name })
        setLoading(false)
        setRenovationData(response)
      })
      .catch(() => {
        history.push('/404')
      })
  }

  useEffect(() => {
    setMainPaymentMethod({ ...mainPaymentMethod, name: mainPaymentMethod.name })
    setSecondPaymentMethod({ ...mainPaymentMethod, name: secondPaymentMethod.name })
  }, [selectedPaymentMethod]) //eslint-disable-line

  useEffect(() => {
    fetchPaymentData()
  }, []) //eslint-disable-line

  const calculateValue = (valueMain: number) => {
    const renovationValue = renovationData?.renovation?.value || 0
    const renovationCorresponsibleValue = renovationData?.renovation_corresponsible?.value || 0
    const totalValue = renovationValue + renovationCorresponsibleValue

    const maxValue = totalValue - 30
    const minValue = 30

    if (isNaN(valueMain)) valueMain = maxValue

    if (valueMain > maxValue) valueMain = maxValue
    if (valueMain < minValue) valueMain = minValue

    setMainPaymentMethod({ ...mainPaymentMethod, value: valueMain })
    setSecondPaymentMethod({ ...secondPaymentMethod, value: totalValue - valueMain })
  }

  const schemaFx = (value: 'main' | 'second') => {
    const schema = yup.object().shape({
      name: yup.string().required({ message: 'Nome é obrigatório', field: `${value}_holder_name` }),
      type: yup.string().required({ message: 'Tipo é obrigatório', field: `${value}_type` }),
      number: yup
        .string()
        .min(18, { message: 'Número do cartão é inválido', field: `${value}_card_number` })
        .max(19, { message: 'Número do cartão é inválido', field: `${value}_card_number` })
        .required({ message: 'Número do cartão é obrigatório', field: `${value}_card_number` }),
      cvv: yup
        .string()
        .required({ message: 'Cvv é obrigatório', field: `${value}_card_cvv` })
        .min(3, { message: 'Cvv deve conter no mínimo 3 dígitos', field: `${value}_card_cvv` })
        .max(4, { message: 'Cvv deve conter no máximo 4 dígitos', field: `${value}_card_cvv` }),
      expiration_date: yup
        .date()
        .typeError({ message: 'Data não é válida', field: `${value}_card_expiration` })
        .min(new Date(), { message: 'Data expirada', field: `${value}_card_expiration` })
        .max(moment().add(100, 'y').format('YYYY-MM-DD'), {
          message: 'Data expirada',
          field: `${value}_card_expiration`,
        })
        .required({ message: 'Data de validade é obrigatória', field: `${value}_card_expiration` }),
      card_maturity: yup
        .number()
        .required({ message: 'Validade do cartão é obrigatória', field: `${value}_card_maturity` })
        .max(30, {
          message: 'Valor máximo para vencimento da fatura é 30',
          field: `${value}_card_maturity`,
        })
        .min(1, {
          message: 'Valor mínimo para vencimento da fatura é 1',
          field: `${value}_card_maturity`,
        }),
      parcel: yup
        .number()
        .required({ message: 'Parcelamento da venda é obrigatório', field: `${value}_card_parcel` })
        .max(12, { message: 'Valor máximo de parcelas é 12', field: `${value}_card_parcel` })
        .min(1, { message: 'Valor mínimo de parcelas é 1', field: `${value}_card_parcel` }),
      value: yup
        .number()
        .test(
          'Valor é válido',
          { message: 'Valor deve ser um número', field: `${value}_value` },
          value =>
            (selectedPaymentMethod !== 3 && selectedPaymentMethod !== 4) || !isNaN(Number(value)),
        )
        .min(30, { message: 'Valor não é válido', field: `${value}_value` }),
    })

    return schema
  }

  const validateData = async (paymentSplit: {
    split?: boolean
    mainType: any
    secondType: any
  }) => {
    let expirationDate = ''

    if (moment(mainPaymentMethod.expiration_date, 'MM/YYYY').isValid()) {
      expirationDate = moment(mainPaymentMethod.expiration_date, 'MM/YYYY').format('YYYY-MM-DD')
    }

    mainPaymentMethod.value ? mainPaymentMethod.value : delete mainPaymentMethod.value
    await schemaFx('main')
      .validate(
        {
          ...mainPaymentMethod,
          type: paymentSplit.mainType,
          expiration_date: expirationDate,
          card_maturity: Number(mainPaymentMethod.card_maturity),
          value: mainPaymentMethod.value,
        },
        { abortEarly: false },
      )
      .catch(error => {
        setSelectedCard('main')
        setErrors(error.errors)
        throw new Error('Falha ao validar')
      })

    if (selectedPaymentMethod === 3) {
      let secondExpirationDate = ''

      if (moment(secondPaymentMethod.expiration_date, 'MM/YYYY').isValid()) {
        secondExpirationDate = moment(secondPaymentMethod.expiration_date, 'MM/YYYY').format(
          'YYYY-MM-DD',
        )
      }

      secondPaymentMethod.value ? secondPaymentMethod.value : delete secondPaymentMethod.value
      await schemaFx('second')
        .validate(
          {
            ...secondPaymentMethod,
            type: paymentSplit.secondType,
            expiration_date: secondExpirationDate,
            value: secondPaymentMethod.value ? secondPaymentMethod.value : null,
          },
          { abortEarly: false },
        )
        .catch(error => {
          setSelectedCard('second')
          setErrors(error.errors)
          throw new Error('Falha ao validar')
        })
    }

    calculateValue(mainPaymentMethod.value || 0)

    return true
  }

  const isPaymentSplited = () => {
    let splitObject = { split: false, mainType: '', secondType: '' }

    switch (selectedPaymentMethod) {
      case 1:
        setMainPaymentMethod({ ...mainPaymentMethod, type: bankSlipCode })
        splitObject = { split: false, mainType: bankSlipCode, secondType: '' }
        break
      case 2:
        setMainPaymentMethod({ ...mainPaymentMethod, type: 'credit_card' })
        splitObject = { split: false, mainType: 'credit_card', secondType: '' }
        break
      case 3:
        setMainPaymentMethod({ ...mainPaymentMethod, type: 'credit_card' })
        setSecondPaymentMethod({ ...secondPaymentMethod, type: 'credit_card' })
        splitObject = { split: true, mainType: 'credit_card', secondType: 'credit_card' }
        break
      case 4:
        setMainPaymentMethod({ ...mainPaymentMethod, type: 'credit_card' })
        setSecondPaymentMethod({ ...secondPaymentMethod, type: bankSlipCode })
        splitObject = { split: true, mainType: 'credit_card', secondType: bankSlipCode }
        break
      default:
        setMainPaymentMethod({ ...mainPaymentMethod, type: 'credit_card' })
        splitObject = { split: false, mainType: 'credit_card', secondType: '' }
        break
    }

    return splitObject
  }

  const changeMainCreditCard = (event: any, isCurrencyInput = false) => {
    if (!isCurrencyInput) {
      let { id, name, value } = event.target
      if (name === 'value') value = Number(value)

      if (name === 'card_maturity') {
        if (value > 30) {
          value = 30
        } else if (value < 1) {
          value = 1
        }
      }

      const error = errors.filter(value => value.field !== id)
      setErrors(error)

      setMainPaymentMethod({ ...mainPaymentMethod, [name]: value })
    } else {
      let { id, name, value } = event
      value = Number(value)

      const error = errors.filter(value => value.field !== id)
      setErrors(error)

      setMainPaymentMethod({ ...mainPaymentMethod, [name]: value })
    }
  }

  const changeSecondCreditCard = (event: any) => {
    let { id, name, value } = event.target
    if (name === 'value') value = Number(value)

    const error = errors.filter(value => value.field !== id)
    setErrors(error)

    setSecondPaymentMethod({ ...secondPaymentMethod, [name]: value })
  }

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault()
    setLoading(true)

    try {
      const paymentSplit = isPaymentSplited()
      await validateData(paymentSplit)

      const urlParams = new URLSearchParams(window.location.search)
      const hash = urlParams.get('hash')
      const error = urlParams.get('error')

      const mainExpirationDate = moment(mainPaymentMethod.expiration_date, 'MM/YYYY').format(
        'YYYY-MM-DD',
      )
      const secondExpirationDate = moment(secondPaymentMethod.expiration_date, 'MM/YYYY').format(
        'YYYY-MM-DD',
      )

      const mainCardMaturity = mainPaymentMethod.card_maturity
      const secondCardMaturity = secondPaymentMethod.card_maturity

      let secondPaymentMethodObject: PaymentMethod = {}
      if (paymentSplit && paymentSplit.secondType === bankSlipCode) {
        secondPaymentMethodObject = {
          name: mainPaymentMethod.name,
          number: mainPaymentMethod.number,
          parcel: 1,
          type: paymentSplit.secondType,
          cvv: mainPaymentMethod.cvv,
          expiration_date: moment(secondExpirationDate).isValid()
            ? secondExpirationDate
            : mainExpirationDate,
          card_maturity: secondCardMaturity,
          value: secondPaymentMethod.value,
        }
      }

      if (paymentSplit && paymentSplit.secondType === 'credit_card') {
        secondPaymentMethodObject = {
          name: secondPaymentMethod.name,
          number: secondPaymentMethod.number,
          parcel: secondPaymentMethod.parcel,
          type: paymentSplit.secondType,
          cvv: secondPaymentMethod.cvv,
          expiration_date: mainExpirationDate,
          card_maturity: secondCardMaturity,
          value: secondPaymentMethod.value,
        }
      }

      const requestBody: RequestBody = {
        hash,
        split: paymentSplit.split,
        main_payment_method: {
          name: mainPaymentMethod.name,
          number: mainPaymentMethod.number,
          parcel: mainPaymentMethod.parcel || 1,
          type: paymentSplit.mainType,
          cvv: mainPaymentMethod.cvv || '',
          expiration_date: mainExpirationDate,
          card_maturity: mainCardMaturity || 1,
          value: mainPaymentMethod.value || 0,
        },
        second_payment_method: secondPaymentMethodObject,
      }

      if (!requestBody.second_payment_method) delete requestBody.second_payment_method

      await ApiInstance.post(`change-payment-method?error=${error}`, requestBody)
        .then(async () => {
          setSelectedPaymentMethod(0)
          setLoading(false)
          toast({
            title: 'Seu método de pagamento foi alterado com sucesso!',
            status: 'success',
            duration: 3000,
            isClosable: true,
          })

          window.location.href = 'https://www.ucred.io'
        })
        .catch(error => {
          const errorMessage = VindiError(error.code, error.message)
          if (error.secondCard) {
            setSelectedCard('second')
            setErrors([
              ...errors,
              { field: 'second_card_number' },
              { field: 'second_card_cvv' },
              { field: 'second_card_expiration' },
            ])
          } else {
            setSelectedCard('main')
            setErrors([
              ...errors,
              { field: 'main_card_number' },
              { field: 'main_card_cvv' },
              { field: 'main_card_expiration' },
            ])
          }

          if (error.code) {
            toast({
              title: error.secondCard ? 'Segundo cartão: ' + errorMessage : errorMessage,
              status: 'error',
              duration: 3000,
              isClosable: true,
            })
          } else {
            toast({
              title: 'Houve um erro inesperado, verifique seus dados e tente novamente.',
              status: 'error',
              duration: 3000,
              isClosable: true,
            })
          }

          setLoading(false)
        })
    } catch (error: any) {
      console.log(error)
      if (error.errors) {
        setErrors(error.errors)
        setLoading(false)

        return toast({
          title: 'Falha ao validar os dados enviados',
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      }

      toast({
        title: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
      setLoading(false)
    }
  }

  return (
    <ChangePaymentMethod.Provider
      value={{
        hash: '',
        split,
        errors,
        loading,
        selectedCard,
        pageLoadError,
        renovationData,
        mainPaymentMethod,
        secondPaymentMethod,

        selectedPaymentMethod,

        setSplit,
        handleSubmit,
        calculateValue,
        setSelectedCard,
        setPageLoadError,
        fetchPaymentData,
        changeMainCreditCard,
        changeSecondCreditCard,
        setSelectedPaymentMethod,
      }}
    >
      {children}
    </ChangePaymentMethod.Provider>
  )
}

export default ChangePaymentMethod
