import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Fab,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core'
import { Close, Person, PersonOutline, PrintRounded } from '@material-ui/icons'
import Copy from 'copy-to-clipboard'
import Lottie from 'lottie-react-web'
import { withSnackbar } from 'notistack'
import React from 'react'
import { connect } from 'react-redux'
import Modal from 'react-responsive-modal'
import ApiInstance from '../../services/ApiInstance'
import Footer from '../../layout/Footer'
import { fillField, refresh } from '../../redux/actions/creditAnalysisActions'
import ApprovedReproved from '../../static/animations/approved-reproved.json'
import Logo from '../../static/images/logo.png'

class Step3 extends React.Component {
  state = {
    type_error: '',
    message_error: '',
    error: false,
    origin_error: '',
    success: false,
    clientName: '',
    linkContract: '',
    linkContractCorresponsible: '',
    linkPayment: '',
    flux: null,
    company: { plan_id: 1 },
  }

  concluir = async () => {
    try {
      this.props.changeLoading(true)

      let {
        selectedService,
        has_corresponsible,
        creditCardResp,
        creditCardCorresp,
        parcel,
        fluxo,
        client,
        immobile,
        corresponsavel,
        adhesion,
        clientCorresp,
        confirmarEmail,
      } = this.props

      let transaction = await ApiInstance.post('ConfirmarTransacao', {
        service: selectedService,
        has_corresponsible,
        creditCardResp,
        creditCardCorresp,
        parcel,
        fluxo,
        client,
        immobile,
        corresponsavel,
        adhesion,
        clientCorresp,
        confirmarEmail,
      })

      this.setState({
        company: transaction.data.company
          ? { name: transaction.data.company.name, plan_id: transaction.data.company.plan_id }
          : { plan_id: 1 },
        clientName: transaction.data.client && transaction.data.client.name,
        corresponsibleName: transaction.data && transaction.data.corresponsible.name,
        linkContract: transaction.data && transaction.data.contract.locator_contract,
        linkContractCorresponsible: transaction.data && transaction.data.contract.corresponsable_contract,
        linkPayment: transaction.data && transaction.data.link,
        linkCorresponsible: transaction.data && transaction.data.linkCorresponsible,
        fluxo: transaction.data.fluxo,
      })

      setTimeout(() => {
        this.props.changeLoading(false)
        this.setState({ success: true })
      }, 5000)
    } catch (error) {
      setTimeout(async () => {
        let type_error = error.originalMessage.split('-')[0]
        if (type_error === 'ERRO_VINDI') {
          let origin_error = error.originalMessage.split('-')[1]
          let message_error = error.message
          this.setState({ error: true, type_error, origin_error, message_error })
        } else if (type_error === 'ERRO_MOTOR') {
          this.setState({
            error: true,
            type_error,
            origin_error: 'Motor',
            message_error: 'O Cliente nao se enquadra aos critérios de analise da Ucred',
          })
        } else {
          this.setState({
            error: true,
            type_error: 'ERRO_UNKNOWN',
            origin_error: 'UNKNOWN',
            message_error: 'Falha ao transacionar',
          })
        }
        let { selectedService, client, adhesion, authUser, finalValue, parcel } = this.props
        let errorMessage = error.message
        await ApiInstance.post('errorTransactionReport', {
          selectedService,
          client,
          adhesion,
          authUser,
          finalValue,
          parcel,
          error,
          errorMessage,
        })

        this.props.changeLoading(false)
      }, 1500)
    }
  }

  back = () => {
    this.props.back()
  }

  print = async () => {
    window.print()
  }

  render() {
    let {
      selectedService,
      has_corresponsible,
      parcel,
      fluxo,
      client,
      immobile,
      corresponsavel,
      adhesion,
      finalValue,
      user,
      type_immobile,
      transactionValue
    } = this.props
    let {
      type_error,
      message_error,
      origin_error,
      clientName,
      company,
      linkContract,
      linkContractCorresponsible,
      linkPayment,
      corresponsibleName,
      linkCorresponsible,
    } = this.state
    return (
      <div className="step3">
        <div className="print-logo">
          <img src={Logo} alt="ucred-logo" style={{ width: 250 }}></img>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box
              id="print-area"
              style={{
                background: '#fff',
                borderRadius: 16,
                boxShadow: '0px 0px 10px 0px rgb(0, 0, 0, .05)',
                overflow: 'hidden',
              }}
            >
              <Box style={{ background: '#044c94', color: '#fff', display: 'flex' }}>
                <Typography className="print-hidden" variant="h6" style={{ padding: '16px 24px' }}>
                  Resumo da transação
                </Typography>
                <Box
                  className="print-hidden"
                  onClick={this.print}
                  style={{
                    marginLeft: 'auto',
                    padding: '16px 0',
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                >
                  <Fab size="small" color="secondary">
                    <PrintRounded style={{ color: '#fff' }} />
                  </Fab>
                  <Typography style={{ color: '#fff', margin: '0 8px', marginRight: 24 }}>Imprimir</Typography>
                </Box>
              </Box>
              <Divider className="print-hidden"></Divider>
              <Box p={2}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography variant="h6" color="primary">
                          Dados da transação
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>Serviço contratado</Typography>
                        <Typography variant="body2" color="textSecondary">
                          {selectedService.name}
                        </Typography>
                      </Grid>

                      {!!selectedService.cover_value && (Number(user.plan_id) === 2 || Number(user.plan_id) === 4) && (
                        <Grid item xs={12} sm={6} md={4}>
                          <Typography>Cobertura Ucred</Typography>
                          <Typography variant="body2" color="textSecondary">
                            R$ {Number(selectedService.cover_value * transactionValue).toFixed(2)}
                          </Typography>
                        </Grid>
                      )}

                      {!!(Number(user.plan_id) === 2 || Number(user.plan_id) === 4) && (
                        <Grid item xs={12} sm={6} md={4}>
                          <Typography>Taxa locatário</Typography>
                          <Typography variant="body2" color="textSecondary">
                            {Number(selectedService.over_value) + Number(selectedService.administration_fee)} %
                          </Typography>
                        </Grid>
                      )}

                      {!!Number(user.plan_id) === 1 && (
                        <Grid item xs={12} sm={6} md={4}>
                          <Typography>Taxa administrativa</Typography>
                          <Typography variant="body2" color="textSecondary">
                            {Number(selectedService.administration_fee)} %
                          </Typography>
                        </Grid>
                      )}

                      {!!Number(this.props.adhesion.value) && (
                        <Grid item xs={12} sm={6} md={4}>
                          <Typography>Taxa de adesão</Typography>
                          <Typography variant="body2" color="textSecondary">
                            R$ {Number(adhesion.value).toFixed(2)}
                          </Typography>
                        </Grid>
                      )}

                      <Grid item xs={12} sm={6} md={4}>
                        <Typography>Valor avalizado</Typography>
                        <Typography variant="body2" color="textSecondary">
                          R$ {Number(finalValue).toFixed(2)}
                        </Typography>
                      </Grid>

                      {(!this.props.confirmarEmail || this.props.authUser.company_id === 5) && (
                        <Grid item xs={12} sm={6} md={4}>
                          <Typography>Valor parcela</Typography>
                          <Typography variant="body2" color="textSecondary">
                            {parcel} x R$ {(Number(finalValue) / Number(parcel)).toFixed(2)}
                          </Typography>
                        </Grid>
                      )}

                      {!!Number(adhesion.parcel) && (
                        <Grid item xs={12} sm={6} md={4}>
                          <Typography>Taxa de adesão</Typography>
                          {!!adhesion.parcel && (
                            <Typography variant="body2" color="textSecondary">
                              {adhesion.parcel} x R$ {adhesion.value / adhesion.parcel}{' '}
                            </Typography>
                          )}
                          {!adhesion.parcel && <Typography></Typography>}
                        </Grid>
                      )}

                      <Grid item xs={12} sm={6} md={4}>
                        <Typography>Valor total a ser cobrado no cartão</Typography>
                        <Typography variant="body2" color="textSecondary">
                          R$ {(Number(finalValue) + Number(adhesion.value)).toFixed(2)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography variant="h6" color="primary">
                          Dados do imóvel que deseja locar
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography>CEP</Typography>
                        <Typography variant="body2" color="textSecondary">
                          {immobile.cep}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography>Endereço completo</Typography>
                        <Typography variant="body2" color="textSecondary">
                          {immobile.address}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography>Cidade</Typography>
                        <Typography variant="body2" color="textSecondary">
                          {immobile.city}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} className="print-same-line">
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography variant="h6" color="primary">
                            Dados do locatário
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>Nome</Typography>
                          <Typography variant="body2" color="textSecondary">
                            {client.name}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Typography>CPF</Typography>
                          <Typography variant="body2" color="textSecondary">
                            {client.cpf}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Typography>Data de nascimento</Typography>
                          <Typography variant="body2" color="textSecondary">
                            {client.birthday}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Typography>Telefone celular</Typography>
                          <Typography variant="body2" color="textSecondary">
                            {client.telephone_1}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Typography>E-mail</Typography>
                          <Typography variant="body2" color="textSecondary">
                            {client.email}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    {!!has_corresponsible && (
                      <Grid item xs={12}>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Typography variant="h6" color="primary">
                              Dados do corresponsável
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Typography>Nome</Typography>
                            <Typography variant="body2" color="textSecondary">
                              {corresponsavel.name}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Typography>CPF</Typography>
                            <Typography variant="body2" color="textSecondary">
                              {corresponsavel.cpf}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Typography>Data de nascimento</Typography>
                            <Typography variant="body2" color="textSecondary">
                              {corresponsavel.birthday}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Typography>Telefone celular</Typography>
                            <Typography variant="body2" color="textSecondary">
                              {corresponsavel.telephone_1}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Typography>E-mail</Typography>
                            <Typography variant="body2" color="textSecondary">
                              {corresponsavel.email}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>

                  {this.props.user.contractStatus === 'running' && (
                    <Grid item xs={12}>
                      <Typography variant="body" color="primary">
                        No momento não é possível confirmar a transação. É necessário assinar o contrato Ucred que foi
                        enviado para seu E-mail
                      </Typography>
                    </Grid>
                  )}
                  {/**
                  {this.props.type_immobile === 1 && (
                    <Typography variant="body" color="primary" justifyContent="center" align="center">
                      Transação reprovada. O locatário foi reprovado na análise definitiva da Ucred.
                    </Typography>
                  )}
                  */}
                </Grid>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12}>
            {this.props.user.contractStatus !== 'running' && (
              <Grid container spacing={1} direction="row" justifyContent="flex-end" style={{ padding: '0 16' }}>
                <Grid className="print-hidden" item>
                  <Button
                    disabled={this.props.loading}
                    size="large"
                    color="primary"
                    variant="outlined"
                    onClick={this.back}
                  >
                    Voltar
                  </Button>
                </Grid>
                <Grid className="print-hidden" item>
                  <Box style={{ display: 'flex', position: 'relative' }}>
                    {this.props.loading && (
                      <CircularProgress
                        size={24}
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          marginTop: -12,
                          marginLeft: -12,
                        }}
                      />
                    )}
                    <Button
                      id="finalize-transaction-button"
                      disabled={this.props.loading}
                      size="large"
                      color="primary"
                      variant="contained"
                      onClick={this.concluir}
                    >
                      Concluir
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Modal open={this.state.error} onClose={() => {}} classNames={{ modal: 'modalErrorTransaction' }}>
          <p className="icon">
            {' '}
            <i className="lni-warning"></i>{' '}
          </p>
          {type_error === 'ERRO_VINDI' && <p className="title"> Erro ao passar o cartão</p>}
          {type_error === 'ERRO_MOTOR' && <p className="title"> Critérios não atendidos pela análise de crédito</p>}
          {type_error === 'ERRO_VINDI' && <p className="subTitle"> Erro com o cartão do {origin_error} </p>}
          {type_error === 'ERRO_UNKNOWN' && <p className="title"> Erro Transação </p>}
          <p className="message">Motivo da falha: </p>
          <p className="descMessage">{message_error}</p>
          Caso tenha dúvidas entre em contato com nosso suporte:
          <div className="container-check"></div>
          <span style={{ fontSize: 20, color: '#009fff' }}>
            <i className="lni lni-whatsapp"></i>&nbsp;(32) 98480-9602 &nbsp;&nbsp;
            <i className="lni lni-laptop"></i>&nbsp;contato@ucred.io
          </span>
          {type_error === 'ERRO_UNKNOWN' ? (
            <Button
              onClick={() => {
                this.back()
              }}
            >
              Tentar Transacionar Novamente
            </Button>
          ) : (
            <Button
              onClick={() => {
                this.props.refresh()
              }}
              data-testid="new-transaction-button"
            >
              Nova Transação
            </Button>
          )}
        </Modal>
        {(this.props.step === 4 || this.props.step === 3) && (
          <Dialog
            transitionDuration={{ exit: 0 }}
            disableScrollLock
            className="print-hidden"
            open={this.state.success}
            onClose={() => this.props.refresh()}
            keepMounted
          >
            <DialogTitle
              disableTypography
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {type_immobile !== 1 && <Typography variant="h6">Transação concluída</Typography>}
              {type_immobile === 1 && <Typography variant="h6">Transação aguardando aprovação da Ucred</Typography>}
              <IconButton onClick={() => this.props.refresh()} size="small" style={{ marginLeft: 'auto' }}>
                <Close></Close>
              </IconButton>
            </DialogTitle>

            {/** OUTRAS EMPRESAS */}

            <DialogContent dividers>
              {this.state.success && (
                <>
                  {fluxo === 2 && company.plan_id !== 1 ? (
                    <>
                      {type_immobile === 1 && (
                        <DialogContentText>
                          Esta transação está pendente de uma análise mais detalhada da Ucred para liberação ou
                          reprovação do locatário. A resposta definitiva da análise você poderá visualizar através da
                          tela de acompanhamento da nossa plataforma e também através das notificações por e-mail,
                          podendo demorar alguns minutos.
                        </DialogContentText>
                      )}
                      {type_immobile !== 1 && (
                        <>
                          <DialogContentText>
                            Foi enviado por e-mail ao locatário o link para pagamento e assinatura de contrato Ucred.
                          </DialogContentText>
                          <DialogContentText>
                            Caso queira enviar ao locatário o link para pagamento e assinatura de contrato Ucred, copie
                            a mensagem clicando no botão abaixo e compartilhe com ele onde preferir.
                          </DialogContentText>

                          <Lottie
                            style={{ opacity: 1 }}
                            width="200px"
                            speed={1}
                            segments={[220, 400]}
                            options={{
                              animationData: ApprovedReproved,
                              autoplay: true,
                              loop: false,
                            }}
                          />
                          <Box
                            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginBottom: 8 }}
                            onClick={() => {
                              Copy(
                                `Olá ${clientName}, primeiramente estamos lhe dando os parabéns por realizar uma locação com a Imobiliária ${company.name}, você vai ver como é simples, fácil e sem burocracia.\n\nSegue abaixo os links para pagamento e o contrato para assinatura:\n\nLink de pagamento: ${linkPayment}\n\nContrato para assinatura: ${linkContract}\n\nCaso tenha qualquer dúvida, entre em contato com sua imobiliária.`
                              )
                              const snackbarKey = this.props.enqueueSnackbar('Mensagem do locatário copiada.', {
                                variant: 'info',
                                onClick: () => {
                                  this.props.closeSnackbar(snackbarKey)
                                },
                              })
                            }}
                          >
                            <Fab color="primary" size="small">
                              <Person></Person>
                            </Fab>
                            <Typography style={{ paddingLeft: 8 }}>Copiar mensagem locatário</Typography>
                          </Box>

                          {!!linkCorresponsible && (
                            <Box
                              style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginBottom: 8 }}
                              onClick={() => {
                                Copy(
                                  `Olá ${corresponsibleName}, primeiramente estamos lhe dando os parabéns por realizar uma locação com a Imobiliária ${company.name}, você vai ver como é simples, fácil e sem burocracia.\n\nSegue abaixo os links para pagamento e o contrato para assinatura:\n\nLink de pagamento: ${linkCorresponsible}\n\nContrato para assinatura: ${linkContractCorresponsible}\n\nCaso tenha qualquer dúvida, entre em contato com sua imobiliária.`
                                )
                                const snackbarKey = this.props.enqueueSnackbar('Mensagem do corresponsável copiada.', {
                                  variant: 'info',
                                  onClick: () => {
                                    this.props.closeSnackbar(snackbarKey)
                                  },
                                })
                              }}
                            >
                              <Fab color="primary" size="small">
                                <PersonOutline></PersonOutline>
                              </Fab>
                              <Typography style={{ paddingLeft: 8 }}>Copiar mensagem corresponsável</Typography>
                            </Box>
                          )}
                        </>
                      )}
                      <Box onClick={this.print} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                        <Fab color="primary" size="small">
                          <PrintRounded></PrintRounded>
                        </Fab>
                        <Typography style={{ paddingLeft: 8 }}>Imprimir resumo da transação</Typography>
                      </Box>
                    </>
                  ) : (
                    <>
                      <DialogContentText>Transação finalizada com sucesso!</DialogContentText>

                      <Box onClick={this.print} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                        <Fab color="primary" size="small">
                          <PrintRounded></PrintRounded>
                        </Fab>
                        <Typography style={{ paddingLeft: 8 }}>Imprimir resumo da transação</Typography>
                      </Box>
                    </>
                  )}
                </>
              )}
            </DialogContent>

            <DialogActions>
              {fluxo === 2 && company.plan_id !== 1 ? (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    this.props.refresh()
                  }}
                  data-testid="new-transaction-button-1"
                >
                  Nova Transação
                </Button>
              ) : (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    this.props.refresh()
                  }}
                  data-testid="new-transaction-button-2"
                >
                  Nova Transação
                </Button>
              )}
            </DialogActions>
          </Dialog>
        )}
      </div>
    )
  }
}

function mapStateToProps({ creditAnalysis, authReducer, authReducer: { authUser } }) {
  return {
    ...creditAnalysis,
    ...authReducer,
    user: { ...authUser },
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fillField: (field, value) => dispatch(fillField(field, value)),
    refresh: () => dispatch(refresh()),
  }
}

export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(Step3))
