/* -------------------------------------------------------------------------- */
/*        A VALIDAÇÃO RETORNARÁ UM OBJETO CONTENDO "success" ou "error"       */
/* -------------------------------------------------------------------------- */
const validateEmail = (email) => {
  let parts = email.split('@')

  if (parts.length > 2) {
    return {
      error: 'O email informado é inválido!',
      provided: email,
      required: 'email@domain.com',
    }
  }

  if (/\S+@\S+\.\S+/.test(email)) {
    return {
      success: 'O email informado é válido!',
      provided: email,
      required: 'email@domain.com',
    }
  } else {
    return {
      error: 'O email informado é inválido!',
      provided: email,
      required: 'email@domain.com',
    }
  }
}

export default validateEmail
