import React from 'react'
import Header from '../../layout/Header'

export default class Notas extends React.Component {

    render() {
        return (
            <React.Fragment>
            <Header />
            <div className="nota">
                <div className="nota__container">
                    <div className="nota__container-header">
                        <h1> Notas </h1>
                    </div>
                    <div className="nota__container-body">
                    </div>
                </div>
            </div>
        </React.Fragment>
        )
    }
}