import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { withSnackbar } from 'notistack'
import React from 'react'
import Dropzone from 'react-dropzone'
import ApiInstance from '../services/ApiInstance'

class modalDoc extends React.Component {
  constructor() {
    super()
    this.state = {
      bank_slip_documents: [],
    }
  }

  render() {
    const { open, onClose } = this.props
    const { bank_slip_documents } = this.state

    const convertToBase64 = (file, cb) => {
      //Read File
      var selectedFile = file
      //Check File is not Empty
      if (selectedFile.length > 0) {
        // Select the very first file from list
        var fileToLoad = selectedFile[0]
        // FileReader function for read the file.
        var fileReader = new FileReader()
        var base64
        // Onload of file read the file content
        fileReader.onload = function (fileLoadedEvent) {
          base64 = fileLoadedEvent.target.result
          // Print data in console
          cb(base64)
        }
        // Convert data to base64
        fileReader.readAsDataURL(fileToLoad)
      }
    }

    const dropDocument = (file) => {
      const { bank_slip_documents } = this.state

      if (!file.length) {
        const snackbarKey = this.props.enqueueSnackbar('O tamanho máximo permitido por arquivo é de 2MB.', {
          variant: 'error',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        })
        return
      }
      if (bank_slip_documents.length === 3) {
        const snackbarKey = this.props.enqueueSnackbar('Você pode enviar apenas 3 documentos por vez.', {
          variant: 'error',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        })
        return
      }

      convertToBase64(file, (base64) => {
        this.setState({
          bank_slip_documents: [
            ...bank_slip_documents,
            {
              base64,
              id: bank_slip_documents.length + 1,
              description: file[0].name.split('.')[0],
            },
          ],
        })
      })
    }

    const removeDocument = (index) => {
      bank_slip_documents.splice(index, 1)

      this.setState({ bank_slip_documents })
    }

    const handleSend = async () => {
      if (bank_slip_documents.length < 1) {
        const snackbarKey = this.props.enqueueSnackbar('Insira ao menos um arquivo.', {
          variant: 'error',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        })
        return
      }
      this.setState({ loading: true })
      await ApiInstance.post('bank_slip/document/upload', {
        bank_slip_documents,
        purchaseId: this.props.purchase,
      })
        .then(() => {
          this.setState({ loading: false })
          onClose()
          const snackbarKey = this.props.enqueueSnackbar('Arquivo(s) enviado(s) com sucesso!', {
            variant: 'success',
            onClick: () => {
              this.props.closeSnackbar(snackbarKey)
            },
          })
          this.setState({ bank_slip_documents: [] })
        })
        .catch(() => {
          this.setState({ loading: false })
        })
    }

    return (
      <Dialog disableScrollLock open={open} onClose={onClose} id="bank-slip-document">
        <DialogTitle
          disableTypography
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6">Envio de comprovante de pagamento</Typography>
          <IconButton onClick={onClose} size="small" style={{ marginLeft: 'auto' }}>
            <Close></Close>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText gutterBottom>
            Utilize este campo para anexar e enviar documentos relacionados a confirmação de pagamento do boleto.
          </DialogContentText>

          <Dropzone onDrop={dropDocument} maxSize={2000000}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <div className="dropzone" {...getRootProps()}>
                  <input {...getInputProps()} />
                  <span className="plus-container">
                    <p className="plus">+</p>
                  </span>
                  <DialogContent align="center">
                    <DialogContentText>Arraste ou clique aqui para enviar documentos.</DialogContentText>
                  </DialogContent>
                </div>
              </section>
            )}
          </Dropzone>

          {bank_slip_documents.map((document, index) => {
            return (
              <span key={index} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <DialogContentText>{document.description}</DialogContentText>
                <IconButton onClick={() => removeDocument(index)} size="small" color="primary" variant="contained">
                  <Close></Close>
                </IconButton>
              </span>
            )
          })}
        </DialogContent>

        <DialogActions>
          <Button disabled={this.state.loading} onClick={onClose} color="primary">
            Cancelar
          </Button>
          <Button disabled={this.state.loading} onClick={handleSend} color="primary" variant="contained" autoFocus>
            {this.state.loading ? <CircularProgress color="primary" size={26} /> : 'Enviar'}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
export default withSnackbar(modalDoc)
