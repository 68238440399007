import Api from '../../services/ApiInstance'
import React from 'react'

class LogOutRevendedor extends React.Component {
  render() {
    logOut()
    this.props.history.push('/login-revendedor')
    return (
      <div></div>
    )
  }
}

function logOut() {
  Api.post('logout')
}

export default LogOutRevendedor