import { useCallback } from 'react'
import { Box, Divider, Flex, Text, useBreakpointValue, useDisclosure } from '@chakra-ui/react'
import { PrivacyPolicy } from './components/PrivacyPolicyModal'
import { UseTermsModal } from './components/UseTermsModal'
import { FooterButton } from './components/FooterButton'
import { useSelector } from 'react-redux'

export default function Footer() {
  const isUserLogged = !!useSelector((state: any) => state.authReducer.authUser)
  const fontSize = useBreakpointValue({ base: 'xs', sm: 'xs', '2xl': 'sm' })
  const marginRight = useBreakpointValue({ base: 20, md: 20, lg: 24 })

  const {
    isOpen: isTermsModalOpen,
    onOpen: onTermsModalOpen,
    onClose: onTermsModalClose,
  } = useDisclosure()
  const { isOpen: isPrivacyOpen, onOpen: onPrivacyOpen, onClose: onPrivacyClose } = useDisclosure()

  const handleTermsPress = useCallback(() => {
    onTermsModalOpen()
  }, [onTermsModalOpen])

  const handlePrivacyPress = useCallback(() => {
    onPrivacyOpen()
  }, [onPrivacyOpen])

  return isUserLogged ? (
    <Box>
      <Divider />
      <UseTermsModal isOpen={isTermsModalOpen} onClose={onTermsModalClose} />
      <PrivacyPolicy isOpen={isPrivacyOpen} onClose={onPrivacyClose} />
      <Box mt={2} mb={4}>
        <Flex justify="center">
          <Flex align="baseline">
            <Text fontSize="sm" color="gray.600" mr={1}>
              &copy; Ucred 2021
            </Text>
            <Text fontSize="sm" color="gray.400">
              |
            </Text>
            <FooterButton title="Termos de uso" handlePress={handleTermsPress} />
            <Text fontSize="sm" color="gray.400">
              |
            </Text>
            <FooterButton title="Política de privacidade" handlePress={handlePrivacyPress} />
          </Flex>
        </Flex>
      </Box>
    </Box>
  ) : (
    <Box mt={-12}>
      <UseTermsModal isOpen={isTermsModalOpen} onClose={onTermsModalClose} />
      <PrivacyPolicy isOpen={isPrivacyOpen} onClose={onPrivacyClose} />
      <Box mt={2} mb={4}>
        <Flex justify="flex-end" mr={marginRight}>
          <Flex align="baseline">
            <Text fontSize={fontSize} color="gray.600" mr={1}>
              &copy; Ucred 2021
            </Text>
            <Text fontSize={fontSize} color="gray.400">
              |
            </Text>
            <FooterButton
              fontSize={fontSize}
              title="Termos de uso"
              handlePress={handleTermsPress}
            />
            <Text fontSize={fontSize} color="gray.400">
              |
            </Text>
            <FooterButton
              fontSize={fontSize}
              title="Política de privacidade"
              handlePress={handlePrivacyPress}
            />
          </Flex>
        </Flex>
      </Box>
    </Box>
  )
}
