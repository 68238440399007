import React from 'react'
import Header from '../../layout/Header'
import { connect } from 'react-redux'
import { fillField, refresh } from '../../redux/actions/creditAnalysisActions'
import { Box, Breadcrumbs, Container, Grid, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { Home } from '@material-ui/icons'
import Footer from '../../layout/Footer'

class Ajustes extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      aba: 'ucred',
    }
  }

  openTab(aba) {
    this.setState({ aba })
  }
  render() {
    const { authUser } = this.props
    const { aba } = this.state
    return (
      <>
        <Header />
        <Container
          style={{
            paddingTop: 32,
            marginTop: 80
          }}
        >
          <Grid spacing={3} container>
            <Grid item xs={12}>
              <Breadcrumbs className="breadcrumbs">
                <Link to="/" style={{ display: 'flex' }}>
                  <Home
                    style={{
                      marginRight: 8,
                      width: 20,
                      height: 20,
                    }}
                  />
                  Início
                </Link>
                <Typography color="textPrimary">Ajustes de contrato</Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item xs={12}>
              <Box style={{ display: 'flex', justifyContent: 'center' }}>
                <Typography
                  style={{ margin: 16, cursor: 'pointer' }}
                  color={aba === 'ucred' ? 'primary' : 'textPrimary'}
                  onClick={() => this.openTab('ucred')}
                  variant="h6"
                >
                  Garantia Ucred
                </Typography>
                {authUser && authUser.plan_id === 1 && (
                  <Typography
                    style={{ margin: 16, cursor: 'pointer' }}
                    color={aba === 'propria' ? 'primary' : 'textPrimary'}
                    variant="h6"
                    onClick={() => this.openTab('propria')}
                  >
                    Garantia Própria
                  </Typography>
                )}
              </Box>
              {aba === 'ucred' && (
                <iframe
                  style={{ width: '100%', height: '60vh' }}
                  src="https://drive.google.com/file/d/1RqXGsNcU12p7p5vLUTUMZhqgoWFzGJuy/preview"
                ></iframe>
              )}
              {aba === 'propria' && (
                <iframe
                  style={{ width: '100%', height: '60vh' }}
                  src="https://drive.google.com/file/d/1sOsRE1CtKpuRQRJ3C9FSuzgqoor9HisG/preview"
                ></iframe>
              )}
            </Grid>
          </Grid>
        </Container>
      </>
    )
  }
}

function mapStateToProps({ creditAnalysis, authReducer }) {
  return {
    ...creditAnalysis,
    ...authReducer,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fillField: (field, value) => dispatch(fillField(field, value)),
    refresh: () => dispatch(refresh()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Ajustes)
