export function getBaseUrl(): string {
  switch (process.env.REACT_APP_STAGE) {
    case 'development':
      return 'http://localhost:3333/'
    case 'staging':
      return 'http://app.hml.ucred.io/'
    case 'production':
      return 'http://app.ucred.io/'
    default:
      return 'http://localhost:3333/'
  }
}
