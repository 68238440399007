import { Button, Divider, Fab, Typography } from '@material-ui/core'
import React from 'react'
import ApiInstance from '../../services/ApiInstance'
import BankSlipIcon from '../../static/svg/bank-slip.svg'
import BarCodeIcon from '../../static/svg/bar-code.svg'
import ContractIcon from '../../static/svg/contract.svg'
import { maskMoney, moneyToNumber } from '../../util/money'

export default class BankSlip extends React.Component {
  state = {
    print_url: '',
    typable_barcode: '',
    contract_url: '',
    contract_status: '',
  }

  async componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search)
    const hash = urlParams.get('hash')
    const twoCards = urlParams.get('twoCards')

    await ApiInstance.post('bank_slip', { hash, twoCards, parcel: this.props.parcel || 1 })
      .then(response => {
        this.props.parent.setState({ loading: false })
        this.setState({
          print_url: response.print_url,
          typable_barcode: response.typeable_barcode,
          contract_url: response.contract_url,
          contract_status: response.contract_status,
        })
        return response
      })
      .catch(() => {
        //this.props.parent.setState({ loading: false, step: 0, snackbar: { ...snackbar, open: true } })
      })
  }

  render() {
    const { print_url, typable_barcode, contract_url, contract_status } = this.state
    const { purchase, corresponsible_purchase, adhesion } = this.props.parent.state

    const copyBankSlipCode = () => {
      var message = document.getElementById(`bank-slip-to-copy`)
      var text = document.createElement('textarea')
      text.value = message.textContent
      document.body.appendChild(text)
      text.select()
      document.execCommand('copy')

      text.remove()
    }

    const bankSlipCode = () => {
      try {
        return typable_barcode
      } catch (error) {
        return ''
      }
    }

    return (
      <div id="bank-slip">
        <textarea
          id="bank-slip-to-copy"
          style={{ display: 'none' }}
          value={bankSlipCode()}
        ></textarea>
        <div className="container">
          <div className="header-comp">
            <Typography variant="h1" component="h1">
              Dados do boleto
            </Typography>
          </div>

          <Divider></Divider>

          <div className="bank-slip">
            <div className="column">
              <div className="item buttons">
                <div className="line">
                  <Fab
                    variant="extended"
                    color="primary"
                    onClick={() => window.open(print_url, '_blank')}
                  >
                    <img alt="boleto" src={BankSlipIcon}></img>
                    Visualizar o boleto
                  </Fab>
                </div>
                <div className="line">
                  <Fab
                    disabled={contract_url && contract_status !== 'signed' ? false : true}
                    variant="extended"
                    onClick={() => window.open(contract_url, '_blank')}
                    color="primary"
                  >
                    <img alt="contract" src={ContractIcon}></img>
                    Assinar o contrato
                  </Fab>
                </div>
              </div>
            </div>
            <Divider flexItem orientation="vertical"></Divider>
            <Divider></Divider>
            <div className="column">
              <div className="item">
                <Typography variant="body1" component="p">
                  Endereço
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {purchase.address}
                </Typography>
              </div>
              <div className="item">
                <Typography variant="body1" component="p">
                  Cidade
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {purchase.city}
                </Typography>
              </div>
              <div className="item">
                <Typography variant="body1" component="p">
                  CEP
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {purchase.cep}
                </Typography>
              </div>
              <Divider style={{ margin: '16px 0' }} />
              <div className="item" style={{ justifyContent: 'flex-end' }}>
                <Typography variant="body1" component="p" className="total_value text">
                  Valor total
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  className="total_value value"
                >
                  {maskMoney(
                    moneyToNumber(purchase.value) +
                      moneyToNumber(corresponsible_purchase.value || 0) +
                      (adhesion[0] ? adhesion[0].value : 0),
                  )}
                </Typography>
              </div>
            </div>
          </div>

          <div className="next-step">
            <Button
              color="primary"
              disabled
              variant="contained"
              style={{ width: '100%' }}
              onClick={() => this.props.parent.setState({ step: 0 })}
              className="back"
            >
              Voltar
            </Button>
            <Button
              disabled
              color="primary"
              variant="contained"
              style={{ width: '100%' }}
              onClick={() => this.props.parent.setState({ step: 1 })}
              className="next"
            >
              Continuar
            </Button>
          </div>
        </div>
      </div>
    )
  }
}
