import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
} from '@chakra-ui/react'
import { MonthButton } from './components/MonthButton'

type MonthsAllowedType = {
  number: number
  title: string
  isActive: boolean
}
interface MonthPickerModalProps {
  months: MonthsAllowedType[] | undefined
  monthSelected: number | undefined
  yearSelected: number | undefined
  isOpen: boolean
  onClose: () => void
  handleSelectMonth: (monthSelected: number) => void
}

export function MonthPickerModal({
  months,
  monthSelected,
  yearSelected,
  isOpen,
  onClose,
  handleSelectMonth,
}: MonthPickerModalProps) {
  return (
    <Modal
      size="lg"
      scrollBehavior="inside"
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      motionPreset="slideInRight"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontWeight="semibold" color="gray.500">
          {yearSelected}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <SimpleGrid py={3} columns={3} spacing={2}>
            {months &&
              months.map(month => (
                <MonthButton
                  selected={month.number === monthSelected}
                  key={month.number}
                  title={month.title}
                  isActive={month.isActive}
                  onClick={() => handleSelectMonth(month.number)}
                />
              ))}
          </SimpleGrid>
        </ModalBody>
        <ModalFooter>
          <Button color="gray.600" onClick={onClose}>
            Voltar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
