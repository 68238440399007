import { QuestionOutlineIcon } from '@chakra-ui/icons'
import {
  FormLabel,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Tooltip,
} from '@chakra-ui/react'
import moment from 'moment'
import React, { useContext } from 'react'
import MaskedInput from 'react-input-mask'
import CreditCardInput from '../../../../components/creditCardInput'
import ChangePaymentMethodContext from '../../../../contexts/ChangePaymentMethod'
import ChakraMaskedInput from '../../../../components/maskedChakraInput'

export default function SecondCreditCardElements() {
  const { secondPaymentMethod, changeSecondCreditCard, errors } = useContext(
    ChangePaymentMethodContext,
  ) as unknown as CreatePaymentMethod
  return (
    <Grid templateColumns="repeat(12, 1fr)" gap={3}>
      {/* ---------------------------------- NOME ---------------------------------- */}
      <GridItem colSpan={12}>
        <FormLabel
          color="gray.500"
          fontSize="xs"
          fontWeight="semibold"
          style={{ textTransform: 'uppercase' }}
          mb={0}
          pl={2}
        >
          Nome completo
        </FormLabel>
        <Input
          id="second_holder_name"
          name="name"
          error={!!errors.filter(value => value.field === 'second_holder_name').length}
          disabled
          placeholder="Nome"
          value={secondPaymentMethod.name}
          onChange={changeSecondCreditCard}
        />
      </GridItem>

      {/* --------------------------------- NÚMERO --------------------------------- */}
      <GridItem colSpan={12}>
        <FormLabel
          color="gray.500"
          fontSize="xs"
          fontWeight="semibold"
          style={{ textTransform: 'uppercase' }}
          mb={0}
          pl={2}
        >
          Número do cartão
        </FormLabel>
        <CreditCardInput
          autoFocus
          id="second_card_number"
          name="number"
          isInvalid={!!errors.filter(value => value.field === 'second_card_number').length}
          placeholder="Número"
          value={secondPaymentMethod.number}
          onChange={changeSecondCreditCard}
        />
      </GridItem>

      {/* ----------------------------------- CVV ---------------------------------- */}
      <GridItem colSpan={6}>
        <FormLabel
          color="gray.500"
          fontSize="xs"
          fontWeight="semibold"
          style={{ textTransform: 'uppercase' }}
          mb={0}
          pl={2}
        >
          Cvv
        </FormLabel>
        <InputGroup>
          <ChakraMaskedInput
            as={MaskedInput}
            id="second_card_cvv"
            name="cvv"
            isInvalid={!!errors.filter(value => value.field === 'second_card_cvv').length}
            maskChar=""
            mask="9999"
            placeholder="CVV"
            value={secondPaymentMethod.cvv}
            onChange={changeSecondCreditCard}
          />
          <InputRightElement
            /* eslint-disable-next-line react/no-children-prop */
            children={
              <Tooltip
                label="O número código de segurança é representado pelos três últimos dígitos no verso do seu cartão. Em cartões American Express, o código de segurança é um número de 4 dígitos na frente do cartão."
                fontSize="md"
              >
                <QuestionOutlineIcon color="gray.500" />
              </Tooltip>
            }
          />
        </InputGroup>
      </GridItem>

      {/* -------------------------------- VALIDADE -------------------------------- */}
      <GridItem colSpan={6}>
        <FormLabel
          color="gray.500"
          fontSize="xs"
          fontWeight="semibold"
          style={{ textTransform: 'uppercase' }}
          mb={0}
          pl={2}
        >
          Validade
        </FormLabel>
        <ChakraMaskedInput
          as={MaskedInput}
          id="second_card_expiration"
          name="expiration_date"
          isInvalid={!!errors.filter(value => value.field === 'second_card_expiration').length}
          maskChar=""
          mask="99/9999"
          placeholder={moment().add('4', 'y').format('MM/YYYY')}
          value={secondPaymentMethod.expiration_date}
          onChange={changeSecondCreditCard}
        />
      </GridItem>

      {/* ------------------------------ PARCELAMENTO ------------------------------ */}
      <GridItem colSpan={12}>
        <FormLabel
          color="gray.500"
          fontSize="xs"
          fontWeight="semibold"
          style={{ textTransform: 'uppercase' }}
          mb={0}
          pl={2}
        >
          Parcelamento
        </FormLabel>
        <Select
          id="second_card_parcel"
          name="parcel"
          isInvalid={!!errors.filter(value => value.field === 'second_card_parcel').length}
          placeholder="Parcelas"
          value={secondPaymentMethod.parcel}
          onChange={changeSecondCreditCard}
        >
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((parcel, index) => (
            <option value={parcel} key={index}>
              Parcelar em {parcel}x
            </option>
          ))}
        </Select>
      </GridItem>

      {/* -------------------------- VENCIMENTO DA FATURA -------------------------- */}
      <GridItem colSpan={12}>
        <FormLabel
          color="gray.500"
          fontSize="xs"
          fontWeight="semibold"
          style={{ textTransform: 'uppercase' }}
          mb={0}
          pl={2}
        >
          Vencimento da fatura do cartão
        </FormLabel>
        <InputGroup>
          <ChakraMaskedInput
            as={MaskedInput}
            id="second_card_maturity"
            name="card_maturity"
            isInvalid={!!errors.filter(value => value.field === 'second_card_maturity').length}
            maskChar=""
            mask="99"
            placeholder="Vencimento da fatura do cartão"
            value={secondPaymentMethod.card_maturity}
            onChange={changeSecondCreditCard}
          />
          <InputRightElement
            /* eslint-disable-next-line react/no-children-prop */
            children={
              <Tooltip
                label="O vencimento da fatura do cartão é relativo a data limite que você tem para pagar a fatura do seu cartão de crédito."
                fontSize="md"
              >
                <QuestionOutlineIcon color="gray.500" />
              </Tooltip>
            }
          />
        </InputGroup>
      </GridItem>
    </Grid>
  )
}
