import { Badge, BadgeProps } from '@chakra-ui/react'
import { getContractStatus } from '../../../../util/getContractStatus'

interface ContractStatusBadge extends BadgeProps {
  status: 'running' | 'signed' | 'canceled' | 'parcial'
}

export function ContractStatusBadge({ status, ...rest }: ContractStatusBadge) {
  const { title, color } = getContractStatus(status)

  return (
    <Badge
      w="fit-content"
      h="fit-content"
      paddingX={2}
      fontSize="sm"
      fontWeight="normal"
      textTransform="unset"
      colorScheme={color}
      {...rest}
    >
      {title}
    </Badge>
  )
}
