import { Close } from '@material-ui/icons'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core'
import React from 'react'

export default class DeleteUser extends React.Component {
  constructor() {
    super()
    this.state = {
      loading: false,
    }
  }

  render() {
    const { loading } = this.state
    return (
      <Dialog disableScrollLock open={this.props.open} onClose={() => this.props.parent.setState({ deleteUserOpen: false })}>
        <DialogTitle
          disableTypography
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6">Atenção</Typography>
          <IconButton onClick={() => this.props.parent.setState({ deleteUserOpen: false })} size="small" style={{ marginLeft: 'auto' }}>
            <Close></Close>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            Esta ação é irreversível, após realizada, não será possível recuperar o usuário sendo necessário a criação de um novo.
          </DialogContentText>

          <DialogContentText>Você confirma que deseja deletar este usuário?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} color="primary" onClick={() => this.props.parent.setState({ deleteUserOpen: false })}>
            Voltar
          </Button>
          <Box style={{ display: 'flex', position: 'relative' }}>
            {loading && (
              <CircularProgress
                size={24}
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: -12,
                  marginLeft: -12,
                }}
              />
            )}
            <Button
              disabled={loading}
              onClick={() => {
                this.setState({ loading: true })
                this.props.parent.deleteUser().then(() => {
                  this.setState({ loading: false })
                })
              }}
              color="primary"
              variant="contained"
            >
              Deletar
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    )
  }
}
