import { useState } from 'react'
import {
  Stack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useToast,
} from '@chakra-ui/react'

import ApiInstance from '../../../../services/ApiInstance'
import { convertToBase64 } from '../../../../util/convertToBase64'
import { DropzoneArea } from '../DropzoneArea'

interface PaymentProofModal {
  isOpen: boolean
  handleCloseModal: () => void
  purchaseId: string | number
}

export function PaymentProofModal({ isOpen, handleCloseModal, purchaseId }: PaymentProofModal) {
  const [loading, setLoading] = useState(false)
  const [documents, setDocuments] = useState<any[]>([])
  const toast = useToast()

  async function onDropzoneChange(files: any[]) {
    const filesArr: any[] = []
    files.map(files => {
      convertToBase64([files], (base64: any) => {
        filesArr.push({ description: files.name.split('.')[0], base64 })
      })
    })
    setDocuments(filesArr)
  }

  async function handleUpload() {
    if (!documents.length)
      return toast({
        title: 'Insira ao menos um documento.',
        status: 'error',
        duration: 2000,
        isClosable: true,
      })

    try {
      setLoading(true)
      await ApiInstance.post('bank_slip/document/upload', {
        bank_slip_documents: documents,
        purchaseId,
      })
      toast({
        title: 'Arquivo(s) enviado(s) com sucesso!',
        status: 'success',
        duration: 2000,
        isClosable: true,
      })
      setLoading(false)
      handleCloseModal()
    } catch {
      toast({
        title: 'Houve um erro inesperado, entre em contato com nosso suporte.',
        status: 'error',
        duration: 2000,
        isClosable: true,
      })
      setLoading(false)
    }
  }

  return (
    <Modal
      size="xl"
      isCentered
      isOpen={isOpen}
      onClose={handleCloseModal}
      motionPreset="slideInBottom"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontWeight="normal" color="gray.600">
          Envio de comprovante de pagamento
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Stack spacing={8}>
            <Text fontSize="md" color="gray.600">
              Utilize este campo para anexar e enviar documentos relacionados a confirmação de
              pagamento do boleto.
            </Text>
            <DropzoneArea
              dropzoneText="Arraste ou clique aqui para enviar documentos."
              onChange={onDropzoneChange}
            />
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button color="gray.600" mr={3} disabled={loading} onClick={handleCloseModal}>
            Voltar
          </Button>
          <Button isLoading={loading} colorScheme="blue" onClick={handleUpload}>
            Enviar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
