import DateFnsUtils from '@date-io/moment'
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, TextField, Typography } from '@material-ui/core'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import moment from 'moment'
import React from 'react'
import MaskedInput from 'react-input-mask'
import ApiInstance from '../../services/ApiInstance'
import { withSnackbar } from 'notistack'
import { Close, Visibility, VisibilityOff } from '@material-ui/icons'

class EditUser extends React.Component {
  constructor() {
    super()
    this.state = {
      open: false,
      loading: false,
      id: '',
      name: '',
      email: '',
      cpf: '',
      password: '',
      token: '',
      telephone: '',
      birthday: null,
      showPassword: false,
    }
  }

  handleOpen(user) {
    this.setState({
      open: true,
      id: user.id,
      name: user.name,
      email: user.email,
      cpf: user.cpf,
      password: user.password,
      telephone: user.telephone,
      birthday: user.birthday,
    })
  }

  render() {
    const { loading, name, email, cpf, password, telephone, birthday } = this.state

    const saveUser = async (event) => {
      event.preventDefault()
      await this.setState({ loading: true })
      try {
        const { id, name, email, cpf, password, birthday, telephone } = this.state
        await ApiInstance.put('usuario', {
          id,
          name,
          email,
          cpf,
          telephone,
          birthday: moment(birthday).format('YYYY-MM-DD'),
          password: password !== '1@-' ? password : null,
          permission: 2,
        })

        this.setState({
          open: false,
          loading: false,
          id: '',
          name: '',
          email: '',
          cpf: '',
          password: '',
          token: '',
          telephone: '',
          birthday: null,
        })
        this.props.parent.fetchData()
        const snackbarKey = this.props.enqueueSnackbar('Usuário editado com sucesso.', {
          variant: 'success',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        })
      } catch (error) {
        const snackbarKey = this.props.enqueueSnackbar(error.message, {
          variant: 'error',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        })
      }
      await this.setState({ loading: false })
    }

    const handleChange = (event) => {
      const { name, value } = event.target

      if (RegExp(/[0-9]/gi).test(value) && name === 'name') return

      this.setState({ [name]: value })
    }

    return (
      <>
        <Dialog disableScrollLock open={this.state.open} onClose={() => this.setState({ open: false })}>
          <DialogTitle
            disableTypography
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6">Editar usuário</Typography>
            <IconButton onClick={() => this.setState({ open: false })} size="small" style={{ marginLeft: 'auto' }}>
              <Close></Close>
            </IconButton>
          </DialogTitle>
          <form onSubmit={saveUser} autoComplete="new-off">
            <DialogContent dividers>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField fullWidth variant="outlined" type="text" placeholder="Nome" name="name" value={name} onChange={handleChange} />
                </Grid>

                <Grid item xs={12}>
                  <MaskedInput maskChar="" mask="999.999.999-99" fullWidth variant="outlined" placeholder="123.456.789-00" label="CPF do usuário" name="cpf" value={cpf} onChange={handleChange}>
                    {(inputProps) => <TextField {...inputProps} />}
                  </MaskedInput>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField fullWidth variant="outlined" type="email" placeholder="jose.ucred@gmail.com" label="E-mail do usuário" name="email" value={email} onChange={handleChange} />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type={this.state.showPassword ? 'text' : 'password'}
                    placeholder="senha"
                    label="Senha do usuário"
                    name="password"
                    value={password}
                    onChange={handleChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => this.setState({ showPassword: !this.state.showPassword })}>{this.state.showPassword ? <Visibility /> : <VisibilityOff />}</IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <MaskedInput
                    maskChar=""
                    mask="(99) 9 9999-9999"
                    name="telephone"
                    fullWidth
                    variant="outlined"
                    placeholder="(32) 9 1234-5678"
                    label="Celular"
                    value={telephone}
                    onChange={handleChange}
                  >
                    {(inputProps) => <TextField {...inputProps} />}
                  </MaskedInput>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      fullWidth
                      disableFuture
                      openTo="year"
                      name="birthday"
                      format="DD/MM/YYYY"
                      emptyLabel={'Selecione uma data'}
                      inputVariant="outlined"
                      invalidDateMessage="Data inválida"
                      value={birthday}
                      onChange={(date) => this.setState({ birthday: date })}
                      placeholder="01/01/1990"
                      label="Data de nascimento"
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button disabled={loading} color="primary" onClick={() => this.setState({ open: false, id: '', name: '', email: '', cpf: '', password: '', token: '', telephone: '', birthday: null })}>
                Cancelar
              </Button>
              <Box style={{ display: 'flex', position: 'relative' }}>
                <Button disabled={loading} type="submit" color="primary" variant="contained">
                  Salvar
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: -12,
                      marginLeft: -12,
                    }}
                  />
                )}
              </Box>
            </DialogActions>
          </form>
        </Dialog>
      </>
    )
  }
}

export default withSnackbar(EditUser)
