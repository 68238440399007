import { Button, Text } from '@chakra-ui/react'

type MonthButtonProps = {
  title: string
  selected: boolean
  isActive: boolean
  onClick: () => void
}

const MonthButton = ({ title, selected, isActive, ...rest }: MonthButtonProps) => (
  <Button
    isDisabled={!isActive}
    px={8}
    py={8}
    colorScheme={selected ? 'blue' : 'blackAlpha'}
    variant="ghost"
    {...rest}
  >
    <Text fontSize={selected ? 'xl' : 'md'} fontWeight={selected ? 'semibold' : 'medium'}>
      {title}
    </Text>
  </Button>
)

export { MonthButton }
