import { ArrowBackIcon } from '@chakra-ui/icons'
import { Box, Button, ButtonGroup, Center, GridItem } from '@chakra-ui/react'
import React, { useContext } from 'react'
import ChangePaymentMethodContext from '../../../../contexts/ChangePaymentMethod'
import FirstCreditCardElements from './FirstCreditCard'
import SecondCreditCardElements from './SecondCreditCard'

function TwoCreditCardPayment() {
  const { setSelectedPaymentMethod, selectedCard, setSelectedCard } = useContext(
    ChangePaymentMethodContext,
  ) as unknown as CreatePaymentMethod

  return (
    <Box pt={6}>
      <Center mb="6">
        <ButtonGroup isAttached>
          <Button
            mr="-px"
            size="sm"
            colorScheme="blue"
            variant={selectedCard === 'main' ? 'solid' : 'outline'}
            onClick={() => setSelectedCard('main')}
          >
            Primeiro cartão
          </Button>
          <Button
            mr="-px"
            size="sm"
            colorScheme="blue"
            variant={selectedCard === 'second' ? 'solid' : 'outline'}
            onClick={() => setSelectedCard('second')}
          >
            Segundo cartão
          </Button>
        </ButtonGroup>
      </Center>

      {selectedCard === 'main' && <FirstCreditCardElements />}

      {selectedCard === 'second' && <SecondCreditCardElements />}

      {/* --------------------------------- VOLTAR --------------------------------- */}
      <GridItem colSpan={12}>
        <Button
          mt={6}
          size="sm"
          leftIcon={<ArrowBackIcon />}
          colorScheme="blue"
          variant="solid"
          onClick={() => setSelectedPaymentMethod(0)}
        >
          Voltar
        </Button>
      </GridItem>
    </Box>
  )
}

export default TwoCreditCardPayment
