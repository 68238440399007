import { useState } from 'react'
import {
  Avatar,
  ButtonBase,
  ClickAwayListener,
  Grid,
  Hidden,
  Paper,
  Popper,
  Typography,
  Zoom,
} from '@material-ui/core'
import { ArrowDropDown, ExitToAppRounded } from '@material-ui/icons'

export default function UserActions(props: any) {
  const [anchorOrigin, setAnchorOrigin] = useState<any>(null)
  const [popper, setPopper] = useState(false)
  const { user, logOut } = props
  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={() => setPopper(false)}
    >
      <Grid
        style={{ display: 'flex', background: '#0462bf', cursor: 'pointer' }}
        className="user"
        onClick={event => {
          setAnchorOrigin(event.currentTarget)
          setPopper(!popper)
        }}
      >
        <Avatar alt={user.name} />
        <Hidden xsDown>
          <Typography className="name" style={{ color: '#fff' }}>
            {user.name}
          </Typography>
        </Hidden>
        <ArrowDropDown
          style={{
            transform: popper ? 'rotate(180deg)' : '',
            transition: '.2s',
            color: '#fff',
            marginLeft: 'auto',
          }}
        />
        <Popper
          style={{ top: 8, right: 4, width: 200, zIndex: 3 }}
          open={popper}
          placement={'bottom-end'}
          anchorEl={anchorOrigin}
        >
          <Zoom in={popper}>
            <Paper>
              <ButtonBase
                onClick={logOut}
                style={{
                  display: 'flex',
                  width: '100%',
                  padding: 12,
                  justifyContent: 'space-between',
                }}
              >
                <Typography className="name">Sair</Typography>
                <ExitToAppRounded />
              </ButtonBase>
            </Paper>
          </Zoom>
        </Popper>
      </Grid>
    </ClickAwayListener>
  )
}
