import React from 'react'

class Calculadora extends React.Component {
    render() {
        return (
            <React.Fragment>
              <iframe  title="calculadora" style={{ width: "100vw", height: '100vh', border: 'none' }} className="iframeCalculadora" src="https://calculadoraucred.herokuapp.com/"/>
            </React.Fragment>
        )
    }
}

export default Calculadora