import { Text, Tab as ChakraTab, TabProps } from '@chakra-ui/react'

interface CustomTabProps extends TabProps {
  title: string
  onClick?: () => void
}

export function Tab({ title }: CustomTabProps) {
  return (
    <ChakraTab
      _hover={{ bg: 'blue.50' }}
      _selected={{
        borderBottomColor: 'blue.500',
        borderBottomWidth: 2,
        color: 'blue.500',
      }}
    >
      <Text>{title}</Text>
    </ChakraTab>
  )
}
