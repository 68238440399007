import {
  AspectRatio,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { Card } from '../../components/Card'

interface PrivacyPolicyProps {
  isOpen: boolean
  onClose: () => void
}

export function PrivacyPolicy({ isOpen, onClose }: PrivacyPolicyProps) {
  return (
    <Modal size="6xl" isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text color="gray.600" fontWeight="normal">
            Política de privacidade
          </Text>
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <Card p={4}>
            <AspectRatio ratio={16 / 9}>
              <iframe
                frameBorder="0"
                src="https://drive.google.com/file/d/146wTBWzphgyDSyTJvRp-53qGTzzgU7T2/preview"
                allowFullScreen
              />
            </AspectRatio>
          </Card>
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose}>Voltar</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
