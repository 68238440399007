import React from 'react'
import CardPlan from '../../components/cardPlan'

const Planos = () => (
  <div className="planos">
    <div className="planos__container">
      <CardPlan />
      <CardPlan />
      <CardPlan />
      <CardPlan />
    </div>
  </div>
)

export default Planos
