import { Container, Grid } from '@material-ui/core'
import { withSnackbar } from 'notistack'
import React from 'react'
import Modal from 'react-responsive-modal'
import { Link } from 'react-router-dom'
import ApiInstance from '../../services/ApiInstance'
import Button from '../../components/button'
import Input from '../../components/input'
import Header from '../../layout/Header'

class NewServiceUcredPlan extends React.Component {
  state = {
    openModalModalConfirmation: false,
    items: [],
    imobiliaria: 0,
    coberturaImobiliaria: '0',
    ganhoImobiliariaMax: 0,
    valorSimulado: '1000',
    valorReceber: 0,
    prodId: 0,
    name: '',
    ganhoImobiliaria: 0,
    coberturaUcred: 0,
    overValue: 0,
    taxaUcred: 5,
    taxaLocatario: '5,00',
    id: null,
  }

  async componentDidMount() {
    if (this.props.location.state) {
      let { name, total_fee, imob_gain, id } = this.props.location.state
      total_fee = String(total_fee).replace('.', ',').replace('%', '').replace(' ', '')
      total_fee = `${total_fee},00`
      await this.setState({
        name: name,
        taxaLocatario: total_fee,
        ganhoImobiliaria: imob_gain,
        id,
      })
    }

    let { taxaLocatario, ganhoImobiliaria } = this.state
    await this.setValues(taxaLocatario)
    this.handleGanhoImobiliaria(ganhoImobiliaria)
  }

  closeModalConfirmation = () => {
    this.setState({ openModalModalConfirmation: false })
  }

  handleValorSimulado = (event) => {
    let { taxaLocatario, ganhoImobiliaria } = this.state

    this.setValues(taxaLocatario)
    this.handleGanhoImobiliaria(ganhoImobiliaria)
    this.setState({ valorSimulado: event.currentTarget.value })
  }

  handleChangeNome = (event) => {
    this.setState({ name: event.currentTarget.value })
  }

  handleTaxaLocatario = (event) => {
    let { value } = event.currentTarget
    this.setValues(value)
  }

  setValues = async (value) => {
    switch (value) {
      case '5,00':
        await this.setState({ ganhoImobiliariaMax: 0, taxaLocatario: value })
        break
      case '6,00':
        await this.setState({ ganhoImobiliariaMax: 0, taxaLocatario: value })
        break
      case '7,00':
        await this.setState({ ganhoImobiliariaMax: 15, taxaLocatario: value })
        break
      case '8,00':
        await this.setState({ ganhoImobiliariaMax: 25, taxaLocatario: value })
        break
      case '9,00':
        await this.setState({ ganhoImobiliariaMax: 35, taxaLocatario: value })
        break
      case '10,00':
        await this.setState({ ganhoImobiliariaMax: 40, taxaLocatario: value })
        break
      case '11,00':
        await this.setState({ ganhoImobiliariaMax: 45, taxaLocatario: value })
        break
      case '12,00':
        await this.setState({ ganhoImobiliariaMax: 50, taxaLocatario: value })
        break
      default:
        await this.setState({ ganhoImobiliariaMax: 0, taxaLocatario: value })
        break
    }

    await this.handleGanhoImobiliaria(0)
    await this.setValueSelect()
  }

  handleGanhoImobiliaria = async (value) => {
    let comissaoImobiliaria = value
    if (value.currentTarget) {
      let { value: aux } = comissaoImobiliaria.currentTarget
      comissaoImobiliaria = aux
    }

    let { taxaLocatario } = this.state
    taxaLocatario = Number(taxaLocatario.replace(',', '.'))

    let overValue = Number(comissaoImobiliaria) * taxaLocatario
    overValue = Number(overValue / 100).toFixed(3)

    let taxaUcred = Number(taxaLocatario - overValue)
    let coberturaUcred = 0
    let porcentagemTaxaUcred = taxaUcred.toFixed(3) / 100

    if (porcentagemTaxaUcred < 0.05) {
      coberturaUcred = 0
    } else if (porcentagemTaxaUcred < 0.06) {
      coberturaUcred = 6
    } else if (porcentagemTaxaUcred < 0.07) {
      coberturaUcred = 9
    } else if (porcentagemTaxaUcred < 0.08) {
      coberturaUcred = 10
    } else if (porcentagemTaxaUcred < 0.09) {
      coberturaUcred = 12
    } else if (porcentagemTaxaUcred < 0.1) {
      coberturaUcred = 14
    } else if (porcentagemTaxaUcred < 0.11) {
      coberturaUcred = 16
    } else {
      coberturaUcred = 20
    }

    await this.setState({
      ganhoImobiliaria: comissaoImobiliaria,
      overValue,
      taxaUcred,
      coberturaUcred,
    })
  }

  confirmation = () => {
    try {
      const { name } = this.state
      if (!name) throw new Error('Informe o nome do serviço')
      this.setState({ openModalModalConfirmation: true })
    } catch (err) {
      const snackbarKey = this.props.enqueueSnackbar(err.message, { variant: 'error',
onClick: () => {
              this.props.closeSnackbar(snackbarKey)
            } })
    }
  }

  save = async () => {
    try {
      let { name, id, taxaUcred, overValue, coberturaUcred, taxaLocatario, ganhoImobiliaria } = this.state
      const { history } = this.props
      let data = {}

      if (id) {
        data = {
          id,
          name,
          administration_fee: taxaUcred,
          over_value: Number(overValue).toFixed(2),
          cover_value: coberturaUcred,
          total_fee: taxaLocatario.replace(',', '.'),
          imob_gain: ganhoImobiliaria,
        }
        await ApiInstance.put('serviceUcredPlan', { data })

        const snackbarKey = this.props.enqueueSnackbar('Atualizado com sucesso.', { variant: 'success',
onClick: () => {
              this.props.closeSnackbar(snackbarKey)
            } })
      } else {
        data = {
          name,
          administration_fee: taxaUcred,
          over_value: Number(overValue).toFixed(2),
          cover_value: coberturaUcred,
          total_fee: taxaLocatario.replace(',', '.'),
          imob_gain: ganhoImobiliaria,
        }
        await ApiInstance.post('serviceUcredPlan', { data })

        const snackbarKey = this.props.enqueueSnackbar('Salvo com sucesso.', { variant: 'success',
onClick: () => {
              this.props.closeSnackbar(snackbarKey)
            } })
      }

      history.push('servicos')
    } catch (error) {
      const snackbarKey = this.props.enqueueSnackbar(error.message, { variant: 'error',
onClick: () => {
              this.props.closeSnackbar(snackbarKey)
            } })
    }
  }

  setValueSelect = async () => {
    const { ganhoImobiliariaMax } = this.state
    const aux = []
    let i = 0
    for (i = 0; i <= ganhoImobiliariaMax; i++) {
      if (i % 5 == 0) aux.push(i)
    }
    switch (ganhoImobiliariaMax) {
      case 15:
        aux[aux.length - 1] = 14
        break
      case 35:
        aux[aux.length - 1] = 33
        break
      default:
        break
    }
    await this.setState({ items: aux })
  }

  render() {
    const { taxaLocatario, valorSimulado, ganhoImobiliaria, name, overValue, coberturaUcred, items } = this.state

    return (
      <React.Fragment>
        <Header />
        <Container style={{ marginTop: 80 }}>
          <Grid spacing={3} container>
            <Grid item xs={12}>
              <div className="verticalLine"></div>
              <div className="newServiceUcredPlan">
                <div className="voltar">
                  <Link to="/servicos">
                    <i className="lni-arrow-left-circle"></i> Voltar
                  </Link>
                </div>

                <div className="newServiceUcredPlan__container-header">
                  <h1>Configurações do serviço</h1>
                </div>

                <div className="newServiceUcredPlan__container">
                  <div className="newServiceUcredPlan__container-leftData">
                    <div className="newServiceUcredPlan__container-leftData-inputs">
                      <span className="spanStyle">Digite um valor para ser simulado:</span>
                      <Input style={{ width: '20px' }} value={valorSimulado} type="currency" iconType="money" onChange={this.handleValorSimulado} />
                      <span className="spanStyle">Digite um nome para o servico:</span>
                      <Input value={name} type="text" iconType="text" onChange={this.handleChangeNome} />
                    </div>

                    <div className="newServiceUcredPlan__container-leftData-selects">
                      <div className="newServiceUcredPlan__container-leftData-selects-header">
                        <p> Configure o serviço conforme sua necessidade:</p>
                      </div>

                      <div className="newServiceUcredPlan__container-leftData-selects-container">
                        <div className="item">
                          {/* No banco se chama administration_fee */}
                          <p>Taxa locatário:</p>
                          <select className="selectStyle" onChange={this.handleTaxaLocatario} value={taxaLocatario}>
                            <option value="5,00">5,00 %</option>
                            <option value="6,00">6,00 %</option>
                            <option value="7,00">7,00 %</option>
                            <option value="8,00">8,00 %</option>
                            <option value="9,00">9,00 %</option>
                            <option value="10,00">10,00 %</option>
                            <option value="11,00">11,00 %</option>
                            <option value="12,00">12,00 %</option>
                          </select>
                        </div>

                        <div className="item">
                          <p>Comissão imobiliária:</p>
                          <select className="selectStyle" onChange={this.handleGanhoImobiliaria} value={ganhoImobiliaria}>
                            {items.map((item) => (
                              <option key={item} value={item}>
                                {item} %
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="newServiceUcredPlan__container-rightData">
                    <div className="newServiceUcredPlan__container-rightData-data">
                      <span className="title">Cobertura Ucred:</span>
                      <span className="content">
                        R$ {String((Number(valorSimulado.split('.').join('').replace(',', '.')) * coberturaUcred).toFixed(2)).replace('.', ',')}
                      </span>
                      <span className="content">{coberturaUcred} Vezes</span>
                    </div>

                    <div className="newServiceUcredPlan__container-rightData-data">
                      <span className="title">Você recebe:</span>
                      <span className="content">
                        R$ {((overValue / 100) * Number(valorSimulado.split('.').join('').replace(',', '.')) * 12 * 0.95).toFixed(2)}
                      </span>
                    </div>

                    <div className="newServiceUcredPlan__container-rightData-data">
                      <span className="title">Valor pago locatário:</span>
                      <span className="content">
                        R${' '}
                        {((Number(taxaLocatario.replace(',', '.')) / 100) * Number(valorSimulado.split('.').join('').replace(',', '.')) * 12).toFixed(
                          2
                        )}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="saveButton">
                  <Button description="Salvar" onClick={this.confirmation} />
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
        <Modal open={this.state.openModalModalConfirmation} onClose={this.closeModalConfirmation} classNames={{ modal: 'confirmationModal' }}>
          <div className="modal">
            <h1>Confirme seus dados:</h1>
            <div className="modal-container">
              <div className="modal-container-data">
                <div className="modal-container-data-row">
                  <span className="modal-container-data-row-title">Nome do serviço:</span>
                  <span className="modal-container-data-row-content">{name}</span>

                  <span className="modal-container-data-row-title">Taxa Locatário:</span>
                  <span className="modal-container-data-row-content">{taxaLocatario}%</span>

                  <span className="modal-container-data-row-title">Cobertura Ucred:</span>
                  <span className="modal-container-data-row-content">{coberturaUcred} Vezes</span>

                  <span className="modal-container-data-row-title">Comissão Imobiliária:</span>
                  <span className="modal-container-data-row-content">{ganhoImobiliaria}%</span>
                </div>
              </div>
              <div className="modal-footer">
                <Button
                  description="Voltar"
                  onClick={() => {
                    this.setState({ openModalModalConfirmation: false })
                  }}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Button description="Confirmar" onClick={this.save} />
              </div>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    )
  }
}

export default withSnackbar(NewServiceUcredPlan)
