import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core'
import {
  AttachMoney,
  CancelRounded,
  Close,
  DescriptionRounded,
  GetApp,
  MoneyOffRounded,
  Person,
  PersonOutline,
} from '@material-ui/icons'
import React from 'react'
import ApiInstance from '../services/ApiInstance'
import U from '../static/svg/u'
import { maskMoney } from '../util/money'

export default class ModalSallesDetail extends React.Component {
  constructor() {
    super()
    this.state = {
      valorTotal: null,
      valorDescontado: null,
      parcelas: null,
      primeiroPagamento: null,
      ultimoPagamento: null,
      garantiaUcred: null,
      valorTaxaDescontado: null,
      purchase: {
        global: {},
        main_purchase: {},
        corresponsible_purchase: {},
        ucred: {},
      },

      document_key: '',

      open: false,
      switchState: 0,
    }
  }

  async handleOpen() {
    if (this.props.purchase[0] && this.props.purchase[0].purchase) {
      const details = await ApiInstance.get(
        `transactionDetail/${this.props.purchase[0].purchase.id}`,
      )

      this.setState({ purchase: details })
    }
  }

  render() {
    const { purchase, switchState, open } = this.state
    const { global, main_purchase, corresponsible_purchase: corresp_purchase, ucred } = purchase

    const formatPaymentMethod = method => {
      switch (method) {
        case 'credit_card':
          method = 'Cartão de crédito'
          break
        case 'bank_slip_1':
          method = 'Boleto'
          break
        case 'cash':
          method = 'Dinheiro'
          break
        case 'hybrid':
          method = 'Boleto + Cartão'
          break
        default:
          method = 'Não definido'
          break
      }
      return method
    }

    const formatContractStatus = status => {
      switch (status) {
        case 'running':
          status = 'Aguardando assinatura'
          break
        case 'signed':
          status = 'Assinado'
          break
        case 'canceled':
          status = 'Cancelado'
          break
        default:
          status = 'Error'
          break
      }
      return status
    }

    const handleDownloadContract = () => {
      ApiInstance.get(`downloadContract/${this.state.document_key}`).then(response =>
        window.open(response.data.signed_file_url, '_blank'),
      )
    }

    return (
      <Dialog disableScrollLock open={open} onClose={() => this.setState({ open: false })}>
        <DialogTitle
          disableTypography
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6">Detalhe da transação</Typography>
          <IconButton
            onClick={() => this.setState({ open: false })}
            size="small"
            style={{ marginLeft: 'auto' }}
          >
            <Close></Close>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {/* -------------------------------------------------------------------------- */}
          {/*               purchase_status_id: 2 = 'Aguardando pagamento'               */}
          {/*                     purchase_status_id: 7 = 'Parcial'                      */}
          {/* -------------------------------------------------------------------------- */}

          {/* ---------------------------------- VENDA --------------------------------- */}

          {switchState === 0 && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary">
                  Valor avalizado
                </Typography>
                <Typography>{maskMoney(global.endorsed_value)}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary">
                  Comissão imobiliária (taxa de adesão)
                </Typography>
                <Typography>
                  {main_purchase.payment_method !== 'hybrid'
                    ? maskMoney(global.adhesion_with_discount)
                    : maskMoney(global.adhesion_value)}
                </Typography>
              </Grid>
              {!!global.adhesion_parcel &&
                main_purchase.purchase_status_id !== 2 &&
                main_purchase.purchase_status_id !== 7 && (
                  <Grid item xs={12}>
                    <Typography variant="body2" color="textSecondary">
                      Parcelas (taxa de adesão)
                    </Typography>
                    <Typography>
                      {global.adhesion_parcel}x {maskMoney(global.adhesion_parcel_value)}
                    </Typography>
                  </Grid>
                )}
              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary">
                  Valor total
                </Typography>
                <Typography>
                  {maskMoney(
                    global.endorsed_value +
                      (main_purchase.payment_method !== 'hybrid'
                        ? global.adhesion_with_discount
                        : global.adhesion_value),
                  )}
                </Typography>
              </Grid>

              {/* -------------------------------------------------------------------------- */}
              {/*          PARA AUTENTICAR DOCUMENTOS, A VENDA DEVE ESTAR CONCLUÍDA          */}
              {/* -------------------------------------------------------------------------- */}
              {main_purchase.purchase_status_id === 3 && (
                <Grid spacing={1} container item xs={12} md={6}>
                  <Box
                    onClick={() => {
                      let id = this.props.parent.state.selectedPurchase[0].purchase.id
                      this.props.parent.props.history.push(`/documentos/${id}`)
                    }}
                    m={1}
                    style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                  >
                    <Fab size="small" color="primary">
                      <DescriptionRounded />
                    </Fab>
                    <Typography
                      component="span"
                      variant="subtitle1"
                      style={{ marginLeft: 16, cursor: 'pointer' }}
                    >
                      Autenticar documentos
                    </Typography>
                  </Box>
                </Grid>
              )}

              {/* -------------------------------------------------------------------------- */}
              {/*            PARA ACIONAR SINISTROS, A VENDA DEVE ESTAR CONCLUIDA            */}
              {/* -------------------------------------------------------------------------- */}
              {main_purchase.purchase_status_id === 3 && (
                <Grid spacing={1} container item xs={12} md={6}>
                  <Box
                    onClick={() =>
                      this.props.parent.setState({
                        invoices: { ...this.props.parent.state.invoices, open: true },
                      })
                    }
                    m={1}
                    style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                  >
                    <Fab size="small" color="primary">
                      <MoneyOffRounded />
                    </Fab>
                    <Typography
                      component="span"
                      variant="subtitle1"
                      style={{ marginLeft: 16, cursor: 'pointer' }}
                    >
                      Acionar sinistro
                    </Typography>
                  </Box>
                </Grid>
              )}

              {/* -------------------------------------------------------------------------- */}
              {/*           PARA CANCELAR VENDAS, O STATUS DEVE SER DIFERENTE DE 4           */}
              {/* -------------------------------------------------------------------------- */}
              {main_purchase.purchase_status_id !== 4 && (
                <Grid spacing={1} container item xs={12} md={6}>
                  <Box
                    onClick={() => {
                      this.props.parent.setState({
                        cancel: { ...this.props.parent.state.cancel, open: true },
                      })
                      this.props.parent.fetchData()
                    }}
                    m={1}
                    style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                  >
                    <Fab size="small" color="primary">
                      <CancelRounded />
                    </Fab>
                    <Typography
                      component="span"
                      variant="subtitle1"
                      style={{ marginLeft: 16, cursor: 'pointer' }}
                    >
                      Cancelar transação
                    </Typography>
                  </Box>
                </Grid>
              )}
            </Grid>
          )}

          {/* -------------------------------- LOCATÁRIO ------------------------------- */}

          {switchState === 1 && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary">
                  Nome
                </Typography>
                <Typography>{main_purchase.name}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body2" color="textSecondary">
                  Email
                </Typography>
                <Typography>{main_purchase.email}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body2" color="textSecondary">
                  Telefone
                </Typography>
                <Typography>{main_purchase.phone}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary">
                  Comissão com o locatário
                </Typography>
                <Typography>
                  {main_purchase.parcel}x {maskMoney(main_purchase.comission_main_purchase)}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body2" color="textSecondary">
                  Método de pagamento
                </Typography>
                <Typography>{formatPaymentMethod(main_purchase.payment_method)}</Typography>
              </Grid>
              {main_purchase.contract_status === 'signed' && !corresp_purchase.contract_status && (
                <Grid item xs={2} sm={1}>
                  <Fab onClick={handleDownloadContract} color="primary" size="small">
                    <GetApp />
                  </Fab>
                </Grid>
              )}

              {main_purchase.contract_status === 'signed' &&
                corresp_purchase.contract_status === 'signed' && (
                  <Grid item xs={3} sm={2} md={1}>
                    <Fab onClick={handleDownloadContract} color="primary" size="small">
                      <GetApp />
                    </Fab>
                  </Grid>
                )}
              <Grid item xs={'auto'}>
                <Typography variant="body2" color="textSecondary">
                  Status do contrato
                </Typography>
                <Typography>{formatContractStatus(main_purchase.contract_status)}</Typography>
              </Grid>
            </Grid>
          )}

          {/* ----------------------------- CORRESPONSÁVEL ----------------------------- */}

          {switchState === 2 && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary">
                  Nome
                </Typography>
                <Typography>{corresp_purchase.name}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body2" color="textSecondary">
                  Email
                </Typography>
                <Typography>{corresp_purchase.email}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body2" color="textSecondary">
                  Telefone
                </Typography>
                <Typography>{corresp_purchase.phone}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary">
                  Comissão com o corresponsável
                </Typography>
                <Typography>
                  {corresp_purchase.parcel}x{' '}
                  {maskMoney(corresp_purchase.comission_corresponsible_purchase)}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body2" color="textSecondary">
                  Método de pagamento
                </Typography>
                <Typography>{formatPaymentMethod(corresp_purchase.payment_method)}</Typography>
              </Grid>
              {corresp_purchase.contract_status && (
                <>
                  {corresp_purchase.contract_status === 'signed' &&
                    main_purchase.contract_status === 'signed' && (
                      <Grid item xs={3} sm={2} md={1}>
                        <Fab onClick={handleDownloadContract} color="primary" size="small">
                          <GetApp />
                        </Fab>
                      </Grid>
                    )}

                  <Grid item xs={'auto'}>
                    <Typography variant="body2" color="textSecondary">
                      Status do contrato
                    </Typography>
                    <Typography>
                      {formatContractStatus(corresp_purchase.contract_status)}
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          )}

          {/* ---------------------------------- UCRED --------------------------------- */}

          {switchState === 3 && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary">
                  Valor descontado pelo adquirente
                </Typography>
                <Typography>
                  {maskMoney(
                    main_purchase.acquirer_value_main +
                      (corresp_purchase.acquirer_value_corresp || 0),
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary">
                  Valor da transação
                </Typography>
                <Typography>{maskMoney(ucred.transaction_value)}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary">
                  Cobertura Ucred
                </Typography>
                <Typography>{ucred.cover_parcel}x</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary">
                  Valor de cobertura Ucred
                </Typography>
                <Typography>{maskMoney(ucred.cover_value)}</Typography>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <BottomNavigation
          value={switchState}
          onChange={(event, newValue) => {
            this.setState({ switchState: newValue })
          }}
          showLabels
        >
          <BottomNavigationAction label="Venda" icon={<AttachMoney />} />
          <BottomNavigationAction label="Locatário" icon={<Person />} />
          {corresp_purchase.name && (
            <BottomNavigationAction label="Corresponsável" icon={<PersonOutline />} />
          )}
          <BottomNavigationAction
            label="Cobertura"
            icon={
              <U
                color={switchState === 3 ? '#009fff' : '#585858'}
                style={{ width: 24, height: 24 }}
              ></U>
            }
          />
        </BottomNavigation>
      </Dialog>
    )
  }
}
