import DateFnsUtils from '@date-io/moment'
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, TextField, Typography } from '@material-ui/core'
import { Close, Visibility, VisibilityOff } from '@material-ui/icons'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import moment from 'moment'
import { withSnackbar } from 'notistack'
import React from 'react'
import MaskedInput from 'react-input-mask'
import ApiInstance from '../../services/ApiInstance'

class NewUser extends React.Component {
  constructor() {
    super()
    this.state = {
      open: false,
      loading: false,
      name: '',
      email: '',
      cpf: '',
      password: '',
      token: '',
      telephone: '',
      birthday: null,
      showPassword: false,
    }
  }
  render() {
    const { loading, name, email, cpf, password, telephone, birthday } = this.state

    const saveUser = async (event) => {
      event.preventDefault()
      await this.setState({ loading: true })
      try {
        const { name, email, cpf, password, birthday, telephone } = this.state
        if (!name) throw new Error('Informe o nome do usuário.')
        if (!email) throw new Error('Informe o email do usuário.')
        if (!cpf) throw new Error('Informe o cpf do usuário.')
        if (!password) throw new Error('Informe a senha do usuário.')
        if (!birthday) throw new Error('Informe a data de nascimento do usuário.')
        if (!telephone) throw new Error('Informe o celular do usuário.')

        await ApiInstance.post('usuario', {
          name,
          email,
          cpf,
          telephone,
          birthday: moment(birthday).format('YYYY-MM-DD'),
          password: password !== '1@-' ? password : null,
          permission: 2,
        })

        this.setState({
          open: false,
          loading: false,
          name: '',
          email: '',
          cpf: '',
          password: '',
          token: '',
          telephone: '',
          birthday: null,
        })
        this.props.parent.fetchData()
        const snackbarKey = this.props.enqueueSnackbar('Usuário criado com sucesso.', {
          variant: 'success',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        })
      } catch (error) {
        const snackbarKey = this.props.enqueueSnackbar('Ocorreu um erro com sua solicitação, entre em contato com nosso suporte.', {
          variant: 'error',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        })
      }

      await this.setState({ loading: false })
    }

    const handleChange = async (event) => {
      const { name, value } = event.target

      if (RegExp(/[0-9]/gi).test(value) && name === 'name') return

      this.setState({ [name]: value })

      if (name === 'cpf' && value.length === 14) {
        this.setState({ disableInput: true })
        await ApiInstance.post(`basic-data`, { document: value, type: 'cpf' })
          .then((response) => {
            this.setState({
              name: response.response.Result[0].BasicData.Name,
              birthday: response.response.Result[0].BasicData.BirthDate.split('T')[0],
            })
            this.setState({ disableInput: false })
          })
          .catch(() => {
            this.setState({ disableInput: false })
          })
      }
    }

    return (
      <>
        <Dialog disableScrollLock open={this.state.open} onClose={() => this.setState({ open: false })}>
          <DialogTitle
            disableTypography
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6">Novo usuário</Typography>
            <IconButton onClick={() => this.setState({ open: false })} size="small" style={{ marginLeft: 'auto' }}>
              <Close></Close>
            </IconButton>
          </DialogTitle>
          <form onSubmit={saveUser} autoComplete="new-off">
            <DialogContent dividers>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <MaskedInput
                    maskChar=""
                    mask="999.999.999-99"
                    disabled={this.state.disableInput}
                    fullWidth
                    variant="outlined"
                    placeholder="123.456.789-00"
                    label="CPF do usuário"
                    name="cpf"
                    value={cpf}
                    onChange={handleChange}
                  >
                    {(inputProps) => <TextField {...inputProps} />}
                  </MaskedInput>
                </Grid>

                <Grid item xs={12}>
                  <TextField fullWidth variant="outlined" type="text" placeholder="Nome" name="name" value={name} onChange={handleChange} />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    variant="outlined"
                    type="email"
                    placeholder="jose.ucred@gmail.com"
                    label="E-mail do usuário"
                    name="email"
                    value={email}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    variant="outlined"
                    type={this.state.showPassword ? 'text' : 'password'}
                    placeholder="senha"
                    label="Senha do usuário"
                    name="password"
                    value={password}
                    onChange={handleChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => this.setState({ showPassword: !this.state.showPassword })}>{this.state.showPassword ? <Visibility /> : <VisibilityOff />}</IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <MaskedInput
                    maskChar=""
                    mask="(99) 9 9999-9999"
                    name="telephone"
                    fullWidth
                    variant="outlined"
                    disabled={this.state.disableInput}
                    placeholder="(32) 9 1234-5678"
                    label="Celular"
                    value={telephone}
                    onChange={handleChange}
                  >
                    {(inputProps) => <TextField {...inputProps} />}
                  </MaskedInput>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      fullWidth
                      disableFuture
                      openTo="year"
                      name="birthday"
                      format="DD/MM/YYYY"
                      inputVariant="outlined"
                      invalidDateMessage="Data inválida"
                      disabled={this.state.disableInput}
                      value={birthday}
                      onChange={(date) => this.setState({ birthday: date })}
                      placeholder="01/01/1990"
                      label="Data de nascimento"
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button disabled={loading} color="primary" onClick={() => this.setState({ open: false, id: '', name: '', email: '', cpf: '', password: '', token: '', telephone: '', birthday: null })}>
                Cancelar
              </Button>
              <Box style={{ display: 'flex', position: 'relative' }}>
                <Button disabled={loading} type="submit" color="primary" variant="contained">
                  Salvar
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: -12,
                      marginLeft: -12,
                    }}
                  />
                )}
              </Box>
            </DialogActions>
          </form>
        </Dialog>
      </>
    )
  }
}

export default withSnackbar(NewUser)
