export const removeMask = (value = ''): string => {
  value = value.toString()
  return value.split('.').join('').replace(',', '.').split('-').join('').split('_').join('')
}

export const maskMoney = (value = 0): string => {
  value = Number(value)
  return Number(value.toFixed(2)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
}

export const moneyToNumber = (value: string): number => {
  return Number(
    String(value)
      .replace(',', '.')
      .replace(/([^0-9.])/g, ''),
  )
}
