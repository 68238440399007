import {
  Avatar,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Breadcrumbs,
  Button,
  Chip,
  Collapse,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Fab,
  Grid,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
  Popper,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  Zoom,
} from '@material-ui/core'
import {
  AttachMoneyRounded,
  CancelRounded,
  Close,
  CreditCard,
  DescriptionRounded,
  EditRounded,
  GetApp,
  Home,
  LocalMallRounded,
  MoneyOffRounded,
  NoteAddRounded,
  ReceiptRounded,
  Settings,
  Print,
  MoneyOff,
} from '@material-ui/icons'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'
import ApiInstance from '../../services/ApiInstance'
import EditClientModal from '../../components/editClientModal'
import EditCreditCardModal from '../../components/editCreditCardModal'
import ModalCancelTransaction from '../../components/modalCancelTransaction'
import ModalCancelTransaction30Days from '../../components/modalCancelTransaction30Days'
import ModalDocs from '../../components/modalDocs'
import ModalSallesDetail from '../../components/modalSallesDetail'
import ModalSallesDetailUcred from '../../components/modalSallesDetailUcred'
import ModalSinistro from '../../components/modalSinistro'
import TransactionResume from '../../components/transactionResume'
import Footer from '../../layout/Footer'
import Header from '../../layout/Header'
import Pattern from '../../static/svg/pattern.svg'
import Styles from '../../styles/defaultStyles'
import InvoicesTable from './InvoicesTable'
import ProductsTable from './ProductsTable'
import TransactionsTable from './TransactionsTable'
import ApiInstanceV2 from '../../services/ApiInstanceV2'

export default class ClientDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tab: (props.location.state && props.location.state.tab) || 0,
      loading: true,
      settings: { open: false, anchorOrigin: null },
      client_modal: { open: false },
      client: { name: '', cpf: '', email: '', telephone_1: '', telephone_2: '' },
      purchases: [],
      purchase: { service: {}, parcel: {} },
      company: {},
      invoices: { open: false, rows: [] },
      products: [],

      transactionsTable: { rows: [] },
      detail: false,
      cancel: { open: false, confirmationOpen: false },
      cancelTransaction30Days: { cancelTransaction30Days: false, confirmationOpen: false },
      sendReceipt: { open: false },
      selectedPurchase: [],
      modalDownloadDocuments: { open: false, contracts: [] },

      //SOUZA GOMES
      openModalDetailSalles: false,
    }
  }

  async componentDidMount() {
    await this.fetchData()
    if (this.TransactionsTable) this.TransactionsTable.transactionsTable()
  }

  async handleOpenModalDocuments(purchase_id) {
    const { modalDownloadDocuments } = this.state
    const document = await ApiInstance.get(`contractSigner/${purchase_id}?signed=true`)

    document.contracts.map(async (contract) => {
      const url = await ApiInstance.get(`downloadContract/${contract.document_key}`)
      contract.download_signed = url.data.signed_file_url
    })

    this.setState({ modalDownloadDocuments: { ...modalDownloadDocuments, open: true, contracts: document.contracts } })
  }

  cancel(id) {
    let { purchases } = this.state
    let newPurchase = purchases.map((_p) => {
      if (_p.id === id) _p.status = 4
      return _p
    })
    this.setState({ purchases: newPurchase })
  }

  async openDetail(purchase) {
    const { company } = this.state
    if (company.plan_id === 4) return false
    await this.setState({ selectedPurchase: [purchase] })

    if (company.plan_id === 1) {
      this.SalesDetailSouzaGomes.setState({ selectedPurchase: [purchase] })
      await this.SalesDetailSouzaGomes.fetchData()
      return this.setState({ openModalDetailSalles: true })
    } else {
      if (!purchase.contract_status) {
        this.SalesDetailSouzaGomes.setState({ selectedPurchase: [purchase] })
        await this.SalesDetailSouzaGomes.fetchData()
        return this.setState({ openModalDetailSalles: true })
      } else {
        this.SalesDetail.setState({ open: true, switchState: 0, document_key: purchase.purchase.document_key })
      }
    }

    this.SalesDetail.handleOpen()
  }

  async fetchData() {
    const { id } = this.props.match.params

    await ApiInstance.get(`cliente/${id}`).then((response) => {
      this.setState({
        client: response.data.clientData,
        purchases: response.data.purchaseFormated,
        company: response.data.companyData,
        invoices: { ...this.state.invoices, rows: response.data.invoices },
        products: response.data.products,
        total: response.data.totalValue,
        loading: false,
      })
    })

    if (this.TransactionsTable) this.TransactionsTable.transactionsTable()
  }

  render() {
    const handleChangeTab = (event, newValue) => {
      this.setState({ tab: newValue })
    }

    const {
      tab,
      settings,
      client,
      loading,
      products,
      openModalDetailSalles,
      selectedPurchase,
      company,
      transactionsTable,
      modalDownloadDocuments,
    } = this.state

    transactionsTable.rows.map((row) => {
      if (row.status === 1)
        row.status_column = (
          <Chip style={{ color: '#fff', background: Styles.info }} size="small" label="Pendente"></Chip>
        )
      if (row.status === 2)
        row.status_column = (
          <Chip style={{ color: '#fff', background: Styles.info }} size="small" label="Aguardando pagamento"></Chip>
        )
      if (row.status === 3)
        row.status_column = (
          <Chip style={{ color: '#fff', background: Styles.success }} size="small" label="Concluida"></Chip>
        )
      if (row.status === 4)
        row.status_column = (
          <Chip style={{ color: '#fff', background: Styles.critical }} size="small" label="Cancelada"></Chip>
        )
      if (row.status === 5)
        row.status_column = <Chip style={{ color: '#fff', background: Styles.error }} size="small" label="Falha"></Chip>
      if (row.status === 6)
        row.status_column = (
          <Chip style={{ color: '#fff', background: Styles.success }} size="small" label="Pago"></Chip>
        )
      if (row.status === 7)
        row.status_column = (
          <Chip style={{ color: '#fff', background: Styles.info }} size="small" label="Pago Parcialmente"></Chip>
        )
      if (row.status === 8)
        row.status_column = (
          <Chip style={{ color: '#fff', background: Styles.info }} size="small" label="Pendente Contrato"></Chip>
        )
    })
    return (
      <>
        <Header
          reference={(reference) => {
            this.Header = reference
          }}
        />

        <TransactionResume purchase={this.state.purchase} plan_id={this.props.plan_id} />

        <Container id="client-detail" className="print-hidden" maxWidth="lg">
          <Grid spacing={3} container>
            <Grid item xs={12}>
              <Breadcrumbs className="breadcrumbs">
                <Link to="/" style={{ display: 'flex' }}>
                  <Home
                    style={{
                      marginRight: 8,
                      width: 20,
                      height: 20,
                    }}
                  />
                  Início
                </Link>
                <Link to="/acompanhamento">
                  <Typography color="textSecondary">Acompanhamento</Typography>
                </Link>
                <Typography color="textPrimary">Detalhe do cliente</Typography>
              </Breadcrumbs>
            </Grid>

            <Grid item xs={12}>
              <div className="card-container">
                {/** 
                <ClickAwayListener
                  mouseEvent="onMouseDown"
                  touchEvent="onTouchStart"
                  onClickAway={() => this.setState({ settings: { ...settings, open: false } })}
                >*/}
                <Box className="config-icon">
                  {transactionsTable.rows[0] &&
                    transactionsTable.rows[0].purchase.status === 3 &&
                    tab === 2 &&
                    transactionsTable.rows.length === 1 && (
                      <Button
                        style={{ borderRadius: 32, margin: 16, color: '#fff', marginRight: 8 }}
                        color="secondary"
                        variant="contained"
                        onClick={() => {
                          this.setState({
                            invoices: { ...this.state.invoices, open: true },
                            selectedPurchase: [transactionsTable.rows[0]],
                          })
                        }}
                      >
                        <MoneyOff style={{ color: '#fff', marginRight: 8 }} />
                        Acionar sinistro
                      </Button>
                    )}

                  <Button
                    style={{ borderRadius: 32, margin: 16, color: '#fff' }}
                    color="secondary"
                    variant="contained"
                    onClick={(event) =>
                      this.setState({
                        settings: { ...settings, open: !settings.open, anchorOrigin: event.currentTarget },
                      })
                    }
                  >
                    <Settings style={{ color: '#fff', marginRight: 8 }} />
                    Ações
                  </Button>

                  <Popper style={{ marginTop: 8 }} open={settings.open} anchorEl={settings.anchorOrigin}>
                    <Zoom in={settings.open}>
                      <Paper className="pop print-hidden">
                        <List
                          component="nav"
                          subheader={
                            <ListSubheader style={{ position: 'initial' }} component="div">
                              Ações
                            </ListSubheader>
                          }
                        >
                          <Divider></Divider>

                          {transactionsTable.rows.length === 1 ? (
                            <>
                              {/* -------------------------------------------------------------------------- */}
                              {/*          PARA AUTENTICAR DOCUMENTOS, A VENDA DEVE ESTAR CONCLUÍDA          */}
                              {/* -------------------------------------------------------------------------- */}
                              {transactionsTable.rows[0].purchase.status === 3 && (
                                <ListItem
                                  button
                                  onClick={() =>
                                    this.props.history.push(`/documentos/${transactionsTable.rows[0].purchase.id}`)
                                  }
                                >
                                  <ListItemIcon>
                                    <NoteAddRounded />
                                  </ListItemIcon>
                                  <ListItemText primary="Autenticar documentos" />
                                </ListItem>
                              )}

                              {/* -------------------------------------------------------------------------- */}
                              {/*                VISUALIZA OS DOCUMENTOS AUTENTICADOS DA VENDA               */}
                              {/* -------------------------------------------------------------------------- */}
                              {!!(
                                transactionsTable.rows[0].purchase.status === 3 &&
                                transactionsTable.rows[0].purchase.contracts.length
                              ) && (
                                <ListItem
                                  button
                                  onClick={() => this.handleOpenModalDocuments(transactionsTable.rows[0].purchase.id)}
                                >
                                  <ListItemIcon>
                                    <DescriptionRounded />
                                  </ListItemIcon>
                                  <ListItemText primary="Baixar documentos autenticados" />
                                </ListItem>
                              )}

                              {/* -------------------------------------------------------------------------- */}
                              {/*            PARA ACIONAR SINISTROS, A VENDA DEVE ESTAR CONCLUÍDA            */}
                              {/* -------------------------------------------------------------------------- */}
                              {transactionsTable.rows[0].purchase.status === 3 && (
                                <ListItem
                                  button
                                  onClick={() => {
                                    this.setState({
                                      invoices: { ...this.state.invoices, open: true },
                                      selectedPurchase: [transactionsTable.rows[0]],
                                    })
                                  }}
                                >
                                  <ListItemIcon>
                                    <MoneyOffRounded />
                                  </ListItemIcon>
                                  <ListItemText primary="Acionar sinistro" />
                                </ListItem>
                              )}

                              {/* -------------------------------------------------------------------------- */}
                              {/*           PARA CANCELAR VENDAS, O STATUS DEVE SER DIFERENTE DE 4           */}
                              {/* -------------------------------------------------------------------------- */}
                              {transactionsTable.rows[0].purchase.status !== 4 && (
                                <ListItem
                                  button
                                  onClick={() => {
                                    if (moment(new Date()).diff(transactionsTable.rows[0].dt_inicio, 'days') > 30) {
                                      this.setState({
                                        cancelTransaction30Days: { ...this.state.cancelTransaction30Days, open: true },
                                        selectedPurchase: [transactionsTable.rows[0]],
                                      })

                                      return
                                    }
                                    this.setState({
                                      cancel: { ...this.state.cancel, open: true },
                                      selectedPurchase: [transactionsTable.rows[0]],
                                    })
                                    this.fetchData()
                                  }}
                                >
                                  <ListItemIcon>
                                    <CancelRounded />
                                  </ListItemIcon>
                                  <ListItemText primary="Cancelar transação" />
                                </ListItem>
                              )}
                              {transactionsTable.rows[0].purchase.payment_method !== 'credit_card' && (
                                <ListItem
                                  button
                                  onClick={() => {
                                    this.setState({
                                      sendReceipt: { ...this.state.sendReceipt, open: true },
                                      selectedPurchase: [transactionsTable.rows[0]],
                                    })
                                  }}
                                >
                                  <ListItemIcon>
                                    <ReceiptRounded />
                                  </ListItemIcon>
                                  <ListItemText primary="Enviar comprovante de pagamento" />
                                </ListItem>
                              )}

                              {transactionsTable.rows[0].purchase.contract_status === 'signed' && (
                                <ListItem
                                  button
                                  onClick={async () => {
                                    const getDownloadUrl = await ApiInstance.get(
                                      `downloadContract/${transactionsTable.rows[0].purchase.document_key}`
                                    ).catch(() => {})

                                    window.open(getDownloadUrl.data.signed_file_url, '_blank')
                                  }}
                                >
                                  <ListItemIcon>
                                    <GetApp />
                                  </ListItemIcon>
                                  <ListItemText primary="Baixar documentos assinados" />
                                </ListItem>
                              )}

                              <ListItem
                                button
                                onClick={async () => {
                                  const { purchase } = await ApiInstanceV2.get(
                                    `/purchase/${transactionsTable.rows[0].purchase.id}`
                                  )
                                  await this.setState({ purchase })

                                  window.print()
                                }}
                                m={1}
                                style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                              >
                                <ListItemIcon>
                                  <Print />
                                </ListItemIcon>
                                <ListItemText primary="Resumo da transação" />
                              </ListItem>
                            </>
                          ) : (
                            <></>
                          )}

                          <ListItem button onClick={() => this.EditClientModal.setState({ open: true, client })}>
                            <ListItemIcon>
                              <EditRounded />
                            </ListItemIcon>
                            <ListItemText primary="Editar cliente" />
                          </ListItem>

                          <ListItem
                            button
                            onClick={() =>
                              this.EditCreditCardModal.setState({
                                open: true,
                                card: { ...this.EditCreditCardModal.state.card, name: client.name },
                              })
                            }
                          >
                            <ListItemIcon>
                              <CreditCard />
                            </ListItemIcon>
                            <ListItemText primary="Alterar cartão" />
                          </ListItem>
                        </List>
                      </Paper>
                    </Zoom>
                  </Popper>
                </Box>
                {/**</ClickAwayListener> */}
                <div className="card-background" style={{ backgroundImage: `url(${Pattern})` }}></div>
                <Box className="avatar-name">
                  <Avatar className="avatar"></Avatar>
                  <Box className="user">
                    <Typography className="name">{client ? client.name : 'Carregando...'}</Typography>
                    <Typography className="cpf">{client ? client.cpf : 'Carregando...'}</Typography>
                  </Box>

                  <Hidden xsDown>
                    <Box className="tabs">
                      <Tabs
                        value={tab}
                        onChange={handleChangeTab}
                        name="tab"
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                      >
                        <Tab label="Transações" />
                        <Tab label="Taxa de adesão" />
                        <Tab label="Sinistros" />
                      </Tabs>
                    </Box>
                  </Hidden>
                </Box>
              </div>
            </Grid>

            <Grid item xs={12}>
              <Collapse in={tab === 0}>
                <TransactionsTable
                  ref={(reference) => (this.TransactionsTable = reference)}
                  parent={this}
                  plan_id={company.plan_id}
                  client={client}
                  products={products}
                  editClientModal={this.EditClientModal}
                  loading={loading}
                  rows={transactionsTable.rows}
                  columns={transactionsTable.columns}
                />
              </Collapse>
              <Collapse in={tab === 1}>
                <ProductsTable products={products} />
              </Collapse>
              <Collapse in={tab === 2}>
                <InvoicesTable parent={this} />
              </Collapse>
            </Grid>

            <Grid item xs={12}>
            </Grid>
          </Grid>
        </Container>

        <EditClientModal ref={(reference) => (this.EditClientModal = reference)} client={client} parent={this} />
        <EditCreditCardModal
          ref={(reference) => (this.EditCreditCardModal = reference)}
          client={client}
          sendMail={true}
        />

        {/** SOUZA GOMES */}
        <ModalSallesDetail
          open={openModalDetailSalles}
          ref={(reference) => (this.SalesDetailSouzaGomes = reference)}
          onClose={() => this.setState({ openModalDetailSalles: false })}
          purchase={selectedPurchase}
          client={client}
        />

        <ModalSallesDetailUcred
          ref={(reference) => (this.SalesDetail = reference)}
          purchase={selectedPurchase}
          client={client}
          parent={this}
        />

        <ModalSinistro
          open={this.state.invoices.open}
          onClose={() => {
            this.setState({ invoices: { ...this.state.invoices, open: false } })
          }}
          parent={this}
          purchase={this.state.selectedPurchase ? this.state.selectedPurchase[0] : {}}
          total_value={this.state.totalValue}
          client={client}
        />

        <ModalDocs
          parent={this}
          client={client}
          open={this.state.sendReceipt.open}
          purchase={this.state.selectedPurchase}
          onClose={() => this.setState({ sendReceipt: { ...this.state.sendReceipt, open: false } })}
        />

        <ModalCancelTransaction parent={this} />
        <ModalCancelTransaction30Days parent={this} />

        <Dialog
          open={modalDownloadDocuments.open}
          onClose={() => this.setState({ modalDownloadDocuments: { ...modalDownloadDocuments, open: false } })}
        >
          <DialogTitle
            disableTypography
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6">Baixar documentos autenticados</Typography>
            <IconButton
              onClick={() => this.setState({ modalDownloadDocuments: { ...modalDownloadDocuments, open: false } })}
              size="small"
              style={{ marginLeft: 'auto' }}
            >
              <Close></Close>
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
                width: 450,
                padding: 8,
              }}
            >
              {modalDownloadDocuments.contracts.map((contract, index) => {
                return (
                  <div
                    key={index}
                    style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '4px 0' }}
                  >
                    <div>{contract.description}</div>
                    <Tooltip title="Baixar este documento" arrow>
                      <Fab
                        style={{ marginLeft: 'auto' }}
                        size="small"
                        color="primary"
                        onClick={() => window.open(contract.download_signed, '_blank')}
                      >
                        <GetApp></GetApp>
                      </Fab>
                    </Tooltip>
                    {index !== modalDownloadDocuments.contracts.length - 1 && <Divider />}
                  </div>
                )
              })}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({ modalDownloadDocuments: { ...modalDownloadDocuments, open: false } })}
              color="primary"
              variant="outlined"
            >
              Voltar
            </Button>
          </DialogActions>
        </Dialog>

        <Hidden smUp>
          <BottomNavigation
            className="print-hidden"
            style={{ width: '100vw', position: 'fixed', bottom: 0, left: 0 }}
            value={tab}
            onChange={handleChangeTab}
            showLabels
          >
            <BottomNavigationAction label="Transações" icon={<AttachMoneyRounded />} />
            <BottomNavigationAction label="Produtos" icon={<LocalMallRounded />} />
            <BottomNavigationAction label="Sinistros" icon={<MoneyOffRounded />} />
          </BottomNavigation>
        </Hidden>
      </>
    )
  }
}
