import React from 'react'
import { withSnackbar } from 'notistack'
import { connect } from 'react-redux'
import Modal from 'react-responsive-modal'
import { Link } from 'react-router-dom'
import Api from '../services/ApiInstance'
import Button from '../components/button'
import Input from '../components/input'
import Loader from '../components/loader'
import { fillAguardandoTrial, fillTrial, logOutRevendedor } from '../redux/actions/authUserAction'
import { refresh } from '../redux/actions/creditAnalysisActions'
import Notification from './Notification'

class Header extends React.Component {
  state = {
    showNotification: false,
    openModalChangeData: false,
    password: '',
    email: '',
    loading: false,
  }

  UNSAFE_componentWillMount() {
    if (this.props.user) {
      this.setState({ email: this.props.user.email })
    }
  }

  handleChange = (event) => {
    const { id, value } = event.currentTarget
    this.setState({ [id]: value })
  }

  handleClick = async () => {
    this.setState({ loading: true })
    const { email, password } = this.state
    try {
      if (!email || email == '') throw new Error('Informe o e-mail')
      if (!password || password == '') throw new Error('Informe a senha')
      await Api.post('changeUserData', {
        id: this.props.user.id,
        email,
        password,
      })
      this.setState({
        loading: false,
        openModalChangeData: false,
      })
      const snackbarKey = this.props.enqueueSnackbar('Alterado com sucesso.', {
        variant: 'success',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
    } catch (error) {
      const snackbarKey = this.props.enqueueSnackbar(error.message, {
        variant: 'error',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
    }
  }

  closeModalChangeData = () => {
    this.setState({ openModalChangeData: false })
  }

  openModalChangeData = async () => {
    this.setState({ openModalChangeData: true })
  }

  render() {
    const { user } = this.props
    const { email, password, loading } = this.state
    if (!user) return null
    return (
      <React.Fragment>
        <div className="row">
          <div className="col">
            <div className="header">
              <div className="header__logo" onClick={this.props.refresh}>
                <Link to="/home-revendedor">
                  <div></div>
                </Link>
              </div>
              <div className="header__navigation">
                <ul>
                  <li></li>
                </ul>
              </div>
              <label className="header__avatar" htmlFor="header__avatar-checkbox">
                <span className="header__avatar--sigla">
                  {' '}
                  <img src="https://secure.gravatar.com/avatar/6d122b9cbe7292c3c0f1e30a3d58ab6d.png?d=identicon&r=G&s=50" alt="avatar" />{' '}
                </span>
                <span className="header__avatar--name">{user.name}</span>
                <input type="checkbox" id="header__avatar-checkbox" className="header__avatar-checkbox" />
                <div className="header__avatar-list">
                  <ul>
                    {/* <Link to="/avisos" onClick={this.props.refresh}> <li style={{display: 'flex', justifyContent: 'center', alignContent: 'center' , alignItems: 'center' }}> <span className="count-notification"> 4 </span> Central de Avisos </li> </Link> */}
                    <Link to="/financeiro-revendedor" onClick={this.props.refresh}>
                      {' '}
                      <li>
                        <i className="lni-stats-up"></i>Financeiro{' '}
                      </li>{' '}
                    </Link>
                    <li onClick={this.openModalChangeData}>
                      <i className="lni-user"></i>Alterar Dados
                    </li>
                    <Link to="/logOutRevendedor" onClick={this.props.logOutRevendedor}>
                      {' '}
                      <li>
                        <i className="lni-exit"></i>Sair
                      </li>{' '}
                    </Link>
                    {/* <li onClick={this.props.logOut}><i className="lni-exit"></i>Sair</li> */}
                  </ul>
                </div>
              </label>
            </div>
          </div>
        </div>
        <Notification
          show={this.state.showNotification}
          close={() => {
            this.setState({ showNotification: false })
          }}
          afterConfirmTrial={() => {
            alert('confirmar')
          }}
        />

        <Modal open={this.state.openModalChangeData} onClose={this.closeModalChangeData} classNames={{ modal: 'modal-change-data' }}>
          <div className="modal-change-data-header">
            <h1>Alterar Dados</h1>
          </div>
          <div className="modal-change-data-body">
            W
            <Input type="email" placeholder="E-mail" labelDescription="E-mail" id="email" value={email} onChange={this.handleChange} iconType="email" />
            <Input type="password" placeholder="Senha" labelDescription="Senha" id="password" value={password} onChange={this.handleChange} />
            <div className="modal-change-data-button">{loading ? <Loader /> : <Button description="Salvar" onClick={this.handleClick} />}</div>
          </div>
        </Modal>
      </React.Fragment>
    )
  }
}

function mapStateToProps({ authReducer }) {
  return {
    user: authReducer.authUser,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    refresh: () => dispatch(refresh()),
    logOutRevendedor: () => dispatch(logOutRevendedor()),
    fillTrial: (trial) => dispatch(fillTrial(trial)),
    fillAguardandoTrial: (trial) => dispatch(fillAguardandoTrial(trial)),
  }
}

export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(Header))
