import { Flex, Stack, HStack, Table, Tbody, Text, Tr, Td } from '@chakra-ui/react'

import { PaymentStatusBadge } from '../components/Badge/PaymentStatusBadge'
import { DetailPopover } from '../components/DetailPopover'
import { SummaryType } from '../types/summary'

interface RowProps {
  children?: JSX.Element | string
  hasBorder?: boolean
  isTotal?: boolean
  isNumeric?: boolean
}

export const Row = ({ children, hasBorder = false, isTotal, ...rest }: RowProps) => (
  <Td px={0} py={isTotal ? 4 : 2} borderBottom={hasBorder ? '1px' : '0px'} {...rest}>
    <Text
      fontSize={isTotal ? 'md' : 'sm'}
      fontWeight={isTotal ? 'semibold' : 'normal'}
      color={isTotal ? 'gray.500' : 'gray.600'}
    >
      {children}
    </Text>
  </Td>
)

interface SummaryProps {
  summary: SummaryType
}

export function Summary({ summary }: SummaryProps) {
  const { status, transaction, commission } = summary

  return (
    <Flex w="100%">
      <HStack w="100%" spacing={16}>
        <Flex w="100%">
          <Stack mr={6} mt={2} spacing={4}>
            <PaymentStatusBadge status={status.transaction} />
            {status.adhesion && <PaymentStatusBadge status={status.adhesion} />}
          </Stack>
          <Table size="sm" variant="simple">
            <Tbody>
              <Tr>
                <Row hasBorder>Valor avalizado</Row>
                <Row hasBorder />
                <Row isNumeric>{transaction.value}</Row>
              </Tr>
              <Tr>
                <Row hasBorder>Taxa de adesão</Row>
                <Row hasBorder />
                <Row isNumeric>{transaction.adhesion}</Row>
              </Tr>
              <Tr>
                <Row />
                <Row isNumeric isTotal>
                  Total
                </Row>
                <Row isNumeric isTotal>
                  {transaction.total}
                </Row>
              </Tr>
            </Tbody>
          </Table>
        </Flex>
        <Flex w="100%">
          <Table size="sm" variant="simple">
            <Tbody>
              <Tr>
                <Row hasBorder>Comissão</Row>
                <Row hasBorder />
                <Row isNumeric>
                  {commission.value === 0 ? (
                    commission.valueFormatted
                  ) : (
                    <DetailPopover value={commission.valueFormatted} details={commission.details} />
                  )}
                </Row>
              </Tr>
              <Tr>
                <Row hasBorder>Comissão da adesão</Row>
                <Row hasBorder />
                <Row isNumeric>
                  {commission.adhesion === 0 ? (
                    commission.adhesionFormatted
                  ) : (
                    <DetailPopover isAdhesion value={commission.adhesionFormatted} />
                  )}
                </Row>
              </Tr>
              <Tr>
                <Row />
                <Row isNumeric isTotal>
                  Total
                </Row>
                <Row isNumeric isTotal>
                  {commission.total}
                </Row>
              </Tr>
            </Tbody>
          </Table>
        </Flex>
      </HStack>
    </Flex>
  )
}
