import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Tooltip,
} from '@material-ui/core'
import { Close, Help } from '@material-ui/icons'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import { withSnackbar } from 'notistack'
import React from 'react'
import ApiInstance from '../../services/ApiInstance'

class EditService extends React.Component {
  constructor() {
    super()
    this.state = {
      id: null,
      openModalModalConfirmation: false,
      items: [],
      valorSimulado: 1000,
      name: '',
      ganhoImobiliaria: 0,
      coberturaUcred: 0,
      overValue: 0,
      taxaLocatario: '5,00',
      loading: false,
    }
  }

  payment(type) {
    if (type === 'parcel') {
      this.setState({ parcel: 1, recurrency: 0 })
    } else {
      this.setState({ parcel: 0, recurrency: 1 })
    }
  }

  async handleOpen(data) {
    const { name, id, total_fee, imob_gain } = data

    this.setState({ name: name, id: id, taxaLocatario: Number(total_fee.replace('%', '')).toFixed(2).replace('.', ',') })
    await this.setValues(Number(total_fee.replace('%', '')).toFixed(2).replace('.', ','))
    this.setState({ ganhoImobiliaria: imob_gain })

    this.handleGanhoImobiliaria(imob_gain)
  }

  async setValues(value) {
    switch (value) {
      case '5,00':
        await this.setState({ ganhoImobiliariaMax: 0, taxaLocatario: value })
        break
      case '6,00':
        await this.setState({ ganhoImobiliariaMax: 0, taxaLocatario: value })
        break
      case '7,00':
        await this.setState({ ganhoImobiliariaMax: 0, taxaLocatario: value })
        break
      case '8,00':
        await this.setState({ ganhoImobiliariaMax: 10, taxaLocatario: value })
        break
      case '9,00':
        await this.setState({ ganhoImobiliariaMax: 20, taxaLocatario: value })
        break
      case '10,00':
        await this.setState({ ganhoImobiliariaMax: 30, taxaLocatario: value })
        break
      case '11,00':
        await this.setState({ ganhoImobiliariaMax: 35, taxaLocatario: value })
        break
      case '12,00':
        await this.setState({ ganhoImobiliariaMax: 50, taxaLocatario: value })
        break
      default:
        await this.setState({ ganhoImobiliariaMax: 0, taxaLocatario: value })
        break
    }

    await this.handleGanhoImobiliaria(0)
    await this.setValueSelect()
  }

  async handleGanhoImobiliaria(value) {
    let comissaoImobiliaria = value

    let { taxaLocatario } = this.state
    taxaLocatario = Number(taxaLocatario.replace(',', '.'))

    let overValue = Number(comissaoImobiliaria) * taxaLocatario
    overValue = Number(overValue / 100).toFixed(3)

    let taxaUcred = Number(taxaLocatario - overValue)
    let coberturaUcred = 0
    let porcentagemTaxaUcred = taxaUcred.toFixed(3) / 100

    if (porcentagemTaxaUcred < 0.05) {
      coberturaUcred = 0
    } else if (porcentagemTaxaUcred < 0.06) {
      coberturaUcred = 7
    } else if (porcentagemTaxaUcred < 0.07) {
      coberturaUcred = 8
    } else if (porcentagemTaxaUcred < 0.08) {
      coberturaUcred = 12
    } else if (porcentagemTaxaUcred < 0.09) {
      coberturaUcred = 20
    } else if (porcentagemTaxaUcred < 0.1) {
      coberturaUcred = 24
    } else if (porcentagemTaxaUcred < 0.11) {
      coberturaUcred = 30
    } else if (porcentagemTaxaUcred < 0.12) {
      coberturaUcred = 32
    } else {
      coberturaUcred = 34
    }

    await this.setState({
      ganhoImobiliaria: comissaoImobiliaria,
      overValue,
      taxaUcred,
      coberturaUcred,
    })
  }

  handleChange(event) {
    const { id, value } = event.currentTarget
    this.setState({ [id]: value })
  }

  handleCredibility(value) {
    this.setState({ level_credibility: value })
  }

  async setValueSelect() {
    const { ganhoImobiliariaMax } = this.state
    const aux = []
    let i = 0
    for (i = 0; i <= ganhoImobiliariaMax; i++) {
      if (i % 5 == 0) aux.push(i)
    }
    await this.setState({ items: aux })
  }

  render() {
    const { taxaLocatario, valorSimulado, ganhoImobiliaria, name, overValue, coberturaUcred, items, loading, id, taxaUcred } = this.state
    const { parent } = this.props

    const confirmation = () => {
      try {
        if (!this.state.name) throw new Error('Informe o nome do serviço')
        this.setState({ openModalModalConfirmation: true })
      } catch (err) {
        const snackbarKey = this.props.enqueueSnackbar(err.message, {
          variant: 'error',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        })
      }
    }

    const resetState = async () => {
      this.setState({
        id: null,
        openModalModalConfirmation: false,
        items: [],
        valorSimulado: 1000,
        name: '',
        ganhoImobiliaria: 0,
        coberturaUcred: 0,
        overValue: 0,
        taxaLocatario: '5,00',
        loading: false,
      })
    }

    const saveService = async () => {
      await this.setState({ loading: true })
      try {
        await ApiInstance.put('serviceUcredPlan', {
          data: {
            id: id,
            name,
            administration_fee: String(taxaUcred).replace(',', '.'),
            cover_value: String(coberturaUcred).replace(',', '.'),
            imob_gain: String(ganhoImobiliaria).replace(',', '.'),
            over_value: String(overValue).replace(',', '.'),
            total_fee: String(taxaLocatario).replace(',', '.'),
          },
        })
        await resetState()
        parent.setState({ editService: { ...parent.state.editService, open: false } })
        parent.fetchData()
        const snackbarKey = this.props.enqueueSnackbar('Serviço atualizado com sucesso!.', {
          variant: 'success',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        })
      } catch (error) {
        const snackbarKey = this.props.enqueueSnackbar('Falha ao salvar serviço, entre em contato com nosso suporte.', {
          variant: 'error',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        })
        await this.setState({ loading: false })
      }
    }

    const handleChangeNome = (event) => {
      this.setState({ name: event.currentTarget.value })
    }

    const handleChangeSimulatedValue = (event, value) => {
      this.setValues(taxaLocatario)
      this.handleGanhoImobiliaria(ganhoImobiliaria)
      this.setState({ valorSimulado: value })
    }

    const handleChangeLocatorFee = (event) => {
      let { value } = event.target
      this.setValues(value)
    }

    return (
      <Dialog disableScrollLock open={this.props.open} onClose={() => parent.setState({ editService: { ...parent.state.newService, open: false } })}>
        <DialogTitle
          disableTypography
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6">Editar serviço</Typography>
          <IconButton onClick={() => parent.setState({ editService: { ...parent.state.newService, open: false } })} size="small" style={{ marginLeft: 'auto' }}>
            <Close></Close>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid spacing={2} container>
            <Grid item xs={12}>
              <CurrencyTextField
                decimalCharacter=","
                digitGroupSeparator="."
                currencySymbol="R$"
                variant="outlined"
                label="Digite um valor de transação a ser simulado"
                placeholder="1200"
                minimumValue="0"
                value={valorSimulado}
                fullWidth
                onChange={handleChangeSimulatedValue}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom>Configurações do serviço</Typography>
              <TextField label="Nome do serviço" variant="outlined" fullWidth value={name} onChange={handleChangeNome} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>Taxa do locatário</InputLabel>
                <Select label="Taxa do locatário" onChange={handleChangeLocatorFee} value={taxaLocatario}>
                  <MenuItem value="5,00">5,00 %</MenuItem>
                  <MenuItem value="6,00">6,00 %</MenuItem>
                  <MenuItem value="7,00">7,00 %</MenuItem>
                  <MenuItem value="8,00">8,00 %</MenuItem>
                  <MenuItem value="9,00">9,00 %</MenuItem>
                  <MenuItem value="10,00">10,00 %</MenuItem>
                  <MenuItem value="11,00">11,00 %</MenuItem>
                  <MenuItem value="12,00">12,00 %</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>Comissão da imobiliária</InputLabel>
                <Select label="Comissão da imobiliária" onChange={(event) => this.handleGanhoImobiliaria(event.target.value)} value={ganhoImobiliaria}>
                  {!items.length && <MenuItem value="0">0 %</MenuItem>}
                  {items.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item} %
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography>Cobertura Ucred</Typography>
              <Typography variant="body2" color="textSecondary">
                R$ {String((Number(String(valorSimulado).split('.').join('').replace(',', '.')) * coberturaUcred).toFixed(2)).replace('.', ',')} ({coberturaUcred} Vezes)
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography style={{ display: 'flex', alignItems: 'center' }}>
                Você recebe
                <Tooltip title="É descontado sobre todas transações efetuadas um percentual de 5% da operadora de cartão. O valor visualizado abaixo já é o líquido à receber.">
                  <Help fontSize="small" style={{ marginLeft: 4 }} />
                </Tooltip>
              </Typography>
              <Typography variant="body2" color="textSecondary">
                R$ {((overValue / 100) * Number(String(valorSimulado).replace(',', '.')) * 12 * 0.95).toFixed(2).replace('.', ',')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>Valor pago pelo locatário</Typography>
              <Typography variant="body2" color="textSecondary">
                R$ {((Number(taxaLocatario.replace(',', '.')) / 100) * Number(String(valorSimulado).split('.').join('').replace(',', '.')) * 12).toFixed(2)}
              </Typography>
            </Grid>{' '}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => parent.setState({ editService: { ...parent.state.newService, open: false } })}>
            Voltar
          </Button>
          <Button variant="contained" color="primary" onClick={confirmation}>
            Salvar
          </Button>
        </DialogActions>

        <Dialog
          open={this.state.openModalModalConfirmation}
          onClose={() => {
            this.setState({ openModalModalConfirmation: false })
          }}
        >
          <DialogTitle
            disableTypography
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6"> Deseja realizar a alteração neste serviço</Typography>
            <IconButton
              onClick={() => {
                this.setState({ openModalModalConfirmation: false })
              }}
              size="small"
              style={{ marginLeft: 'auto' }}
            >
              <Close></Close>
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography>Nome do serviço</Typography>
                <Typography variant="body2" color="textSecondary">
                  {name}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography>Taxa Locatário</Typography>
                <Typography variant="body2" color="textSecondary">
                  {taxaLocatario}%
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography>Cobertura Ucred</Typography>
                <Typography variant="body2" color="textSecondary">
                  {coberturaUcred} Vezes
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography>Comissão Imobiliária</Typography>
                <Typography variant="body2" color="textSecondary">
                  {ganhoImobiliaria}%
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={loading}
              variant="outlined"
              color="primary"
              onClick={() => {
                this.setState({ openModalModalConfirmation: false })
              }}
            >
              Voltar
            </Button>
            <Box style={{ display: 'flex', position: 'relative' }}>
              {loading && (
                <CircularProgress
                  size={24}
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: -12,
                    marginLeft: -12,
                  }}
                />
              )}
              <Button disabled={loading} variant="contained" color="primary" onClick={saveService}>
                Confirmar
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      </Dialog>
    )
  }
}

export default withSnackbar(EditService)
