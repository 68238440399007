import { Button, Circle, ButtonProps, IconProps } from '@chakra-ui/react'

interface ActionButtonProps extends ButtonProps {
  title: string
  icon: IconProps
  iconPosition?: 'left' | 'right'
}

export function ActionButton({
  title,
  w = 'fit-content',
  icon,
  iconPosition = 'left',
  ...rest
}: ActionButtonProps) {
  return (
    <Button
      w={w}
      colorScheme="blue"
      size="sm"
      variant="ghost"
      borderRadius={20}
      paddingLeft={iconPosition === 'left' ? 0 : '0'}
      paddingRight={iconPosition === 'right' ? 0 : '0'}
      iconSpacing={4}
      leftIcon={
        iconPosition === 'left' ? (
          <Circle size="8" bg="blue.500" color="white" boxShadow="md">
            {icon}
          </Circle>
        ) : (
          <></>
        )
      }
      rightIcon={
        iconPosition === 'right' ? (
          <Circle size="8" bg="blue.500" color="white" boxShadow="md">
            {icon}
          </Circle>
        ) : (
          <></>
        )
      }
      {...rest}
    >
      {title}
    </Button>
  )
}
