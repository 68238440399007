import { Button } from '@chakra-ui/react'

type FooterButtonProps = {
  title: string
  handlePress: () => void
  fontSize?: string
}

export function FooterButton({ title, fontSize, handlePress }: FooterButtonProps) {
  return (
    <Button
      size="sm"
      variant="ghost"
      colorScheme="blue"
      fontWeight="normal"
      fontSize={fontSize}
      p={1}
      h="fit-content"
      color="#3497DB"
      onClick={handlePress}
    >
      {title}
    </Button>
  )
}
