import React from 'react'
import Modal from 'react-responsive-modal'
import ApiInstance from '../services/ApiInstance'

export default class ModalSallesDetail extends React.Component {
  state = {
    details: null,
    valorTotal: null,
    valorDescontado: null,
    parcelas: null,
    primeiroPagamento: null,
    ultimoPagamento: null,
    garantiaUcred: null,
    valorTaxaDescontado: null,
    purchase: {},
    purchaseCorresp: {},
    selectedPurchase: [],
  }
  async componentDidMount() {
    this.fetchData()
  }

  async fetchData() {
    const purchase = this.state.selectedPurchase

    if (purchase && purchase.length) {
      const details = await ApiInstance.get(`venda/${purchase[0].purchase.id}`)
      let { purchaseData, purchase_corresponsavel } = details.data
      await this.setState({ purchase: purchaseData, purchaseCorresp: purchase_corresponsavel })
    }
  }

  render() {
    const { open, onClose, client } = this.props
    const { purchase, purchaseCorresp } = this.state
    return (
      <Modal open={open} onClose={onClose} center classNames={{ modal: 'modalSallesDetail' }}>
        <h2>Detalhamento da transação</h2>
        <div className="client-container">
          <div className="client-container--header">
            <h3>Locatário</h3>
          </div>
          <b>Nome:</b> <span>{client ? client.name : ''}</span>
          <b>Telefone celular:</b> <span>{client ? client.telephone_1 : ''}</span>
          <b>E-mail:</b> <span>{client ? client.email : ''}</span>
        </div>

        {purchaseCorresp.valorTotalCorresp ? (
          <React.Fragment>
            <div className="ucred-container">
              <div className="ucred-container--header">
                <h3>Corresponsável</h3>
              </div>
              <b>Nome:</b> <span>{purchase.corresponsavel.client_name} </span>
              <b>Telefone celular:</b> <span>{purchase.corresponsavel.client_telephone_1}</span>
              <b>E-mail:</b> <span>{purchase.corresponsavel.client_email}</span>
            </div>
            <div className="ucred-container">
              <div className="ucred-container--header">
                <h3>Dados financeiros locatário</h3>
              </div>
              <b>Valor transacionado no cartão:</b> <span>R$ {Number(purchase.valorTotal).toFixed(2)}</span>
              <b>Parcelamento:</b>{' '}
              <span>
                {' '}
                {purchase.parcelas} X R$ {(purchase.valorTotal / purchase.parcelas).toFixed(2)}{' '}
              </span>
            </div>
            <div className="ucred-container">
              <div className="ucred-container--header">
                <h3>Dados financeiros corresponsável</h3>
              </div>
              <b>Valor transacionado no cartão:</b> <span>R$ {Number(purchase.corresponsavel.total_value).toFixed(2)}</span>
              <b>Parcelamento:</b>{' '}
              <span>
                {' '}
                {purchase.parcelas} X R$ {(purchase.corresponsavel.total_value / purchase.parcelas).toFixed(2)}
              </span>
            </div>
            <div className="ucred-container">
              <div className="ucred-container--header">
                <h3>Adquirente</h3>
              </div>
              <b>Valor descontado:</b> {purchase.corresponsavel && <span>R$ {(Number(Number(purchase.valorTotal) + Number(purchase.corresponsavel.total_value)) * 0.05).toFixed(2)}</span>}
            </div>
            <div className="ucred-container">
              <div className="ucred-container--header">
                <h3>Valor total</h3>
              </div>
              <b>Valor recebido:</b> <span>R$ {Number(Number(purchase.valorTotal) + Number(purchase.corresponsavel.total_value)).toFixed(2)}</span>
            </div>
          </React.Fragment>
        ) : (
          ''
        )}

        {purchase
          ? !purchase.corresponsavel && (
              <React.Fragment>
                <div className="salles-container">
                  <div className="salles-container--header">
                    <h3>Venda</h3>
                  </div>
                  <b>Valor Total:</b> <span>R$ {Number(purchase.valorTotal).toFixed(2)}</span>
                  <b>Parcelas:</b>{' '}
                  <span>
                    {purchase.parcelas}x R$ {Number(purchase.parcelasValorTotal).toFixed(2)}
                  </span>
                </div>
                <div className="ucred-container">
                  <div className="ucred-container--header">
                    <h3>Adquirente</h3>
                  </div>
                  <b>Valor Descontado:</b>{' '}
                  <span>
                    R${' '}
                    {purchase.garantiaUcred === 1
                      ? Number(Number(purchase.valorTotal) - Number(purchase.valorTaxaDescontado)).toFixed(2)
                      : Number(Number(purchase.valorTotal) - Number(purchase.valorDescontado)).toFixed(2)}
                  </span>
                </div>
              </React.Fragment>
            )
          : null}
      </Modal>
    )
  }
}
