import React from 'react'
import moment from 'moment'
import MaterialTable from 'material-table'
import { Box, Breadcrumbs, CircularProgress, Container, Fab, Grid, Tooltip, Typography } from '@material-ui/core'
import { Add, Delete, Edit, Home } from '@material-ui/icons'
import { withSnackbar } from 'notistack'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import api from '../../services/ApiInstance'
import Header from '../../layout/Header'
import tableIcons from '../../styles/tableIcons'
import NewService from './NewService'
import EditService from './EditService'
import DeleteService from './DeleteService'
import styles from '../../styles/defaultStyles'
import localization from '../../locale/tableLocale'
import { ContractUpdateModal } from '../../components/ContractUpdateModal'

class Services extends React.Component {
  constructor() {
    super()
    this.state = {
      services: [],
      currentId: '',
      currentName: '',
      currentOver: 0,
      newOver: 1,
      planId: 0,
      showModal: true,
      newService: { open: false },
      editService: { open: false, data: {} },
      deleteService: { open: false, data: {} },
    }
  }

  componentDidMount() {
    this.fetchData()
  }

  formatServicesData(row) {
    return {
      id: row.id,
      name: row.name,
      warranty_card: row.warranty_card + ' vezes',
      adhesion_fee: 'R$ ' + row.adhesion_fee,
      administration_fee: row.administration_fee + ' %',
      cover_value: row.cover_value + ' vezes',
      total_fee: row.total_fee + ' %',
      updated_at: moment(row.updated_at).format('YYYY-MM-DD HH:mm:ss'),
      warranty_ucred: row.warranty_ucred === 1 ? 'Ucred' : 'Própria',
      over_value: row.over_value ? `${row.over_value} %` : '0 %',
      imob_gain: row.imob_gain,
      imob_gain_to_show: row.imob_gain ? `${row.imob_gain} %` : '0 %',
      type_imobile: row.type_imobile,
      editable: row.editable
    }
  }

  async fetchData() {
    try {
      const {
        data: { services },
        plan_id,
      } = await api.get(`servico`)
      const { renda } = await api.get('rendaFamiliar')
      this.setState({ services: services.map(this.formatServicesData), planId: plan_id, renda })
    } catch (error) {
      const snackbarKey = this.props.enqueueSnackbar('Erro inesperado, entre em contato com nosso suporte.', {
        variant: 'error',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
    }
    this.setState({ loading: false })
  }

  onClickRow(params) {
    const { history } = this.props
    if (params.type_imobile === 1) {
      const snackbarKey = this.props.enqueueSnackbar('Você não pode editar esse serviço.', {
        variant: 'success',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
      return false
    }
    history.push(`servicoUcredPlan`, { ...params })
  }

  newService() {
    this.props.history.push(`/servicos/criar`)
  }

  newServiceUcredPlan() {
    this.props.history.push(`/servicoUcredPlan`)
  }

  async handleRenda() {
    let { renda } = this.state
    let { fillRenda } = this.props
    let rendaAux
    if (renda === 'Y') rendaAux = 'N'
    if (renda === 'N') rendaAux = 'Y'
    this.setState({ renda: rendaAux })
    await api.post('alterarRendaFamiliar')
    fillRenda(rendaAux)
  }

  render() {
    const { services } = this.state
    const { user } = this.props
    const needUpdate = user.contractStatus === 'need_update'
    const contractUrl = user.contractUrl && user.contractUrl

    const noOverflow = {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      width: 300,
      maxWidth: 300,
      textOverflow: 'ellipsis',
    }

    const columns = []
    if (this.state.planId === 1) {
      columns.push(
        { field: 'name', title: 'Nome', visible: true, cellStyle: { ...noOverflow } },
        { field: 'administration_fee', title: 'Taxa Administrativa', visible: true, cellStyle: { ...noOverflow } },
        {
          field: 'updated_at',
          title: 'Última Alteração',
          visible: true,
          cellStyle: { ...noOverflow },
          type: 'datetime',
          dateSetting: { locale: 'pt-BR' },
        },
        {
          field: 'actions',
          title: 'Ações',
          export: false,
          visible: true,
          align: 'center',
          cellStyle: {
            ...noOverflow,
            display: 'flex',
            width: 200,
            justifyContent: 'center',
            height: 45,
            padding: 0,
            alignItems: 'center',
          },
        }
      )
    } else {
      columns.push(
        { field: 'name', title: 'Nome', visible: true, cellStyle: { ...noOverflow } },
        { field: 'total_fee', title: 'Taxa locatário', visible: true, cellStyle: { ...noOverflow } },
        { field: 'administration_fee', title: 'Taxa Ucred', visible: true, cellStyle: { ...noOverflow } },
        { field: 'imob_gain_to_show', title: 'Comissão', visible: true, cellStyle: { ...noOverflow } },
        { field: 'cover_value', title: 'Cobertura Ucred', visible: true, cellStyle: { ...noOverflow } },
        {
          field: 'updated_at',
          title: 'Última Alteração',
          visible: true,
          cellStyle: { ...noOverflow },
          type: 'datetime',
          dateSetting: { locale: 'pt-BR' },
        },
        {
          field: 'actions',
          title: 'Ações',
          export: false,
          visible: true,
          align: 'center',
          cellStyle: {
            ...noOverflow,
            display: 'flex',
            width: 200,
            justifyContent: 'center',
            height: 45,
            padding: 0,
            alignItems: 'center',
          },
        }
      )
    }

    services.map((service) => {
      const tooltipMessage = needUpdate ? 'É necessário assinar o novo contrato' : 'Editar'
      service.actions = (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Box style={{ position: 'relative', marginRight: 8 }}>
            <Tooltip title={tooltipMessage} arrow>
              <Fab
                onClick={async () => {
                  if (!service.editable) return
                  this.setState({ editService: { ...this.state.editService, open: true } })
                  this.EditService.handleOpen(service)
                }}
                disabled={service.loading || !service.editable || needUpdate}
                size="small"
                color="primary"
                style={{
                  width: 35,
                  height: 35,
                  minHeight: 'initial',
                  boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.20)',
                }}
              >
                <Edit style={{ width: 20 }} />
              </Fab>
            </Tooltip>
            {service.loading && (
              <CircularProgress
                size={24}
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: -12,
                  marginLeft: -12,
                }}
              />
            )}
          </Box>
          <Box style={{ display: 'flex', position: 'relative' }}>
            <Tooltip title="Deletar" arrow>
              <Fab
                onClick={async (event) => {
                  if (!service.editable) return
                  event.stopPropagation()
                  this.setState({ deleteService: { open: true, data: service } })
                }}
                disabled={service.loading || !service.editable}
                size="small"
                color="primary"
                style={{
                  backgroundColor: service.loading || !service.editable ? '' : styles.error,
                  width: 35,
                  height: 35,
                  minHeight: 'initial',
                  boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.20)',
                }}
              >
                <Delete style={{ width: 20 }}></Delete>
              </Fab>
            </Tooltip>
            {service.loading && (
              <CircularProgress
                size={24}
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: -12,
                  marginLeft: -12,
                }}
              />
            )}
          </Box>
        </div>
      )
      return void 0
    })

    return (
      <>
        <Header />
        <Container
          maxWidth="lg"
          style={{
            paddingTop: 32,
            marginTop: 80,
            marginBottom: 32
          }}
        >
          <Grid spacing={3} container>
            <Grid item xs={12}>
              <Breadcrumbs className="breadcrumbs">
                <Link to="/" style={{ display: 'flex' }}>
                  <Home
                    style={{
                      marginRight: 8,
                      width: 20,
                      height: 20,
                    }}
                  />
                  Início
                </Link>
                <Typography color="textPrimary">Serviços</Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item xs={12}>
              <MaterialTable
                data={services}
                columns={columns}
                options={{
                  detailPanelType: 'single',
                  pageSize: 15,
                  pageSizeOptions: [15, 50, 100],
                  tableLayout: 'auto',
                  showTitle: true,
                  padding: 'dense',
                  exportAllData: true,
                  exportFileName: 'Relatório Ucred (Serviços) - ' + moment().format('DD-MM-YYYY H:mm:ss'),
                  exportButton: true,
                  actionsColumnIndex: 0,
                }}
                components={{ Container: (props) => <div id="mtable-container" {...props}></div> }}
                icons={tableIcons}
                title="Relatório de serviços"
                localization={localization}
              />
            </Grid>
          </Grid>
        </Container>
        <NewService
          open={this.state.newService.open}
          parent={this}
          ref={(reference) => (this.NewService = reference)}
        />
        <EditService
          open={this.state.editService.open}
          parent={this}
          ref={(reference) => (this.EditService = reference)}
        />
        <DeleteService
          open={this.state.deleteService.open}
          parent={this}
          ref={(reference) => (this.DeleteService = reference)}
        />
        {needUpdate && this.state.showModal && <ContractUpdateModal contractUrl={contractUrl} isPersistent />}
        {!needUpdate && (
          <Fab
            onClick={() => {
              this.setState({ newService: { ...this.state.newService, open: true } })
            }}
            color="primary"
            style={{ position: 'fixed', bottom: 90, right: 22 }}
          >
            <Add />
          </Fab>
        )}
      </>
    )
  }
}

const mapStateToProps = ({ authReducer: { authUser } }) => ({ user: { ...authUser } })

export default withSnackbar(connect(mapStateToProps)(Services))
