import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import moment from 'moment'

const _Table: any = Table

export default function ConfirmModal(props: {
  confirm: boolean
  value: number
  setConfirm: (value: boolean) => void
  adhesion: any[]
  adhesionParcel: number
  loading: boolean
  handleSubmit: () => void
}) {
  const getParcelValue = (parcel: number) => {
    if (parcel <= props.adhesionParcel) {
      return (props?.adhesion[0]?.value || 0) / props.adhesionParcel
    } else {
      return 0
    }
  }

  return (
    <Dialog open={props.confirm}>
      <DialogTitle
        disableTypography
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6">Pagamento recorrente</Typography>
        <IconButton
          size="small"
          style={{ marginLeft: 'auto' }}
          onClick={() => props.setConfirm(false)}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          O método de pagamento via cartão de crédito irá cobrar mensalmente os valores em seu
          cartão, portanto, certifique-se de disponibilizar limite em sua fatura.
        </DialogContentText>
        <Box style={{ display: 'flex' }} className="parcel-column">
          <TableContainer component={Paper}>
            <_Table sx={{ minWidth: 650 }} size="small">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Valor</TableCell>
                  <TableCell>Cobrança</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow>
                  <TableCell>1ª</TableCell>
                  <TableCell>
                    R${(props.value / 12 + getParcelValue(1)).toFixed(2).replace('.', ',')}
                  </TableCell>
                  <TableCell>{moment().format('DD/MM/YYYY')}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>2ª</TableCell>
                  <TableCell>
                    R${(props.value / 12 + getParcelValue(2)).toFixed(2).replace('.', ',')}
                  </TableCell>
                  <TableCell>{moment().add(1, 'months').format('DD/MM/YYYY')}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>3ª</TableCell>
                  <TableCell>
                    R${(props.value / 12 + getParcelValue(3)).toFixed(2).replace('.', ',')}
                  </TableCell>
                  <TableCell>{moment().add(2, 'months').format('DD/MM/YYYY')}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>4ª</TableCell>
                  <TableCell>R${(props.value / 12).toFixed(2).replace('.', ',')}</TableCell>
                  <TableCell>{moment().add(3, 'months').format('DD/MM/YYYY')}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>5ª</TableCell>
                  <TableCell>R${(props.value / 12).toFixed(2).replace('.', ',')}</TableCell>
                  <TableCell>{moment().add(4, 'months').format('DD/MM/YYYY')}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>6ª</TableCell>
                  <TableCell>R${(props.value / 12).toFixed(2).replace('.', ',')}</TableCell>
                  <TableCell>{moment().add(5, 'months').format('DD/MM/YYYY')}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>7ª</TableCell>
                  <TableCell>R${(props.value / 12).toFixed(2).replace('.', ',')}</TableCell>
                  <TableCell>{moment().add(6, 'months').format('DD/MM/YYYY')}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>8ª</TableCell>
                  <TableCell>R${(props.value / 12).toFixed(2).replace('.', ',')}</TableCell>
                  <TableCell>{moment().add(7, 'months').format('DD/MM/YYYY')}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>9ª</TableCell>
                  <TableCell>R${(props.value / 12).toFixed(2).replace('.', ',')}</TableCell>
                  <TableCell>{moment().add(8, 'months').format('DD/MM/YYYY')}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>10ª</TableCell>
                  <TableCell>R${(props.value / 12).toFixed(2).replace('.', ',')}</TableCell>
                  <TableCell>{moment().add(9, 'months').format('DD/MM/YYYY')}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>11ª</TableCell>
                  <TableCell>R${(props.value / 12).toFixed(2).replace('.', ',')}</TableCell>
                  <TableCell>{moment().add(10, 'months').format('DD/MM/YYYY')}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>12ª</TableCell>
                  <TableCell>R${(props.value / 12).toFixed(2).replace('.', ',')}</TableCell>
                  <TableCell>{moment().add(11, 'months').format('DD/MM/YYYY')}</TableCell>
                </TableRow>
              </TableBody>
            </_Table>
          </TableContainer>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={props.loading}
          variant="outlined"
          color="primary"
          onClick={() => props.setConfirm(false)}
        >
          Fechar
        </Button>
        <Box style={{ display: 'flex', position: 'relative' }}>
          <Button
            disabled={props.loading}
            onClick={() => props.handleSubmit()}
            color="primary"
            variant="contained"
          >
            Continuar
          </Button>
          {props.loading && (
            <CircularProgress
              size={24}
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: -12,
                marginLeft: -12,
              }}
            />
          )}
        </Box>
      </DialogActions>
    </Dialog>
  )
}
