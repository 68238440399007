import { Backdrop, Grid, Slide, Step, StepLabel, Stepper } from '@material-ui/core'
import Lottie from 'lottie-react-web'
import React from 'react'
import { connect } from 'react-redux'
import { fillField, refresh } from '../../redux/actions/creditAnalysisActions'
import HouseLoading from '../../static/animations/house-loading.json'
import Step1 from './Step1'
import Step1UcredPlan from './Step1UcredPlan'
import Step2 from './Step2'
import Step2UcredPlan from './Step2UcredPlan'
import Step3 from './Step3'
import StepExtra from './StepExtra'

class StepContainer extends React.Component {
  constructor() {
    super()
    this.state = {
      loading: false,
      step: 1,
    }
  }

  nextStep = () => {
    const { fillField, step } = this.props
    this.setState({ step: step + 1 })
    fillField('step', step + 1)
  }

  changeLoading = (status) => {
    this.setState({ loading: status })
  }

  back = () => {
    const { fillField, step } = this.props
    this.setState({ step: step - 1 })
    fillField('step', step - 1)
  }

  render() {
    const { loading } = this.state
    let { authUser, step } = this.props
    const steps = ['Análise de crédito', 'Dados iniciais', 'Dados da transação', 'Resumo final']
    const planId = authUser ? authUser.plan_id : 2

    if (planId !== 2 && planId !== 4) {
      return (
        <React.Fragment>
          <div className="step-timeLine">
            <div className="step__timeline">
              <div className={step >= 1 ? 'step__timeline-number step__timeline-number--active' : 'step__timeline-number'}>1</div>
              <div className={step >= 2 ? ' step__timeline-bar step__timeline-bar--active' : 'step__timeline-bar'}></div>
              <div className={step >= 2 ? 'step__timeline-number step__timeline-bar--active' : 'step__timeline-number'}>2</div>
              <div className={step >= 3 ? 'step__timeline-bar step__timeline-bar--active' : 'step__timeline-bar'}></div>
              <div className={step >= 3 ? 'step__timeline-number step__timeline-bar--active' : 'step__timeline-number'}>3</div>
            </div>
          </div>
          {step === 1 ? <Step1 nextStep={this.nextStep} changeLoading={this.changeLoading} /> : <div></div>}
          {step === 2 ? <Step2 nextStep={this.nextStep} back={this.back} changeLoading={this.changeLoading} loading={loading} /> : <div></div>}
          {step === 3 ? <Step3 nextStep={this.nextStep} back={this.back} changeLoading={this.changeLoading} loading={loading} /> : <div></div>}
          {loading && (
            <div className="container-loading">
              <span>Carregando</span>
              <img src="https://jwdnhost.com/assets/images/property-loader.gif" />
            </div>
          )}
        </React.Fragment>
      )
    } else {
      return (
        <>
          <Grid container spacing={3}>
            <Grid className="print-hidden" item xs={12}>
              <Stepper style={{ background: 'none' }} activeStep={step - 1} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Grid>
            <Grid item xs={12} style={{ position: 'relative' }}>
              <Slide direction={step === 1 ? 'left' : 'right'} in={step === 1} timeout={{ appear: 500, enter: 500, exit: 300 }} unmountOnExit={true}>
                <div>
                  <Step1UcredPlan parent={this} nextStep={this.nextStep} changeLoading={this.changeLoading} authUser={authUser} />
                </div>
              </Slide>

              <Slide direction={step === 2 ? 'left' : 'right'} in={step === 2} timeout={{ appear: 500, enter: 500, exit: 300 }} unmountOnExit={true}>
                <div style={{ position: 'absolute', width: '100%' }}>
                  <StepExtra parent={this} nextStep={this.nextStep} back={this.back} changeLoading={this.changeLoading} loading={loading} />
                </div>
              </Slide>

              <Slide direction={step === 3 ? 'left' : 'right'} in={step === 3} timeout={{ appear: 500, enter: 500, exit: 300 }} unmountOnExit={true}>
                <div style={{ width: '100%' }}>
                  <Step2UcredPlan parent={this} nextStep={this.nextStep} back={this.back} changeLoading={this.changeLoading} loading={loading} />
                </div>
              </Slide>

              <Slide direction={step === 4 ? 'left' : 'right'} in={step === 4} timeout={{ appear: 500, enter: 500, exit: 300 }} unmountOnExit={true}>
                <div style={{ width: '100%' }}>
                  <Step3 parent={this} nextStep={this.nextStep} back={this.back} changeLoading={this.changeLoading} loading={loading} />
                </div>
              </Slide>

              <Backdrop style={{ zIndex: 50000 }} open={loading}>
                <Lottie
                  width="200px"
                  speed={1}
                  options={{
                    animationData: HouseLoading,
                    autoplay: true,
                    loop: true,
                  }}
                ></Lottie>
              </Backdrop>
            </Grid>
          </Grid>
        </>
      )
    }
  }
}

function mapStateToProps({ creditAnalysis, authReducer }) {
  return {
    ...creditAnalysis,
    ...authReducer,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fillField: (field, value) => dispatch(fillField(field, value)),
    refresh: () => dispatch(refresh()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StepContainer)
