import { Box, Center, RadioGroup, Spinner, Text } from '@chakra-ui/react'
import React, { useState, useEffect, useContext } from 'react'
import { PaymentMethodButton } from '../components/PaymentMethodButton'
import BankSlipPayment from './BankSlipPayment'
import CreditCardPayment from './CreditCardPayment'
import TwoCreditCardPayment from './TwoCreditCardPayment'
import ChangePaymentMethodContext from '../../../contexts/ChangePaymentMethod'
import HybridPayment from './HybridPayment'

function PaymentMethodSelect() {
  const [title, setTitle] = useState('Selecione o novo método de pagamento')
  const { renovationData, selectedPaymentMethod, setSelectedPaymentMethod, loading } = useContext(
    ChangePaymentMethodContext,
  ) as unknown as CreatePaymentMethod

  useEffect(() => {
    switch (selectedPaymentMethod) {
      case null:
        setTitle('Selecione o novo método de pagamento')
        break
      case 1:
        setTitle('Insira os dados do seu cartão')
        break
      case 2:
        setTitle('Insira os dados do seu cartão')
        break
      case 3:
        setTitle('Insira os dados do seu cartão')
        break
      case 4:
        setTitle('Insira os dados do seu cartão')
        break
    }
  }, [selectedPaymentMethod])

  const isDiffPerson = () => {
    if (renovationData) {
      const purchase = renovationData.purchase
      const corresponsiblePurchase: Purchase | undefined = renovationData.corresponsible_purchase

      if (
        !corresponsiblePurchase?.client_id ||
        purchase.client_id === corresponsiblePurchase?.client_id
      ) {
        return true
      }

      return false
    }
  }

  return (
    <Box>
      <Text fontSize="sm" fontWeight="bold" mt={{ base: 3, md: 5 }} ml={{ base: 6, md: 4 }}>
        {title}
      </Text>

      {selectedPaymentMethod === 1 && <BankSlipPayment />}

      {selectedPaymentMethod === 2 && <CreditCardPayment />}

      {selectedPaymentMethod === 3 && <TwoCreditCardPayment />}

      {selectedPaymentMethod === 4 && <HybridPayment />}

      {!selectedPaymentMethod && (
        <>
          {loading ? (
            <Center minHeight={300}>
              <Spinner />
            </Center>
          ) : (
            <RadioGroup value={selectedPaymentMethod || 0}>
              <PaymentMethodButton
                style={{ cursor: 'pointer' }}
                label={'Pague com boleto'}
                value={1}
                img={() => (
                  <img
                    alt="icone cartao"
                    src={
                      'https://res.cloudinary.com/ucred/image/upload/c_scale,h_26,w_38/v1626719139/icons/ic_bar_code.png'
                    }
                  />
                )}
                secondImg={() => <></>}
                selectedPaymentMethod={selectedPaymentMethod}
                setSelectedPaymentMethod={setSelectedPaymentMethod}
              />

              <PaymentMethodButton
                style={{ cursor: 'pointer' }}
                label={'Pague com cartão'}
                value={2}
                img={() => (
                  <img
                    alt="icone cartao"
                    src={
                      'https://res.cloudinary.com/ucred/image/upload/c_scale,h_38,w_38/v1626719139/icons/ic_credit_card_2.png'
                    }
                  />
                )}
                secondImg={() => <></>}
                selectedPaymentMethod={selectedPaymentMethod}
                setSelectedPaymentMethod={setSelectedPaymentMethod}
              />

              {isDiffPerson() && (
                <>
                  <PaymentMethodButton
                    style={{ cursor: 'pointer' }}
                    label={'Pague com 2 cartões'}
                    value={3}
                    img={() => (
                      <img
                        alt="icone cartao"
                        src={
                          'https://res.cloudinary.com/ucred/image/upload/c_scale,h_38,w_38/v1626719139/icons/ic_credit_card_2.png'
                        }
                      />
                    )}
                    secondImg={() => (
                      <img
                        style={{ transform: 'translate(32px, 6px)' }}
                        alt="icone boleto"
                        src={
                          'https://res.cloudinary.com/ucred/image/upload/c_scale,h_38,w_38/v1626719139/icons/ic_credit_card_2.png'
                        }
                      />
                    )}
                    selectedPaymentMethod={selectedPaymentMethod}
                    setSelectedPaymentMethod={setSelectedPaymentMethod}
                  />

                  <PaymentMethodButton
                    style={{ cursor: 'pointer' }}
                    label={'Pague com cartão + boleto'}
                    value={4}
                    img={() => (
                      <img
                        alt="icone cartao"
                        src={
                          'https://res.cloudinary.com/ucred/image/upload/c_scale,h_26,w_38/v1626719139/icons/ic_bar_code.png'
                        }
                      />
                    )}
                    secondImg={() => (
                      <img
                        style={{ transform: 'translate(32px, 6px)' }}
                        alt="icone boleto"
                        src={
                          'https://res.cloudinary.com/ucred/image/upload/c_scale,h_38,w_38/v1626719139/icons/ic_credit_card_2.png'
                        }
                      />
                    )}
                    selectedPaymentMethod={selectedPaymentMethod}
                    setSelectedPaymentMethod={setSelectedPaymentMethod}
                  />
                </>
              )}
            </RadioGroup>
          )}
        </>
      )}
    </Box>
  )
}

export default PaymentMethodSelect
