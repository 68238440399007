import { Backdrop, Box, Button, ButtonBase, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@material-ui/core'
import Lottie from 'lottie-react-web'
import { withSnackbar } from 'notistack'
import React, { Component } from 'react'
import ApiInstance from '../../services/ApiInstance'
import HouseLoading from '../../static/animations/house-loading.json'
import Angry from '../../static/svg/feedback/angry.svg'
import Clock from '../../static/svg/feedback/clock.svg'
import Fear from '../../static/svg/feedback/fear.svg'
import Happy from '../../static/svg/feedback/happy.svg'
import Sad from '../../static/svg/feedback/sad.svg'
import Wallet from '../../static/svg/feedback/wallet.svg'
import Other from '../../static/svg/feedback/other.svg'
class RatingComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      answers: {
        emotion: '',
        choose: '',
        imob_rating: '',
        recommend: '',
        chooseSpecification: '',
      },
      type: 'location_feedback',
      observation: '',
    }
  }

  componentDidMount() {
    this.setState({ loading: false })
  }

  render() {
    const { answers, type, observation, loading, preAnswerRecommend, preAnswerImobRating } = this.state
    const AnswerOne = [
      {
        Icon: <img style={{ display: 'flex', margin: '0 auto' }} src={Angry} />,
        label: 'Raiva',
      },
      {
        Icon: <img style={{ display: 'flex', margin: '0 auto' }} src={Fear} />,
        label: 'Medo',
      },
      {
        Icon: <img style={{ display: 'flex', margin: '0 auto' }} src={Sad} />,
        label: 'Triste',
      },
      {
        Icon: <img style={{ display: 'flex', margin: '0 auto' }} src={Happy} />,
        label: 'Feliz',
      },
    ]

    const AnswerTwo = [
      {
        Icon: <img style={{ display: 'flex', margin: '0 auto', width: 40 }} src={Clock} />,
        label: 'Praticidade',
      },
      {
        Icon: <img style={{ display: 'flex', margin: '0 auto', width: 40 }} src={Wallet} />,
        label: 'Custo',
      },
      {
        Icon: <img style={{ display: 'flex', margin: '0 auto', width: 40 }} src={Other} />,
        label: 'Outros',
      },
    ]

    const AnswerThree = [
      {
        Icon: 1,
        label: '1',
      },
      {
        Icon: 2,
        label: '2',
      },
      {
        Icon: 3,
        label: '3',
      },
      {
        Icon: 4,
        label: '4',
      },
      {
        Icon: 5,
        label: '5',
      },
      {
        Icon: 6,
        label: '6',
      },
      {
        Icon: 7,
        label: '7',
      },
      {
        Icon: 8,
        label: '8',
      },
      {
        Icon: 9,
        label: '9',
      },
      {
        Icon: 10,
        label: '10',
      },
    ]

    const AnswerFour = [
      {
        Icon: 1,
        label: '1',
      },
      {
        Icon: 2,
        label: '2',
      },
      {
        Icon: 3,
        label: '3',
      },
      {
        Icon: 4,
        label: '4',
      },
      {
        Icon: 5,
        label: '5',
      },
      {
        Icon: 6,
        label: '6',
      },
      {
        Icon: 7,
        label: '7',
      },
      {
        Icon: 8,
        label: '8',
      },
      {
        Icon: 9,
        label: '9',
      },
      {
        Icon: 10,
        label: '10',
      },
    ]

    const handleSubmit = async (event) => {
      event.preventDefault()
      const urlParams = new URLSearchParams(window.location.search)
      const hash = urlParams.get('hash')

      if (!answers.emotion) {
        const snackbarKey = this.props.enqueueSnackbar('Responda a sua emoção selecionando um dos smiles.', {
          variant: 'error',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        })
        return
      }

      if (!answers.choose) {
        const snackbarKey = this.props.enqueueSnackbar('Mostre por que você selecionou a garantia da Ucred.', {
          variant: 'error',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        })
        return
      }

      if (!answers.imob_rating) {
        const snackbarKey = this.props.enqueueSnackbar('De uma nota para o atendimento fornecido pela imobiliária.', {
          variant: 'error',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        })
        return
      }

      if (!answers.recommend) {
        const snackbarKey = this.props.enqueueSnackbar('Qual a probabilidade de recomendar a Ucred para um amigo ou colega?', {
          variant: 'error',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        })
        return
      }

      if (answers.choose === 'Outros' && !answers.chooseSpecification) {
        const snackbarKey = this.props.enqueueSnackbar('Especifique o que lhe fez optar pela Ucred.', {
          variant: 'error',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        })
        return
      }

      this.setState({ loading: true })
      await ApiInstance.post('location_feedback', {
        answers: { ...answers, chooseSpecification: answers.choose === 'Outros' ? answers.chooseSpecification : '' },
        type,
        observation,
        hash,
      })
        .then(() => {
          const snackbarKey = this.props.enqueueSnackbar('Obrigado por sua contribuição!', {
            variant: 'success',
            onClick: () => {
              this.props.closeSnackbar(snackbarKey)
            },
          })
          window.location.href = 'https://ucred.io'
        })
        .catch(() => {
          const snackbarKey = this.props.enqueueSnackbar('Erro inesperado, entre em contato com nosso suporte.', {
            variant: 'error',
            onClick: () => {
              this.props.closeSnackbar(snackbarKey)
            },
          })
          this.setState({ loading: false })
        })
    }

    return (
      <div id="feedback">
        <Container>
          <Backdrop style={{ zIndex: 50000 }} open={loading}>
            <Lottie
              style={{ opacity: 1 }}
              width="200px"
              speed={1}
              options={{
                animationData: HouseLoading,
                autoplay: true,
                loop: true,
              }}
            ></Lottie>
          </Backdrop>
          <Dialog disableScrollLock open={true}>
            <DialogTitle
              disableTypography
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              Envie-nos um feedback
            </DialogTitle>
            <DialogContent dividers>
              <Grid container spacing={3} align="center">
                <Grid item xs={12} align="center">
                  <Typography align="center">Qual emoção melhor descreve sua experiência ao alugar um imóvel com a garantia Ucred?</Typography>
                  <Box style={{ display: 'flex', justifyContent: 'center' }}>
                    {AnswerOne.map((value, index) => {
                      return (
                        <ButtonBase
                          key={index}
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            cursor: 'pointer',
                            border: `2px solid ${answers.emotion === value.label ? '#009fff' : 'rgba(0,0,0,0)'}`,
                            borderRadius: 8,
                            transition: '.2s',
                            width: 80,
                            height: 70,
                          }}
                          onClick={() => this.setState({ answers: { ...answers, emotion: value.label } })}
                        >
                          {value.Icon}
                          <Typography align="center">{value.label}</Typography>
                        </ButtonBase>
                      )
                    })}
                  </Box>
                </Grid>

                <Grid item xs={12} align="center">
                  <Typography gutterBottom align="center">
                    Porque você escolheu a garantia Ucred?
                  </Typography>
                  <Box style={{ display: 'flex', justifyContent: 'center', marginBottom: 8 }}>
                    {AnswerTwo.map((value, index) => {
                      return (
                        <ButtonBase
                          key={index}
                          p={1}
                          m={1}
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            cursor: 'pointer',
                            border: `2px solid ${answers.choose === value.label ? '#009fff' : 'rgba(0,0,0,0)'}`,
                            borderRadius: 8,
                            transition: '.2s',
                            width: 120,
                            height: 80,
                          }}
                          onClick={() => this.setState({ answers: { ...answers, choose: value.label } })}
                        >
                          {value.Icon}
                          <Typography align="center">{value.label}</Typography>
                        </ButtonBase>
                      )
                    })}
                  </Box>
                  {answers.choose === 'Outros' && (
                    <Box style={{ display: 'flex', justifyContent: 'center' }}>
                      <TextField
                        value={answers.chooseSpecification}
                        onChange={(event) => this.setState({ answers: { ...answers, chooseSpecification: event.target.value } })}
                        fullWidth
                        variant="outlined"
                        label="Especifique o motivo da escolha"
                      ></TextField>
                    </Box>
                  )}
                </Grid>

                <Grid item xs={12} align="center">
                  <Typography align="center" gutterBottom>
                    Qual nota você daria para o atendimento da imobiliária?{' '}
                  </Typography>
                  <ButtonBase style={{ border: `2px solid #009fff`, borderRadius: 40, overflow: 'hidden', width: 284 }}>
                    {AnswerThree.map((value, index) => {
                      return (
                        <Box
                          component="span"
                          key={index}
                          p={1}
                          onMouseOver={() => this.setState({ preAnswerImobRating: value.label })}
                          onMouseOut={() => this.setState({ preAnswerImobRating: 0 })}
                          style={{
                            width: 28,
                            cursor: 'pointer',
                            background: `${value.Icon <= answers.imob_rating && !preAnswerImobRating ? '#009fff' : value.Icon <= preAnswerImobRating ? '#009fff' : ''}`,
                            borderRadius: value.Icon == answers.imob_rating && !preAnswerImobRating ? '0 40px 40px 0' : value.Icon == preAnswerImobRating ? '0 40px 40px 0' : '',
                            padding: '6px',
                          }}
                          onClick={() => this.setState({ answers: { ...answers, imob_rating: value.label } })}
                        >
                          <Typography
                            align="center"
                            onMouseEnter={() => this.setState({ preAnswerImobRating: value.label })}
                            onMouseOut={() => this.setState({ preAnswerImobRating: 0 })}
                            style={{
                              fontSize: '10pt',
                              color: value.Icon <= answers.imob_rating && !preAnswerImobRating ? '#fff' : value.Icon <= preAnswerImobRating ? '#fff' : '',
                            }}
                          >
                            {value.Icon}
                          </Typography>
                        </Box>
                      )
                    })}
                  </ButtonBase>
                </Grid>

                <Grid item xs={12}>
                  <Typography align="center" gutterBottom>
                    Qual a probabilidade de você recomendar a garantia da Ucred para um amigo?
                  </Typography>
                  <ButtonBase style={{ border: `2px solid #009fff`, borderRadius: 40, overflow: 'hidden', width: 284 }}>
                    {AnswerFour.map((value, index) => {
                      return (
                        <Box
                          component="span"
                          key={index}
                          p={1}
                          onMouseOver={() => this.setState({ preAnswerRecommend: value.label })}
                          onMouseOut={() => this.setState({ preAnswerRecommend: 0 })}
                          onClick={() => this.setState({ answers: { ...answers, recommend: value.label } })}
                          style={{
                            width: 28,
                            cursor: 'pointer',
                            background: `${value.Icon <= answers.recommend && !preAnswerRecommend ? '#009fff' : value.Icon <= preAnswerRecommend ? '#009fff' : ''}`,
                            borderRadius: value.Icon == answers.recommend && !preAnswerRecommend ? '0 40px 40px 0' : value.Icon == preAnswerRecommend ? '0 40px 40px 0' : '',
                            padding: '6px',
                          }}
                        >
                          <Typography
                            align="center"
                            onMouseEnter={() => this.setState({ preAnswerRecommend: value.label })}
                            onMouseOut={() => this.setState({ preAnswerRecommend: 0 })}
                            style={{
                              fontSize: '10pt',
                              color: value.Icon <= answers.recommend && !preAnswerRecommend ? '#fff' : value.Icon <= preAnswerRecommend ? '#fff' : '',
                            }}
                          >
                            {value.Icon}
                          </Typography>
                        </Box>
                      )
                    })}
                  </ButtonBase>
                </Grid>

                <Grid item xs={12}>
                  <Typography gutterBottom align="center">
                    Trabalhamos para que você tenha uma experiência cada vez melhor ao alugar um imóvel, e sua opinião é essencial para que isso ocorra. Você pode nos contar os motivos das suas
                    respostas anteriores e como podemos melhorar?
                  </Typography>
                  <TextField
                    fullWidth
                    label="Observação"
                    rows={4}
                    multiline
                    variant="outlined"
                    onChange={(event) => {
                      this.setState({ observation: event.target.value })
                    }}
                  ></TextField>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleSubmit} variant="contained" color="primary">
                Enviar
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
      </div>
    )
  }
}

export default withSnackbar(RatingComponent)
