const localization = {
  header: {
    actions: 'Ações',
  },
  body: {
    emptyDataSourceMessage: 'Nenhum registro para exibir',
  },
  toolbar: {
    exportTitle: 'Exportar',
    searchTooltip: 'Pesquisar',
    searchPlaceholder: 'Pesquisar',
    exportCSVName: 'Exportar em CSV',
    exportPDFName: 'Exportar em PDF',
  },
  pagination: {
    labelRowsSelect: 'linhas',
    labelDisplayedRows: '{from}-{to} de {count}',
    firstTooltip: 'Primeira página',
    previousTooltip: 'Página anterior',
    nextTooltip: 'Próxima página',
    lastTooltip: 'Última página',
  },
}

module.exports = localization
