import { useState } from 'react'
import { Button, Center, Circle, Flex, Spinner, Stack, Text, useToast } from '@chakra-ui/react'
import { MdFileDownload } from 'react-icons/md'

import ApiInstance from '../../../services/ApiInstance'
import { Card } from '../components/Card'
import { LabelInfo } from '../components/LabelInfo'

import { ContractStatusBadge } from '../components/Badge/ContractStatusBadge'
import { ContractType } from '../types/contract'

interface ContratProps {
  contract: ContractType
}

export function Contract({ contract }: ContratProps) {
  const [loading, setLoading] = useState(false)
  const toast = useToast()
  const {
    startDateFormatted,
    endDateFormatted,
    globalStatus,
    documentKey,
    transactionValue,
    coverParcel,
    coverValue,
  } = contract
  const contractSigned = globalStatus === 'signed'

  async function handleDownloadContract() {
    setLoading(true)
    try {
      const response = await ApiInstance.get(`downloadContract/${documentKey}`)
      const contractUrl = response.data.signed_file_url
      window.open(contractUrl, 'Blank')
      setLoading(false)
    } catch {
      toast({
        title: 'Houve um erro ao baixar o contrato.',
        status: 'error',
        duration: 2000,
        isClosable: true,
      })
      setLoading(false)
    }
  }

  return (
    <Card bg="gray.50" boxShadow="sm" mt={16}>
      <Stack spacing="8">
        <Flex justify="space-between">
          <Flex align="center">
            <Text mr={3} fontSize="xl" color="gray.600">
              Contrato Locatário/Ucred
            </Text>
            {<ContractStatusBadge status={globalStatus} />}
          </Flex>
          {contractSigned && (
            <Button
              alignItems="center"
              justifyContent="center"
              title="Baixar contrato"
              onClick={handleDownloadContract}
              colorScheme="blue"
              size="sm"
              variant="ghost"
              paddingRight={0}
              borderRadius={20}
            >
              {loading ? (
                <Center>
                  <Text color="blue.400" mr={8}>
                    Carregando
                  </Text>
                  <Circle size="8" bg="blue.50" color="white">
                    <Spinner color="blue.400" />
                  </Circle>
                </Center>
              ) : (
                <Center>
                  <Text mr={2}>Baixar contrato</Text>
                  <Circle size="8" bg="blue.500" color="white" boxShadow="md">
                    <MdFileDownload size={20} />
                  </Circle>
                </Center>
              )}
            </Button>
          )}
        </Flex>
        <Flex justify="space-between">
          <LabelInfo label="Início da Garantia" info={startDateFormatted} />
          <LabelInfo label="Fim da Garantia" info={endDateFormatted} />
          <LabelInfo label="Valor da Transação" info={transactionValue} />
          <LabelInfo label="Cobertura Ucred" info={`${coverParcel} vezes`} />
          <LabelInfo label="Valor da cobertura" info={coverValue} />
        </Flex>
      </Stack>
    </Card>
  )
}
