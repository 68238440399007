/* eslint-disable camelcase */
import moment from 'moment'
import { Chip } from '@material-ui/core'
import { getOverallStatus } from '../../util/getOverallStatus'
import { PurchaseType } from '../TransactionDetails/types/response/purchase'
import { ReactNode } from 'react'

export interface PurchaseFormattedProps extends PurchaseType {
  status_column: ReactNode
  status: number
  status_filter: string
  status_formatted: string
  totalValue: number
  anticipation: number
  anticipation_formatted: string
  dt_inicio: string
  dt_fim: string
  warranty_ucred: number
  warranty_ucred_formatted: string
  id_cliente?: string | number
}

function formatPurchases(purchases: PurchaseFormattedProps[]) {
  return purchases.map((purchase: PurchaseFormattedProps) => {
    purchase.total_fee = purchase.total_fee + ' %'
    purchase.administration_fee = purchase.administration_fee + ' %'
    purchase.imob_gain = Number(purchase.imob_gain).toFixed(2) + ' %'
    purchase.cover_value = purchase.cover_value + ' vezes'

    const { title, background } = getOverallStatus(
      purchase.status,
      purchase.contract_status,
      purchase.corresponsible_purchase?.purchase_status_id,
      purchase.corresponsible_purchase?.contract?.status,
    )
    if (title === 'Invalid status') {
      console.log({
        status: purchase.status,
        contract: purchase.contract_status,
        c_status: purchase.corresponsible_purchase?.purchase_status_id,
        c_contract: purchase.corresponsible_purchase?.contract?.status,
      })
    }

    purchase.status_filter = title

    purchase.status_column = (
      <Chip style={{ color: '#fff', background }} size="small" label={title} />
    )
    purchase.status_formatted = title
    purchase.totalValue = Number(purchase.totalValue)
    if (purchase.anticipation === 1) purchase.anticipation_formatted = 'Não Solicitada'
    if (purchase.anticipation === 2) purchase.anticipation_formatted = 'Aguardando'
    if (purchase.anticipation === 3) purchase.anticipation_formatted = 'Antecipada'
    purchase.dt_inicio = moment(purchase.dt_inicio).format('YYYY-MM-DD')
    purchase.dt_fim = moment(purchase.dt_fim).format('YYYY-MM-DD')
    purchase.warranty_ucred_formatted = purchase.warranty_ucred === 1 ? 'Ucred' : 'própria'
    return purchase
  })
}

export { formatPurchases }
