import { QuestionOutlineIcon } from '@chakra-ui/icons'
import {
  FormLabel,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Select,
  Tooltip,
} from '@chakra-ui/react'
import moment from 'moment'
import React, { useContext } from 'react'
import MaskedInput from 'react-input-mask'
import CreditCardInput from '../../../../components/creditCardInput'
import CurrencyInput from '../../../../components/CurrencyInput'
import ChangePaymentMethodContext from '../../../../contexts/ChangePaymentMethod'
import ChakraMaskedInput from '../../../../components/maskedChakraInput'

export default function FirstCreditCardElements() {
  const { mainPaymentMethod, changeMainCreditCard, calculateValue, errors } = useContext(
    ChangePaymentMethodContext,
  ) as unknown as CreatePaymentMethod

  return (
    <Grid templateColumns="repeat(12, 1fr)" gap={3}>
      {/* ---------------------------------- NOME ---------------------------------- */}
      <GridItem colSpan={12}>
        <FormLabel
          color="gray.500"
          fontSize="xs"
          fontWeight="semibold"
          style={{ textTransform: 'uppercase' }}
          mb={0}
          pl={2}
        >
          Nome completo
        </FormLabel>
        <Input
          id="main_holder_name"
          name="name"
          error={errors.filter(value => value.field === 'main_holder_name').length}
          placeholder="Nome"
          disabled
          value={mainPaymentMethod.name}
          onChange={event => changeMainCreditCard(event, false)}
        />
      </GridItem>

      {/* --------------------------------- NÚMERO --------------------------------- */}
      <GridItem colSpan={12}>
        <FormLabel
          color="gray.500"
          fontSize="xs"
          fontWeight="semibold"
          style={{ textTransform: 'uppercase' }}
          mb={0}
          pl={2}
        >
          Número do cartão
        </FormLabel>
        <CreditCardInput
          autoFocus
          id="main_card_number"
          name="number"
          isInvalid={errors.filter(value => value.field === 'main_card_number').length && true}
          placeholder="Número"
          value={mainPaymentMethod.number}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            changeMainCreditCard(event, false)
          }
        />
      </GridItem>

      {/* ----------------------------------- CVV ---------------------------------- */}
      <GridItem colSpan={6}>
        <FormLabel
          color="gray.500"
          fontSize="xs"
          fontWeight="semibold"
          style={{ textTransform: 'uppercase' }}
          mb={0}
          pl={2}
        >
          Cvv
        </FormLabel>
        <InputGroup>
          <ChakraMaskedInput
            as={MaskedInput}
            id="main_card_cvv"
            name="cvv"
            isInvalid={!!errors.filter(value => value.field === 'main_card_cvv').length}
            mask="9999"
            maskChar=""
            placeholder="CVV"
            value={mainPaymentMethod.cvv}
            onChange={event => changeMainCreditCard(event, false)}
          />
          <InputRightElement
            /* eslint-disable-next-line react/no-children-prop */
            children={
              <Tooltip
                label="O número código de segurança é representado pelos três últimos dígitos no verso do seu cartão. Em cartões American Express, o código de segurança é um número de 4 dígitos na frente do cartão."
                fontSize="md"
              >
                <QuestionOutlineIcon color="gray.500" />
              </Tooltip>
            }
          />
        </InputGroup>
      </GridItem>

      {/* -------------------------------- VALIDADE -------------------------------- */}
      <GridItem colSpan={6}>
        <FormLabel
          color="gray.500"
          fontSize="xs"
          fontWeight="semibold"
          style={{ textTransform: 'uppercase' }}
          mb={0}
          pl={2}
        >
          Validade
        </FormLabel>
        <ChakraMaskedInput
          as={MaskedInput}
          id="main_card_expiration"
          name="expiration_date"
          isInvalid={!!errors.filter(value => value.field === 'main_card_expiration').length}
          maskChar=""
          mask="99/9999"
          placeholder={moment().add('4', 'y').format('MM/YYYY')}
          value={mainPaymentMethod.expiration_date}
          onChange={event => changeMainCreditCard(event, false)}
        />
      </GridItem>

      {/* --------------------------------- VALOR -------------------------------- */}
      <GridItem colSpan={7}>
        <FormLabel
          color="gray.500"
          fontSize="xs"
          fontWeight="semibold"
          style={{ textTransform: 'uppercase' }}
          mb={0}
          pl={2}
        >
          Valor
        </FormLabel>

        <CurrencyInput
          id="value"
          name="value"
          error={errors.filter(value => value.field === 'main_credit_card').length}
          onBlur={(event: React.ChangeEvent<HTMLInputElement>) => {
            const { value } = event.target
            const valueFormated = Number(String(value).replace('.', '').replace(',', '.'))
            calculateValue(valueFormated)
          }}
          onValueChange={(value: { floatValue: number; formatedValue: string; value: string }) => {
            changeMainCreditCard(
              { name: 'value', id: 'main_credit_card', value: value?.floatValue },
              true,
            )
          }}
          prefix=""
          leftElement={
            <InputLeftElement
              pointerEvents="none"
              color="gray.400"
              fontSize="1em"
              ml={1}
              fontWeight="medium"
            >
              R$
            </InputLeftElement>
          }
          value={mainPaymentMethod.value}
        />
      </GridItem>

      {/* ------------------------------ PARCELAMENTO ------------------------------ */}
      <GridItem colSpan={5}>
        <FormLabel
          color="gray.500"
          fontSize="xs"
          fontWeight="semibold"
          style={{ textTransform: 'uppercase' }}
          mb={0}
          pl={2}
        >
          Parcelamento
        </FormLabel>

        <Select
          id="main_card_parcel"
          name="parcel"
          isInvalid={!!errors.filter(value => value.field === 'main_card_parcel').length}
          placeholder="Parcelamento"
          value={mainPaymentMethod.parcel}
          onChange={event => changeMainCreditCard(event, false)}
        >
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((parcel, index) => (
            <option value={parcel} key={index}>
              Parcelar em {parcel}x
            </option>
          ))}
        </Select>
      </GridItem>

      {/* -------------------------- VENCIMENTO DA FATURA -------------------------- */}
      <GridItem colSpan={12}>
        <FormLabel
          color="gray.500"
          fontSize="xs"
          fontWeight="semibold"
          style={{ textTransform: 'uppercase' }}
          mb={0}
          pl={2}
        >
          Vencimento da fatura do cartão
        </FormLabel>

        <InputGroup>
          <ChakraMaskedInput
            as={MaskedInput}
            id="main_card_maturity"
            name="card_maturity"
            isInvalid={!!errors.filter(value => value.field === 'main_card_maturity').length}
            maskChar=""
            mask="99"
            placeholder="Vencimento da fatura do cartão"
            value={mainPaymentMethod.card_maturity}
            onChange={event => changeMainCreditCard(event, false)}
          />
          <InputRightElement
            /* eslint-disable-next-line react/no-children-prop */
            children={
              <Tooltip
                label="O vencimento da fatura do cartão é relativo a data limite que você tem para pagar a fatura do seu cartão de crédito."
                fontSize="md"
              >
                <QuestionOutlineIcon color="gray.500" />
              </Tooltip>
            }
          />
        </InputGroup>
      </GridItem>
    </Grid>
  )
}
