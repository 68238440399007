import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useToast,
} from '@chakra-ui/react'

import ApiInstance from '../../../../services/ApiInstance'

import { AlertDialog } from '../AlertDialog'
import { Textarea } from '../Form/Textarea'
import { cancelTransactionSchema } from '../../schemas/cancelTransactionSchema'
import { SummaryType } from '../../types/summary'

interface CancelFirst30DaysProps extends Pick<SummaryType, 'purchase'> {
  isOpen: boolean
  handleCloseModal: () => void
}

export function CancelFirst30DaysTransactionModal({
  purchase,
  isOpen,
  handleCloseModal,
}: CancelFirst30DaysProps) {
  const [isAlertOpen, setIsAlertOpen] = useState(false)
  const onClose = () => setIsAlertOpen(false)
  const history = useHistory()
  const toast = useToast()

  const { register, handleSubmit, formState, watch, reset } = useForm({
    resolver: yupResolver(cancelTransactionSchema),
  })
  const { errors, isSubmitting } = formState

  const alertDescription = watch('description')

  function handleConfirm() {
    setIsAlertOpen(true)
  }

  async function handleCancelSubmit(values: { description: string }) {
    const { description } = values
    try {
      await ApiInstance.put('/transacao/cancelaTransacao', {
        id_purchase: purchase.id,
      })

      await ApiInstance.post('/transacao/emailCancelaTransacao', {
        company: purchase.company,
        reason: description,
        id_purchase: purchase.id,
        value: purchase.totalValueLocCorresp,
      })

      await ApiInstance.post('timeline', {
        client_id: purchase.clientId,
        name: 'Cancelamento',
        notas: description,
      })
      toast({
        title: 'Transação cancelada com sucesso!',
        status: 'success',
        duration: 2000,
        isClosable: true,
      })
      setIsAlertOpen(false)
      reset()
      history.push(`/acompanhamento`)
      handleCloseModal()
    } catch (error: any) {
      if (error.originalMessage === 'DATA_LIMITE')
        return toast({
          title: 'O limite para cancelamento de uma transação é 30 dias.',
          status: 'error',
          isClosable: true,
        })
      return toast({
        title: 'Erro inesperado, entre em contato com nosso suporte.',
        status: 'error',
        isClosable: true,
      })
    }
  }

  return (
    <>
      <AlertDialog
        description={alertDescription}
        loading={isSubmitting}
        isOpen={isAlertOpen}
        onCancel={onClose}
        onConfirm={handleSubmit(handleCancelSubmit)}
      />
      <Modal
        size="xl"
        isCentered
        isOpen={isOpen}
        onClose={handleCloseModal}
        scrollBehavior="inside"
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent as="form" onSubmit={handleSubmit(handleConfirm)}>
          <ModalHeader color="gray.600">Deseja cancelar esta transação?</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Textarea
              label="Motivo do cancelamento"
              rows={4}
              multiline
              resize="none"
              autoFocus
              placeholder="Informe o motivo do cancelamento..."
              error={errors.description}
              {...register('description')}
            />
          </ModalBody>

          <ModalFooter>
            <Button color="gray.600" mr={3} disabled={isSubmitting} onClick={handleCloseModal}>
              Voltar
            </Button>
            <Button isLoading={isSubmitting} colorScheme="blue" type="submit">
              Confirmar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
