import { Stack, Skeleton } from '@chakra-ui/react'
import { Card } from '../Card'

export function InvoicesSkeleton() {
  return (
    <>
      <Card mt={8}>
        <Stack w="100%" spacing={10} mr={16}>
          <Skeleton startColor="gray.50" endColor="gray.300" h={6} w={44} mb={1} />
          <Stack w="100%" align="flex-end" spacing={1} mr={16}>
            <Skeleton startColor="gray.50" endColor="gray.300" h={6} w="100%" />
            <Skeleton startColor="gray.50" endColor="gray.300" h={12} w="100%" />
            <Skeleton startColor="gray.50" endColor="gray.300" h={12} w="100%" />
            <Skeleton startColor="gray.50" endColor="gray.300" h={12} w="100%" />
          </Stack>
        </Stack>
      </Card>
    </>
  )
}
