import React from 'react'
import { Breadcrumbs, Container, Grid, Typography } from '@material-ui/core'
import { Home } from '@material-ui/icons'
import { Link } from 'react-router-dom'
import Header from '../../layout/Header'
import Table from './Table'

class Acompanhamento extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      clientId: null,
      rows: [],
      bar_chart: { series: [], categories: [] },
    }
  }

  render() {
    return (
      <>
        <Header />

        <Container id="acompanhamento" maxWidth="lg">
          <Grid
            spacing={3}
            container
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Grid item xs={12} style={{ width: '100%', maxWidth: '1600px' }}>
              <Breadcrumbs className="breadcrumbs">
                <Link to="/" style={{ display: 'flex' }}>
                  <Home
                    style={{
                      marginRight: 8,
                      width: 20,
                      height: 20,
                    }}
                  />
                  Início
                </Link>
                <Typography color="textPrimary">Acompanhamento</Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item xs={12}>
              <Table parent={this}></Table>
            </Grid>
          </Grid>
        </Container>
      </>
    )
  }
}

export default Acompanhamento
