import React from 'react'
import moment from 'moment'
import { Chip } from '@material-ui/core'
import styles from '../../styles/defaultStyles'

function formatPurchases(purchases) {
  return purchases.map((purchase) => {
    purchase.total_fee = purchase.total_fee + ' %'
    purchase.administration_fee = purchase.administration_fee + ' %'
    purchase.imob_gain = Number(purchase.imob_gain).toFixed(2) + ' %'
    purchase.cover_value = purchase.cover_value + ' vezes'

    if (purchase.status === 1) purchase.status_column = <Chip style={{ color: '#fff', background: styles.info }} size="small" label="Pendente"></Chip>
    if (purchase.status === 2) purchase.status_column = <Chip style={{ color: '#fff', background: styles.info }} size="small" label="Aguardando pagamento"></Chip>
    if (purchase.status === 3) purchase.status_column = <Chip style={{ color: '#fff', background: styles.success }} size="small" label="Concluída"></Chip>
    if (purchase.status === 4) purchase.status_column = <Chip style={{ color: '#fff', background: styles.critical }} size="small" label="Cancelada"></Chip>
    if (purchase.status === 5) purchase.status_column = <Chip style={{ color: '#fff', background: styles.error }} size="small" label="Falha"></Chip>
    if (purchase.status === 6) purchase.status_column = <Chip style={{ color: '#fff', background: styles.success }} size="small" label="Pago"></Chip>
    if (purchase.status === 7) purchase.status_column = <Chip style={{ color: '#fff', background: styles.info }} size="small" label="Pago Parcialmente"></Chip>
    if (purchase.status === 8) purchase.status_column = <Chip style={{ color: '#fff', background: styles.info }} size="small" label="Pendente Contrato"></Chip>
    if (purchase.status === 9) purchase.status_column = <Chip style={{ color: '#fff', background: styles.info }} size="small" label="Pendente Aprovação Ucred"></Chip>

    if (purchase.status === 1) purchase.status = 'Pendente'
    if (purchase.status === 2) purchase.status = 'Aguardando pagamento'
    if (purchase.status === 3) purchase.status = 'Concluída'
    if (purchase.status === 4) purchase.status = 'Cancelada'
    if (purchase.status === 5) purchase.status = 'Falha'
    if (purchase.status === 6) purchase.status = 'Pago'
    if (purchase.status === 7) purchase.status = 'Pago Parcialmente'
    if (purchase.status === 8) purchase.status = 'Pendente Contrato'
    if (purchase.status === 9) purchase.status = 'Pendente Aprovação Ucred'

    purchase.totalValue = Number(purchase.totalValue)
    if (purchase.anticipation === 1) purchase.anticipation = 'Não Solicitada'
    if (purchase.anticipation === 2) purchase.anticipation = 'Aguardando'
    if (purchase.anticipation === 3) purchase.anticipation = 'Antecipada'

    purchase.dt_inicio = moment(purchase.dt_inicio).format('YYYY-MM-DD')
    purchase.dt_fim = moment(purchase.dt_fim).format('YYYY-MM-DD')
    purchase.warranty_ucred === 1 ? (purchase.warranty_ucred = 'Ucred') : (purchase.warranty_ucred = 'própria')
    return purchase
  })
}

export { formatPurchases }
