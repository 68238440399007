import { useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useToast,
  Text,
  Flex,
  Stack,
  ListItem,
  UnorderedList,
} from '@chakra-ui/react'

import { DropzoneArea } from '../DropzoneArea'
import { convertToBase64 } from '../../../../util/convertToBase64'
import ApiInstance from '../../../../services/ApiInstance'
import { Textarea } from '../Form/Textarea'

import { cancelTransactionSchema } from '../../schemas/cancelTransactionSchema'

interface CancelTransactioProps {
  purchaseId: number
  isOpen: boolean
  handleCloseModal: () => void
}

interface TextareaRef extends HTMLTextAreaElement {
  focus: () => void
}

export function CancelTransactionModal({
  purchaseId,
  isOpen,
  handleCloseModal,
}: CancelTransactioProps) {
  const [files64, setFiles64] = useState<any[]>([])
  const initialRef = useRef<TextareaRef>(null)
  const toast = useToast()
  const history = useHistory()

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(cancelTransactionSchema),
  })
  const { errors, isSubmitting } = formState

  async function onDropzoneChange(files: any[]) {
    const filesArr: any[] = []
    files.map(files => {
      convertToBase64([files], (base64: any) => {
        filesArr.push({ name: files.name, base: base64 })
      })
    })
    setFiles64(filesArr)
  }

  async function handleUpload(values: { description: string }) {
    const { description } = values
    if (!files64.length)
      return toast({
        title: 'Insira ao menos um documento.',
        status: 'error',
        duration: 2000,
        isClosable: true,
      })

    try {
      await ApiInstance.post('cancel-request', {
        files: files64,
        description,
        purchaseId,
      })
      toast({
        title: 'Solicitação de cancelamento enviada com sucesso.',
        status: 'success',
        duration: 2000,
        isClosable: true,
      })
      history.push(`/acompanhamento`)
      handleCloseModal()
    } catch {
      toast({
        title: 'Houve um erro inesperado, entre em contato com nosso suporte.',
        status: 'error',
        duration: 2000,
        isClosable: true,
      })
    }
  }

  return (
    <Modal
      size="2xl"
      isCentered
      isOpen={isOpen}
      onClose={handleCloseModal}
      initialFocusRef={initialRef}
      scrollBehavior="inside"
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmit(handleUpload)}>
        <ModalHeader fontWeight="normal" color="gray.600">
          Deseja cancelar esta transação?
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Stack spacing={8}>
            <Text fontSize="md" color="gray.600">
              Para que nossa equipe possa efetuar o cancelamento da transação, solicitamos que sejam
              enviados os seguintes documentos:
            </Text>
            <Flex>
              <UnorderedList color="gray.500" fontSize="sm" pl={4}>
                <ListItem>Contrato de locação do locatário.</ListItem>
                <ListItem>
                  Termo de entrega de chaves ou documento que comprove a entrega do imóvel.
                </ListItem>
                <ListItem>Laudo de vistoria de entrada e saída (não obrigatório).</ListItem>
              </UnorderedList>
            </Flex>
            <DropzoneArea
              dropzoneText="Arraste e solte arquivos aqui ou clique para fazer upload. São aceitos aquivos .jpeg, .png, .bmp e .pdf"
              onChange={onDropzoneChange}
            />
            <Textarea
              rows={4}
              multiline
              resize="none"
              label="Motivo do cancelamento"
              placeholder="Informe o motivo do cancelamento..."
              error={errors.description}
              {...register('description')}
              ref={initialRef}
            />
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button color="gray.600" mr={3} disabled={isSubmitting} onClick={handleCloseModal}>
            Voltar
          </Button>
          <Button isLoading={isSubmitting} colorScheme="blue" type="submit">
            Confirmar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
