import React from 'react'
import Header from '../../layout/Header'
import Modal from 'react-responsive-modal'
import { Breadcrumbs, Container, Grid, Typography } from '@material-ui/core'
import { Home } from '@material-ui/icons'
import { Link } from 'react-router-dom'

class Treinamento extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      openModal: false,
    }
  }

  handleQRCode() {
    this.setState({ openModal: true })
  }

  closeModal() {
    this.setState({ openModal: false })
  }

  render() {
    return (
      <>
        <Header />
        <Container style={{ paddingTop: 32, marginTop: 80 }}>
          <Grid item xs={12}>
            <Breadcrumbs className="breadcrumbs">
              <Link to="/" style={{ display: 'flex' }}>
                <Home
                  style={{
                    marginRight: 8,
                    width: 20,
                    height: 20,
                  }}
                />
                Início
              </Link>
              <Typography color="textPrimary">Treinamento</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid spacing={3} container>
            <Grid item xs={12}>
              <div className="treinamento">
                <div className="treinamento-title">
                  <h1>Treinamento</h1>
                </div>
                <div className="treinamento-body">
                  <div className="row-agendamento">
                    <p>Agende um treinamento com nossos especialistas!</p>
                    <button
                      onClick={() => {
                        window.open(`https://api.whatsapp.com/send?phone=5532984019402`)
                      }}
                    >
                      <i className="lni lni-whatsapp"></i>Agende agora
                    </button>
                  </div>
                  <div className="row">
                    <div className="treinamento-body-item">
                      <div className="treinamento-body-item-title">Treinamento completo da plataforma:</div>
                      <div className="treinamento-body-item-description">
                        O vídeo abaixo demonstra todas as funcionalidades da plataforma
                      </div>
                      <div className="treinamento-body-item-video">
                        <iframe
                          width="560"
                          height="315"
                          src="https://www.youtube.com/embed/4Cccu_Poon4"
                          frameBorder="0"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                      </div>
                    </div>

                    <div className="treinamento-body-item">
                      <div className="treinamento-body-item-title">Simulando uma transação:</div>
                      <div className="treinamento-body-item-description">
                        O vídeo abaixo demonstra como fazer uma transação na plataforma
                      </div>
                      <div className="treinamento-body-item-video">
                        <iframe
                          width="560"
                          height="315"
                          src="https://www.youtube.com/embed/WXlceT4cAHk"
                          frameBorder="0"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="treinamento-body-item">
                      <div className="treinamento-body-item-title">
                        Como instruir o locatário para completar a transação por e-mail e assinar o contrato da UCred:
                      </div>
                      <div className="treinamento-body-item-description">
                        O vídeo abaixo demonstra como fazer uma transação por E-mail
                      </div>
                      <div className="treinamento-body-item-video">
                        <iframe
                          width="560"
                          height="315"
                          src="https://www.youtube.com/embed/85XDKTPOB-M"
                          frameBorder="0"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                      </div>
                    </div>

                    <div className="treinamento-body-item">
                      <div className="treinamento-body-item-title">Como configurar seu ganho com a UCred:</div>
                      <div className="treinamento-body-item-description">
                        O vídeo abaixo demonstra como configurar seu Over
                      </div>
                      <div className="treinamento-body-item-video">
                        <iframe
                          width="560"
                          height="315"
                          src="https://www.youtube.com/embed/iM9yDmC-fK4"
                          frameBorder="0"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="treinamento-body-item">
                      <div className="treinamento-body-item-title">
                        Quais ajustes contratuais devo fazer para utilizar a garantia UCred:
                      </div>
                      <div className="treinamento-body-item-description">
                        O vídeo abaixo demonstra ajustes necessário no seu contrato antes de efetuar a garantia
                      </div>
                      <div className="treinamento-body-item-video">
                        <iframe
                          width="560"
                          height="315"
                          src="https://www.youtube.com/embed/JhTq69WF-wE"
                          frameBorder="0"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                      </div>
                    </div>

                    <div className="treinamento-body-item">
                      <div className="treinamento-body-item-title">Como cancelar uma transação já efetuada:</div>
                      <div className="treinamento-body-item-description">
                        O vídeo abaixo demonstra como cancelar uma transação do jeito certo
                      </div>
                      <div className="treinamento-body-item-video">
                        <iframe
                          width="560"
                          height="315"
                          src="https://www.youtube.com/embed/NFryYx5EmuU"
                          frameBorder="0"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="treinamento-body-item">
                      <div className="treinamento-body-item-title">
                        Como acionar um sinistro na plataforma da UCred:
                      </div>
                      <div className="treinamento-body-item-description">
                        O vídeo abaixo demonstra como acionar a garantia Ucred
                      </div>
                      <div className="treinamento-body-item-video">
                        <iframe
                          width="560"
                          height="315"
                          src="https://www.youtube.com/embed/_Yf_ccgtcoc"
                          frameBorder="0"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
        <Modal open={this.state.openModal} onClose={this.closeModal} classNames={{ modal: 'modal' }}>
          <div>
            <img src="https://i.ibb.co/6tLmxLS/Whats-App-Image-2020-09-01-at-09-56-20.jpg" />
          </div>
        </Modal>
      </>
    )
  }
}

export default Treinamento
