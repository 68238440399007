export default function errorMessage(code, message = '') {
  switch (String(code)) {
    case '57':
      message = 'Transação recusada, entre em contato com o banco emissor do cartão para regularizar.'
      break
    case '78':
      message = 'Cartão não foi desbloqueado pelo portador.'
      break
    case 'N7':
      message = 'Código de segurança inválido.'
      break
    case 'null':
      message = 'Erro de comunicação com o gateway, aguarde alguns instantes e tente novamente.'
      break
    case '01':
      message = 'Transação recusada, entre em contato com o banco emissor do cartão para regularizar.'
      break
    case '04':
      message = 'Transação recusada, entre em contato com o banco emissor do cartão para regularizar.'
      break
    case '05':
      message = 'Transação recusada, entre em contato com o banco emissor do cartão para regularizar.'
      break
    case '51':
      message = 'Limite do cartão insuficiente.'
      break
    case '54':
      message = 'Cartão vencido.'
      break
    case '105':
      message = 'Transação recusada, entre em contato com o banco emissor do cartão para regularizar.'
      break
    case '106':
      message = 'Erro ao processar a transação, tente novamente.'
      break
    case '107':
      message = 'Transação recusada, entre em contato com o banco emissor do cartão para regularizar.'
      break
    case '112':
      message = 'Data de validade expirada.'
      break
    case '111':
      message = 'Limite do cartão insuficiente.'
      break
    case '113':
      message =
        'Transação bloqueada pelo emissor, entre em contato com o banco responsável pelo emissor do cartão para regularizar.'
      break
    default:
      break
  }

  return message
}
