import React, { useCallback, useState } from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Link, Typography } from '@material-ui/core'
import { Close } from '@material-ui/icons'

function ContractUpdateModal({ contractUrl }) {
  const [isModalOpen, setIsModalOpen] = useState(true)

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  return (
    <Dialog open={isModalOpen} onClose={handleCloseModal}>
      <DialogTitle
        disableTypography
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6">Atualização de Contrato</Typography>
        <IconButton size="small" style={{ marginLeft: 'auto' }} onClick={handleCloseModal}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          Atenção, o nosso contrato passou por uma nova atualização, portanto, é necessário a assinatura do novo documento para seguir utilizando a plataforma da Ucred. Um e-mail com os novos termos
          foi enviado para o usuário principal da plataforma, certifique-se de assinar o novo contrato o quanto antes para evitar o bloqueio temporário da plataforma.
        </DialogContentText>
        <Typography variant="body2">
          <Link href={contractUrl} target="_blank" rel="noopener noreferrer">
            Acessar o novo contrato
          </Link>
        </Typography>
        <Box
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: 16,
          }}
        ></Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleCloseModal}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export { ContractUpdateModal }
