import {
  Box,
  Button,
  Divider,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { Help } from '@material-ui/icons'
import moment from 'moment'
import { useState } from 'react'
import MaskedInput from 'react-input-mask'
import ApiInstance from '../../../services/ApiInstanceV3'
import ConfirmModal from './ConfirmModal'

export default function Recurrence(props: {
  name: string
  adhesion: any[]
  value: number
  address: { cep: string; address: string; city: string }
  snackbar: (message: string, severity: string) => void
  previousStep: () => void
  loading: boolean
  setLoading: (loading: boolean) => void
}) {
  const [creditCard, setCreditCard] = useState({
    name: props.name,
    number: '',
    cvv: '',
    expiry: '',
    cardMaturity: '',
  })
  const [adhesion] = useState(props.adhesion || [])
  const [adhesionParcel, setAdhesionParcel] = useState(3)
  const [confirm, setConfirm] = useState(false)

  const handleChangeCreditCard = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event?.target
    if (name === 'card_maturity') {
      if (Number(value) > 31 || Number(value) < 30) return
    }
    setCreditCard({ ...creditCard, [name]: value })
  }

  const validateCardNumber = (number: string) => {
    if (number.replace(/ /g, '').length > 16 || number.replace(/ /g, '').length < 15)
      throw new Error('Número do cartão inválido')
  }

  const validateCardCvv = (cvv: string) => {
    if (cvv.length > 4 || cvv.length < 3) throw new Error('Cvv inválido')
  }

  const validateCardMaturity = (cardMaturity: string) => {
    if (cardMaturity.length > 31 || cardMaturity.length < 1)
      throw new Error('Dia de vencimento da fatura inválida')
  }

  const validateCardExpiry = (expiry: string) => {
    if (!moment(expiry, 'MM/YY').isValid()) throw new Error('Data de expiração inválida')
  }

  const handleSubmit = async () => {
    props.setLoading(true)
    try {
      if (!confirm) {
        validateCardNumber(creditCard.number)
        validateCardCvv(creditCard.cvv)
        validateCardExpiry(creditCard.expiry)
        validateCardMaturity(creditCard.cardMaturity)

        props.setLoading(false)
        return setConfirm(true)
      }
      const urlParams = new URLSearchParams(window.location.search)
      const hash = urlParams.get('hash')
      let products = null
      if (adhesion[0]) {
        products = [{ installments: adhesionParcel, productId: adhesion[0].product_id }]
      }
      await ApiInstance.post(`/purchases/${hash}/recurrence`, {
        paymentProfile: {
          holderName: creditCard.name,
          cardNumber: creditCard.number,
          cardCvv: creditCard.cvv,
          cardMaturity: creditCard.cardMaturity,
          cardExpiration: moment(creditCard.expiry, 'MM/YY').format('MM/YYYY'),
        },
        products,
      })
      props.snackbar('Pagamento realizado com sucesso!', 'success')
      await new Promise(resolve => setTimeout(resolve, 2000))
      window.location.reload()
    } catch (error: any) {
      if (error?.response?.data?.message) {
        props.snackbar(error?.response?.data?.message || 'Houve um erro não identificado', 'error')
      } else {
        props.snackbar(error?.message || 'Houve um erro não identificado', 'error')
      }
      props.setLoading(false)
    }
  }

  const ReactInputMaskExtend: any = MaskedInput

  return (
    <>
      <Box m={2} id="recurrence-payment">
        <div className="header-comp" style={{ height: 62 }}>
          <Typography variant="h1" component="h1">
            Dados do cartão
          </Typography>
        </div>
        <Divider />

        <div className="columns">
          <Box p={2} style={{ width: 300 }}>
            <TextField
              className="input"
              disabled
              size="small"
              value={creditCard.name}
              onChange={handleChangeCreditCard}
              name="name"
              label="Titular do Cartão"
              placeholder="João S M Silveira"
              fullWidth
            />

            <ReactInputMaskExtend
              className="input"
              label="Número do cartão"
              mask="9999 9999 9999 9999"
              name="number"
              placeholder="4444 4444 4444 4448"
              maskChar=""
              fullWidth
              value={creditCard.number}
              onChange={handleChangeCreditCard}
              id="card-number-input"
            >
              {(inputProps: any) => <TextField {...inputProps} />}
            </ReactInputMaskExtend>

            <ReactInputMaskExtend
              className="input"
              mask="99/99"
              name="expiry"
              label="Data de expiração"
              placeholder="12/26"
              maskChar=""
              fullWidth
              size="small"
              value={creditCard.expiry}
              onChange={handleChangeCreditCard}
            >
              {(inputProps: any) => <TextField {...inputProps} />}
            </ReactInputMaskExtend>

            <ReactInputMaskExtend
              className="input"
              mask="9999"
              name="cvv"
              label="Cód. de segurança"
              placeholder="153"
              maskChar=""
              fullWidth
              InputProps={{
                endAdornment: (
                  <Tooltip
                    arrow
                    placement="left-start"
                    title="O número código de segurança é representado pelos três últimos dígitos no verso do seu cartão. Em cartões American Express, o código de segurança é um número de 4 dígitos na frente do cartão."
                  >
                    <Help></Help>
                  </Tooltip>
                ),
              }}
              value={creditCard.cvv}
              onChange={handleChangeCreditCard}
            >
              {(inputProps: any) => <TextField {...inputProps} />}
            </ReactInputMaskExtend>

            <ReactInputMaskExtend
              className="input"
              mask={'99'}
              name="cardMaturity"
              label="Dia do vencimento da fatura"
              placeholder="20"
              maskChar=""
              fullWidth
              size="small"
              value={creditCard.cardMaturity}
              onChange={handleChangeCreditCard}
            >
              {(inputProps: any) => <TextField {...inputProps} />}
            </ReactInputMaskExtend>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box p={2} style={{ width: 300 }}>
            <div className="item">
              <Typography variant="body1" component="p">
                Endereço
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {props.address.address}
              </Typography>
            </div>
            <div className="item">
              <Typography variant="body1" component="p">
                Cidade
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {props.address.city}
              </Typography>
            </div>
            <div className="item">
              <Typography variant="body1" component="p">
                CEP
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {props.address.cep}
              </Typography>
            </div>

            {!!adhesion[0] && (
              <div className="item" style={{ marginTop: 8 }}>
                <Typography variant="body1" component="p">
                  Taxa de adesão
                </Typography>
                <div style={{ width: '100%' }}>
                  <Select
                    style={{ width: '100%' }}
                    value={adhesionParcel}
                    onChange={(event: any) => {
                      const { value } = event.target
                      return setAdhesionParcel(value)
                    }}
                  >
                    {[1, 2, 3].map((parcel, index) => {
                      return (
                        <MenuItem key={index} value={parcel}>
                          {parcel}X de R${' '}
                          {(adhesion[0].value / parcel).toFixed(2).replace('.', ',')}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </div>
              </div>
            )}

            <div className="item" style={{ marginTop: 8 }}>
              <Typography variant="body1" component="p">
                Valor avalizado
              </Typography>
              <Typography variant="body1" color="primary" component="p">
                R$ {props.value.toFixed(2).replace('.', ',')} em 12 vezes
              </Typography>
            </div>

            <div className="item" style={{ marginTop: 8 }}>
              <Typography variant="body1" component="p">
                Valor total
              </Typography>
              <Typography variant="body1" color="primary" component="p">
                R$ {(props.value + (adhesion[0]?.value || 0)).toFixed(2).replace('.', ',')}
              </Typography>
            </div>
          </Box>
        </div>

        <div className="next-step">
          <Button
            disabled={props.loading}
            color="primary"
            variant="contained"
            style={{ width: '100%' }}
            className="back"
            onClick={props.previousStep}
          >
            Voltar
          </Button>
          <Button
            id="recurrence-next-button"
            disabled={props.loading}
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            style={{ width: '100%' }}
            className="next"
          >
            Continuar
          </Button>
        </div>
      </Box>
      <ConfirmModal
        value={props.value}
        confirm={confirm}
        loading={props.loading}
        adhesion={adhesion}
        adhesionParcel={adhesionParcel}
        setConfirm={setConfirm}
        handleSubmit={handleSubmit}
      ></ConfirmModal>
    </>
  )
}
