import Api from '../../services/ApiInstance'
import React from 'react'

class LogOut extends React.Component {

    render() {
        logOut()
        return (
            <div></div>
        )
    }
}

function logOut() {
    Api.post('logout')
}

export default LogOut