import React from 'react'
import Header from '../../layout/Header'
class ConsumoPlano extends React.Component {
    
    render() {
        return (
            <React.Fragment>
                <Header />
                <div className="consumoPlano">
                    <div className="consumoPlano__container">
                        <div className="consumoPlano__container-header">
                            <h1> Consumo do Plano </h1>
                        </div>
                        <div className='consumoPlano__floating-menu'>
                        </div>
                        <div className="consumoPlano__container-body">
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default ConsumoPlano