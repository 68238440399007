import { Box, Button, Spacer, Text } from '@chakra-ui/react'
import React from 'react'

function PaymentMethodButton(props) {
  const boxShadow = 'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px'

  return (
    <Button
      as={Box}
      p={6}
      m={{ base: 3, md: 6 }}
      style={{
        display: 'flex',
        height: 78,
        borderRadius: 8,
        border: '1px solid rgb(145 158 171 / 24%)',
        boxShadow: props.selectedPaymentMethod === props.value && boxShadow,
        transition: '.5s',
      }}
      colorScheme="blue"
      variant="ghost"
      onClick={() => props.setSelectedPaymentMethod(props.value)}
    >
      <Text color="gray.500" fontWeight="semibold">
        {props.label}
      </Text>
      <Spacer />
      <props.secondImg></props.secondImg>
      <props.img></props.img>
    </Button>
  )
}

export { PaymentMethodButton }
