import { createStore, combineReducers } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storageSession from 'redux-persist/lib/storage'
import authReducer from '../reducers/authUserReducer'
import creditAnalysis from '../reducers/creditAnalysisReducer'
import dashboardReducer from '../reducers/dashboardReducer'

const persist_config = {
  key: 'root',
  storage: storageSession,
  blacklist:['creditAnalysis', 'authReducer']
}

const reducer = combineReducers({
  authReducer,
  creditAnalysis,
  dashboardReducer,
})

const persist_reducer = persistReducer(persist_config, reducer)

const store = createStore(
  persist_reducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

const persistor = persistStore(store)

export { store, persistor }
