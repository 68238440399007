import { Center, Text, Box } from '@chakra-ui/react'

export default function NotFound() {
  return (
    <Box height="100vh" display="flex" flexDirection="column" justifyContent="center">
      <Center>
        <img
          style={{ width: 400, maxWidth: '80%' }}
          src={'https://res.cloudinary.com/ucred/image/upload/v1629896226/images/404%20error.svg'}
        ></img>
      </Center>
      <Text align="center">404 | Whopss... A página que você solicitou não foi encontrada.</Text>

      <Text as="a" href={'http://www.ucred.io'} style={{ color: '#777', textAlign: 'center' }}>
        Clique aqui para ir até a página da Ucred.
      </Text>
    </Box>
  )
}
