import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux'
import api from '../../services/ApiInstance'
import { withSnackbar } from 'notistack'
import { Close } from '@material-ui/icons'

class DeleteService extends React.Component {
  constructor() {
    super()
    this.state = {
      loading: false,
    }
  }
  render() {
    const { parent } = this.props
    const { loading } = this.state

    const deleteService = async () => {
      this.setState({ loading: true })
      try {
        if (this.props.user.plan_id === 1) {
          if (parent.state.deleteService.data.warranty_ucred !== 'Própria') throw new Error('Não é possível deletar este serviço.')
        }
        if (parent.state.deleteService.data.type_imobile === 1) throw new Error('Não é possível deletar este serviço.')
        if (this.props.user.plan_id === 2) {
          await api.delete(`serviceUcredPlan/${parent.state.deleteService.data.id}`)
        } else {
          await api.delete(`servico/${parent.state.deleteService.data.id}`)
        }
        parent.setState({ deleteService: { ...parent.state.deleteService, open: false } })
        this.setState({ loading: false })
        parent.fetchData()
        const snackbarKey = this.props.enqueueSnackbar('Serviço deletado com sucesso.', { variant: 'success',
onClick: () => {
              this.props.closeSnackbar(snackbarKey)
            } })
      } catch (error) {
        this.setState({ loading: false })

        const snackbarKey = this.props.enqueueSnackbar(error.message, { variant: 'error',
onClick: () => {
              this.props.closeSnackbar(snackbarKey)
            } })
      }
    }
    return (
      <Dialog
        open={parent.state.deleteService.open}
        onClose={() => parent.setState({ deleteService: { ...parent.state.deleteService, open: false } })}
      >
        <DialogTitle
          disableTypography
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6">Tem certeza que deseja deletar este serviço?</Typography>
          <IconButton
            onClick={() => parent.setState({ deleteService: { ...parent.state.deleteService, open: false } })}
            size="small"
            style={{ marginLeft: 'auto' }}
          >
            <Close></Close>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>Atenção</DialogContentText>
          <DialogContentText>Esta ação é irreversível, após deletado você não poderá recuperar este serviço novamente.</DialogContentText>
          <DialogContentText>Suas transações não serão afetadas por esta ação.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={loading}
            onClick={() => {
              parent.setState({ deleteService: { ...parent.state.deleteService, open: false } })
            }}
            color="primary"
          >
            Voltar
          </Button>
          <Box style={{ display: 'flex', position: 'relative' }}>
            {loading && (
              <CircularProgress
                size={24}
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: -12,
                  marginLeft: -12,
                }}
              />
            )}
            <Button disabled={loading} onClick={deleteService} color="primary" variant="contained">
              Deletar
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    )
  }
}

function mapStateToProps({ authReducer: { authUser } }) {
  return {
    user: { ...authUser },
  }
}

export default withSnackbar(connect(mapStateToProps)(DeleteService))
