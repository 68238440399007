import {
  Stack,
  Skeleton as ChakraSkeleton,
  SkeletonProps,
  Box,
  HStack,
  Divider,
  Flex,
} from '@chakra-ui/react'

const Skeleton = ({ w = '100%', h = 6, ...rest }: SkeletonProps) => (
  <ChakraSkeleton w={w} h={h} startColor="gray.50" endColor="gray.300" {...rest} />
)

const Row = () => (
  <>
    <Box w="100%" py={2} px={3}>
      <Skeleton h={6} />
    </Box>
    <Divider />
  </>
)

export function TableSkeleton() {
  return (
    <Box p={6} borderRadius={16} boxShadow="xs" w="1600px" h="100%">
      <Stack w="100%" spacing={10} pb={9}>
        <Flex>
          <Skeleton ml={3} h={6} w={96} mt={5} />
          <Skeleton ml="auto" mr={3} h={6} w={80} mt={5} />
        </Flex>
        <Stack align="flex-end" spacing={1} w="100%">
          <HStack w="100%" px={3} mb={3}>
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </HStack>
          <Divider />
          <Row />
          <Row />
          <Row />
          <Row />
          <Row />
          <Row />
          <Row />
          <Row />
          <Row />
        </Stack>
      </Stack>
      <Flex justify="flex-end">
        <Skeleton w={80} mr={3} h={8} mb={2} />
      </Flex>
      <Divider />
    </Box>
  )
}
