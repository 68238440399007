import React, { useState } from 'react'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { useToast } from '@chakra-ui/react'
import ApiInstance from '../../services/ApiInstance'
import * as yup from 'yup'

const recoveryPasswordSchema = yup.string().email('E-mail inválido').required('E-mail obrigatório')

export function RecoverPasswordModal({ modalOpen, handleClose }) {
  const [loading, setLoading] = useState(false)
  const [emailForgot, setEmailForgot] = useState('')
  const [emailInputError, setEmailInputError] = useState(false)
  const toast = useToast()

  const confirmForgot = async () => {
    setLoading(true)
    try {
      if (!emailForgot) setEmailInputError(true)
      await recoveryPasswordSchema.validate(emailForgot)
      const response = await ApiInstance.post('/recover-password', { email: emailForgot })
      toast({
        title: response.message,
        status: 'success',
        duration: 2000,
        isClosable: true,
      })
      setLoading(false)
      handleClose()
    } catch (error) {
      setLoading(false)
      toast({
        title: error.message || 'Houve um erro inesperado, entre em contato com nosso suporte.',
        status: 'error',
        duration: 2000,
        isClosable: true,
      })
    }
  }

  const handleChange = (event) => {
    const { value } = event.target
    setEmailForgot(value)
  }

  const handleBlur = async (event) => {
    const { value } = event.target
    const hasError = await recoveryPasswordSchema.isValid(value)
    setEmailInputError(!hasError)
  }

  return (
    <Dialog open={modalOpen} onClose={handleClose}>
      <DialogTitle
        disableTypography
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6">Digite seu email para recuperar sua senha</Typography>
        <IconButton onClick={handleClose} size="small" style={{ marginLeft: 'auto' }}>
          <Close></Close>
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <Typography gutterBottom>A Ucred irá lhe enviar um email com um link para redefinir sua senha.</Typography>

        <TextField
          fullWidth
          type="email"
          variant="outlined"
          label="Email"
          placeholder="seuemail@dominio.com"
          value={emailForgot}
          name="emailForgot"
          onChange={handleChange}
          onBlur={handleBlur}
          error={emailInputError}
        />
      </DialogContent>

      <DialogActions>
        <span style={{ position: 'relative' }}>
          <Button variant="contained" color="primary" disabled={loading} onClick={confirmForgot}>
            Confirmar
          </Button>

          {loading && (
            <CircularProgress
              size={24}
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: -12,
                marginLeft: -12,
              }}
            />
          )}
        </span>
      </DialogActions>
    </Dialog>
  )
}
