import { Breadcrumbs, Container, Grid, Typography } from '@material-ui/core'
import { Home } from '@material-ui/icons'
import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../../layout/Footer'
import Header from '../../layout/Header'
import Table from './Table'

class Invoices extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      clientId: null,
      rows: [],
      bar_chart: { series: [], categories: [] },
    }
  }

  render() {
    return (
      <React.Fragment>
        <Header />

        <Container
          style={{
            paddingTop: 32,
            marginTop: 80,
            marginBottom: 32
          }}
          maxWidth="lg"
        >
          <Grid spacing={3} container>
            <Grid item xs={12}>
              <Breadcrumbs className="breadcrumbs">
                <Link to="/" style={{ display: 'flex' }}>
                  <Home
                    style={{
                      marginRight: 8,
                      width: 20,
                      height: 20,
                    }}
                  />
                  Início
                </Link>
                <Typography color="textPrimary">Sinistros</Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item xs={12}>
              <Table parent={this}></Table>
            </Grid>
          </Grid>
        </Container>
      </React.Fragment>
    )
  }
}

export default Invoices
