import { Box, Button, Flex, Text } from '@chakra-ui/react'
import { HiShieldCheck } from 'react-icons/hi'
import React, { useContext } from 'react'
import ChangePaymentMethodContext from '../../../contexts/ChangePaymentMethod'

function TransactionResume() {
  const { selectedPaymentMethod, handleSubmit, loading, renovationData } = useContext(
    ChangePaymentMethodContext,
  ) as unknown as CreatePaymentMethod

  const purchaseValue = () => {
    const renovation: Renovation | null = renovationData ? renovationData.renovation : null
    const renovationCorresponsible: Renovation | null = renovationData
      ? renovationData.renovation_corresponsible
      : null

    const purchase: Purchase = (renovationData && renovationData.purchase) || {}
    const purchaseCorresponsible: Purchase | null =
      (renovationData && renovationData.corresponsible_purchase) || null

    let value = 0
    value = (renovation?.value || 0) + (value || 0)

    if (purchase.client_id === purchaseCorresponsible?.client_id) {
      value = value + (renovationCorresponsible?.value || 0)
    }

    return value.toFixed(2)
  }

  return (
    <Box
      p={{ base: 3, md: 3 }}
      height="100%"
      style={{ background: 'rgb(244, 246, 248)', borderRadius: 16 }}
    >
      <Text
        display={{ base: 'none', md: 'block' }}
        my={5}
        ml={{ base: 6, md: 4 }}
        fontSize="sm"
        fontWeight="bold"
      >
        Resumo
      </Text>

      <Box m={4}>
        <Text
          fontSize="sm"
          fontWeight="bold"
          color="blackAlpha.600"
          align={{ base: 'center', md: 'left' }}
        >
          Valor total
        </Text>
        <Box>
          <Text fontSize="3xl" align={{ base: 'center', md: 'left' }}>
            R$ {purchaseValue()}
          </Text>
        </Box>
      </Box>
      <Flex>
        <Button
          isLoading={loading}
          disabled={loading || (!selectedPaymentMethod && true)}
          onClick={event => handleSubmit(event)}
          colorScheme="blue"
          mx={'auto'}
        >
          Alterar método de pagamento
        </Button>
      </Flex>
      <Flex mt={8} justify="center">
        <HiShieldCheck size={20} color="green"></HiShieldCheck>
        <Text ml="1" fontSize="sm" fontWeight="bold">
          Pagamento seguro
        </Text>
      </Flex>

      <Box>
        <Text fontSize="xs" align="center" maxWidth={200} mx="auto" mb={3}>
          As informações que transitam nesta página são protegidas por criptografia SSL.
        </Text>
      </Box>
    </Box>
  )
}

export default TransactionResume
