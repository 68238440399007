type GetPaymentMethodReturnType =
  | 'Não definido'
  | 'Dinheiro'
  | 'Boleto + Cartão'
  | 'Cartão de crédito'
  | 'Boleto bancário'
  | 'Invalid method'

type GetPaymentMethodProps =
  | 'not_defined'
  | 'cash'
  | 'hybrid'
  | 'credit_card'
  | 'bank_slip'
  | 'bank_slip_1'

export function getPaymentMethod(paymentMethod: GetPaymentMethodProps): GetPaymentMethodReturnType {
  switch (paymentMethod) {
    case 'not_defined':
      return 'Não definido'
    case 'cash':
      return 'Dinheiro'
    case 'hybrid':
      return 'Boleto + Cartão'
    case 'credit_card':
      return 'Cartão de crédito'
    case 'bank_slip':
    case 'bank_slip_1':
      return 'Boleto bancário'
    default:
      return 'Invalid method'
  }
}
