type PaymentLinkProps = {
  clientName: string
  companyName: string
  paymentUrl: string
  contractUrl: string
}

export function getPaymentLink({
  clientName,
  companyName,
  paymentUrl,
  contractUrl,
}: PaymentLinkProps): string {
  const message = `*UCRED, GARANTIA LOCATÍCIA VIA CARTÃO DE CRÉDITO:* 💙💙

Olá ${clientName}, primeiramente estamos lhe dando os parabens por realizar uma locação com a ${companyName} em parceria com a UCred (ucred.io), você vai ver como é simples, fácil e sem burocracia.

Segue abaixo os links para pagamento e o contrato para assinatura:

Link de pagamento: ${paymentUrl || '-'}

Contrato para assinatura: ${contractUrl || '-'}

Caso tenha qualquer dúvida, entre em contato com sua imobiliária.`
  return message
}
