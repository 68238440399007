import moment from 'moment'
import React, { Component } from 'react'
import StepWizard from 'react-step-wizard'
import ApiInstance from '../../services/ApiInstance'
import Button from '../../components/button'
import Input from '../../components/input'
import { consultaCep } from '../../util/cep'
import { removeMask } from '../../util/money'
import { withSnackbar } from 'notistack'

const DadosEmpresa = (props) => {
  const nextStep = () => {
    props.go(props)
  }

  return (
    <>
      <div className="dados-empresa">
        <div className="header">
          <h3>Dados da Empresa</h3>
        </div>
        <div className="body">
          <div className="line">
            <div className="item">
              <Input
                type="text"
                placeholder="Nome fantasia"
                labelDescription="Nome fantasia"
                id="name"
                onChange={props.handleChange}
                value={props.company.name}
              />
            </div>
            <div className="item">
              <div className="cnpj-creci" style={{ display: 'flex' }}>
                <Input
                  id="cnpj"
                  type="text"
                  labelIcon={true}
                  labelIconMessage={'Você pode alternar entre CRECI e CNPJ clicando no botão a direita deste campo.'}
                  mask={!props.component.state.using_creci ? '11.111.111/1111-11' : ''}
                  placeholder={!props.component.state.using_creci ? 'CNPJ' : 'CRECI'}
                  labelDescription={!props.component.state.using_creci ? 'CNPJ' : 'CRECI'}
                  onChange={(event) => {
                    if (event.target.value.length > 20) {
                      return
                    }
                    props.handleChange(event)
                  }}
                  iconType="number"
                  value={props.company.cnpj}
                />

                <span
                  title={props.component.state.using_creci ? 'CADASTRAR COM CNPJ' : 'CADASTRAR COM CRECI'}
                  className="btn"
                  onClick={() => {
                    let component = props.component
                    let company = props.company

                    company.cnpj = ''

                    component.setState({ using_creci: !component.state.using_creci, company })
                  }}
                >
                  <i className="lni lni-reload"></i>
                </span>
              </div>
            </div>
          </div>
          <div className="line">
            <div className="item">
              <Input
                type="text"
                placeholder="CEP"
                labelDescription="CEP"
                id="zip_code"
                onChange={props.handleCep}
                value={props.company.zip_code}
                mask="11111-111"
              />
            </div>
            <div className="item">
              <Input
                type="text"
                placeholder="Cidade"
                labelDescription="Cidade"
                id="city"
                onChange={props.handleChange}
                disabled={props.disabled}
                value={props.company.city}
              />
            </div>
            <div className="item">
              <Input
                type="text"
                placeholder="Bairro"
                labelDescription="Bairro"
                id="neighborhood"
                onChange={props.handleChange}
                disabled={props.disabled}
                value={props.company.neighborhood}
              />
            </div>
            <div className="item">
              <Input
                type="text"
                placeholder="Estado"
                labelDescription="Estado"
                id="state"
                onChange={props.handleChange}
                disabled={props.disabled}
                value={props.company.state}
              />
            </div>
          </div>
          <div className="line">
            <div className="item">
              <Input
                type="text"
                placeholder="Logradouro"
                labelDescription="Logradouro"
                id="street"
                onChange={props.handleChange}
                disabled={props.disabled}
                value={props.company.street}
              />
            </div>
            <div className="item">
              <Input
                type="number"
                placeholder="Número"
                labelDescription="Número"
                id="number"
                onChange={props.handleChange}
                disabled={props.disabled}
                iconType="number"
                value={props.company.number}
              />
            </div>
            <div className="item">
              <Input
                type="text"
                placeholder="Complemento"
                labelDescription="Complemento"
                id="complement"
                onChange={props.handleChange}
                disabled={props.disabled}
                value={props.company.complement}
              />
            </div>
          </div>
          <div className="progress-container">
            <Button description="Continuar" onClick={nextStep} />
          </div>
        </div>
      </div>
    </>
  )
}

const DadosUsuario = (props) => {
  return (
    <div className="dados-usuario">
      <div className="header">
        <h3>Dados do usuário principal</h3>
      </div>
      <div className="body">
        <div className="line">
          <div className="item">
            <Input
              type="text"
              placeholder="Nome completo"
              labelDescription="Nome completo"
              id="user_name"
              onChange={props.handleChange}
              value={props.user.user_name}
            />
          </div>
          <div className="item">
            <Input
              type="text"
              mask="111.111.111-11"
              placeholder="CPF"
              labelDescription="CPF"
              id="cpf"
              onChange={props.handleChange}
              iconType="number"
              value={props.user.cpf}
            />
          </div>
        </div>
        <div className="line">
          <div className="item">
            <Input
              type="text"
              placeholder="E-mail"
              labelDescription="E-mail"
              id="email"
              onChange={props.handleChange}
              iconType="email"
              value={props.user.email}
            />
          </div>
          <div className="item">
            <div className="container-password">
              <Input
                type={props.type}
                placeholder="Senha"
                labelDescription="Senha"
                id="password"
                onChange={props.handleChange}
                iconType="password"
                value={props.user.password}
              />
            </div>
          </div>
          <div className="item">
            <div className="container-password">
              <Input
                type={props.type}
                placeholder="Confirmar senha"
                labelDescription="Confirmar senha"
                id="confirmPassword"
                onChange={props.handleChange}
                iconType="password"
                value={props.user.confirmPassword}
              />
              <i className="lni-eye" onClick={props.showPassword}></i>
            </div>
          </div>
        </div>
        <div className="line">
          <div className="item">
            <Input
              type="text"
              labelDescription="Data de nascimento"
              placeholder="Data de nascimento"
              iconType="number"
              id="birthday"
              mask="11/11/1111"
              onChange={props.handleChange}
              value={props.user.birthday}
            />
          </div>

          <div className="item">
            <Input
              type="text"
              mask="(11) 11111-1111"
              placeholder="(DD) XXXXX-XXXX"
              labelDescription="Telefone celular"
              id="telephone"
              onChange={props.handleChange}
              iconType="number"
              value={props.user.telephone}
            />
          </div>
        </div>

        {props.companyUsers.length ? (
          <div className="header-employee">
            <h3>Outros usuários</h3>
          </div>
        ) : (
          ''
        )}
        {props.currentStep === 2 ? (
          props.companyUsers.map((user, index) => {
            return (
              <div className="employee" key={index}>
                <div className="subheader-employee">
                  <div></div>
                  <h4>Usuário {index + 1}</h4>
                  <div className="close">
                    <i className="lni lni-close" onClick={() => props.handleRemoveCompanyUser(index)}></i>
                  </div>
                </div>
                <div className="company-users-container">
                  <div className="line">
                    <div className="item">
                      <Input
                        id={`name_${index}`}
                        type="text"
                        placeholder="Nome completo"
                        labelDescription="Nome completo"
                        onChange={(event) => props.handleChangeCompanyUser('name', event.target.value, index)}
                        value={user.name}
                      />
                    </div>
                    <div className="item">
                      <Input
                        id={`email_${index}`}
                        type="text"
                        placeholder="E-mail"
                        labelDescription="E-mail"
                        onChange={(event) => props.handleChangeCompanyUser('email', event.target.value, index)}
                        value={user.email}
                      />
                    </div>
                    <div className="item">
                      <Input
                        id={`cpf_${index}`}
                        type="text"
                        mask="111.111.111-11"
                        placeholder="CPF"
                        labelDescription="CPF"
                        onChange={(event) => props.handleChangeCompanyUser('cpf', event.target.value, index)}
                        value={user.cpf}
                      />
                    </div>
                  </div>
                  <div className="line">
                    <div className="item">
                      <Input
                        id={`password_${index}`}
                        type="password"
                        iconType="password"
                        placeholder="Senha"
                        labelDescription="Senha"
                        onChange={(event) => props.handleChangeCompanyUser('password', event.target.value, index)}
                        value={user.password}
                      />
                    </div>
                    <div className="item">
                      <Input
                        id={`birthday_${index}`}
                        type="text"
                        labelDescription="Data de nascimento"
                        placeholder="Data de nascimento"
                        iconType="number"
                        mask="11/11/1111"
                        onChange={(event) => props.handleChangeCompanyUser('birthday', event.target.value, index)}
                        value={user.birthday}
                      />
                    </div>
                    <div className="item">
                      <Input
                        id={`telephone_${index}`}
                        type="text"
                        mask="(11) 11111-1111"
                        placeholder="(DD) XXXXX-XXXX"
                        labelDescription="Telefone celular"
                        onChange={(event) => props.handleChangeCompanyUser('telephone', event.target.value, index)}
                        value={user.telephone}
                      />
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
            )
          })
        ) : (
          <></>
        )}
        <div className="progress-container">
          <p></p>
          {!props.loading && (
            <div className="second-step-buttons">
              <Button description="Adicionar usuário" onClick={() => props.handleAddCompanyUser()} />
              <Button description="Continuar" onClick={() => props.go(props)} />
            </div>
          )}
          {props.loading && <div className="loader" />}
          <div className="back-button" style={{ position: 'absolute', left: '10px' }}>
            <Button description="Voltar" onClick={() => props.previousStep()} />
          </div>
        </div>
      </div>
    </div>
  )
}

const Planos = (props) => {
  const save = () => {
    props.save(props)
  }
  return (
    <div className="planos">
      <div className="container-planos">
        <div className="plano-card plano-card-2" onClick={() => props.selectPlan(2)}>
          <div className="plano-icone">
            <img src="https://i.ibb.co/mJ7FRKN/SIMBOLO-GRAFICO.png" alt="logo" />
          </div>
          <div className="plano-header">
            <h2>GARANTIA UCRED</h2>
          </div>
          <div className="plano-body">
            <div className="plano-body-linha">
              <b>Taxa de Ativação: </b> <span> R$ 0,00 </span>
            </div>
          </div>
        </div>
      </div>
      {props.loading ? (
        <div className="loader" />
      ) : (
        <div style={{ marginLeft: '37%' }}>
          <span>
            <Button description="Voltar" onClick={() => props.previousStep()} />
          </span>
          <span style={{ marginLeft: '30px' }}>
            <Button disabled={props.loading} description="CONCLUIR CADASTRO" onClick={save} />
          </span>
        </div>
      )}
    </div>
  )
}

class NovaImobiliaria extends Component {
  state = {
    openModal: false,
    isShowing: false,
    using_creci: false,
    loading: false,
    disabled: true,
    aceite: 0,
    plan: 0,
    type: 'password',
    coupon: {
      value: '',
      searching: false,
    },
    company: {
      zip_code: null,
      city: null,
      street: null,
      neighborhood: null,
      complement: null,
      number: null,
      cnpj: null,
      name: null,
    },
    user: {
      user_name: null,
      cpf: null,
      email: null,
      password: null,
      confirmPassword: null,
      telephone: null,
      birthday: null,
    },
    companyUsers: [],
  }

  handleAddCompanyUser = () => {
    let companyUsers = this.state.companyUsers
    if (companyUsers.length >= 2) {
      const snackbarKey = this.props.enqueueSnackbar('Você pode adicionar apenas 2 usuários no cadastro.', {
        variant: 'error',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
      return
    }

    companyUsers.push({ name: '', email: '', cpf: '', password: '', confirmed_email: 1, telephone: '', birthday: '' })

    this.setState({ companyUsers })
  }

  handleRemoveCompanyUser = (index) => {
    let companyUsers = this.state.companyUsers

    companyUsers.splice(index, 1)

    this.setState({ companyUsers: companyUsers })
  }

  handleChangeCompanyUser = (name, value, index) => {
    let companyUsers = this.state.companyUsers

    companyUsers[index][name] = value

    this.setState(companyUsers)
  }

  handleModal = (status) => {
    this.setState({ openModal: status })
  }

  handleChangeCompany = (event) => {
    const { id, value } = event.currentTarget
    const { company } = this.state
    this.setState({ company: { ...company, [id]: value } })
  }

  handleChangeUser = (event) => {
    const { id, value } = event.currentTarget
    const { user } = this.state
    this.setState({ user: { ...user, [id]: value } })
  }

  setLoading = (value) => {
    this.setState({ loading: value })
  }

  validarCPF = (cpf) => {
    let soma = 0
    let resto = 0
    let i = 0
    let j = 0

    cpf = cpf.replace('.', '')
    cpf = cpf.replace('.', '')
    cpf = cpf.replace('-', '')

    if (
      cpf === '00000000000' ||
      cpf === '11111111111' ||
      cpf === '22222222222' ||
      cpf === '33333333333' ||
      cpf === '44444444444' ||
      cpf === '55555555555' ||
      cpf === '66666666666' ||
      cpf === '77777777777' ||
      cpf === '88888888888' ||
      cpf === '99999999999'
    ) {
      throw new Error('CPF Inválido')
    }

    for (i = 1; i <= 9; i++) {
      soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i)
    }

    resto = (soma * 10) % 11

    if (resto === 10 || resto === 11) {
      resto = 0
    }

    if (resto !== parseInt(cpf.substring(9, 10))) {
      throw new Error('CPF Inválido')
    }

    soma = 0

    for (j = 1; j <= 10; j++) {
      soma = soma + parseInt(cpf.substring(j - 1, j)) * (12 - j)
    }

    resto = (soma * 10) % 11

    if (resto === 10 || resto === 11) {
      resto = 0
    }

    if (resto !== parseInt(cpf.substring(10, 11))) {
      throw new Error('CPF Inválido')
    }

    return true
  }

  validarCNPJ = (cnpj) => {
    if (document.getElementsByClassName('error')[0])
      document.getElementsByClassName('error')[0].classList.remove('error')
    cnpj = cnpj.replace(/[^\d]+/g, '')
    if (cnpj === '') {
      document.getElementById('cnpj').focus()
      document.getElementById('cnpj').classList.add('error')
      throw new Error('CNPJ inválido')
    }
    if (cnpj.length !== 14) {
      document.getElementById('cnpj').focus()
      document.getElementById('cnpj').classList.add('error')
      throw new Error('CNPJ inválido')
    }
    // Elimina CNPJs invalidos conhecidos
    if (
      cnpj === '00000000000000' ||
      cnpj === '11111111111111' ||
      cnpj === '22222222222222' ||
      cnpj === '33333333333333' ||
      cnpj === '44444444444444' ||
      cnpj === '55555555555555' ||
      cnpj === '66666666666666' ||
      cnpj === '77777777777777' ||
      cnpj === '88888888888888' ||
      cnpj === '99999999999999' ||
      cnpj === '27064491000137'
    ) {
      document.getElementById('cnpj').focus()
      document.getElementById('cnpj').classList.add('error')
      throw new Error('CNPJ inválido')
    }
    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0, tamanho)
    //let digitos = cnpj.substring(tamanho);
    let soma = 0
    let pos = tamanho - 7
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--
      if (pos < 2) pos = 9
    }
    //let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    // alert(resultado)
    // alert(digitos)
    // if (resultado !== digitos.charAt(0)) {
    //     document.getElementById('cnpj').focus()
    //     document.getElementById('cnpj').classList.add('error')
    //     throw new Error('CNPJ inválido4')
    // }
    tamanho = tamanho + 1
    numeros = cnpj.substring(0, tamanho)
    soma = 0
    pos = tamanho - 7
    for (let i = tamanho; i >= 1; i--) {
      soma = soma + numeros.charAt(tamanho - i) * pos--
      if (pos < 2) pos = 9
    }
    // resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    // if (resultado !== digitos.charAt(1)){
    //     document.getElementById('cnpj').focus()
    //     document.getElementById('cnpj').classList.add('error')
    //     throw new Error('CNPJ inválido5')
    // }

    return true
  }

  save = async (props) => {
    if (document.getElementsByClassName('error')[0])
      document.getElementsByClassName('error')[0].classList.remove('error')
    try {
      this.setLoading(true)
      const { user, company, using_creci, plan, companyUsers } = this.state
      if (!plan) throw new Error('Selecione um Plano')
      this.verifyUser()
      // this.verifytermos()
      const data = {
        ...company,
        ...user,
        using_creci,
        companyUsers,
        plan,
      }
      await ApiInstance.post('empresa', data)
      this.setLoading(false)
      const snackbarKey = this.props.enqueueSnackbar('Criado com sucesso.', {
        variant: 'success',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
      this.props.history.push('/')
    } catch (e) {
      this.setLoading(false)
      if (e.message === 'EMAIL') {
        document.getElementById('email').focus()
        document.getElementById('email').classList.add('error')
        props.goToStep(2)
        const snackbarKey = this.props.enqueueSnackbar('Email já cadastrado na base.', {
          variant: 'error',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        })
        return
      }
      if (e.message === 'CNPJ') {
        document.getElementById('cnpj').focus()
        document.getElementById('cnpj').classList.add('error')
        props.firstStep()
        const snackbarKey = this.props.enqueueSnackbar('CNPJ já cadastrado na base.', {
          variant: 'error',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        })
        return
      }
      const snackbarKey = this.props.enqueueSnackbar(e.message, {
        variant: 'error',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
      return
    }
  }

  avanca = (props) => {
    try {
      this.verifyCompany()
      if (!this.state.using_creci) {
        this.validarCNPJ(removeMask(this.state.company.cnpj))
      }
      props.nextStep()
    } catch (e) {
      const snackbarKey = this.props.enqueueSnackbar(e.message, {
        variant: 'error',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
    }
  }

  avancaUsu = (props) => {
    try {
      this.verifyUser()
      this.verifyExtraUsers()
      this.validarCPF(this.state.user.cpf)
      props.nextStep()
    } catch (e) {
      const snackbarKey = this.props.enqueueSnackbar(e.message, {
        variant: 'error',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
    }
  }

  verifyCompany = () => {
    let { company } = this.state
    const REQUIRED_FIELD = [
      'name',
      'cnpj',
      'zip_code',
      'city',
      'neighborhood',
      'street',
      'number',
      'cnpj',
      'name',
      'state',
    ]

    if (company.zip_code) {
      if (removeMask(company.zip_code).length < 8) {
        throw new Error('Informe o cep')
      }
    }

    if (document.getElementsByClassName('error')[0])
      document.getElementsByClassName('error')[0].classList.remove('error')
    REQUIRED_FIELD.map((field) => {
      if (!company[field]) {
        document.getElementById(field).focus()
        document.getElementById(field).classList.add('error')
        throw new Error('Por favor, Preencha todos os campos')
      }
      return null
    })
  }

  verifyUser = () => {
    let { user } = this.state
    const REQUIRED_FIELD = ['user_name', 'cpf', 'email', 'password', 'birthday', 'telephone']
    if (user.name) {
      const userName = user.name
      let userFullName = userName.split(' ')
      if (!userFullName[1]) {
        throw new Error('Informe nome e sobrenome')
      }
    }
    if (user.birthday) {
      const birthdayArr = user.birthday.split('/')
      const userBirthday = birthdayArr[2]
      const yearActual = moment().format('YYYY')
      const age = Number(yearActual) - Number(userBirthday)
      if (birthdayArr[0] === 0 || birthdayArr[0] > 31) {
        throw new Error('Dia Inválido')
      }
      if (birthdayArr[1] === 0 || birthdayArr[1] > 12) {
        throw new Error('Mês Inválido')
      }
      if (age <= 18 || age >= 110) {
        throw new Error('Tem que possuir mais de 18 anos')
      }
    }
    if (user.telephone) {
      const userTelephone = removeMask(user.telephone)
      if (userTelephone.length < 14) {
        throw new Error('Informe o telefone')
      }
    }
    if (document.getElementsByClassName('error')[0])
      document.getElementsByClassName('error')[0].classList.remove('error')
    REQUIRED_FIELD.map((field) => {
      if (!user[field]) {
        document.getElementById(field).focus()
        document.getElementById(field).classList.add('error')
        throw new Error('Por favor, Preencha todos os campos')
      }
      return null
    })

    if (!user.confirmPassword) {
      throw new Error('Confirme a senha')
    } else if (user.password !== user.confirmPassword) {
      throw new Error('Senhas não conferem')
    }
  }

  verifyExtraUsers = () => {
    let companyUsers = this.state.companyUsers
    const REQUIRED_FIELD = ['name', 'email', 'cpf', 'password', 'birthday', 'telephone']

    companyUsers.map((user, index) => {
      if (user.name) {
        const userName = user.name
        let userFullName = userName.split(' ')
        if (!userFullName[1]) {
          throw new Error('Informe nome e sobrenome')
        }
        return null
      }
      if (user.birthday) {
        const birthdayArr = user.birthday.split('/')
        const userBirthday = birthdayArr[2]
        const yearActual = moment().format('YYYY')
        const age = Number(yearActual) - Number(userBirthday)
        if (birthdayArr[0] === 0 || birthdayArr[0] > 31) {
          throw new Error('Dia Inválido')
        }
        if (birthdayArr[1] === 0 || birthdayArr[1] > 12) {
          throw new Error('Mês Inválido')
        }
        if (age <= 18 || age >= 110) {
          throw new Error('Tem que possuir mais de 18 anos')
        }
      }

      if (user.telephone) {
        const userTelephone = removeMask(user.telephone)
        if (userTelephone.length < 14) {
          throw new Error('Informe o telefone')
        }
      }

      if (document.getElementsByClassName('error')[0])
        document.getElementsByClassName('error')[0].classList.remove('error')

      REQUIRED_FIELD.map((field) => {
        if (!user[field]) {
          document.getElementById(field + '_' + index).focus()
          document.getElementById(field + '_' + index).classList.add('error')
          throw new Error('Por favor, Preencha todos os campos')
        }
        return null
      })
      /**
        if(!user.confirmPassword) {
            throw new Error('Confirme a senha')
        } else if (user.password !== user.confirmPassword) {
            throw new Error('Senhas não conferem')
        } 
        */
    })
  }

  selectPlan = (plan) => {
    document.querySelector('.plano-card-2').style.borderColor = '#009fff'
    // document.querySelector('.plano-card-1').style.borderColor = "#009fff";
    // if(plan === 3) document.querySelector('.plano-card-1').style.borderColor = "#00e3cf";
    if (plan === 2) document.querySelector('.plano-card-2').style.borderColor = '#00e3cf'
    this.setState({ plan })
  }

  handleCep = async (event) => {
    const { value } = event.currentTarget
    const { company } = this.state
    let companyData = {}
    if (removeMask(value).length === 8) {
      let respCep = await consultaCep(removeMask(value))
      companyData = {
        city: respCep.localidade,
        street: respCep.logradouro,
        neighborhood: respCep.bairro,
        state: respCep.uf,
      }
    }
    await this.setState({ company: { ...company, ...companyData, zip_code: removeMask(value) }, disabled: false })
  }

  showPassword = () => {
    const { type } = this.state
    let newType = null
    if (type === 'password') newType = 'text'
    if (type === 'text') newType = 'password'
    this.setState({ type: newType })
  }

  render() {
    return (
      <div className="nova-imobiliaria">
        <div className="nova-imobiliaria-container">
          <div className="nova-imobiliaria-card">
            <div className="nova-imobiliaria-card--img"></div>
            <div className="nova-imobiliaria-card--step">
              <div className="nova-imobiliaria-card-welcome">
                <h3>Olá, será um prazer ter você com a gente</h3>
                <small>Mas antes precisamos que você preencha alguns dados</small>
              </div>
              <div className="step-container">
                <StepWizard>
                  <DadosEmpresa
                    handleChange={this.handleChangeCompany}
                    go={this.avanca}
                    handleCep={this.handleCep}
                    component={this}
                    company={this.state.company}
                    disabled={this.state.disabled}
                  />

                  <DadosUsuario
                    openModal={this.state.openModal}
                    handleModal={this.handleModal}
                    loading={this.state.loading}
                    setLoading={this.setLoading}
                    save={this.save}
                    user={this.state.user}
                    handleChange={this.handleChangeUser}
                    handleChangeCompanyUser={this.handleChangeCompanyUser}
                    handleRemoveCompanyUser={this.handleRemoveCompanyUser}
                    aceite={this.state.aceite}
                    // handleTermos={this.handleTermos}
                    coupon={this.state.coupon}
                    handleCoupon={this.handleCoupon}
                    go={this.avancaUsu}
                    type={this.state.type}
                    showPassword={this.showPassword}
                    handleAddCompanyUser={this.handleAddCompanyUser}
                    companyUsers={this.state.companyUsers}
                  />

                  <Planos
                    selectPlan={this.selectPlan}
                    save={this.save}
                    loading={this.state.loading}
                    setLoading={this.setLoading}
                  />
                </StepWizard>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withSnackbar(NovaImobiliaria)
