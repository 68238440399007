/* -------------------------------------------------------------------------- */
/*        A VALIDAÇÃO RETORNARÁ UM OBJETO CONTENDO "success" ou "error"       */
/* -------------------------------------------------------------------------- */
  const validateCpf = (cpf) => { 
    if (!cpf) {
      return {
        error: "O CPF não foi informado!",
        provided: cpf || undefined,
        required: "###.###.###-##"
      };
    }

    cpf = cpf.match(/([0-9])/g);

    if (cpf.length !== 11) {
      return {
        error: "O CPF informado está com o formato incorreto!",
        provided: cpf,
        required: "###.###.###-##"
      };
    }

    let validacao = [10, 9, 8, 7, 6, 5, 4, 3, 2];
    var calc = 0;
    for (let i = 0; i < 9; i++) {
      calc += validacao[i] * Number(cpf[i]);
    }

    let res = calc % 11;

    if (res < 2) {
      if (Number(cpf[9]) !== 0) {
        return {
          error:
            "O CPF informado está com o formato correto, porém é inválido!",
          provided: cpf,
          required: "###.###.###-##"
        };
      }
    } else {
      if (Number(cpf[9]) !== 11 - res) {
        return {
          error:
            "O CPF informado está com o formato correto, porém é inválido!",
          provided: cpf,
          required: "###.###.###-##"
        };
      }
    }

    calc = 0;
    validacao = [11, 10, 9, 8, 7, 6, 5, 4, 3, 2];

    for (let i = 0; i < 10; i++) {
      calc += validacao[i] * Number(cpf[i]);
    }

    res = calc % 11;

    if (res < 2) {
      if (Number(cpf[10]) !== 0) {
        return {
          error:
            "O CPF informado está com o formato correto, porém é inválido!",
          provided: cpf,
          required: "###.###.###-##"
        };
      }
    } else {
      if (Number(cpf[10]) !== 11 - res) {
        return {
          error:
            "O CPF informado está com o formato correto, porém é inválido!",
          provided: cpf,
          required: "###.###.###-##"
        };
      }
    }

    return {
      success: "O CPF informado é válido!",
      provided: cpf,
      required: "###.###.###-##"
    }
  }

  export default validateCpf