import { Button, ButtonGroup, Dialog, DialogActions, DialogTitle, Divider, FormControl, IconButton, MenuItem, Select, TextField, Tooltip, Typography } from '@material-ui/core'
import { Close, Help } from '@material-ui/icons'
import moment from 'moment'
import React from 'react'
import ReactCreditCard from 'react-credit-cards'
import MaskedInput from 'react-input-mask'
import ApiInstance from '../../services/ApiInstance'
import vindiErrorMessage from '../../functions/vindiErrorMessage'
import { moneyToNumber } from '../../util/money'

export default class CreditCard extends React.Component {
  state = {
    loading: false,
    selected_card: 1,
    open: false,
    credit_card: {
      name: '',
      number: '',
      expiry: '',
      cvv: '',
      card_maturity: '',
      focus: '',
      issuer: '',
      errors: {
        name: '',
        number: '',
        expiry: '',
        cvv: '',
        card_maturity: '',
      },
    },
    second_credit_card: {
      name: '',
      number: '',
      expiry: '',
      cvv: '',
      focus: '',
      issuer: '',
      errors: {
        name: '',
        number: '',
        expiry: '',
        cvv: '',
        card_maturity: '',
        focus: '',
        issuer: '',
      },
    },
  }

  componentDidMount() {
    const { card } = this.props.parent.state
    this.setState({
      credit_card: { ...this.state.credit_card, name: card.name },
      second_credit_card: { ...this.state.second_credit_card, name: card.name },
    })
  }

  getIssuer = () => {
    this.setState({ credit_card: { ...this.state.credit_card } })
  }

  getIssuerCorresp = () => {
    this.setState({ second_credit_card: { ...this.state.second_credit_card } })
  }

  render() {
    const { selected_card, credit_card, second_credit_card, loading, open } = this.state
    const { adhesion, purchase, purchase_parcel_options, corresponsible_purchase, purchase_selected_option, adhesion_parcel_options, adhesion_selected_option, twoCards, hash } =
      this.props.parent.state

    const total_value =
      Number(moneyToNumber(purchase.value)) +
      (corresponsible_purchase.value ? Number(moneyToNumber(corresponsible_purchase.value)) : 0) +
      (adhesion.length ? Number(moneyToNumber(adhesion[0].value)) : 0)

    const handleChangeCreditCard = async (event) => {
      let { name, value } = event.target
      let credit_card = this.state.credit_card
      let dateIsValid = await this.props.parent.validateDate(value)

      if (name === 'number') credit_card.errors.number = false

      if (name === 'expiry' && dateIsValid) {
        credit_card.errors.expiry = true
        this.setState({ credit_card })
      } else if (name === 'expiry' && !dateIsValid) {
        credit_card.errors.expiry = false
        this.setState({ credit_card })
      }

      if (name === 'card_maturity' && value > 30) {
        value = 30
      }

      if (name === 'cvv') credit_card.errors.cvv = false

      this.setState({ credit_card: { ...credit_card, [name]: value } })
    }

    const handleChangeSecondCreditCard = async (event) => {
      let { name, value } = event.target
      let second_credit_card = this.state.second_credit_card
      let dateIsValid = await this.props.parent.validateDate(value)

      if (name === 'expiry' && dateIsValid) {
        second_credit_card.errors.expiry = true
        this.setState({ second_credit_card })
      } else {
        second_credit_card.errors.expiry = false
        this.setState({ second_credit_card })
      }

      if (name === 'cvv') second_credit_card.errors.cvv = false

      this.setState({ second_credit_card: { ...second_credit_card, [name]: value } })
    }

    const openConfirmation = () => {
      if (!credit_card.cvv || !credit_card.number || !credit_card.card_maturity || !credit_card.expiry) {
        this.setState({ selected_card: 1 })
        return this.props.parent.setState({
          loading: false,
          snackbar: { message: 'Informe todos os dados do primeiro cartão', severity: 'error', duration: 6000, open: true },
        })
      }

      if (twoCards === 'true' && (!second_credit_card.cvv || !second_credit_card.number || !second_credit_card.expiry)) {
        this.setState({ selected_card: 2 })
        return this.props.parent.setState({
          loading: false,
          snackbar: { message: 'Informe todos os dados do segundo cartão', severity: 'error', duration: 6000, open: true },
        })
      }
      this.setState({ open: true })
    }

    const createOnlinePurchase = async () => {
      this.props.parent.setState({ loading: true })

      if (credit_card.number.length < 18) {
        this.props.parent.setState({ loading: false })
        return this.setState({
          credit_card: { ...credit_card, errors: { ...credit_card.errors, number: true } },
          selected_card: 1,
          open: false,
        })
      }

      if (twoCards === 'true' && second_credit_card.number.length < 19) {
        this.props.parent.setState({ loading: false })
        return this.setState({
          second_credit_card: { ...second_credit_card, errors: { ...second_credit_card.errors, number: true } },
          selected_card: 2,
          open: false,
        })
      }

      if (twoCards === 'true') {
        await ApiInstance.post('vendaOnlineTwoCards', {
          hash,
          cvv: credit_card.cvv,
          card_number: credit_card.number,
          card_holder: credit_card.name,
          card_maturity: Number(credit_card.card_maturity),
          expiration_date: credit_card.expiry,
          type_card: credit_card.issuer,

          cvv_corresp: second_credit_card.cvv,
          card_number_corresp: second_credit_card.number,
          card_holder_corresp: second_credit_card.name,
          expiration_date_corresp: second_credit_card.expiry,
          type_card_corresp: second_credit_card.issuer,

          //purchaseParcel: { ...purchaseParcel, valueCorresp: this.state.value_corresp / this.state.purchaseParcel.quantity },

          purchaseParcel: {
            ...purchase_parcel_options[purchase_selected_option],
            valueCorresp: Number(moneyToNumber(corresponsible_purchase.value)) / (purchase_selected_option + 1),
          },
          adhesionParcel: adhesion_parcel_options[adhesion_selected_option],
        })
          .then(() => {
            this.props.parent.setState({
              snackbar: { message: 'Transação paga com sucesso!', severity: 'success', duration: 6000, open: true },
            })

            window.location.reload()
          })
          .catch((error) => {
            let errorMessage = error.message ? vindiErrorMessage(error.message, twoCards) : ''
            let messageCode = error.message ? error.message.split('|')[1] : ''

            if (messageCode === 'N7') {
              if (errorMessage.split(':')[0] === 'Segundo cartão') {
                this.setState({
                  second_credit_card: { ...second_credit_card, errors: { ...second_credit_card.errors, cvv: true } },
                  selected_card: 2,
                  open: false,
                })
              } else {
                this.setState({ credit_card: { ...credit_card, errors: { ...credit_card.errors, cvv: true } }, selected_card: 1, open: false })
              }
            }
            this.props.parent.setState({
              loading: false,
              snackbar: { message: errorMessage || 'Houve um erro ao processar a transação.', severity: 'error', duration: 6000, open: true },
            })
          })
      } else {
        await ApiInstance.post('vendaOnline', {
          hash,
          cvv: credit_card.cvv,
          card_number: credit_card.number,
          card_holder: credit_card.name,
          card_maturity: Number(credit_card.card_maturity),
          expiration_date: credit_card.expiry,
          type_card: credit_card.issuer,

          purchaseParcel: purchase_parcel_options[purchase_selected_option],
          adhesionParcel: adhesion_parcel_options[adhesion_selected_option],
        })
          .then(() => {
            this.props.parent.setState({
              loading: false,
              snackbar: { message: 'Transação paga com sucesso!', severity: 'success', duration: 6000, open: true },
            })

            window.location.reload()
          })
          .catch((error) => {
            let errorMessage = error.message ? vindiErrorMessage(error.message, this.state.twoCards) : ''

            this.props.parent.setState({
              loading: false,
              snackbar: { message: errorMessage || 'Houve um erro ao processar a transação.', severity: 'error', duration: 6000, open: true },
            })
          })
      }
    }

    return (
      <div id="credit_card">
        <Dialog
          disableScrollLock
          open={open}
          onClose={() => {
            this.setState({ open: false })
          }}
          className="dialog"
        >
          <DialogTitle
            disableTypography
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6">Verifique seus dados antes de prosseguir</Typography>
            <IconButton
              onClick={() => {
                this.setState({ open: false })
              }}
              size="small"
              style={{ marginLeft: 'auto' }}
            >
              <Close></Close>
            </IconButton>
          </DialogTitle>

          <Divider></Divider>

          <div className="dialog-body">
            <Typography variant="body1" component="p" className="total_value text">
              Número do primeiro cartão
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p" className="total_value value">
              **** **** **** {credit_card.number.substr(credit_card.number.length - 4, 4)}
            </Typography>

            {twoCards !== 'false' && (
              <>
                <Typography variant="body1" component="p" className="total_value text" style={{ marginTop: 8 }}>
                  Número do segundo cartão
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p" className="total_value value">
                  **** **** **** {second_credit_card.number.substr(second_credit_card.number.length - 4, 4)}
                </Typography>
              </>
            )}

            {!!adhesion[0] && (
              <>
                <Typography variant="body1" component="p" className="total_value text" style={{ marginTop: 8 }}>
                  Valor da adesão
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p" className="total_value value">
                  R$ {adhesion[0].value.toFixed(2).replace('.', ',')} ({adhesion_parcel_options[adhesion_selected_option].label})
                </Typography>
              </>
            )}

            <Typography variant="body1" component="p" className="total_value text" style={{ marginTop: 8 }}>
              Valor da transação no primeiro cartão
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p" className="total_value value">
              {purchase.value} ({purchase_parcel_options[purchase_selected_option].label})
            </Typography>

            {twoCards !== 'false' && (
              <>
                <Typography variant="body1" component="p" className="total_value text" style={{ marginTop: 8 }}>
                  Valor da transação no segundo cartão
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p" className="total_value value">
                  {corresponsible_purchase.value} ({purchase_parcel_options[purchase_selected_option].label_corresp})
                </Typography>
              </>
            )}
          </div>
          <Divider></Divider>
          <div className="dialog-body">
            <Typography variant="h6" component="p" className="total_value text">
              Valor total
            </Typography>
            <Typography variant="h6" color="textSecondary" component="p" className="total_value value">
              R$ {total_value.toFixed(2).replace('.', ',')}
            </Typography>
          </div>

          <Divider></Divider>

          <DialogActions>
            <Button
              disabled={loading}
              onClick={() => {
                this.setState({ open: false })
              }}
              color="primary"
            >
              Cancelar
            </Button>
            <Button disabled={loading} onClick={createOnlinePurchase} color="primary" variant="contained">
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>

        <div className="container-card">
          <div className="header-comp" style={{ height: 62 }}>
            <Typography variant="h1" component="h1">
              Dados do cartao
            </Typography>
          </div>

          <Divider />

          {twoCards === 'true' && (
            <div className="buttons">
              <ButtonGroup disableElevation color="primary">
                <Button id="primary-card" onClick={() => this.setState({ selected_card: 1 })} variant={selected_card === 1 ? 'contained' : 'outlined'}>
                  Cartao primario
                </Button>
                <Button id="secundary-card-button" onClick={() => this.setState({ selected_card: 2 })} variant={selected_card === 2 ? 'contained' : 'outlined'}>
                  Cartao secundario
                </Button>
              </ButtonGroup>
            </div>
          )}

          {selected_card === 1 ? (
            <>
              <div className="cards">
                <div className="column">
                  <ReactCreditCard
                    className="credit_card_image"
                    name={credit_card.name}
                    number={credit_card.number}
                    expiry={credit_card.expiry}
                    cvc={credit_card.cvv}
                    focused={credit_card.focus}
                    locale={{ valid: 'Data Venc' }}
                    placeholders={{ name: 'Nome do Titular' }}
                  />
                  <FormControl className="form">
                    <TextField
                      disabled
                      className="venda-online-input"
                      size="small"
                      value={credit_card.name}
                      onChange={handleChangeCreditCard}
                      name="name"
                      label="Titular do Cartão"
                      placeholder="João S M Silveira"
                      onFocus={() => this.setState({ credit_card: { ...credit_card, focus: 'name' } })}
                      onBlur={() => this.setState({ credit_card: { ...credit_card, focus: '' } })}
                      fullWidth
                    />

                    <MaskedInput
                      id="card-number-input"
                      className="venda-online-input"
                      mask="9999 9999 9999 9999"
                      name="number"
                      label="Número do cartão"
                      placeholder="4444 4444 4444 4448"
                      maskChar=""
                      fullWidth
                      size="small"
                      onFocus={() => this.setState({ credit_card: { ...credit_card, focus: 'number' } })}
                      onBlur={() => this.setState({ credit_card: { ...credit_card, focus: '' } })}
                      value={credit_card.number}
                      error={credit_card.errors.number}
                      onChange={handleChangeCreditCard}
                    >
                      {(inputProps) => <TextField {...inputProps} />}
                    </MaskedInput>

                    <MaskedInput
                      className="venda-online-input"
                      mask="99/99"
                      error={credit_card.errors.expiry}
                      helperText={credit_card.errors.expiry && 'A expiração deve superior a ' + moment().add(12, 'months').format('MM/YY') + '.'}
                      name="expiry"
                      label="Data de expiração"
                      placeholder="12/26"
                      maskChar=""
                      fullWidth
                      size="small"
                      onFocus={() => this.setState({ credit_card: { ...credit_card, focus: 'expiry' } })}
                      onBlur={() => this.setState({ credit_card: { ...credit_card, focus: '' } })}
                      value={credit_card.expiry}
                      onChange={handleChangeCreditCard}
                    >
                      {(inputProps) => <TextField {...inputProps} />}
                    </MaskedInput>

                    <MaskedInput
                      className="venda-online-input"
                      mask="9999"
                      id="cvc"
                      name="cvv"
                      label="Cód. de segurança"
                      placeholder="153"
                      maskChar=""
                      fullWidth
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <Tooltip
                            arrow
                            position="start"
                            title="O número código de segurança é representado pelos três últimos dígitos no verso do seu cartão. Em cartões American Express, o código de segurança é um número de 4 dígitos na frente do cartão."
                          >
                            <Help></Help>
                          </Tooltip>
                        ),
                      }}
                      value={credit_card.cvv}
                      error={credit_card.errors.cvv}
                      onFocus={() => this.setState({ credit_card: { ...credit_card, focus: 'cvc' } })}
                      onBlur={() => this.setState({ credit_card: { ...credit_card, focus: '' } })}
                      onChange={handleChangeCreditCard}
                    >
                      {(inputProps) => <TextField {...inputProps} />}
                    </MaskedInput>

                    <MaskedInput
                      className="venda-online-input"
                      mask={'99'}
                      name="card_maturity"
                      label="Dia do vencimento da fatura"
                      placeholder="20"
                      maskChar=""
                      fullWidth
                      size="small"
                      value={credit_card.card_maturity}
                      onChange={handleChangeCreditCard}
                    >
                      {(inputProps) => <TextField {...inputProps} />}
                    </MaskedInput>
                  </FormControl>
                </div>
                <Divider flexItem orientation="vertical"></Divider>
                <div className="column second">
                  <div className="item">
                    <Typography variant="body1" component="p">
                      Endereço
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      {purchase.address}
                    </Typography>
                  </div>
                  <div className="item">
                    <Typography variant="body1" component="p">
                      Cidade
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      {purchase.city}
                    </Typography>
                  </div>
                  <div className="item">
                    <Typography variant="body1" component="p">
                      CEP
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      {purchase.cep}
                    </Typography>
                  </div>
                  <Divider style={{ margin: '16px 0' }} />
                  {!!adhesion.length && (
                    <div className="item">
                      <Typography variant="body1" component="p">
                        Parcelas da adesão
                      </Typography>
                      <FormControl>
                        <Select
                          labelId="demo-simple-select-label"
                          value={adhesion_selected_option}
                          onChange={(event) => {
                            const { value } = event.target
                            return this.props.parent.setState({ adhesion_selected_option: value })
                          }}
                        >
                          {adhesion_parcel_options.map((parcel, index) => {
                            return (
                              <MenuItem key={index} value={index}>
                                {parcel.label}
                              </MenuItem>
                            )
                          })}
                        </Select>
                      </FormControl>
                    </div>
                  )}
                  <div className="item">
                    <Typography variant="body1" component="p">
                      Parcelamento da venda
                    </Typography>
                    <FormControl>
                      <Select
                        labelId="demo-simple-select-label"
                        value={purchase_selected_option}
                        onChange={(event) => {
                          const { value } = event.target
                          return this.props.parent.setState({ purchase_selected_option: value })
                        }}
                      >
                        {purchase_parcel_options.map((parcel, index) => {
                          return (
                            <MenuItem key={index} value={index}>
                              {parcel.label}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                  </div>
                  <Divider style={{ margin: '16px 0' }} />
                  <div className="item">
                    <Typography variant="body1" component="p" className="total_value text">
                      Valor total
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" className="total_value value">
                      R$ {total_value.toFixed(2).replace('.', ',')}
                    </Typography>
                  </div>
                </div>
              </div>
            </>
          ) : (
            ''
          )}

          {selected_card === 2 ? (
            <>
              <div className="cards">
                <div className="column">
                  <ReactCreditCard
                    disabled
                    className="credit_card_image"
                    preview={false}
                    name={second_credit_card.name}
                    number={second_credit_card.number}
                    expiry={second_credit_card.expiry}
                    cvc={second_credit_card.cvv}
                    focused={second_credit_card.focus}
                    locale={{ valid: 'Data Venc' }}
                    placeholders={{ name: 'Nome do Titular' }}
                  />
                  <FormControl className="form">
                    <TextField
                      disabled
                      className="venda-online-input"
                      size="small"
                      value={second_credit_card.name}
                      onChange={handleChangeSecondCreditCard}
                      name="name"
                      label="Titular do Cartão"
                      placeholder="Joao S M Silveira"
                      onFocus={() => this.setState({ second_credit_card: { ...second_credit_card, focus: 'name' } })}
                      onBlur={() => this.setState({ second_credit_card: { ...second_credit_card, focus: '' } })}
                      fullWidth
                    />

                    <MaskedInput
                      id="secundary-card-number-input"
                      className="venda-online-input"
                      mask="9999 9999 9999 9999"
                      name="number"
                      label="Número do cartão"
                      placeholder="4444 4444 4444 4448"
                      maskChar=""
                      fullWidth
                      size="small"
                      value={second_credit_card.number}
                      error={second_credit_card.errors.number}
                      onFocus={() => this.setState({ second_credit_card: { ...second_credit_card, focus: 'number' } })}
                      onBlur={() => this.setState({ second_credit_card: { ...second_credit_card, focus: '' } })}
                      onChange={handleChangeSecondCreditCard}
                    >
                      {(inputProps) => <TextField {...inputProps} />}
                    </MaskedInput>

                    <MaskedInput
                      className="venda-online-input"
                      mask="99/99"
                      error={second_credit_card.errors.expiry}
                      helperText={credit_card.errors.expiry && 'A expiração deve superior a ' + moment().add(12, 'months').format('MM/YY') + '.'}
                      name="expiry"
                      label="Data de expiração"
                      placeholder="12/26"
                      maskChar=""
                      fullWidth
                      size="small"
                      value={second_credit_card.expiry}
                      onFocus={() => this.setState({ second_credit_card: { ...second_credit_card, focus: 'expiry' } })}
                      onBlur={() => this.setState({ second_credit_card: { ...second_credit_card, focus: '' } })}
                      onChange={handleChangeSecondCreditCard}
                    >
                      {(inputProps) => <TextField {...inputProps} />}
                    </MaskedInput>

                    <MaskedInput
                      className="venda-online-input"
                      mask="9999"
                      name="cvv"
                      label="Cód. de segurança"
                      placeholder="153"
                      maskChar=""
                      fullWidth
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <Tooltip
                            arrow
                            position="start"
                            title="O número código de segurança é representado pelos três últimos dígitos no verso do seu cartão. Em cartões American Express, o código de segurança é um número de 4 dígitos na frente do cartão."
                          >
                            <Help></Help>
                          </Tooltip>
                        ),
                      }}
                      value={second_credit_card.cvv}
                      error={second_credit_card.errors.cvv}
                      onFocus={() => this.setState({ second_credit_card: { ...second_credit_card, focus: 'cvc' } })}
                      onBlur={() => this.setState({ second_credit_card: { ...second_credit_card, focus: '' } })}
                      onChange={handleChangeSecondCreditCard}
                    >
                      {(inputProps) => <TextField {...inputProps} />}
                    </MaskedInput>

                    <MaskedInput
                      className="venda-online-input"
                      mask={'99'}
                      name="card_maturity"
                      label="Dia do vencimento da fatura"
                      placeholder="20"
                      maskChar=""
                      fullWidth
                      size="small"
                      value={second_credit_card.card_maturity}
                      onChange={handleChangeSecondCreditCard}
                    >
                      {(inputProps) => <TextField {...inputProps} />}
                    </MaskedInput>
                  </FormControl>
                </div>
                <Divider flexItem orientation="vertical"></Divider>
                <div className="column second">
                  <div className="item">
                    <Typography variant="body1" component="p">
                      Endereço
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      {purchase.address}
                    </Typography>
                  </div>
                  <div className="item">
                    <Typography variant="body1" component="p">
                      Cidade
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      {purchase.city}
                    </Typography>
                  </div>
                  <div className="item">
                    <Typography variant="body1" component="p">
                      CEP
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      {purchase.cep}
                    </Typography>
                  </div>
                  <Divider style={{ margin: '16px 0' }} />
                  <div className="item">
                    <Typography variant="body1" component="p">
                      Parcelamento da venda
                    </Typography>
                    <FormControl>
                      <Select
                        disabled
                        labelId="demo-simple-select-label"
                        value={purchase_selected_option}
                        onChange={(event) => {
                          const { value } = event.target
                          return this.props.parent.setState({ purchase_selected_option: value })
                        }}
                      >
                        {purchase_parcel_options.map((parcel, index) => {
                          return (
                            <MenuItem key={index} value={index}>
                              {parcel.label_corresp}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                  </div>
                  <Divider style={{ margin: '16px 0' }} />
                  <div className="item">
                    <Typography variant="body1" component="p" className="total_value text">
                      Valor total
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" className="total_value value">
                      R$ {total_value.toFixed(2).replace('.', ',')}
                    </Typography>
                  </div>
                </div>
              </div>
            </>
          ) : (
            ''
          )}

          <div className="next-step">
            <Button disabled={loading} color="primary" variant="contained" style={{ width: '100%' }} onClick={() => this.props.parent.setState({ step: 0 })} className="back">
              Voltar
            </Button>
            <Button id='recurrence-next-button' disabled={loading} color="primary" variant="contained" style={{ width: '100%' }} onClick={openConfirmation} className="next">
              Continuar
            </Button>
          </div>
        </div>
      </div>
    )
  }
}
