import { Container, Grid, GridItem, Heading, Text } from '@chakra-ui/react'
import { ChangePaymentMethodProvider } from '../../contexts/ChangePaymentMethod'
import PaymentMethodSelect from './PaymentMethodSelect'
import TransactionResume from './TransactionResume'
const boxShadow =
  'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px'

export default function ChangePaymentMethod() {
  return (
    <>
      <img
        style={{ position: 'absolute', top: 32, left: 32, width: 120 }}
        src={'https://res.cloudinary.com/ucred/image/upload/v1626782488/logo_ucred.png'}
      />
      <ChangePaymentMethodProvider>
        <Container maxW="container.lg" width={{ base: '100%', md: 820 }} centerContent>
          <Heading size="lg" mt={{ base: 24, lg: 10 }} mb={4} align="center">
            Selecione seu novo método de pagamento!
          </Heading>

          <Text fontSize="md" mb={16} align="center">
            Assim que chegar o dia de sua renovação, iremos cobrá-la no novo método selecionado.
          </Text>
          <Grid
            p={2}
            templateColumns="repeat(12, 1fr)"
            gap={{ base: 3, md: 6 }}
            style={{ borderRadius: 16, boxShadow }}
          >
            <GridItem p={2} colSpan={{ base: 12, md: 7 }}>
              <PaymentMethodSelect />
            </GridItem>

            <GridItem colSpan={{ base: 12, md: 5 }}>
              <TransactionResume />
            </GridItem>
          </Grid>
        </Container>
      </ChangePaymentMethodProvider>
    </>
  )
}
