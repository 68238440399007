import React, { useCallback, useState } from 'react'
import { Container, Grid, TextField, Button, Box, CircularProgress, Typography } from '@material-ui/core'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import Checkbox from '@material-ui/core/Checkbox'
import Link from '@material-ui/core/Link'
import Lottie from 'lottie-react-web'
import { withSnackbar } from 'notistack'
import DateFnsUtils from '@date-io/moment'
import MaskedInput from 'react-input-mask'
import moment from 'moment'

import CPFAnalysis from '../../static/animations/cpf-analysis.json'
import CPFAnalysisApproved from '../../static/animations/cpf-analysis-approved.json'
import validateCpf from '../../functions/validateCpf'
import ApiInstance from '../../services/ApiInstanceV2'
import IpInstance from '../../services/IpInstance'

class LocalizedUtils extends DateFnsUtils {
  getYearRange(start, end) {
    const startDate = this.moment(end).startOf('year')
    const endDate = this.moment(start).endOf('year')
    const years = []

    let current = startDate
    while (current.isAfter(endDate)) {
      years.push(current)
      current = current.clone().subtract(1, 'year')
    }

    return years
  }
}

function AnaliseCpf(props) {
  const [cpf, setCpf] = useState('')
  const [birthDate, setBirthDate] = useState(null)
  const [status, setStatus] = useState('')
  const [isCpfInvalid, setIsCpfInvalid] = useState(false)
  const [isBirthDateInvalid, setIsBirthDateInvalid] = useState(false)
  const [name, setName] = useState('')
  const [accepted, setAccepted] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleCpfChange = useCallback((event) => {
    const cpfText = event.target.value
    setIsCpfInvalid(false)
    if (cpfText.length === 14) {
      const isValid = validateCpf(cpfText)
      if (isValid.success) {
        setIsCpfInvalid(false)
      } else {
        setIsCpfInvalid(true)
      }
    }
    setCpf(cpfText)
  }, [])

  const maxBirthDate = moment().subtract(18, 'year')
  const minBirthDate = moment().subtract(120, 'year')

  const handleBirthDateChange = useCallback((date) => {
    setIsBirthDateInvalid(!moment(date).isValid())
    setBirthDate(moment(date).format('YYYY-MM-DD'))
  }, [])

  const handleSubmit = useCallback(async () => {
    setLoading(true)
    const {
      data: { ip },
    } = await IpInstance.get()
    const data = {
      cpf,
      ['ip_address']: ip,
      ['birth_date']: birthDate,
    }
    try {
      const { Name } = await ApiInstance.post('/credit-engines', data)
      setName(Name.split(' ')[0])
      setStatus('approved')
    } catch (err) {
      const snackbarKey = props.enqueueSnackbar(`${err.message}`, {
        variant: 'error',
        onClick: () => {
          props.closeSnackbar(snackbarKey)
        },
      })
    }
    setLoading(false)
  }, [cpf, birthDate])

  const handleTermsAcceptCheck = (event) => {
    setAccepted(event.target.checked)
  }

  return (
    <>
      <Container maxWidth="lg" style={{ flex: 1, height: '100vh' }}>
        <Grid
          spacing={3}
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{ height: '100%' }}
        >
          <Grid item style={{ display: 'flex', flexDirection: 'column' }} justifyContent="center" alignItems="center">
            {status === 'approved' && (
              <>
                <Lottie
                  style={{ opacity: 1, marginBottom: 64 }}
                  width="500px"
                  speed={0.3}
                  options={{
                    animationData: CPFAnalysisApproved,
                    autoplay: true,
                    loop: true,
                  }}
                />
                <Typography style={{ fontSize: 24 }}>Olá, {name} você foi aprovado!</Typography>
              </>
            )}

            {status === '' && (
              <>
                <Lottie
                  style={{ opacity: 1, marginBottom: 64 }}
                  width="500px"
                  speed={1}
                  options={{
                    animationData: CPFAnalysis,
                    autoplay: true,
                    loop: true,
                  }}
                />
                <Box
                  style={{
                    width: '100%',
                    maxWidth: 380,
                    marginRight: 32,
                    marginLeft: 32,
                  }}
                >
                  <MaskedInput
                    maskChar=""
                    mask="999.999.999-99"
                    name="cpf"
                    fullWidth
                    variant="outlined"
                    label="Digite seu CPF"
                    placeholder="000.000.000-00"
                    value={cpf}
                    onChange={handleCpfChange}
                    error={isCpfInvalid}
                    helperText={isCpfInvalid && 'CPF inválido'}
                  >
                    {(inputProps) => <TextField {...inputProps} />}
                  </MaskedInput>
                  <MuiPickersUtilsProvider utils={LocalizedUtils}>
                    <KeyboardDatePicker
                      name="birthDate"
                      label="Data de nascimento"
                      openTo="year"
                      views={['year', 'month', 'date']}
                      format="DD/MM/YYYY"
                      placeholder="00/00/0000"
                      inputVariant="outlined"
                      value={birthDate}
                      minDate={minBirthDate}
                      maxDate={maxBirthDate}
                      onChange={handleBirthDateChange}
                      initialFocusedDate={maxBirthDate}
                      style={{ marginTop: 16, marginBottom: 16 }}
                      error={isBirthDateInvalid}
                      invalidDateMessage="Data inválida"
                      fullWidth
                      autoOk
                    />
                  </MuiPickersUtilsProvider>
                  <Box
                    style={{
                      display: 'flex',
                      width: '100%',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      marginTop: 16,
                    }}
                  >
                    <Checkbox
                      checked={accepted}
                      onChange={handleTermsAcceptCheck}
                      name="accepted"
                      color="primary"
                      style={{
                        paddingTop: 0,
                        paddingLeft: 0,
                        marginTop: -4,
                        marginLeft: -3,
                      }}
                    />
                    <Typography variant="body2">
                      Li e aceito os{' '}
                      <Link
                        href="https://www.smartnx.com/site20/wp-content/uploads/2020/04/TERMO-DE-USO-SMART-NX.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        termos de uso
                      </Link>{' '}
                      e concordo com o envio <br /> e armazenamento dos{' '}
                      <Link
                        href="https://www.smartnx.com/site20/wp-content/uploads/2020/04/politica-de-privacidade.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        meus dados pessoais.
                      </Link>
                    </Typography>
                  </Box>
                  <Box style={{ display: 'flex', position: 'relative', marginTop: 16, width: '100%' }}>
                    <Button
                      color="primary"
                      variant="contained"
                      disabled={!(!isCpfInvalid && moment(birthDate).isValid() && accepted) || loading}
                      size="large"
                      style={{ width: '100%' }}
                      onClick={handleSubmit}
                    >
                      Analisar
                    </Button>
                    {loading && (
                      <CircularProgress
                        size={24}
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          marginTop: -12,
                          marginLeft: -12,
                        }}
                      />
                    )}
                  </Box>
                </Box>
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  )
}
export default withSnackbar(AnaliseCpf)
