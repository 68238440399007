import { Box, Button, Grid, Hidden } from '@material-ui/core'
import { MenuOpenRounded, MenuRounded } from '@material-ui/icons'
import { withSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  changeNotification,
  fillAguardandoTrial,
  fillTrial,
  logOut,
} from '../../redux/actions/authUserAction'
import { refresh } from '../../redux/actions/creditAnalysisActions'
import Api from '../../services/ApiInstance'
import Logo from '../../static/images/logo-ucred-line.webp'
import Navbar from '../Navbar'
// import Goal from './components/Goal'
import Notifications from './components/Notifications'
import Social from './components/Social'
import UserActions from './components/UserActions'

const Header = (props: any) => {
  const [navbar, setNavbar] = useState(false)
  const [company, setCompany] = useState({})

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    async function fetchData() {
      await Api.get('/company').then(response => {
        setCompany(response.data)
      })
    }

    fetchData().then(() => {
      props.refresh()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  /** 
    const handleClick = e => {
      if (this.node.contains(e.target)) {
        return setChecked(checked)
      }
  
      return setChecked(false)
    }
  
    const closeNotification = () => {
      const { changeNotification } = props
      changeNotification(false)
    }
  */

  const { user } = props

  if (!user) return null
  return (
    <>
      <Grid container id="header" style={{ background: '#044c94' }}>
        <Grid item xs={3} md={4}>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              height: '100%',
              padding: 8,
            }}
          >
            <Button
              style={{ borderRadius: 16 }}
              color="primary"
              onClick={() => {
                setNavbar(!navbar)
              }}
            >
              {!navbar ? (
                <MenuRounded style={{ color: '#fff' }} />
              ) : (
                <MenuOpenRounded style={{ color: '#fff' }} />
              )}
              <div style={{ marginLeft: 8, color: '#fff' }}>MENU</div>
            </Button>
          </Box>
        </Grid>

        <Hidden smDown>
          <Grid item xs={4}>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                padding: 8,
              }}
            >
              <Link to="/">
                <div className="image">
                  <img className="logo" alt="logo" src={Logo}></img>
                </div>
              </Link>
            </Box>
          </Grid>
        </Hidden>

        <Grid item xs={9} md={4}>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              height: '100%',
              padding: 8,
            }}
          >
            {/* -------------------------------------------------------------------------- */}
            {/*                                   SOCIAL                                   */}
            {/* -------------------------------------------------------------------------- */}
            <Social />
            {/* -------------------------------------------------------------------------- */}
            {/*                                NOTIFICAÇÕES                                */}
            {/* -------------------------------------------------------------------------- */}

            <Notifications user={user} refresh={props.refresh} />
            {/* -------------------------------------------------------------------------- */}
            {/*                                 META UCRED                                 */}
            {/* -------------------------------------------------------------------------- */}
            {/** <Goal /> *}

              {/* -------------------------------------------------------------------------- */}
            {/*                                  USUARIO                                   */}
            {/* -------------------------------------------------------------------------- */}
            <UserActions user={user} logOut={props.logOut} />
          </Box>
        </Grid>
      </Grid>

      <Navbar
        id="navbar"
        open={navbar}
        user={user}
        company={company}
        setOpen={setNavbar}
        refresh={props.refresh}
      ></Navbar>
    </>
  )
}

function mapStateToProps({ authReducer }: any) {
  return {
    user: authReducer.authUser,
  }
}

function mapDispatchToProps(dispatch: any) {
  return {
    refresh: () => dispatch(refresh()),
    logOut: () => dispatch(logOut()),
    fillTrial: (trial: any) => dispatch(fillTrial(trial)),
    fillAguardandoTrial: (trial: any) => dispatch(fillAguardandoTrial(trial)),
    changeNotification: (status: any) => dispatch(changeNotification(status)),
  }
}

export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(Header))
