const styles = {
  background: '#fdfdff',
  container: '#ffffff',
  primary: '#009fff',

  success: '#27e3b1',
  error: '#ff6b6b',
  info: '#279be3',
  alert: '#f5d742',
  critical: '#404040',
}

export default styles
