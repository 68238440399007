import { Button, Text, useClipboard, Icon, Center } from '@chakra-ui/react'
import { MdCheck } from 'react-icons/md'
import { BiLink } from 'react-icons/bi'

type PaymentLinkButtonProps = {
  message: string
}

export function PaymentLinkButton({ message }: PaymentLinkButtonProps) {
  const { hasCopied, onCopy } = useClipboard(message)

  return (
    <Button
      onClick={onCopy}
      size="sm"
      borderRadius="16"
      colorScheme={hasCopied ? 'green' : 'blue'}
      fontWeight="medium"
      w={44}
      display="flex"
      _focus={{
        boxShadow: hasCopied
          ? '0 0 0 3px rgba(45, 158, 86, 0.6)'
          : '0 0 0 3px rgba(66, 153, 225, 0.6)',
      }}
    >
      {hasCopied ? <Icon as={MdCheck} boxSize="5" /> : <Icon as={BiLink} boxSize="5" />}
      <Center w="100%">
        <Text>{hasCopied ? 'Copiado' : 'Link de Pagamento'}</Text>
      </Center>
    </Button>
  )
}
