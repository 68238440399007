import React from 'react'
import { connect } from 'react-redux'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'

import AnaliseCpf from './AnaliseCpf'
import Ajustes from './Ajustes'
import Avisos from './Avisos'
import Calculadora from './Calculadora'
import ConsumoPlano from './ConsumoPlano'
import BankAccount from './Contas'
import Dashboard from './Dashboard'
import DashboardRevendedor from './DashboardRevendedor'
import DetalhadoRevendedor from './DetalhadoRevendedor'
import Documentacao from './Documentacao'
import Feedback from './Feedback'
import Home from './Home'
import HomeRevendedor from './HomeRevendedor'
import LoginPage from './Login'
import LoginPageRevendedor from './LoginRevendedor'
import LogOut from './LogOut'
import LogOutRevendedor from './LogOutRevendedor'
import MudarCartao from './MudarCartao'
import NewServiceUcredPlan from './NewServiceUcredPlan'
import Notas from './Nota'
import NovaImobiliaria from './NovaImobiliaria'
import NovaImobiliariaRevendedor from './NovaImobiliariaRevendedor'
import NewVendaOnline from './NovaVendaOnline/index'
import NovoRevendedor from './NovoRevendedor'
import Planos from './Planos'
import Reajuste from './Reajuste'
import Renovacao from './Renovacao'
import Servicos from './Servicos'
import ServicosForm from './ServicosForm'
import Treinamento from './Treinamento'
import Usuarios from './Usuarios'
import UsuariosForm from './Usuarios/EditUser'
import VendaOnline from './VendaOnline'
import RecoverPassword from './RecoverPassword'
import ChangePaymentMethod from './ChangePaymentMethod'
import PageNotFound from './404'

import EnvioDeDocumentos from './Documentos/NovoEnvio'
import Documentos from './Documentos'
import Acompanhamento from './Acompanhamento'
import DetalhesCliente from './DetalhesCliente'
import Sinistros from './Sinistros'

import NewSignerDocument from './SignerDocuments/NewSignerDocument'
import SignerDocuments from './SignerDocuments'
import { Followup } from './Followup'
import { TransactionDetails } from './TransactionDetails'
import { Invoices } from './Ivoices'

const mapState = ({ authReducer }) => {
  return { authUser: authReducer.authUser }
}

const RedirectPls = connect(mapState)(({ authUser, location }) => {
  const publicRoutes = [
    '/planos',
    '/login',
    '/nova-imobiliaria',
    '/novo-revendedor',
    '/nova-imobiliaria-revendedor',
    '/calculadora',
    '/documentacao',
    '/vendaOnline',
    '/venda-online',
    '/feedback',
    '/mudarCartao',
    '/login-revendedor',
    '/recuperar-senha',
    '/analise-cpf',
    '/alterar-metodo-de-pagamento',
    '/404',
  ]

  const privateRoutes = [
    '/',
    '/contas/conta-pessoal',
    '/contas/conta-corporativa',
    '/dashboard',
    '/servicos',
    '/usuarios',
    '/reajuste',
    '/consumo',
    '/ajustes-contrato',
    '/avisos',
    '/notas',
    '/logOut',
    '/treinamento',
    '/renovacao',
    '/documentos',
    '/feedback',
    '/acompanhamento',
    '/detalhe-do-cliente',
    '/sinistros',
  ]

  const resellerRoutes = [
    '/logOutRevendedor',
    '/home-revendedor',
    '/financeiro-revendedor',
    '/detalhadoRevendedor',
  ]

  let pathFormatted = location.pathname.substring(1).split('/')[0]

  const isPublicRoute = publicRoutes.filter(value => value.split('/').includes(pathFormatted))
  const isPrivateRoute = privateRoutes.filter(value => value.split('/').includes(pathFormatted))
  const isResellerRoute = resellerRoutes.filter(value => value.split('/').includes(pathFormatted))

  if (!authUser && isPrivateRoute.length) return <Redirect to="/login" />
  if (!authUser && isResellerRoute.length) return <Redirect to="/login-revendedor" />
  if (!isPublicRoute.length && !isResellerRoute.length && !isPrivateRoute.length)
    return <Redirect to="/404" />

  return null
})

const AppRouter = props => {
  const isSouzaGomesPlan = props.user && props.user.plan_id === 1
  return (
    <React.Fragment>
      <BrowserRouter>
        <Route path="/" component={RedirectPls} />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/404" component={PageNotFound} />
          <Route path="/login" exact component={LoginPage} />
          <Route path="/login-revendedor" exact component={LoginPageRevendedor} />
          <Route path="/home-revendedor" component={HomeRevendedor} />
          <Route path="/financeiro-revendedor" component={DashboardRevendedor} />
          <Route path="/contas/:account" component={BankAccount} />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/servicos" exact component={Servicos} />
          <Route path="/servicos/:id" component={ServicosForm} />
          <Route path="/usuarios" exact component={Usuarios} />
          <Route path="/usuarios/:id" component={UsuariosForm} />
          <Route path="/planos" component={Planos} />
          <Route path="/nova-imobiliaria" component={NovaImobiliaria} />
          <Route path="/novo-revendedor" component={NovoRevendedor} />
          <Route path="/nova-imobiliaria-revendedor" component={NovaImobiliariaRevendedor} />
          <Route path="/reajuste" component={Reajuste} />
          <Route path="/consumo" component={ConsumoPlano} />
          <Route path="/ajustes-contrato" component={Ajustes} />
          <Route path="/avisos" component={Avisos} />
          <Route path="/calculadora" component={Calculadora} />
          <Route path="/documentacao" component={Documentacao} />
          <Route path="/notas" component={Notas} />
          <Route path="/logOut" component={LogOut} />
          <Route path="/logOutRevendedor" component={LogOutRevendedor} />
          <Route path="/vendaOnline" component={VendaOnline} />
          <Route path="/venda-online" component={NewVendaOnline} />
          <Route path="/mudarCartao" component={MudarCartao} />
          <Route path="/treinamento" component={Treinamento} />
          <Route path="/detalhadoRevendedor/:grafico" component={DetalhadoRevendedor} />
          <Route path="/renovacao" component={Renovacao} />
          <Route path="/servicoUcredPlan" component={NewServiceUcredPlan} />
          <Route path="/feedback" component={Feedback} />
          <Route path="/recuperar-senha" component={RecoverPassword} />
          <Route path="/analise-cpf" component={AnaliseCpf} />
          <Route path="/alterar-metodo-de-pagamento" component={ChangePaymentMethod} />

          {isSouzaGomesPlan ? (
            <>
              <Route path="/documentos/novo-envio/:purchase_id" component={EnvioDeDocumentos} />
              <Route path="/documentos/:purchase_id" exact component={Documentos} />
              <Route path="/acompanhamento" component={Acompanhamento} />
              <Route path="/detalhe-do-cliente/:id" component={DetalhesCliente} />
              <Route path="/sinistros" component={Sinistros} />
            </>
          ) : (
            <>
              <Route path="/documentos/novo-envio/:purchase_id" component={NewSignerDocument} />
              <Route path="/documentos/:purchase_id" exact component={SignerDocuments} />
              <Route path="/acompanhamento" component={Followup} />
              <Route path="/detalhe-do-cliente/:id" component={TransactionDetails} />
              <Route path="/sinistros" component={Invoices} />
            </>
          )}
        </Switch>
      </BrowserRouter>
    </React.Fragment>
  )
}

function mapStateToProps({ authReducer }) {
  return {
    user: authReducer.authUser,
  }
}
export default connect(mapStateToProps, null)(AppRouter)
