import { Flex, Text } from '@chakra-ui/react'
import { TransactionType } from '../types/transaction'
import { OverallStatusBadge } from './Badge/OverallStatusBadge'
import { Card } from './Card'

type CardResumeProps = {
  transaction: TransactionType
}

export const CardResume = ({ transaction }: CardResumeProps) => (
  <Card>
    <Flex justify="space-between">
      <Flex flexDir="column">
        <Text fontSize="2xl" fontWeight="medium" color="gray.600">
          {transaction.renter.name}
        </Text>
        <Text fontSize="sm" fontWeight="thin" color="gray.500">
          {transaction.renter.cpf}
        </Text>
        <OverallStatusBadge transaction={transaction} mt="8" />
      </Flex>
      <Flex flexDir="column" align="flex-end">
        <Text fontSize="sm" fontWeight="medium" color="gray.500">
          {transaction.renter.address}
        </Text>
        <Text fontSize="sm" color="gray.400">
          {transaction.renter.city}
        </Text>
        <Text fontSize="xs" color="gray.400">
          {transaction.renter.zipCode}
        </Text>
      </Flex>
    </Flex>
  </Card>
)
