import { Divider, Fab, Typography } from '@material-ui/core'
import ContractIcon from '../../static/svg/contract.svg'
import BankSlipIcon from '../../static/svg/bank-slip.svg'

export default function Error(props: {
  contractUrl: string
  paymentMethod: string
  contractStatus: 'signed' | 'running' | 'canceled'
  purchaseStatus: number
  printUrl: string
  bankSlipUrl: string
}) {
  return (
    <div id="online-purchase-error">
      <div className="container">
        <div className="online-purchase-error">
          <div className="column house-img">
            <div className="images">
              <img
                className="image-house"
                src="https://cdn.iconscout.com/icon/free/png-256/house-home-building-infrastructure-real-estate-resident-emoj-symbol-1-30743.png"
                alt="icone casa"
              />

              <img
                className="image-error"
                src={
                  props.contractStatus === 'running'
                    ? 'https://www.shareicon.net/data/512x512/2016/08/18/810028_close_512x512.png'
                    : 'https://www.shareicon.net/data/512x512/2016/07/07/792201_success_512x512.png'
                }
                alt="icone erro"
              />
            </div>
          </div>
          <Divider flexItem orientation="vertical"></Divider>
          <div className="column">
            <div className="item buttons">
              <div className="line">
                {props.contractStatus === 'running' && (
                  <>
                    <Typography variant="h6" component="h1" align="center">
                      Aguardando assinatura do contrato.
                    </Typography>

                    <Fab
                      style={{ margin: '16px 0' }}
                      disabled={!props.contractUrl}
                      variant="extended"
                      color="primary"
                      onClick={() => window.open(props.contractUrl, '_blank')}
                    >
                      <img alt="contract" src={ContractIcon}></img>
                      Assinar o contrato
                    </Fab>
                  </>
                )}

                {props.paymentMethod === 'credit_card' && props.bankSlipUrl && (
                  <Fab
                    disabled={!props.contractUrl}
                    variant="extended"
                    color="primary"
                    style={{ marginBottom: 12 }}
                    onClick={() => window.open(props.bankSlipUrl, '_blank')}
                  >
                    <img alt="boleto" src={BankSlipIcon}></img>
                    Boleto bancário
                  </Fab>
                )}

                {props.contractStatus === 'signed' && (
                  <>
                    <Typography variant="h6" component="h1" align="center">
                      Contrato assinado.
                    </Typography>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
