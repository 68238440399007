import { Link } from 'react-router-dom'
import { MdHome } from 'react-icons/md'
import {
  Breadcrumb as ChakraBreadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Icon,
} from '@chakra-ui/react'

export function Breadcrumb() {
  return (
    <ChakraBreadcrumb fontSize="md" color="gray.600" my={8} ml={2}>
      <Icon as={MdHome} boxSize={5} mr={2} mt="-6px" color="gray.500" />
      <BreadcrumbItem>
        <BreadcrumbLink color="gray.500" as={Link} to="/">
          Início
        </BreadcrumbLink>
      </BreadcrumbItem>

      <BreadcrumbItem isCurrentPage>
        <BreadcrumbLink href="#">Sinistros</BreadcrumbLink>
      </BreadcrumbItem>
    </ChakraBreadcrumb>
  )
}
