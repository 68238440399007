import React from 'react'
import { Button, CircularProgress, Grid, IconButton, InputAdornment, TextField } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { withSnackbar } from 'notistack'
import { connect } from 'react-redux'
import { fillCompany, fillUser } from '../../redux/actions/authUserAction'
import ApiInstance from '../../services/ApiInstance'
import IpInstance from '../../services/IpInstance'
import Logo from '../../static/images/logo.png'
import { RecoverPasswordModal } from './RecoverPasswordModal'
export class LoginPage extends React.Component {
  constructor() {
    super()
    this.state = {
      email: '',
      password: '',
      loading: false,
      wallpaper: '',
      errorField: '',
      showPassword: false,
      recoveryPasswordModalOpen: false,
    }
  }

  async componentDidMount() {
    await ApiInstance.get('login_wallpaper').then((response) => {
      this.setState({ wallpaper: response[0] ? response[0].url : '' })
    })

    window.localStorage.removeItem('jwt')

    window.localStorage.setItem('hiddenCorrespMessage', false)
    window.localStorage.setItem('hiddenEmailMessage', false)
  }

  render() {
    const { email, password, loading, errorField, showPassword, wallpaper } = this.state

    const handleSubmit = async (event) => {
      event.preventDefault()
      this.setState({ loading: true })

      const { fillUser } = this.props
      const { email, password } = this.state
      if (!email) {
        this.setState({ errorField: 'email', loading: false })
        document.getElementsByName('email')[0].focus()
        const snackbarKey = this.props.enqueueSnackbar('Informe seu email.', {
          variant: 'error',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        })
        return
      }

      if (!password) {
        this.setState({ errorField: 'password', loading: false })
        document.getElementsByName('password')[0].focus()
        const snackbarKey = this.props.enqueueSnackbar('Informe sua senha.', {
          variant: 'error',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        })
        return
      }

      let address = { ip: '0.0.0.0' }
      let ip_address = await IpInstance.get().catch(() => {})

      if (ip_address && ip_address.data) {
        address = ip_address.data
      }

      try {
        const authData = { email, password, ip: address.ip }
        const { data: response } = await ApiInstance.post('login', authData)
        localStorage.setItem('jwt', response.token.token)

        fillUser({
          ...response.user,
          contractStatus: response.contract.status,
          contractUpdatedAt: response.contract.updatedAt,
          contractSigningTimeout: response.contract.signingTimeout,
          contractUrl: response.contract.contractUrl,
          token: response.token,
          trial: response.trial,
          atrasado: response.pagamentoAtrasado,
          aguardandoTrial: response.aguardandoTrial,
          company_id: response.company.id,
          renda: response.renda,
          plan_id: response.company.plan_id,
          notSigned: response ? response.notSigned : false,
          total_purchase: response.goal.total_purchase,
          goal: response.goal.goal,
          notification: true,
          isMainUser: response.isMainUser,
        })

        this.props.history.push('/')
      } catch (error) {
        let data = error
        let options = {}

        let snackbarKey
        switch (data.message) {
          /* -------------------------------------------------------------------------- */
          case 'FALTA_ASSINAR':
            options = {
              variant: 'error',
              onClick: () => {
                this.props.closeSnackbar(snackbarKey)
              },
            }
            snackbarKey = this.props.enqueueSnackbar(
              'É necessário assinar o contrato para continuar usando a plataforma Ucred.',
              options
            )
            break
          /* -------------------------------------------------------------------------- */
          case 'A senha informada está incorreta.':
            this.setState({ errorField: 'password' })
            snackbarKey = this.props.enqueueSnackbar(data.message, {
              variant: 'error',
              onClick: () => {
                this.props.closeSnackbar(snackbarKey)
              },
              action: () => (
                <Button
                  variant="outlined"
                  onClick={() => this.recoverPasswordModal.handleOpen()}
                  style={{ color: '#fff', borderColor: '#fff' }}
                >
                  Recuperar senha
                </Button>
              ),
            })
            break
          /* -------------------------------------------------------------------------- */
          case 'O email informado não está registrado.':
            this.setState({ errorField: 'email' })
            snackbarKey = this.props.enqueueSnackbar(data.message, {
              variant: 'error',
              onClick: () => {
                this.props.closeSnackbar(snackbarKey)
              },
            })
            break
          /* -------------------------------------------------------------------------- */
          default:
            snackbarKey = this.props.enqueueSnackbar('Houve um erro não identificado.', {
              variant: 'error',
              onClick: () => {
                this.props.closeSnackbar(snackbarKey)
              },
            })
            break
        }
      }
      await this.setState({ loading: false })
    }

    const handleChange = (event) => {
      const { value, name } = event.target
      this.setState({ [name]: value, errorField: '' })
    }

    const handleRecoverPassword = () => {
      this.setState({ recoveryPasswordModalOpen: true })
    }
    const handleRecoveryModalClose = () => {
      this.setState({ recoveryPasswordModalOpen: false })
    }

    return (
      <>
        <Grid container spacing={2} >
          <Grid item xs={false} md={7} lg={8} style={{ padding: 0 }}>
            <div
              style={{
                backgroundImage: `url(${wallpaper})`,
                height: 'calc(100vh + 8px)',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top left',
              }}
            />
          </Grid>
          <Grid item xs={12} md={5} lg={4} container justifyContent="center" alignItems="center">
            <form style={{ display: 'flex', flexDirection: 'column', flex: 1, margin: 36 }} onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', marginBottom: 42 }}>
                  <img src={Logo} alt="logo" style={{ width: 200 }} />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="email"
                    variant="outlined"
                    fullWidth
                    type="email"
                    label="E-mail"
                    placeholder="seuemail@dominio.com"
                    value={email}
                    onChange={handleChange}
                    error={errorField === 'email'}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="password"
                    variant="outlined"
                    fullWidth
                    type={showPassword ? 'text' : 'password'}
                    label="Senha"
                    placeholder="Informe sua senha"
                    value={password}
                    onChange={handleChange}
                    error={errorField === 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => this.setState({ showPassword: !showPassword })}>
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    style={{ marginRight: 8 }}
                    size="large"
                    variant="outlined"
                    color="primary"
                    onClick={handleRecoverPassword}
                    disabled={loading}
                  >
                    Recuperar senha
                  </Button>
                  <span style={{ position: 'relative' }}>
                    <Button size="large" color="primary" disabled={loading} variant="contained" type="submit">
                      Entrar
                    </Button>
                    {loading && (
                      <CircularProgress
                        size={24}
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          marginTop: -12,
                          marginLeft: -12,
                        }}
                      />
                    )}
                  </span>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>

        <RecoverPasswordModal modalOpen={this.state.recoveryPasswordModalOpen} handleClose={handleRecoveryModalClose} />
      </>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fillUser: (user) => dispatch(fillUser(user)),
    fillCompany: (c) => dispatch(fillCompany(c)),
  }
}

export default withSnackbar(connect(null, mapDispatchToProps)(LoginPage))
