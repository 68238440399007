import { useState } from 'react'
import ReactCreditCard from 'react-credit-cards'
import InputMask from 'react-input-mask'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { addMonths, format } from 'date-fns'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Stack,
  Box,
  HStack,
  useToast,
} from '@chakra-ui/react'

import { Input } from '../Form/Input'
import { DatePicker } from '../DatePicker'
import { creditCardSchema } from '../../schemas/creditCardSchema'
import ApiInstance from '../../../../services/ApiInstance'
import { RenterType } from '../../types/renter'

interface EditCreditCardProps {
  isOpen: boolean
  handleCloseModal: (payload?: string) => void
  renter: RenterType
}

export function EditCreditCardModal({ handleCloseModal, isOpen, renter }: EditCreditCardProps) {
  const [cardFocus, setCardFocus] = useState<'name' | 'number' | 'expiry' | 'cvc'>()
  const toast = useToast()

  const { register, handleSubmit, formState, watch, reset, setValue } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(creditCardSchema),
    defaultValues: {
      cardNumber: '',
      cardExpiry: '',
      cardCVV: '',
      cardMaturity: '',
    },
  })

  const { errors, isSubmitting } = formState
  const [cardNumber, cardExpiry, cardCVV] = watch(['cardNumber', 'cardExpiry', 'cardCVV'])

  async function handleCreditCardSubmit(values: {
    cardNumber: number
    cardExpiry: number
    cardCVV: string
    cardMaturity: string
  }) {
    const { cardNumber, cardExpiry, cardCVV, cardMaturity } = values

    const card = {
      holder_name: renter.name,
      expiration_date: format(cardExpiry, 'MM/yyyy'),
      number_card: cardNumber,
      cvv: cardCVV,
      type: '',
      card_maturity: cardMaturity,
    }
    try {
      await ApiInstance.post('cliente/atualizarCartao', {
        card,
        id_client: renter.id,
      })
      toast({
        title: 'Atualizado com sucesso.',
        status: 'success',
        duration: 2000,
        isClosable: true,
      })
      reset()
      handleCloseModal('submit')
    } catch (error: any) {
      toast({
        title: error.originalMessage,
        status: 'error',
        duration: 2000,
        isClosable: true,
      })
    }
  }

  const handleExpiryChange = (event: { target: { value: string } }) =>
    setValue('cardExpiry', event.target.value, { shouldValidate: true })

  return (
    <Modal
      motionPreset="slideInBottom"
      isCentered
      isOpen={isOpen}
      onClose={handleCloseModal}
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmit(handleCreditCardSubmit)}>
        <ModalHeader fontWeight="normal" color="gray.600">
          Edição de cartão
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Box mb={6}>
            <ReactCreditCard
              name={renter.name}
              number={cardNumber}
              expiry={cardExpiry}
              cvc={cardCVV}
              focused={cardFocus}
              locale={{ valid: 'Data Venc' }}
              placeholders={{ name: 'Nome do Titular' }}
            />
          </Box>
          <Stack spacing={4}>
            <Input
              isReadOnly
              variant="filled"
              name="name"
              label="Nome"
              defaultValue={renter.name}
            />
            <Input
              as={InputMask}
              label="Número"
              maskChar=""
              mask="9999 9999 9999 9999"
              placeholder="1234 5678 8765 4321"
              onFocus={() => setCardFocus('number')}
              error={errors.cardNumber}
              autoFocus
              {...register('cardNumber')}
              onBlur={() => setCardFocus(undefined)}
            />
            <HStack spacing={4} align="flex-start">
              <DatePicker
                as={InputMask}
                label="Validade"
                maskChar=""
                mask="99/99"
                minDate={addMonths(new Date(), 13)}
                placeholder="12/26"
                handleChange={handleExpiryChange}
                onFocus={() => setCardFocus('expiry')}
                error={errors.cardExpiry}
                {...register('cardExpiry')}
                onBlur={() => setCardFocus(undefined)}
              />
              <Input
                as={InputMask}
                label="CVV"
                maskChar=""
                mask="9999"
                placeholder="123"
                onFocus={() => setCardFocus('cvc')}
                error={errors.cardCVV}
                {...register('cardCVV')}
                onBlur={() => setCardFocus(undefined)}
              />
            </HStack>
            <Input
              as={InputMask}
              label="Vencimento da fatura"
              maskChar=""
              mask="99"
              placeholder="10"
              error={errors.cardMaturity}
              {...register('cardMaturity')}
            />
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button
            color="gray.600"
            mr={3}
            disabled={isSubmitting}
            onClick={() => handleCloseModal()}
          >
            Voltar
          </Button>
          <Button isLoading={isSubmitting} colorScheme="blue" type="submit">
            Salvar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
