import { Button, Text } from '@chakra-ui/react'

type YearButtonProps = {
  title: string | number
  selected: boolean
  onClick: () => void
}

const YearButton = ({ title, selected, ...rest }: YearButtonProps) => (
  <Button colorScheme={selected ? 'blue' : 'blackAlpha'} w="100%" variant="ghost" {...rest}>
    <Text fontSize={selected ? 'xl' : 'md'} fontWeight={selected ? 'semibold' : 'medium'}>
      {title}
    </Text>
  </Button>
)

export { YearButton }
