import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { Add, ArrowBack, Close, GetApp, Home } from '@material-ui/icons'
import MaterialTable from 'material-table'
import moment from 'moment'
import React from 'react'

import { withSnackbar } from 'notistack'
import { Link } from 'react-router-dom'
import ApiInstance from '../../services/ApiInstance'
import Header from '../../layout/Header'
import tableIcons from '../../styles/tableIcons'
import styles from '../../styles/defaultStyles'

class SignerDocuments extends React.Component {
  constructor() {
    super()

    this.state = {
      step: 0,
      selectedRow: null,
      url: {},
      documentsToDownload: 0,
      rows: [],
      contractSigners: { client: {} },
      loading: false,
      checked: false,
      acceptedTerm: false,
      purchase: [],
      user: { name: '', cpf: '', birthday: '', email: '' },
      signers: [],
      confirm: false,
      index: null,
    }
  }

  componentDidMount() {
    this.fetchData()
  }

  async fetchData() {
    const { purchase_id } = this.props.match.params

    this.setState({ purchase_id })

    try {
      let contractSigners = await ApiInstance.get(`contractSigner/${purchase_id}`)

      if (!contractSigners.contracts.length) {
        this.props.history.push(`/documentos/novo-envio/${purchase_id}`)
      }

      this.setState({ contractSigners: contractSigners })

      this.prepareData()
    } catch (error) {
      const snackbarKey = this.props.enqueueSnackbar('Erro inesperado, entre em contato com nosso suporte.', {
        variant: 'error',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
    }
  }

  /* -------------------------------------------------------------------------- */
  /*                PREPARANDO DADOS PARA A TABELA DE SIGNATÁRIOS               */
  /* -------------------------------------------------------------------------- */

  prepareData() {
    let data = this.state.contractSigners

    if (!data.contracts) {
      data.contracts = []
    }

    let rows = []

    function getColor(status) {
      if (status === 'Aguardando assinatura') {
        return '#c49000'
      }

      if (status === 'Contrato Assinado') {
        return '#32a852'
      }
    }

    data.contracts.map((contract, index) => {
      let currentRow = {}
      currentRow.description = contract.description

      currentRow.status = 'Não assinado'

      let signed = 0
      let pending = 0
      let canceled = 0

      contract.signers.map((signer) => {
        switch (signer.status) {
          case 'Aguardando assinatura':
            pending += 1
            break

          case 'Contrato Assinado':
            signed += 1
            break

          case 'Cancelado':
            canceled += 1
            break

          default:
            break
        }
      })

      if (signed && !pending) {
        currentRow.status = 'Concluído'
      }

      if (pending && signed) {
        currentRow.status = 'Assinado parcialmente'
      }

      if (!signed) {
        currentRow.status = 'Não assinado'
      }

      if (canceled) {
        currentRow.status = 'Cancelado'
      }

      currentRow.cancel = (
        <Box style={{ position: 'relative', marginRight: 8 }}>
          <Fab
            disabled={currentRow.status === 'Cancelado'}
            onClick={async (event) => {
              event.stopPropagation()
              this.setState({ loading: true })
              try {
                this.setState({ loading: false, confirmCancel: true, selectedRow: index })
              } catch (error) {
                const snackbarKey = this.props.enqueueSnackbar('Erro inesperado, entre em contato com nosso suporte.', {
                  variant: 'error',
                  onClick: () => {
                    this.props.closeSnackbar(snackbarKey)
                  },
                })
                this.setState({ loading: false })
              }
            }}
            size="small"
            color="primary"
            style={{
              zIndex: 5,
              width: 35,
              height: 35,
              minHeight: 'initial',
              boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.20)',
              backgroundColor: currentRow.status !== 'Cancelado' ? styles.error : '',
            }}
          >
            <Close style={{ width: 20 }} />
          </Fab>
          {this.state.loading && (
            <CircularProgress
              size={24}
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: -12,
                marginLeft: -12,
              }}
            />
          )}
        </Box>
      )

      currentRow.download = (
        <Box style={{ position: 'relative', marginRight: 8 }}>
          <Fab
            onClick={async (event) => {
              event.stopPropagation()
              this.setState({ loading: true })
              try {
                let url = await ApiInstance.get(
                  `downloadContract/${this.state.contractSigners.contracts[index].document_key}`
                )
                this.setState({ url: url.data, loading: false, documentDownload: true, selectedRow: index })
              } catch (error) {
                const snackbarKey = this.props.enqueueSnackbar('Erro inesperado, entre em contato com nosso suporte.', {
                  variant: 'error',
                  onClick: () => {
                    this.props.closeSnackbar(snackbarKey)
                  },
                })
                this.setState({ url: {}, loading: false })
              }
            }}
            size="small"
            color="primary"
            style={{
              zIndex: 5,
              width: 35,
              height: 35,
              minHeight: 'initial',
              boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.20)',
            }}
          >
            <GetApp style={{ width: 20 }} />
          </Fab>
          {this.state.loading && (
            <CircularProgress
              size={24}
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: -12,
                marginLeft: -12,
              }}
            />
          )}
        </Box>
      )

      contract.signers.map((signer, index) => {
        currentRow['signer' + (index + 1)] = (
          <p>
            {signer.name} <br /> <p style={{ color: getColor(signer.status) }}>{signer.status}</p>
          </p>
        )
      })

      rows.push(currentRow)
    })

    this.setState({ rows })
  }

  render() {
    const noOverflow = {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
    const columns = [
      { title: 'Descrição', field: 'description', cellStyle: { ...noOverflow, width: '100%', maxWidth: 1920 } },
      { title: 'Status', field: 'status', cellStyle: { ...noOverflow } },
      { title: 'Responsável pela imobiliária', field: 'signer1', cellStyle: { ...noOverflow }, hidden: true },
      { title: 'Signatário 1', field: 'signer2', cellStyle: { ...noOverflow }, hidden: true },
      { title: 'Signatário 2', field: 'signer3', cellStyle: { ...noOverflow }, hidden: true },
      { title: 'Signatário 3', field: 'signer4', cellStyle: { ...noOverflow }, hidden: true },
      { title: 'Signatário 4', field: 'signer5', cellStyle: { ...noOverflow }, hidden: true },
      { title: 'Signatário 5', field: 'signer6', cellStyle: { ...noOverflow }, hidden: true },
      {
        title: 'Download',
        field: 'download',
        cellStyle: { width: 40 },
        export: false,
        align: 'center',
        sorting: false,
      },
      { title: 'Cancelar', field: 'cancel', cellStyle: { width: 40 }, export: false, align: 'center', sorting: false },
    ]
    return (
      <>
        <Header />
        <Container
          maxWidth="lg"
          style={{
            paddingTop: 32,
            marginTop: 80,
            marginBottom: 32
          }}
        >
          <Grid spacing={3} container>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Breadcrumbs className="breadcrumbs">
                <Link to="/" style={{ display: 'flex' }}>
                  <Home
                    style={{
                      marginRight: 8,
                      width: 20,
                      height: 20,
                    }}
                  />
                  Início
                </Link>
                <Typography color="textPrimary">Documentos</Typography>
              </Breadcrumbs>
              <Button
                color="primary"
                variant="outlined"
                onClick={() => {
                  let { purchase_id } = this.props.match.params
                  this.props.history.push({
                    pathname: `/detalhe-do-cliente/${this.state.contractSigners.client.id}`,
                    state: { tab: 0, detail: { purchaseId: purchase_id } },
                  })
                }}
              >
                <ArrowBack />
                Voltar
              </Button>
            </Grid>
            {/* -------------------------------------------------------------------------- */}
            {/*                                   PASSO 0                                  */}
            {/* -------------------------------------------------------------------------- */}
            <>
              <Grid item xs={12}>
                <MaterialTable
                  data={this.state.rows}
                  columns={columns}
                  options={{
                    detailPanelType: 'single',
                    pageSize: 15,
                    pageSizeOptions: [15, 50, 100],
                    tableLayout: 'auto',
                    showTitle: true,
                    padding: 'dense',
                    exportAllData: true,
                    exportFileName: 'Relatório Ucred (Documentos) - ' + moment().format('DD-MM-YYYY H:mm:ss'),
                    exportButton: true,
                    actionsColumnIndex: 0,
                  }}
                  onRowClick={(event, rowData, togglePanel) => {
                    togglePanel()
                  }}
                  detailPanel={(rowData) => {
                    return (
                      <Box p={2}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6} md={4}>
                            <Box p={3} style={{ background: '#fafafa', borderRadius: 16 }}>
                              {rowData.signer1}
                            </Box>
                          </Grid>
                          {rowData.signer2 && (
                            <Grid item xs={12} sm={6} md={4}>
                              <Box p={3} style={{ background: '#fafafa', borderRadius: 16 }}>
                                {rowData.signer2}
                              </Box>
                            </Grid>
                          )}
                          {rowData.signer3 && (
                            <Grid item xs={12} sm={6} md={4}>
                              <Box p={3} style={{ background: '#fafafa', borderRadius: 16 }}>
                                {rowData.signer3}
                              </Box>
                            </Grid>
                          )}
                          {rowData.signer4 && (
                            <Grid item xs={12} sm={6} md={4}>
                              <Box p={3} style={{ background: '#fafafa', borderRadius: 16 }}>
                                {rowData.signer4}
                              </Box>
                            </Grid>
                          )}
                          {rowData.signer5 && (
                            <Grid item xs={12} sm={6} md={4}>
                              <Box p={3} style={{ background: '#fafafa', borderRadius: 16 }}>
                                {rowData.signer5}
                              </Box>
                            </Grid>
                          )}
                          {rowData.signer6 && (
                            <Grid item xs={12} sm={6} md={4}>
                              <Box p={3} style={{ background: '#fafafa', borderRadius: 16 }}>
                                {rowData.signer6}
                              </Box>
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                    )
                  }}
                  components={{ Container: (props) => <div id="mtable-container" {...props}></div> }}
                  icons={tableIcons}
                  title="Relatório de documentos"
                  localization={{
                    header: {
                      actions: 'Ações',
                    },
                    body: {
                      emptyDataSourceMessage: 'Nenhum registro para exibir',
                    },
                    toolbar: {
                      exportTitle: 'Exportar',
                      searchTooltip: 'Pesquisar',
                      searchPlaceholder: 'Pesquisar',
                      exportCSVName: 'Exportar em CSV',
                      exportPDFName: 'Exportar em PDF',
                    },
                    pagination: {
                      labelRowsSelect: 'linhas',
                      labelDisplayedRows: '{from}-{to} de {count}',
                      firstTooltip: 'Primeira página',
                      previousTooltip: 'Página anterior',
                      nextTooltip: 'Próxima página',
                      lastTooltip: 'Última página',
                    },
                  }}
                />
              </Grid>
            </>
          </Grid>
        </Container>

        {/* -------------------------------------------------------------------------- */}
        {/*                    MODAL DE CONFIRMAÇÃO DE CANCELAMENTO                    */}
        {/* -------------------------------------------------------------------------- */}

        <Dialog open={this.state.confirmCancel} onClose={() => this.setState({ confirmCancel: false })}>
          <DialogTitle
            disableTypography
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6">Deseja cancelar este documento?</Typography>
            <IconButton onClick={() => this.setState({ confirmCancel: false })}>
              <Close></Close>
            </IconButton>
          </DialogTitle>

          <DialogContent dividers>
            <DialogContentText>
              Esta ação é irreversível e não poderá ser desfeita, sendo necessário o reenvio de um novo contrato.
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button color="primary" onClick={() => this.setState({ confirmCancel: false })}>
              Voltar
            </Button>
            <Box style={{ display: 'flex', position: 'relative' }}>
              <CircularProgress
                size={24}
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: -12,
                  marginLeft: -12,
                }}
              ></CircularProgress>
              <Button
                disabled={this.state.loading}
                color="primary"
                variant="contained"
                onClick={async () => {
                  this.setState({ loading: true })
                  await ApiInstance.delete(
                    `cancelContract/${this.state.contractSigners.contracts[this.state.selectedRow].document_key}`
                  )

                  this.fetchData()

                  this.setState({ loading: false, confirmCancel: false })

                  const snackbarKey = this.props.enqueueSnackbar('Documento cancelado.', {
                    variant: 'success',
                    onClick: () => {
                      this.props.closeSnackbar(snackbarKey)
                    },
                  })
                }}
              >
                Cancelar
              </Button>
            </Box>
          </DialogActions>
        </Dialog>

        {/* -------------------------------------------------------------------------- */}
        {/*                        MODAL DE CONFIRMAÇÃO DE ENVIO                       */}
        {/* -------------------------------------------------------------------------- */}
        <Dialog
          open={this.state.documentDownload}
          onClose={() => {
            this.setState({ documentDownload: false })
          }}
        >
          <DialogTitle
            disableTypography
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6">Download do contrato</Typography>
            <IconButton onClick={() => this.setState({ documentDownload: false })}>
              <Close></Close>
            </IconButton>
          </DialogTitle>

          <DialogContent dividers>
            {/* --------------------- DOCUMENTO ORIGINAL --------------------- */}
            <Grid container spacing={2}>
              <Grid item xs={1}>
                <Fab
                  color="primary"
                  size="small"
                  onClick={() => {
                    window.open(this.state.url.original_file_url, '_blank')
                  }}
                >
                  <GetApp />
                </Fab>
              </Grid>
              <Grid item xs={11} style={{ margin: 'auto' }}>
                <Typography
                  style={{ marginLeft: 12 }}
                  onClick={() => {
                    window.open(this.state.url.original_file_url, '_blank')
                  }}
                >
                  Baixar modelo original
                </Typography>
              </Grid>

              {/* --------------------- DOCUMENTO ASSINADO --------------------- */}

              <Grid item xs={1}>
                <Fab
                  disabled={this.state.url.signed_file_url === 'Todos os signatários devem assinar' ? true : false}
                  color="primary"
                  size="small"
                  onClick={() => {
                    window.open(this.state.url.signed_file_url, '_blank')
                  }}
                >
                  <GetApp />
                </Fab>
              </Grid>
              <Grid item xs={11} style={{ margin: 'auto' }}>
                <Typography
                  style={{ marginLeft: 12 }}
                  onClick={() => {
                    if (this.state.url.signed_file_url === 'Todos os signatários devem assinar') {
                      const snackbarKey = this.props.enqueueSnackbar(
                        'Todos os signatários devem assinar para que você possa baixar este documento.',
                        {
                          variant: 'error',
                          onClick: () => {
                            this.props.closeSnackbar(snackbarKey)
                          },
                        }
                      )
                    } else {
                      window.open(this.state.url.signed_file_url, '_blank')
                    }
                  }}
                >
                  Baixar contratos assinados/cancelados
                </Typography>
              </Grid>

              {/* --------------------- DOCUMENTO ORIGINAL E ASSINADOS --------------------- */}

              <Grid item xs={1}>
                <Fab
                  disabled={this.state.url.ziped_file_url === 'Todos os signatários devem assinar' ? true : false}
                  color="primary"
                  size="small"
                  onClick={() => {
                    window.open(this.state.url.ziped_file_url, '_blank')
                  }}
                >
                  <GetApp />
                </Fab>
              </Grid>
              <Grid item xs={11} style={{ margin: 'auto' }}>
                <Typography
                  style={{ marginLeft: 12 }}
                  disabled={this.state.url.ziped_file_url === 'Todos os signatários devem assinar' ? true : false}
                  onClick={() => {
                    if (this.state.url.signed_file_url === 'Todos os signatários devem assinar') {
                      const snackbarKey = this.props.enqueueSnackbar(
                        'Todos os signatários devem assinar para que você possa baixar este documento.',
                        {
                          variant: 'error',
                          onClick: () => {
                            this.props.closeSnackbar(snackbarKey)
                          },
                        }
                      )
                    } else {
                      window.open(this.state.url.ziped_file_url, '_blank')
                    }
                  }}
                >
                  Baixar contratos originais e assinados/cancelados
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button
              color="primary"
              onClick={() => {
                this.setState({ documentDownload: false })
              }}
            >
              Fechar
            </Button>
          </DialogActions>
        </Dialog>
        <Tooltip title="Autenticar outro documento" placement="top-start" arrow>
          <Fab
            onClick={() => {
              const { purchase_id } = this.props.match.params
              this.props.history.push(`/documentos/novo-envio/${purchase_id}`)
            }}
            color="primary"
            style={{ position: 'fixed', bottom: 90, right: 22 }}
          >
            <Add />
          </Fab>
        </Tooltip>
      </>
    )
  }
}

export default withSnackbar(SignerDocuments)
