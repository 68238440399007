import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@material-ui/core'
import { AccessTime, Block, Close, DoneAll, Home } from '@material-ui/icons'
import MaterialTable from 'material-table'
import moment from 'moment'
import { withSnackbar } from 'notistack'
import React from 'react'
import ApiInstance from '../../services/ApiInstance'
import EditCreditCardModal from '../../components/editCreditCardModal'
import Header from '../../layout/Header'
import tableIcons from '../../styles/tableIcons'
import styles from '../../styles/defaultStyles'
import Footer from '../../layout/Footer'
import { Link } from 'react-router-dom'
import localization from '../../locale/tableLocale'

class Renovacao extends React.Component {
  constructor() {
    super()
    this.state = {
      rows: [],
      rowsSuccess: [],
      rowsCanceled: [],
      rowsFail: [],
      Error: false,
      purchaseSelected: {},
      defaultFee: 0,
      corresponsavel: false,
      feeRenovation: 0,
      loading: false,
      newValue: 0,
      qtdFail: 0,
      ErrorError: false,
      newCard: false,
      focusCard: 'name',
      client: { id: '', name: '' },
      creditCard: {
        number: '',
        cvc: '',
        name: '',
        expiry: '',
        issuer: '',
        date: '',
      },
      tab: 'pending',
    }
  }

  async componentDidMount() {
    await this.fetchData()
  }

  async fetchData() {
    try {
      let { renovation_fee } = await ApiInstance.get('/renovationFee')
      let { dataPending, dataSuccess, dataError, qtdError, dataCanceled } = await ApiInstance.get('/purchasesDue')
      this.setState({
        rows: dataPending,
        defaultFee: renovation_fee,
        feeRenovation: renovation_fee,
        rowsSuccess: dataSuccess,
        rowsFail: dataError,
        qtdFail: qtdError,
        rowsCanceled: dataCanceled,
      })
    } catch (error) {
      const snackbarKey = this.props.enqueueSnackbar('Erro inesperado, entre em contato com nosso suporte.', {
        variant: 'error',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
    }
  }

  renovacao = async (line) => {
    this.setState({ novaRenovacao: true })
    let formatedValue = Number(line.value.replace(',', '.').replace('R$ ', ''))
    let newValue = formatedValue * (this.state.defaultFee / 100) + formatedValue
    this.setState({ Error: true, purchaseSelected: line, newValue })
  }

  changeDefault = async (event) => {
    let { value } = event.target
    if (!Number.isInteger(Number(value))) return false
    if (Number(value.replace(',', '.')) > 15) value = '15'
    if (Number(value.replace(',', '.')) < 0) value = '0'
    this.setState({ defaultFee: value })
  }

  updateDefault = async () => {
    this.setState({ loading: true })
    try {
      await ApiInstance.post('/renovationFee', { renovation_fee: this.state.defaultFee })
      const snackbarKey = this.props.enqueueSnackbar('Atualizado com sucesso.', {
        variant: 'success',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
      this.setState({ loading: false })
    } catch (error) {
      const snackbarKey = this.props.enqueueSnackbar('Erro inesperado, entre em contato com nosso suporte.', {
        variant: 'error',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
      this.setState({ loading: false })
    }
  }

  updateNewFee = async (event) => {
    let { value } = event.target
    if (isNaN(Number(value))) return false
    if (Number(value.replace(',', '.')) > 15) value = '15'
    if (Number(value.replace(',', '.')) < 0) value = '0'
    let formatedValue = Number(this.state.purchaseSelected.value.replace(',', '.').replace('R$ ', ''))
    let newValue = formatedValue * (value / 100) + formatedValue
    this.setState({ newValue, feeRenovation: value })
  }

  customRenovation = async () => {
    this.setState({ loading: true })
    try {
      await ApiInstance.post('/renovatePurchase', {
        renovation_id: this.state.purchaseSelected.id,
        renovation_fee: this.state.feeRenovation,
        date_renovation: this.state.purchaseSelected.end_pucharse,
      })
      const snackbarKey = this.props.enqueueSnackbar('Índice alterado com sucesso!', {
        variant: 'success',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
      this.setState({ loading: false, Error: false, novaRenovacao: false })
      this.fetchData()
    } catch (error) {
      const snackbarKey = this.props.enqueueSnackbar('Erro inesperado, entre em contato com nosso suporte.', {
        variant: 'error',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
      this.setState({ loading: false })
    }
  }

  cancelRenovation = async () => {
    try {
      const answer = window.confirm('Deseja realmente cancelar a renovação?')
      if (answer === true) {
        this.setState({ loading: true })
        await ApiInstance.post('/cancelRenovation', {
          renovation_id: this.state.purchaseSelected.id,
        })
        const snackbarKey = this.props.enqueueSnackbar('Cancelado com sucesso.', {
          variant: 'success',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        })
        this.setState({ loading: false, novaRenovacao: false })
      } else {
        return false
      }
      this.fetchData()
    } catch (error) {
      const snackbarKey = this.props.enqueueSnackbar(
        'Erro inesperado, entre em contato com nosso suporte.',
        {
          variant: 'error',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        },
      )
      this.setState({ loading: false })
    }
  }

  errorRenovation = async () => {
    this.setState({ loading: true })
    try {
      await ApiInstance.post('/renovateErrorPurchase', {
        renovation_id: this.state.purchaseSelected.id,
        renovation_fee: this.state.feeRenovation,
        date_renovation: this.state.purchaseSelected.end_pucharse,
      })
      const snackbarKey = this.props.enqueueSnackbar('Renovado com sucesso.', {
        variant: 'success',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
      this.setState({ loading: false, novaRenovacao: false, novaRenovacaoError: false })
      this.fetchData()
    } catch (error) {
      const snackbarKey = this.props.enqueueSnackbar('Falha na renovação, entre em contato com nosso suporte.', {
        variant: 'error',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
      this.setState({ loading: false })
    }
  }

  renovacaoError = async (line) => {
    const { creditCard } = this.state
    let formatedValue = Number(line.value.replace(',', '.').replace('R$ ', ''))
    let newValue = formatedValue * (this.state.defaultFee / 100) + formatedValue
    this.setState({
      novaRenovacaoError: true,
      purchaseSelected: line,
      newValue,
      creditCard: {
        ...creditCard,
        name: line.client_name,
      },
    })
  }

  changeCardRenovation = (c) => {
    this.EditCreditCardModal.setState({ open: true })
    this.setState({ newCard: true, corresponsavel: c })
  }

  changeCardRenovationVindi = async () => {
    try {
      const { corresponsavel } = this.state
      this.setState({ loading: true })
      const {
        creditCard: { name, expiry, number, cvc, issuer, date },
      } = this.state
      if (!name || !expiry || !number || !cvc || !issuer) throw new Error('Preencha todos os dados')
      await ApiInstance.post('changeCardRenovation', {
        card: {
          holder_name: name,
          expiration_date: expiry,
          number_card: number,
          cvv: cvc,
          type: issuer,
          date,
        },
        renovation_id: this.state.purchaseSelected.id,
        corresponsavel,
      })
      const snackbarKey = this.props.enqueueSnackbar('Atualizado com sucesso.', {
        variant: 'success',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
      this.setState({ newCard: false, loading: false })
    } catch (error) {
      this.setState({ loading: false })
      const snackbarKey = this.props.enqueueSnackbar('Falha ao alterar cartão, entre em contato com nosso suporte.', {
        variant: 'error',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
    }
  }

  getIssuer = ({ issuer }) => {
    const { creditCard } = this.state
    this.setState({
      creditCard: {
        ...creditCard,
        issuer,
      },
    })
  }

  handleCard = (event) => {
    const { creditCard } = this.state
    const { id, value } = event.target
    this.setState({
      creditCard: {
        ...creditCard,
        [id]: value,
      },
    })
  }

  render() {
    const { novaRenovacao, novaRenovacaoError, tab, purchaseSelected, client, loading } = this.state
    const handleChangeTab = (event, newValue) => {
      this.setState({ tab: newValue })
    }

    const noOverflow = {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      width: 300,
      maxWidth: 300,
      textOverflow: 'ellipsis',
    }

    const columns = [
      { field: 'client_name', title: 'Cliente', cellStyle: noOverflow },
      { field: 'corresp_data', title: 'Corresponsável', cellStyle: noOverflow },
      { field: 'value', title: 'Valor', cellStyle: noOverflow },
      { field: 'total_value', title: 'Valor Reajustado', cellStyle: noOverflow },
      { field: 'begin_contract', title: 'Início do contrato', cellStyle: noOverflow },
      { field: 'end_pucharse_formated', title: 'Final do contrato', cellStyle: noOverflow },
      { field: 'date_renovation_formated', title: 'Data de renovação', cellStyle: noOverflow },
      { field: 'renovation_fee', title: 'Taxa da renovação', cellStyle: noOverflow },
      { field: 'service_name', title: 'Serviço', cellStyle: noOverflow },
    ]

    const options = {
      pageSize: 15,
      pageSizeOptions: [15, 50, 100],
      tableLayout: 'auto',
      showTitle: true,
      padding: 'dense',
      exportAllData: true,
      exportButton: true,
      actionsColumnIndex: -1,
    }

    return (
      <>
        <Header />
        <Container
          style={{
            paddingTop: 32,
            marginTop: 80,
            marginBottom: 32
          }}
        >
          <Grid spacing={3} container>
            <Grid item xs={12}>
              <Breadcrumbs className="breadcrumbs">
                <Link to="/" style={{ display: 'flex' }}>
                  <Home
                    style={{
                      marginRight: 8,
                      width: 20,
                      height: 20,
                    }}
                  />
                  Início
                </Link>
                <Typography color="textPrimary">Renovação</Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item xs={12}>
              <Box
                style={{
                  borderRadius: 16,
                  background: '#fff',
                  boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.05)',
                  overflow: 'hidden',
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} style={{ margin: 0 }}>
                    <Box p={3} style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Typography variant="h6">Percentual de reajuste dos contratos</Typography>
                    </Box>
                  </Grid>

                  <Divider style={{ width: '100%' }} />

                  <Grid item xs={12}>
                    <Box p={3}>
                      <Grid container spacing={1}>
                        <Grid item xs="auto" style={{ display: 'flex', flex: 1 }}>
                          <TextField
                            fullWidth
                            label="Percentual de reajuste dos contratos"
                            variant="outlined"
                            value={this.state.defaultFee}
                            onChange={this.changeDefault}
                            InputProps={{
                              endAdornment: <>%</>,
                            }}
                          />
                        </Grid>
                        <Grid item>
                          {!this.state.loading && (
                            <Button
                              style={{ height: 56 }}
                              variant="contained"
                              color="primary"
                              onClick={this.updateDefault}
                            >
                              Atualizar
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box
                style={{
                  borderRadius: 16,
                  background: '#fff',
                  boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.05)',
                  overflow: 'hidden',
                }}
              >
                <Tabs
                  value={tab}
                  variant="fullWidth"
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={handleChangeTab}
                >
                  <Tab label="Pendentes" value="pending" icon={<AccessTime />} style={{ color: styles.primary }} />
                  <Tab label="Sucesso" value="success" icon={<DoneAll />} style={{ color: styles.success }} />
                  <Tab label="Falha" value="error" icon={<Close />} style={{ color: styles.error }} />
                  <Tab label="Cancelados" value="canceled" icon={<Block />} style={{ color: styles.critical }} />
                </Tabs>

                {tab === 'pending' && (
                  <MaterialTable
                    icons={tableIcons}
                    title="Renovações pendentes"
                    components={{ Container: (props) => <div id="mtable-container no-shadow" {...props}></div> }}
                    options={{
                      ...options,
                      exportFileName:
                        'Relatório Ucred (Renovações pendentes) - ' + moment().format('DD-MM-YYYY H:mm:ss'),
                    }}
                    data={this.state.rows}
                    columns={columns}
                    onRowClick={(event, rowData) => this.renovacao(rowData)}
                    localization={localization}
                  />
                )}

                {tab === 'success' && (
                  <MaterialTable
                    icons={tableIcons}
                    title="Renovações bem sucedidas"
                    components={{ Container: (props) => <div id="mtable-container no-shadow" {...props}></div> }}
                    options={{
                      ...options,
                      exportFileName:
                        'Relatório Ucred (Renovações sucedidas) - ' + moment().format('DD-MM-YYYY H:mm:ss'),
                    }}
                    data={this.state.rowsSuccess}
                    columns={columns}
                    localization={localization}
                  />
                )}

                {tab === 'error' && (
                  <MaterialTable
                    icons={tableIcons}
                    title="Renovações com erro"
                    components={{ Container: (props) => <div id="mtable-container no-shadow" {...props}></div> }}
                    options={{
                      ...options,
                      exportFileName:
                        'Relatório Ucred (Renovações com erro) - ' + moment().format('DD-MM-YYYY H:mm:ss'),
                    }}
                    data={this.state.rowsFail}
                    columns={[
                      ...columns,
                      { field: 'obs_transaction', title: 'Resposta da operadora', cellStyle: { ...noOverflow } },
                      { field: 'has_corresponsible', title: 'Possui corresponsável', cellStyle: { ...noOverflow } },
                    ]}
                    onRowClick={(event, rowData) => this.renovacaoError(rowData)}
                    localization={localization}
                  />
                )}

                {tab === 'canceled' && (
                  <MaterialTable
                    icons={tableIcons}
                    title="Renovações canceladas"
                    components={{ Container: (props) => <div id="mtable-container no-shadow" {...props}></div> }}
                    options={{
                      ...options,
                      exportFileName:
                        'Relatório Ucred (Renovações canceladas) - ' + moment().format('DD-MM-YYYY H:mm:ss'),
                    }}
                    data={this.state.rowsCanceled}
                    columns={columns}
                    localization={localization}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </Container>
        <Dialog
          disableScrollLock
          open={novaRenovacao}
          onClose={() => {
            if (loading) return
            this.setState({ novaRenovacao: false })
          }}
          center
        >
          <DialogTitle
            disableTypography
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6">Nova Renovação</Typography>
            <IconButton
              onClick={() => {
                if (loading) return
                this.setState({ novaRenovacao: false })
              }}
              size="small"
              style={{ marginLeft: 'auto' }}
            >
              <Close></Close>
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography>Cliente</Typography>
                <Typography variant="body2" color="textSecondary">
                  {this.state.purchaseSelected.client_name}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography>Meses para o vencimento</Typography>
                <Typography variant="body2" color="textSecondary">
                  {this.state.purchaseSelected.month_diff}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography>Serviço</Typography>
                <Typography variant="body2" color="textSecondary">
                  {this.state.purchaseSelected.service_name}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography>Valor original</Typography>
                <Typography variant="body2" color="textSecondary">
                  {this.state.purchaseSelected.value}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography>Valor com reajuste</Typography>
                <Typography variant="body2" color="textSecondary">
                  R$ {this.state.newValue.toFixed(2)}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Taxa renovação"
                  placeholder="Digite o índice de renovação"
                  value={this.state.feeRenovation}
                  onChange={this.updateNewFee}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">%</InputAdornment>,
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button disabled={loading} variant="outlined" color="primary" onClick={this.cancelRenovation}>
              Cancelar Renovação
            </Button>
            <Box style={{ display: 'flex', position: 'relative' }}>
              {loading && (
                <CircularProgress
                  size={24}
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: -12,
                    marginLeft: -12,
                  }}
                />
              )}
              <Button disabled={loading} color="primary" variant="contained" onClick={this.customRenovation}>
                Alterar índice
              </Button>
            </Box>
          </DialogActions>
        </Dialog>

        <Dialog
          open={novaRenovacaoError}
          onClose={() => {
            if (loading) return
            this.setState({ novaRenovacaoError: false })
          }}
          center
        >
          <DialogTitle
            disableTypography
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6">Renovação</Typography>
            <IconButton
              onClick={() => {
                if (loading) return
                this.setState({ novaRenovacaoError: false })
              }}
              size="small"
              style={{ marginLeft: 'auto' }}
            >
              <Close></Close>
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography>Cliente</Typography>
                <Typography variant="body2" color="textSecondary">
                  {this.state.purchaseSelected.client_name}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography>Meses para o vencimento</Typography>
                <Typography variant="body2" color="textSecondary">
                  {this.state.purchaseSelected.month_diff || '-'}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography>Serviço</Typography>
                <Typography variant="body2" color="textSecondary">
                  {this.state.purchaseSelected.service_name}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography>Valor</Typography>
                <Typography variant="body2" color="textSecondary">
                  {this.state.purchaseSelected.total_value}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography>Valor Total</Typography>
                <Typography variant="body2" color="textSecondary">
                  R$ {this.state.newValue.toFixed(2)}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Taxa renovação"
                  placeholder="Digite o índice de renovação"
                  value={this.state.feeRenovation}
                  onChange={this.updateNewFee}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">%</InputAdornment>,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} align="center">
                <Button
                  fullWidth
                  disabled={loading}
                  onClick={() => {
                    this.setState({ client: { id: purchaseSelected.client_id, name: purchaseSelected.client_name } })
                    this.changeCardRenovation(false)
                  }}
                  color="primary"
                  variant="outlined"
                >
                  Alterar Cartão Responsável
                </Button>
              </Grid>

              <Grid item xs={12} sm={6} align="center">
                {this.state.purchaseSelected.has_corresponsible === 'Sim' && (
                  <Button
                    fullWidth
                    disabled={loading}
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                      this.setState({
                        client: { id: purchaseSelected.corresponsible_id, name: purchaseSelected.corresp_data },
                      })
                      this.changeCardRenovation(true)
                    }}
                  >
                    Alterar Cartão Corresponsável
                  </Button>
                )}
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button disabled={loading} color="primary" onClick={() => this.setState({ novaRenovacaoError: false })}>
              Fechar
            </Button>
            <Box style={{ display: 'flex', position: 'relative' }}>
              {loading && (
                <CircularProgress
                  size={24}
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: -12,
                    marginLeft: -12,
                  }}
                />
              )}
              <Button disabled={loading} color="primary" variant="contained" onClick={this.errorRenovation}>
                Renovar
              </Button>
            </Box>
          </DialogActions>
        </Dialog>

        <EditCreditCardModal ref={(reference) => (this.EditCreditCardModal = reference)} client={client} />
      </>
    )
  }
}

export default withSnackbar(Renovacao)
