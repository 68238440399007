/* eslint-disable react/display-name */
import { useState, useEffect, useCallback, memo } from 'react'
import { useHistory } from 'react-router-dom'
import MaterialTable from 'material-table'
import moment from 'moment'
import 'moment/locale/pt-br'

import ApiInstance from '../../services/ApiInstance'
import tableIcons from '../../styles/tableIcons'

import localization from '../../locale/tableLocale'
import { formatPurchases, PurchaseFormattedProps } from './formatPurchases'
import { ColumnType, fullPlanColumns, resalePlanColumns } from './purchaseTableColumns'
import { TableSkeleton } from './TableSkeleton'

interface TableDataProps {
  rows: PurchaseFormattedProps[]
  columns: ColumnType[]
}

function FollowupComponent() {
  const [tableData, setTableData] = useState<TableDataProps>({} as TableDataProps)
  const history = useHistory()

  useEffect(() => {
    async function fetchData() {
      const response = await ApiInstance.get('/acompanhamento')
      const purchases = response.data
      const purchasePlan = purchases[0] ? purchases[0].plan_id : null
      const resalePlan = 4
      const purchasesFormatted = formatPurchases(purchases)
      setTableData({
        rows: purchasesFormatted,
        columns: purchasePlan === resalePlan ? resalePlanColumns : fullPlanColumns,
      })
    }
    fetchData()
  }, [])

  const onClickRow = useCallback(
    ({ purchaseId, clientId }) =>
      history.push({
        pathname: `detalhe-do-cliente/${clientId}`,
        state: { detail: { purchaseId } },
      }),
    [history],
  )

  return (
    <>
      {tableData ? (
        <MaterialTable
          style={{ width: '100%', padding: 24, maxWidth: '1600px' }}
          icons={tableIcons}
          columns={tableData.columns}
          options={{
            headerStyle: { whiteSpace: 'pre-wrap', color: '#1A202C' },
            rowStyle: { color: '#4A5568' },
            pageSize: 10,
            pageSizeOptions: [10, 25, 50, 100],
            paginationType: 'stepped',
            tableLayout: 'auto',
            showTitle: true,
            padding: 'dense',
            exportAllData: true,
            exportFileName:
              'Relatório Ucred (Acompanhamento) - ' + moment().format('DD-MM-YYYY H:mm:ss'),
            exportButton: true,
            actionsColumnIndex: -1,
          }}
          onRowClick={(event, rowData) => {
            onClickRow({ purchaseId: rowData?.id, clientId: rowData?.id_cliente })
          }}
          components={{ Container: props => <div id="mtable-container" {...props}></div> }}
          data={tableData.rows}
          title="Relatório de acompanhamento"
          localization={localization}
        />
      ) : (
        <TableSkeleton />
      )}
    </>
  )
}

const Acompanhamento = memo(FollowupComponent)
export default Acompanhamento
