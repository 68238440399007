import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Breadcrumbs, Container, Grid, Typography, Box } from '@material-ui/core'
import Lottie from 'lottie-react-web'

import ContractSign from '../../static/animations/contractsign.json'
import { Home } from '@material-ui/icons'

import Header from '../../layout/Header'
import StepContainer from './StepContainer'
import { ContractUpdateModal } from '../../components/ContractUpdateModal'

function Start(props) {
  const [showModal, setShowModal] = useState(false)
  const contractUrl = props.user && props.user.contractUrl

  useEffect(() => {
    if (!props.user) return
    const { contractStatus } = props.user
    const isDismissed = localStorage.getItem('@Ucred:contractDismissed')
    if (contractStatus === 'need_update' && !isDismissed) return setShowModal(true)
    if (contractStatus === 'signed') localStorage.removeItem('@Ucred:contractDismissed')
  }, [])

  return props.user && props.user.contractSigningTimeout ? (
    <Box
      style={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <div>
        <Lottie
          width="300px"
          speed={1.0}
          options={{
            animationData: ContractSign,
            autoplay: true,
            loop: true,
          }}
        />
      </div>
      <Typography style={{ fontSize: 20 }}>
        O usuário principal de sua imobiliária
        <br /> precisa assinar o novo contrato Ucred.
      </Typography>
      <Typography color="primary" variant="h5" style={{ marginTop: 20 }}>
        <Link href={`${contractUrl}`} target="_blank" rel="noopener noreferrer">
          Acessar o novo contrato
        </Link>
      </Typography>
    </Box>
  ) : (
    <>
      <Header />
      <Container style={{ paddingTop: 32, marginTop: 80, marginBottom: 32 }}>
        <Grid spacing={3} container>
          <Grid className="print-hidden" item xs={12}>
            <Breadcrumbs className="breadcrumbs">
              <Link to="/" style={{ display: 'flex' }}>
                <Home
                  style={{
                    marginRight: 8,
                    width: 20,
                    height: 20,
                  }}
                />
                Início
              </Link>
              <Typography color="textPrimary">Nova transação</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12}>
            <StepContainer />
          </Grid>
        </Grid>
      </Container>
      {showModal && <ContractUpdateModal contractUrl={props.user && props.user.contractUrl} />}
    </>
  )
}

const mapStateToProps = ({ authReducer }) => ({ user: authReducer.authUser })

export default connect(mapStateToProps)(Start)
