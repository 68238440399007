/* eslint-disable camelcase */
import { useState, useEffect } from 'react'
import { Avatar, Box, Flex, Text, Button, Grid, useDisclosure, Icon } from '@chakra-ui/react'

import { getPaymentLink } from '../../../util/getPaymentLink'

import { Card } from '../components/Card'
import { LabelInfo } from '../components/LabelInfo'
import { EditRenterInfoModal } from '../components/Modal/EditRenterInfoModal'
import { PaymentLinkButton } from '../components/Button/PaymentLinkButton'
import { RenterType } from '../types/renter'
import { MdCreate } from 'react-icons/md'

interface RenterProps {
  renter: RenterType
  isCoresponsible?: boolean
}

export function Renter({ renter, isCoresponsible }: RenterProps) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [paymentMessage, setPaymentMessage] = useState('')
  const [email, setEmail] = useState('')
  const [phone1, setPhone1] = useState('')
  const [phone2, setPhone2] = useState('')

  const {
    id,
    cpf,
    name,
    paymentUrl,
    paymentStatus,
    companyName,
    contractUrl,
    paymentParcel,
    amountCharged,
    paymentMethod,
    contractStatus,
    corresponsibleIs,
    contractStatusFormatted,
    paymentMethodFormatted,
  } = renter

  useEffect(() => {
    setEmail(renter.email)
    setPhone1(renter.phone1)
    setPhone2(renter.phone2)
  }, [renter.email, renter.phone1, renter.phone2])

  const renterEditFields = { id, name, cpf, email, phone1, phone2, setEmail, setPhone1, setPhone2 }

  useEffect(() => {
    const message = getPaymentLink({
      clientName: name,
      companyName,
      paymentUrl,
      contractUrl,
    })
    setPaymentMessage(message)
  }, [companyName, contractUrl, name, paymentUrl])

  const amountChargedMessage =
    paymentMethod === 'not_defined' ? amountCharged : `${amountCharged} em ${paymentParcel}x`

  const isEditable = !isCoresponsible || corresponsibleIs === 'other'
  return (
    <>
      <EditRenterInfoModal isOpen={isOpen} onClose={onClose} editFields={renterEditFields} />
      <Card bg="gray.50" boxShadow="sm" width="100%">
        <Flex flexDir="column" h="100%">
          <Flex>
            <Avatar mr={4} />
            <Box textAlign="left">
              <Text fontSize="md" fontWeight="medium" color="gray.600">
                {name}
              </Text>
              <Text fontSize="sm" color={isCoresponsible ? 'orange.400' : 'blue.400'}>
                {isCoresponsible ? 'Corresponsável' : 'Locatário'}
              </Text>
            </Box>
            {isEditable && (
              <Button
                size="sm"
                variant="ghost"
                marginLeft="auto"
                borderRadius={16}
                p={1}
                onClick={onOpen}
              >
                <Icon as={MdCreate} boxSize={5} color="gray.400" />
              </Button>
            )}
          </Flex>
          <Grid templateColumns="repeat(2, 1fr)" gap={6} my={10}>
            <LabelInfo label="CPF" info={cpf} />
            <LabelInfo label="Email" info={email} />
            <LabelInfo label="Telefone principal" info={phone1} />
            <LabelInfo label="Telefone alternativo" info={phone2} />
            <LabelInfo label="Método de pagamento" info={paymentMethodFormatted} infoSize="md" />
            <LabelInfo
              label="Valor cobrado"
              info={amountChargedMessage}
              infoColor={isCoresponsible ? 'orange.400' : 'blue.500'}
              infoSize="md"
            />
            {contractStatus && <LabelInfo label="Contrato" info={contractStatusFormatted} />}
          </Grid>
          <Flex mt="auto">
            {isEditable && paymentStatus !== 3 && paymentStatus !== 9 && (
              <PaymentLinkButton message={paymentMessage} />
            )}
          </Flex>
        </Flex>
      </Card>
    </>
  )
}
