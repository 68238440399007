import { ArrowBackIcon, QuestionOutlineIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  FormLabel,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Tooltip,
} from '@chakra-ui/react'
import moment from 'moment'
import React, { useContext } from 'react'
import MaskedInput from 'react-input-mask'
import CreditCardInput from '../../../../components/creditCardInput'
import ChangePaymentMethodContext from '../../../../contexts/ChangePaymentMethod'
import ChakraMaskedInput from '../../../../components/maskedChakraInput'

function BankSlipPayment() {
  const { mainPaymentMethod, changeMainCreditCard, setSelectedPaymentMethod, errors } = useContext(
    ChangePaymentMethodContext,
  ) as unknown as CreatePaymentMethod

  return (
    <Box pt={6}>
      <Grid templateColumns="repeat(12, 1fr)" gap={3}>
        {/* ---------------------------------- NOME ---------------------------------- */}
        <GridItem colSpan={12}>
          <FormLabel
            color="gray.500"
            fontSize="xs"
            fontWeight="semibold"
            style={{ textTransform: 'uppercase' }}
            mb={0}
            pl={2}
          >
            Nome completo
          </FormLabel>
          <Input
            autoFocus
            disabled
            id="main_holder_name"
            name="name"
            error={errors.filter((value: Errors) => value.field === 'main_holder_name').length}
            placeholder="Nome"
            value={mainPaymentMethod?.name}
            onChange={event => changeMainCreditCard(event, false)}
          />
        </GridItem>

        {/* --------------------------------- NÚMERO --------------------------------- */}
        <GridItem colSpan={12}>
          <FormLabel
            color="gray.500"
            fontSize="xs"
            fontWeight="semibold"
            style={{ textTransform: 'uppercase' }}
            mb={0}
            pl={2}
          >
            Número do cartão
          </FormLabel>
          <CreditCardInput
            id="main_card_number"
            name="number"
            isInvalid={
              errors.filter((value: Errors) => value.field === 'main_card_number').length && true
            }
            placeholder="Número"
            value={mainPaymentMethod?.number}
            onChange={changeMainCreditCard}
          />
        </GridItem>

        {/* ----------------------------------- CVV ---------------------------------- */}
        <GridItem colSpan={6}>
          <FormLabel
            color="gray.500"
            fontSize="xs"
            fontWeight="semibold"
            style={{ textTransform: 'uppercase' }}
            mb={0}
            pl={2}
          >
            Cvv
          </FormLabel>
          <InputGroup>
            <ChakraMaskedInput
              as={MaskedInput}
              id="main_card_cvv"
              name="cvv"
              isInvalid={!!errors.filter((value: Errors) => value.field === 'main_card_cvv').length}
              maskChar=""
              mask="9999"
              placeholder="CVV"
              value={mainPaymentMethod?.cvv}
              onChange={event => changeMainCreditCard(event, false)}
            />
            <InputRightElement
              /* eslint-disable-next-line react/no-children-prop */
              children={
                <Tooltip
                  label="O número código de segurança é representado pelos três últimos dígitos no verso do seu cartão. Em cartões American Express, o código de segurança é um número de 4 dígitos na frente do cartão."
                  fontSize="md"
                >
                  <QuestionOutlineIcon color="gray.500" />
                </Tooltip>
              }
            />
          </InputGroup>
        </GridItem>

        {/* -------------------------------- VALIDADE -------------------------------- */}
        <GridItem colSpan={6}>
          <FormLabel
            color="gray.500"
            fontSize="xs"
            fontWeight="semibold"
            style={{ textTransform: 'uppercase' }}
            mb={0}
            pl={2}
          >
            Validade
          </FormLabel>
          <ChakraMaskedInput
            as={MaskedInput}
            id="main_card_expiration"
            name="expiration_date"
            isInvalid={
              !!errors.filter((value: Errors) => value.field === 'main_card_expiration').length
            }
            maskChar=""
            mask="99/9999"
            placeholder={moment().add('4', 'y').format('MM/YYYY')}
            value={mainPaymentMethod.expiration_date}
            onChange={event => changeMainCreditCard(event, false)}
          />
        </GridItem>

        {/* ------------------------------ PARCELAMENTO ------------------------------ */}
        <GridItem colSpan={12}>
          <FormLabel
            color="gray.500"
            fontSize="xs"
            fontWeight="semibold"
            style={{ textTransform: 'uppercase' }}
            mb={0}
            pl={2}
          >
            Parcelamento
          </FormLabel>
          <Select
            id="main_card_parcel"
            name="parcel"
            isInvalid={
              !!errors.filter((value: Errors) => value.field === 'main_card_parcel').length
            }
            placeholder="Parcelas"
            value={mainPaymentMethod?.parcel}
            onChange={event => changeMainCreditCard(event, false)}
          >
            {[1, 2, 3].map((parcel, index) => (
              <option value={parcel} key={index}>
                Parcelar em {parcel}x
              </option>
            ))}
          </Select>
        </GridItem>

        {/* -------------------------- VENCIMENTO DA FATURA -------------------------- */}
        <GridItem colSpan={12}>
          <FormLabel
            color="gray.500"
            fontSize="xs"
            fontWeight="semibold"
            style={{ textTransform: 'uppercase' }}
            mb={0}
            pl={2}
          >
            Vencimento da fatura
          </FormLabel>
          <InputGroup>
            <ChakraMaskedInput
              as={MaskedInput}
              id="main_card_maturity"
              name="card_maturity"
              isInvalid={
                !!errors.filter((value: Errors) => value.field === 'main_card_maturity').length
              }
              maskChar=""
              mask="99"
              placeholder="Vencimento da fatura do cartão"
              value={mainPaymentMethod.card_maturity}
              onChange={event => changeMainCreditCard(event, false)}
            />
            <InputRightElement
              /* eslint-disable-next-line react/no-children-prop */
              children={
                <Tooltip
                  label="O vencimento da fatura do cartão é relativo a data limite que você tem para pagar a fatura do seu cartão de crédito."
                  fontSize="md"
                >
                  <QuestionOutlineIcon color="gray.500" />
                </Tooltip>
              }
            />
          </InputGroup>
        </GridItem>

        <GridItem colSpan={12}>
          <Button
            size="sm"
            leftIcon={<ArrowBackIcon />}
            colorScheme="blue"
            variant="solid"
            onClick={() => setSelectedPaymentMethod(0)}
          >
            Voltar
          </Button>
        </GridItem>
      </Grid>
    </Box>
  )
}

export default BankSlipPayment
