import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from '@chakra-ui/react'
import { YearButton } from './components/YearButton'

type YearsAllowedType = {
  year: number
}

interface YearPickerModalProps {
  years: YearsAllowedType[]
  yearSelected: number | undefined
  isOpen: boolean
  onClose: () => void
  handleSelectYear: (year: number) => void
}

export function YearPickerModal({
  years,
  yearSelected,
  isOpen,
  onClose,
  handleSelectYear,
}: YearPickerModalProps) {
  return (
    <Modal
      size="xs"
      scrollBehavior="inside"
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontWeight="normal" color="gray.600">
          Selecione o ano:
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody maxH={80}>
          <VStack spacing={2}>
            {years.map(({ year }) => (
              <YearButton
                selected={year === yearSelected}
                key={year}
                title={year}
                onClick={() => handleSelectYear(year)}
              />
            ))}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button color="gray.600" onClick={onClose}>
            Voltar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
