import { FILL_USER, CLEAR_REDUCERS, FILL_TRIAL, FILL_AGUARDANDO_TRIAL, FILL_RENDA, CHANGE_NOTIFICATION } from '../actions/authUserAction'

const initialState = {
    authUser: null
}

function authReducer(state=initialState, action) {
    switch (action.type) {
        case FILL_USER: {
            return {
                ...state,
                authUser: action.user
            }
        }
        case CLEAR_REDUCERS: {
            return {...initialState}
        }
        case FILL_TRIAL: {
            return {
                ...state,
                authUser:{
                    ...state.authUser,
                    closeHeaderTrial: true
                } 
            }
        }
        case FILL_AGUARDANDO_TRIAL: {
            return {
                ...state,
                authUser:{
                    ...state.authUser,
                    closeHeaderAguardandoTrial: true
                } 
            }
        }
        case FILL_RENDA: {
            return {
                ...state,
                authUser: {
                    ...state.authUser,
                    renda: action.renda
                }
            }
        }
        case CHANGE_NOTIFICATION: {
            return {
                ...state,
                authUser: {
                    ...state.authUser,
                    notification: action.status
                }
            }
        }
        default:
        return state;
    }
}

export default authReducer