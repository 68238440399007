import { useForm } from 'react-hook-form'

import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Stack,
  HStack,
  useToast,
} from '@chakra-ui/react'

import { Input } from '../Form/Input'
import { PhoneInput } from '../Form/PhoneInput'
import ApiInstance from '../../../../services/ApiInstance'

const renterInfoFormSchema = yup.object().shape({
  email: yup.string().email('E-mail inválido').required('E-mail obrigatório'),
})

type EditFieldsProps = {
  id: number
  name: string
  cpf: string
  email: string
  phone1: string
  phone2: string
  setEmail: (payload: string) => void
  setPhone1: (payload: string) => void
  setPhone2: (payload: string) => void
}

interface EditRenterInfo {
  isOpen: boolean
  onClose: () => void
  editFields: EditFieldsProps
}

export function EditRenterInfoModal({ isOpen, onClose, editFields }: EditRenterInfo) {
  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(renterInfoFormSchema),
  })
  const { errors, isSubmitting } = formState
  const toast = useToast()

  async function handleEdit(values: { email: string; phone1: string; phone2: string }) {
    const { email, phone1, phone2 } = values
    const { id, setEmail, setPhone1, setPhone2 } = editFields
    try {
      await ApiInstance.put('cliente', {
        id,
        email,
        telephone_1: phone1,
        telephone_2: phone2,
      })
      setEmail(email)
      setPhone1(phone1)
      setPhone2(phone2)
      toast({
        title: 'Atualizado com sucesso!',
        status: 'success',
        variant: 'subtle',
        duration: 2000,
        isClosable: true,
      })
      onClose()
    } catch (e) {
      toast({
        title: 'Erro inesperado, entre em contato com nosso suporte.',
        status: 'error',
        isClosable: true,
      })
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      motionPreset="slideInBottom"
      isCentered
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmit(handleEdit)}>
        <ModalHeader fontWeight="normal" color="gray.600">
          Edição de cliente
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Stack spacing={7}>
            <Input
              isReadOnly
              variant="filled"
              name="name"
              label="Nome"
              defaultValue={editFields.name}
            />
            <Input
              isReadOnly
              variant="filled"
              name="cpf"
              label="CPF"
              defaultValue={editFields.cpf}
            />
            <Input
              type="email"
              label="Email"
              defaultValue={editFields.email}
              error={errors.email}
              {...register('email')}
            />
            <HStack spacing={6}>
              <PhoneInput
                label="Telefone principal"
                defaultValue={editFields.phone1}
                {...register('phone1')}
              />
              <PhoneInput
                label="Telefone alternativo"
                defaultValue={editFields.phone2}
                {...register('phone2')}
              />
            </HStack>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button color="gray.600" mr={3} onClick={onClose}>
            Voltar
          </Button>
          <Button isLoading={isSubmitting} colorScheme="blue" type="submit">
            Salvar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
