import { Grid, Tab, Tabs, Typography } from '@material-ui/core'
import React from 'react'
import Box from '../static/svg/productCard/box.svg'
import Diamond from '../static/svg/productCard/diamond.svg'
import Silver from '../static/svg/productCard/silver-medal.svg'
import Bronze from '../static/svg/productCard/bronze-medal.svg'
import Gold from '../static/svg/productCard/gold-ingots.svg'

export default class MousewheelControl extends React.Component {
  constructor() {
    super()
    this.state = {
      swiper: false,
      error: false,
    }
  }

  render() {
    let transactionValue = Number(this.props.transactionValue.replace(',', '.'))

    const calcValue = service => {
      const TAXA_FINAL = Number(service.administration_fee) + Number(service.over_value)
      // Encontra o valor da mensalidade
      let value = Number(transactionValue) * (TAXA_FINAL / 100)
      // Multiplica pelo tempo de contrato
      value = value * Number(service.contract_time)
      // Adiciona taxa de adesao
      value = value + Number(service.adhesion_fee)
      return value.toFixed(2)
    }

    const select = (index, service) => {
      let totalValue = calcValue(service)
      this.props.selectedProduct(index, totalValue)
    }

    const Image = props => {
      let img = <img src={Box} style={{ width: 35, height: 35 }}></img>

      if (props.name.search(new RegExp('Bronze', 'i')) !== -1) {
        return <img src={Bronze} style={{ width: 35, height: 35 }}></img>
      }
      if (props.name.search(new RegExp('Prata', 'i')) !== -1) {
        return <img src={Silver} style={{ width: 35, height: 35 }}></img>
      }
      if (props.name.search(new RegExp('Ouro', 'i')) !== -1) {
        return <img src={Gold} style={{ width: 35, height: 35 }}></img>
      }
      if (props.name.search(new RegExp('Diamante', 'i')) !== -1) {
        return <img src={Diamond} style={{ width: 35, height: 35 }}></img>
      }

      return img
    }

    const services = this.props.services

    services.sort(function (a, b) {
      if (a.total_fee > b.total_fee) {
        return 1
      }
      if (a.total_fee < b.total_fee) {
        return -1
      }
      return 0
    })

    return (
      <>
        {this.props.services.length ? (
          <Tabs
            variant="scrollable"
            scrollButtons="on"
            indicatorColor="primary"
            textColor="primary"
            value={this.props.index >= 0 ? this.props.index : false}
            TabIndicatorProps={{
              style: {
                display: 'none',
              },
            }}
            onChange={(event, newValue) => this.setState({ swiper: newValue, error: false })}
          >
            {services.map((service, index) => {
              return (
                <Tab
                  style={{
                    width: 350,
                    maxWidth: '100%',
                    borderRadius: 8,
                    padding: 8,
                    margin: '0 8px',
                    transition: '0.5s',
                    background: '#fff',
                    border: `2px solid ${
                      this.state.error
                        ? '#ff6b6b'
                        : index === this.props.index
                        ? '#009fff'
                        : '#fafafa'
                    }`,
                  }}
                  key={index}
                  id={`service-${index}`}
                  onClick={() => select(index, service)}
                  label={
                    <Grid style={{ textTransform: 'none' }} container spacing={2}>
                      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                        <Image name={service.name}></Image>
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant="body2" color="primary">
                          {service.name}
                        </Typography>
                        <Typography variant="body2">R$ {calcValue(service)} </Typography>
                      </Grid>

                      {Number(this.props.plan_id) === 3 && (
                        <Grid item xs={6}>
                          <Typography variant="body2">Valor de cobertura</Typography>
                          <Typography variant="body2" color="textSecondary">
                            R${' '}
                            {String(Number(service.valor_cobertura).toFixed(2)).replace('.', ',')}
                          </Typography>
                        </Grid>
                      )}

                      <Grid item xs={6}>
                        <Typography align="left" variant="body2">
                          Tipo de garantia
                        </Typography>
                        <Typography align="left" variant="body2" color="textSecondary">
                          {service.warranty_ucred == 1 && 'Ucred'}
                          {service.warranty_ucred != 1 && 'Própria'}
                        </Typography>
                      </Grid>
                      {Number(this.props.plan_id) === 1 ? (
                        <Grid item xs={6}>
                          <Typography align="left" variant="body2">
                            Taxa administrativa
                          </Typography>
                          <Typography align="left" variant="body2" color="textSecondary">
                            {service.administration_fee} %
                          </Typography>
                        </Grid>
                      ) : (
                        <>
                          <Grid item xs={6}>
                            <Typography align="left" variant="body2">
                              Taxa locatário
                            </Typography>
                            <Typography align="left" variant="body2" color="textSecondary">
                              {service.over_value + service.administration_fee} %
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography align="left" variant="body2">
                              Cobertura Ucred
                            </Typography>

                            <Typography align="left" variant="body2" color="textSecondary">
                              R$ {Number(service.cover_value * transactionValue).toFixed(2)}
                            </Typography>
                          </Grid>
                          <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <span
                              style={{
                                background: '#009fff',
                                color: '#fff',
                                marginLeft: 'auto',
                                padding: '6px 16px',
                                fontSize: '0.875rem',
                                minWidth: 64,
                                boxSizing: 'border-box',
                                fontWeight: 500,
                                lineHeight: 1.75,
                                borderRadius: 4,
                                letterSpacing: '0.02857em',
                                textTransform: 'uppercase',
                              }}
                              variant="contained"
                              color="primary"
                            >
                              Contratar
                            </span>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  }
                ></Tab>
              )
            })}
          </Tabs>
        ) : null}
      </>
    )
  }
}
