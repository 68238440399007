import { useState, useMemo, forwardRef, ForwardRefRenderFunction } from 'react'
import { addYears, format } from 'date-fns'
import {
  Icon,
  IconButton,
  InputRightElement,
  InputProps as ChakraInputProps,
} from '@chakra-ui/react'
import { FieldErrors } from 'react-hook-form'

import { YearPickerModal } from './YearPickerModal'
import { MonthPickerModal } from './MonthPickerModal'
import { Input } from '../Form/Input'
import { BiCalendar } from 'react-icons/bi'

interface InputProps extends ChakraInputProps {
  minDate: Date
  label: string
  name: string
  value?: number
  maskChar?: string
  mask?: string
  error?: FieldErrors
  handleChange: (event: { target: { value: string } }) => void
}

interface InputRef extends HTMLInputElement {
  focus: () => void
}

type MonthsAllowedType = {
  number: number
  title: string
  isActive: boolean
}

type YearsAllowedType = {
  year: number
}

const InputDatePicker: ForwardRefRenderFunction<InputRef, InputProps> = (
  { minDate, label, name, value, handleChange, ...rest },
  ref,
) => {
  const [year, setYear] = useState<number>()
  const [month, setMonth] = useState<number>()
  const [yearModalOpen, setYearModalOpen] = useState(false)
  const [monthModalOpen, setMonthModalOpen] = useState(false)

  const yearsAllowed = useMemo<YearsAllowedType[]>(() => {
    const endDate = addYears(minDate, 60)
    const years = []
    for (let year = minDate.getFullYear(); year <= endDate.getFullYear(); year++) {
      years.push({ year })
    }
    return years
  }, [minDate])

  const monthsAllowed = useMemo<MonthsAllowedType[] | undefined>(() => {
    if (!year) return
    const monthsTitle = [
      'Jan',
      'Fev',
      'Mar',
      'Abr',
      'Mai',
      'Jun',
      'Jul',
      'Ago',
      'Set',
      'Out',
      'Nov',
      'Dez',
    ]
    const startMonth = year <= minDate.getFullYear() ? minDate.getMonth() + 1 : 0
    const months = []
    for (let month = 0; month < 12; month++) {
      months.push({ number: month, title: monthsTitle[month], isActive: month >= startMonth })
    }
    return months
  }, [year, minDate])

  function handleSelectYear(yearSelected: number) {
    setYear(yearSelected)

    setMonthModalOpen(true)
  }

  function handleSelectMonth(monthSelected: number) {
    if (!year) return
    setMonth(monthSelected)
    setYearModalOpen(false)
    handleChange({ target: { value: `${format(new Date(year, monthSelected), 'MMyy')}` } })
    setMonthModalOpen(false)
  }

  return (
    <>
      <YearPickerModal
        isOpen={yearModalOpen}
        years={yearsAllowed}
        yearSelected={year}
        handleSelectYear={handleSelectYear}
        onClose={() => setYearModalOpen(false)}
      />
      <MonthPickerModal
        isOpen={monthModalOpen}
        months={monthsAllowed}
        monthSelected={month}
        yearSelected={year}
        handleSelectMonth={handleSelectMonth}
        onClose={() => setMonthModalOpen(false)}
      />
      <Input
        label={label}
        name={name}
        value={value}
        ref={ref}
        rightElement={
          <InputRightElement color="gray.500">
            <IconButton
              aria-label="select year"
              size="sm"
              variant="ghost"
              icon={<Icon as={BiCalendar} boxSize="5" />}
              onClick={() => setYearModalOpen(true)}
            />
          </InputRightElement>
        }
        {...rest}
      />
    </>
  )
}

export const DatePicker = forwardRef(InputDatePicker)
