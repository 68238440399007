import { forwardRef, ForwardRefRenderFunction } from 'react'
import InputMask from 'react-input-mask'
import { Input } from './Input'

const beforeMaskedValueChange = (newState: { value: string }) => {
  let { value } = newState

  const newValue = value.replace(/\D/g, '')
  if (newValue.length === 11) {
    value = newValue.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3')
  }

  return {
    ...newState,
    value,
  }
}

interface InputProps {
  name: string
  label: string
  defaultValue?: string | number
}

interface InputRef extends HTMLInputElement {
  focus: () => void
}

const BaseInputMask: ForwardRefRenderFunction<InputRef, InputProps> = (
  { name, label, ...props },
  ref,
) => {
  return (
    <Input
      as={InputMask}
      name={name}
      type="tel"
      inputMode="numeric"
      label={label}
      mask="(99) 9999-99999"
      maskChar={null}
      beforeMaskedValueChange={beforeMaskedValueChange}
      maskPlaceholder={null}
      {...props}
      ref={ref}
    />
  )
}

export const PhoneInput = forwardRef(BaseInputMask)
