import { Box, Breadcrumbs, CircularProgress, Container, Fab, Grid, Tooltip, Typography } from '@material-ui/core'
import { Add, Delete, Edit, Home } from '@material-ui/icons'
import MaterialTable from 'material-table'
import moment from 'moment'
import 'moment/locale/pt-br'
import { withSnackbar } from 'notistack'
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import api from '../../services/ApiInstance'
import Footer from '../../layout/Footer'
import Header from '../../layout/Header'
import { changeNotification, fillAguardandoTrial, fillTrial, logOut } from '../../redux/actions/authUserAction'
import { refresh } from '../../redux/actions/creditAnalysisActions'
import tableIcons from '../../styles/tableIcons'
import DeleteUser from './DeleteUser'
import EditUser from './EditUser'
import NewUser from './NewUser'
import styles from '../../styles/defaultStyles'

class Users extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      isMainUser: props.user && props.user.isMainUser ? true : false,
      data: [],
      line: {},
      deleteUserOpen: false,
    }
  }

  componentDidMount() {
    this.fetchData()
  }

  prepareData(row) {
    return {
      id: row.id,
      name: row.name,
      cpf: row.cpf,
      email: row.email,
      telephone: row.telephone,
      birthday: row.birthday,
      updated_at: moment(row.updated_at).format('DD/MM/YYYY HH:mm'),
    }
  }

  async fetchData() {
    this.setState({ loading: true })
    try {
      const { data: users } = await api.get(`usuario`)
      this.setState({ data: users.map(this.prepareData) })
      this.setState({ loading: false })
    } catch (error) {
      this.setState({ loading: false })
      const snackbarKey = this.props.enqueueSnackbar('Erro inesperado, entre em contato com nosso suporte.', {
        variant: 'error',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
    }
  }

  async deleteUser() {
    const { line } = this.state
    try {
      const { data } = this.state
      await api.delete(`usuario/${line.id}`)
      const newData = data.filter((_l) => _l.id !== line.id)
      await this.setState({ data: newData, deleteUserOpen: false })
      const snackbarKey = this.props.enqueueSnackbar('Usuário deletado com sucesso.', {
        variant: 'success',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
    } catch (error) {
      const snackbarKey = this.props.enqueueSnackbar('Não foi possível deletar este usuário.', {
        variant: 'error',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
    }
  }

  render() {
    const { data, loading, isMainUser } = this.state

    const noOverflow = {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      width: 300,
      maxWidth: 300,
      textOverflow: 'ellipsis',
    }

    const columns = [
      { field: 'name', title: 'Nome', cellStyle: noOverflow },
      { field: 'cpf', title: 'CPF', cellStyle: noOverflow },
      { field: 'email', title: 'E-mail', cellStyle: noOverflow },
      { field: 'telephone', title: 'Telefone', cellStyle: noOverflow },
      { field: 'updated_at', title: 'Última Alteração', cellStyle: noOverflow },
      {
        field: 'actions',
        title: 'Ações',
        hidden: !isMainUser,
        export: false,
        cellStyle: {
          ...noOverflow,
          display: 'flex',
          width: 200,
          justifyContent: 'center',
          height: 45,
          padding: 0,
          alignItems: 'center',
        },
        align: 'center',
      },
    ]

    data.map((data) => {
      data.actions = (
        <div style={{ display: 'flex' }}>
          <Box style={{ position: 'relative', marginRight: 8 }}>
            <Tooltip title="Editar" arrow>
              <Fab
                onClick={async () => {
                  this.EditUser.handleOpen(data)
                }}
                disabled={data.loading}
                color="primary"
                style={{
                  zIndex: 0,
                  width: 35,
                  height: 35,
                  minHeight: 'initial',
                  boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.20)',
                }}
              >
                <Edit style={{ width: 20 }} />
              </Fab>
            </Tooltip>
            {data.loading && (
              <CircularProgress
                size={24}
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: -12,
                  marginLeft: -12,
                }}
              />
            )}
          </Box>
          <Box style={{ display: 'flex', position: 'relative' }}>
            <Tooltip title="Deletar" arrow>
              <Fab
                onClick={async () => {
                  this.setState({ deleteUserOpen: true, line: data })
                }}
                disabled={data.loading}
                size="small"
                color="primary"
                style={{
                  zIndex: 0,
                  width: 35,
                  height: 35,
                  backgroundColor: styles.error,
                  minHeight: 'initial',
                  boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.20)',
                }}
              >
                <Delete style={{ width: 20 }}></Delete>
              </Fab>
            </Tooltip>
            {data.loading && (
              <CircularProgress
                size={24}
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: -12,
                  marginLeft: -12,
                }}
              />
            )}
          </Box>
        </div>
      )
    })

    return (
      <>
        <Header />
        <Container
          maxWidth="lg"
          style={{
            paddingTop: 32,
            marginTop: 80,
            marginBottom: 32
          }}
        >
          <Grid spacing={3} container style={{ margin: 0, width: 'initial' }}>
            <Grid item xs={12}>
              <Breadcrumbs className="breadcrumbs">
                <Link to="/" style={{ display: 'flex' }}>
                  <Home
                    style={{
                      marginRight: 8,
                      width: 20,
                      height: 20,
                    }}
                  />
                  Início
                </Link>
                <Typography color="textPrimary">Usuários</Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item xs={12}>
              <div style={{ position: 'relative' }}>
                <DeleteUser parent={this} open={this.state.deleteUserOpen}></DeleteUser>
                <MaterialTable
                  isLoading={loading}
                  columns={columns}
                  icons={tableIcons}
                  components={{ Container: (props) => <div id="mtable-container" {...props}></div> }}
                  data={data}
                  title="Relatório de usuários"
                  options={{
                    pageSize: 15,
                    pageSizeOptions: [15, 50, 100],
                    tableLayout: 'auto',
                    showTitle: true,
                    padding: 'dense',
                    exportAllData: true,
                    exportFileName: 'Relatório Ucred (Usuários) - ' + moment().format('DD-MM-YYYY H:mm:ss'),
                    exportButton: true,
                    actionsColumnIndex: 0,
                  }}
                  localization={{
                    header: {
                      actions: 'Ações',
                    },
                    body: {
                      emptyDataSourceMessage: 'Nenhum registro para exibir',
                    },
                    toolbar: {
                      exportTitle: 'Exportar',
                      searchTooltip: 'Pesquisar',
                      searchPlaceholder: 'Pesquisar',
                      exportCSVName: 'Exportar em CSV',
                      exportPDFName: 'Exportar em PDF',
                    },
                    pagination: {
                      labelRowsSelect: 'linhas',
                      labelDisplayedRows: '{from}-{to} de {count}',
                      firstTooltip: 'Primeira página',
                      previousTooltip: 'Página anterior',
                      nextTooltip: 'Próxima página',
                      lastTooltip: 'Última página',
                    },
                  }}
                />
                <Tooltip title="Novo usuário" placement="top-start" arrow>
                  <Fab
                    color="primary"
                    style={{ position: 'fixed', bottom: 90, right: 22, display: !isMainUser ? 'none' : 'flex' }}
                    onClick={() => {
                      this.NewUser.setState({ open: true })
                    }}
                  >
                    <Add></Add>
                  </Fab>
                </Tooltip>
              </div>
            </Grid>
          </Grid>
        </Container>

        <NewUser parent={this} ref={(reference) => (this.NewUser = reference)} />
        <EditUser parent={this} ref={(reference) => (this.EditUser = reference)} />
      </>
    )
  }
}

function mapStateToProps({ authReducer }) {
  return {
    user: authReducer.authUser,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    refresh: () => dispatch(refresh()),
    logOut: () => dispatch(logOut()),
    fillTrial: (trial) => dispatch(fillTrial(trial)),
    fillAguardandoTrial: (trial) => dispatch(fillAguardandoTrial(trial)),
    changeNotification: (status) => dispatch(changeNotification(status)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Users))
