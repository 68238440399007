import { ReactNode } from 'react'
import {
  AccordionItem as ChakraAccordionItem,
  AccordionItemProps as ChakraAccordionItemProps,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Flex,
  Text,
} from '@chakra-ui/react'

interface AccordionItemProps extends ChakraAccordionItemProps {
  index?: number
  title: string
  children: ReactNode
}
export const AccordionItem = ({ index, title, children, ...rest }: AccordionItemProps) => (
  <ChakraAccordionItem borderColor="gray.100" index={index} {...rest}>
    <AccordionButton border={0}>
      <Flex flex="1">
        <Text fontSize="sm">{title}</Text>
      </Flex>
      <AccordionIcon />
    </AccordionButton>
    <AccordionPanel>{children}</AccordionPanel>
  </ChakraAccordionItem>
)
