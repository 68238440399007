import { forwardRef, ForwardRefRenderFunction } from 'react'
import { FieldErrors } from 'react-hook-form'
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  InputGroup,
  Input as ChackraInput,
  InputProps as ChakraInputProps,
} from '@chakra-ui/react'

interface InputProps extends ChakraInputProps {
  leftElement?: JSX.Element
  rightElement?: JSX.Element
  name: string
  label: string
  error?: FieldErrors
  mask?: string | null
  maskChar?: string | null
  maskPlaceholder?: string | null
  beforeMaskedValueChange?: (newState: any) => any
}

interface InputRef extends HTMLInputElement {
  focus: () => void
}

const InputBase: ForwardRefRenderFunction<InputRef, InputProps> = (
  { leftElement, rightElement, name, label, error, color = 'gray.700', ...rest },
  ref,
) => {
  return (
    <>
      <FormControl isInvalid={!!error}>
        {!!label && (
          <FormLabel
            color="gray.500"
            fontSize="xs"
            fontWeight="semibold"
            style={{ textTransform: 'uppercase' }}
            htmlFor={name}
          >
            {label}
          </FormLabel>
        )}
        <InputGroup>
          {leftElement}
          <ChackraInput name={name} id={name} ref={ref} color={color} {...rest} />
          {rightElement}
        </InputGroup>
        {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
      </FormControl>
    </>
  )
}

export const Input = forwardRef(InputBase)
