import { format, parseISO } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'

const formatDate = (payload: string | Date | number): string => {
  if (typeof payload === 'string') {
    return format(parseISO(payload), 'dd/MM/yyyy', {
      locale: ptBR,
    })
  }
  return format(payload, 'dd/MM/yyyy', {
    locale: ptBR,
  })
}

export { formatDate }
