export const FILL_USER = 'FILL_USER'
export const FILL_COMPANY = 'FILL_COMPANY'
export const CLEAR_REDUCERS = 'CLEAR_REDUCERS'
export const FILL_TRIAL = 'FILL_TRIAL'
export const FILL_AGUARDANDO_TRIAL = 'FILL_AGUARDANDO_TRIAL'
export const FILL_RENDA = 'FILL_RENDA'
export const CHANGE_NOTIFICATION = 'CHANGE_NOTIFICATION'

export const fillUser = ( user ) => ({
    type: FILL_USER,
    user
});

export const fillTrial = (trial) => ({
    type: FILL_TRIAL,
    trial
})

export const fillCompany = (company) => ({
    type: FILL_COMPANY,
    payload: company
})

export const fillAguardandoTrial = aguardandoTrial => ({
    type: FILL_AGUARDANDO_TRIAL,
    aguardandoTrial
})

export const fillRenda = renda => ({
    type: FILL_RENDA,
    renda
})

export const changeNotification = status => ({
    type: CHANGE_NOTIFICATION,
    status
})

export const logOut = () => ({type: CLEAR_REDUCERS})
export const logOutRevendedor = () => ({type: CLEAR_REDUCERS})