import { withSnackbar } from 'notistack'
import React from 'react'
import Cards from 'react-credit-cards'
import 'react-credit-cards/es/styles-compiled.css'
import ApiInstance from '../../services/ApiInstance'
import Button from '../../components/button'
import Input from '../../components/input'

class MudarCartao extends React.Component {
  state = {
    cvv: '',
    expiry: '',
    name: '',
    number: '',
    error: false,
    hash: '',
    loading: false,
    type_card: '',
  }

  async componentDidMount() {
    try {
      const urlParams = new URLSearchParams(window.location.search)
      const name = urlParams.get('name')
      const hash = urlParams.get('hash')
      this.setState({ name, hash })
    } catch (error) {
      this.setState({ error: true })
    }
  }

  handleChangeCard = (event) => {
    let { value, id } = event.currentTarget
    this.setState({ [id]: value })
  }

  getIssuerCard = ({ issuer }) => {
    this.setState({ type_card: issuer })
  }

  confirmar = async () => {
    this.setState({ loading: true })
    try {
      let { cvv, hash, number, name, card_maturity, expiry, type_card } = this.state
      await ApiInstance.post('cliente/atualizarCartao', {
        card: {
          holder_name: name,
          expiration_date: expiry,
          number_card: number,
          cvv,
          type: type_card,
          card_maturity,
        },
        id_client: hash,
        decrypt: true,
      })
      const snackbarKey = this.props.enqueueSnackbar('Atualizado com sucesso.', {
        variant: 'success',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
      setTimeout(() => {
        window.location.href = 'http://www.ucred.com.br'
      }, 2000)
    } catch (error) {
      const snackbarKey = this.props.enqueueSnackbar(error.message, {
        variant: 'error',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
      this.setState({ loading: false })
    }
  }

  render() {
    return (
      <div className="mudarCartao">
        <div className="mudarCartao--header">
          <div>
            <img src="https://i.ibb.co/1G1R9dv/LOGO-FINAL2.png" />
          </div>
        </div>
        {!this.state.error && (
          <div className="mudarCartao--body">
            <div className="row" style={{ flexDirection: 'column', paddingBottom: '50px', textAlign: 'center' }}>
              <p className="title">Preencha os dados do cartão</p>
            </div>
            <div className="container-card">
              <div className="row responsive-row" style={{ justifyContent: 'center' }}>
                <div className="card-image">
                  <Cards
                    number={this.state.number}
                    name={this.state.name}
                    expiry={this.state.expiry}
                    cvc={this.state.cvv}
                    focused={this.state.focusCard}
                    placeholders={{ name: 'Nome do Titular', expiry: 'Data Venc' }}
                    preview={false}
                    callback={this.getIssuerCard}
                  />
                </div>
                <div className="card-item" style={{ marginLeft: '10px', position: 'relative', top: '-15px' }}>
                  <div className="row">
                    <Input
                      type="text"
                      labelDescription="Titular do Cartão"
                      placeholder="Titular do Cartão"
                      id="name"
                      value={this.state.name}
                      disabled
                      onFocus={() => this.setState({ focusCard: 'name' })}
                      onChange={this.handleChangeCard}
                    />
                  </div>
                  <div className="row">
                    <Input
                      type="text"
                      labelDescription="Número do Cartão"
                      placeholder="Número do Cartão"
                      id="number"
                      iconType="number"
                      mask="1111 1111 1111 1111"
                      value={this.state.number}
                      onFocus={() => this.setState({ focusCard: 'number' })}
                      onChange={this.handleChangeCard}
                    />
                  </div>
                  <div className="row responsive-row">
                    <Input
                      type="text"
                      labelDescription="Data de Expiração"
                      placeholder="Data de Expiração"
                      iconType="number"
                      id="expiry"
                      mask="11/11"
                      value={this.state.expiry}
                      onFocus={() => this.setState({ focusCard: 'expiry' })}
                      onChange={this.handleChangeCard}
                    />

                    <Input
                      type="text"
                      labelDescription="Cvv"
                      maxLength="4"
                      placeholder="Cvv"
                      id="cvv"
                      iconType="number"
                      value={this.state.cvv}
                      onFocus={() => this.setState({ focusCard: 'cvc' })}
                      onChange={this.handleChangeCard}
                    />

                    <Input
                      type="text"
                      labelDescription="Dia do vencimento da fatura"
                      maxLength="4"
                      placeholder="Dia do vencimento da fatura"
                      id="card_maturity"
                      iconType="number"
                      value={this.state.card_maturity}
                      onFocus={() => this.setState({ focusCard: 'cvc' })}
                      onChange={this.handleChangeCard}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row" style={{ justifyContent: 'center' }}>
              {this.state.loading && <div className="loader" />}
              {!this.state.loading && <Button description="confirmar" onClick={this.confirmar} />}
            </div>
          </div>
        )}

        {this.state.error && (
          <div>
            <h1>Infelizmente não conseguimos localizar seus dados.</h1>
            <h1>Entre em contato com a imobiliária</h1>
            <img className="logo-erro" src="https://images.vexels.com/media/users/3/127182/isolated/preview/41d323a8b70e7edbffc17f538fba13c6-blue-flat-houses-icon-by-vexels.png" alt="icone casa" />
          </div>
        )}
      </div>
    )
  }
}

export default withSnackbar(MudarCartao)
