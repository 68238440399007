type GetContractStatusProps = 'running' | 'signed' | 'canceled' | 'parcial'

type GetContractStatusType = {
  title:
    | 'Aguardando assinatura'
    | 'Assinado com a Ucred'
    | 'Cancelado. Contrato não foi assinado'
    | 'Assinado parcialmente'
    | 'Invalid status'
  color: 'linkedin' | 'green' | 'red' | 'orange' | 'red'
}

export function getContractStatus(contractStatus: GetContractStatusProps): GetContractStatusType {
  switch (contractStatus) {
    case 'running':
      return { title: 'Aguardando assinatura', color: 'linkedin' }
    case 'signed':
      return { title: 'Assinado com a Ucred', color: 'green' }
    case 'canceled':
      return { title: 'Cancelado. Contrato não foi assinado', color: 'red' }
    case 'parcial':
      return { title: 'Assinado parcialmente', color: 'orange' }
    default:
      return { title: 'Invalid status', color: 'red' }
  }
}
