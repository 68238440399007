import React from 'react'
import Header from '../../layout/Header'
import { BarChart, XAxis, Bar, YAxis, Tooltip, ResponsiveContainer } from 'recharts'
import Modal from 'react-responsive-modal'
import Api from '../../services/ApiInstance'

import { withSnackbar } from 'notistack'
import moment from 'moment'
class Dashboard extends React.Component {
  state = {
    transacoesAtivas: 0,
    valorMes: 0,
    transacoesAno: [],
    antecipacao3: 0,
    antecipacao3Desconted: 0,
    fee3: 0,
    antecipacao6: 0,
    antecipacao6Desconted: 0,
    fee6: 0,
    antecipacao12: 0,
    antecipacao12Desconted: 0,
    fee12: 0,
    open: false,
    mesSelecionado: 0,
    valorSelecionado: 0,
    feeSelecionada: 0,
  }
  async componentDidMount() {
    this.transacoesAtivas()
    this.valorTransacoes()
    this.transacoesAno()
    this.antecipacao3()
    this.antecipacao6()
    this.antecipacao12()
  }

  async transacoesAtivas() {
    const transacoesAtivas = await Api.get('relatorio/transacaoMes')
    this.setState({ transacoesAtivas: transacoesAtivas.data })
  }

  async valorTransacoes() {
    const valorMes = await Api.get('relatorio/valorMes')
    this.setState({ valorMes: valorMes.data })
  }

  async antecipacao3() {
    const antecipacao3 = await Api.get('relatorio/antecipacao3')
    this.setState({ antecipacao3: antecipacao3.data, antecipacao3Desconted: antecipacao3.data, fee3: antecipacao3.fee })
  }

  async antecipacao6() {
    const antecipacao6 = await Api.get('relatorio/antecipacao6')
    this.setState({ antecipacao6: antecipacao6.data, antecipacao6Desconted: antecipacao6.data, fee6: antecipacao6.fee })
  }

  async antecipacao12() {
    const antecipacao12 = await Api.get('relatorio/antecipacao12')
    this.setState({ antecipacao12: antecipacao12.data, antecipacao12Desconted: antecipacao12.data, fee12: antecipacao12.fee })
  }

  async solicitarAntecipacao(period) {
    switch (period) {
      case 3:
        this.setState({ open: true, mesSelecionado: 3, valorSelecionado: this.state.antecipacao3Desconted, feeSelecionada: this.state.fee3 })
        break
      case 6:
        this.setState({ open: true, mesSelecionado: 6, valorSelecionado: this.state.antecipacao6Desconted, feeSelecionada: this.state.fee6 })
        break
      case 12:
        this.setState({ open: true, mesSelecionado: 12, valorSelecionado: this.state.antecipacao12Desconted, feeSelecionada: this.state.fee12 })
        break
      default:
        alert('erro')
        break
    }
  }

  confirmarAntecipacao = async () => {
    const { mesSelecionado, valorSelecionado, feeSelecionada } = this.state
    try {
      const day = moment().format('DD')
      if (Number(day) > 10) {
        throw new Error('FALHA_DIA')
      }
      await Api.put(`solicitar/antecipacao${mesSelecionado}`, { valueDesconted: valorSelecionado, fee: feeSelecionada })
      const snackbarKey = this.props.enqueueSnackbar('Antecipado com sucesso.', { variant: 'success',
onClick: () => {
              this.props.closeSnackbar(snackbarKey)
            } })
      this.setState({ open: false })
    } catch (error) {
      if (error.message === 'FALHA_DIA') {
        const snackbarKey = this.props.enqueueSnackbar('Período de antecipação é entre os dias 01~10 de cada mês!', { variant: 'error',
onClick: () => {
              this.props.closeSnackbar(snackbarKey)
            } })
        this.setState({ open: false })
        return false
      }
      const snackbarKey = this.props.enqueueSnackbar('Falha ao Antecipar.', { variant: 'error',
onClick: () => {
              this.props.closeSnackbar(snackbarKey)
            } })
    }
  }
  onClose = () => this.setState({ open: false })
  async transacoesAno() {
    const transacoesAno = await Api.get('relatorio/graficoValorMes')
    let f_transacoesAno = transacoesAno.data.map((transacaoMes) => {
      if (transacaoMes.date === 1) transacaoMes.dateDes = 'Jan'
      if (transacaoMes.date === 2) transacaoMes.dateDes = 'Fev'
      if (transacaoMes.date === 3) transacaoMes.dateDes = 'Mar'
      if (transacaoMes.date === 4) transacaoMes.dateDes = 'Abr'
      if (transacaoMes.date === 5) transacaoMes.dateDes = 'Mai'
      if (transacaoMes.date === 6) transacaoMes.dateDes = 'Jun'
      if (transacaoMes.date === 7) transacaoMes.dateDes = 'Jul'
      if (transacaoMes.date === 8) transacaoMes.dateDes = 'Ago'
      if (transacaoMes.date === 9) transacaoMes.dateDes = 'Set'
      if (transacaoMes.date === 10) transacaoMes.dateDes = 'Out'
      if (transacaoMes.date === 11) transacaoMes.dateDes = 'Nov'
      if (transacaoMes.date === 12) transacaoMes.dateDes = 'Dez'
      return transacaoMes
    })
    f_transacoesAno.sort((a, b) => parseInt(a.date) - parseInt(b.date))
    this.setState({ transacoesAno: f_transacoesAno })
  }
  render() {
    const {
      transacoesAtivas,
      valorMes,
      transacoesAno,
      antecipacao3,
      antecipacao6,
      antecipacao12,
      open,
      mesSelecionado,
      valorSelecionado,
    } = this.state
    return (
      <React.Fragment>
        <Header />
        <div className="dashboard">
          <div className="dashboard__container">
            <div className="dashboard__container-header">
              <h1> Dashboard </h1>
            </div>
            <div className="dashboard__container-body">
              <div className="row-grid">
                <div className="col-12 col-md-4">
                  <div className="antecipacao-card antecipacao-card--first" onClick={() => this.solicitarAntecipacao(3)}>
                    <div className="antecipacao-card-head">
                      <span>Saldo para antecipar em: </span>
                      <span>3 Meses</span>
                    </div>
                    <div className="antecipacao-card-body">
                      <div className="antecipacao-card-body--value">R$ {String(antecipacao3).replace('.', ',')}</div>
                    </div>
                    <div className="antecipacao-card-footer">
                      <span>Clique para antecipar</span>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="antecipacao-card antecipacao-card--second" onClick={() => this.solicitarAntecipacao(6)}>
                    <div className="antecipacao-card-head">
                      <span>Saldo para antecipar em: </span>
                      <span>6 Meses</span>
                    </div>
                    <div className="antecipacao-card-body">
                      <div className="antecipacao-card-body--value">R$ {String(antecipacao6).replace('.', ',')}</div>
                    </div>
                    <div className="antecipacao-card-footer">
                      <span>Clique para antecipar</span>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="antecipacao-card antecipacao-card--ter" onClick={() => this.solicitarAntecipacao(12)}>
                    <div className="antecipacao-card-head">
                      <span>Saldo para antecipar em: </span>
                      <span>12 Meses</span>
                    </div>
                    <div className="antecipacao-card-body">
                      <div className="antecipacao-card-body--value">R$ {String(antecipacao12).replace('.', ',')}</div>
                    </div>
                    <div className="antecipacao-card-footer">
                      <span>Clique para antecipar</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row-grid">
                <div className="col-12 col-md-6">
                  <div className="mes-card mes-card--first">
                    <div className="mes-card-head">
                      <span>Quantidade de Transações Ativas no Mês:</span>
                    </div>
                    <div className="mes-card-body">
                      <span>{transacoesAtivas}</span>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-6">
                  <div className="mes-card mes-card--second">
                    <div className="mes-card-head">
                      <span>Valor à receber:</span>
                    </div>
                    <div className="mes-card-body">
                      <span>R$ {String(valorMes).replace('.', ',')} </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row-grid">
                <div className="col-12 col-md-12" style={{ display: 'flex', justifyContent: 'center' }}>
                  <div className="month" style={{ width: '100%' }}>
                    <div className="month-header">Recebimentos ao mês</div>
                    <div className="month-body">
                      <ResponsiveContainer>
                        <BarChart data={transacoesAno} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                          <XAxis dataKey="dateDes" />
                          <YAxis />
                          <Tooltip />
                          <Bar dataKey="valor" stroke="#005f99" fill="#005f99" />
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal open={open} onClose={this.onClose} center classNames={{ modal: 'modalA' }}>
            <div className="modal__antecipacao">
              <div className="modal__antecipacao-head">
                <span>Valor a receber antecipado </span>
                <span>{mesSelecionado} Meses </span>
              </div>
              <div className="modal__antecipacao-body">R$ {String(Number(valorSelecionado).toFixed(2)).replace('.', ',')}</div>
              <div className="modal__antecipacao-footer">
                <span className="btn-cancelar" onClick={this.onClose}>
                  Cancelar
                </span>
                <span></span>
                <span className="btn-confirmar" onClick={this.confirmarAntecipacao}>
                  Confirmar
                </span>
              </div>
            </div>
          </Modal>
        </div>
      </React.Fragment>
    )
  }
}

export default withSnackbar(Dashboard)
