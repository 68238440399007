import { useRef } from 'react'
import {
  AlertDialog as ChakraAlert,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Text,
} from '@chakra-ui/react'

interface AlertDialog {
  isOpen: boolean
  onCancel: () => void
  onConfirm: () => void
  loading: boolean
  description: string
}

export function AlertDialog({ isOpen, onCancel, onConfirm, loading, description }: AlertDialog) {
  const cancelRef = useRef(null)
  return (
    <ChakraAlert
      motionPreset="slideInRight"
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onCancel}
      isCentered
      size="xl"
      variant="subtle"
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader color="gray.600" fontSize="lg" fontWeight="bold">
            Atenção!
          </AlertDialogHeader>

          <AlertDialogBody>
            <Text fontSize="md" color="gray.600">
              Esta ação é <strong style={{ color: '#E53E3E' }}>irreversível</strong>, ou seja, após
              cancelada, a venda não poderá ser revertida ao estado anterior.
            </Text>
            <Text fontSize="md" color="gray.600" mt={6} noOfLines={[1, 2, 3]}>
              Você está cancelando a transação pelo seguinte motivo:
            </Text>
            <Text fontSize="sm" color="gray.500" mt={4} mb={8} noOfLines={[1, 2, 3, 4, 5]}>
              {description}
            </Text>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button color="gray.600" ref={cancelRef} onClick={onCancel}>
              Voltar
            </Button>
            <Button isLoading={loading} colorScheme="red" onClick={onConfirm} ml={3}>
              Confirmar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </ChakraAlert>
  )
}
