import DateFnsUtils from '@date-io/moment'
import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core'
import { Add, ArrowBack, Clear, Close, Home } from '@material-ui/icons'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { DropzoneArea } from 'material-ui-dropzone'
import moment from 'moment'
import React from 'react'
import MaskedInput from 'react-input-mask'

import { withSnackbar } from 'notistack'
import { Link } from 'react-router-dom'
import ApiInstance from '../../../services/ApiInstance'
import validateCpf from '../../../functions/validateCpf'
import validateEmail from '../../../functions/validateEmail'
import Footer from '../../../layout/Footer'
import Header from '../../../layout/Header'

class SignerDocumentSend extends React.Component {
  constructor() {
    super()
    this.state = {
      step: 1,
      btnToDocumentHidden: true,
      loading: false,
      acceptedTerm: false,
      purchase: [],
      contracts: [],
      client: {},
      user: { name: '', cpf: '', birthday: null, email: '' },
      signers: [],
      confirm: false,
    }
  }

  async componentDidMount() {
    const { purchase_id } = this.props.match.params

    try {
      let user = await ApiInstance.get(`/signer/${purchase_id}`)

      let signers = await user.data.signers.filter(function (signer) {
        return signer.is_main !== '1'
      })

      let signersArr = []
      if (signers.length > 5) {
        signersArr[0] = signers[signers.length - 5]
        signersArr[1] = signers[signers.length - 4]
        signersArr[2] = signers[signers.length - 3]
        signersArr[3] = signers[signers.length - 2]
        signersArr[4] = signers[signers.length - 1]
      }

      let mainUser = await user.data.signers.filter(function (user) {
        return user.is_main === '1'
      })

      if (signers.length) {
        signers.map((signer) => {
          signer.birthday = new Date(signer.birthday)
        })

        this.setState({ signers: signersArr.length ? signersArr : signers })
      } else {
        this.setState({
          signers: [
            {
              id: null,
              name: '',
              cpf: '',
              birthday: null,
              email: '',
            },
          ],
        })
      }

      if (mainUser.length) {
        this.setState({
          user: {
            id: mainUser[mainUser.length - 1].id,
            name: mainUser[mainUser.length - 1].name,
            cpf: mainUser[mainUser.length - 1].cpf,
            birthday: moment(mainUser[mainUser.length - 1].birthday).format('YYYY-MM-DD'),
            email: mainUser[mainUser.length - 1].email,
          },
        })
      } else {
        this.setState({
          user: {
            name: user.data.userMain.name,
            cpf: user.data.userMain.cpf,
            birthday: moment(user.data.userMain.birthday, 'DD/MM/YYYY').format('YYYY-MM-DD'),
            email: user.data.userMain.email,
          },
        })
      }
    } catch (error) {
      const snackbarKey = this.props.enqueueSnackbar('Erro inesperado, entre em contato com nosso suporte.', {
        variant: 'error',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
    }

    this.fetchContracts()
  }

  /* -------------------------------------------------------------------------- */
  /*                                PRÓXIMO PASSO                               */
  /* -------------------------------------------------------------------------- */

  nextStep() {
    try {
      let { step } = this.state

      if (step === 2 && !this.state.user.name) {
        throw new Error('Insira o nome do locatário.')
      }

      if (step === 2 && !this.state.user.cpf) {
        throw new Error('Insira o CPF do locatário.')
      }

      if (step === 2 && !this.state.user.birthday) {
        throw new Error('Insira a data de nascimento do locatário.')
      }

      if (step === 2 && !this.state.user.email) {
        throw new Error('Insira o email do locatário.')
      }

      if (step === 1 && !this.state.contracts.length) {
        throw new Error('Insira ao menos 1 contrato.')
      }

      if (step >= 2) {
        return
      }

      this.setState({ step: step + 1 })
    } catch (error) {
      const snackbarKey = this.props.enqueueSnackbar(error.message, {
        variant: 'error',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
    }
  }

  /* -------------------------------------------------------------------------- */
  /*                               PASSO ANTERIOR                               */
  /* -------------------------------------------------------------------------- */

  prevStep() {
    let { step } = this.state

    if (step === 1) {
      return
    }

    this.setState({ step: step - 1 })
  }

  /* -------------------------------------------------------------------------- */
  /*                 CARREGAR QUANTIDADE DE DOCUMENTOS ENVIADOS                 */
  /* -------------------------------------------------------------------------- */

  async fetchContracts() {
    const { purchase_id } = this.props.match.params
    let contractSigners = await ApiInstance.get(`contractSigner/${purchase_id}`)

    if (!contractSigners.contracts.length) {
      this.setState({
        btnToDocumentHidden: true,
        client: contractSigners.client,
      })
    } else {
      this.setState({
        btnToDocumentHidden: false,
        client: contractSigners.client,
      })
    }
  }

  /* -------------------------------------------------------------------------- */
  /*                     ALTERAR OS VALORES DOS SIGNATÁRIOS                     */
  /* -------------------------------------------------------------------------- */

  async handleChangeSigners(value, field, index) {
    let signers = this.state.signers

    signers[index][field] = value

    if (field === 'cpf' && value.length === 14) {
      this.setState({ disableInput: true })
      await ApiInstance.post(`basic-data`, { document: value, type: 'cpf' })
        .then((response) => {
          signers[index].name = response.response.Result[0].BasicData.Name || ''
          signers[index].birthday = response.response.Result[0].BasicData.BirthDate
            ? response.response.Result[0].BasicData.BirthDate.split('T')[0]
            : null
          this.setState({ disableInput: false })
        })
        .catch(() => {
          this.setState({ disableInput: false })
        })
    }

    this.setState({ signers })
  }

  /* -------------------------------------------------------------------------- */
  /*                             INSERIR UM CONTRATO                            */
  /* -------------------------------------------------------------------------- */
  dropContract(files) {
    const { contracts } = this.state
    let newContracts = []

    if (files) {
      files.map((file) => {
        this.convertToBase64([file], (base64) => {
          newContracts.push({
            base64,
            id: contracts.length + 1,
            description: file.name.split('.')[0],
          })
        })
      })
    }

    this.setState({ contracts: newContracts })
  }

  /* -------------------------------------------------------------------------- */
  /*                            CONVERTER PARA BASE64                           */
  /* -------------------------------------------------------------------------- */

  convertToBase64(file, cb) {
    //Read File
    var selectedFile = file
    //Check File is not Empty
    if (selectedFile.length > 0) {
      // Select the very first file from list
      var fileToLoad = selectedFile[0]
      // FileReader function for read the file.
      var fileReader = new FileReader()
      var base64
      // Onload of file read the file content
      fileReader.onload = function (fileLoadedEvent) {
        base64 = fileLoadedEvent.target.result
        // Print data in console
        cb(base64)
      }
      // Convert data to base64
      fileReader.readAsDataURL(fileToLoad)
    }
  }

  /* -------------------------------------------------------------------------- */
  /*                             REMOVER UM CONTRATO                            */
  /* -------------------------------------------------------------------------- */

  removeContract(id) {
    const { contracts } = this.state
    const newContracts = contracts.filter((_c) => _c.id !== id)
    this.setState({ contracts: newContracts })
  }

  /* -------------------------------------------------------------------------- */
  /*                              ENVIAR FORMULÁRIO                             */
  /* -------------------------------------------------------------------------- */

  render() {
    const { signers, contracts, user, loading } = this.state

    /* -------------------------------------------------------------------------- */
    /*                          ALTERA O NOME DO CONTRATO                         */
    /* -------------------------------------------------------------------------- */

    const changeNameContract = (event, index) => {
      let { value } = event.target

      contracts[index].description = value
      this.setState({ contracts })
    }

    const submit = async () => {
      this.setState({ loading: true })

      const auxUser = { ...user }

      const snackbarKey = this.props.enqueueSnackbar('Aguarde a conclusão do envio antes de fechar a página.', {
        variant: 'info',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
      const { purchase_id } = this.props.match.params

      auxUser.is_main = '1'

      try {
        const emailIsValid = validateEmail(this.state.user.email)
        const cpfIsValid = validateCpf(this.state.user.cpf)

        if (!this.state.user.birthday) {
          throw new Error('Informe a data de nascimento.')
        }

        if (!cpfIsValid.success) {
          throw new Error(cpfIsValid.error)
        }
        if (!emailIsValid.success) {
          throw new Error(emailIsValid.error)
        }

        auxUser.birthday = moment(auxUser.birthday).format('DD/MM/YYYY')
        signers.map((signer) => {
          signer.birthday = moment(signer.birthday).format('DD/MM/YYYY')
        })

        /* ---------------------------- REQUISIÇÃO NA API --------------------------- */

        await ApiInstance.post('signer', {
          purchase_id,
          contracts,
          signers: [auxUser, ...signers],
          auxUser,
        })

        const snackbarKey = this.props.enqueueSnackbar('Enviado com sucesso.', {
          variant: 'success',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        })
        this.setState({ loading: false })
        this.props.history.push(`/documentos/${purchase_id}`)
      } catch (error) {
        this.setState({ loading: false })

        const snackbarKey = this.props.enqueueSnackbar(error.message, {
          variant: 'error',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        })

        return
      }
    }

    const openConfirm = () => {
      const { signers, user } = this.state

      /* ---- VERIFICANDO SE HÁ CPF OU EMAIL REPETIDO NO CAMPO DOS SIGNATÁRIOS ---- */

      let nameArr = []
      let cpfArr = []
      let emailArr = []
      let error = []

      let findDuplicates = (arr) => arr.filter((item, index) => arr.indexOf(item) != index)

      signers.map((value) => {
        if (value.name === user.name) {
          error.push('O nome do signatário ' + value.name + ' deve ser diferente do usuário.')
        }
        nameArr.push(value.name)

        if (value.email === user.email) {
          error.push('O email do signatário ' + value.email + ' deve ser diferente do usuário.')
        }
        emailArr.push(value.email)

        if (value.cpf === user.cpf) {
          error.push('O CPF do signatário ' + value.cpf + ' deve ser diferente do usuário.')
        }
        cpfArr.push(value.cpf)
      })

      if (error.length) {
        const snackbarKey = this.props.enqueueSnackbar(error[0], {
          variant: 'error',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        })
        return
      }

      try {
        if (findDuplicates(nameArr).length) {
          throw new Error('O nome ' + findDuplicates(nameArr) + ' está duplicado.')
        }

        if (findDuplicates(emailArr).length) {
          throw new Error('O email ' + findDuplicates(emailArr) + ' está duplicado.')
        }

        if (findDuplicates(cpfArr).length) {
          throw new Error('O CPF ' + findDuplicates(cpfArr) + ' está duplicado.')
        }
      } catch (error) {
        const snackbarKey = this.props.enqueueSnackbar(error.message, {
          variant: 'error',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        })
      }

      /* -------------------------------------------------------------------------- */
      /*                              VALIDANDO USUÁRIO                             */
      /* -------------------------------------------------------------------------- */
      try {
        let validateName = user.name.split(' ')
        if (!user.name) {
          throw new Error('Informe o nome do usuário.')
        } else if (validateName.length <= 1) {
          throw new Error('O nome do usuário deve conter o sobrenome.')
        }

        if (!validateEmail(user.email).success) {
          throw new Error('O email do usuário é inválido.')
        }
        if (!validateCpf(user.cpf).success) {
          throw new Error('O CPF do usuário é inválido.')
        }

        if (!this.state.user.birthday) {
          throw new Error('Informe a data de nascimento.')
        }
      } catch (error) {
        const snackbarKey = this.props.enqueueSnackbar(error.message, {
          variant: 'error',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        })
        return
      }

      /* -------------------------------------------------------------------------- */
      /*                            VALIDANDO SIGNATÁRIOS                           */
      /* -------------------------------------------------------------------------- */

      try {
        /* ------------------ VERIFICANDO SE OS CAMPOS SÃO VÁLIDOS ------------------ */

        signers.map((value, index) => {
          /* ----------------------------- VALIDANDO NOME ----------------------------- */

          let validateName = value.name.split(' ')
          if (!value.name) {
            throw new Error('Informe o nome do signatário ' + (index + 1) + '.')
          } else if (validateName.length <= 1) {
            throw new Error('O nome do signatário ' + (index + 1) + ' deve conter o sobrenome.')
          }

          /* ----------------------------- VALIDANDO EMAIL ---------------------------- */

          if (!validateEmail(value.email).success) {
            throw new Error('O email do signatário ' + (index + 1) + ' é inválido.')
          }
          /* ------------------------------ VALIDANDO CPF ----------------------------- */

          if (!validateCpf(value.cpf).success) {
            throw new Error('O CPF do signatário ' + (index + 1) + ' é inválido.')
          }

          /* ----------------------------- VALIDANDO DATA ---------------------------- */
          if (!this.state.user.birthday) {
            throw new Error('Informe a data de nascimento.')
          }
        })

        this.setState({ confirm: true })
      } catch (error) {
        const snackbarKey = this.props.enqueueSnackbar(error.message, {
          variant: 'error',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        })
      }
    }
    /* -------------------------------------------------------------------------- */
    /*                            ADICIONAR SIGNATÁRIO                            */
    /* -------------------------------------------------------------------------- */

    const addSigner = () => {
      try {
        if (signers.length >= 5) {
          throw new Error('O número máximo de signatários é 5.')
        }

        signers.push({
          id: null,
          name: '',
          cpf: '',
          birthday: null,
          email: '',
        })

        this.setState({ signers })
      } catch (error) {
        const snackbarKey = this.props.enqueueSnackbar(error.message, {
          variant: 'error',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        })
      }
    }

    /* -------------------------------------------------------------------------- */
    /*                             REMOVER SIGNATÁRIO                             */
    /* -------------------------------------------------------------------------- */
    const removeSigner = (index) => {
      try {
        if (signers.length <= 1) {
          throw new Error('O número mínimo de signatários é 1.')
        }

        /* ------------- NECESSÁRIO, POIS OS DADOS CONTINUAVAM NO INPUT ------------- */

        signers.splice(index, 1)

        this.setState({ signers })
      } catch (error) {
        const snackbarKey = this.props.enqueueSnackbar(error.message, {
          variant: 'error',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        })
      }
    }

    return (
      <>
        <Header />
        <Container
          maxWidth="lg"
          style={{
            paddingTop: 32,
            marginTop: 80
          }}
        >
          <Grid spacing={3} container>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Breadcrumbs className="breadcrumbs">
                <Link to="/" style={{ display: 'flex' }}>
                  <Home
                    style={{
                      marginRight: 8,
                      width: 20,
                      height: 20,
                    }}
                  />
                  Início
                </Link>
                <Typography
                  onClick={() => {
                    let { purchase_id } = this.props.match.params
                    this.props.history.push(`/documentos/${purchase_id}`)
                  }}
                >
                  Documentos
                </Typography>
                <Typography color="textPrimary">Novo envio</Typography>
              </Breadcrumbs>
              <Button
                color="primary"
                variant="outlined"
                onClick={() => {
                  this.props.history.push(`/detalhe-do-cliente/${this.state.client.id}`)
                }}
              >
                <ArrowBack></ArrowBack>
                Voltar
              </Button>
            </Grid>

            {/* -------------------------------------------------------------------------- */}
            {/*                                   PASSO 1                                  */}
            {/* -------------------------------------------------------------------------- */}

            {this.state.step === 1 && (
              <>
                <Grid item xs={12}>
                  <DropzoneArea
                    dropzoneText={
                      'Arraste e solte arquivos aqui ou clique para fazer upload. São aceitos apenas aquivos .pdf'
                    }
                    getFileLimitExceedMessage={(filesLimit) =>
                      `Limite máximo de arquivos excedidos. São permitidos no máximo ${filesLimit} arquivos.`
                    }
                    getFileAddedMessage={(fileName) => `O arquivo ${fileName} foi adicionado.`}
                    getFileRemovedMessage={(fileName) => `O arquivo ${fileName} foi removido.`}
                    getDropRejectMessage={(file) =>
                      `O arquivo ${file.name} foi rejeitado. São aceitos no máximo 3 arquivos, com tamanho máximo de 2Mb no formato pdf.`
                    }
                    acceptedFiles={['application/pdf']}
                    onChange={(file) => this.dropContract(file)}
                    maxFileSize={2000000}
                  ></DropzoneArea>
                </Grid>
                <Grid item xs={12} align="right">
                  <Button
                    disabled={this.state.btnToDocumentHidden}
                    style={{ marginRight: 16 }}
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                      let { purchase_id } = this.props.match.params
                      this.props.history.push(`/documentos/${purchase_id}`)
                    }}
                  >
                    Documentos enviados
                  </Button>
                  <Button color="primary" variant="contained" onClick={() => this.nextStep()}>
                    Enviar documentos
                  </Button>
                </Grid>
              </>
            )}

            {/* -------------------------------------------------------------------------- */}
            {/*                                   PASSO 2                                  */}
            {/* -------------------------------------------------------------------------- */}

            {this.state.step === 2 && (
              <>
                <Grid item xs={12} md={6}>
                  <Box
                    style={{ background: '#fff', borderRadius: 16, boxShadow: '0px 0px 10px 0px rgb(0, 0, 0, .05)' }}
                  >
                    <Box p={2}>
                      <Grid
                        container
                        spacing={2}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                      >
                        <Grid item xs={'auto'}>
                          <Typography align="justify">Responsável pela imobiliária</Typography>
                        </Grid>
                      </Grid>
                    </Box>
                    <Divider></Divider>
                    <Box p={3}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <MaskedInput
                            maskChar=""
                            size="small"
                            mask="999.999.999-99"
                            fullWidth
                            variant="outlined"
                            placeholder="123.456.789-00"
                            onChange={(event) => {
                              let cpf = event.target.value
                              let user = this.state.user
                              user.cpf = cpf
                              this.setState({ user })
                            }}
                            value={this.state.user.cpf}
                            label="CPF"
                            id="cpf"
                          >
                            {(inputProps) => <TextField {...inputProps} />}
                          </MaskedInput>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            onChange={(event) => {
                              let user = this.state.user
                              let name = event.target.value
                              if (/[^a-zÀ-ú ]/gi.test(name)) {
                                return
                              }

                              user.name = name
                              this.setState({ user })
                            }}
                            value={this.state.user.name}
                            placeholder="Nome"
                            label="Nome"
                            id="name"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            placeholder="E-mail"
                            label="E-mail"
                            id="email"
                            onChange={(event) => {
                              let user = this.state.user
                              let email = event.target.value
                              user.email = email
                              this.setState({ user })
                            }}
                            value={this.state.user.email}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              fullWidth
                              size="small"
                              disableFuture
                              openTo="year"
                              name="birthday"
                              format="DD/MM/YYYY"
                              label="Data de nascimento"
                              inputVariant="outlined"
                              invalidDateMessage="Data inválida"
                              maxDate={moment().add(-18, 'years').format('YYYY-MM-DD')}
                              onChange={(date) => {
                                this.setState({ user: { ...user, birthday: date } })
                              }}
                              value={this.state.user.birthday}
                              placeholder="01/01/1990"
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>

                {signers.map((signer, index) => {
                  return (
                    <Grid item xs={12} md={6} key={index}>
                      <Box
                        style={{
                          background: '#fff',
                          borderRadius: 16,
                          boxShadow: '0px 0px 10px 0px rgb(0, 0, 0, .05)',
                        }}
                      >
                        <Box p={2}>
                          <Grid
                            container
                            spacing={2}
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                          >
                            <Grid item xs={'auto'}>
                              <Typography align="justify">Signatário {index + 1}</Typography>
                            </Grid>
                            <Grid style={{ display: index === 0 ? 'none' : '' }} item xs={'auto'}>
                              <IconButton
                                onClick={() => {
                                  removeSigner(index)
                                }}
                                color="primary"
                                style={{ padding: 0 }}
                              >
                                <Clear></Clear>
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Box>
                        <Divider></Divider>
                        <Box p={3}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <MaskedInput
                                maskChar=""
                                size="small"
                                mask="999.999.999-99"
                                fullWidth
                                variant="outlined"
                                placeholder="123.456.789-00"
                                onChange={(event) => {
                                  this.handleChangeSigners(event.target.value, 'cpf', index)
                                }}
                                value={signer.cpf}
                                label="CPF"
                                id="cpf"
                              >
                                {(inputProps) => <TextField {...inputProps} />}
                              </MaskedInput>
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                fullWidth
                                size="small"
                                variant="outlined"
                                onChange={(event) => {
                                  if (/[^a-zÀ-ú ]/gi.test(event.target.value)) {
                                    return
                                  }

                                  this.handleChangeSigners(event.target.value, 'name', index)
                                }}
                                placeholder="Nome"
                                label="Nome"
                                id="name"
                                value={signer.name}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                fullWidth
                                size="small"
                                variant="outlined"
                                onChange={(event) => {
                                  this.handleChangeSigners(event.target.value, 'email', index)
                                }}
                                placeholder="E-mail"
                                label="E-mail"
                                id="email"
                                value={signer.email}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  fullWidth
                                  size="small"
                                  disableFuture
                                  openTo="year"
                                  name="birthday"
                                  format="DD/MM/YYYY"
                                  label="Data de nascimento"
                                  inputVariant="outlined"
                                  invalidDateMessage="Data inválida"
                                  maxDate={moment().add(-18, 'years').format('YYYY-MM-DD')}
                                  onChange={(date) => {
                                    this.handleChangeSigners(date, 'birthday', index)
                                  }}
                                  value={signer.birthday}
                                  placeholder="01/01/1990"
                                />
                              </MuiPickersUtilsProvider>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </Grid>
                  )
                })}

                <Grid
                  style={{ display: signers.length >= 5 ? 'none' : '' }}
                  item
                  xs={12}
                  md={6}
                  onClick={() => {
                    addSigner()
                  }}
                >
                  <Box
                    p={3}
                    style={{
                      cursor: 'pointer',
                      background: '#fff',
                      borderRadius: 16,
                      height: 306,
                      border: 'dashed rgba(0, 0, 0, 0.12)',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                      boxShadow: '0px 0px 10px 0px rgb(0, 0, 0, .05)',
                    }}
                  >
                    <Add style={{ fontSize: 56 }}></Add>
                    <Typography>Clique aqui para adicionar um signatário</Typography>
                    <Typography variant="body2" color="textSecondary" align="center">
                      Os signatários são as partes envolvidas que irão assinar digitalmente documento.
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button variant={'outlined'} color="primary" disabled={loading} onClick={() => this.prevStep()}>
                      Voltar
                    </Button>
                    <Box style={{ display: 'flex', position: 'relative' }}>
                      <Button
                        style={{ marginLeft: 8 }}
                        disabled={loading}
                        onClick={() => {
                          openConfirm()
                        }}
                        color="primary"
                        variant="contained"
                      >
                        Enviar
                      </Button>
                      {loading && (
                        <CircularProgress
                          size={24}
                          style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: -12,
                            marginLeft: -12,
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
        </Container>

        {/* -------------------------------------------------------------------------- */}
        {/*                        MODAL DE CONFIMAÇÃO DE ENVIO                        */}
        {/* -------------------------------------------------------------------------- */}
        <Dialog
          open={this.state.confirm}
          onClose={() => {
            if (loading) return
            this.setState({ confirm: false })
          }}
          center
          gutterBottom
        >
          <DialogTitle
            disableTypography
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6">Atenção</Typography>
            <IconButton onClick={() => this.setState({ documentDownload: false })}>
              <Close></Close>
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <Typography>Você enviará para os seguintes destinatários:</Typography>
            <Typography variant="body2" color="textSecondary">
              {this.state.user.name} ({this.state.user.email})
            </Typography>
            {signers.map((signer, index) => {
              if (signer.name && signer.email && signer.birthday && signer.cpf)
                return (
                  <Typography variant="body2" color="textSecondary" key={index}>
                    {signer.name} ({signer.email})
                  </Typography>
                )
            })}
            <Typography style={{ marginTop: 12 }}>Os seguintes documentos:</Typography>
            <Grid container spacing={2}>
              {' '}
              {this.state.contracts.length > 0 &&
                this.state.contracts.map((contract, index) => (
                  <Grid item xs={12} md={'auto'} key={contract.id}>
                    <Box style={{ display: 'flex', flexDirection: 'column' }}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} key={contract.id}>
                          <embed style={{ borderRadius: 12, width: '100%' }} src={contract.base64} />
                        </Grid>
                        <Grid item xs={12} key={contract.id}>
                          <TextField
                            fullWidth
                            label="Nome do documento"
                            placeholder="Documento X"
                            variant="outlined"
                            value={contract.description}
                            onChange={(event) => changeNameContract(event, index)}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                ))}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              disabled={loading}
              onClick={() => {
                this.setState({ confirm: false })
              }}
            >
              Cancelar
            </Button>
            <Box style={{ display: 'flex', position: 'relative' }}>
              <Button disabled={loading} onClick={submit} color="primary" variant="contained">
                Enviar
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: -12,
                    marginLeft: -12,
                  }}
                />
              )}
            </Box>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

export default withSnackbar(SignerDocumentSend)
