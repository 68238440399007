import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, Grid, Hidden, IconButton, TextField, Typography } from '@material-ui/core'
import { Close, Email, WhatsApp } from '@material-ui/icons'
import Lottie from 'lottie-react-web'
import { withSnackbar } from 'notistack'
import React from 'react'
import MaskedInput from 'react-input-mask'
import { connect } from 'react-redux'
import ApiInstance from '../../services/ApiInstance'
import validateCpf from '../../functions/validateCpf'
import Footer from '../../layout/Footer'
import { fillField, refresh } from '../../redux/actions/creditAnalysisActions'
import ApprovedReproved from '../../static/animations/approved-reproved.json'
import CPF from '../../static/images/CPF.png'

class Step1 extends React.Component {
  state = {
    openModalWasAnalysed: false,
    loading: false,
    error: false,
    errorMessage: 'Origem do erro não identificada.',
  }

  closeModalWasAnalysed = () => {
    this.setState({ openModalWasAnalysed: false })
  }

  handleChange = (event) => {
    let { fillField, client } = this.props
    this.setState({ cpfError: false })
    let { id, value } = event.currentTarget
    if (id === 'cpf') fillField('client', { ...client, cpf: value })
    fillField(id, value)
  }

  analyse = async () => {
    try {
      let delay = false

      setTimeout(() => {
        delay = true
      }, 1000)

      let { cpf, changeLoading, client, fillField, oldCpf } = this.props
      if (!cpf) throw new Error('CPF_NAO_INFORMADO')

      let validation = validateCpf(cpf)
      if (validation.error) {
        this.setState({ cpfError: true })
        document.getElementById('cpf').focus()
        const snackbarKey = this.props.enqueueSnackbar(validation.error, {
          variant: 'error',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        })

        return
      }

      changeLoading(true)
      this.setState({ loading: true })
      let search = await ApiInstance.post('/client_analyse', { cpf, method: 'ondemand_credit_spc_score_12_months' })

      if (search.BasicData && search.BasicData.TaxIdStatus === 'DOCUMENT IS NOT REGISTERED' && !search.approved) {
        this.props.changeLoading(false)
        return this.setState({ loading: false, error: true, errorMessage: 'O CPF informado não está registrado no SPC.' })
      }

      if (!search.BasicData && !search.approved) {
        this.props.changeLoading(false)
        return this.setState({
          loading: false,
          error: true,
          errorMessage: 'Não foi possível analisar este CPF, verifique se os dados estão corretos.',
        })
      }

      if (!search.approved) {
        this.props.changeLoading(false)
        return this.setState({
          loading: false,
          error: true,
          errorMessage: 'O score deste locatário não atingiu o necessário para aprovação na Ucred.',
        })
      }

      if (!search.BasicData) {
        search.BasicData = {}
      }

      fillField('client', {
        ...client,
        cpf: search.BasicData.TaxIdNumber,
        name: search.BasicData.Name,
        birthday: search.BasicData.BirthDate,
      })

      if (!oldCpf) {
        fillField('oldCpf', {
          ...client,
        })
      }

      //Victtor solicitou que houvesse um delay mínimo de 8 segundos na análise
      while (delay !== true) {
        await new Promise((resolve) => setTimeout(resolve, 200))
      }

      this.props.changeLoading(false)
      this.setState({ loading: false, openModalWasAnalysed: true })
      return true
    } catch (error) {
      this.props.changeLoading(false)
      this.setState({ loading: false })
      if (error.message == 'CPF_NAO_INFORMADO') {
        const snackbarKey = this.props.enqueueSnackbar('Informe o CPF à ser analisado.', {
          variant: 'error',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        })
        document.getElementById('cpf').focus()
        this.setState({ cpfError: true })
        return
      }
      if (error.originalMessage == 'Informe um documento válido.') {
        const snackbarKey = this.props.enqueueSnackbar(error.originalMessage, {
          variant: 'error',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        })
        document.getElementById('cpf').focus()
        this.setState({ cpfError: true })
        return
      }
      if (error.message == 'ERRO_ANALISE') {
        return this.setState({ loading: false, error: true, errorMessage: 'Erro análise.' })
      } else {
        const snackbarKey = this.props.enqueueSnackbar(error.message, {
          variant: 'error',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        })
        return
      }
    }
  }

  avancar = async () => {
    try {
      this.props.changeLoading(true)
      this.setState({
        openModalWasAnalysed: false,
        loading: true,
      })
      this.props.nextStep()
      this.props.changeLoading(false)
    } catch (error) {
      this.props.changeLoading(false)
      const snackbarKey = this.props.enqueueSnackbar(error.message, {
        variant: 'error',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
      return
    }
  }

  render() {
    let { authUser, client, loading } = this.props
    let customName = ''
    if (client.name) {
      customName = client.name.split(' ')
      customName = `${customName[0]} ${customName[customName.length - 1]}`
    }
    let { errorMessage, error, cpfError } = this.state
    return (
      <div style={{ display: 'flex' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <Box style={{ position: 'relative' }}>
              <Hidden xsDown>
                <img src={CPF} alt="cpf" style={{ width: 450, height: 380, margin: '0 auto' }}></img>
                <MaskedInput
                  style={{ width: 345, position: 'absolute', top: 270, left: 90 }}
                  id="cpf"
                  name="cpf"
                  variant="outlined"
                  size="small"
                  placeholder="123.456.789-00"
                  error={cpfError}
                  color="secondary"
                  value={this.props.cpf}
                  onChange={this.handleChange}
                  mask="999.999.999-99"
                  maskChar=""
                  label="Insira aqui o CPF do locatário"
                >
                  {(inputProps) => <TextField {...inputProps} />}
                </MaskedInput>
              </Hidden>

              <Hidden smUp>
                <MaskedInput
                  style={{ minWidth: 300 }}
                  fullWidth
                  id="cpf"
                  name="cpf"
                  variant="outlined"
                  size="small"
                  placeholder="123.456.789-00"
                  error={cpfError}
                  color="secondary"
                  value={this.props.cpf}
                  onChange={this.handleChange}
                  mask="999.999.999-99"
                  maskChar=""
                  label="Insira aqui o CPF do locatário"
                >
                  {(inputProps) => <TextField {...inputProps} />}
                </MaskedInput>
              </Hidden>
            </Box>
          </Grid>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Box style={{ display: 'flex', position: 'relative' }}>
              {loading && (
                <CircularProgress
                  size={24}
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: -12,
                    marginLeft: -12,
                  }}
                />
              )}
              <Button disabled={loading} color="primary" variant="contained" size="large" onClick={this.analyse}>
                Analisar
              </Button>
            </Box>
          </Grid>
        </Grid>

        <Dialog disableScrollLock open={this.state.openModalWasAnalysed} onClose={this.closeModalWasAnalysed}>
          <DialogTitle
            disableTypography
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6">Análise Ucred</Typography>
            <IconButton onClick={this.closeModalWasAnalysed} size="small" style={{ marginLeft: 'auto' }}>
              <Close></Close>
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <DialogContentText>
              Olá <font style={{ color: '#009fff' }}>{authUser ? authUser.name : ''}</font>, análise do locatário <font style={{ color: '#009fff' }}>{customName}</font> foi realizada com sucesso pela
              plataforma da Ucred. Basta clicar no botão abaixo para prosseguir com sua locação.
            </DialogContentText>
            <Lottie
              style={{ opacity: 1 }}
              width="200px"
              speed={1}
              segments={[220, 400]}
              options={{
                animationData: ApprovedReproved,
                autoplay: true,
                loop: false,
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              disabled={this.state.loading}
              color="primary"
              onClick={() => {
                this.setState({ openModalWasAnalysed: false })
              }}
            >
              Cancelar
            </Button>

            <Box style={{ display: 'flex', position: 'relative' }}>
              {this.state.loading && (
                <CircularProgress
                  size={24}
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: -12,
                    marginLeft: -12,
                  }}
                />
              )}
              <Button disabled={this.state.loading} variant="contained" color="primary" onClick={this.avancar}>
                Avançar
              </Button>
            </Box>
          </DialogActions>
        </Dialog>

        <Dialog disableScrollLock open={error} onClose={() => this.setState({ error: false })}>
          <DialogTitle
            disableTypography
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6">Erro na análise do motor</Typography>
            <IconButton onClick={() => this.setState({ error: false })} size="small" style={{ marginLeft: 'auto' }}>
              <Close></Close>
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom paragraph>
              Olá{authUser ? ` ${authUser.name.split(' ')[0]}` : ''}!
            </Typography>
            <Typography paragraph variant="body1" color="textSecondary">
              Infelizmente ocorreu um erro na análise do locatário de CPF
              <Typography component="span" color="textPrimary">
                {this.props.cpf + ','}
              </Typography>
              portanto, não podemos seguir com a transação no momento.
            </Typography>
            <Typography paragraph color="error">
              Natureza do erro: {errorMessage}
            </Typography>
            <Lottie
              style={{ opacity: 1 }}
              width="200px"
              speed={1}
              segments={[418, 826]}
              options={{
                animationData: ApprovedReproved,
                autoplay: true,
                loop: false,
              }}
            />
            <Typography paragraph variant="body1" color="textSecondary">
              Caso tenha dúvidas entre em contato com nosso suporte:
            </Typography>
            <Box
              m={1}
              onClick={() =>
                window.open(
                  `https://api.whatsapp.com/send?phone=5532984809602&text=Olá, tentei realizar a transação do usuário com o cpf ${this.props.cpf}, mas obtive o seguinte erro: ${errorMessage}`
                )
              }
            >
              <Fab color="primary" size="small">
                <WhatsApp />
              </Fab>
              <Typography component="span">&nbsp;&nbsp;&nbsp;(32) 98480-9602</Typography>
            </Box>
            <Box m={1} onClick={() => window.open('mailto:contato@ucred.io')}>
              <Fab color="primary" size="small">
                <Email />
              </Fab>
              <Typography component="span">&nbsp;&nbsp;&nbsp;contato@ucred.io</Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={() => {
                this.setState({ error: false })
              }}
            >
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

function mapStateToProps({ creditAnalysis, authReducer }) {
  return {
    ...creditAnalysis,
    ...authReducer,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fillField: (field, value) => dispatch(fillField(field, value)),
    refresh: () => dispatch(refresh()),
  }
}

export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(Step1))
