import axios from 'axios'

const token = localStorage.getItem('jwt')

let URL = 'http://localhost:3334/'

if (process.env.REACT_APP_STAGE === 'development') URL = 'http://localhost:3334/'
if (process.env.REACT_APP_STAGE === 'staging') URL = 'https://app.hml.ucred.io/api/v3/'
if (process.env.REACT_APP_STAGE === 'production') URL = 'https://app.ucred.io/api/v3/'

const instance = axios.create({
  baseURL: URL,
  headers: { authorization: `Bearer ${token}` },
})

export default instance
