import { Box, Button, CircularProgress, Container, Grid, IconButton, TextField, Typography } from '@material-ui/core'
import React from 'react'
import ApiInstance from '../../services/ApiInstance'
import { withSnackbar } from 'notistack'
import { Visibility, VisibilityOff } from '@material-ui/icons'

class PasswordRecover extends React.Component {
  constructor() {
    super()
    this.state = { loading: true, password: '', confirm_password: '', passwordHidden: true }
  }

  async componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search)
    const hash = urlParams.get('hash')
    await ApiInstance.get(`recover-password/${hash}`)
      .then(() => {
        this.setState({ loading: false })
      })
      .catch((error) => {
        this.setState({ loading: false })
        const snackbarKey = this.props.enqueueSnackbar(error.message, {
          variant: 'error',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        })
      })
  }
  render() {
    const { loading, password, confirm_password } = this.state

    const handleSubmit = async () => {
      this.setState({ loading: true })
      const urlParams = new URLSearchParams(window.location.search)
      const hash = urlParams.get('hash')

      if (password !== confirm_password) {
        this.setState({ loading: false })
        const snackbarKey = this.props.enqueueSnackbar('A senha e a confirmação devem ser iguais.', {
          variant: 'error',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        })
        return this.setState({ error: true })
      }

      if (password.length < 6) {
        this.setState({ loading: false })
        const snackbarKey = this.props.enqueueSnackbar('A senha deve conter no mínimo 6 dígitos.', {
          variant: 'error',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        })
        return
      }

      try {
        await ApiInstance.put(`recover-password/${hash}`, {
          password,
        })

        const snackbarKey = this.props.enqueueSnackbar('Senha atualizada com sucesso, você será redirecionado.', {
          variant: 'success',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        })

        setTimeout(() => {
          window.location.href = 'https://app.ucred.io/'
        }, 3000)
      } catch (error) {
        const snackbarKey = this.props.enqueueSnackbar(error.message, {
          variant: 'error',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        })
        this.setState({ loading: false })
      }
    }

    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          backgroundImage: 'url(https://res.cloudinary.com/ucred/image/upload/v1635505519/wooden-house-miniature.jpg)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <Container
          maxWidth="xs"
          style={{
            height: '100vh',
          }}
        >
          <Grid spacing={3} container style={{ height: '100%' }} justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              <Box
                p={1}
                style={{ background: '#fff', borderRadius: 8, boxShadow: '0px 0px 10px 0px rgb(0, 0, 0, .05)' }}
              >
                <Box m={2}>
                  <Typography variant="h6" gutterBottom>
                    Redefinir senha
                  </Typography>
                </Box>
                <Box m={2}>
                  <TextField
                    value={password}
                    onChange={(event) => {
                      this.setState({ password: event.target.value })
                    }}
                    variant="outlined"
                    type={this.state.passwordHidden ? 'password' : 'text'}
                    label="Nova senha"
                    fullWidth
                    InputProps={{
                      endAdornment: this.state.passwordHidden ? (
                        <IconButton onClick={() => this.setState({ passwordHidden: !this.state.passwordHidden })}>
                          <VisibilityOff />
                        </IconButton>
                      ) : (
                        <IconButton onClick={() => this.setState({ passwordHidden: !this.state.passwordHidden })}>
                          <Visibility />
                        </IconButton>
                      ),
                    }}
                  ></TextField>
                </Box>
                <Box m={2}>
                  <TextField
                    value={confirm_password}
                    onChange={(event) => {
                      this.setState({ confirm_password: event.target.value })
                    }}
                    variant="outlined"
                    type={this.state.passwordHidden ? 'password' : 'text'}
                    label="Confirme sua nova senha"
                    fullWidth
                    InputProps={{
                      endAdornment: this.state.passwordHidden ? (
                        <IconButton onClick={() => this.setState({ passwordHidden: !this.state.passwordHidden })}>
                          <VisibilityOff />
                        </IconButton>
                      ) : (
                        <IconButton onClick={() => this.setState({ passwordHidden: !this.state.passwordHidden })}>
                          <Visibility />
                        </IconButton>
                      ),
                    }}
                  ></TextField>
                </Box>
                <Box m={2} style={{ display: 'flex', position: 'relative' }}>
                  {loading && (
                    <CircularProgress
                      size={24}
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: -12,
                        marginLeft: -12,
                      }}
                    />
                  )}
                  <Button
                    disabled={loading}
                    onClick={handleSubmit}
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                  >
                    Atualizar senha
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </div>
    )
  }
}
export default withSnackbar(PasswordRecover)
