import React, { forwardRef } from 'react'
import NumberFormat from 'react-number-format'
import { Input as ChakraInput, FormControl, FormLabel, FormErrorMessage, InputGroup } from '@chakra-ui/react'

const BaseCurrencyInput = (
  { leftElement, rightElement, name, value, label, error, color = 'gray.700', prefix = 'R$ ', ...rest },
  ref
) => {
  return (
    <>
      <FormControl isInvalid={!!error}>
        {!!label && (
          <FormLabel
            color="gray.500"
            fontSize="xs"
            fontWeight="semibold"
            style={{ textTransform: 'uppercase' }}
            htmlFor={name}
          >
            {label}
          </FormLabel>
          
        )}
        <InputGroup>
          {leftElement}
          <ChakraInput
            as={NumberFormat}
            value={value}
            name={name}
            color={color}
            prefix={prefix}
            decimalScale={2}
            decimalSeparator=","
            thousandSeparator="."
            allowedDecimalSeparators={[',']}
            allowLeadingZeros={false}
            getInputRef={ref}
            {...rest}
          />
          {rightElement}
        </InputGroup>
        {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
      </FormControl>
    </>
  )
}

export const CurrencyInput = forwardRef(BaseCurrencyInput)

export default CurrencyInput