import moment from 'moment'
import { withSnackbar } from 'notistack'
import React from 'react'
import Cards from 'react-credit-cards'
import Dropzone from 'react-dropzone'
import { connect } from 'react-redux'
import Modal from 'react-responsive-modal'
import ApiInstance from '../../services/ApiInstance'
import Button from '../../components/button'
import Checkbox from '../../components/checkbox'
import Input from '../../components/input'
import ProductCard from '../../components/productCardOld'
import { fillField, refresh } from '../../redux/actions/creditAnalysisActions'
import { consultaCep } from '../../util/cep'

class Step2 extends React.Component {
  state = {
    serviceActive: -1,
    focusCard: 'name',
    focusCardCorresp: 'name',
    newContactClient: false,
    newContactCorresp: false,
    hasContract: false,
  }

  handleChangeClient = (event) => {
    let { client, corresponsavel, clientCorresp, fillField, creditCardResp, creditCardCorresp } = this.props
    let { value, id } = event.target
    fillField('client', { ...client, [id]: value })
    if (id === 'name') fillField('creditCardResp', { ...creditCardResp, name: value })
    if (clientCorresp) {
      fillField('corresponsavel', { ...corresponsavel, [id]: value })
      if (id === 'name') fillField('creditCardCorresp', { ...creditCardCorresp, name: value })
    }
  }

  handleChangeCorresponsavel = (event) => {
    let { corresponsavel, creditCardCorresp, fillField } = this.props
    let { value, id } = event.target
    fillField('corresponsavel', { ...corresponsavel, [id]: value })
    if (id === 'name') fillField('creditCardCorresp', { ...creditCardCorresp, name: value })
  }

  newContactClient = () => {
    let { newContactClient } = this.state
    this.setState({ newContactClient: !newContactClient })
  }

  newContactCorresp = () => {
    let { newContactCorresp } = this.state
    this.setState({ newContactCorresp: !newContactCorresp })
  }

  selectedProduct = (indice, value) => {
    const { fillField, services, has_corresponsible, creditCardResp } = this.props
    fillField('selectedService', services[indice])
    fillField('finalValue', value)
    fillField('serviceActive', indice)
    if (!has_corresponsible) {
      fillField('creditCardResp', { ...creditCardResp, value: value })
    }
  }

  checkEmail = () => {
    let { client } = this.props
    let mailCheck = client.email.split('@')
    if (mailCheck.length < 2) {
      const snackbarKey = this.props.enqueueSnackbar('Email inválido.', {
        variant: 'error',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
      setTimeout(() => {
        document.querySelector('#email').focus()
      }, 500)
    }
  }

  checkEmailCorresp = () => {
    let { corresponsavel } = this.props
    let mailCheck = corresponsavel.email.split('@')
    if (mailCheck.length < 2) {
      const snackbarKey = this.props.enqueueSnackbar('Email inválido.', {
        variant: 'error',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
      setTimeout(() => {
        document.querySelectorAll('#email')[1].focus()
      }, 500)
    }
  }
  checkCorresp = (event) => {
    let { checked } = event.target
    let { fillField, client, creditCardCorresp } = this.props
    if (checked) {
      fillField('corresponsavel', { ...client })
      fillField('creditCardCorresp', { ...creditCardCorresp, name: client.name })
      fillField('clientCorresp', true)
    } else {
      fillField('corresponsavel', {
        id_vindi: null,
        id: null,
        name: '',
        cpf: '',
        email: '',
        telephone_1: '',
        telephone_2: '',
        card_maturity: '',
        city: '',
        address: '',
        birthday: null,
        confirm_email: '',
      })
      fillField('creditCardCorresp', {
        ...creditCardCorresp,
        name: '',
      })
      fillField('clientCorresp', false)
    }
  }

  changeParcel = (event) => {
    let { fillField } = this.props
    let { value } = event.target
    fillField('parcel', value)
  }

  changeAdesion = (event) => {
    const { fillField, adhesion } = this.props
    let { value } = event.target
    fillField('adhesion', { ...adhesion, value })
  }

  changeParcelAdhesion = (event) => {
    const { fillField, adhesion } = this.props
    let { value } = event.target
    fillField('adhesion', { ...adhesion, parcel: value })
  }

  changeCep = async (event) => {
    try {
      let { value } = event.target
      let { fillField } = this.props
      let cep = value.split('-').join('').split('_').join('')
      if (cep.length === 8) {
        let viaCep = await consultaCep(cep)
        let dataAddress = {
          city: viaCep.localidade ? viaCep.localidade : '',
          uf: viaCep.uf,
          state: viaCep.uf,
          neighborhood: viaCep.bairro ? viaCep.bairro : '',
          country: 'BR',
          cep: value,
          number: '',
          complement: '',
          street: viaCep.logradouro ? viaCep.logradouro : '',
        }
        fillField('immobile', { ...dataAddress })
      } else {
        fillField('immobile', { cep, street: '', city: '', number: '', neighborhood: '', complement: '' })
      }
    } catch (error) {
      const snackbarKey = this.props.enqueueSnackbar('Nao foi possível localizar o CEP.', {
        variant: 'error',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
    }
  }

  changeAddress = (event) => {
    const { id, value } = event.target
    let { fillField, immobile } = this.props
    fillField('immobile', { ...immobile, [id]: value })
  }

  changeNumber = (event) => {
    const { id, value } = event.target
    let { fillField, immobile } = this.props
    fillField('immobile', { ...immobile, [id]: value })
  }

  changeComplement = (event) => {
    const { id, value } = event.target
    let { fillField, immobile } = this.props
    fillField('immobile', { ...immobile, [id]: value })
  }

  changeCard = (event) => {
    let { value, id } = event.target
    let { creditCardResp, fillField } = this.props
    fillField('creditCardResp', { ...creditCardResp, [id]: value })
  }

  changeCardCorresp = (event) => {
    let { value, id } = event.target
    let { creditCardCorresp, fillField } = this.props
    fillField('creditCardCorresp', { ...creditCardCorresp, [id]: value })
  }

  changeValueCard = (event) => {
    let { finalValue, fillField, creditCardResp, creditCardCorresp } = this.props
    let { value } = event.target
    let restValue = 0
    finalValue = Number(finalValue)
    value = value.split('.').join('').replace(',', '.')
    if (Number(value) > finalValue) value = finalValue
    restValue = Number(finalValue) - Number(value)

    fillField('creditCardResp', { ...creditCardResp, value })
    fillField('creditCardCorresp', { ...creditCardCorresp, value: restValue.toFixed(2) })
  }

  checkMinValue = () => {
    let { finalValue, fillField, creditCardResp, creditCardCorresp } = this.props
    let value = creditCardResp.value
    let restValue = 0
    let valorMin = finalValue * 0.51
    let maxValue = finalValue - 30

    if (value > maxValue) {
      value = maxValue.toFixed(2)
      const snackbarKey = this.props.enqueueSnackbar('O valor mínimo do corresponsavel deve ser de R$ 30,00.', {
        variant: 'info',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
    }

    if (value < valorMin) {
      value = Number(valorMin).toFixed(2)
      const snackbarKey = this.props.enqueueSnackbar('O valor mínimo a ser passado no cartão do locatário deve ser 51% do valor total.', {
        variant: 'error',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
    }
    restValue = Number(finalValue) - Number(value)
    fillField('creditCardResp', { ...creditCardResp, value })
    fillField('creditCardCorresp', { ...creditCardCorresp, value: restValue.toFixed(2) })
  }

  dropFile = (file) => {
    const { files } = this.props
    const { fillField } = this.props
    file = this.convertToBase64(file, (base64) => {
      let newFiles = [
        ...files,
        {
          id: Number(files.length) + 1,
          base64,
        },
      ]
      fillField('files', newFiles)
    })

    return file
  }

  dropContract = (file) => {
    const { fillField } = this.props
    this.convertToBase64(file, (base64) => {
      fillField('contract', base64)
    })
  }

  removeContract = () => {
    const { fillField } = this.props
    fillField('contract', null)
  }

  getIssuerResp = ({ issuer }) => {
    if (issuer !== 'unknown') {
      let { creditCardResp, fillField, confirmarEmail } = this.props
      if (!confirmarEmail) fillField('creditCardResp', { ...creditCardResp, type: issuer })
    }
  }

  getIssuerCorresp = ({ issuer }) => {
    if (issuer !== 'unknown') {
      let { creditCardCorresp, fillField, confirmarEmail } = this.props
      if (!confirmarEmail) fillField('creditCardCorresp', { ...creditCardCorresp, type: issuer })
    }
  }

  convertToBase64 = (file, cb) => {
    //Read File
    var selectedFile = file
    //Check File is not Empty
    if (selectedFile.length > 0) {
      // Select the very first file from list
      var fileToLoad = selectedFile[0]
      // FileReader function for read the file.
      var fileReader = new FileReader()
      var base64
      // Onload of file read the file content
      fileReader.onload = function (fileLoadedEvent) {
        base64 = fileLoadedEvent.target.result
        // Print data in console
        cb(base64)
      }
      // Convert data to base64
      fileReader.readAsDataURL(fileToLoad)
    }
  }

  removeImage = (id) => {
    const { files } = this.props
    const { fillField } = this.props
    const newFiles = files.filter((file) => file.id !== id)
    fillField('files', newFiles)
  }

  next = async () => {
    if (this.props.client.email === this.props.corresponsavel.email && !this.props.clientCorresp) {
      const snackbarKey = this.props.enqueueSnackbar('O email do locatário não pode ser o mesmo do corresponsável.', {
        variant: 'error',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
      return
    }
    try {
      this.props.changeLoading(true)
      await this.confirmData()
    } catch (error) {
      this.props.changeLoading(false)
      const snackbarKey = this.props.enqueueSnackbar(error.message, {
        variant: 'error',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
    }
  }

  back = () => {
    this.props.back()
  }

  sendData = async () => {
    try {
      let { corresponsavel, client, has_corresponsible, creditCardResp, creditCardCorresp, fillField, contract, clientCorresp, confirmarEmail, immobile } = this.props

      let address = {
        street: immobile.street,
        number: immobile.number,
        zipcode: immobile.cep,
        neighborhood: immobile.neighborhood,
        city: immobile.city,
        state: immobile.uf,
        country: immobile.country,
        complement: immobile.complement
      }

      creditCardResp = {
        ...creditCardResp,
        number: '4444444444444448',
        type: 'visa',
        expiry: moment().add(2, 'y').format('MM/YYYY')
      }

      creditCardCorresp = {
        ...creditCardCorresp,
        number: '5555555555555557',
        type: 'mastercard',
        expiry: moment().add(2, 'y').format('MM/YYYY')
      }

      let resp = await ApiInstance.post('InicioTransacao', {
        address,
        client: { ...client, confirm_email: null, card_maturity: creditCardResp.card_maturity },
        corresponsavel: { ...corresponsavel, card_maturity: creditCardCorresp.card_maturity },
        has_corresponsible,
        creditCardResp,
        creditCardCorresp,
        contract,
        clientCorresp,
      })
      fillField('client', {
        ...client,
        id: resp.data.clientMain.id,
        id_vindi: resp.data.clientMain.id_vindi,
        second_id_vindi: resp.data.clientMain.second_id_vindi,
      })
      fillField('corresponsavel', { ...corresponsavel, id: resp.data.corresponsavelMain.id, id_vindi: resp.data.corresponsavelMain.id_vindi })
      this.props.changeLoading(false)
      this.props.nextStep()
    } catch (error) {
      this.props.changeLoading(false)
      const snackbarKey = this.props.enqueueSnackbar(error.message, {
        variant: 'error',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
    }
  }

  confirmData = () => {
    try {
      let { corresponsavel, client, has_corresponsible, creditCardResp, creditCardCorresp, immobile, parcel, clientCorresp, serviceActive, confirmarEmail } = this.props

      let address = `${immobile.street}, ${immobile.number}${immobile.complement ? ', ' : ''}${immobile.complement || ''} - ${immobile.neighborhood}`

      immobile.address = address

      if (!immobile.number) {
        throw new Error('ERRO_IMOVEL')
      }

      if (!parcel) throw new Error('ERRO_PARCELA')
      if (Number(serviceActive) < 0) throw new Error('ERRO_SERVICO')
      if (!client.name || !client.birthday || !client.telephone_1 || !client.email || !client.confirm_email) {
        throw new Error('ERRO_CLIENTE')
      }

      if (client.name.split(' ').length < 2) throw new Error('CLIENTE_SOBRENOME')
      if (client.email !== client.confirm_email) throw new Error('CLIENTE_CONFIRMAR_EMAIL')
      if (!immobile.street || !immobile.city || !immobile.cep || !immobile.number || !immobile.neighborhood) throw new Error('ERRO_IMOVEL')

      let validadeMinima = moment().add('months', 13)
      let dataVencimentoCartao = creditCardResp.expiry.split('/')
      dataVencimentoCartao = moment(`${dataVencimentoCartao[1]}-${dataVencimentoCartao[0]}-28`)

      let nascimento = client.birthday.split('/')
      let years = moment().diff(`${nascimento[2]}-${nascimento[1]}-${nascimento[0]}`, 'years')
      if (years < 18) throw new Error('RESPONSAVEL_MENOR')

      if (has_corresponsible) {
        if (!clientCorresp) {
          if (!corresponsavel.name || !corresponsavel.cpf || !corresponsavel.birthday || !corresponsavel.telephone_1 || !corresponsavel.email || !corresponsavel.confirm_email) {
            throw new Error('ERRO_CORRESPONSAVEL')
          }
          if (corresponsavel.email !== corresponsavel.confirm_email) throw new Error('CORRESPONSAVEL_CONFIRMAR_EMAIL')
          if (corresponsavel.name.split(' ').length < 2) throw new Error('CORRESPONSAVEL_SOBRENOME')
          nascimento = corresponsavel.birthday.split('/')
          years = moment().diff(`${nascimento[2]}-${nascimento[1]}-${nascimento[0]}`, 'years')
          if (years < 18) throw new Error('CORRESPONSAVEL_MENOR')
        }
      }

      this.sendData()
    } catch (error) {
      if (error.message === 'ERRO_SERVICO') throw new Error('Escolha um serviço antes de prosseguir')
      if (error.message === 'ERRO_PARCELA') throw new Error('Escolha uma parcela antes de prosseguir')
      if (error.message === 'ERRO_PARCELA_ADESAO') throw new Error('Escolha um parcelamento para a adesão')
      if (error.message === 'ERRO_CLIENTE') throw new Error('Preencha todos os dados do cliente antes de prosseguir')
      if (error.message === 'CLIENTE_CONFIRMAR_EMAIL') throw new Error('Confirme o email antes de prosseguir')
      if (error.message === 'ERRO_IMOVEL') throw new Error('Preencha todos os dados do imóvel antes de prosseguir')
      if (error.message === 'CARTAO_RESPONSAVEL') throw new Error('Preencha todos os dados do cartão do responsavel')
      if (error.message === 'ERRO_CORRESPONSAVEL') throw new Error('Preencha todos os dados do corresponsável antes de prosseguir')
      if (error.message === 'CORRESPONSAVEL_CONFIRMAR_EMAIL') throw new Error('Confirme o email do corresponsável')
      if (error.message === 'CARTAO_CORRESPONSAVEL') throw new Error('Preencha todos os dados do cartão do corresponsável')
      if (error.message === 'CLIENTE_SOBRENOME') throw new Error('Digite o sobrenome do responsável')
      if (error.message === 'CORRESPONSAVEL_SOBRENOME') throw new Error('Digite o sobrenome do corresponsável')
      if (error.message === 'VALIDADE_ERRO') throw new Error('O cartão deve possuir no mínimo 13 meses de validade')
      if (error.message === 'CARTAO_IGUAL') throw new Error('Os cartões não podem ser iguais')
      if (error.message === 'VENCIMENTO_RESPONSAVEL') throw new Error('Data de validade do primeiro cartão inválida')
      if (error.message === 'VENCIMENTO_CORRESPONSAVEL') throw new Error('Data de validade do segundo cartão inválida')
      if (error.message === 'NUMERO_VENCIMENTO_RESPONSAVEL') throw new Error('Dia do vencimento da fatura não pode ser maior que 30')
      if (error.message === 'NUMERO_VENCIMENTO_CORRESPONSAVEL') throw new Error('Dia do vencimento da fatura não pode ser maior que 30')
      if (error.message === 'RESPONSAVEL_MENOR') throw new Error('O locatário deve possuir no mínimo 18 anos')
      if (error.message === 'CORRESPONSAVEL_MENOR') throw new Error('O corresponsável deve possuir no mínimo 18 anos')
    }
  }

  ModalContract = (status) => {
    this.setState({ hasContract: status })
  }

  selecionaServicoDefault() {
    let { services, transactionValue } = this.props
    let valor = this.calcValue(services[0], transactionValue)
    this.selectedProduct(0, valor)
  }

  calcValue = (service, valorTransacao) => {
    let transactionValue = valorTransacao.split('.').join('').replace(',', '.')
    const TAXA_FINAL = Number(service.administration_fee) + Number(service.over_value)
    // Encontra o valor da mensalidade
    let value = Number(transactionValue) * (TAXA_FINAL / 100)
    // Multiplica pelo tempo de contrato
    value = value * Number(service.contract_time)
    // Adiciona taxa de adesao
    value = value + Number(service.adhesion_fee)
    return value.toFixed(2)
  }
  render() {
    const {
      has_corresponsible,
      services,
      transactionValue,
      selectedService,
      finalValue,
      adhesion,
      immobile,
      creditCardResp,
      creditCardCorresp,
      clientCorresp,
      contract,
      fluxo,
      parcel,
      serviceActive,
      confirmarEmail,
      authUser,
    } = this.props
    const { newContactClient, newContactCorresp } = this.state
    if (serviceActive == -1 && services.length === 1) this.selecionaServicoDefault()
    return (
      <React.Fragment>
        <div className="step__step2">
          <div className="dadosServico">
            <fieldset style={{ padding: '0' }}>
              <legend>
                <i className="lni-archive"></i> Serviços{' '}
              </legend>
              <div className="containerServices">
                <ProductCard services={services} transactionValue={transactionValue} selectedProduct={this.selectedProduct} indice={serviceActive} plan_id={authUser.plan_id} />
              </div>
            </fieldset>
          </div>
          <div className="dadosVenda">
            <fieldset>
              <legend>
                <i className="lni-money-protection"></i> Dados da transação{' '}
              </legend>
              <div className="line line-service">
                <span>Serviço Contratado</span>
                <span>
                  <b> {selectedService.name} </b>
                </span>
              </div>
              <div className="line line-adhesion">
                <div className="adhesion-obs">
                  <div>
                    {!confirmarEmail && (
                      <div className="column">
                        <span>Parcelamento: </span>
                        <select
                          style={{
                            backgroundColor: '#F8F9F9',
                            color: '#009fff',
                            height: 40,
                            borderRadius: 20,
                            borderColor: '#009fff',
                            borderWidth: 'medium',
                            fontSize: 17,
                            fontFamily: 'Arial',
                            fontWeight: 'bold',
                          }}
                          onChange={this.changeParcel}
                          value={parcel ? parcel : null}
                        >
                          <option></option>
                          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((parcela) => (
                            <option value={parcela} key={parcela}>
                              {parcela} x {Number(finalValue / parcela).toFixed(2)}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                    <div className="column">
                      <span>Taxa de adesão: </span>
                      <select
                        style={{
                          backgroundColor: '#F8F9F9',
                          color: '#009fff',
                          height: 40,
                          borderRadius: 20,
                          borderColor: '#009fff',
                          borderWidth: 'medium',
                          fontSize: 17,
                          fontFamily: 'Arial',
                          fontWeight: 'bold',
                        }}
                        onChange={this.changeAdesion}
                        value={adhesion.value ? adhesion.value : null}
                      >
                        {[0, 30, 60, 90, 120].map((adesao) => (
                          <option value={adesao} key={adesao}>
                            {' '}
                            R$ {`${adesao}.00`}{' '}
                          </option>
                        ))}
                      </select>
                    </div>
                    {adhesion.value && !confirmarEmail ? (
                      <div className="column">
                        <span>Parcelamento da taxa de adesão: </span>
                        <select
                          style={{
                            backgroundColor: '#F8F9F9',
                            color: '#009fff',
                            height: 40,
                            borderRadius: 20,
                            borderColor: '#009fff',
                            borderWidth: 'medium',
                            fontSize: 17,
                            fontFamily: 'Arial',
                            fontWeight: 'bold',
                          }}
                          onChange={this.changeParcelAdhesion}
                          value={adhesion.parcel ? adhesion.parcel : null}
                        >
                          <option></option>
                          {[1, 2, 3].map((parcela) => (
                            <option value={parcela} key={parcela}>
                              {parcela} x {(adhesion.value / parcela).toFixed(2)}
                            </option>
                          ))}
                        </select>
                      </div>
                    ) : null}

                    <div className="column">
                      <span>Valor total:</span>
                      <b>R$ {(Number(finalValue) + Number(adhesion.value)).toFixed(2)}</b>
                    </div>
                  </div>
                  {confirmarEmail && <h1 className="observation">Observação: O locatário irá definir o método de parcelamento desejado ao concluir o pagamento.</h1>}
                </div>
              </div>
            </fieldset>
          </div>
          <div className="dadosContratante">
            <fieldset>
              <legend>
                <i className="lni-user"></i>Dados do locatário{' '}
              </legend>
              <div className="line">
                <Input type="text" placeholder="Nome" labelDescription="Nome" id="name" value={this.props.client.name} onChange={this.handleChangeClient} />
                <Input type="text" mask="111.111.111-11" placeholder="CPF" labelDescription="CPF" id="cpf" disabled onChange={() => { }} iconType="number" value={this.props.client.cpf} />
                <Input
                  type="text"
                  mask="11/11/1111"
                  placeholder="Data de nascimento"
                  id="birthday"
                  value={this.props.client.birthday}
                  labelDescription="Data de nascimento"
                  onChange={this.handleChangeClient}
                  iconType="number"
                />
              </div>
              <div className="line">
                <Input
                  type="text"
                  placeholder="E-mail"
                  id="email"
                  labelDescription="E-mail"
                  onChange={this.handleChangeClient}
                  iconType="email"
                  value={this.props.client.email}
                  onBlur={this.checkEmail}
                />
                <Input
                  type="text"
                  placeholder="Confirmar E-mail"
                  id="confirm_email"
                  labelDescription="Confirmar E-mail"
                  onChange={this.handleChangeClient}
                  iconType="email"
                  value={this.props.client.confirm_email}
                />
              </div>
              <div className="line">
                <Input
                  type="text"
                  mask="(11) 11111-1111"
                  placeholder="Telefone celular"
                  id="telephone_1"
                  labelDescription="Telefone celular"
                  onChange={this.handleChangeClient}
                  iconType="number"
                  value={this.props.client.telephone_1}
                />
                {!newContactClient && (
                  <div className="add_contact" onClick={this.newContactClient}>
                    <span> + Adicionar outro contato </span>
                  </div>
                )}
                {newContactClient && (
                  <React.Fragment>
                    <Input
                      mask="(11) 11111-1111"
                      type="text"
                      placeholder="Telefone"
                      id="telephone_2"
                      labelDescription="Telefone"
                      onChange={this.handleChangeClient}
                      iconType="number"
                      value={this.props.client.telephone_2}
                    />
                    <div className="remove_contact" onClick={this.newContactClient}>
                      <span className="remove"> - Remover contato</span>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </fieldset>
            {has_corresponsible && (
              <fieldset>
                <legend>
                  <i className="lni-users"></i> Dados do corresponsável{' '}
                </legend>
                <div className="line line-contratante">
                  <Checkbox onChange={this.checkCorresp} /> <label className="label-check"> Ambos os cartões serão do locatário </label>
                </div>
                <div className="line">
                  <Input type="text" placeholder="Nome" disabled={clientCorresp} labelDescription="Nome" id="name" value={this.props.corresponsavel.name} onChange={this.handleChangeCorresponsavel} />
                  <Input
                    type="text"
                    mask="111.111.111-11"
                    placeholder="CPF"
                    disabled={clientCorresp}
                    labelDescription="CPF"
                    onChange={this.handleChangeCorresponsavel}
                    iconType="number"
                    value={this.props.corresponsavel.cpf}
                    id="cpf"
                  />
                  <Input
                    type="text"
                    mask="11/11/1111"
                    placeholder="Data de nascimento"
                    disabled={clientCorresp}
                    labelDescription="Data de nascimento"
                    value={this.props.corresponsavel.birthday}
                    onChange={this.handleChangeCorresponsavel}
                    iconType="number"
                    id="birthday"
                  />
                </div>
                <div className="line">
                  <Input
                    type="text"
                    placeholder="E-mail"
                    labelDescription="E-mail"
                    disabled={clientCorresp}
                    onChange={this.handleChangeCorresponsavel}
                    iconType="emailr"
                    value={this.props.corresponsavel.email}
                    id="email"
                    onBlur={this.checkEmailCorresp}
                  />
                  <Input
                    type="text"
                    placeholder="Confirmar E-mail"
                    labelDescription="Confirmar E-mail"
                    disabled={clientCorresp}
                    onChange={this.handleChangeCorresponsavel}
                    iconType="email"
                    value={this.props.corresponsavel.confirm_email}
                    id="confirm_email"
                  />
                </div>
                <div className="line">
                  <Input
                    type="text"
                    mask="(11) 11111-1111"
                    disabled={clientCorresp}
                    placeholder="Telefone celular"
                    id="telephone_1"
                    labelDescription="Telefone celular"
                    onChange={this.handleChangeCorresponsavel}
                    iconType="number"
                    value={this.props.corresponsavel.telephone_1}
                  />
                  {!newContactCorresp && (
                    <div className="add_contact" onClick={this.newContactCorresp}>
                      <span> + Adicionar outro contato </span>
                    </div>
                  )}
                  {newContactCorresp && (
                    <React.Fragment>
                      <Input
                        mask="(11) 11111-1111"
                        type="text"
                        disabled={clientCorresp}
                        placeholder="Telefone"
                        id="telephone_2"
                        labelDescription="Telefone"
                        onChange={this.handleChangeCorresponsavel}
                        iconType="number"
                        value={this.props.corresponsavel.telephone_2}
                      />
                      <div className="remove_contact" onClick={this.newContactCorresp}>
                        <span className="remove"> - Remover contato</span>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </fieldset>
            )}
          </div>
          <div className="dadosImovel">
            <fieldset>
              <legend>
                <i className="lni-home"></i> Dados do imóvel que deseja locar
              </legend>
              <div className="line">
                <Input type="text" mask="11111-111" placeholder="CEP" labelDescription="CEP" iconType="number" id="cep" onChange={this.changeCep} value={immobile.cep} />
                <Input value={immobile.street} type="text" placeholder="Endereço completo" labelDescription="Endereço completo" iconType="text" id="street" onChange={this.changeAddress} />
                <Input value={immobile.number} type="text" placeholder="Número" labelDescription="Número" iconType="number" id="number" onChange={this.changeNumber} />
                <Input value={immobile.neighborhood} type="text" placeholder="Bairro" labelDescription="Bairro" iconType="text" id="neighborhood" onChange={this.changeAddress} />
                <Input value={immobile.city} type="text" placeholder="Cidade" labelDescription="Cidade" iconType="number" id="city" onChange={this.changeAddress} />
                <Input value={immobile.complement} type="text" placeholder="Complemento" labelDescription="Complemento" iconType="text" id="complement" onChange={this.changeComplement} />
              </div>
            </fieldset>
          </div>
          {has_corresponsible && (
            <div className="dadosCartaoContratante">
              <fieldset>
                <legend>
                  <i className="lni-credit-cards"></i> Cartão locatário
                </legend>
                <div className="line">
                  <small className="obs-adhesion">* Observação: além do valor selecionado também será passado o valor da taxa de adesão caso haja algum.</small>
                </div>
                <Input
                  type="currency"
                  placeholder="Valor cobrado no cartão do locatário"
                  labelDescription="Valor cobrado neste cartão"
                  id="value"
                  onChange={this.changeValueCard}
                  iconType="money"
                  value={this.props.creditCardResp.value}
                  onBlur={this.checkMinValue}
                />
              </fieldset>
              <fieldset>
                <legend>
                  <i className="lni-credit-cards"></i> Cartão corresponsavel
                </legend>
                <div className="line">
                  <small className="obs-adhesion-fake">escrevendo qualquer coisa aqui pra da tamanho</small>
                </div>
                <Input
                  type="currency"
                  placeholder="Valor cobrado no cartão do corresponsavel"
                  labelDescription="Valor cobrado neste cartão"
                  id="value"
                  onChange={() => { }}
                  disabled
                  value={this.props.creditCardCorresp.value}
                  iconType="money"
                />
              </fieldset>
            </div>
          )}
          {!this.props.loading && (
            <div className="line line-buttons">
              <Button description={'Voltar'} onClick={this.back} />
              {/* <span onClick={() => { this.ModalContract(true) } } className="btn-sendContract">
                            <i className="lni-add-file"></i>
                            Enviar Contrato
                        </span> */}
              <Button description={'Avançar'} onClick={this.next} />
            </div>
          )}
        </div>
        <Modal
          open={this.state.hasContract}
          onClose={() => {
            this.ModalContract(false)
          }}
          classNames={{ modal: 'modalUploadContract' }}
        >
          <div className="modalUploadContract__container">
            <div className="modalUploadContract__container--instructions">
              <span>Faça upload do seu contrato de locação, </span>
              <span> para ser enviado automaticamente para seu cliente</span>
              <span>Envie o arquivo no campo abaixo</span>
              <small>
                Atenção! Só será permitido arquivos com o formato <b>pdf</b>
              </small>
            </div>
          </div>
          <div className="modalUploadContract__dropzone">
            <Dropzone onDrop={this.dropContract} maxSize={150994944}>
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>Arraste ou clique para enviar documentos</p>
                    <small>Apenas arquivos pdfs serão aceitos.</small>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
          <div className="modalUploadContract__contract">
            {contract && (
              <div className="contract">
                <i className="lni-close" onClick={this.removeContract}></i>
                <embed src={contract} />
              </div>
            )}
          </div>
          <div className="modalUploadContract__button">
            <Button
              onClick={() => {
                this.ModalContract(false)
              }}
              description="Confirmar"
            />
          </div>
        </Modal>
      </React.Fragment>
    )
  }
}

function mapStateToProps({ creditAnalysis, authReducer }) {
  return {
    ...creditAnalysis,
    ...authReducer,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fillField: (field, value) => dispatch(fillField(field, value)),
    refresh: () => dispatch(refresh()),
  }
}

export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(Step2))
