import { withSnackbar } from 'notistack'
import React from 'react'
import { connect } from 'react-redux'
import Modal from 'react-responsive-modal'
import Api from '../../services/ApiInstance'
import Button from '../../components/button'
import Input from '../../components/input'
import Loader from '../../components/loader'
import { fillCompany, fillUser } from '../../redux/actions/authUserAction'

export class LoginPageRevendedor extends React.Component {
  state = {
    email: '',
    password: '',
    loading: false,
    type: 'password',
    open: false,
    emailForgot: '',
  }

  handleClick = async () => {
    const { fillUser } = this.props
    const { email, password } = this.state
    try {
      await this.setState({ loading: true })
      const authData = { email, password, type_user: 'reseller' }
      const resp = await Api.post('loginResellerCompany', authData)
      Api.setJwt(resp.data.token.token)
      fillUser({
        ...resp.data.user,
        token: resp.data.token,
        plan_id: 4,
      })
      this.props.history.push('/home-revendedor')
    } catch (error) {
      const snackbarKey = this.props.enqueueSnackbar('Verifique seus dados.', { variant: 'error',
onClick: () => {
              this.props.closeSnackbar(snackbarKey)
            } })
    }
    await this.setState({ loading: false })
  }

  handleChange = (event) => {
    const { value, id } = event.currentTarget
    this.setState({ [id]: value })
  }

  showPassword = () => {
    const { type } = this.state
    let newType = null
    if (type === 'password') newType = 'text'
    if (type === 'text') newType = 'password'
    this.setState({ type: newType })
  }

  openModal = () => {
    this.setState({ open: true })
  }

  closeModal = () => {
    this.setState({ open: false })
  }

  confirmForgot = async () => {
    await this.setState({ loading: true })
    try {
      await Api.post('forgotPassword', { email: this.state.emailForgot })
      const snackbarKey = this.props.enqueueSnackbar('Enviado com sucesso.', { variant: 'success',
onClick: () => {
              this.props.closeSnackbar(snackbarKey)
            } })
      await this.setState({ loading: false, open: false })
    } catch (error) {
      const snackbarKey = this.props.enqueueSnackbar('Falha ao enviar.', { variant: 'error',
onClick: () => {
              this.props.closeSnackbar(snackbarKey)
            } })
      await this.setState({ loading: false })
    }
  }

  componentDidMount() {
    window.localStorage.setItem('hiddenCorrespMessage', false)
    window.localStorage.setItem('hiddenEmailMessage', false)
  }

  render() {
    const { email, password, loading, type } = this.state
    return (
      <div className="loginRevendedor">
        <div className="loginRevendedor__container-image"></div>
        <div className="loginRevendedor__container-form">
          <div className="loginRevendedor__form">
            <div className="loginRevendedor__form--header">
              <img className="loginRevendedor__form-image" src="https://i.ibb.co/1G1R9dv/LOGO-FINAL2.png" alt="logo" />
              <span className="loginRevendedor_reseller">REVENDEDOR</span>
              <p>Olá! faça seu login</p>
            </div>
            <div className="loginRevendedor__form--body">
              <Input
                type="text"
                labelDescription="Seu E-mail"
                placeholder="email"
                id="email"
                value={email}
                onChange={this.handleChange}
                iconType="email"
              />
              <div className="container-password">
                <Input type={type} labelDescription="Sua Senha" placeholder="senha" id="password" value={password} onChange={this.handleChange} />
                <i className="lni-eye" onClick={this.showPassword}></i>
              </div>
              <div className="forgot">
                <span onClick={this.openModal}> Esqueci minha senha </span>
              </div>
              {loading ? <Loader /> : <Button description="Entrar" onClick={this.handleClick} />}
            </div>
          </div>
        </div>
        <Modal open={this.state.open} onClose={this.closeModal}>
          <div className="modal-forgot">
            <span>
              {' '}
              <b> Digite o email para recuperar a senha </b>{' '}
            </span>
            <Input
              type="text"
              labelDescription="Email"
              placeholder="Email"
              value={this.state.emailForgot}
              id="emailForgot"
              onChange={this.handleChange}
            />
            {loading ? <Loader /> : <Button description="Confirmar" onClick={this.confirmForgot} />}
          </div>
        </Modal>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fillUser: (user) => dispatch(fillUser(user)),
    fillCompany: (c) => dispatch(fillCompany(c)),
  }
}

export default withSnackbar(connect(null, mapDispatchToProps)(LoginPageRevendedor))
