import { Box, Breadcrumbs, Container, Grid, Hidden, Tab, Tabs, Typography } from '@material-ui/core'
import { Home } from '@material-ui/icons'
import { withSnackbar } from 'notistack'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Header from '../../layout/Header'
import CoorporativeAccount from './ContaCorporativa'
import PersonalAccount from './ContaPessoal'
import Footer from '../../layout/Footer'

class ContasBancarias extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tab: props.match.state && props.match.params.account === 'conta-corporativa' ? 1 : 0,
    }
  }

  componentDidUpdate() {
    if (this.props.location.state && this.props.location.state.tab !== this.state.tab) {
      this.setState({ tab: this.props.location.state.tab })
    }
  }

  componentDidMount() {
    if (this.props.match.params && this.props.match.params.account === 'conta-corporativa') {
      this.setState({ tab: 1 })
    }
  }

  render() {
    const { tab } = this.state
    return (
      <>
        <Header />
        <Container
          maxWidth="lg"
          style={{
            paddingTop: 32,
            marginTop: 80
          }}
        >
          <Grid spacing={3} container style={{ margin: 0, width: 'initial' }}>
            <Grid item xs={12}>
              <Breadcrumbs className="breadcrumbs">
                <Link to="/" style={{ display: 'flex' }}>
                  <Home
                    style={{
                      marginRight: 8,
                      width: 20,
                      height: 20,
                    }}
                  />
                  Início
                </Link>
                <Typography color="textPrimary">Contas Bancárias</Typography>
                <Typography color="textPrimary">{tab === 0 ? 'Pessoal' : 'Corporativa'}</Typography>
              </Breadcrumbs>
            </Grid>

            <Grid
              container
              style={{
                background: '#fff',
                boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.05)',
                borderRadius: '16px',
                marginTop: '16px',
              }}
            >
              <Hidden smDown>
                <Grid item md={2}>
                  <Tabs style={{ marginTop: 16 }} orientation="vertical" value={tab}>
                    <Tab
                      onClick={() => {
                        this.setState({ tab: 0 })
                        this.props.history.push('/contas/conta-pessoal')
                      }}
                      style={{ width: '100%' }}
                      label="Conta pessoal"
                    />
                    {this.props.authUser && this.props.authUser.plan_id !== 4 && this.props.authUser.isMainUser && (
                      <Tab
                        onClick={() => {
                          this.setState({ tab: 1 })
                          this.props.history.push('/contas/conta-corporativa')
                        }}
                        style={{ width: '100%' }}
                        label="Conta corporativa"
                      />
                    )}
                  </Tabs>
                </Grid>
              </Hidden>
              <Hidden mdUp>
                <Grid item xs={12}>
                  <Tabs
                    variant="fullwidth"
                    value={tab}
                    onChange={(event, value) => {
                      this.setState({ tab: value })
                      this.Header && this.Header.props.refresh()
                    }}
                  >
                    <Tab
                      onClick={() => {
                        this.setState({ tab: 0 })
                        this.props.history.push('/contas/conta-pessoal')
                      }}
                      style={{ width: '100%' }}
                      label="Conta pessoal"
                    />
                    <Tab
                      onClick={() => {
                        this.setState({ tab: 1 })
                        this.props.history.push('/contas/conta-corporativa')
                      }}
                      style={{ width: '100%' }}
                      label="Conta corporativa"
                    />
                  </Tabs>
                </Grid>
              </Hidden>

              {tab === 0 && (
                <>
                  <Grid item md={5}>
                    <Hidden smDown>
                      <Box p={2}>
                        <Typography style={{ marginTop: 4, marginLeft: 8, marginBottom: 16 }} gutterBottom variant="h6">
                          Por que isso é necessário?
                        </Typography>
                        <Typography gutterBottom variant="body2">
                          A conta pessoal irá ser utilizada para depósitos da Ucred quando surgirem promoções e/ou
                          eventos em que seja necessário repassar premiações diretamente aos usuários da plataforma.
                        </Typography>
                        <Typography variant="body2">
                          Lembrando que a Ucred jamais compartilhará seus dados com terceiros.
                        </Typography>
                      </Box>
                    </Hidden>
                  </Grid>

                  <Grid item md={5} xs={12}>
                    <PersonalAccount></PersonalAccount>
                  </Grid>
                </>
              )}

              {tab === 1 && (
                <>
                  <Grid item md={5}>
                    <Hidden smDown>
                      <Box p={2}>
                        <Typography style={{ marginTop: 4, marginLeft: 8, marginBottom: 16 }} gutterBottom variant="h6">
                          Por que isso é necessário?
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          A conta corporativa será utilizada para repasses direcionados à imobiliária.
                        </Typography>

                        <Typography variant="body2">
                          Esta página pode ser visualizada apenas pelo usuário principal da imobiliária.
                        </Typography>
                      </Box>
                    </Hidden>
                  </Grid>

                  <Grid item md={5} xs={12}>
                    <CoorporativeAccount></CoorporativeAccount>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Container>
      </>
    )
  }
}

function mapStateToProps({ authReducer }) {
  return {
    ...authReducer,
  }
}

export default withSnackbar(connect(mapStateToProps)(ContasBancarias))
