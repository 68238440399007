/* eslint-disable camelcase */
import { formatDate } from '../../../util/formatDate'
import { maskMoney } from '../../../util/money'
import { ContractType } from '../types/contract'
import { ResponseClientDataType } from '../types/response/client'
import { ResponseTransactionDetailsType } from '../types/response/transaction-details'
import { SummaryType } from '../types/summary'

type MapperProps = {
  purchaseId: number
  clientDetails: ResponseClientDataType
  transactionDetails: ResponseTransactionDetailsType
}

type ClientApiDataMapperType = {
  contract: ContractType
  summary: SummaryType
}

export function transactionApiDataMapper({
  purchaseId,
  clientDetails,
  transactionDetails,
}: MapperProps): ClientApiDataMapperType {
  const { global, main_purchase, corresponsible_purchase, ucred } = transactionDetails
  const { transaction_value, cover_parcel, cover_value } = ucred
  const { clientData, companyData, products, purchaseFormated } = clientDetails
  const { service_name, dt_inicio, dt_fim, contract_status, document_key, empresa, status } =
    purchaseFormated.filter(purchase => purchase.id === purchaseId)[0]

  const productPurchase = products.filter(product => product.purchase_id === purchaseId)
  const hasCorresponsible = !!corresponsible_purchase.name

  const contract_signed = contract_status === 'signed'
  const contract = {
    purchaseId,
    companyPlanId: companyData.plan_id,
    globalStatus: contract_status,
    startDate: dt_inicio,
    endDate: dt_fim,
    startDateFormatted: contract_signed ? formatDate(dt_inicio) : '-',
    endDateFormatted: contract_signed ? formatDate(dt_fim) : '-',
    documentKey: document_key,
    transactionValue: maskMoney(transaction_value),
    coverParcel: cover_parcel,
    coverValue: maskMoney(cover_value),
    serviceName: service_name,
  }

  const renter_commission = (main_purchase.comission_main_purchase || 0) * main_purchase.parcel
  const coresponsible_commission =
    (corresponsible_purchase.comission_corresponsible_purchase || 0) *
    (corresponsible_purchase.parcel || 0)
  const total_commission = renter_commission + coresponsible_commission

  const summary = {
    purchase: {
      id: purchaseId,
      totalValueLocCorresp: global.endorsed_value,
      status: status,
      company: empresa,
      clientId: clientData.id,
    },
    status: {
      transaction: status,
      adhesion: productPurchase[0] && productPurchase[0].status,
    },
    transaction: {
      value: maskMoney(global.endorsed_value),
      adhesion: maskMoney(global.adhesion_value),
      total: maskMoney(global.endorsed_value + global.adhesion_value),
    },
    commission: {
      value: total_commission,
      valueFormatted: maskMoney(total_commission),
      adhesion: global.adhesion_with_discount,
      adhesionFormatted: maskMoney(global.adhesion_with_discount),
      total: maskMoney(global.comission + global.adhesion_with_discount),
      details: {
        commissionAmount: maskMoney(global.comission),
        renter: {
          parcelNumber: main_purchase.parcel,
          parcelValue: maskMoney(main_purchase.comission_main_purchase),
          total: maskMoney(main_purchase.parcel * main_purchase.comission_main_purchase),
        },
        corresponsible: hasCorresponsible && {
          parcelNumber: corresponsible_purchase.parcel,
          parcelValue: maskMoney(corresponsible_purchase.comission_corresponsible_purchase),
          total: maskMoney(
            corresponsible_purchase.parcel *
              corresponsible_purchase.comission_corresponsible_purchase,
          ),
        },
      },
    },
  }
  return { summary, contract }
}
