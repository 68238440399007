import { FILL_FIELD, REFRESH } from '../actions/creditAnalysisActions'
import { CLEAR_REDUCERS } from '../actions/authUserAction'

const INITIAL_STATE = {
    adhesion: {
        value: 0,
        parcel: 0
    },
    finalValue: '',
    cpf: '',
    cardLimit: '',
    transactionValue: '',
    familyIncome: '',
    services: [],
    reload: false,
    fluxo: 1,
    serviceActive: -1,
    client:{
        id_vindi: null,
        id: null,
        name: '',
        cpf: '',
        email: '',
        telephone_1: '',
        telephone_2: '',
        card_maturity: '',
        city: '',
        address: '',
        birthday: null,
        confirm_email: ''
    },
    corresponsavel: {
        id_vindi: null,
        id: null,
        name: null,
        cpf: null,
        email: null,
        telephone_1: null,
        telephone_2: null,
        card_maturity: null,
        city: null,
        address: null,
        birthday: null,
        confirm_email: null
    },
    clientCorresp: false,
    selectedService: {
        parcel: 12, 
        value : 0
    },
    creditCardResp: {
        number: '',
        name: '',
        expiry: '',
        cvv: '',
        focused: '',
        date: '',
        value: 0
    },
    creditCardCorresp: {
        number: '',
        name: '',
        expiry: '',
        cvv: '',
        focused: '',
        date: ''
    },
    immobile: {
        address: '',
        cep: '',
        city: ''
    },
    files:[],
    has_corresponsible: false,
    step: 1,
    parcel: 12,
    type_imobile_check: null,
    oldCpf: null
}

function creditAnalysis(state = INITIAL_STATE, action) {
    switch(action.type) {
        case FILL_FIELD: {
            return {
                ...state,
                [action.field]: action.value
            }
        }
        case CLEAR_REDUCERS: {
            return {...INITIAL_STATE}
        }
        case REFRESH: {
            return {...INITIAL_STATE, reload: true}
        }
        default:
            return state
    }
}

export default creditAnalysis