import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useToast,
  Text,
  Stack,
  Box,
} from '@chakra-ui/react'

import { DropzoneArea } from '../DropzoneArea'
import { convertToBase64 } from '../../../../util/convertToBase64'
import ApiInstance from '../../../../services/ApiInstance'
import { cancelInvoiceSchema } from '../../schemas/cancelInvoiceSchema'

import { Textarea } from '../Form/Textarea'

interface CancelInvoiceProps {
  invoiceId: number
  isOpen: boolean
  handleCloseModal: () => void
  fetchData: () => void
}

export function CancelInvoiceModal({
  invoiceId,
  isOpen,
  handleCloseModal,
  fetchData,
}: CancelInvoiceProps) {
  const [files64, setFiles64] = useState<any[]>([])
  const toast = useToast()

  const { register, handleSubmit, formState, reset } = useForm({
    resolver: yupResolver(cancelInvoiceSchema),
  })
  const { errors, isSubmitting } = formState

  async function onDropzoneChange(files: any[]) {
    const filesArr: any[] = []
    files.map(files => {
      convertToBase64([files], (base64: any) => {
        filesArr.push({ name: files.name, base: base64 })
      })
    })
    setFiles64(filesArr)
  }

  async function handleCancelInvoiceSubmit(values: { description: string }) {
    const { description } = values

    if (!files64.length)
      return toast({
        title: 'Insira ao menos um documento.',
        status: 'error',
        duration: 2000,
        isClosable: true,
      })
    try {
      const response = await ApiInstance.put(`/invoice/${invoiceId}`, {
        status: 5,
        description,
        files: files64,
      })
      toast({
        title: response.message,
        status: 'success',
        duration: 2000,
        isClosable: true,
      })
      fetchData()
      reset()
      handleCloseModal()
    } catch {
      toast({
        title: 'Houve um erro inesperado, entre em contato com nosso suporte.',
        status: 'error',
        duration: 2000,
        isClosable: true,
      })
    }
  }

  return (
    <Modal
      size="2xl"
      isCentered
      isOpen={isOpen}
      onClose={handleCloseModal}
      scrollBehavior="inside"
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmit(handleCancelInvoiceSubmit)}>
        <ModalHeader fontWeight="normal" color="gray.800">
          Deseja cancelar este sinistro?
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Stack spacing={6}>
            <Box>
              <Text fontSize="md" color="gray.500">
                Atenção, você ainda poderá visualizar os sinistros cancelados, mas a ação de
                cancelamento é<b style={{ color: '#E53E3E' }}> irreversível</b>
              </Text>
              <Text mt={8} fontSize="md" color="gray.500">
                Insira o motivo do cancelamento e um <b>comprovante de quitação dos débitos</b>{' '}
                antes de continuar.
              </Text>
            </Box>
            <DropzoneArea
              dropzoneText="Arraste e solte arquivos aqui ou clique para fazer upload. São aceitos aquivos .jpeg, .png, .bmp e .pdf"
              onChange={onDropzoneChange}
            />
            <Textarea
              label="Motivo do cancelamento"
              autoFocus
              rows={4}
              multiline
              resize="none"
              placeholder="Informe o motivo do cancelamento..."
              error={errors.description}
              {...register('description')}
            />
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button color="gray.600" mr={3} disabled={isSubmitting} onClick={handleCloseModal}>
            Voltar
          </Button>
          <Button isLoading={isSubmitting} colorScheme="blue" type="submit">
            Confirmar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
