import { extendTheme } from '@chakra-ui/react'

export const chakraTheme = extendTheme({
  colors: {
    background: '#fdfdff',
    container: '#ffffff',
    primary: '#009fff',
    success: '#27e3b1',
    error: '#ff6b6b',
    info: '#279be3',
    alert: '#f5d742',
    critical: '#404040',
  },
  fonts: {
    heading: 'Roboto',
    body: 'Roboto',
  },
})
