import { Container, Grid } from '@material-ui/core'
import { withSnackbar } from 'notistack'
import React, { Component } from 'react'
import ApiInstance from '../../services/ApiInstance'
import Button from '../../components/button'
import Input from '../../components/input'
import Header from '../../layout/Header'
import { removeMask } from '../../util/money'

class ServicosForm extends Component {
  state = {
    editing: false,
    loading: false,
    id: undefined,
    name: '',
    warranty_card: '',
    family_income: '',
    adhesion_fee: '',
    administration_fee: '',
    contract_time: '',
    // panic_value: '',
    level_credibility: 1,
    parcel: 1,
    recurrency: 0,
  }
  async componentDidMount() {
    const { id } = this.props.match.params
    if (id !== 'criar') {
      // load data for editing, set into state
      let { data: service } = await ApiInstance.get(`servico/${id}`)

      this.setState({
        editing: true,
        level_credibility: service.level_credibility,
        parcel: service.parcel,
        recurrency: service.recurrency,
        id,
        name: service.name,
        warranty_card: service.warranty_card,
        family_income: service.family_income,
        adhesion_fee: service.adhesion_fee.toFixed(2).replace('.', ','),
        administration_fee: service.administration_fee,
        // panic_value: service.panic_value.toFixed(2).replace('.', ','),
        contract_time: service.contract_time,
      })
    }
  }

  payment = (type) => {
    if (type === 'parcel') {
      this.setState({ parcel: 1, recurrency: 0 })
    } else {
      this.setState({ parcel: 0, recurrency: 1 })
    }
  }

  saveService = async () => {
    await this.setState({ loading: true })
    try {
      const { id, name, warranty_card, family_income, administration_fee, contract_time, editing, level_credibility, parcel, recurrency } = this.state
      const json = {
        parcel,
        recurrency,
        level_credibility,
        id,
        name,
        warranty_card: removeMask(warranty_card),
        family_income: removeMask(family_income),
        adhesion_fee: 0,
        administration_fee: removeMask(administration_fee),
        contract_time,
      }
      const method = editing ? 'put' : 'post'
      await ApiInstance[method]('servico', json)
      this.props.history.push(`/servicos`)
    } catch (error) {
      const snackbarKey = this.props.enqueueSnackbar('Falha ao salvar serviço.', {
        variant: 'error',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
    }
    await this.setState({ loading: false })
  }

  handleChange = (event) => {
    const { id, value } = event.currentTarget
    this.setState({ [id]: value })
  }

  handleCredibility = (value) => {
    this.setState({ level_credibility: value })
  }
  render() {
    const { loading, name, warranty_card, family_income, administration_fee, contract_time, level_credibility, parcel, recurrency } = this.state
    return (
      <React.Fragment>
        <Header />

        <Container style={{ marginTop: 80, marginBottom: 32 }}>
          <Grid spacing={3} container>
            <Grid item xs={12}>
              <div className="servico_form">
                <div className="servico_form__container">
                  <div className="servico_form__container-header">
                    <h1> Novo serviço </h1>
                  </div>
                  <div className="servico_form__container-body">
                    <div className="row-grid">
                      <div className="col-12 col-md-6">
                        <Input type="text" placeholder="Nome" labelDescription="Nome do Serviço" id="name" value={name} onChange={this.handleChange} />
                      </div>
                      <div className="col-12 col-md-6">
                        <Input
                          type="number"
                          placeholder="Limite Mínimo do Cartão"
                          labelDescription="Limite Mínimo do Cartão"
                          id="warranty_card"
                          value={warranty_card}
                          onChange={this.handleChange}
                          iconType="vezes"
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <Input
                          type="number"
                          placeholder="Renda Familiar Comprometida"
                          labelDescription="Renda Familiar Comprometida"
                          id="family_income"
                          value={family_income}
                          onChange={this.handleChange}
                          iconType="percent"
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <Input
                          type="number"
                          placeholder="Taxa de Administração"
                          labelDescription="Taxa de Administração"
                          id="administration_fee"
                          value={administration_fee}
                          onChange={this.handleChange}
                          iconType="percent"
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <Input
                          type="number"
                          placeholder="Tempo de Contrato"
                          labelDescription="Tempo de Contrato"
                          id="contract_time"
                          value={contract_time}
                          onChange={this.handleChange}
                          iconType="number"
                        />
                      </div>
                      <div className="col-12 col-md-3">
                        <div className="payment_method--container">
                          <label>Forma de pagamento:</label>
                          <div className="payment_method">
                            <div className="payment_method-option">
                              <input type="radio" name="payment_method" id="parcel" value={1} checked={parcel === 1 ? true : false} onClick={() => this.payment('parcel')} />
                              <label htmlFor="parcel">Parcelado</label>
                            </div>
                            <div className="payment_method-option">
                              <input type="radio" name="payment_method" id="recurrency" value={1} checked={recurrency === 1 ? true : false} onClick={() => this.payment('recurrency')} />
                              <label htmlFor="recurrency">Recorrência</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-3">
                        <div className="level_credibility">
                          Credibilidade Mínima:
                          <label htmlFor="container-credibility">
                            <div className="credibility--current">
                              <span></span>
                              {level_credibility === 1 && (
                                <span className="happy">
                                  {' '}
                                  <i className="lni-emoji-happy"></i>{' '}
                                </span>
                              )}
                              {level_credibility === 2 && (
                                <span className="neutral">
                                  {' '}
                                  <i className="lni-emoji-neutral"></i>{' '}
                                </span>
                              )}
                              {level_credibility === 3 && (
                                <span className="sad">
                                  {' '}
                                  <i className="lni-emoji-sad"></i>{' '}
                                </span>
                              )}
                              <span className="credibility--current-divider">
                                <i className="lni-chevron-down"></i>
                              </span>
                            </div>
                            <input type="checkbox" id="container-credibility" />
                            <div className="credibility-options">
                              <span className="happy" onClick={() => this.handleCredibility(1)}>
                                <i className="lni-emoji-happy"></i>
                              </span>
                              <span className="neutral" onClick={() => this.handleCredibility(2)}>
                                <i className="lni-emoji-neutral"></i>
                              </span>
                              <span className="sad" onClick={() => this.handleCredibility(3)}>
                                <i className="lni-emoji-sad"></i>
                              </span>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 servico_form__button-container">
                    {loading ? (
                      <div>
                        <div className="loader" />
                      </div>
                    ) : (
                      <Button disabled={loading} description="Salvar" onClick={this.saveService} />
                    )}
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </React.Fragment>
    )
  }
}

export default withSnackbar(ServicosForm)
