import React from 'react'
import { maskMoney } from '../util/money'

const CardPlan = (props) => {
  const title = props.name // || 'Starter pack'
  const subtitle = props.description // || 'qlker coisa'
  const content = props.content || [
    `Taxa de implantação: ${maskMoney(props.membership_fee)}`,
    `Consulta realizada: ${maskMoney(props.value_query)}`,
    `Cliente ativo: ${maskMoney(props.value_transaction)}`,
  ]
  const bottomPhrase = props.bottomPhrase || (
    <React.Fragment>
      Pelo valor mensal de <span className="price">{maskMoney(props.monthly_value)}</span>
    </React.Fragment>
  )
  const bottom = props.bottom || null
  const onClick = props.onClick ? props.onClick : () => {}
  return (
    <div onClick={onClick} className={`card ${props.active ? 'card-active' : ''}`} id="card-1">
      <div className="card-heading">
        <h2>{title}</h2>
        <span>{subtitle}</span>
      </div>
      <div className="card-features">
        <ul>
          {content.map((c, index) => (
            <li key={index}>{c}</li>
          ))}
        </ul>
      </div>

      <div className="card-price">
        <p>{bottomPhrase}</p>
        {bottom}
      </div>
    </div>
  )
}

export default CardPlan
