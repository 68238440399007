import React from 'react'
import Api from '../services/ApiInstance'

import { withSnackbar } from 'notistack'

class Notification extends React.Component {
  state = {
    link_2via: null,
    open: false,
  }
  loadBoleto = async () => {
    try {
      await this.setState({ open: true })
      let boleto = await Api.get('empresa/retirarTrial')
      const { link_2via } = boleto.data[0]
      this.setState({ link_2via })
    } catch (e) {
      //console.log(e)
    }
  }

  onCloseModal = () => {
    this.setState({ open: false })
    this.props.afterConfirmTrial()
  }

  sendMail = async () => {
    try {
      await Api.post('empresa/enviarTrial', { link: this.state.link_2via })
      const snackbarKey = this.props.enqueueSnackbar('Enviado com sucesso.', {
        variant: 'success',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
    } catch (error) {
      const snackbarKey = this.props.enqueueSnackbar('Falha ao enviar.', {
        variant: 'error',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
    }
  }
  render() {
    const { goal, total_purchase } = this.props
    return (
      <React.Fragment>
        {this.props.show ? (
          <div className="trial-popup" onClick={this.props.close}>
            <div className="trial-popup-image">
              <img src="https://i.ibb.co/tLM2JXX/Banner-Ucred-Mais-transa-es-mais-voc-ganha-1.png" />
            </div>
            <div className="trial-popup-text">
              <p>Quanto mais transações com </p>
              <p>
                a Ucred, <b>mais você ganha</b>
              </p>
            </div>
            <div className="trial-popup-data">
              <div>
                <p>Percentual da meta</p>
                <span>{((total_purchase / goal) * 100).toFixed(0)}%</span>
              </div>
              <div>
                <p>Transações faltantes para a meta</p>
                <span>{goal - total_purchase}</span>
              </div>
            </div>
            <div className="trial-popup-close">X</div>
          </div>
        ) : (
          <div></div>
        )}
      </React.Fragment>
    )
  }
}
export default withSnackbar(Notification)
