import { Box, BoxProps } from '@chakra-ui/react'

interface CardProps extends BoxProps {
  children: JSX.Element | JSX.Element[]
}

export function Card({
  children,
  padding = 6,
  borderRadius = 16,
  boxShadow = 'xs',
  ...rest
}: CardProps) {
  return (
    <Box p={padding} borderRadius={borderRadius} boxShadow={boxShadow} {...rest}>
      {children}
    </Box>
  )
}
