import { Box, Container, Stack } from '@chakra-ui/react'

import { Breadcrumb } from './Breadcrumb'
import Header from '../../layout/Header'
import Table from './Table'

function Followup() {
  return (
    <Box mt={20}>
      <Header />
      <Container maxWidth="1600px" width="100%" pr={3} pl={3}>
        <Breadcrumb />
        <Stack spacing={3}>
          <Table />
        </Stack>
      </Container>
    </Box>
  )
}

export { Followup }
