import { Stack, Text } from '@chakra-ui/react'

interface LabelInfoProps {
  label: string
  info: string
  infoColor?: string
  infoSize?: string
  fw?: string
}

export function LabelInfo({
  label,
  info,
  infoColor = 'gray.600',
  infoSize = 'sm',
  fw = 'normal',
}: LabelInfoProps) {
  return (
    <Stack spacing={0.5} align="start">
      <Text fontSize="xs" color="gray.500">
        {label}
      </Text>
      <Text fontSize={infoSize} fontWeight={fw} color={infoColor}>
        {info}
      </Text>
    </Stack>
  )
}
