import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core'
import MaterialTable from 'material-table'
import moment from 'moment'
import 'moment/locale/pt-br'
import { withSnackbar } from 'notistack'
import React from 'react'
import ApiInstance from '../../services/ApiInstance'
import tableIcons from '../../styles/tableIcons'
import styles from '../../styles/defaultStyles'
import { DropzoneArea } from 'material-ui-dropzone'
import { Close } from '@material-ui/icons'

class InvoicesTable extends React.Component {
  constructor() {
    super()

    this.state = {
      loading: true,
      confirmation: false,
      cancelId: null,
      cancelDescription: '',
      confirmationLoading: false,
      files64: [],
    }
  }

  convertToBase64 = (file, cb) => {
    //Read File
    var selectedFile = file
    //Check File is not Empty
    if (selectedFile.length > 0) {
      // Select the very first file from list
      var fileToLoad = selectedFile[0]
      // FileReader function for read the file.
      var fileReader = new FileReader()
      var base64
      // Onload of file read the file content
      fileReader.onload = function (fileLoadedEvent) {
        base64 = fileLoadedEvent.target.result
        // Print data in console
        cb(base64)
      }
      // Convert data to base64
      fileReader.readAsDataURL(fileToLoad)
    }
  }

  render() {
    let url = 'http://localhost:3333/'

    // eslint-disable-next-line no-undef
    switch (process.env.REACT_APP_STAGE) {
      case 'development':
        url = 'http://localhost:3333/'
        break
      case 'staging':
        url = 'http://app.hml.ucred.io/'
        break
      case 'production':
        url = 'http://app.ucred.io/'
        break
      default:
        break
    }

    const { confirmation, cancelId, cancelDescription, confirmationLoading } = this.state
    const noOverflow = {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      width: 300,
      maxWidth: 300,
      textOverflow: 'ellipsis',
    }

    const invoicesTable = () => {
      let invoicesArray = []
      this.props.parent.state.invoices.rows.map((_i) => {
        let invoicesObj = {}
        invoicesObj.value = _i.value
        switch (_i.status) {
          case 1:
            invoicesObj.status = 'Requisitado'
            invoicesObj.status_column = (
              <Chip style={{ color: '#fff', background: styles.info }} size="small" label="Requisitado" />
            )
            break
          case 2:
            invoicesObj.status = 'Recusado'
            invoicesObj.status_column = (
              <Chip style={{ color: '#fff', background: styles.error }} size="small" label="Recusado" />
            )
            break
          case 3:
            invoicesObj.status = 'Falha'
            invoicesObj.status_column = (
              <Chip style={{ color: '#fff', background: styles.error }} size="small" label="Falha" />
            )
            break
          case 4:
            invoicesObj.status = 'Pago'
            invoicesObj.status_column = (
              <Chip style={{ color: '#fff', background: styles.success }} size="small" label="Pago" />
            )
            break
          case 5:
            invoicesObj.status = 'Cancelado'
            invoicesObj.status_column = (
              <Chip style={{ color: '#fff', background: styles.critical }} size="small" label="Cancelado" />
            )
            break
          default:
            break
        }

        let forecast = moment(_i.created_at).add(30, 'days')

        if (forecast.format('dddd') === 'Sábado') forecast = moment(_i.created_at).add(29, 'days')
        if (forecast.format('dddd') === 'Domingo') forecast = moment(_i.created_at).add(28, 'days')

        invoicesObj.payment_forecast = new Date(forecast).toLocaleDateString('ptB')

        if (_i.status === 2 || _i.status === 5) invoicesObj.payment_forecast = '-'

        invoicesObj.created_at = new Date(_i.created_at).toLocaleDateString('ptB')
        invoicesObj.name = _i.name
        invoicesObj.description = _i.description
        invoicesObj.path = JSON.parse(_i.path)

        invoicesObj.cancel = (
          <Button
            disabled={_i.status === 2 || _i.status === 4 || _i.status === 5}
            onClick={async () => {
              this.setState({ confirmation: true, cancelId: _i.id })
            }}
            size="small"
            color="primary"
            variant="contained"
          >
            Cancelar
          </Button>
        )

        invoicesArray.push(invoicesObj)

        return null
      })

      return invoicesArray
    }
    return (
      <>
        <Dialog
          open={confirmation}
          onClose={() => {
            this.setState({ confirmation: false })
          }}
        >
          <DialogTitle
            disableTypography
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6">Deseja cancelar este sinistro?</Typography>
            <IconButton
              onClick={() => {
                this.setState({ confirmation: false })
              }}
            >
              <Close></Close>
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <DialogContentText>
              Atenção, você ainda poderá visualizar os sinistros cancelados, mas a ação de cancelamento é irreversível.
            </DialogContentText>
            <DialogContentText>
              Insira o motivo do cancelamento e um comprovante de quitação dos débitos antes de continuar.
            </DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <DropzoneArea
                  maxFileSize={2000000}
                  filesLimit={10}
                  dropzoneText={
                    'Arraste e solte arquivos aqui ou clique para fazer upload. São aceitos aquivos .jpeg, .png, .bmp e .pdf'
                  }
                  getFileLimitExceedMessage={(filesLimit) =>
                    `Limite máximo de arquivos excedidos. São permitidos no máximo ${filesLimit} arquivos.`
                  }
                  getFileAddedMessage={(fileName) => `O arquivo ${fileName} foi adicionado.`}
                  getFileRemovedMessage={(fileName) => `O arquivo ${fileName} foi removido.`}
                  getDropRejectMessage={(file) =>
                    `O arquivo ${file.name} foi rejeitado. São aceitos no máximo 3 arquivos, com tamanho máximo de 2Mb nos formatos png, jpeg, bmp ou pdf.`
                  }
                  acceptedFiles={['image/jpeg', 'image/png', 'image/bmp', 'application/pdf']}
                  onChange={(files) => {
                    let filesArr = []
                    files.map((files) => {
                      this.convertToBase64([files], (base64) => {
                        filesArr.push({ name: files.name, base: base64 })
                      })
                    })
                    this.setState({ files64: filesArr })
                  }}
                ></DropzoneArea>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  multiline
                  variant="outlined"
                  label="Motivo do cancelamento"
                  rows={4}
                  fullWidth
                  value={cancelDescription}
                  placeholder="Insira aqui um motivo para o cancelamento..."
                  onChange={(e) => this.setState({ cancelDescription: e.target.value })}
                ></TextField>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => this.setState({ confirmation: false })}>
              Voltar
            </Button>
            <Box style={{ display: 'flex', position: 'relative' }}>
              <Button
                color="primary"
                variant="contained"
                disabled={confirmationLoading}
                onClick={async () => {
                  this.setState({ confirmationLoading: true })
                  await ApiInstance.put(`/invoice/${cancelId}`, {
                    status: 5,
                    description: cancelDescription,
                    files: this.state.files64,
                  })
                    .then((response) => {
                      const snackbarKey = this.props.enqueueSnackbar(response.message, {
                        variant: 'success',
                        onClick: () => {
                          this.props.closeSnackbar(snackbarKey)
                        },
                      })
                      this.props.parent.fetchData()
                      this.setState({ confirmationLoading: false, confirmation: false })
                    })
                    .catch(() => {
                      const snackbarKey = this.props.enqueueSnackbar(
                        'Erro inesperado, entre em contato com nosso suporte.',
                        {
                          variant: 'error',
                          onClick: () => {
                            this.props.closeSnackbar(snackbarKey)
                          },
                        }
                      )
                      this.setState({ confirmationLoading: false })
                    })
                }}
              >
                Salvar
              </Button>
              {confirmationLoading && (
                <CircularProgress
                  size={24}
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: -12,
                    marginLeft: -12,
                  }}
                />
              )}
            </Box>
          </DialogActions>
        </Dialog>
        <MaterialTable
          icons={tableIcons}
          columns={[
            {
              title: 'Valor acionado',
              field: 'value',
              type: 'currency',
              align: 'center',
              currencySetting: {
                locale: 'pt-BR',
                currencyCode: 'BRL',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              },
              cellStyle: noOverflow,
            },
            {
              title: 'Status',
              field: 'status',
              type: 'string',
              align: 'center',
              cellStyle: noOverflow,
              export: true,
              hidden: true,
              searchable: true,
            },
            {
              title: 'Status',
              field: 'status_column',
              align: 'center',
              cellStyle: noOverflow,
              export: false,
              searchable: false,
            },
            {
              title: 'Data do acionamento',
              field: 'created_at',
              type: 'date',
              align: 'center',
              cellStyle: noOverflow,
              defaultSort: 'desc',
            },
            {
              title: 'Previsão de pagamento',
              field: 'payment_forecast',
              type: 'date',
              align: 'center',
              cellStyle: noOverflow,
            },
            { title: 'Solicitado por', field: 'name', align: 'center', cellStyle: noOverflow },
            { title: 'Descrição', field: 'description', align: 'center', cellStyle: noOverflow, hidden: true },
            { title: 'Cancelar', field: 'cancel', align: 'center', cellStyle: noOverflow, export: false },
            { title: 'Path', field: 'path', align: 'center', cellStyle: noOverflow, export: false, hidden: true },
          ]}
          options={{
            pageSize: 15,
            pageSizeOptions: [15, 50, 100],
            tableLayout: 'auto',
            showTitle: true,
            padding: 'dense',
            exportAllData: true,
            exportFileName: 'Relatório Ucred (Sinistros) - ' + moment().format('DD-MM-YYYY H:mm:ss'),
            exportButton: true,
            actionsColumnIndex: 0,
          }}
          onRowClick={(event, rowData, togglePanel) => {
            togglePanel()
          }}
          detailPanel={(rowData) => (
            <Grid container spacing={2}>
              {rowData.path &&
                rowData.path.map((file, index) => {
                  return (
                    <Box
                      onClick={() => {
                        window.open(`${url}${file.path}`, '_blank')
                      }}
                      key={index}
                      m={3}
                      style={{
                        background: '#fafafa',
                        borderRadius: 16,
                        display: 'flex',
                        flexDirection: 'column',
                        cursor: 'pointer',
                      }}
                    >
                      <Typography
                        variant="body2"
                        noWrap
                        style={{ margin: '8px 16px', width: 93 }}
                        align="center"
                        color="textSecondary"
                      >
                        {file.name}
                      </Typography>
                      <embed
                        src={`${url}${file.path}`}
                        width="125"
                        height="140"
                        style={{ borderRadius: 16, border: '1px solid #ddd' }}
                      />
                    </Box>
                  )
                })}
            </Grid>
          )}
          components={{ Container: (props) => <div id="mtable-container" {...props}></div> }}
          isLoading={this.props.loading}
          data={invoicesTable()}
          title="Relatório de sinistros"
          localization={{
            header: {
              actions: 'Ações',
            },
            body: {
              emptyDataSourceMessage: 'Nenhum registro para exibir',
            },
            toolbar: {
              exportTitle: 'Exportar',
              searchTooltip: 'Pesquisar',
              searchPlaceholder: 'Pesquisar',
              exportCSVName: 'Exportar em CSV',
              exportPDFName: 'Exportar em PDF',
            },
            pagination: {
              labelRowsSelect: 'linhas',
              labelDisplayedRows: '{from}-{to} de {count}',
              firstTooltip: 'Primeira página',
              previousTooltip: 'Página anterior',
              nextTooltip: 'Próxima página',
              lastTooltip: 'Última página',
            },
          }}
        />
      </>
    )
  }
}

export default withSnackbar(InvoicesTable)
