import { MdPictureAsPdf, MdAttachFile } from 'react-icons/md'
import { RiImage2Fill } from 'react-icons/ri'

import { DropzoneArea as MuiDropzoneArea } from 'material-ui-dropzone'
import { Icon } from '@chakra-ui/react'

interface DropzoneAreaProps {
  onChange: (files: any[]) => Promise<void>
  dropzoneText: string
}

export function DropzoneArea({ onChange, ...rest }: DropzoneAreaProps) {
  const handlePreviewIcon = (fileObject: { file: { type: string } }) => {
    const { type } = fileObject.file
    if (type.startsWith('image/'))
      return <Icon as={RiImage2Fill} boxSize={16} color="blue.500" m={2} />
    if (type.startsWith('application/pdf'))
      return <Icon as={MdPictureAsPdf} boxSize={16} color="red.500" m={2} />
    return <Icon as={MdAttachFile} boxSize={16} color="gray.500" m={2} />
  }

  return (
    <MuiDropzoneArea
      maxFileSize={2000000}
      filesLimit={10}
      acceptedFiles={['image/jpeg', 'image/png', 'image/bmp', 'application/pdf']}
      getFileAddedMessage={fileName => `O arquivo ${fileName} foi adicionado.`}
      getFileRemovedMessage={fileName => `O arquivo ${fileName} foi removido.`}
      getFileLimitExceedMessage={filesLimit =>
        `Limite máximo de arquivos excedidos. São permitidos no máximo ${filesLimit} arquivos.`
      }
      getDropRejectMessage={file =>
        `O arquivo ${file.name} foi rejeitado. São aceitos no máximo 3 arquivos, com tamanho máximo de 2Mb nos formatos png, jpeg, bmp ou pdf.`
      }
      getPreviewIcon={handlePreviewIcon}
      alertSnackbarProps={{
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
        autoHideDuration: 2000,
      }}
      showFileNames={true}
      dropzoneParagraphClass="dropzoneParagraphClass"
      onChange={onChange}
      {...rest}
    />
  )
}
