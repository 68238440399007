import { Container, Stack, Box } from '@chakra-ui/react'

import Header from '../../layout/Header'
import { Breadcrumb } from './Breadcrumb'
import { InvoicesTable } from './Table'

export function Invoices() {
  return (
    <Box h="fit-content" mb={8} mt={20}>
      <Header />
      <Container maxWidth="1600px" width="100%" pr={3} pl={3}>
        <Breadcrumb />
        <Stack spacing={3}>
          <InvoicesTable />
        </Stack>
      </Container>
    </Box>
  )
}
