import { Chip } from '@material-ui/core'
import MaterialTable from 'material-table'
import moment from 'moment'
import 'moment/locale/pt-br'
import React from 'react'
import { connect } from 'react-redux'
import ApiInstance from '../../services/ApiInstance'
import { changeNotification, fillAguardandoTrial, fillTrial, logOut } from '../../redux/actions/authUserAction'
import { refresh } from '../../redux/actions/creditAnalysisActions'
import styles from '../../styles/defaultStyles'
import tableIcons from '../../styles/tableIcons'

class InvoicesTable extends React.Component {
  constructor(props) {
    super(props)
    const noOverflow = {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      width: 300,
      maxWidth: 300,
      textOverflow: 'ellipsis',
    }

    this.state = {
      loading: true,
      feedbacks: [],
      rows: [],
      columns: [
        {
          title: 'Valor acionado',
          field: 'value',
          cellStyle: noOverflow,
          type: 'currency',
          currencySetting: { locale: 'pt-BR', currencyCode: 'BRL', minimumFractionDigits: 2, maximumFractionDigits: 2 },
        },
        {
          title: 'Status',
          field: 'status',
          cellStyle: noOverflow,
          export: true,
          hidden: true,
          searchable: true,
        },
        {
          title: 'Status',
          field: 'status_column',
          export: false,
          searchable: false,
          align: 'center',
          cellStyle: noOverflow,
        },
        {
          title: 'Locatário',
          field: 'client.name',
          cellStyle: noOverflow,
        },
        {
          title: 'Data de acionamento',
          field: 'created_at',
          type: 'datetime',
          cellStyle: noOverflow,
          defaultSort: 'desc',
        },
        {
          title: 'Previsão de pagamento',
          field: 'payment_forecast',
          type: 'date',
          cellStyle: noOverflow,
          defaultSort: 'desc',
        },
        {
          title: 'Descrição',
          field: 'description',
          cellStyle: noOverflow,
        },
        {
          title: 'Criado por',
          field: 'user.name',
          cellStyle: noOverflow,
        },
      ],
    }
  }

  componentDidMount() {
    this.fetchData()
  }

  async fetchData() {
    const invoices = await ApiInstance.get('/invoices')
    this.setState({ rows: invoices.data, loading: false })
  }

  render() {
    const { loading, rows, columns } = this.state

    rows.map((row) => {
      let forecast = moment(row.created_at).add(30, 'days')
      if (forecast.format('dddd') === 'Sábado') forecast = moment(row.created_at).add(29, 'days')
      if (forecast.format('dddd') === 'Domingo') forecast = moment(row.created_at).add(28, 'days')

      row.payment_forecast = forecast.format('YYYY-MM-DD')

      if (row.status === 2 || row.status === 5) row.payment_forecast = '-'

      switch (row.status) {
        case 1:
          row.status = 'Requisitado'
          row.status_column = (
            <Chip style={{ color: '#fff', background: styles.info }} size="small" label="Requisitado" />
          )
          break
        case 2:
          row.status = 'Recusado'
          row.status_column = (
            <Chip style={{ color: '#fff', background: styles.critical }} size="small" label="Recusado" />
          )
          break
        case 3:
          row.status = 'Falha'
          row.status_column = <Chip style={{ color: '#fff', background: styles.error }} size="small" label="Falha" />
          break
        case 4:
          row.status = 'Pago'
          row.status_column = <Chip style={{ color: '#fff', background: styles.success }} size="small" label="Pago" />
          break
        case 5:
          row.status = 'Cancelado'
          row.status_column = (
            <Chip style={{ color: '#fff', background: styles.critical }} size="small" label="Cancelado" />
          )
          break
        default:
          break
      }
    })

    const onClickRow = (id_cliente) => {
      this.props.parent.props.history.push({ pathname: `detalhe-do-cliente/${id_cliente}`, state: { tab: 2 } })
    }

    return (
      <MaterialTable
        style={{ width: '100%' }}
        icons={tableIcons}
        columns={columns}
        options={{
          pageSize: 15,
          pageSizeOptions: [15, 50, 100],
          tableLayout: 'auto',
          showTitle: true,
          padding: 'dense',
          exportAllData: true,
          exportFileName: 'Relatório Ucred (Sinistros) - ' + moment().format('DD-MM-YYYY H:mm:ss'),
          exportButton: true,
          actionsColumnIndex: -1,
        }}
        onRowClick={(event, rowData) => onClickRow(rowData.client.id)}
        components={{ Container: (props) => <div id="mtable-container" {...props}></div> }}
        isLoading={loading}
        data={rows}
        title="Relatório de sinistros"
        localization={{
          header: {
            actions: 'Ações',
          },
          body: {
            emptyDataSourceMessage: 'Nenhum registro para exibir',
          },
          toolbar: {
            exportTitle: 'Exportar',
            searchTooltip: 'Pesquisar',
            searchPlaceholder: 'Pesquisar',
            exportCSVName: 'Exportar em CSV',
            exportPDFName: 'Exportar em PDF',
          },
          pagination: {
            labelRowsSelect: 'linhas',
            labelDisplayedRows: '{from}-{to} de {count}',
            firstTooltip: 'Primeira página',
            previousTooltip: 'Página anterior',
            nextTooltip: 'Próxima página',
            lastTooltip: 'Última página',
          },
        }}
      />
    )
  }
}

function mapStateToProps({ authReducer }) {
  return {
    user: authReducer.authUser,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    refresh: () => dispatch(refresh()),
    logOut: () => dispatch(logOut()),
    fillTrial: (trial) => dispatch(fillTrial(trial)),
    fillAguardandoTrial: (trial) => dispatch(fillAguardandoTrial(trial)),
    changeNotification: (status) => dispatch(changeNotification(status)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesTable)
