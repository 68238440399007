import {
  Box,
  ButtonBase,
  ClickAwayListener,
  Divider,
  IconButton,
  Paper,
  Popper,
  Tooltip,
  withStyles,
  Typography,
  Zoom,
  Badge,
} from '@material-ui/core'
import { NotificationsRounded } from '@material-ui/icons'
import { useState, useEffect } from 'react'
import ApiInstance from '../../../services/ApiInstance'
import ApiInstanceV2 from '../../../services/ApiInstanceV2'
import Feedback from '../dialogs/Feedback'
import moment from 'moment'

export default function Notifications(props: any) {
  const { user, refresh } = props
  const contractUrl = user && user.contractUrl

  const [notificationCount, setNotificationCount] = useState(0)
  const [showNotification, setShowNotification] = useState(false)
  const [notificationAnchor, setNotificationAnchor] = useState<any>(null)

  const [notifications, setNotifications] = useState<
    Array<{
      title: string
      // eslint-disable-next-line camelcase
      icon_url: string
      // eslint-disable-next-line camelcase
      read_notification: boolean
      message: string
      id: number
    }>
  >([])
  const [feedback, setFeedback] = useState<{
    answers: {
      question1: null | number
      question2: null | number
      question3: null | number
    }
    open: boolean
    loading: boolean
    observation: string
    feedbackNotification: boolean
  }>({
    answers: {
      question1: null,
      question2: null,
      question3: null,
    },
    open: false,
    loading: false,
    observation: '',
    feedbackNotification: false,
  })

  useEffect(() => {
    async function fetchData() {
      await ApiInstanceV2.get('/notification').then(response => {
        if (response.data) {
          setNotifications(response.data.notifications)
          setNotificationCount(notificationCount + response.data.count)
        }
      })

      const feedback = await ApiInstance.get('user_feedback')
      if (!feedback.data || moment(feedback.data.created_at).isBefore(moment().add(-3, 'months'))) {
        setFeedback({ ...feedback, feedbackNotification: true })
        setNotificationCount(notificationCount + 1)
      }
    }

    fetchData().then(() => {
      refresh()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleMarkAsRead = async (index: number) => {
    const id = notifications[index].id
    if (!notifications[index].read_notification) {
      await ApiInstanceV2.put(`/notification/${id}`, { read_notification: true }).then(() => {
        notifications[index].read_notification = true
        setNotifications(notifications)
        setNotificationCount(notificationCount - 1)
      })
    }
  }

  const StyledBadge = withStyles(theme => ({
    badge: {
      right: 6,
      top: 20,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  }))(Badge)

  const needUpdate = user && user.contractStatus === 'need_update'
  if (needUpdate) setNotificationCount(notificationCount + 1)
  return (
    <>
      <Feedback
        user={user}
        feedback={feedback}
        setFeedback={setFeedback}
        notificationCount={notificationCount}
        setNotificationCount={setNotificationCount}
      />
      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={() => setShowNotification(false)}
      >
        <Box>
          <Tooltip title="Notificações">
            <IconButton
              className="icon"
              onClick={event => {
                setNotificationAnchor(event.currentTarget)

                setShowNotification(!showNotification)
              }}
            >
              <StyledBadge
                style={{
                  pointerEvents: 'none',
                }}
                badgeContent={notificationCount}
                color="error"
              >
                <NotificationsRounded style={{ color: '#fff' }} />
              </StyledBadge>
            </IconButton>
          </Tooltip>

          <Popper
            style={{ top: 8, right: 4, maxWidth: 400, zIndex: 3 }}
            open={showNotification}
            placement={'bottom-start'}
            anchorEl={notificationAnchor}
          >
            <Zoom in={showNotification}>
              <Paper style={{ maxHeight: 430, overflow: 'auto' }}>
                <Box p={2}>
                  <Typography>Minhas notificações</Typography>
                </Box>
                <Divider />
                {!notificationCount && !notifications.length && (
                  <Box display="flex" p={2}>
                    <Typography color="textSecondary">Você não tem notificações...</Typography>
                  </Box>
                )}
                {needUpdate && (
                  <ButtonBase
                    onClick={() => {
                      window.open(contractUrl, '_blank')
                    }}
                    style={{
                      display: 'flex',
                      width: '100%',
                      padding: 12,
                      justifyContent: 'flex-start',
                    }}
                  >
                    <Box display="flex">
                      <img
                        src={
                          'https://res.cloudinary.com/ucred/image/upload/v1625596456/icons/alert.png'
                        }
                        style={{ height: 33, width: 40 }}
                      ></img>
                      <Box style={{ marginLeft: 20 }}>
                        <Typography align="left">Atualização de contrato</Typography>
                        <Typography align="left" variant="body2" color="textSecondary">
                          É necessário assinar o novo contato Ucred
                        </Typography>
                      </Box>
                    </Box>
                  </ButtonBase>
                )}
                {feedback.feedbackNotification && (
                  <ButtonBase
                    onClick={() => setFeedback({ ...feedback, open: true })}
                    style={{
                      display: 'flex',
                      width: '100%',
                      padding: 12,
                      justifyContent: 'flex-start',
                    }}
                  >
                    <Box display="flex">
                      <img
                        src={'https://i.imgur.com/TtBtDcs.png'}
                        style={{ width: 40, height: 40 }}
                      ></img>
                      <Box style={{ marginLeft: 20 }}>
                        <Typography align="left">Realize o feedback trimestral</Typography>
                        <Typography align="left" variant="body2" color="textSecondary">
                          Seu feedback é importante para nós
                        </Typography>
                      </Box>
                    </Box>
                  </ButtonBase>
                )}

                <Box display="flex" flexDirection="column">
                  {notifications.map((notification, index) => (
                    <ButtonBase
                      key={index}
                      style={{
                        display: 'flex',
                        width: '100%',
                        padding: 12,
                        justifyContent: 'flex-start',
                      }}
                      onClick={() => handleMarkAsRead(index)}
                    >
                      <Box display="flex" flex="1" style={{ overflow: 'hidden' }}>
                        <img src={notification.icon_url} style={{ width: 40, height: 40 }}></img>
                        <Box style={{ marginLeft: 20 }}>
                          <Typography
                            align="left"
                            noWrap
                            style={{
                              color: notification.read_notification ? '#ccc' : 'initial',
                            }}
                          >
                            {notification.title}
                          </Typography>
                          <Typography
                            align="left"
                            variant={'body2'}
                            color={'textSecondary'}
                            style={{
                              color: notification.read_notification ? '#ccc' : '',
                            }}
                          >
                            {notification.message}
                          </Typography>
                        </Box>
                      </Box>
                      {/* <Box>{!notification.readNotification && <Badge color="primary" variant="dot" badgeContent=" "></Badge>}</Box> */}
                    </ButtonBase>
                  ))}
                </Box>
              </Paper>
            </Zoom>
          </Popper>
        </Box>
      </ClickAwayListener>
    </>
  )
}
