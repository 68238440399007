import { Box, Button, ButtonBase, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid, IconButton, Tooltip, Typography } from '@material-ui/core'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import React from 'react'
import 'react-image-picker/dist/index.css'

import { withSnackbar } from 'notistack'
import { connect } from 'react-redux'
import ApiInstance from '../../services/ApiInstance'
import { fillField, refresh } from '../../redux/actions/creditAnalysisActions'
import FarmHouse from '../../static/svg/home/farm-house.svg'
import Store from '../../static/svg/home/store.svg'
import { removeMask } from '../../util/money'
import Footer from '../../layout/Footer'
import { Close, Help } from '@material-ui/icons'

class StepExtra extends React.Component {
  state = {
    type_immobile: null,
    help: false,
    helpEmail: false,
    corresponsavel: false,
    openModalCorresp: false,
    openModalServices: false,
    confirmarEmail: false,
    openModalEmail: false,
    loading: false,
  }

  async componentDidMount() {
    let { fillField, oldCpf, client } = this.props
    this.changeEmail({
      target: {
        checked: true,
      },
    })
    this.setState({ openModalEmail: false })
    if (oldCpf != client.cpf) {
      fillField('oldCpf', client.cpf)
    }
  }

  help = () => {
    let { help } = this.state
    this.setState({ help: !help })
  }

  helpEmail = () => {
    let { helpEmail } = this.state
    this.setState({ helpEmail: !helpEmail })
  }

  changeCorresponsavel = () => {
    let { corresponsavel } = this.state
    let { fillField } = this.props
    this.setState({ corresponsavel: !corresponsavel })
    fillField('has_corresponsible', !corresponsavel)
    if (!corresponsavel) {
      if (window.localStorage.getItem('hiddenCorrespMessage') !== 'true') {
        this.setState({ openModalCorresp: true })
      }
    }
  }

  changeEmail = (event) => {
    let { confirmarEmail } = this.state
    let { fillField, creditCardCorresp, creditCardResp, client, corresponsavel } = this.props
    this.setState({ confirmarEmail: event.target.checked })
    fillField('confirmarEmail', event.target.checked)
    if (confirmarEmail) {
      if (window.localStorage.getItem('hiddenEmailMessage') !== 'true') {
        this.setState({ openModalEmail: true })
      }
    }
    if (event.target.checked) {
      fillField('fluxo', 2)
      fillField('creditCardResp', {
        ...creditCardResp,
        number: '5555 5555 5555 5557',
        expiry: '10/2022',
        cvv: '123',
        card_maturity: 1,
        type: 'mastercard',
      })
      fillField('creditCardCorresp', {
        ...creditCardCorresp,
        number: '5555 5555 5555 5557',
        expiry: '10/2022',
        cvv: '123',
        card_maturity: 1,
        type: 'mastercard',
      })
      fillField('client', { ...client, card_maturity: 1 })
      fillField('corresponsavel', { ...corresponsavel, card_maturity: 1 })
    }
    if (!event.target.checked) {
      fillField('fluxo', 1)
      fillField('creditCardResp', { number: '', name: '', expiry: '', cvv: '', focused: '', date: '', value: 0 })
      fillField('creditCardCorresp', { number: '', name: '', expiry: '', cvv: '', focused: '', date: '', value: 0 })
      fillField('client', { ...client, card_maturity: null })
      fillField('corresponsavel', { ...corresponsavel, card_maturity: null })
    }
  }

  closeModalCorresp = () => {
    this.setState({ openModalCorresp: false })
  }

  closeModalService = () => {
    this.setState({ openModalServices: false })
  }

  showMessageEmail = (event) => {
    let { checked } = event.currentTarget
    if (checked) {
      window.localStorage.setItem('hiddenEmailMessage', true)
    } else {
      window.localStorage.setItem('hiddenEmailMessage', false)
    }
  }

  showMessage = (event) => {
    let { checked } = event.currentTarget
    if (checked) {
      window.localStorage.setItem('hiddenCorrespMessage', true)
    } else {
      window.localStorage.setItem('hiddenCorrespMessage', false)
    }
  }

  onPick = (value) => {
    this.setState({ error_immobile: false })
    const { fillField } = this.props
    this.setState({ type_immobile: value })
    fillField('type_immobile', value)
  }

  next = async () => {
    try {
      this.props.changeLoading(true)
      await this.avancar()
    } catch (error) {
      this.props.changeLoading(false)
      if (error.message == 'SEM_SERVICOS') return this.setState({ openModalServices: true })
      const snackbarKey = this.props.enqueueSnackbar(error.message, {
        variant: 'error',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
    }
  }

  back = () => {
    this.props.back()
  }

  avancar = async () => {
    try {
      let { type_immobile } = this.props
      if (type_immobile !== 0 && type_immobile !== 1) throw new Error('ERRO_SELECT')
      let { cardLimit, transactionValue, cpf, familyIncome, fillField, creditCardResp } = this.props
      let { confirmarEmail } = this.state
      if (!transactionValue) throw new Error('CAMPO_VAZIO')
      let {
        data: { services, client, files },
      } = await ApiInstance.get(
        `/clienteTransacao?cpf=${cpf}&cardLimit=${removeMask(cardLimit)}&familyIncome=${removeMask(familyIncome)}&transactionValue=${transactionValue}&typeImobile=${type_immobile}`
      ).catch((error) => {
        const snackbarKey = this.props.enqueueSnackbar(error.message, {
          variant: 'error',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        })
        this.props.changeLoading(false)
      })
      if (services.length === 0) throw new Error('SEM_SERVICOS')
      if (client) {
        if (services[0].type_immobile === 1) {
          fillField('client', {
            ...client,
            cnpj: client.cnpj ? client.cnpj : this.props.client.cnpj,
            company_name: client.company_name ? client.company_name : this.props.client.company_name,
          })
        } else {
          fillField('client', client)
        }
        fillField('creditCardResp', { ...creditCardResp, name: client.name })
      }
      if (!client) {
        if (!confirmarEmail) {
          fillField('creditCardResp', { number: '', name: '', expiry: '', cvv: '', focused: '', date: '', value: 0 })
          fillField('creditCardCorresp', { number: '', name: '', expiry: '', cvv: '', focused: '', date: '', value: 0 })
        }

        fillField('client', {
          cpf: this.props.client.cpf,
          id_vindi: null,
          id: null,
          name: '',
          email: '',
          telephone_1: '',
          telephone_2: '',
          card_maturity: '',
          city: '',
          address: '',
          birthday: null,
          confirm_email: '',
          cnpj: null,
          company_name: null,
        })
        fillField('immobile', { address: '', cep: '', city: '' })
        fillField('selectedService', { parcel: 1, value: 0 })
        fillField('finalValue', 0)
        fillField('adhesion', { value: 0, parcel: 0 })
      }
      fillField('selectedService', { parcel: 1, value: 0 })
      fillField('finalValue', 0)
      fillField('services', services)
      fillField('files', files)
      fillField('serviceActive', -1)
      fillField('clientCorresp', false)
      fillField('corresponsavel', {
        id_vindi: null,
        id: null,
        name: null,
        cpf: null,
        email: null,
        telephone_1: null,
        telephone_2: null,
        card_maturity: null,
        city: null,
        address: null,
        birthday: null,
        confirm_email: null,
      })
      fillField('services', services)
      setTimeout(() => {
        this.props.changeLoading(false)
        this.props.nextStep()
      }, 2000)
    } catch (error) {
      if (error.message === 'ERRO_SELECT') {
        this.setState({ error_immobile: true })
        throw new Error('Selecione o tipo do imóvel antes de prosseguir.')
      }
      if (error.message == 'CAMPO_VAZIO') {
        document.getElementById('transactionValue').focus()
        this.setState({ error_value: true })
        throw new Error('Informe o valor da transação.')
      }
      if (error.message == 'SEM_SERVICOS') throw new Error('SEM_SERVICOS')
    }
  }

  handleChange = (event, value) => {
    this.setState({ error_value: false })
    let { fillField } = this.props
    let { id } = event.target
    fillField(id, value)
  }

  render() {
    let { has_corresponsible, type_immobile } = this.props
    const { error_immobile, error_value } = this.state

    return (
      <React.Fragment>
        <Grid container spacing={3} alignItems="center" direction="column">
          <Grid item xs={12}>
            <ButtonBase style={{ borderRadius: 4, margin: 8 }} onClick={() => this.onPick(0)} id="residential-button" >
              <Box
                p={3}
                style={{
                  borderRadius: 4,
                  width: 150,
                  border: `2px solid ${error_immobile ? '#ff6b6b' : type_immobile === 0 ? '#009fff' : '#fafafa'}`,
                  transition: '.5s',
                }}
              >
                <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                  <img style={{ width: 60 }} src={FarmHouse} />
                  <Typography variant="button">RESIDENCIAL</Typography>
                </Box>
              </Box>
            </ButtonBase>
            <ButtonBase style={{ borderRadius: 4, margin: 8, width: 150 }} onClick={() => this.onPick(1)}>
              <Box
                p={3}
                style={{
                  borderRadius: 4,
                  width: 150,
                  border: `2px solid ${error_immobile ? '#ff6b6b' : type_immobile === 1 ? '#009fff' : '#fafafa'}`,
                  transition: '.5s',
                }}
              >
                <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                  <img style={{ width: 60 }} src={Store} />
                  <Typography variant="button">COMERCIAL</Typography>
                </Box>
              </Box>
            </ButtonBase>
          </Grid>

          <Grid item xs={12}>
            <form autoComplete="off">
              <CurrencyTextField
                id="transactionValue"
                placeholder="1.200,00"
                error={error_value}
                variant="outlined"
                minimumValue="0"
                currencySymbol="R$"
                decimalCharacter=","
                digitGroupSeparator="."
                outputFormat="string"
                label="Valor da Transação"
                value={this.props.transactionValue}
                onChange={(event, value) => this.handleChange(event, value)}
                InputProps={{
                  endAdornment: (
                    <Tooltip title="O valor da transação é referente ao valor do aluguel, permitindo ainda a inclusão de outros valores, tais como taxa de codomínio e demais encargos locatícios.">
                      <Help></Help>
                    </Tooltip>
                  ),
                }}
              />
            </form>
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel control={<Checkbox color="primary" onChange={this.changeCorresponsavel} checked={has_corresponsible} />} label="Transacionar com 2 cartões" />
          </Grid>

          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            <Button size="large" disabled={this.props.loading} color="primary" variant="outlined" onClick={this.back}>
              Voltar
            </Button>
            <Button size="large" style={{ marginLeft: 8 }} disabled={this.props.loading} color="primary" variant="contained" onClick={this.next}>
              Avançar
            </Button>
          </Grid>
        </Grid>

        <Dialog disableScrollLock open={this.state.openModalCorresp} onClose={this.closeModalCorresp}>
          <DialogTitle
            disableTypography
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6">Atenção</Typography>
            <IconButton onClick={this.closeModalCorresp} size="small" style={{ marginLeft: 'auto' }}>
              <Close></Close>
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <DialogContentText>Ao selecionar está opção você poderá transacionar com 2 cartões de crédito diferentes, sendo eles de uma mesma pessoa ou de pessoas diferentes.</DialogContentText>

            <FormControlLabel control={<Checkbox color="primary" onChange={this.showMessage} />} label="Nao exibir essa mensagem novamente" />
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                this.setState({ openModalCorresp: false })
              }}
            >
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog disableScrollLock open={this.state.openModalEmail} onClose={() => this.setState({ openModalEmail: false })}>
          <DialogTitle
            disableTypography
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6">Atenção</Typography>
            <IconButton onClick={() => this.setState({ openModalEmail: false })} size="small" style={{ marginLeft: 'auto' }}>
              <Close></Close>
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <DialogContentText>
              Ao desmarcar esta opção é importante que possua os dados de cartão de crédito do locatário em mãos para concluir a transação, incluindo o código de segurança (CVV).
            </DialogContentText>
            <FormControlLabel control={<Checkbox color="primary" oid="notify-corresp" onChange={this.showMessageEmail} />} label="Não exibir essa mensagem novamente" />
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                this.setState({ openModalEmail: false })
              }}
            >
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog disableScrollLock open={this.state.openModalServices} onClose={this.closeModalService}>
          <DialogTitle
            disableTypography
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6">Atenção</Typography>
            <IconButton onClick={this.closeModalService} size="small" style={{ marginLeft: 'auto' }}>
              <Close></Close>
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <DialogContentText>Não foram encontrados serviços disponíveis para esse cliente.</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" variant="contained" onClick={this.closeModalService}>
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}

function mapStateToProps({ creditAnalysis, authReducer }) {
  return {
    ...creditAnalysis,
    ...authReducer,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fillField: (field, value) => dispatch(fillField(field, value)),
    refresh: () => dispatch(refresh()),
  }
}

export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(StepExtra))
