import { Box, Button, CircularProgress, Grid, TextField, Typography } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { withSnackbar } from 'notistack'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import ApiInstance from '../../../services/ApiInstance'
import BankList from '../../../static/json/bank_list.json'

class ContaBancariaPessoal extends Component {
  constructor() {
    super()
    this.state = {
      loading: true,

      has_account: false,

      account_id: '',
      account_number: '',
      account_agency: '',
      bank_name: {},
      bank_code: '',
    }
  }
  async componentDidMount() {
    await ApiInstance.get('user_bank_account')
      .then((response) => {
        this.setState({
          has_account: true,
          account_id: response.id,
          account_number: response.account_number,
          account_agency: response.account_agency,
          bank_name: { value: response.bank_code || '000', label: response.bank_name || 'Sem banco' },
          bank_code: response.bank_code,
        })
      })
      .catch(() => {
        return false
      })

    this.setState({ loading: false })
  }

  render() {
    const { loading, has_account, account_number, account_agency, bank_name } = this.state
    const { authUser } = this.props

    const handleUpdateAccount = async () => {
      if (!account_number || !account_agency || !bank_name) {
        const snackbarKey = this.props.enqueueSnackbar('Preencha todos os campos antes de continuar.', {
          variant: 'error',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        })
        return
      }
      this.setState({ loading: true })
      await new Promise((resolve) => setTimeout(resolve, 3000))
      switch (has_account) {
        case true:
          await ApiInstance.put(`/user_bank_account`, { account_number, account_agency, bank_name: bank_name.label, bank_code: bank_name.value })
            .then((response) => {
              const snackbarKey = this.props.enqueueSnackbar(response.message, {
                variant: 'success',
                onClick: () => {
                  this.props.closeSnackbar(snackbarKey)
                },
              })
            })
            .catch((error) => {
              const snackbarKey = this.props.enqueueSnackbar(error.message, {
                variant: 'error',
                onClick: () => {
                  this.props.closeSnackbar(snackbarKey)
                },
              })
            })
          break
        case false:
          await ApiInstance.post(`/user_bank_account`, { account_number, account_agency, bank_name: bank_name.label, bank_code: bank_name.value })
            .then((response) => {
              const snackbarKey = this.props.enqueueSnackbar(response.message, {
                variant: 'success',
                onClick: () => {
                  this.props.closeSnackbar(snackbarKey)
                },
              })
              this.setState({ has_account: true })
            })
            .catch((error) => {
              const snackbarKey = this.props.enqueueSnackbar(error.message, {
                variant: 'error',
                onClick: () => {
                  this.props.closeSnackbar(snackbarKey)
                },
              })
            })
          break
        default:
          break
      }

      this.setState({ loading: false })
    }

    const handleChange = (event) => {
      const { name, value } = event.target
      if (RegExp(/[^0-9-]/gi).test(value)) return
      this.setState({ [name]: value })
    }

    return (
      <Box p={2}>
        <Grid className="form_grid" spacing={2} container>
          <Typography style={{ marginTop: 12, marginLeft: 8, marginBottom: 16 }} gutterBottom variant="h6">
            Insira abaixo os dados da conta
          </Typography>
          <Grid item md={12} xs={12}>
            <TextField
              fullWidth
              type="text"
              variant="outlined"
              label="CPF"
              name="cpf"
              value={authUser ? authUser.cpf || '' : ''}
              mask="###.###.###-##"
              disabled
              iconType="number"
            />
          </Grid>

          <Grid item md={12} xs={12}>
            <Autocomplete
              disableClearable
              fullWidth
              name="bank_name"
              variant="outlined"
              placeholder="Banco"
              options={BankList}
              noOptionsText="Banco não encontrado"
              onChange={(event, newValue) => {
                this.setState({ bank_name: newValue })
              }}
              value={bank_name || { value: '', label: '' }}
              type="text"
              getOptionLabel={(option) => (option.value ? option.value + ' - ' + option.label : '')}
              renderInput={(params) => <TextField {...params} label="Nome do banco" variant="outlined" />}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              type="text"
              variant="outlined"
              label="Agência da conta"
              name="account_agency"
              value={account_agency || ''}
              onChange={handleChange}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              type="text"
              variant="outlined"
              label="Número da conta"
              name="account_number"
              value={account_number || ''}
              onChange={handleChange}
            />
          </Grid>

          <Grid item md={12} xs={12} style={{ display: 'flex', position: 'relative', justifyContent: 'flex-end' }}>
            <Link to="/">
              <Button style={{ marginRight: 8 }} size="large" variant="outlined" color="primary" disabled={loading}>
                Voltar
              </Button>
            </Link>
            <Box style={{ position: 'relative' }}>
              <Button size="large" variant="contained" color="primary" disabled={loading} onClick={handleUpdateAccount}>
                Salvar
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: -12,
                    marginLeft: -12,
                  }}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    )
  }
}

function mapStateToProps({ authReducer }) {
  return {
    ...authReducer,
  }
}

export default withSnackbar(connect(mapStateToProps)(ContaBancariaPessoal))
