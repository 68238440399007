import { Box, Button, CircularProgress, Grid, TextField, Typography } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { withSnackbar } from 'notistack'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import ApiInstance from '../../../services/ApiInstance'
import BankList from '../../../static/json/bank_list.json'

class ContaBancaria extends Component {
  constructor() {
    super()
    this.state = {
      loading: false,
      doc: '',
      account_owner: '',
      account_number: '',
      account_agency: '',
      bank_code: '',
      bank_name: {},
    }
  }
  async componentDidMount() {
    try {
      const bankAccount = await ApiInstance.get('conta')

      if (!bankAccount.data.account_owner && bankAccount.data.doc) {
        await ApiInstance.get(`cnpjAnalyse?cnpj=${bankAccount.data.doc}`)
          .then((response) => {
            bankAccount.data.account_owner = response.companyName
          })
          .catch(() => {})
      }

      if (bankAccount) {
        await this.setState({
          account_id: bankAccount.data.id,
          account_owner: bankAccount.data.account_owner,
          doc: bankAccount.data.doc,
          account_number: bankAccount.data.account_number,
          account_agency: bankAccount.data.account_agency,
          bank_name: { value: bankAccount.data.bank_code || '000', label: bankAccount.data.bank_name || 'Sem banco' },
          bank_code: bankAccount.data.bank_code,
        })
      }
    } catch (error) {
      const snackbarKey = this.props.enqueueSnackbar('Falha ao carregar conta cadastrada.', {
        variant: 'error',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
    }
    this.setState({ loading: false })
  }

  render() {
    const { loading, account_owner, doc, bank_name, account_agency, account_number } = this.state

    const handleChange = (event) => {
      const { name, value } = event.currentTarget
      if (RegExp(/[^0-9-]/gi).test(value) && name !== 'account_owner') return
      if (RegExp(/[0-9]/gi).test(value) && name === 'account_owner') return
      this.setState({ [name]: value })
    }

    const saveAccount = async () => {
      const { doc, account_owner, account_number, account_agency, bank_name } = this.state
      if (this.state.loading) return

      if (!doc || !account_owner || !account_number || !account_agency || !bank_name) {
        const snackbarKey = this.props.enqueueSnackbar('Preencha todos os campos antes de continuar.', {
          variant: 'error',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        })
        return
      }

      this.setState({ loading: true })
      await new Promise((resolve) => setTimeout(resolve, 3000))
      try {
        await ApiInstance.post('conta', {
          doc,
          account_owner,
          account_number,
          account_agency,
          bank_code: bank_name.value,
          bank_name: bank_name.label,
        })
        const snackbarKey = this.props.enqueueSnackbar('Conta bancária atualizada com sucesso.', {
          variant: 'success',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        })
      } catch (error) {
        const snackbarKey = this.props.enqueueSnackbar('Falha ao salvar informações bancárias.', {
          variant: 'error',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        })
      }
      this.setState({ loading: false })
    }

    return (
      <Box p={2}>
        <Grid spacing={2} container>
          <Typography style={{ marginTop: 12, marginLeft: 8, marginBottom: 16 }} gutterBottom variant="h6">
            Insira abaixo os dados da conta
          </Typography>
          <Grid item md={12} xs={12}>
            <TextField fullWidth type="text" variant="outlined" label="Documento" name="doc" value={doc} disabled />
          </Grid>

          <Grid item md={12} xs={12}>
            <TextField fullWidth type="text" variant="outlined" label="Titular da conta" name="account_owner" value={account_owner || ''} onChange={handleChange} />
          </Grid>

          <Grid item md={12} xs={12}>
            <Autocomplete
              disableClearable
              fullWidth
              name="bank_name"
              variant="outlined"
              placeholder="Banco"
              options={BankList}
              noOptionsText="Banco não encontrado"
              onChange={(event, newValue) => {
                this.setState({ bank_name: newValue })
              }}
              value={bank_name || { value: '', label: '' }}
              type="text"
              getOptionLabel={(option) => (option.value ? option.value + ' - ' + option.label : '')}
              renderInput={(params) => <TextField {...params} label="Nome do banco" variant="outlined" />}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <TextField fullWidth type="text" variant="outlined" label="Agência da conta" name="account_agency" value={account_agency || ''} onChange={handleChange} />
          </Grid>

          <Grid item md={6} xs={12}>
            <TextField fullWidth type="text" variant="outlined" label="Número da conta" name="account_number" value={account_number || ''} onChange={handleChange} />
          </Grid>

          <Grid item md={12} xs={12} style={{ display: 'flex', position: 'relative', justifyContent: 'flex-end' }}>
            <Link to="/">
              <Button style={{ marginRight: 8 }} size="large" variant="outlined" color="primary" disabled={loading}>
                Voltar
              </Button>
            </Link>
            <Box style={{ position: 'relative' }}>
              <Button size="large" variant="contained" color="primary" disabled={loading} onClick={saveAccount}>
                Salvar
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: -12,
                    marginLeft: -12,
                  }}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    )
  }
}
export default withSnackbar(ContaBancaria)
