import { Box, Grid, Typography } from '@material-ui/core'
import React from 'react'
import Logo from '../static/images/logo.png'

class TransactionResume extends React.Component {
  print() {
    window.print()
  }

  render() {
    let { purchase, plan_id } = this.props

    const total_value = purchase?.corresponsible_purchase?.value
      ? purchase.value + purchase.corresponsible_purchase.value
      : purchase.value

    return (
      <Box className="print-area-hidden" style={{ minHeight: '100vh' }} p={3}>
        <div className="print-logo">
          <img src={Logo} alt="ucred-logo" style={{ width: 250 }}></img>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h6" color="primary">
                  Dados da transação
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>Serviço contratado</Typography>
                <Typography variant="body2" color="textSecondary">
                  {purchase.service.name}
                </Typography>
              </Grid>

              {!!purchase.service.cover_value && (Number(plan_id) === 2 || Number(plan_id) === 4) && (
                <Grid item xs={12} sm={6} md={4}>
                  <Typography>Cobertura Ucred</Typography>
                  <Typography variant="body2" color="textSecondary">
                    {purchase.service.cover_value} vezes
                  </Typography>
                </Grid>
              )}

              {!!(Number(plan_id) === 2 || Number(plan_id) === 4) && (
                <Grid item xs={12} sm={6} md={4}>
                  <Typography>Taxa locatário</Typography>
                  <Typography variant="body2" color="textSecondary">
                    {Number(purchase.service.over_value) +
                      Number(purchase.service.administration_fee)}{' '}
                    %
                  </Typography>
                </Grid>
              )}

              {!!Number(plan_id) === 1 && (
                <Grid item xs={12} sm={6} md={4}>
                  <Typography>Taxa administrativa</Typography>
                  <Typography variant="body2" color="textSecondary">
                    {Number(purchase.service.administration_fee)} %
                  </Typography>
                </Grid>
              )}

              {!!purchase.adhesion && (
                <Grid item xs={12} sm={6} md={4}>
                  <Typography>Taxa de adesão</Typography>
                  <Typography variant="body2" color="textSecondary">
                    R$ {Number(purchase.adhesion.value).toFixed(2)}
                  </Typography>
                </Grid>
              )}

              <Grid item xs={12} sm={6} md={4}>
                <Typography>Valor avalizado</Typography>
                <Typography variant="body2" color="textSecondary">
                  R$ {Number(total_value).toFixed(2)}
                </Typography>
              </Grid>

              {/**(!this.props.confirmarEmail || this.props.authUser.company_id === 5) && (
                <Grid item xs={12} sm={6} md={4}>
                  <Typography>Valor parcela</Typography>
                  <Typography variant="body2" color="textSecondary">
                    {purchase.parcel} x R$ {(Number(finalValue || 1) / Number(purchase.parcel)).toFixed(2)}
                  </Typography>
                </Grid>
              )*/}

              {purchase.adhesion && (
                <>
                  {purchase.adhesion.parcel && (
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography>Taxa de adesão</Typography>
                      {!!purchase.adhesion.parcel && (
                        <Typography variant="body2" color="textSecondary">
                          {purchase.adhesion.parcel} x R${' '}
                          {Number(purchase.adhesion.value / purchase.adhesion.parcel).toFixed(2)}
                        </Typography>
                      )}
                      {!purchase.adhesion.parcel && <Typography></Typography>}
                    </Grid>
                  )}
                </>
              )}

              <Grid item xs={12} sm={6} md={4}>
                <Typography>Valor total a ser cobrado no cartão</Typography>
                <Typography variant="body2" color="textSecondary">
                  R${' '}
                  {(purchase.adhesion
                    ? Number(total_value) + Number(purchase.adhesion.value)
                    : Number(total_value)
                  ).toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h6" color="primary">
                  Dados do imóvel que deseja locar
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography>CEP</Typography>
                <Typography variant="body2" color="textSecondary">
                  {purchase.cep}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography>Endereço completo</Typography>
                <Typography variant="body2" color="textSecondary">
                  {purchase.address}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography>Cidade</Typography>
                <Typography variant="body2" color="textSecondary">
                  {purchase.city}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} className="print-same-line">
            <Grid item xs={12}>
              {purchase.client && (
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="h6" color="primary">
                      Dados do locatário
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>Nome</Typography>
                    <Typography variant="body2" color="textSecondary">
                      {purchase.client.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography>CPF</Typography>
                    <Typography variant="body2" color="textSecondary">
                      {purchase.client.cpf}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography>Data de nascimento</Typography>
                    <Typography variant="body2" color="textSecondary">
                      {purchase.client.birthday}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography>Telefone celular</Typography>
                    <Typography variant="body2" color="textSecondary">
                      {purchase.client.telephone_1}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography>E-mail</Typography>
                    <Typography variant="body2" color="textSecondary">
                      {purchase.client.email}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>

            {!!purchase.has_responsible && (
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="h6" color="primary">
                      Dados do corresponsável
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography>Nome</Typography>
                    <Typography variant="body2" color="textSecondary">
                      {purchase.corresponsible.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography>CPF</Typography>
                    <Typography variant="body2" color="textSecondary">
                      {purchase.corresponsible.cpf}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography>Data de nascimento</Typography>
                    <Typography variant="body2" color="textSecondary">
                      {purchase.corresponsible.birthday}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography>Telefone celular</Typography>
                    <Typography variant="body2" color="textSecondary">
                      {purchase.corresponsible.telephone_1}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography>E-mail</Typography>
                    <Typography variant="body2" color="textSecondary">
                      {purchase.corresponsible.email}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
    )
  }
}

export default TransactionResume
