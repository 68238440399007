import { Chip } from '@material-ui/core'
import MaterialTable from 'material-table'
import moment from 'moment'
import 'moment/locale/pt-br'
import React from 'react'
import styles from '../../styles/defaultStyles'
import tableIcons from '../../styles/tableIcons'

export default class ProductsTable extends React.Component {
  productsTable = () => {
    const { products } = this.props
    let productArray = []

    products.map((_p) => {
      let productObj = {}
      productObj.value = _p.value
      productObj.parcel = _p.parcel || '-'
      productObj.parcel_value = Number(_p.parcel) !== 0 ? _p.value / _p.parcel : _p.value

      switch (_p.status) {
        case 1:
          productObj.status = 'Pendente'
          productObj.status_column = <Chip style={{ color: '#fff', background: styles.info }} size="small" label="Pendente" />
          break
        case 2:
          productObj.status = 'Aguardando pagamento'
          productObj.status_column = <Chip style={{ color: '#fff', background: styles.info }} size="small" label="Aguardando pagamento" />
          break
        case 3:
          productObj.status = 'Concluída'
          productObj.status_column = <Chip style={{ color: '#fff', background: styles.success }} size="small" label="Concluída" />
          break
        case 4:
          productObj.status = 'Cancelada'
          productObj.status_column = <Chip style={{ color: '#fff', background: styles.critical }} size="small" label="Cancelada" />
          break
        case 5:
          productObj.status = 'Falha'
          productObj.status_column = <Chip style={{ color: '#fff', background: styles.error }} size="small" label="Falha" />
          break
        case 6:
          productObj.status = 'Pago'
          productObj.status_column = <Chip style={{ color: '#fff', background: styles.success }} size="small" label="Pago" />
          break
        case 7:
          productObj.status = 'Pago parcialmente'
          productObj.status_column = <Chip style={{ color: '#fff', background: styles.info }} size="small" label="Pago parcialmente" />
          break
        case 8:
          productObj.status = 'Pendente contrato'
          productObj.status_column = <Chip style={{ color: '#fff', background: styles.info }} size="small" label="Pendente contrato" />
          break
        case 9:
          productObj.status = 'Pendente aprovação Ucred'
          productObj.status_column = <Chip style={{ color: '#fff', background: styles.info }} size="small" label="Pendente aprovação Ucred" />
          break
        default:
          break
      }

      productObj.payment_date = _p.created_at
      productObj.description = _p.product_id === 1 ? 'Taxa de adesão' : _p.name

      productArray.push(productObj)

      return null
    })

    return productArray
  }

  render() {
    const noOverflow = {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      width: 300,
      maxWidth: 300,
      textOverflow: 'ellipsis',
    }
    return (
      <MaterialTable
        isLoading={false}
        columns={[
          {
            title: 'Valor',
            field: 'value',
            type: 'currency',
            align: 'center',
            export: true,
            currencySetting: { locale: 'pt-BR', currencyCode: 'BRL', minimumFractionDigits: 2, maximumFractionDigits: 2 },
          },
          { title: 'Quantidade de parcelas', field: 'parcel', type: 'numeric', align: 'center' },
          {
            title: 'Valor das parcelas',
            field: 'parcel_value',
            type: 'currency',
            align: 'center',
            currencySetting: { locale: 'pt-BR', currencyCode: 'BRL', minimumFractionDigits: 2, maximumFractionDigits: 2 },
          },
          { title: 'Status', field: 'status', align: 'center', cellStyle: noOverflow, export: true, hidden: true, searchable: true },
          { title: 'Status da transação', align: 'center', field: 'status_column', cellStyle: noOverflow, export: false, searchable: false },
          { title: 'Data do Pagamento', field: 'payment_date', align: 'center', type: 'date', defaultSort: 'desc' },
          { title: 'Descrição', field: 'description', align: 'center', type: 'string' },
        ]}
        icons={tableIcons}
        components={{ Container: (props) => <div id="mtable-container" {...props}></div> }}
        data={this.productsTable()}
        title="Relatório de adesão"
        options={{
          pageSize: 15,
          pageSizeOptions: [15, 50, 100],
          tableLayout: 'auto',
          showTitle: true,
          padding: 'dense',
          exportAllData: true,
          exportFileName: 'Relatório Ucred (Adesão) - ' + moment().format('DD-MM-YYYY H:mm:ss'),
          exportButton: true,
          actionsColumnIndex: 0,
        }}
        localization={{
          header: {
            actions: 'Ações',
          },
          body: {
            emptyDataSourceMessage: 'Nenhum registro para exibir',
          },
          toolbar: {
            exportTitle: 'Exportar',
            searchTooltip: 'Pesquisar',
            searchPlaceholder: 'Pesquisar',
            exportCSVName: 'Exportar em CSV',
            exportPDFName: 'Exportar em PDF',
          },
          pagination: {
            labelRowsSelect: 'linhas',
            labelDisplayedRows: '{from}-{to} de {count}',
            firstTooltip: 'Primeira página',
            previousTooltip: 'Página anterior',
            nextTooltip: 'Próxima página',
            lastTooltip: 'Última página',
          },
        }}
      />
    )
  }
}
