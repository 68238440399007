import React from 'react'
import MaskedInput from 'react-maskedinput'
import _ from 'lodash'

const Input = (props) => {
  let lastValueLength = 1
  let iconType = null
  const formatMoney = (n, c, d = ',', t = '.') => {
    c = isNaN((c = Math.abs(c))) ? 2 : c
    const s = n < 0 ? '-' : ''
    const i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c))))
    let j = i.length
    j = i.length > 3 ? j % 3 : 0

    const result =
      s +
      (j ? i.substr(0, j) + t : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) +
      (c
        ? d +
          Math.abs(n - i)
            .toFixed(c)
            .slice(2)
        : '')
    return result
  }
  const onChangeCurrency = _.debounce((id, value = '0.00') => {
    if (value.length > lastValueLength) {
      value = value.replace(/\D/g, '')
      value = value.substr(0, value.length - 2) + '.' + value.substr(-2)
    } else {
      if (value.length === 1) {
        // user is replacing the whole string > he's typing cents
        value = '00.0' + value
      }
      value = value.replace(/\D/g, '')
      value = value.substr(0, value.length - 2) + '.' + value.substr(-2)
    }
    value = formatMoney(value)
    lastValueLength = value.length
    props.onChange({ target: { id, value }, currentTarget: { id, value } })
  }, 1)
  const _onChangeCurrency = (e) => {
    onChangeCurrency(e.target.id, e.target.value)
  }

  const onChange = props.type === 'currency' ? _onChangeCurrency : props.onChange

  switch (props.iconType) {
    case 'password':
      iconType = '*'
      break
    case 'email':
      iconType = '@'
      break
    case 'money':
      iconType = 'R$'
      break
    case 'percent':
      iconType = '%'
      break
    case 'number':
      iconType = '[0-9]'
      break
    case 'vezes':
      iconType = 'X'
      break
    default:
      iconType = '[A-Z]'
      break
  }
  if (props.mask) {
    return (
      <div className="input">
        <div className="input__field-container">
          <MaskedInput
            className="input__field"
            type={props.type}
            placeholder={props.placeholder}
            onChange={onChange}
            value={props.value}
            id={props.id}
            disabled={props.disabled}
            mask={props.mask}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            maxLength={props.maxLength}
          />
          <div className="input_field-icon">
            <span>{iconType}</span>
          </div>
        </div>
        <label className="input__label">
          {props.labelDescription}
          {props.labelIcon && (
            <div className="label-icon">
              ?<span className="label-icon-message">{props.labelIconMessage}</span>
            </div>
          )}
        </label>
      </div>
    )
  }
  return (
    <div className="input">
      <div className="input__field-container">
        <input
          className="input__field"
          type={props.type}
          placeholder={props.placeholder}
          onChange={onChange}
          value={props.value}
          id={props.id}
          disabled={props.disabled}
          onFocus={props.onFocus}
          maxLength={props.maxLength}
          onBlur={props.onBlur}
        />
        <div className="input_field-icon">
          <span>{iconType}</span>
        </div>
      </div>
      <label className="input__label">
        {props.labelDescription}{' '}
        {props.labelIcon && (
          <div className="label-icon">
            ?<span className="label-icon-message">{props.labelIconMessage}</span>
          </div>
        )}
      </label>
    </div>
  )
}

export default Input
