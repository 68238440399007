export const FILL_PURCHASE = 'FILL_PURCHASE'
export const FILL_CONTRACT = 'FILL_CONTRACT'
export const FILL_LOGIN = 'FILL_LOGIN'
export const FILL_LOGOUT = 'FILL_LOGOUT'
export const FILL_CLIENT = 'FILL_CLIENT'
export const FILL_TICKET = 'FILL_TICKET'
export const FILL_TRIAL = 'FILL_TRIAL'
export const FILL_PURCHASE_PROPRIA = 'FILL_PURCHASE_PROPRIA'
export const FILL_PURCHASE_PROPRIA_CANCELLED = 'FILL_PURCHASE_PROPRIA_CANCELLED'
export const FILL_PURCHASE_UCRED = 'FILL_PURCHASE_UCRED'
export const FILL_PURCHASE_UCRED_CANCELLED = 'FILL_PURCHASE_UCRED_CANCELLED'
export const FILL_TICKET_PROPRIA = 'FILL_TICKET_PROPRIA'
export const FILL_TICKET_UCRED = 'FILL_TICKET_UCRED'
export const FILL_TOTAL_UCRED = 'FILL_TOTAL_UCRED'
export const FILL_TOTAL_PROPRIA = 'FILL_TOTAL_PROPRIA'
export const FILL_RECUSED_UCRED = 'FILL_RECUSED_UCRED'
export const FILL_RECUSED_PROPRIA = 'FILL_RECUSED_PROPRIA'
export const FILL_CONTRACT_SIGNED = 'FILL_CONTRACT_SIGNED'
export const FILL_CONTRACT_NOT_SIGNED = 'FILL_CONTRACT_NOT_SIGNED'
export const FILL_COMPANY = 'FILL_COMPANY'
export const FILL_ACTIVE_COMPANY = 'FILL_ACTIVE_COMPANY'
export const FILL_SINISTRO_PROPRIA = 'FILL_SINISTRO_PROPRIA'
export const FILL_SINISTRO_UCRED = 'FILL_SINISTRO_UCRED'

export const fillPurchase = (value) => {
    return {
        type: FILL_PURCHASE,
        value
    }
}

export const fillContract = value => {
    return {
        type: FILL_CONTRACT,
        value
    }
}

export const fillLogin = value => {
    return {
        type: FILL_LOGIN,
        value
    }
}

export const fillLogout = value => {
    return {
        type: FILL_LOGOUT,
        value
    }
}

export const fillClient = value => {
    return {
        type: FILL_CLIENT,
        value
    }
}

export const fillTicket = value => {
    return {
        type: FILL_TICKET,
        value
    }
}

export const fillTrial = value => {
    return {
        type: FILL_TRIAL,
        value
    }
}

export const fillPurchasePropria = value => {
    return {
        type: FILL_PURCHASE_PROPRIA,
        value
    }
}

export const fillPurchasePropriaCancelled = value => {
    return {
        type: FILL_PURCHASE_PROPRIA_CANCELLED,
        value
    }
}

export const fillPurchaseUcred = value => {
    return {
        type: FILL_PURCHASE_UCRED,
        value
    }
}

export const fillPurchaseUcredCancelled = value => {
    return {
        type: FILL_PURCHASE_UCRED_CANCELLED,
        value
    }
}

export const fillTicketPropria = value => {
    return {
        type: FILL_TICKET_PROPRIA,
        value
    }
}

export const fillTicketUcred = value => {
    return {
        type: FILL_TICKET_UCRED,
        value
    }
}

export const fillTotalUcred = value => {
    return {
        type: FILL_TOTAL_UCRED,
        value
    }
}

export const fillTotalPropria = value => {
    return {
        type: FILL_TOTAL_PROPRIA,
        value
    }
}

export const fillRecusedUcred = value => {
    return {
        type: FILL_RECUSED_UCRED,
        value
    }
}

export const fillRecusedPropria = value => {
    return {
        type: FILL_RECUSED_PROPRIA,
        value
    }
}

export const fillContractSigned = value => {
    return {
        type: FILL_CONTRACT_SIGNED,
        value
    }
}

export const fillContractNotSigned = value => {
    return {
        type: FILL_CONTRACT_NOT_SIGNED,
        value
    }
}

export const fillCompany = value => {
    return {
        type: FILL_COMPANY,
        value
    }
}

export const fillActiveCompany = value => {
    return {
        type: FILL_ACTIVE_COMPANY,
        value
    }
}

export const fillSinistroPropria = value => {
    return {
        type: FILL_SINISTRO_PROPRIA,
        value
    }
}

export const fillSinistroUcred = value => {
    return {
        type: FILL_SINISTRO_UCRED,
        value
    }
}