import { forwardRef, ForwardRefRenderFunction } from 'react'
import NumberFormat from 'react-number-format'
import { FieldErrors } from 'react-hook-form'

import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  InputGroup,
  Input as ChackraInput,
  InputProps as ChakraInputProps,
} from '@chakra-ui/react'

interface InputProps extends ChakraInputProps {
  leftElement?: JSX.Element
  rightElement?: JSX.Element
  name: string
  label: string
  error?: FieldErrors | null
  onValueChange?: ({ floatValue }: { floatValue: number }) => void
}

type InputRef = {
  focus: () => void
}

const BaseCurrencyInput: ForwardRefRenderFunction<InputRef, InputProps> = (
  { leftElement, rightElement, name, label, error, color = 'gray.700', prefix = 'R$ ', ...rest },
  ref,
) => {
  return (
    <>
      <FormControl isInvalid={!!error}>
        {!!label && (
          <FormLabel
            color="gray.500"
            fontSize="xs"
            fontWeight="semibold"
            style={{ textTransform: 'uppercase' }}
            htmlFor={name}
          >
            {label}
          </FormLabel>
        )}
        <InputGroup>
          {leftElement}
          <ChackraInput
            as={NumberFormat}
            name={name}
            color={color}
            prefix={prefix}
            decimalScale={2}
            decimalSeparator=","
            thousandSeparator="."
            allowedDecimalSeparators={[',']}
            allowLeadingZeros={false}
            getInputRef={ref}
            {...rest}
          />
          {rightElement}
        </InputGroup>
        {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
      </FormControl>
    </>
  )
}

export const CurrencyInput = forwardRef(BaseCurrencyInput)
