import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Collapse,
  Grid,
  Slide,
  Typography,
  ClickAwayListener,
} from '@material-ui/core'
import {
  AccountBalanceRounded,
  ArchiveRounded,
  CachedRounded,
  ChevronRightRounded,
  EditRounded,
  FiberManualRecord,
  HomeRounded,
  ListAltRounded,
  MoneyOff,
  PeopleRounded,
  SchoolRounded,
} from '@material-ui/icons'
import { useState } from 'react'
import { Link } from 'react-router-dom'

const Navbar = props => {
  const [bankAccounts, setBankAccounts] = useState(false)

  const { open, company, user, setOpen } = props

  return (
    <>
      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={() => setOpen(false)}
      >
        <Slide
          id="navbar"
          className="print-hidden"
          direction="right"
          in={open}
          style={{ background: '#044c94' }}
        >
          <Grid>
            <Box
              className="wrapper"
              style={{ height: '100%', overflowY: 'auto', paddingBottom: 90 }}
            >
              <ButtonBase style={{ width: '100%' }} className="user">
                <Avatar alt={'Avatar'} />
                <Box style={{ maxWidth: '90%' }}>
                  <Typography align="left" className="name" style={{ color: '#fff' }}>
                    {user.name}
                  </Typography>
                  <Typography
                    align="left"
                    className="company"
                    color="textSecondary"
                    style={{ color: '#fff' }}
                  >
                    {company.name}
                  </Typography>
                </Box>
              </ButtonBase>

              <Link to="/" onClick={props.refresh}>
                <Button
                  color={window.location.pathname !== '/' ? 'default' : 'primary'}
                  className="menu"
                  style={{ marginTop: 16, color: '#fff', background: '#0462bf' }}
                >
                  <HomeRounded style={{ marginRight: 16 }}></HomeRounded>
                  <Typography color={'textSecondary'} style={{ color: '#fff' }}>
                    Início
                  </Typography>
                </Button>
              </Link>

              <Link to="/acompanhamento" onClick={props.refresh}>
                <Button
                  color={window.location.pathname !== '/acompanhamento' ? 'default' : 'primary'}
                  className="menu"
                  style={{ color: '#fff', background: '#0462bf' }}
                >
                  <ListAltRounded style={{ marginRight: 16 }}></ListAltRounded>
                  <Typography color={'textSecondary'} style={{ color: '#fff' }}>
                    Acompanhamento
                  </Typography>
                </Button>
              </Link>

              <Button
                className="menu"
                onClick={() => setBankAccounts(!bankAccounts)}
                color={
                  window.location.pathname !== '/contas/conta-corporativa' &&
                  window.location.pathname !== '/contas/conta-pessoal'
                    ? 'default'
                    : 'primary'
                }
                style={{ color: '#fff', background: '#0462bf' }}
              >
                <AccountBalanceRounded style={{ marginRight: 16 }}></AccountBalanceRounded>
                <Typography color="textSecondary" style={{ color: '#fff' }}>
                  Contas bancárias
                </Typography>
                <ChevronRightRounded
                  style={{
                    marginLeft: 'auto',
                    transform: bankAccounts ? `rotate(90deg)` : '',
                    transition: '0.2s',
                  }}
                ></ChevronRightRounded>
              </Button>
              <Collapse in={bankAccounts} timeout="auto" unmountOnExit>
                <div className="submenu">
                  <Link
                    style={{ width: '100%' }}
                    to={{ pathname: '/contas/conta-pessoal', state: { tab: 0 } }}
                    onClick={() => {
                      setOpen(false)
                      props.refresh()
                    }}
                  >
                    <Button className="item" style={{ background: '#0462bf' }}>
                      <FiberManualRecord
                        style={{ marginRight: 16, width: 12, color: '#fff' }}
                      ></FiberManualRecord>
                      <Typography color={'textSecondary'} style={{ color: '#fff' }}>
                        Pessoal
                      </Typography>
                    </Button>
                  </Link>

                  {user.isMainUser && (
                    <Link
                      style={{ width: '100%' }}
                      to={{ pathname: '/contas/conta-corporativa', state: { tab: 1 } }}
                      onClick={() => {
                        setOpen(false)
                        props.refresh()
                      }}
                    >
                      <Button className="item" style={{ background: '#0462bf' }}>
                        <FiberManualRecord
                          style={{ marginRight: 16, width: 12, color: '#fff' }}
                        ></FiberManualRecord>
                        <Typography color={'textSecondary'} style={{ color: '#fff' }}>
                          Corporativa
                        </Typography>
                      </Button>
                    </Link>
                  )}
                </div>
              </Collapse>

              <Link to="/usuarios" onClick={props.refresh}>
                <Button
                  className="menu"
                  color={window.location.pathname !== '/usuarios' ? 'default' : 'primary'}
                  style={{ color: '#fff', background: '#0462bf' }}
                >
                  <PeopleRounded style={{ marginRight: 16 }}></PeopleRounded>
                  <Typography color="textSecondary" style={{ color: '#fff' }}>
                    Usuários
                  </Typography>
                </Button>
              </Link>

              <Link to="/ajustes-contrato" onClick={props.refresh}>
                <Button
                  className="menu"
                  color={window.location.pathname !== '/ajustes-contrato' ? 'default' : 'primary'}
                  style={{ color: '#fff', background: '#0462bf' }}
                >
                  <EditRounded style={{ marginRight: 16 }}></EditRounded>
                  <Typography color="textSecondary" style={{ color: '#fff' }}>
                    Ajustes contrato
                  </Typography>
                </Button>
              </Link>

              <Link to="/treinamento" onClick={props.refresh}>
                <Button
                  className="menu"
                  color={window.location.pathname !== '/treinamento' ? 'default' : 'primary'}
                  style={{ color: '#fff', background: '#0462bf' }}
                >
                  <SchoolRounded style={{ marginRight: 16 }}></SchoolRounded>
                  <Typography color="textSecondary" style={{ color: '#fff' }}>
                    Treinamento
                  </Typography>
                </Button>
              </Link>
              {user && user.plan_id !== 4 && user.isMainUser && (
                <Link to="/servicos" onClick={props.refresh}>
                  <Button
                    className="menu"
                    color={window.location.pathname !== '/servicos' ? 'default' : 'primary'}
                    style={{ color: '#fff', background: '#0462bf' }}
                  >
                    <ArchiveRounded style={{ marginRight: 16 }}></ArchiveRounded>
                    <Typography color="textSecondary" style={{ color: '#fff' }}>
                      Serviços
                    </Typography>
                  </Button>
                </Link>
              )}

              <Link to="/renovacao" onClick={props.refresh}>
                <Button
                  className="menu"
                  color={window.location.pathname !== '/renovacao' ? 'default' : 'primary'}
                  style={{ color: '#fff', background: '#0462bf' }}
                >
                  <CachedRounded style={{ marginRight: 16 }}></CachedRounded>
                  <Typography color="textSecondary" style={{ color: '#fff' }}>
                    Renovação
                  </Typography>
                </Button>
              </Link>

              <Link to="/sinistros" onClick={props.refresh}>
                <Button
                  className="menu"
                  color={window.location.pathname !== '/sinistros' ? 'default' : 'primary'}
                  style={{ color: '#fff', background: '#0462bf' }}
                >
                  <MoneyOff style={{ marginRight: 16 }}></MoneyOff>
                  <Typography color="textSecondary" style={{ color: '#fff' }}>
                    Sinistros
                  </Typography>
                </Button>
              </Link>
            </Box>
          </Grid>
        </Slide>
      </ClickAwayListener>
    </>
  )
}

export default Navbar
