import { AspectRatio, Box, Button, Flex, SimpleGrid, Image, Text } from '@chakra-ui/react'
import { Icon } from '@chakra-ui/react'
import { RiDownloadFill } from "react-icons/ri";

export const InvoiceDocuments = ({ row }) => (
  <SimpleGrid p={6} spacing={2} boxShadow="md" borderRadius={6} minChildWidth="232px">
    {row.values.documents &&
      row.values.documents.map((document) => {
        const isPDF = document.name.endsWith('.pdf')
        return (
          <Box key={document.id} p={4} w="fit-content" boxShadow="xs" borderRadius={8}>
            <AspectRatio w="200px" h="282px" ratio={9 / 16}>
              {isPDF ? (
                <iframe frameBorder="0" type="application/pdf" src={document.url} alt="pdf" width="200" />
              ) : (
                <Image boxSize="200px" objectFit="cover" src={document.url} alt="documento" />
              )}
            </AspectRatio>

            <Flex mt={4} justify="space-between" align="center">
              <Button
                justifyContent="space-between"
                w="100%"
                size="sm"
                rightIcon={<Icon as={RiDownloadFill} boxSize="4" />}
                colorScheme="blue"
                variant="ghost"
                onClick={() => {
                  window.open(`${document.url}`, '_blank')
                }}
              >
                <Text maxW="150px" isTruncated>
                  {document.name}
                </Text>
              </Button>
            </Flex>
          </Box>
        )
      })}
  </SimpleGrid>
)
