export const FILL_FIELD = 'FILL_FIELD'
export const REFRESH = 'REFRESH'

export const fillField = (field, value) => {
    return {
        type: FILL_FIELD,
        field,
        value
    }
}
export const refresh = () => {
    return {
        type: REFRESH
    }
}