import { Button, Divider, FormControl, InputAdornment, MenuItem, Select, TextField, Tooltip, Typography } from '@material-ui/core'
import { Help } from '@material-ui/icons'
import moment from 'moment'
import React from 'react'
import ReactCreditCard from 'react-credit-cards'
import MaskedInput from 'react-input-mask'
import ApiInstance from '../../services/ApiInstance'
import vindiErrorMessage from '../../functions/vindiErrorMessage'
import BankSlipAlt from '../../static/images/bank-slip-alt.png'
import { maskMoney, moneyToNumber } from '../../util/money'

export default class Hybrid extends React.Component {
  constructor(props) {
    super(props)
    const { adhesion, purchase, bank_slip_fee } = props.parent.state

    let maxCreditCard = adhesion[0] ? moneyToNumber(purchase.value) - moneyToNumber(adhesion[0].value) : moneyToNumber(purchase.value) - 30
    let maxBankSlip = moneyToNumber(purchase.value) - 30 + bank_slip_fee

    let minBankSlip = adhesion[0] ? moneyToNumber(adhesion[0].value) + bank_slip_fee : 30 + bank_slip_fee

    this.state = {
      twoCards: false,
      loading: false,
      totalValue: 0,
      maxCreditCard: maxCreditCard,
      minCreditCard: 30,
      minBankSlip: minBankSlip,
      maxBankSlip: maxBankSlip,
      credit_card: {
        name: '',
        number: '',
        expiry: '',
        cvv: '',
        card_maturity: '',
        focused: false,
        value: 30,
        parcel: 12,
        errors: {
          number: false,
          expiry: false,
          cvv: false,
          card_maturity: false,
        },
      },
      bank_slip: {
        value: 30,
        parcel: 1,
      },
      purchaseParcel: {},
      parcelOptions: [],
    }
  }

  getParcelOptions(value, correspValue = 0) {
    let count = 1
    let parcels = []

    while (count < 13) {
      parcels.push({
        label: `${count}X de R$ ${Number(value / count)
          .toFixed(2)
          .replace('.', ',')}`,

        label_corresp: `${count}X de R$ ${Number(correspValue / count)
          .toFixed(2)
          .replace('.', ',')}`,
        value: value / count,
        valueCorresp: correspValue / count,
        quantity: count,
      })

      count++
    }
    return parcels
  }

  componentDidMount() {
    const { credit_card, bank_slip } = this.state
    const { adhesion, purchase, bank_slip_fee, card } = this.props.parent.state

    const total_value = adhesion[0] ? moneyToNumber(purchase.value) + moneyToNumber(adhesion[0].value) : moneyToNumber(purchase.value)

    let min = adhesion[0] ? moneyToNumber(adhesion[0].value) + bank_slip_fee : 30 + bank_slip_fee

    let parcels = this.getParcelOptions(total_value + bank_slip_fee)

    parcels = this.getParcelOptions(total_value + bank_slip_fee - min)

    return this.setState({
      credit_card: { ...credit_card, value: total_value + bank_slip_fee - min, name: card.name },
      bank_slip: { ...bank_slip, value: min },
      parcelOptions: parcels,
      totalValue: total_value,
    })
  }

  render() {
    const { credit_card, bank_slip, parcelOptions, loading, totalValue } = this.state
    const { adhesion, bank_slip_fee, hash } = this.props.parent.state

    const handleChangeCreditCard = async (event) => {
      let { name, value } = event.target
      let credit_card = this.state.credit_card
      let dateIsValid = await this.props.parent.validateDate(value)
      if (name === 'number') credit_card.errors.number = false

      if (name === 'expiry' && dateIsValid) {
        credit_card.errors.expiry = true
        this.setState({ credit_card })
      } else if (name === 'expiry' && !dateIsValid) {
        credit_card.errors.expiry = false
        this.setState({ credit_card })
      }

      if (name === 'card_maturity' && value > 30) {
        value = 30
      }

      if (name === 'cvv') credit_card.errors.cvv = false

      this.setState({ credit_card: { ...credit_card, [name]: value } })
    }

    const handleChangeCardValue = (value) => {
      let max = adhesion[0] ? totalValue - moneyToNumber(adhesion[0].value) : totalValue - 30

      value = moneyToNumber(value)

      if (value > max) {
        let parcelOptions = this.getParcelOptions(max)
        this.setState({
          credit_card: { ...credit_card, value: max },
          bank_slip: { ...bank_slip, value: totalValue - max + bank_slip_fee },
          parcelOptions: parcelOptions,
          purchaseParcel: parcelOptions ? parcelOptions[parcelOptions.length - 1] : {},
        })
        return true
      }

      let parcelOptions = this.getParcelOptions(value)
      this.setState({
        credit_card: { ...credit_card, value: value },
        bank_slip: { ...bank_slip, value: totalValue - value + bank_slip_fee },
        parcelOptions: parcelOptions,
        purchaseParcel: parcelOptions ? parcelOptions[parcelOptions.length - 1] : {},
      })
    }

    const handleBlurCreditCard = (value) => {
      let min = 30

      value = moneyToNumber(value)

      if (value < min) {
        let parcelOptions = this.getParcelOptions(min)
        return this.setState({
          credit_card: { ...credit_card, value: min },
          bank_slip: { ...bank_slip, value: totalValue - min + bank_slip_fee },
          parcelOptions: parcelOptions,
          purchaseParcel: parcelOptions ? parcelOptions[parcelOptions.length - 1] : {},
        })
      }

      let parcelOptions = this.getParcelOptions(value)
      this.setState({
        credit_card: { ...credit_card, value: value },
        bank_slip: { ...bank_slip, value: totalValue - value + bank_slip_fee },
        parcelOptions: parcelOptions,
        purchaseParcel: parcelOptions ? parcelOptions[parcelOptions.length - 1] : {},
      })
    }

    const handleChangeBankSlipValue = (value) => {
      let max = totalValue - 30 + bank_slip_fee

      value = moneyToNumber(value)

      if (value > max) {
        let parcelOptions = this.getParcelOptions(totalValue + bank_slip_fee - max)
        return this.setState({
          credit_card: { ...credit_card, value: totalValue + bank_slip_fee - max },
          bank_slip: { ...bank_slip, value: max },
          parcelOptions: parcelOptions,
          purchaseParcel: parcelOptions ? parcelOptions[parcelOptions.length - 1] : {},
        })
      }

      let parcelOptions = this.getParcelOptions(totalValue + bank_slip_fee - value)

      this.setState({
        credit_card: { ...credit_card, value: totalValue + bank_slip_fee - value },
        bank_slip: { ...bank_slip, value: value },
        parcelOptions: parcelOptions,
        purchaseParcel: parcelOptions ? parcelOptions[parcelOptions.length - 1] : {},
      })
    }

    const handleBlurBankSlip = (value) => {
      let min = adhesion[0] ? moneyToNumber(adhesion[0].value) + bank_slip_fee : 30 + bank_slip_fee

      value = moneyToNumber(value)

      let parcelOptions = this.getParcelOptions(totalValue + bank_slip_fee - value)

      if (value < min) {
        parcelOptions = this.getParcelOptions(totalValue + bank_slip_fee - min)
        return this.setState({
          credit_card: { ...credit_card, value: totalValue + bank_slip_fee - min },
          bank_slip: { ...bank_slip, value: min },
          parcelOptions: parcelOptions,
          purchaseParcel: parcelOptions ? parcelOptions[parcelOptions.length - 1] : {},
        })
      }

      this.setState({
        credit_card: { ...credit_card, value: totalValue + bank_slip_fee - value },
        bank_slip: { ...bank_slip, value: value },
        parcelOptions: parcelOptions,
        purchaseParcel: parcelOptions ? parcelOptions[parcelOptions.length - 1] : {},
      })
    }

    const createHybridPayment = async () => {
      this.props.parent.setState({ loading: true })
      let expiry = moment(credit_card.expiry, 'MM/YY').format('MM/YYYY')

      if (credit_card.number.length < 19) {
        this.props.parent.setState({ loading: false })
        return this.setState({ credit_card: { ...credit_card, errors: { ...credit_card.errors, number: true } } })
      }

      await ApiInstance.post('hybrid', {
        credit_card: { ...credit_card, expiry },
        bank_slip,
        hash,
      })
        .then(() => {
          this.props.parent.setState({
            snackbar: { message: 'Transação paga com sucesso!', severity: 'success', duration: 6000, open: true },
            loading: false,
          })

          window.location.reload()
        })
        .catch((error) => {
          let errorMessage = error.error ? vindiErrorMessage(error.error, false) : ''
          let messageCode = error.error ? error.error.split('|')[1] : ''

          if (messageCode === 'N7') {
            this.setState({
              credit_card: { ...credit_card, errors: { ...credit_card.errors, cvv: true } },
              selected_card: 2,
              open: false,
            })
          }

          this.props.parent.setState({
            snackbar: { message: errorMessage || 'Houve um erro ao processar sua transação.', severity: 'error', open: true },
            loading: false,
          })
        })
    }

    return (
      <div id="hybrid">
        <div className="container">
          <div className="header-comp">
            <Typography variant="h1" component="h1" style={{ margin: 19 }}>
              Pagamento híbrido
            </Typography>
          </div>

          <Divider className="main-divider"></Divider>

          <div className="cards">
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <ReactCreditCard
                className="credit_card_image"
                preview={true}
                name={credit_card.name}
                number={credit_card.number}
                expiry={credit_card.expiry}
                cvc={credit_card.cvv}
                focused={credit_card.focused}
                locale={{ valid: 'Data Venc' }}
                placeholders={{ name: 'Nome do Titular' }}
              />
              <Divider className="main-divider" style={{ margin: '8px 0' }}></Divider>

              <div className="column">
                <FormControl className="form">
                  <TextField
                    disabled
                    className="venda-online-input"
                    size="small"
                    value={credit_card.name}
                    onChange={handleChangeCreditCard}
                    name="name"
                    label="Titular do cartão"
                    fullWidth
                    placeholder="João S M Silveira"
                  />

                  <MaskedInput
                    className="venda-online-input"
                    mask="9999 9999 9999 9999"
                    name="number"
                    label="Número do cartão"
                    placeholder="4444 4444 4444 4448"
                    maskChar=""
                    fullWidth
                    size="small"
                    value={credit_card.number}
                    error={credit_card.errors.number}
                    onChange={handleChangeCreditCard}
                  >
                    {(inputProps) => <TextField {...inputProps} />}
                  </MaskedInput>

                  <div style={{ display: 'flex' }}>
                    <MaskedInput
                      className="venda-online-input"
                      mask="99/99"
                      name="expiry"
                      label="Data de expiração"
                      helperText={credit_card.errors.expiry && 'A expiração deve superior a ' + moment().add(12, 'months').format('MM/YY') + '.'}
                      placeholder="12/26"
                      style={{
                        marginRight: 8,
                      }}
                      maskChar=""
                      fullWidth
                      size="small"
                      value={credit_card.expiry}
                      error={credit_card.errors.expiry}
                      onChange={handleChangeCreditCard}
                    >
                      {(inputProps) => <TextField {...inputProps} />}
                    </MaskedInput>

                    <MaskedInput
                      className="venda-online-input"
                      mask="9999"
                      name="cvv"
                      label="CVV"
                      placeholder="153"
                      maskChar=""
                      fullWidth
                      size="small"
                      value={credit_card.cvv}
                      error={credit_card.errors.cvv}
                      onChange={handleChangeCreditCard}
                      InputProps={{
                        endAdornment: (
                          <Tooltip
                            arrow
                            position="start"
                            title="O número código de segurança é representado pelos três últimos dígitos no verso do seu cartão. Em cartões American Express, o código de segurança é um número de 4 dígitos na frente do cartão."
                          >
                            <Help></Help>
                          </Tooltip>
                        ),
                      }}
                    >
                      {(inputProps) => <TextField {...inputProps} />}
                    </MaskedInput>
                  </div>
                  <MaskedInput
                    className="venda-online-input"
                    mask={'99'}
                    name="card_maturity"
                    label="Dia do vencimento da fatura"
                    placeholder="20"
                    maskChar=""
                    fullWidth
                    size="small"
                    value={credit_card.card_maturity}
                    onChange={handleChangeCreditCard}
                  >
                    {(inputProps) => <TextField {...inputProps} />}
                  </MaskedInput>
                  <Divider flexItem></Divider>

                  <TextField
                    id="payment_value_credit_card"
                    className="payment_value_credit_card"
                    label="Valor a ser pago no cartão"
                    size="small"
                    fullWidth
                    value={credit_card.value}
                    onChange={(e) => handleChangeCardValue(e.target.value)}
                    onBlur={(e) => handleBlurCreditCard(e.target.value)}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                    }}
                  />

                  <div className="item">
                    <Typography variant="caption" component="p">
                      Quantidade de parcelas
                    </Typography>
                    <Select
                      labelId="demo-simple-select-label"
                      label="Quantidade de parcelas"
                      value={credit_card.parcel}
                      fullWidth
                      onChange={(event) => this.setState({ credit_card: { ...credit_card, parcel: event.target.value } })}
                    >
                      {parcelOptions.map((parcel, index) => {
                        return (
                          <MenuItem key={index} value={index + 1}>
                            {parcel.label}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </div>
                </FormControl>
              </div>
            </div>
            <Divider flexItem orientation="vertical"></Divider>
            <div className="bank-slip-container" style={{ display: 'flex', flexDirection: 'column' }}>
              <img alt="boleto" src={BankSlipAlt} className="bank-slip-image" />

              <Divider className="main-divider" style={{ margin: '8px 0' }}></Divider>
              <div className="column">
                <div className="item">
                  <TextField
                    label="Valor a ser pago no boleto"
                    size="small"
                    fullWidth
                    value={bank_slip.value}
                    onChange={(e) => handleChangeBankSlipValue(e.target.value)}
                    onBlur={(e) => handleBlurBankSlip(e.target.value)}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                    }}
                  />
                </div>
              </div>
            </div>
            <Divider flexItem orientation="vertical"></Divider>
            <Divider className="main-divider" style={{ margin: '8px 0' }}></Divider>
            <div className="column">
              <div className="item">
                <Typography variant="body1" component="p">
                  Endereço
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {this.props.parent.state.purchase.address}
                </Typography>
              </div>
              <div className="item">
                <Typography variant="body1" component="p">
                  Cidade
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {this.props.parent.state.purchase.city}
                </Typography>
              </div>
              <div className="item">
                <Typography variant="body1" component="p">
                  CEP
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {this.props.parent.state.purchase.cep}
                </Typography>
              </div>
              {adhesion[0] && (
                <>
                  <Divider style={{ margin: '16px 0' }} />
                  <div className="item">
                    <Typography variant="body1" component="p">
                      Valor da adesão
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      {maskMoney(adhesion[0].value)}
                    </Typography>
                  </div>
                </>
              )}
              <Divider style={{ margin: '16px 0' }} />
              <div className="item">
                <Typography variant="body1" component="p" className="total_value text">
                  Valor total
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p" className="total_value value">
                  {maskMoney(credit_card.value + bank_slip.value)}
                </Typography>
              </div>
            </div>
          </div>

          <div className="next-step">
            <Button disabled={loading} color="primary" variant="contained" style={{ width: '100%' }} onClick={() => this.props.parent.setState({ step: 0 })} className="back">
              Voltar
            </Button>
            <Button disabled={loading} color="primary" variant="contained" style={{ width: '100%' }} onClick={createHybridPayment} className="next">
              Continuar
            </Button>
          </div>
        </div>
      </div>
    )
  }
}
