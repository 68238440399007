import { useState, useEffect, useCallback } from 'react'
import { Flex, TabList, Tabs, TabPanels, TabPanel, useToast, SlideFade } from '@chakra-ui/react'

import ApiInstance from '../../services/ApiInstance'
import ApiInstanceV2 from '../../services/ApiInstanceV2'

import Header from '../../layout/Header'
import { Transaction } from './Transaction'
import { Invoices } from './Invoices'
import { Tab } from './components/Tab'
import { Breadcrumb } from './components/Breadcrumb'
import { TransactionSkeleton } from './components/Skeleton/TransactionSkeleton'
import { clientApiDataMapper } from './mappers/clientApiDataMapper'
import { transactionApiDataMapper } from './mappers/transactionApiDataMapper'

import { TransactionType } from './types/transaction'
import { ResponseClientDataType } from './types/response/client'
import { ResponseTransactionDetailsType } from './types/response/transaction-details'
import { ResponsePurchaseType } from './types/response/purchase'
import { CardResume } from './components/ClientResume'

export function TransactionDetails(props: {
  location: { state: { tab: number; detail: { purchaseId: number } } }
  match: { params: { id: number } }
}) {
  const [loading, setLoading] = useState(true)
  const [transaction, setTransaction] = useState<TransactionType>({} as TransactionType)
  const [tabIndex, setTabIndex] = useState(props.location.state?.tab || 0)
  const toast = useToast()

  const clientId = props.match.params.id
  const purchaseId = props.location.state?.detail?.purchaseId

  const loadTransactionDetails = useCallback(
    async ({ tabIndex = 0 }: { tabIndex: number }) => {
      setLoading(true)
      try {
        const response = await ApiInstance.get(`cliente/${clientId}`)
        const clientDetails: ResponseClientDataType = response.data
        const transactionDetails: ResponseTransactionDetailsType = await ApiInstance.get(
          `transactionDetail/${purchaseId}`,
        )

        const clientMapped = clientApiDataMapper({ purchaseId, clientDetails, transactionDetails })
        const transactionMapped = transactionApiDataMapper({
          purchaseId,
          clientDetails,
          transactionDetails,
        })

        const resumeResponse: ResponsePurchaseType = await ApiInstanceV2.get(
          `/purchase/${purchaseId}`,
        )
        setTransaction({
          ...clientMapped,
          ...transactionMapped,
          transactionResume: resumeResponse.purchase,
        })
        setTabIndex(tabIndex)
        setLoading(false)
      } catch (err: any) {
        return toast({
          title: err.message,
          status: 'error',
          isClosable: true,
        })
      }
    },
    [clientId, purchaseId, toast],
  )

  useEffect(() => {
    loadTransactionDetails({ tabIndex: props.location.state?.tab || 0 })
  }, [props.match.params.id, loadTransactionDetails, props.location.state?.tab])

  const handleTabsChange = (index: number) => {
    setTabIndex(index)
  }

  return (
    <Flex
      h="fit-content"
      w="100%"
      justify="center"
      direction="column"
      align="center"
      mb={8}
      mt={20}
    >
      <Header />
      <Flex flexDir="column" maxW="1600px" w="100%" pr={3} pl={3}>
        <Breadcrumb />
        {loading ? (
          <TransactionSkeleton />
        ) : (
          <>
            <CardResume transaction={transaction} />

            <Tabs
              index={tabIndex}
              onChange={handleTabsChange}
              variant="unstyled"
              size="sm"
              align="end"
            >
              <TabList mt="-30px" mb="30px" mr={6}>
                <Tab onClick={() => setTabIndex(0)} title="TRANSAÇÃO" />
                <Tab onClick={() => setTabIndex(1)} title="SINISTROS" />
              </TabList>
              <TabPanels>
                <TabPanel p={0}>
                  <SlideFade in={tabIndex === 0} offsetY={0} offsetX="-25px">
                    <Transaction
                      transaction={{ ...transaction }}
                      loadTransactionDetails={loadTransactionDetails}
                    />
                  </SlideFade>
                </TabPanel>
                <TabPanel p={0}>
                  <SlideFade in={tabIndex === 1} offsetY={0} offsetX="25px">
                    <Invoices purchaseId={purchaseId} clientId={clientId} />
                  </SlideFade>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </>
        )}
      </Flex>
    </Flex>
  )
}
