import React from 'react'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import Modal from 'react-responsive-modal'
import Api from '../../services/ApiInstance'

import { withSnackbar } from 'notistack'
import TableRevendedor from '../../components/tableRevendedor'
import moment from 'moment'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css'
import HeaderRevendedor from '../../layout/HeaderRevendedor'

const CustomTooltip = ({ active, payload }) => {
  if (active && payload) {
    return (
      <div className="custom-tooltip">
        <div className="custom-tooltip-header">{payload[0].payload.originalName}</div>
        <div className="custom-tooltip-body">
          <span>Transações: {payload[0].value}</span>
          <span>Renda: {payload[0].payload.value}</span>
        </div>
      </div>
    )
  }
  return null
}

class DashboardRevendedor extends React.Component {
  state = {
    title: 'Renda Total',
    totalRevenue: '0.00',
    totalRevenueMonth: '0.00',
    totalAmount: 0,
    totalAmountMonth: 0,
    openModalTimeline: false,
    openModalTotalRevenue: false,
    openModalTotalRevenueMonth: false,
    openModalTotalAmount: false,
    openModalTotalAmountMonth: false,
    loading: false,
    rows: [],
    data: [],
    dataTimeline: [],
    columns: [
      { name: 'Imobiliária', key: 'company_name', visible: true },
      // { name: 'Usúario', key: 'name_user', visible: true},
      { name: 'Serviço', key: 'service_name', visible: true },
      { name: 'Valor', key: 'total_value', visible: true },
      // { name: 'Parcelas', key: 'parcel', visible: true},
      { name: 'Porcentagem Revendedor', key: 'reseller_value', visible: true },
      { name: 'Valor à receber', key: 'finalValue', visible: true },
      { name: 'Data', key: 'created_at', visible: true },
    ],
  }

  closeModalTimeline = () => {
    this.setState({ openModalTimeline: false })
  }

  closeModalTotalRevenue = () => {
    this.setState({ openModalTotalRevenue: false })
  }

  closeModalTotalRevenueMonth = () => {
    this.setState({ openModalTotalRevenueMonth: false })
  }

  closeModalTotalAmount = () => {
    this.setState({ openModalTotalAmount: false })
  }

  closeModalTotalAmountMonth = () => {
    this.setState({ openModalTotalAmountMonth: false })
  }

  async componentDidMount() {
    this.getTotalRevenue()
    this.getTotalRevenueMonth()
    this.getTotalAmount()
    this.getTotalAmountMonth()
    this.getResellerDashboardData()
  }

  async getResellerDashboardData() {
    try {
      const dashboardData = await Api.get('getResellerDashboardData')
      await dashboardData.data.map((dashboard) => {
        if (dashboard.name.length > 10) {
          dashboard.name = dashboard.name.substr(0, 10) + '.'
        }
      })
      this.setState({ data: dashboardData.data })
    } catch (error) {
      const snackbarKey = this.props.enqueueSnackbar('Falha ao montar Dashboard.', {
        variant: 'error',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
    }
  }

  async getResellerTimelineData() {
    try {
      const timelineData = await Api.get('getResellerTimelineData')
      const timelineDataCards = []
      await timelineData.data.map((timeline) => {
        timelineDataCards.push(
          <VerticalTimelineElement
            date={timeline.date}
            contentStyle={{
              backgroundColor: 'rgb(33, 150, 243)',
              borderRadius: 3,
              color: 'white',
              fontSize: 17,
              borderTopStyle: 'solid',
              borderColor: '#00e3cf',
            }}
            dateClassName="timeline-element-date"
            iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
            icon={<i className="lni lni-home"></i>}
            iconClassName="timeline-element-icon"
          >
            <h3 className="vertical-timeline-element-title">{timeline.company_name}</h3>
            <p>{timeline.message}</p>
          </VerticalTimelineElement>
        )
      })
      this.setState({ dataTimeline: timelineDataCards })
    } catch (error) {
      const snackbarKey = this.props.enqueueSnackbar('Falha ao montar Dashboard.', {
        variant: 'error',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
    }
  }

  async getTotalRevenue() {
    try {
      const totalRevenue = await Api.get('getTotalRevenue')
      this.setState({ totalRevenue: totalRevenue.data })
    } catch (error) {
      const snackbarKey = this.props.enqueueSnackbar('Falha ao listar Total Geral.', {
        variant: 'error',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
    }
  }

  async getTotalRevenueMonth() {
    try {
      const totalRevenueMonth = await Api.get('getTotalRevenueMonth')
      this.setState({ totalRevenueMonth: totalRevenueMonth.data })
    } catch (error) {
      const snackbarKey = this.props.enqueueSnackbar('Falha ao listar total mensal.', {
        variant: 'error',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
    }
  }

  async getTotalAmount() {
    try {
      const totalAmount = await Api.get('getAmountPurchase')
      this.setState({ totalAmount: totalAmount.data })
    } catch (error) {
      const snackbarKey = this.props.enqueueSnackbar('Falha ao listar total transações.', {
        variant: 'error',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
    }
  }

  async getTotalAmountMonth() {
    try {
      const totalAmountMonth = await Api.get('getAmountPurchaseMonth')
      this.setState({ totalAmountMonth: totalAmountMonth.data })
    } catch (error) {
      const snackbarKey = this.props.enqueueSnackbar('Falha ao listar total transações mensal.', {
        variant: 'error',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
    }
  }

  getTotalRevenueModal = async () => {
    try {
      this.setState({ loading: true, openModalTotalRevenue: true })
      let totalRevenueList = await Api.get('getTotalRevenueList')
      await totalRevenueList.rows.map((list) => {
        list.created_at = moment(list.created_at).format('DD/MM/YYYY HH:mm:ss')
        return list
      })
      this.setState({ loading: false, rows: totalRevenueList.rows })
    } catch (error) {
      const snackbarKey = this.props.enqueueSnackbar('Falha ao abrir modal total geral.', {
        variant: 'error',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
    }
  }

  getTimelineModal = async () => {
    try {
      this.getResellerTimelineData()
      this.setState({ openModalTimeline: true })
    } catch (error) {
      const snackbarKey = this.props.enqueueSnackbar('Falha ao abrir modal timeline.', {
        variant: 'error',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
    }
  }

  getTotalRevenueModalMonth = async () => {
    try {
      this.setState({ loading: true, openModalTotalRevenueMonth: true })
      let totalRevenueListMonth = await Api.get('getTotalRevenueListMonth')
      await totalRevenueListMonth.rows.map((list) => {
        list.created_at = moment(list.created_at).format('DD/MM/YYYY HH:mm:ss')
        return list
      })
      this.setState({ loading: false, rows: totalRevenueListMonth.rows })
    } catch (error) {
      const snackbarKey = this.props.enqueueSnackbar('Falha ao abrir modal total geral.', {
        variant: 'error',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
    }
  }

  getTotalAmountModal = async () => {
    try {
      this.setState({ loading: true, openModalTotalAmount: true })
      let totalRevenueList = await Api.get('getTotalRevenueList')
      await totalRevenueList.rows.map((list) => {
        list.created_at = moment(list.created_at).format('DD/MM/YYYY HH:mm:ss')
        return list
      })
      this.setState({ loading: false, rows: totalRevenueList.rows })
    } catch (error) {
      const snackbarKey = this.props.enqueueSnackbar('Falha ao abrir modal total transações.', {
        variant: 'error',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
    }
  }

  getTotalAmountMonthModal = async () => {
    try {
      this.setState({ loading: true, openModalTotalAmountMonth: true })
      let totalRevenueListMonth = await Api.get('getTotalRevenueListMonth')
      await totalRevenueListMonth.rows.map((list) => {
        list.created_at = moment(list.created_at).format('DD/MM/YYYY HH:mm:ss')
        return list
      })
      this.setState({ loading: false, rows: totalRevenueListMonth.rows })
    } catch (error) {
      const snackbarKey = this.props.enqueueSnackbar('Falha ao abrir modal total transações mensal.', {
        variant: 'error',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
    }
  }

  onClickRow = () => {
    return false
  }

  render() {
    const { totalRevenue, totalRevenueMonth, totalAmount, totalAmountMonth, loading, data, dataTimeline } = this.state
    return (
      <React.Fragment>
        <HeaderRevendedor />
        <div className="dashboard-body">
          <div className="dashboard-cards">
            <div
              className="card"
              style={{ backgroundColor: '#53BEF3' }}
              // onClick={this.getTotalRevenueModal}
            >
              <font className="font-value">R$: {totalRevenue}</font>
              <font className="font-information">Valor Total à receber</font>
              <i className="lni lni-dollar"></i>
            </div>
            <div
              className="card"
              style={{ backgroundColor: '#28A1DD' }}
              // onClick={this.getTotalRevenueModalMonth}
            >
              <font className="font-value">R$: {totalRevenueMonth}</font>
              <font className="font-information">Renda do Mês</font>
              <i className="lni lni-dollar"></i>
            </div>
            <div
              className="card"
              style={{ backgroundColor: '#158FCB' }}
              // onClick={this.getTotalAmountModal}
            >
              <font className="font-value">{totalAmount} Transações</font>
              <font className="font-information">Total de Transações</font>
              <i className="lni lni-home"></i>
            </div>
            <div
              className="card"
              style={{ backgroundColor: '#027AB6' }}
              // onClick={this.getTotalAmountMonthModal}
            >
              <font className="font-value">{totalAmountMonth} Transações</font>
              <font className="font-information">Transações do Mês</font>
              <i className="lni lni-home"></i>
            </div>
          </div>
          <div className="dashboard-graph">
            <div className="dashboard-graph-header">
              <span>Transações</span>
            </div>
            <div className="month-body">
              <ResponsiveContainer>
                <BarChart
                  data={data}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="5 5" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip content={<CustomTooltip />} />
                  <Legend />
                  <Bar dataKey="transacoes" barSize={50} fill="rgb(33, 150, 243)" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div title="Timeline" onClick={this.getTimelineModal} className="timeline-modal">
            <i className="lni lni-list"></i>
          </div>
        </div>

        <Modal open={this.state.openModalTimeline} onClose={this.closeModalTimeline} classNames={{ modal: 'modal-total-revenue' }}>
          <div className="modal-total-revenue-body">
            <div>
              <h1>Timeline</h1>
            </div>
            <div className="dashboard-timeline">
              <div className="dashboard-timeline-body">
                <VerticalTimeline className="timeline-root">{dataTimeline}</VerticalTimeline>
              </div>
            </div>
          </div>
        </Modal>

        <Modal open={this.state.openModalTotalRevenue} onClose={this.closeModalTotalRevenue} classNames={{ modal: 'modal-total-revenue' }}>
          <div className="modal-total-revenue-body">
            <div>
              <h1>Renda Total</h1>
            </div>
            {loading ? <div className="loader" /> : <TableRevendedor linhas={this.state.rows} columns={this.state.columns} onClickRow={this.onClickRow} />}
          </div>
        </Modal>

        <Modal open={this.state.openModalTotalRevenueMonth} onClose={this.closeModalTotalRevenueMonth} classNames={{ modal: 'modal-total-revenue' }}>
          <div className="modal-total-revenue-body">
            <div>
              <h1>Renda do Mês</h1>
            </div>
            {loading ? <div className="loader" /> : <TableRevendedor linhas={this.state.rows} columns={this.state.columns} onClickRow={this.onClickRow} />}
          </div>
        </Modal>

        <Modal open={this.state.openModalTotalAmount} onClose={this.closeModalTotalAmount} classNames={{ modal: 'modal-total-revenue' }}>
          <div className="modal-total-revenue-body">
            <div>
              <h1>Total de Transações</h1>
            </div>
            {loading ? <div className="loader" /> : <TableRevendedor linhas={this.state.rows} columns={this.state.columns} onClickRow={this.onClickRow} />}
          </div>
        </Modal>

        <Modal open={this.state.openModalTotalAmountMonth} onClose={this.closeModalTotalAmountMonth} classNames={{ modal: 'modal-total-revenue' }}>
          <div className="modal-total-revenue-body">
            <div>
              <h1>Transações do Mês</h1>
            </div>
            {loading ? <div className="loader" /> : <TableRevendedor linhas={this.state.rows} columns={this.state.columns} onClickRow={this.onClickRow} />}
          </div>
        </Modal>
      </React.Fragment>
    )
  }
}

export default withSnackbar(DashboardRevendedor)
