import styles from '../styles/defaultStyles'

type GetPaymentStatusType = {
  title:
    | 'Pendente'
    | 'Aguardando pagamento'
    | 'Concluída'
    | 'Cancelada'
    | 'Falha'
    | 'Pago'
    | 'Pago parcialmente'
    | 'Pendente contrato'
    | 'Pendente aprovação Ucred'
    | 'Invalid status'
  color: 'facebook' | 'linkedin' | 'green' | 'gray' | 'red' | 'orange' | 'cyan' | 'teal' | 'red'
  background: string
}

export function getPaymentStatus(paymentStatus: number): GetPaymentStatusType {
  switch (paymentStatus) {
    case 1:
      return { title: 'Pendente', color: 'facebook', background: styles.info }
    case 2:
      return { title: 'Aguardando pagamento', color: 'linkedin', background: styles.info }
    case 3:
      return { title: 'Concluída', color: 'green', background: styles.success }
    case 4:
      return { title: 'Cancelada', color: 'gray', background: styles.critical }
    case 5:
      return { title: 'Falha', color: 'red', background: styles.error }
    case 6:
      return { title: 'Pago', color: 'green', background: styles.success }
    case 7:
      return { title: 'Pago parcialmente', color: 'orange', background: styles.info }
    case 8:
      return { title: 'Pendente contrato', color: 'cyan', background: styles.info }
    case 9:
      return { title: 'Pendente aprovação Ucred', color: 'teal', background: styles.info }
    default:
      return { title: 'Invalid status', color: 'red', background: styles.error }
  }
}
