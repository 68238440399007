import { cellStyle } from '../../config/materialTableConfig'

export type ColumnType = {
  title: string
  field: string
  cellStyle: any
  export?: boolean
  hidden?: boolean
  searchable?: boolean
  align?: 'inherit' | 'center' | 'justify' | 'left' | 'right' | undefined
  type?: 'string' | 'boolean' | 'currency' | 'date' | 'time' | 'numeric' | 'datetime' | undefined
  dateSetting?: { locale: string }
  currencySetting?: {
    locale: string
    currencyCode: string
    minimumFractionDigits: number
    maximumFractionDigits: number
  }
}

const resalePlanColumns: ColumnType[] = [
  { title: 'Locatário', field: 'cliente', cellStyle },
  { title: 'Serviço', field: 'servico', cellStyle },
  {
    title: 'Status transação',
    field: 'status_filter',
    cellStyle,
    export: true,
    hidden: true,
    searchable: true,
  },
  {
    title: 'Status',
    field: 'status_column',
    cellStyle,
    export: false,
    searchable: false,
    align: 'center',
  },
  {
    title: 'Valor Total',
    field: 'totalValue',
    cellStyle,
    type: 'currency',
    currencySetting: {
      locale: 'pt-BR',
      currencyCode: 'BRL',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  },
  { title: 'Taxa administrativa', field: 'administration_fee', cellStyle },
  {
    title: 'Data Início',
    field: 'dt_inicio',
    cellStyle,
    type: 'date',
    dateSetting: { locale: 'pt-BR' },
  },
  { title: 'Data Fim', field: 'dt_fim', cellStyle, type: 'date', dateSetting: { locale: 'pt-BR' } },
  { title: 'Usuário', field: 'userId', cellStyle },
]

const fullPlanColumns: ColumnType[] = [
  { title: 'Locatário', field: 'cliente', cellStyle },
  { title: 'Serviço', field: 'servico', cellStyle },
  {
    title: 'Status transação',
    field: 'status_filter',
    cellStyle,
    export: true,
    hidden: true,
    searchable: true,
  },
  {
    title: 'Status',
    field: 'status_column',
    cellStyle,
    export: false,
    searchable: false,
    align: 'center',
  },
  {
    title: 'Valor Total',
    field: 'totalValue',
    cellStyle,
    type: 'currency',
    currencySetting: {
      locale: 'pt-BR',
      currencyCode: 'BRL',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  },
  { title: 'Taxa locatário', field: 'total_fee', cellStyle },
  { title: 'Cobertura Ucred', field: 'cover_value', cellStyle },
  {
    title: 'Data Início',
    field: 'dt_inicio',
    cellStyle,
    type: 'date',
    dateSetting: { locale: 'pt-BR' },
  },
  { title: 'Data Fim', field: 'dt_fim', cellStyle, type: 'date', dateSetting: { locale: 'pt-BR' } },
  { title: 'Usuário', field: 'userId', cellStyle },
]

export { fullPlanColumns, resalePlanColumns, cellStyle }
