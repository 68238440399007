import { Flex, Skeleton, SkeletonCircle, Stack, SimpleGrid } from '@chakra-ui/react'

import { Card } from '../Card'

export function TransactionSkeleton() {
  return (
    <>
      <Card>
        <Flex justify="space-between">
          <Flex direction="column">
            <Skeleton h={6} w={64} mt={1} />
            <Skeleton h={4} w={24} mt={2} />
            <Skeleton h="21px" w={40} mt="37px" />
          </Flex>
          <Stack align="flex-end" spacing={2}>
            <Skeleton h={4} w={64} />
            <Skeleton h={2} w={32} />
            <Skeleton h={2} w={16} />
          </Stack>
        </Flex>
      </Card>
      <Card mt={8}>
        <Flex justify="space-between" mt={2}>
          <Stack w="100%" align="flex-end" spacing={4} mr={16}>
            <Skeleton h={4} w="100%" />
            <Skeleton h={4} w="100%" />
            <Skeleton h={6} w={56} />
          </Stack>
          <Stack w="100%" align="flex-end" spacing={4}>
            <Skeleton h={4} w="100%" />
            <Skeleton h={4} w="100%" />
            <Skeleton h={6} w={56} />
          </Stack>
        </Flex>
        <Card bg="gray.50" boxShadow="sm" mt="84px">
          <Flex justify="space-between">
            <Skeleton h={6} w={96} />
            <Skeleton h={6} w={40} />
          </Flex>
          <Flex justify="space-between" mt={12}>
            <Stack>
              <Skeleton h={2} w={24} />
              <Skeleton h={5} w={24} />
            </Stack>
            <Stack>
              <Skeleton h={2} w={24} />
              <Skeleton h={5} w={24} />
            </Stack>
            <Stack>
              <Skeleton h={2} w={28} />
              <Skeleton h={5} w={28} />
            </Stack>
            <Stack>
              <Skeleton h={2} w={24} />
              <Skeleton h={5} w={24} />
            </Stack>
            <Stack>
              <Skeleton h={2} w={28} />
              <Skeleton h={5} w={28} />
            </Stack>
          </Flex>
        </Card>
        <SimpleGrid mt={6} columns={2} spacing={6}>
          <Card bg="gray.50" boxShadow="sm">
            <Flex>
              <SkeletonCircle size="12" />
              <Stack ml={4} mt={2}>
                <Skeleton h={4} w={36} />
                <Skeleton h={2} w={28} />
              </Stack>
            </Flex>
            <SimpleGrid mt={10} columns={2} spacing={8}>
              <Stack>
                <Skeleton h={2} w={10} />
                <Skeleton h={4} w={36} />
              </Stack>
              <Stack>
                <Skeleton h={2} w={10} />
                <Skeleton h={4} w={40} />
              </Stack>
              <Stack>
                <Skeleton h={2} w={24} />
                <Skeleton h={4} w={24} />
              </Stack>
              <Stack>
                <Skeleton h={2} w={28} />
                <Skeleton h={4} w={28} />
              </Stack>
              <Stack>
                <Skeleton h={2} w={36} />
                <Skeleton h={4} w={36} />
              </Stack>
              <Stack>
                <Skeleton h={2} w={16} />
                <Skeleton h={4} w={36} />
              </Stack>
            </SimpleGrid>
          </Card>
        </SimpleGrid>
      </Card>
    </>
  )
}
