import { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import { differenceInDays, parseISO } from 'date-fns'

import { Stack, SimpleGrid } from '@chakra-ui/react'
import { MdMoneyOff, MdNoteAdd, MdReceipt } from 'react-icons/md'
import { BsCreditCard, BsXCircleFill } from 'react-icons/bs'
import { IoMdPrint } from 'react-icons/io'

import { Card } from '../components/Card'
import { ActionButton } from '../components/Button/ActionButton'
import { ChangeCreditCardModal } from '../components/Modal/ChangeCreditCardModal'
import { PaymentProofModal } from '../components/Modal/PaymentProofModal'
import { CancelTransactionModal } from '../components/Modal/CancelTransactionModal'
import { CancelFirst30DaysTransactionModal } from '../components/Modal/CancelFirst30DaysTransactionModal'
import { AddInvoiceModal } from '../components/Modal/AddInvoiceModal'

import TransactionPrint from '../../../components/transactionResume'

import { Summary } from './Summary'
import { Contract } from './Contract'
import { Renter } from './Renter'
import { TransactionType } from '../types/transaction'

interface TransactionProps {
  transaction: TransactionType
  loadTransactionDetails: ({ tabIndex }: { tabIndex: number }) => void
}

export function Transaction({ transaction, loadTransactionDetails }: TransactionProps) {
  const [cancelFirst30DaysTransactionModalOpen, setCancelFirst30DaysTransactionModalOpen] =
    useState(false)
  const [cancelTransactionModalOpen, setCancelTransactionModalOpen] = useState(false)
  const [paymentProofModalOpen, setPaymentProofModalOpen] = useState(false)
  const [addInvoiceModalOpen, setAddInvoiceModalOpen] = useState(false)
  const [changeCreditCardModalOpen, setChangeCreditCardModalOpen] = useState(false)

  const componentRef = useRef(null)
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Resumo da transação',
  })

  const { summary, renter, contract, transactionResume } = transaction
  const isPurchaseCompleted = summary.status.transaction === 3
  const isPurchaseCancelled = summary.status.transaction === 4
  const history = useHistory()

  async function cancelTransaction() {
    const isFirst30Days = differenceInDays(new Date(), parseISO(contract.startDate)) <= 30
    if (isFirst30Days) return setCancelFirst30DaysTransactionModalOpen(true)
    setCancelTransactionModalOpen(true)
  }

  function handleCloseModal() {
    setCancelFirst30DaysTransactionModalOpen(false)
    setCancelTransactionModalOpen(false)
    setPaymentProofModalOpen(false)
    setChangeCreditCardModalOpen(false)
    setAddInvoiceModalOpen(false)
  }

  function sendPaymentProof() {
    setPaymentProofModalOpen(true)
  }

  function addInvoice() {
    setAddInvoiceModalOpen(true)
  }
  function handleChangeCreditCard() {
    setChangeCreditCardModalOpen(true)
  }

  return (
    <>
      <div style={{ display: 'none' }}>
        {transactionResume && (
          <TransactionPrint
            ref={componentRef}
            purchase={transactionResume}
            planId={contract.companyPlanId}
          />
        )}
      </div>
      <CancelFirst30DaysTransactionModal
        isOpen={cancelFirst30DaysTransactionModalOpen}
        handleCloseModal={handleCloseModal}
        purchase={summary.purchase}
      />
      <CancelTransactionModal
        isOpen={cancelTransactionModalOpen}
        handleCloseModal={handleCloseModal}
        purchaseId={contract.purchaseId}
      />
      <PaymentProofModal
        isOpen={paymentProofModalOpen}
        handleCloseModal={handleCloseModal}
        purchaseId={contract.purchaseId}
      />
      <ChangeCreditCardModal
        isOpen={changeCreditCardModalOpen}
        handleCloseModal={handleCloseModal}
        renter={transaction.renter}
      />
      <AddInvoiceModal
        isOpen={addInvoiceModalOpen}
        handleCloseModal={handleCloseModal}
        purchase={summary.purchase}
        loadTransactionDetails={loadTransactionDetails}
      />
      <Card>
        <Summary summary={summary} />
        {transaction.contract.globalStatus && <Contract contract={transaction.contract} />}

        <SimpleGrid mt={6} columns={2} spacing={6}>
          <Renter renter={transaction.renter} />
          {transaction.coresponsible && (
            <Renter renter={transaction.coresponsible} isCoresponsible />
          )}

          <Stack spacing={3} mt={4} w="fit-content">
            <ActionButton
              title="Resumo da transação"
              onClick={handlePrint}
              icon={<IoMdPrint size={20} />}
            />

            {isPurchaseCompleted && (
              <>
                <ActionButton
                  title="Autenticar documentos"
                  icon={<MdNoteAdd size={20} />}
                  onClick={() => history.push(`/documentos/${contract.purchaseId}`)}
                />
                <ActionButton
                  title="Acionar sinistro"
                  onClick={addInvoice}
                  icon={<MdMoneyOff size={20} />}
                />
              </>
            )}
            {!isPurchaseCancelled && (
              <>
                <ActionButton
                  title="Cancelar transação"
                  onClick={cancelTransaction}
                  icon={<BsXCircleFill size={20} />}
                />
                <ActionButton
                  title="Alterar cartão"
                  onClick={handleChangeCreditCard}
                  icon={<BsCreditCard size={20} />}
                />
              </>
            )}

            {renter.paymentMethod !== 'creditCard' && renter.paymentMethod !== 'notDefined' && (
              <ActionButton
                title="Enviar comprovante de pagamento"
                onClick={sendPaymentProof}
                icon={<MdReceipt size={20} />}
              />
            )}
          </Stack>
        </SimpleGrid>
      </Card>
    </>
  )
}
