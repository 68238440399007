import DateFnsUtils from '@date-io/moment'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import moment from 'moment'
import { withSnackbar } from 'notistack'
import React from 'react'
import ReactCreditCard from 'react-credit-cards'
import MaskedInput from 'react-input-mask'
import ApiInstance from '../services/ApiInstance'

class ChangeCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      choose: true,
      loading: false,
      open: false,
      card: {
        number: '',
        name: '',
        expiry: '',
        expiry_picker: null,
        cvv: '',
        type: '',
        card_maturity: '',
        focus: '',
      },
      focusCard: null,
    }
  }

  render() {
    const { card, open, loading, choose } = this.state
    card.name = this.props.client.name

    const handleChangeCard = (event) => {
      let { name, value } = event.target

      if (name === 'card_maturity' && value > 30) {
        value = 30
      }

      this.setState({ card: { ...this.state.card, [name]: value } })
    }

    const sendEmail = async () => {
      this.setState({ loading: true })
      try {
        const { email, name, id } = this.props.client
        await ApiInstance.post('sendMailToLocator', { email, name, id })
        const snackbarKey = this.props.enqueueSnackbar('Email enviado com sucesso.', {
          variant: 'success',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        })
        this.setState({
          loading: false,
          choose: true,
          open: false,
          card: {
            number: '',
            name: '',
            expiry: '',
            expiry_picker: null,
            cvv: '',
            type: '',
            card_maturity: '',
            focus: '',
          },
          focusCard: null,
        })
      } catch (error) {
        this.setState({ loading: false })

        const snackbarKey = this.props.enqueueSnackbar('Erro ao enviar email, entre em contato com nosso suporte.', {
          variant: 'error',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        })
      }
    }

    const handleSubmit = async (event) => {
      event.preventDefault()
      this.setState({ loading: true })
      const name = this.props.client.name
      try {
        const {
          card: { expiry, number, cvv, type, card_maturity },
        } = this.state

        if (!name) throw new Error('Informe o nome do titular do cartão.')
        if (!number) throw new Error('Informe o número do cartão.')
        if (!expiry) throw new Error('Informe a data de expiração do cartão.')
        if (!cvv) throw new Error('Informe o código de segurança do cartão.')
        if (!card_maturity) throw new Error('Informe o dia do vencimento da fatura do cartão.')

        const { client } = this.props
        await ApiInstance.post('cliente/atualizarCartao', {
          card: { holder_name: name, expiration_date: expiry, number_card: number, cvv: cvv, type, card_maturity },
          id_client: client.id,
        })

        const snackbarKey = this.props.enqueueSnackbar('Atualizado com sucesso.', {
          variant: 'success',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        })

        this.setState({
          loading: false,
          choose: true,
          open: false,
          card: {
            number: '',
            name: '',
            expiry: '',
            expiry_picker: null,
            cvv: '',
            type: '',
            card_maturity: '',
            focus: '',
          },
          focusCard: null,
        })
      } catch (error) {
        this.setState({ loading: false })
        const snackbarKey = this.props.enqueueSnackbar('Erro inesperado, entre em contato com nosso suporte.', {
          variant: 'error',
          onClick: () => {
            this.props.closeSnackbar(snackbarKey)
          },
        })
      }
    }

    const handleChangeDate = (date) => {
      this.setState({ card: { ...this.state.card, expiry_picker: moment(date).format('YYYY-MM-DD'), expiry: moment(date).format('MM/YYYY') } })
    }

    return (
      open && (
        <Dialog
          id="edit-credit-card-modal"
          open={open}
          onClose={() => {
            this.setState({
              choose: true,
              open: false,
              card: {
                number: '',
                name: '',
                expiry: '',
                expiry_picker: null,
                cvv: '',
                type: '',
                card_maturity: '',
                focus: '',
              },
              focusCard: null,
            })
          }}
        >
          <form onSubmit={handleSubmit}>
            <DialogTitle
              disableTypography
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography variant="h6">Edição de cartão</Typography>
              <IconButton
                onClick={() => {
                  this.setState({
                    choose: true,
                    open: false,
                    card: {
                      number: '',
                      name: '',
                      expiry: '',
                      expiry_picker: null,
                      cvv: '',
                      type: '',
                      card_maturity: '',
                      focus: '',
                    },
                    focusCard: null,
                  })
                }}
                size="small"
                style={{ marginLeft: 'auto' }}
              >
                <Close></Close>
              </IconButton>
            </DialogTitle>
            <DialogContent className="content" dividers>
              {this.state.choose && (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Tooltip title="Envia um email para que o próprio cliente altere o cartão">
                      <Box style={{ display: 'flex', position: 'relative' }}>
                        <Button size="large" fullWidth disabled={loading} onClick={sendEmail} color="primary" variant="contained">
                          Enviar email
                        </Button>
                        {loading && (
                          <CircularProgress
                            size={24}
                            style={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              marginTop: -12,
                              marginLeft: -12,
                            }}
                          />
                        )}
                      </Box>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12}>
                    <Tooltip title="Você mesmo insere os dados do cartão do locatário">
                      <Box style={{ display: 'flex', position: 'relative' }}>
                        <Button
                          size="large"
                          fullWidth
                          disabled={loading}
                          onClick={() => {
                            this.setState({ choose: false })
                          }}
                          color="primary"
                          variant="contained"
                        >
                          Irei alterar manualmente
                        </Button>
                      </Box>
                    </Tooltip>
                  </Grid>
                </Grid>
              )}
              {!this.state.choose && (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <ReactCreditCard
                      name={card.name}
                      number={card.number}
                      expiry={card.expiry}
                      cvc={card.cvv}
                      focused={card.focus}
                      locale={{ valid: 'Data Venc' }}
                      placeholders={{ name: 'Nome do Titular' }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="name"
                      fullWidth
                      variant="outlined"
                      placeholder="José Vieira"
                      label="Nome"
                      value={card.name}
                      onFocus={() => this.setState({ card: { ...card, focus: 'name' } })}
                      onBlur={() => this.setState({ card: { ...card, focus: '' } })}
                      disabled
                    ></TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <MaskedInput
                      maskChar=""
                      mask="9999 9999 9999 9999"
                      name="number"
                      fullWidth
                      variant="outlined"
                      placeholder="1234 5678 8765 4321"
                      label="Número"
                      value={card.number}
                      onChange={handleChangeCard}
                      onFocus={() => this.setState({ card: { ...card, focus: 'number' } })}
                      onBlur={() => this.setState({ card: { ...card, focus: '' } })}
                    >
                      {(inputProps) => <TextField {...inputProps} />}
                    </MaskedInput>
                  </Grid>
                  <Grid item xs={8}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        openTo="year"
                        views={['year', 'month']}
                        name="expiry"
                        fullWidth
                        format="MM/YY"
                        inputVariant="outlined"
                        placeholder="12/25"
                        value={card.expiry_picker}
                        minDate={moment().add(13, 'month').format('YYYY-MM-DD')}
                        label="Validade"
                        onChange={handleChangeDate}
                        onFocus={() => this.setState({ card: { ...card, focus: 'expiry' } })}
                        onBlur={() => this.setState({ card: { ...card, focus: '' } })}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={4}>
                    <MaskedInput
                      maskChar=""
                      mask="9999"
                      name="cvv"
                      fullWidth
                      variant="outlined"
                      placeholder="123"
                      label="CVV"
                      value={card.cvv}
                      onChange={handleChangeCard}
                      onFocus={() => this.setState({ card: { ...card, focus: 'cvc' } })}
                      onBlur={() => this.setState({ card: { ...card, focus: '' } })}
                    >
                      {(inputProps) => <TextField {...inputProps} />}
                    </MaskedInput>
                  </Grid>
                  <Grid item xs={12}>
                    <MaskedInput
                      maskChar=""
                      mask="99"
                      name="card_maturity"
                      fullWidth
                      variant="outlined"
                      placeholder="10"
                      label="Vencimento da fatura"
                      onChange={handleChangeCard}
                      value={card.card_maturity}
                    >
                      {(inputProps) => <TextField {...inputProps} />}
                    </MaskedInput>
                  </Grid>
                </Grid>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                disabled={loading}
                onClick={() => {
                  this.setState({
                    open: false,
                    choose: true,
                    card: {
                      number: '',
                      name: '',
                      expiry: '',
                      expiry_picker: null,
                      cvv: '',
                      type: '',
                      card_maturity: '',
                      focus: '',
                    },
                    focusCard: null,
                  })
                }}
                color="primary"
              >
                Cancelar
              </Button>
              {!choose && (
                <Box style={{ display: 'flex', position: 'relative' }}>
                  <Button disabled={loading} type="submit" color="primary" variant="contained">
                    Salvar
                  </Button>
                  {loading && (
                    <CircularProgress
                      size={24}
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: -12,
                        marginLeft: -12,
                      }}
                    />
                  )}
                </Box>
              )}
            </DialogActions>
          </form>
        </Dialog>
      )
    )
  }
}

export default withSnackbar(ChangeCard)
