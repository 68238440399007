import { useMemo, useState, useEffect, useCallback } from 'react'
import { useTable, useSortBy, useExpanded } from 'react-table'
import { useExportData } from 'react-table-plugins'
import { v4 as uuidv4 } from 'uuid'
import Papa from 'papaparse'
import jsPDF from 'jspdf'
import 'jspdf-autotable'

import {
  Flex,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  chakra,
  Button,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Center,
  useToast,
  SlideFade,
} from '@chakra-ui/react'
import { BiChevronDown, BiChevronRight, BiCaretDown, BiCaretUp } from 'react-icons/bi'
import { RiDownloadFill } from "react-icons/ri";
import { addDays, isSaturday, isSunday, format } from 'date-fns'
import { setDay } from 'date-fns/esm'

import { maskMoney } from '../../../util/money'
import { formatDate } from '../../../util/formatDate'
import { getBaseUrl } from '../../../util/getBaseUrl'
import { getInvoiceStatus } from '../../../util/getInvoiceStatus'

import { Card } from '../components/Card'
import ApiInstance from '../../../services/ApiInstance'
import { InvoiceStatusBadge } from '../components/Badge/InvoiceStatusBadge'
import { CancelInvoiceModal } from '../components/Modal/CancelInvoiceModal'
import { InvoicesSkeleton } from '../components/Skeleton/InvoicesSkeleton'
import { InvoiceDocuments } from './InvoiceDocuments'

export function Invoices({ clientId, purchaseId }) {
  const [loading, setLoading] = useState(true)
  const [cancelInvoiceModalOpen, setCancelInvoiceModalOpen] = useState(false)
  const [cancelInvoiceId, setCancelInvoiceId] = useState()
  const [invoices, setInvoices] = useState([])
  const toast = useToast()

  function formatInvoices(invoices) {
    const invoicesFormatted = invoices.filter(invoice => invoice.purchase_id === purchaseId).map((invoice) => {
      const invoiceCreatedAt = new Date(invoice.created_at)
      const forecast = addDays(invoiceCreatedAt, 30)
      const paymentAccuracyDate =
        isSaturday(forecast) || isSunday(forecast) ? setDay(forecast, 5, { weekStartsOn: 1 }) : forecast
      const documents = JSON.parse(invoice.path).map((document) => ({
        id: uuidv4(),
        name: document.name,
        url: `${getBaseUrl()}${document.path}`,
      }))

      return {
        ...invoice,
        invoiceValue: maskMoney(invoice.value),
        invoiceDate: formatDate(invoice.created_at),
        paymentAccuracyDate: invoice.status === 2 || invoice.status === 5 ? '-' : formatDate(paymentAccuracyDate),
        requestedBy: invoice.name,
        documents,
        status_formatted: getInvoiceStatus(invoice.status).title,
      }
    })
    setInvoices(invoicesFormatted)
  }

  const fetchData = useCallback(async () => {
    try {
      const response = await ApiInstance.get(`cliente/${clientId}`)
      formatInvoices(response.data.invoices)
      setLoading(false)
    } catch (err) {
      toast({
        title: err.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      })
    }
  }, [clientId])

  useEffect(() => {
    setLoading(true)
    fetchData()
  }, [fetchData])

  function handleCancelInvoice(row) {
    setCancelInvoiceId(row.values.id)
    setCancelInvoiceModalOpen(true)
  }

  function handleCloseInvoiceModal() {
    setCancelInvoiceModalOpen(false)
  }

  const exportPDF = ({ headerNames, data }) => {
    const unit = 'pt'
    const size = 'A4'
    const orientation = 'landscape'
    const pdfDocument = new jsPDF(orientation, unit, size)
    pdfDocument.setFontSize(15)
    const dateFormatted = format(new Date(), 'yyyy-MM-dd hh:mm:ss')
    const title = `Relatório Ucred (Sinistros) - ${dateFormatted}`
    const marginLeft = 40
    pdfDocument.text(title, marginLeft, 40)
    pdfDocument.autoTable({ startY: 50, head: [headerNames], body: data })
    pdfDocument.save(`${title}.pdf`)
    return false
  }

  const exportCSV = ({ headerNames, data }) => {
    const csvString = Papa.unparse({ fields: headerNames, data })
    return new Blob([csvString], { type: 'text/csv' })
  }

  function getExportFileName() {
    const dateFormatted = format(new Date(), 'yyyy-MM-dd hh:mm:ss')
    return `Relatório Ucred (Sinistros) - ${dateFormatted}`
  }

  function getExportFileBlob({ columns, data, fileType }) {
    const headerNames = columns.map((column) => column.exportValue)
    if (fileType === 'csv') return exportCSV({ headerNames, data })
    if (fileType === 'pdf') return exportPDF({ data, headerNames })
  }

  const data = useMemo(() => invoices, [invoices])

  const columns = useMemo(
    () => [
      {
        Header: () => null,
        id: 'id',
        accessor: 'id',
        disableExport: true,
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>
            <IconButton
              variant="ghost"
              colorScheme="telegram"
              aria-label="close"
              size="sm"
              borderRadius={32}
              icon={row.isExpanded ? <Icon as={BiChevronDown} boxSize="5" /> : <Icon as={BiChevronRight} boxSize="5" />}
            />
          </span>
        ),
      },
      {
        id: 'requestedBy',
        Header: 'Solicitado por',
        accessor: 'requestedBy',
      },
      {
        id: 'status',
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row }) => <InvoiceStatusBadge status={row.values.status} />,
        getCellExportValue: (row) => getInvoiceStatus(row.values.status).title,
      },
      {
        id: 'invoiceDate',
        Header: 'Data do acionamento',
        accessor: 'invoiceDate',
      },
      {
        id: 'paymentAccuracyDate',
        Header: 'Previsão do pagamento',
        accessor: 'paymentAccuracyDate',
      },
      {
        id: 'invoiceValue',
        Header: 'Valor acionado',
        accessor: 'invoiceValue',
        isNumeric: true,
      },
      {
        id: 'description',
        Header: () => null,
        accessor: 'description',
        disableExport: true,
        Cell: () => null,
      },
      {
        id: 'documents',
        Header: () => null,
        accessor: 'documents',
        disableExport: true,
        Cell: () => null,
      },
      {
        Header: () => null,
        id: 'cancel',
        disableExport: true,
        Cell: ({ row }) => (
          <Button
            isDisabled={row.values.status === 5}
            size="sm"
            colorScheme="blue"
            onClick={() => handleCancelInvoice(row)}
          >
            Cancelar
          </Button>
        ),
      },
    ],
    []
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, visibleColumns, exportData } = useTable(
    { columns, data, getExportFileName, getExportFileBlob },
    useSortBy,
    useExpanded,
    useExportData
  )

  return (
    <>
      <CancelInvoiceModal
        isOpen={cancelInvoiceModalOpen}
        handleCloseModal={handleCloseInvoiceModal}
        fetchData={fetchData}
        invoiceId={cancelInvoiceId}
      />
      {loading ? (
        <InvoicesSkeleton />
      ) : (
        <Card>
          <Flex justify="space-between" mb={6}>
            <Text fontSize="lg" color="gray.600">
              Relatório de sinistros
            </Text>
            <Menu>
              <MenuButton as={IconButton} aria-label="Exportar como" icon={<Icon as={RiDownloadFill} boxSize="4" color="gray.600" />} variant="outline" />
              <MenuList>
                <MenuItem onClick={() => exportData('pdf')}>Exportar em PDF</MenuItem>
                <MenuItem onClick={() => exportData('csv')}>Exportar em CSV</MenuItem>
              </MenuList>
            </Menu>
          </Flex>

          <Flex>
            <Table size="sm" {...getTableProps()}>
              <Thead>
                {headerGroups.map((headerGroup) => (
                  <Tr key={uuidv4()} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <Th
                        key={column.id}
                        py={2}
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        isNumeric={column.isNumeric}
                      >
                        {column.render('Header')}
                        <chakra.span pl="4">
                          {column.isSorted &&
                            (column.isSortedDesc ? (
                              <Icon as={BiCaretDown} boxSize="4" aria-label="sorted descending" />
                            ) : (
                              <Icon as={BiCaretUp} boxSize="4" aria-label="sorted ascending" />
                            ))}
                        </chakra.span>
                      </Th>
                    ))}
                  </Tr>
                ))}
              </Thead>
              <Tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row)
                  return (
                    <>
                      <Tr
                        key={row.id}
                        _hover={{
                          backgroundColor: 'gray.50',
                          transitionProperty: 'background-color',
                          transitionDuration: '0.5s',
                        }}
                        {...row.getRowProps()}
                      >
                        {row.cells.map((cell) => (
                          <Td
                            key={uuidv4()}
                            color="gray.600"
                            {...cell.getCellProps()}
                            isNumeric={cell.column.isNumeric}
                          >
                            {cell.render('Cell')}
                          </Td>
                        ))}
                      </Tr>
                      {row.isExpanded && (
                        <Tr>
                          <Td colSpan={visibleColumns.length}>
                            <SlideFade in={row.isExpanded} offsetY="-25px">
                              <InvoiceDocuments row={row} />
                            </SlideFade>
                          </Td>
                        </Tr>
                      )}
                    </>
                  )
                })}
              </Tbody>
            </Table>
          </Flex>
          {invoices.length === 0 && (
            <Center h={44} boxShadow="inner" mt={6} bg="gray.50" borderRadius={6}>
              <Text fontSize="xl" fontWeight="medium" color="gray.500">
                Nenhum registro para exibir
              </Text>
            </Center>
          )}
        </Card>
      )}
    </>
  )
}
