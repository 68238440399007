/* eslint-disable camelcase */
import { ReactNode } from 'react'
import moment from 'moment'
import 'moment/locale/pt-br'

import { InvoicesType } from '../TransactionDetails/types/response/invoices'
import { getInvoiceStatus } from '../../util/getInvoiceStatus'
import { cellStyle } from '../../config/materialTableConfig'
import { Chip } from '@material-ui/core'

export interface InvoicesFormattedProps extends InvoicesType {
  status_formatted: 'Requisitado' | 'Recusado' | 'Falha' | 'Pago' | 'Cancelado' | 'Error'
  status_column: ReactNode
  payment_forecast: string
}

export type ColumnType = {
  title: string
  field: string
  cellStyle: any
  export?: boolean
  hidden?: boolean
  searchable?: boolean
  align?: 'inherit' | 'center' | 'justify' | 'left' | 'right' | undefined
  type?: 'string' | 'boolean' | 'currency' | 'date' | 'time' | 'numeric' | 'datetime' | undefined
  dateSetting?: { locale: string }
  defaultSort?: 'asc' | 'desc' | undefined
  currencySetting?: {
    locale: string
    currencyCode: string
    minimumFractionDigits: number
    maximumFractionDigits: number
  }
}

export const columnsDefinitions: ColumnType[] = [
  {
    title: 'Valor acionado',
    field: 'value',
    cellStyle,
    type: 'currency',
    currencySetting: {
      locale: 'pt-BR',
      currencyCode: 'BRL',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  },
  {
    title: 'Status',
    field: 'status',
    cellStyle,
    export: true,
    hidden: true,
    searchable: true,
  },
  {
    title: 'Status',
    field: 'status_column',
    export: false,
    searchable: false,
    align: 'center',
    cellStyle,
  },
  {
    title: 'Locatário',
    field: 'client.name',
    cellStyle,
  },
  {
    title: 'Data de acionamento',
    field: 'created_at',
    type: 'datetime',
    cellStyle,
    defaultSort: 'desc',
  },
  {
    title: 'Previsão de pagamento',
    field: 'payment_forecast',
    type: 'date',
    cellStyle,
    defaultSort: 'desc',
  },
  {
    title: 'Descrição',
    field: 'description',
    cellStyle,
  },
  {
    title: 'Criado por',
    field: 'user.name',
    cellStyle,
  },
]

export const formatInvoices = (invoices: InvoicesType[]): InvoicesFormattedProps[] => {
  return invoices.map(invoice => {
    let forecast = moment(invoice.created_at).add(30, 'days')
    if (forecast.format('dddd') === 'Sábado') forecast = moment(invoice.created_at).add(29, 'days')
    if (forecast.format('dddd') === 'Domingo') forecast = moment(invoice.created_at).add(28, 'days')

    let payment_forecast = forecast.format('YYYY-MM-DD')
    if (invoice.status === 2 || invoice.status === 5) payment_forecast = '-'
    const { title, background } = getInvoiceStatus(invoice.status)
    const status_formatted = title
    const status_column = <Chip style={{ color: '#fff', background }} size="small" label={title} />
    return { ...invoice, status_formatted, status_column, payment_forecast }
  })
}
