import * as yup from 'yup'
import moment from 'moment'
import { addMonths, format } from 'date-fns'

function parseDateFromFormats(formats, parseStrict) {
  return this.transform(function (value, originalValue) {
    value = moment(originalValue, formats, parseStrict)
    return value.isValid() ? value.toDate() : yup.date.INVALID_DATE
  })
}

yup.addMethod(yup.date, 'format', parseDateFromFormats)

const minExpiryDate = addMonths(new Date(), 13)

export const creditCardSchema = yup.object().shape({
  cardNumber: yup.string().length(19, 'Número incompleto').required().typeError('Número do cartão é obrigatório'),
  cardExpiry: yup
    .date()
    .format('MM/YY')
    .min(minExpiryDate, `Precisa ser depois de ${format(minExpiryDate, 'MM/yy')}`)
    .required()
    .typeError('Validade é obrigatória'),
  cardCVV: yup.string().min(3, 'CVV inválido').max(4, 'CVV inválido').required().typeError('CVV é obrigatório'),
  cardMaturity: yup
    .number()
    .min(1, 'Vencimento inválido')
    .max(30, 'Vencimento inválido')
    .required()
    .typeError('Vencimento da fatura do cartão é obrigatório'),
})
