import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core'
import { Close, Favorite } from '@material-ui/icons'
import { Rating } from '@material-ui/lab'
import { withSnackbar } from 'notistack'
import ApiInstance from '../../../services/ApiInstance'

function Feedback(props: any) {
  const { user, notificationCount, setNotificationCount, feedback, setFeedback } = props

  const handleClose = () => {
    setFeedback({ ...feedback, open: false })
  }

  const handleSubmit = async () => {
    setFeedback({ ...feedback, loading: true })

    await ApiInstance.post('user_feedback', {
      observation: feedback.observation,
      answers: feedback.answers,
    })
      .then(() => {
        const snackbarKey = props.enqueueSnackbar('Obrigado por sua contribuição!', {
          variant: 'success',
          onClick: () => {
            props.closeSnackbar(snackbarKey)
          },
        })
        setFeedback({ ...feedback, feedbackNotification: false, open: false, loading: false })
        setNotificationCount(notificationCount - 1)
      })
      .catch(() => {
        const snackbarKey = props.enqueueSnackbar(
          'Erro inesperado, entre em contato com nosso suporte.',
          {
            variant: 'error',
            onClick: () => {
              props.closeSnackbar(snackbarKey)
            },
          },
        )
        setFeedback({ ...feedback, loading: false })
      })
  }

  return (
    <Dialog disableScrollLock id="feedback-dialog" open={feedback.open} onClose={handleClose}>
      <DialogTitle
        disableTypography
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6">Nos dê um feedback!</Typography>
        <IconButton onClick={handleClose}>
          <Close></Close>
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          Olá {user.name}, estamos muito felizes em saber que você está utilizando a Ucred, mas
          ainda precisamos da sua ajuda, responda o questionário abaixo deixando sua visão sobre a
          Ucred, assim iremos poder melhorar cada vez mais!
        </DialogContentText>

        <Typography align="center" component="legend" variant="body2" color="textSecondary">
          Qual a facilidade de alugar com a Ucred?
        </Typography>

        <div className="icons">
          <Rating
            size="large"
            value={feedback.answers?.question1}
            precision={0.5}
            onChange={(event, newValue: number | null) => {
              setFeedback({
                ...feedback,
                answers: {
                  ...feedback.answers,
                  question1: newValue,
                },
              })
            }}
            icon={<Favorite fontSize="inherit" />}
          />
        </div>

        <Typography align="center" component="legend" variant="body2" color="textSecondary">
          Dê uma nota quanto ao suporte recebido
        </Typography>

        <div className="icons">
          <Rating
            size="large"
            value={feedback.answers?.question2}
            precision={0.5}
            onChange={(event, newValue) => {
              setFeedback({
                ...feedback,
                answers: {
                  ...feedback.answers,
                  question2: newValue,
                },
              })
            }}
            icon={<Favorite fontSize="inherit" />}
          />
        </div>

        <Typography align="center" component="legend" variant="body2" color="textSecondary">
          De uma nota quanto a facilidade de uso da plataforma
        </Typography>

        <div className="icons">
          <Rating
            size="large"
            value={feedback.answers?.question3}
            precision={0.5}
            onChange={(event, newValue) => {
              setFeedback({
                ...feedback,
                answers: {
                  ...feedback.answers,
                  question3: newValue,
                },
              })
            }}
            icon={<Favorite fontSize="inherit" />}
          />
        </div>

        <TextField
          className="final-opinion"
          variant="outlined"
          placeholder="Escreva aqui pontos de melhorias, sugestões, ideias dentre outros para que possamos melhorar cada vez mais nosso processo..."
          multiline
          fullWidth
          rows={4}
          value={feedback.observation}
          onChange={event => {
            setFeedback({ ...feedback, observation: event.target.value })
          }}
        ></TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" disabled={feedback.loading}>
          Mais tarde
        </Button>

        <Box style={{ display: 'flex', position: 'relative' }}>
          <Button
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            disabled={feedback.loading}
            autoFocus
          >
            Enviar feedback
          </Button>
          {feedback.loading && (
            <CircularProgress
              size={24}
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: -12,
                marginLeft: -12,
              }}
            />
          )}
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default withSnackbar(Feedback)
