import styles from '../styles/defaultStyles'
import { getPaymentStatus } from './getPaymentStatus'

interface overallStatus {
  title:
    | 'Pendente'
    | 'Aguardando pagamento'
    | 'Concluída'
    | 'Cancelada'
    | 'Falha'
    | 'Pago'
    | 'Pago parcialmente'
    | 'Pendente contrato'
    | 'Pendente aprovação Ucred'
    | 'Invalid status'
  color: 'facebook' | 'linkedin' | 'green' | 'gray' | 'red' | 'orange' | 'cyan' | 'teal' | 'red'
  background: string
}

export function getOverallStatus(
  purchaseStatus: number,
  contractStatus: 'signed' | 'running' | 'canceled',
  corresponsiblePurchaseStatus: number | undefined,
  corresponsibleContractStatus: 'signed' | 'running' | 'canceled' | undefined,
): overallStatus {
  if (!corresponsiblePurchaseStatus) {
    if (purchaseStatus === 7 || purchaseStatus === 8) return getPaymentStatus(purchaseStatus)
    if (purchaseStatus === 4) {
      return { title: 'Cancelada', color: 'gray', background: styles.critical }
    }

    if (purchaseStatus === 2 && contractStatus === 'signed') {
      return { title: 'Aguardando pagamento', color: 'linkedin', background: styles.info }
    }

    if (purchaseStatus === 2 && contractStatus === 'running') {
      return { title: 'Aguardando pagamento', color: 'linkedin', background: styles.info }
    }

    if (purchaseStatus === 3 && contractStatus === 'running') {
      return { title: 'Pendente contrato', color: 'cyan', background: styles.info }
    }

    if (purchaseStatus === 3 && contractStatus === 'signed') {
      return { title: 'Concluída', color: 'green', background: styles.success }
    }
  }

  if (corresponsiblePurchaseStatus) {
    if (purchaseStatus === 4) {
      return { title: 'Cancelada', color: 'gray', background: styles.critical }
    }

    if (purchaseStatus === 2 || corresponsiblePurchaseStatus === 2) {
      return { title: 'Aguardando pagamento', color: 'linkedin', background: styles.info }
    }

    if (
      purchaseStatus === 3 &&
      corresponsiblePurchaseStatus === 3 &&
      (contractStatus === 'running' || corresponsibleContractStatus === 'running')
    ) {
      return { title: 'Pendente contrato', color: 'orange', background: styles.info }
    }

    if (purchaseStatus === 3 && corresponsiblePurchaseStatus === 2) {
      return { title: 'Pago parcialmente', color: 'orange', background: styles.info }
    }

    if (purchaseStatus === 2 && corresponsiblePurchaseStatus === 3) {
      return { title: 'Pago parcialmente', color: 'orange', background: styles.info }
    }

    if (purchaseStatus === 7)
      return { title: 'Pago parcialmente', color: 'orange', background: styles.info }

    if (
      purchaseStatus === 3 &&
      corresponsiblePurchaseStatus === 3 &&
      contractStatus === 'signed' &&
      corresponsibleContractStatus === 'signed'
    ) {
      return { title: 'Concluída', color: 'green', background: styles.success }
    }

    if (
      purchaseStatus === 3 &&
      corresponsiblePurchaseStatus === 3 &&
      contractStatus === 'signed' &&
      corresponsibleContractStatus === undefined
    ) {
      return { title: 'Concluída', color: 'green', background: styles.success }
    }
  }

  if (purchaseStatus === 9) {
    return { title: 'Pendente aprovação Ucred', color: 'linkedin', background: styles.info }
  }

  return { title: 'Invalid status', color: 'red', background: styles.error }
}
