import React from 'react'
import { withSnackbar } from 'notistack'
import { connect } from 'react-redux'
import ApiInstance from '../../services/ApiInstance'
import {
  fillActiveCompany,
  fillClient,
  fillCompany,
  fillContract,
  fillContractNotSigned,
  fillContractSigned,
  fillLogin,
  fillLogout,
  fillPurchase,
  fillPurchasePropria,
  fillPurchasePropriaCancelled,
  fillPurchaseUcred,
  fillPurchaseUcredCancelled,
  fillRecusedPropria,
  fillRecusedUcred,
  fillSinistroPropria,
  fillSinistroUcred,
  fillTicket,
  fillTicketPropria,
  fillTicketUcred,
  fillTotalPropria,
  fillTotalUcred,
  fillTrial,
} from '../../redux/actions/dashbardAction'

class ViewRevendedor extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
    }
  }

  async getData() {
    try {
      const {
        fillPurchase,
        fillClient,
        fillContract,
        fillLogin,
        fillLogout,
        fillTicket,
        fillTrial,
        fillPurchaseUcred,
        fillPurchaseUcredCancelled,
        fillPurchasePropria,
        fillPurchasePropriaCancelled,
        fillTicketUcred,
        fillTicketPropria,
        fillTotalUcred,
        fillTotalPropria,
        fillRecusedUcred,
        fillRecusedPropria,
        fillContractSigned,
        fillContractNotSigned,
        fillCompany,
        fillActiveCompany,
        fillSinistroPropria,
        fillSinistroUcred,
      } = this.props
      const response = await ApiInstance.get('dadosDashboardRevendedor')

      const {
        clients,
        contracts,
        logins,
        logouts,
        purchases,
        tickets,
        trials,
        purchases_ucred,
        purchases_ucred_cancelled,
        purchases_propria,
        purchases_propria_canelled,
        tickets_ucred,
        tickets_propria,
        total_value_ucred,
        total_value_propria,
        recused_ucred,
        recused_propria,
        signed_contract,
        not_contract_signed,
        companies,
        imobiliarias_ativas,
        sinistroPropria,
        sinistroUcred,
      } = response.data

      fillPurchase(purchases)
      fillClient(clients)
      fillLogin(logins)
      fillContract(contracts)
      fillLogout(logouts)
      fillTicket(tickets)
      fillTrial(trials)
      fillPurchaseUcred(purchases_ucred)
      fillPurchaseUcredCancelled(purchases_ucred_cancelled)
      fillPurchasePropria(purchases_propria)
      fillPurchasePropriaCancelled(purchases_propria_canelled)
      fillTicketUcred(tickets_ucred)
      fillTicketPropria(tickets_propria)
      fillTotalUcred(total_value_ucred)
      fillTotalPropria(total_value_propria)
      fillRecusedUcred(recused_ucred)
      fillRecusedPropria(recused_propria)
      fillContractSigned(signed_contract)
      fillContractNotSigned(not_contract_signed)
      fillCompany(companies)
      fillActiveCompany(imobiliarias_ativas)
      fillSinistroPropria(sinistroPropria)
      fillSinistroUcred(sinistroUcred)
    } catch (error) {
      const snackbarKey = this.props.enqueueSnackbar('Erro inesperado, entre em contato com nosso suporte.', {
        variant: 'error',
        onClick: () => {
          this.props.closeSnackbar(snackbarKey)
        },
      })
    }
  }

  componentDidMount() {
    this.getData()
  }

  async reload() {
    this.setState({ loading: true })
    await this.getData()
    setTimeout(() => {
      this.setState({ loading: false })
    }, 2000)
  }

  detalhado(grafico) {
    this.props.history.push(`/detalhadoRevendedor/${grafico}`)
  }

  render() {
    const {
      contracts,
      logins,
      logouts,
      purchasesUcred,
      purchasesUcredCancelled,
      ticketsUcred,
      totalUcred,
      recusedUcred,
      contractsSigned,
      contractsNotSigned,
      companies,
      activeCompany,
      sinistroUcred,
    } = this.props
    return (
      <React.Fragment>
        <div className="viewRevendedor">
          <div className="viewRevendedor__line line-title">
            <h1>Dashboard</h1>
            <div className="reload-icon" title="Recarregar" onClick={this.reload}>
              {!this.state.loading ? <i className="lni lni-reload"></i> : <div className="loader" />}
            </div>
          </div>
          <div className="viewRevendedor__line">
            <div className="viewRevendedor__card" onClick={() => this.detalhado('imobiliaria')}>
              <div className="viewRevendedor__card-header">
                <i className="lni-home"></i>
                <span>Imobiliárias Cadastradas</span>
              </div>
              <div className="viewRevendedor__card-body">
                <span>{companies}</span>
              </div>
            </div>
            <div className="viewRevendedor__card" onClick={() => this.detalhado('imobiliariaAtiva')}>
              <div className="viewRevendedor__card-header">
                <i className="lni-check-mark-circle"></i>
                <span>Imobiliárias Ativas</span>
              </div>
              <div className="viewRevendedor__card-body">
                <span>{activeCompany}</span>
              </div>
            </div>
            <div className="viewRevendedor__card" onClick={() => this.detalhado('assinatura')}>
              <div className="viewRevendedor__card-header">
                <i className="lni-agenda"></i>
                <span>Imobiliárias Pendentes Assinatura</span>
              </div>
              <div className="viewRevendedor__card-body">
                <span>{contracts}</span>
              </div>
            </div>
          </div>
          <div className="viewRevendedor__line">
            <div className="viewRevendedor__card" onClick={() => this.detalhado('login')}>
              <div className="viewRevendedor__card-header">
                <i className="lni-network"></i>
                <span>Usuários Logados</span>
              </div>
              <div className="viewRevendedor__card-body">
                <span>{logins}</span>
              </div>
            </div>
            <div className="viewRevendedor__card" onClick={() => this.detalhado('logout')}>
              <div className="viewRevendedor__card-header">
                <i className="lni-timer"></i>
                <span>Usuários Não Logados</span>
              </div>
              <div className="viewRevendedor__card-body">
                <span>{logouts}</span>
              </div>
            </div>
          </div>
          <div className="viewRevendedor__line">
            <div className="viewRevendedor__card" onClick={() => this.detalhado('consultaUcred')}>
              <div className="viewRevendedor__card-header">
                <i className="lni-archive"></i>
                <span>Transações Aprovadas</span>
              </div>
              <div className="viewRevendedor__card-body">
                <span>{purchasesUcred}</span>
              </div>
            </div>
            <div className="viewRevendedor__card" onClick={() => this.detalhado('consultaUcredCancelada')}>
              <div className="viewRevendedor__card-header">
                <i className="lni-archive"></i>
                <span>Transações Canceladas</span>
              </div>
              <div className="viewRevendedor__card-body">
                <span>{purchasesUcredCancelled}</span>
              </div>
            </div>
            <div className="viewRevendedor__card" onClick={() => this.detalhado('reprovadoUcred')}>
              <div className="viewRevendedor__card-header">
                <i className="lni-seo-consulting"></i>
                <span>Transações Reprovadas</span>
              </div>
              <div className="viewRevendedor__card-body">
                <span>{recusedUcred}</span>
              </div>
            </div>
          </div>
          <div className="viewRevendedor__line"></div>
          <div className="viewRevendedor__line">
            <div className="viewRevendedor__card" onClick={() => this.detalhado('contratosPendentes')}>
              <div className="viewRevendedor__card-header">
                <i className="lni-support"></i>
                <span>Contratos Pendentes (Locatário) </span>
              </div>
              <div className="viewRevendedor__card-body">
                <span>{contractsNotSigned}</span>
              </div>
            </div>
            <div className="viewRevendedor__card" onClick={() => this.detalhado('contratosAssinados')}>
              <div className="viewRevendedor__card-header">
                <i className="lni-support"></i>
                <span>Contratos Assinados (Locatário) </span>
              </div>
              <div className="viewRevendedor__card-body">
                <span>{contractsSigned}</span>
              </div>
            </div>
          </div>
          <div className="viewRevendedor__line">
            <div className="viewRevendedor__card" onClick={() => this.detalhado('ticketUcred')}>
              <div className="viewRevendedor__card-header">
                <i className="lni-money-protection"></i>
                <span>Ticket Médio</span>
              </div>
              <div className="viewRevendedor__card-body">
                <span>R$ {ticketsUcred}</span>
              </div>
            </div>
            <div className="viewRevendedor__card" onClick={() => this.detalhado('valorTotalUcred')}>
              <div className="viewRevendedor__card-header">
                <i className="lni-shopify"></i>
                <span>Valor Total Transacionado</span>
              </div>
              <div className="viewRevendedor__card-body">
                <span>R$ {totalUcred}</span>
              </div>
            </div>
            <div className="viewRevendedor__card" onClick={() => this.detalhado('sinistroUcred')}>
              <div className="viewRevendedor__card-header">
                <i className="lni-bullhorn"></i>
                <span>Sinistro</span>
              </div>
              <div className="viewRevendedor__card-body">
                <span>{sinistroUcred}</span>
              </div>
            </div>
          </div>
          <div className="viewRevendedor__line"></div>
          <div className="viewRevendedor__line"></div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ dashboardReducer }) => ({
  ...dashboardReducer,
})

const mapDispatchToProps = (dispatch) => {
  return {
    fillPurchase: (qtd) => dispatch(fillPurchase(qtd)),
    fillClient: (qtd) => dispatch(fillClient(qtd)),
    fillContract: (qtd) => dispatch(fillContract(qtd)),
    fillLogin: (qtd) => dispatch(fillLogin(qtd)),
    fillLogout: (qtd) => dispatch(fillLogout(qtd)),
    fillTicket: (qtd) => dispatch(fillTicket(qtd)),
    fillTrial: (qtd) => dispatch(fillTrial(qtd)),
    fillPurchasePropria: (qtd) => dispatch(fillPurchasePropria(qtd)),
    fillPurchasePropriaCancelled: (qtd) => dispatch(fillPurchasePropriaCancelled(qtd)),
    fillPurchaseUcred: (qtd) => dispatch(fillPurchaseUcred(qtd)),
    fillPurchaseUcredCancelled: (qtd) => dispatch(fillPurchaseUcredCancelled(qtd)),
    fillTicketPropria: (qtd) => dispatch(fillTicketPropria(qtd)),
    fillTicketUcred: (qtd) => dispatch(fillTicketUcred(qtd)),
    fillTotalUcred: (qtd) => dispatch(fillTotalUcred(qtd)),
    fillTotalPropria: (qtd) => dispatch(fillTotalPropria(qtd)),
    fillRecusedUcred: (qtd) => dispatch(fillRecusedUcred(qtd)),
    fillContractSigned: (qtd) => dispatch(fillContractSigned(qtd)),
    fillContractNotSigned: (qtd) => dispatch(fillContractNotSigned(qtd)),
    fillRecusedPropria: (qtd) => dispatch(fillRecusedPropria(qtd)),
    fillCompany: (qtd) => dispatch(fillCompany(qtd)),
    fillActiveCompany: (qtd) => dispatch(fillActiveCompany(qtd)),
    fillSinistroPropria: (qtd) => dispatch(fillSinistroPropria(qtd)),
    fillSinistroUcred: (qtd) => dispatch(fillSinistroUcred(qtd)),
  }
}

export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(ViewRevendedor))
