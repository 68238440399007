import { createTheme } from '@material-ui/core/styles'

const activeTheme = localStorage.getItem('theme')

export const materialTheme = createTheme({
  palette: {
    type: activeTheme === 'dark' ? 'dark' : 'light',
    primary: {
      body: '#fdfdff',
      container: '#fff',
      main: '#009fff',
      contrastText: '#fff',
    },
    secondary: {
      main: '#044c94',
    },
  },
})
