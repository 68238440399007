import {
  Flex,
  Text,
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Icon,
} from '@chakra-ui/react'
import { MdHelp } from 'react-icons/md'
import { ComissionDetailType } from '../types/summary'

interface RowProps {
  children?: JSX.Element | string
  hasBorder?: boolean
  isTotal?: boolean
  isNumeric?: boolean
  isDetail?: boolean
}

export const Row = ({ children, isDetail, isNumeric, ...rest }: RowProps) => (
  <Td px={0} py={2} isNumeric={isDetail || isNumeric} {...rest}>
    <Text mr={isDetail ? 2 : 0} fontSize={isDetail ? 'xs' : 'sm'} color="gray.600">
      {children}
    </Text>
  </Td>
)

interface DetailPopoverProps {
  value: string
  details?: ComissionDetailType
  isAdhesion?: boolean
}

export function DetailPopover({ value, isAdhesion, details }: DetailPopoverProps) {
  return (
    <Popover trigger="hover">
      <Flex justify="flex-end">
        <PopoverTrigger>
          <Box as="button" _hover={{ bg: '#EBF8FF' }} w="fit-content">
            <Flex justify="flex-end" align="center">
              <Icon as={MdHelp} boxSize="3" mr={1} color="gray.500" />
              <Text
                style={{
                  borderBottomWidth: '1px',
                  borderBottomStyle: 'dotted',
                  borderBottomColor: '#CBD5E0',
                }}
                fontSize="sm"
                color="gray.600"
              >
                {value}
              </Text>
            </Flex>
          </Box>
        </PopoverTrigger>
      </Flex>
      <PopoverContent justifyContent="center" boxShadow="lg">
        <PopoverArrow />
        {isAdhesion ? (
          <PopoverBody>
            <Text align="left" color="gray.600">
              É descontado sobre todas transações efetuadas um percentual de 5% da operadora de
              cartão. O valor visualizado já é o líquido à receber.
            </Text>
          </PopoverBody>
        ) : (
          <PopoverBody>
            <Table size="sm" variant="simple">
              <Thead>
                <Tr>
                  <Th px={0} py={2}>
                    Descrição
                  </Th>
                  <Th px={0} py={2} />
                  <Th px={0} py={2} isNumeric>
                    Valor
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Row>Locatário</Row>
                  <Row isDetail>
                    {details && `${details.renter.parcelNumber}x ${details.renter.parcelValue}`}
                  </Row>
                  <Row isNumeric>{details && details.renter.total}</Row>
                </Tr>
                {details && details.corresponsible && (
                  <>
                    <Tr>
                      <Row>Corresponsável</Row>
                      <Row
                        isDetail
                      >{`${details.corresponsible.parcelNumber}x ${details.corresponsible.parcelValue}`}</Row>
                      <Row isNumeric>{details.corresponsible.total}</Row>
                    </Tr>
                    <Tr>
                      <Row />
                      <Row />
                      <Row isNumeric>{details.commissionAmount}</Row>
                    </Tr>
                  </>
                )}
              </Tbody>
            </Table>
          </PopoverBody>
        )}
      </PopoverContent>
    </Popover>
  )
}
