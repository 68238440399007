import React from 'react'
import Button from '../../components/button'
import Header from '../../layout/Header'
import Table from '../../components/table'
class Reajuste extends React.Component {
    state = {
        columns : [
            {key: 'fee', name: 'taxa', visible: true},
            {key: 'value', name: 'valor', visible: true},
            {key: 'updated_at', name: 'Última alteração', visible: true}
        ],
        rows: [
            {fee: 'Alguma', value: '10%', updated_at: '17/04/2018'}, 
            {fee: 'Outra', value: '15%', updated_at: '17/05/2018'}, 
            {fee: 'Mais Uma', value: '12%', updated_at: '12/04/2018'}, 
            {fee: 'Taxa', value: '20%', updated_at: '17/09/2018'}, 
            {fee: 'Sei la', value: '11%', updated_at: '07/03/2018'}, 
        ]
    }
    render() {
        const { columns, rows } = this.state
        return (
            <React.Fragment>
                <Header />
                <div className="reajuste">
                    <div className="reajuste__container">
                        <div className="reajuste__container-header">
                            <h1> Reajuste </h1>
                        </div>
                        <div className='reajuste__floating-menu'>
                            <Button description='Nova Taxa' onClick={() => {}} />
                        </div>
                        <div className="reajuste__container-body">
                            <Table linhas={rows} columns={columns} onClickRow={() => {} } lineFilter={'name'} placeholder="Pesquise pelo usuário"/>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Reajuste